import React from 'react';
import { Map, List, OrderedMap } from 'immutable';
import queryString from 'query-string';

import { MOMENT_UTC_TODAY, MOMENT_UTC_A_YEAR_AGO } from '^/utils';
import LandingCard from '^/components/app/groups/insights/LandingCard';
import PureComponent from '^/components/common/PureComponent';
import {
  INSIGHTS,
  INSIGHTS_API_URLS,
  PATHS,
  DATABASE_MODEL_NAME,
} from '^/components/app/groups/insights/constants';
import { withRouter } from '^/withRouter';
import {
  getSubGroupFilterFromPath,
  getCompletedActivitiesCountryFromPath,
} from '^/components/app/groups/insights/utils';
import withInsight from '^/components/app/groups/insights/hoc/withInsight';
import withLastUpdatedTimestampData from '^/components/app/groups/insights/hoc/withLastUpdatedTimestampData';
import ComplianceActivitiesCompletedCharts from '^/components/app/groups/insights/compliance-activities/ComplianceActivitiesCompletedCharts';

export class ComplianceActivitiesCompletedLanding extends PureComponent {
  componentDidMount() {
    const { query, pathname, country } = this.props;
    const subGroup = query.sub_group;

    const queryParams = {
      ...(subGroup ? { sub_group: subGroup } : {}),
      country,
    };

    this.props.router.replace(
      `${pathname}${'?' + queryString.stringify(queryParams)}`
    );
  }

  componentWillUpdate(nextProps) {
    const { query, pathname, country } = this.props;
    const subGroup = query.sub_group;

    if (nextProps.country !== country) {
      const queryParams = {
        ...(subGroup ? { sub_group: subGroup } : {}),
        country: nextProps.country,
      };

      this.props.router.replace(
        `${pathname}${'?' + queryString.stringify(queryParams)}`
      );
    }
  }

  render() {
    const {
      lastUpdatedTimestamp,
      query,
      data,
      labels,
      complianceSystemName,
    } = this.props;

    const activitiesData = data
      ? OrderedMap(List(labels.keys()).map(key => [key, data.get(key, 0.0)]))
      : OrderedMap(List(labels.keys()).map(key => [key, 0.0]));

    return (
      <LandingCard
        title={`Compliance activities completed (${complianceSystemName})`}
        icon="check"
        link={PATHS.COMPLIANCE_ACTIVITIES}
        linkText="VIEW MORE"
        lastUpdatedTimestamp={lastUpdatedTimestamp}
        query={query}
      >
        <ComplianceActivitiesCompletedCharts
          labels={labels.toJS()}
          data={activitiesData}
        />
      </LandingCard>
    );
  }
}

const ComplianceActivitiesCompletedLandingWithLastUpdatedTimestamp = withLastUpdatedTimestampData(
  DATABASE_MODEL_NAME.ACTIVITY
)(withRouter(ComplianceActivitiesCompletedLanding));

export default withInsight({
  insight: INSIGHTS.COMPLIANCE_ACTIVITIES_COMPLETED_LANDING,
  url: INSIGHTS_API_URLS.COMPLIANCE_ACTIVITIES_COMPLETED,
  getFilters: () =>
    Map({
      date_from: MOMENT_UTC_A_YEAR_AGO,
      date_to: MOMENT_UTC_TODAY,
      practice_sub_group_id: getSubGroupFilterFromPath(),
      country: getCompletedActivitiesCountryFromPath(),
    }),
})(ComplianceActivitiesCompletedLandingWithLastUpdatedTimestamp);
