import { fromJS } from 'immutable';
import moment from 'moment';
import { formatBackendDate } from '^/utils';
import { SectorTypes } from '^/consts/sector';

export const ADMIN_PRACTICE_MODEL = 'admin/practices';
export const ADMIN_COMPLIANCE_DETAILS_MODEL = 'admin/compliancedetails';
export const ADMIN_PRACTICE_MEMBERSHIP_MODEL = 'admin/practicememberships';
export const ADMIN_STAFF_PRACTICE_MODEL = 'admin/staffpractices';
export const ADMIN_PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_MODEL = 'admin/practicegrouppracticefeatureflagdefaults';
export const ADMIN_PRACTICE_OPTIONS = 'PRACTICE_OPTIONS';
export const ADMIN_COMPLIANCE_DETAILS_OPTIONS = 'COMPLIANCE_DETAILS_OPTIONS';
export const ADMIN_PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_OPTIONS = 'PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_OPTIONS';
export const ALL_PRACTICES = 'ALL_PRACTICES';
export const PRACTICES_PAST_RENEWAL = 'PRACTICES_PAST_RENEWAL';
const ADMIN_PRACTICE_MANAGE_ROUTE = '/admin/practices/${id}';
export const ADMIN_ALL_PRACTICE_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['memberships_display'], display_name: 'Memberships', type: 'string', sortable: true},
  {name: ['sector'], display_name: 'Sector', type: 'string', sortable: true},
  {name: ['practice_admins', 'email'], display_name: 'Practice Admin(s)', type: 'manyToMany', sortable: false},
  {name: ['is_active'], display_name: 'Activated', type: 'boolean', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Manage', route: ADMIN_PRACTICE_MANAGE_ROUTE},
]);

export const ADMIN_ADMIN_PRACTICE_PAST_RENEWAL_FIELDS = ADMIN_ALL_PRACTICE_FIELDS.delete(3).delete(2).insert(
  2,
  fromJS({
    name: ['renewal_date'], display_name: 'Renewal Date', type: 'datetime', show_past: true, sortable: false
  })
);
export const ADMIN_PRACTICE_CREATE = 'ADMIN_PRACTICE_CREATE';
export const ADMIN_PRACTICE_CREATE_FIELDS = fromJS([
  'sector', 'name', 'email', 'charge', 'renewal_date', 'join_date',
  'practice_admin', 'membership', 'has_mobile_access', 'has_digital_records_access', 'has_digital_records_quick_check_access',
  'has_documents_to_read_access', 'code_reference', 'business_days', 'has_icomply_lite_document_reviews_access',
]);

/* eslint-disable max-len */
export const ADMIN_PRACTICE_VIEW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string'},
  {name: ['group'], display_name: 'Group', type: 'string'},
  {name: ['members', 'user', 'staffdetail', 'full_name'], display_name: 'Members', type: 'manyToMany'},
  {name: ['practice_admins', 'user', 'email'], display_name: 'Practice Admin(s)', type: 'manyToMany'},
  {name: ['created'], display_name: 'Created', type: 'datetime'},
  {name: ['base_membership_display'], display_name: 'Base membersip', type: 'string'},
  {name: ['membership_display'], display_name: 'Membership', type: 'string'},
  {name: ['country_display'], display_name: 'Country', type: 'string'},
  {name: ['group_display'], display_name: 'Group', type: 'string'},
  {name: ['compliance_details', 'has_nhs_treatment_only'], display_name: 'Has nhs treatment only', type: 'boolean'},
  {name: ['compliance_details', 'has_mixed_nhs_private_treatment'], display_name: 'Has mixed nhs private treatment', type: 'boolean'},
  {name: ['compliance_details', 'has_private_treatment_only'], display_name: 'Has private treatment only', type: 'boolean'},
  {name: ['compliance_details', 'has_washer_disinfector'], display_name: 'Has washer disinfector', type: 'boolean'},
  {name: ['compliance_details', 'has_ultrasonic_bath'], display_name: 'Has ultrasonic bath', type: 'boolean'},
  {name: ['compliance_details', 'has_hygienist_prescribed_local_anaesthetics'], display_name: 'Has hygienist prescribed local anaesthetics', type: 'boolean'},
  {name: ['compliance_details', 'has_radon'], display_name: 'Has radon', type: 'boolean'},
  {name: ['compliance_details', 'has_asbestos'], display_name: 'Has asbestos', type: 'boolean'},
  {name: ['compliance_details', 'is_offering_sedation'], display_name: 'Is offering sedation', type: 'boolean'},
  {name: ['compliance_details', 'sedation_type_display'], display_name: 'Sedation type', type: 'string'},
  {name: ['compliance_details', 'has_practice_dispensed_drugs'], display_name: 'Has practice dispensed drugs', type: 'boolean'},
  {name: ['compliance_details', 'has_lasers'], display_name: 'Has lasers', type: 'boolean'},
  {name: ['compliance_details', 'has_digital_xray_equipment'], display_name: 'Has digital xray equipment', type: 'boolean'},
  {name: ['compliance_details', 'has_film_xray_equipment'], display_name: 'Has film xray equipment', type: 'boolean'},
  {name: ['compliance_details', 'has_handheld_xray_equipment'], display_name: 'Has handheld xray equipment', type: 'boolean'},
  {name: ['compliance_details', 'has_staff_incentive_scheme'], display_name: 'Has staff incentive scheme', type: 'boolean'},
  {name: ['compliance_details', 'has_gas_boiler'], display_name: 'Has gas boiler', type: 'boolean'},
  {name: ['compliance_details', 'has_air_conditioning'], display_name: 'Has air conditioning', type: 'boolean'},
  {name: ['compliance_details', 'has_domiciliary_care'], display_name: 'Has domiciliary care', type: 'boolean'},
  {name: ['compliance_details', 'has_cctv'], display_name: 'Has CCTV', type: 'boolean'},
  {name: ['compliance_details', 'has_hygienists_work_without_nurse'], display_name: 'Has hygienists/therapists work without a nurse', type: 'boolean'},
  {name: ['compliance_details', 'has_call_log'], display_name: 'Has practice record calls', type: 'boolean'},
  {name: ['compliance_details', 'has_dac_autoclaves'], display_name: 'Has DAC autoclaves', type: 'boolean'},
  {name: ['compliance_details', 'has_infection_control_best_practice'], display_name: 'Has infection control currently at W/HTM 0105 \'Best Practice\'', type: 'boolean'},
  {name: ['compliance_details', 'has_practice_organisation_or_partnership'], display_name: 'Has practice part of an organisation or partnership', type: 'boolean'},
  {name: ['compliance_details', 'has_patient_xray_referrals'], display_name: 'Has patient referrals for X-rays (no X-ray equipment onsite)', type: 'boolean'},
  {name: ['compliance_details', 'has_practice_amalgam'], display_name: 'Has practice use amalgam (mercury)', type: 'boolean'},
  {name: ['setup_stage_display'], display_name: 'Setup stage', type: 'string'},
  {name: ['accreditation_lead_display'], display_name: 'Accreditation lead', type: 'string'},
  {name: ['address_line_1'], display_name: 'Address line 1', type: 'string'},
  {name: ['address_line_2'], display_name: 'Address line 2', type: 'string'},
  {name: ['address_line_3'], display_name: 'Address line 3', type: 'string'},
  {name: ['city'], display_name: 'City', type: 'string'},
  {name: ['county'], display_name: 'County', type: 'string'},
  {name: ['postcode'], display_name: 'Post code', type: 'string'},
  {name: ['country'], display_name: 'Country', type: 'string'},
  {name: ['phone'], display_name: 'Phone', type: 'string'},
  {name: ['fax'], display_name: 'Fax', type: 'string'},
  {name: ['email'], display_name: 'Email', type: 'string'},
  {name: ['url'], display_name: 'Website', type: 'string'},
  {name: ['logo'], display_name: 'Logo', type: 'image'},
  {name: ['last_update'], display_name: 'Last Update', type: 'string'},
  {name: ['charge'], display_name: 'charge', type: 'string'},
  {name: ['renewal_date'], display_name: 'renewal_date', type: 'datetime'},
  {name: ['is_active'], display_name: 'Active', type: 'boolean'},
  {name: ['is_accredited'], display_name: 'Accredited', type: 'boolean'},
  {name: ['is_master_demo'], display_name: 'Master demo', type: 'boolean'},
  {name: ['is_demo'], display_name: 'Demo', type: 'boolean'},
  {name: ['has_dental_hygienist'], display_name: 'Has dental hygienist', type: 'boolean'},
  {name: ['has_dental_plan'], display_name: 'Has dental plan', type: 'boolean'},
  {name: ['dental_plan_provider'], display_name: 'Dental plan provider', type: 'string'},
  {name: ['treatment_rooms'], display_name: 'Treatment Rooms', type: 'string'},
  {name: ['practices_in_group'], display_name: 'Practices in group', type: 'string'},
]);

export const PAST_RENEWAL_PRACTICES_FILTERS = fromJS({
  'max_renewal_date': formatBackendDate(moment())
});

export const ADMIN_PRACTICE_EDIT_FIELDS = fromJS([
  'sector', 'name', 'group', 'sub_group', 'address_line_1', 'address_line_2', 'address_line_3', 'city', 'county', 'country',
  'postcode', 'phone', 'fax', 'email', 'url', 'logo', 'join_date', 'has_mobile_access', 'has_healthcheck_access', 
  'has_digital_records_access', 'has_digital_records_quick_check_access',  'has_icomply_lite_document_reviews_access',
  'has_documents_to_read_access', 'has_dashboard_tasks_chart', 'breathehr_dashboard_url', 'code_reference', 'business_days',
  'cycle_configuration',
]);

export const DENTAL_ADMIN_COMPLIANCE_DETAILS_EDIT_FIELDS = fromJS([
  'has_nhs_treatment_only',
  'has_mixed_nhs_private_treatment',
  'has_private_treatment_only',
  'has_washer_disinfector',
  'has_ultrasonic_bath',
  'has_hygienist_prescribed_local_anaesthetics',
  'has_radon',
  'has_asbestos',
  'is_offering_sedation',
  'has_practice_dispensed_drugs',
  'has_lasers',
  'has_digital_xray_equipment',
  'has_film_xray_equipment',
  'has_handheld_xray_equipment',
  'has_staff_incentive_scheme',
  'has_gas_boiler',
  'has_domiciliary_care',
  'has_cctv',
  'has_hygienists_work_without_nurse',
  'has_call_log',
  'has_dac_autoclaves',
  'has_infection_control_best_practice',
  'has_practice_organisation_or_partnership',
  'has_patient_xray_referrals',
  'has_practice_amalgam',
]);

export const GP_ADMIN_COMPLIANCE_DETAILS_EDIT_FIELDS = fromJS([
  "has_asbestos",
  "has_practice_dispensed_drugs",
  "is_offering_surgical_procedures",
  "has_non_medical_prescribers",
  "has_allied_health_professionals",
  "has_lift",
  "has_aed",
  "has_air_conditioning"
]);

export const MODALITY_ADMIN_COMPLIANCE_DETAILS_EDIT_FIELDS = fromJS([
  "has_asbestos",
  "has_practice_dispensed_drugs",
  "is_offering_surgical_procedures",
  "has_non_medical_prescribers",
  "has_allied_health_professionals",
  "has_lift",
  "has_aed",
  "has_air_conditioning"
]);

export const HOSPITAL_ADMIN_COMPLIANCE_DETAILS_EDIT_FIELDS = fromJS([]);

export const ADMIN_COMPLIANCE_DETAILS_EDIT_FIELDS = fromJS({
  [SectorTypes.DENTAL]: DENTAL_ADMIN_COMPLIANCE_DETAILS_EDIT_FIELDS,
  [SectorTypes.GP]: GP_ADMIN_COMPLIANCE_DETAILS_EDIT_FIELDS,
  [SectorTypes.MODALITY]: MODALITY_ADMIN_COMPLIANCE_DETAILS_EDIT_FIELDS,
  [SectorTypes.HOSPITAL]: HOSPITAL_ADMIN_COMPLIANCE_DETAILS_EDIT_FIELDS,
})

export const ADMIN_PRACTICE_CHARGES_FIELDS = fromJS(['charge', 'renewal_date']);

export const PRACTICES_STAFF_MEMBERS = 'PRACTICES_STAFF_MEMBERS';
const ADMIN_PRACTICE_MANAGE_USER_ROUTE = '/admin/users/${user.id}';
export const ADMIN_PRACTICE_MEMBERS_FIELDS = fromJS([
  {name: ['user', 'email'], display_name: 'Email', type: 'string', sortable: true},
  {name: ['user', 'staffdetail', 'full_name'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['type_display'], display_name: 'Type', type: 'string', sortable: true},
  {name: ['is_owner'], display_name: 'Is Owner', type: 'boolean', sortable: true},
  {name: ['is_active'], display_name: 'Active', type: 'boolean', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Manage', route: ADMIN_PRACTICE_MANAGE_USER_ROUTE},
  {name: ['id'], display_name: 'Remove from Practice', type: 'remove', sortable: false},
]);
export const ADMIN_PRACTICE_MEMBERS_CREATE_FIELDS = fromJS(['user', 'type', 'is_owner']);

export const PRACTICES_MEMBERSHIPS = 'PRACTICES_MEMBERSHIPS';
export const ADMIN_PRACTICE_MEMBERSHIP_FIELDS = fromJS([
  {name: ['membership_display'], display_name: 'Membership', type: 'string', sortable: true},
  {name: ['id'], display_name: 'Remove', type: 'remove', sortable: false},
]);
export const ADMIN_PRACTICE_MEMBERSHIP_CREATE_FIELDS = fromJS(['membership']);

export const ADMIN_PRACTICE_SERVICES_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string', sortable: true},
]);

export function getAdminPracticeCycleRowFields(exportHandle) {
  return fromJS([
    {name: ['start_date'], display_name: 'Start Date', type: 'datetime', sortable: false},
    {name: ['end_date'], display_name: 'End Date', type: 'datetime', sortable: false},
    {name: ['id'], display_name: 'Export', type: 'function', sortable: false, link_text: 'Export', handler: exportHandle},
    {name: ['id'], display_name: 'Delete', type: 'remove', sortable: false},
    {name: ['completed_activities'], display_name: 'Activities', type: 'string', sortable: false},
  ]);
}

export const ADMIN_PRACTICE_GROUP_CREATE = 'ADMIN_PRACTICE_GROUP_CREATE';
export const ADMIN_PRACTICE_GROUP_MODEL = 'admin/practicegroups';
const ADMIN_PRACTICE_GROUP_MANAGE_ROUTE = '/admin/practices/groups/${id}/';

export const ADMIN_PRACTICE_GROUP_EDIT_FIELDS = fromJS([
  'sector',
  'name',
  'provider_name',
  'phone',
  'email',
  'address_line_1',
  'address_line_2',
  'address_line_3',
  'city',
  'county',
  'country',
  'postcode',
  'url',
  'logo',
  'has_group_dashboard_access',
  'is_using_group_documents',
  'suppress_all_code_document_alerts_to_practices',
  'has_group_configuration_access',
  'has_group_news_triage',
  'has_group_practice_interaction_report',
]);

export const ADMIN_PRACTICE_GROUP_ROW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['is_using_group_documents'], display_name: 'Using group documents', type: 'boolean'},
  {name: ['has_group_dashboard_access'], display_name: 'Has access to group dashboard', type: 'boolean'},
  {name: ['has_group_news_triage'], display_name: 'Has group news triage', type: 'boolean'},
  {name: ['has_group_practice_interaction_report'], display_name: 'Has practice interaction report', type: 'boolean'},
  {display_name: 'Actions', type: 'detail', link_text: 'Edit', route: ADMIN_PRACTICE_GROUP_MANAGE_ROUTE},
  {name: ['id'], display_name: 'Remove', type: 'remove', sortable: false},
]);

export const ADMIN_PRACTICE_GROUP_VIEW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string'},
  {name: ['is_using_group_documents'], display_name: 'Using group documents', type: 'boolean'},
  {name: ['has_group_dashboard_access'], display_name: 'Has access to group dashboard', type: 'boolean'},
  {name: ['has_group_news_triage'], display_name: 'Has group news triage', type: 'boolean'},
  {name: ['has_group_practice_interaction_report'], display_name: 'Has practice interaction report', type: 'boolean'},
]);

export const ADMIN_STAFF_PRACTICE_GROUPS_MODEL = 'admin/staffpracticegroups';
export const ADMIN_STAFF_PRACTICE_GROUPS = 'ADMIN_STAFF_PRACTICE_GROUP';
export const ADMIN_STAFF_PRACTICE_GROUP_ROW_FIELDS = fromJS([
  {name: ['user', 'staffdetail', 'full_name'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['user', 'email'], display_name: 'Email', type: 'string', sortable: true},
  {name: ['assigned_sub_group_display'], display_name: 'Assigned sub group', type: 'string'},
  {name: ['insights_only'], display_name: 'Group insights only', type: 'boolean'},
  {name: ['id'], display_name: 'Remove', type: 'remove', sortable: false},
]);
export const ADMIN_STAFF_PRACTICE_GROUP_CREATE_FIELDS = fromJS(['user', 'assigned_sub_group', 'insights_only']);

export const ADMIN_PRACTICE_GROUP_PRACTICE_ROW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['id'], display_name: 'Remove', type: 'remove', sortable: false},
]);

export const ADMIN_PRACTICE_SUB_GROUPS = 'ADMIN_PRACTICE_SUB_GROUPS';
export const ADMIN_PRACTICE_SUB_GROUP_EDIT = 'ADMIN_PRACTICE_SUB_GROUP_EDIT';
export const ADMIN_PRACTICE_SUB_GROUP_MODEL = 'admin/practicesubgroups';
const ADMIN_PRACTICE_MANAGE_SUB_GROUP_ROUTE = '/admin/practices/groups/subgroups/${id}/';
export const ADMIN_PRACTICE_SUB_GROUP_VIEW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string'},
]);
export const ADMIN_PRACTICE_SUB_GROUP_EDIT_FIELDS = fromJS(['name']);
export const ADMIN_PRACTICE_SUB_GROUP_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string', sortable: false},
  {display_name: 'Manage', type: 'detail', link_text: 'Manage', route: ADMIN_PRACTICE_MANAGE_SUB_GROUP_ROUTE},
  {name: ['id'], display_name: 'Remove', type: 'remove', sortable: false},
]);
export const ADMIN_PRACTICE_SUB_GROUP_CREATE_FIELDS = fromJS(['name']);
export const ADMIN_PRACTICE_SUB_GROUP_PRACTICE_ROW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['id'], display_name: 'Remove', type: 'remove', sortable: false},
]);

// We show just the base membership for all except QPS where we show the two sub-memberships
const MEMBERSHIP_CHOICES_TO_SHOW = [
  {value: 'ADP', display_name: 'ADP'},
  {value: 'AMA', display_name: 'AMA'},
  {value: 'ICMS', display_name: 'iComply Manual'},
  {value: 'ICAPP', display_name: 'iComply Application'},
  {value: 'QPS_N', display_name: 'QPS (working toward)'},
  {value: 'QPS_A', display_name: 'QPS (membership achieved)'},
  {value: 'TOTAL_HR', display_name: 'Total HR'},
  {value: 'ICAPP_NO_CYCLE', display_name: 'iComply Application (no cycle required)'},
  {value: 'ICOMPLY_LITE', display_name: 'iComply Lite'},
  {value: 'NHS_ESSENTIALS', display_name: 'NHS Essentials'},
  {value: 'I_MANAGE', display_name: 'iManage'},
];

export const DEFAULT_BUSINESS_DAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']

export const ADMIN_PRACTICE_AUDIT_ROW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Audit', type: 'string', sortable: true},
  {name: ['id'], display_name: 'Remove', type: 'remove', sortable: false},
]);

export const transformMembershipField = (fieldConfig) =>
{
  return fieldConfig.setIn(['membership', 'choices'], MEMBERSHIP_CHOICES_TO_SHOW);
}

export const transformPracticeDetailsFields = (fieldConfig) =>
{
  const newChoices = fieldConfig.getIn(['business_days', 'choices']).map(
    choice => (
      {
        value: choice.get('value'),
        display_name: choice.get('display_name').charAt(0)
      }
    )
  )
  return fieldConfig.setIn(
    ['business_days', 'type'], 'multiple check-box'
    ).setIn(['business_days', 'choices'], newChoices
    ).setIn(['sector', 'read_only'], true)
    .setIn(['sub_group', 'label'], 'Sub group(s)');
}


export const transformConfig = (fieldConfig) =>
{
  const newChoices = fieldConfig.getIn(['business_days', 'choices']).map(
    choice => (
      {
        value: choice.get('value'),
        display_name: choice.get('display_name').charAt(0)
      }
    )
  )
  return fieldConfig.setIn(
    ['membership', 'choices'], MEMBERSHIP_CHOICES_TO_SHOW
    ).setIn(['business_days', 'type'], 'multiple check-box'
    ).setIn(['business_days', 'choices'], newChoices);
}

export const transformPracticeGroupConfig = (fieldConfig) =>
{
  return fieldConfig.setIn(['sector', 'read_only'], true)
}

export const PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_FIELDS = fromJS([
  'has_digital_records_access',
  'has_digital_records_quick_check_access',
  'has_dental_hygienist',
  'has_dental_plan', 
  'has_mobile_access',
  'has_healthcheck_access',
  'has_icomply_lite_document_reviews_access',
  'has_documents_to_read_access',
  'has_dashboard_tasks_chart',
  'cycle_configuration',
]);
