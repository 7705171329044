import React from 'react';
import {
  COMPLIANCE_ACTIVITIES_THRESHOLDS
} from '^/components/app/groups/insights/constants';
import RadialChart from '^/components/app/groups/insights/visualisations/RadialChart';
import BatteryBar from '^/components/app/groups/insights/visualisations/BatteryBar';

const ComplianceActivitiesCompletedCharts = (props) => {
  
  return (
    <div className="row">
      <div className="col-2-3 col-last radial-charts-container print-full-width">
        {props.data.map((value, key) => (
          <div key={key} className="radial-charts-container__item">
            <RadialChart
              key={key}
              label={props.labels[key]}
              value={value}
            />
            <span className="value__label">
              {typeof props.labels[key] === 'string' && props.labels[key]}
            </span>
          </div>
        ))}
      </div>

      <div className="col-1-3 battery-chart-container">
        {props.data.map((value, key) => (
          <span key={key}>
          <div className="col-md-4 battery-chart-row label">{props.labels[key]}</div>
          <div key={key} className="row">

            <div key={key} className="compliance-activities-battery">
              <BatteryBar
                rate={value}
                height={8}
                borderRadius={1}
                thresholdBad={COMPLIANCE_ACTIVITIES_THRESHOLDS.BAD}
                thresholdOk={COMPLIANCE_ACTIVITIES_THRESHOLDS.OK}
              />
            </div>
          </div>
          </span>
        ))}
      </div>
    </div>
  );
};

export default ComplianceActivitiesCompletedCharts;
