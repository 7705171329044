
import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import { connect } from 'react-redux';

import * as actions from '^/actions/actions';

import ChangePasswordForm from '^/components/app/users/forms/ChangePasswordForm';
import { hasSucceeded } from '^/consts/responseStates';


export class ChangePassword extends PureComponent {

  changePassword(data) {
    this.props.changePassword(data);
  }

  successMessage() {
    return <p className="alert-success">Your password was updated successfully.</p>;
  }

  render() {
    return (
      <div className="col-1 mt-1">
        <h1>Change password</h1>
        <hr className="dark" />
          {hasSucceeded(this.props.response) ? (
            this.successMessage()
          ) : (
            <ChangePasswordForm
              onSubmit={this.changePassword.bind(this)}
              response={this.props.response}
            />
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.get('changePassword')
  };
}

export default connect(mapStateToProps, actions) (ChangePassword);
