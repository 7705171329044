/* global process */
import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import Nav from '^/components/admin/nav/Nav';
import Main from '^/components/admin/content/Main';
import Header from '^/components/admin/content/Header';
import Footer from '^/components/app/content/Footer';
import Loading from '^/components/app/content/Loading';
import { connect } from 'react-redux';


export class AdminPage extends PureComponent {
  render() {
    const { isLoggedIn, routes, params, children } = this.props;
    const hideNav = process.env.TEMPLATES_ONLY === 'true';

    if(!isLoggedIn) {
      return( <Loading /> )
    }

    return (
      <div className="admin-page">
        <Header />
        <div className="wrapper">
          <div className="row relative">
            {!hideNav && (
              <Nav routes={routes} />
            )}
            <Main routes={routes} params={params}>
              {children}
            </Main>
          </div>
        </div>
        <Footer />
      </div>
    );
    
  }
}

export function mapStateToProps(state) {
  return {
    isLoggedIn: Boolean(state.user && state.user.get('loggedInUserId')),
  };
}

export default connect(mapStateToProps)(AdminPage);
