import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import moment from 'moment';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import BigCalendar from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import { ACTIVITY_TYPE, sortActivitiesInCalendar } from '^/models/activities';
import { loadTasksForMonth } from '^/actions/collections';
import { ACTIVITIES, TASKS, DOCUMENT_REVIEWS_BY_MONTH } from '^/consts/collectionKeys';
import { isPending, hasFailed } from '^/consts/responseStates';
import { setSelectedEvent, resetResponse } from '^/actions/actions';
import {
  updateTaskDateAndCallOnComplete,
  setCurrentMonthAndGetMyActivities,
  updateActivityDateAndCallOnComplete
} from '^/actions/actionSequences';
import { formatBackendDate, isPracticesGroupAdmin } from '^/utils';
import CalendarEventPopup from './CalendarEventPopup';
import CalendarEvent, { CALENDAR_EVENT_TYPE } from './CalendarEvent';

const DragAndDropCalendar = withDragAndDrop(BigCalendar);

export class CalendarGridView extends React.Component {
  constructor(props) {
    super(props);
    this.reloadActivities = this.reloadActivities.bind(this);
    this.refreshActivities = this.refreshActivities.bind(this);
  }

  renderSelectedEvent(selectedEventProps) {
    return selectedEventProps.type ? (
      <CalendarEventPopup
        {...selectedEventProps}
        onClose={() => this.props.setSelectedEvent(null)}
      />
    ) : (
      null
    );
  }

  reloadActivities() {
    const { currentMonth, currentPracticeId } = this.props;
    if (currentMonth && currentPracticeId) {
      this.props.resetResponse('updateItem');
      this.props.loadTasksForMonth(currentMonth, currentPracticeId);
    }
  }

  refreshActivities() {
    const { currentMonth, currentPracticeId } = this.props;
    if(currentMonth && currentPracticeId) {
      this.props.resetResponse('updateItem');
      this.props.setCurrentMonthAndGetMyActivities(currentMonth, currentPracticeId, false);
    }
  }

  moveEvent({event: {activity, task, documentReview}, start}, isGroupAdmin) {
    if (activity) {
      if (activity.get('type') === ACTIVITY_TYPE.COMPLIANCE_REVIEW) {
        alert("You cannot move Compliance Reviews on the calendar.");
      } else {
        this.props.updateActivityDateAndCallOnComplete(activity, start, this.refreshActivities);
      }
    }
    else if(task) {
      if(task.get('is_locked_at_practice_level') && !isGroupAdmin) {
        alert("You cannot move this Task as it's Group Task is locked.");
      } else {
        this.props.updateTaskDateAndCallOnComplete(task.get('id'), formatBackendDate(start), this.reloadActivities);
      }
    } else if (documentReview) {
      alert('You cannot move Document Reviews on the calendar');
    }
  }

  selectedEventIsInCurrentMonth() {
    const eventDateFieldName = {
      [CALENDAR_EVENT_TYPE.ACTIVITY]: 'start_date',
      [CALENDAR_EVENT_TYPE.TASK]: 'deadline',
      [CALENDAR_EVENT_TYPE.DOCUMENT_REVIEW]: 'scheduled_completion_date',
    }
    const { currentMonth, selectedEvent } = this.props;
    const { type, object } = selectedEvent.toObject();
    if (!object) { return false; }
    const eventDate = moment(object.get(eventDateFieldName[type]));

    return eventDate.isSame(moment(currentMonth), 'month');
  }

  render() {
    const {
      tasks,
      activities,
      documentReviews,
      currentMonth,
      selectedEvent,
      failedUpdating,
      updatingError,
      currentPractice,
      userId,
    } = this.props;

    const calendarActivities = activities.map(activity => ({
      start: moment(activity.get(activity.get('type') === ACTIVITY_TYPE.COMPLIANCE_REVIEW ? 'due_date' : 'start_date')).toDate(),
      end: moment(activity.get(activity.get('type') === ACTIVITY_TYPE.COMPLIANCE_REVIEW ? 'due_date' : 'start_date')).add(1, 'second').toDate(),
      type: CALENDAR_EVENT_TYPE.ACTIVITY,
      activity,
    })).sort(sortActivitiesInCalendar);

    const calendarTasks = tasks.map(task => ({
      start: moment(task.get('deadline')).toDate(),
      end: moment(task.get('deadline')).add(1, 'second').toDate(),
      type: CALENDAR_EVENT_TYPE.TASK,
      task,
    }));

    const calendarDocumentReviews = documentReviews.map(documentReview => ({
      start: moment(documentReview.get('scheduled_completion_date')).toDate(),
      end: moment(documentReview.get('scheduled_completion_date')).add(1, 'second').toDate(),
      type: CALENDAR_EVENT_TYPE.DOCUMENT_REVIEW,
      documentReview,
    }));

    const events = calendarActivities.concat(calendarTasks).concat(calendarDocumentReviews).toArray();
    const isGroupAdmin = currentPractice && userId && isPracticesGroupAdmin(currentPractice, userId);

    return (
      <div className="calendar-container">
        {failedUpdating && (
          <p className="alert-warning">
            {updatingError || 'An error occurred trying to move this calendar item.'}
          </p>
        )}

        {this.selectedEventIsInCurrentMonth() && (
          this.renderSelectedEvent(selectedEvent.toObject())
        )}

        <DragAndDropCalendar
          events={events}
          date={currentMonth.toDate()}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onNavigate={() => {}}
          toolbar={false}
          popup
          components={{event: CalendarEvent}}
          selectable
          onEventDrop={args => this.moveEvent(args, isGroupAdmin)}
          views={['month']}
          formats={{
            weekdayFormat: 'dddd',
          }}
          culture="en-GB"
        />
      </div>
    );
  }
}

CalendarGridView.propTypes = {
  currentMonth: React.PropTypes.any.isRequired,
  activities: ImmutablePropTypes.list.isRequired,
  tasks: ImmutablePropTypes.list.isRequired,
  isLoading: React.PropTypes.bool,
  isUpdating: React.PropTypes.bool,
  failedUpdating: React.PropTypes.bool,
};

function mapStateToProps(state) {
  const activities = state.collections.getIn([ACTIVITIES, 'items'], List());
  const tasks = state.collections.getIn([TASKS, 'items'], List());
  const documentReviews = state.collections.getIn([DOCUMENT_REVIEWS_BY_MONTH, 'items'], List());

  const updatingActivity = state.responses.getIn(['updateItem', ACTIVITIES]);
  const updatingTask = state.responses.getIn(['updateItem', TASKS]);

  return {
    tasks,
    activities,
    documentReviews,
    currentMonth: state.currentMonth,
    currentPracticeId: state.currentPractice && state.currentPractice.get('id'),
    currentPractice: state.currentPractice,
    isLoading: isPending(state.responses.getIn(['getCollection', ACTIVITIES])),
    isUpdating: isPending(updatingActivity) || isPending(updatingTask),
    failedUpdating: hasFailed(updatingActivity) || hasFailed(updatingTask),
    updatingError: (
      (hasFailed(updatingActivity) && updatingActivity) ||
      (hasFailed(updatingTask) && updatingTask) ||
      Map()
    ).get('errors'),
    selectedEvent: state.ui.get('selectedEvent'),
    userId: state.userProfile && state.userProfile.get('id'),
  };
}

export default connect(mapStateToProps, {
  setSelectedEvent,
  updateActivityDateAndCallOnComplete,
  updateTaskDateAndCallOnComplete,
  setCurrentMonthAndGetMyActivities,
  loadTasksForMonth,
  resetResponse,
})(DragDropContext(HTML5Backend)(CalendarGridView));
