import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import Table from '^/components/app/groups/insights/visualisations/Table';
import TableFooter from '^/components/app/groups/insights/visualisations/TableFooter';
import { changePracticeAndNavToDashboard } from '^/actions/actionSequences';
import withLoadInsightDataOnMount from '^/components/app/groups/insights/hoc/withLoadInsightDataOnMount';
import {
  INSIGHTS,
  INSIGHTS_API_URLS,
  INSIGHTS_CSV_API_URLS,
} from '^/components/app/groups/insights/constants';
import {
  getSubGroupFilterFromPath,
  getCompletedActivitiesCountryFromPath,
} from '^/components/app/groups/insights/utils';
import {
  convertRateToPercentage,
  MOMENT_UTC_TODAY,
  MOMENT_UTC_A_YEAR_AGO,
} from '^/utils';

function getTableColumns(labels, practiceNameOnClick) {
  return [
    {
      key: 'practice_name',
      header: 'Practice',
      className: 'text-align-left',
      cell: ({ row }) => (
        <a onClick={() => practiceNameOnClick(row.get('practice_id'))}>
          {row.get('practice_name')}
        </a>
      ),
    },
    ...labels
      .map((headingLabel, key) => {
        return {
          key,
          header: headingLabel,
          className: 'text-align-right',
          cell: ({ row }) => (
            <span>{`${convertRateToPercentage(
              row.getIn(['completed_rates', key])
            )} %`}</span>
          ),
        };
      })
      .values(),
  ];
}

const INSIGHT = INSIGHTS.COMPLIANCE_ACTIVITIES_COMPLETED_BY_PRACTICE;

export const ComplianceActivitiesTable = props => {
  const { labels, changePractice } = props;
  const columnDefinitions = getTableColumns(labels, changePractice);

  return (
    <div className="row">
      <div className="col-1">
        <Table
          insight={INSIGHT}
          columns={columnDefinitions}
          defaultSortByKey="overall"
          defaultSortIsAscending
        />
        <TableFooter
          insight={INSIGHT}
          csvUrl={INSIGHTS_CSV_API_URLS.COMPLIANCE_ACTIVITIES_COMPLETED_BY_PRACTICE_REPORT}
        />
      </div>
    </div>
  );
};

const ConnectedComplianceActivitiesTable = connect(undefined, {
  changePracticeAndNavToDashboard,
})(ComplianceActivitiesTable);

export default withLoadInsightDataOnMount({
  insight: INSIGHT,
  url: INSIGHTS_API_URLS.COMPLIANCE_ACTIVITIES_COMPLETED_BY_PRACTICE,
  getFilters: () =>
    Map({
      date_from: MOMENT_UTC_A_YEAR_AGO,
      date_to: MOMENT_UTC_TODAY,
      practice_sub_group_id: getSubGroupFilterFromPath(),
      country: getCompletedActivitiesCountryFromPath(),
    }),
})(ConnectedComplianceActivitiesTable);
