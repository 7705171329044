import React from 'react';
import { connect } from 'react-redux';
import { setDownloadingReport } from '^/actions/actions';
import Loading from '^/components/app/content/Loading';


function ReportsModalContent({ reportType, url, valid, isDownloading, setDownloading }) {
  if (isDownloading) {
    return (
      <div>
        <p>Your report is downloading in the background. Please don't close your browser.</p>
        <Loading />
      </div>
    );
  }

  const reportName = reportType.get('display_name');

  return (
    <div>
      <p>Generate { reportName }{ reportName.endsWith('Report') ? '' : ' report'}.</p>
      <p>Please note: The link is only valid for the next { valid } minutes!</p>
      <a href={url} className="btn btn-primary fullwidth" onClick={setDownloading}>Generate</a>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isDownloading: state.ui.get('disableReportDownloadButton')
  };
}

export default connect(mapStateToProps, {
  setDownloading: setDownloadingReport
})(ReportsModalContent);
