import React from 'react';
import { reduxForm } from 'redux-form';
import Icon from '^/components/app/content/Icon';

export const AdminListSearch = ({ fields: { searchString }, isLoading, handleSubmit, onClear }) => {
  const onChangeInput = (event) => {
    const { value } = event.target;
    searchString.onChange(value);
    if (!value) {
      onClear();
    }
  };

  return (
    <div className=" pull-right">
      <form onSubmit={handleSubmit}>
        <span className="admin-list-search-container pull-right">
          <input type="search" {...searchString} placeholder="search..." disabled={isLoading} onChange={onChangeInput} />
          <a onClick={handleSubmit}><Icon type="search" className="admin-list-search-icon" /></a>
        </span>
      </form>
    </div>
  );
};

AdminListSearch.propTypes = {
  handleSubmit: React.PropTypes.func.isRequired,
  loading: React.PropTypes.bool.isRequired,
};

export default reduxForm({
  fields: ['searchString']
})(AdminListSearch);
