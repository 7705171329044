import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

import { setRetainLoadedDocuments } from '^/actions/actions';

interface StoreState {
  ui: {
    retainLoadedDocuments: boolean;
  };
}

interface StateProps {
  retainLoadedDocuments: boolean;
}

interface OwnProps {
  retainLoadedDocumentsProp?: boolean;
}

type DispatchProps = {
  setRetainLoadedDocuments: typeof setRetainLoadedDocuments;
};

export type BackButtonProps = OwnProps & DispatchProps & StateProps;

export class DocumentBackButton extends React.Component<BackButtonProps, {}> {
  private setRetainLoadedDocuments = () => {
    this.props.setRetainLoadedDocuments(true);
  };

  componentWillMount() {
    const { retainLoadedDocumentsProp } = this.props;

    if (retainLoadedDocumentsProp) {
      this.setRetainLoadedDocuments();
    }
  }

  render() {
    return (
      <button className="back-link" onClick={this.onBack}>
        {'< Back'}
      </button>
    );
  }

  private onBack = () => {
    browserHistory.goBack();
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  retainLoadedDocuments: state.ui.retainLoadedDocuments,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, {
  setRetainLoadedDocuments,
})(DocumentBackButton);
