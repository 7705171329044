import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deleteAuditAndNavigateToDashboard } from '^/actions/audit';
import { StoreState } from '^/store/types';
import { closeModal } from '^/actions/modals';
import NonFieldErrorRenderer from '^/components/common/NonFieldErrorRenderer';

interface StateProps {
  errors: unknown;
  isLoading: boolean;
}

interface DispatchProps {
  onDelete: typeof deleteAuditAndNavigateToDashboard;
  onCancel: typeof closeModal;
}

interface OwnProps {
  itemId: string;
}

type Props = StateProps & DispatchProps & OwnProps;

class DeleteAuditModal extends Component<Props, {}> {
  public render() {
    return (
      <div className="mb-1">
        <p>Are you sure you want to delete this audit?</p>
        <NonFieldErrorRenderer errors={this.props.errors} />
        <div className="row align-right mt-1">
          <div className="col-1">
            <button className="btn btn-default" onClick={this.props.onCancel}>
              Cancel
            </button>
            <button
              className="btn btn-warning ml-1"
              onClick={this.onDelete}
              disabled={this.props.isLoading}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  }

  private onDelete = () => {
    this.props.onDelete(this.props.itemId);
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  errors: state.thunkRequests.deleteAudit.errors,
  isLoading: state.thunkRequests.deleteAudit.isLoading,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, {
  onDelete: deleteAuditAndNavigateToDashboard,
  onCancel: closeModal,
})(DeleteAuditModal);
