import { List } from 'immutable';
import { STAFF_PRACTICE_TYPE } from '^/models/staffPractice';

export const USER_TYPES = {
  SUPERADMIN: 'SUPERADMIN',
  ADMIN: 'ADMIN',
  ENDUSER: 'ENDUSER',
  DEMO_USER: 'DEMO_USER',
};

export const STATUS_TYPES = {
  ACTIVE: 'ACTIVE',
  INVITED: 'INVITED',
};

export const isAdminType = userType =>
  List.of(USER_TYPES.ADMIN, USER_TYPES.SUPERADMIN).contains(userType);

export const isAdmin = user =>
  user && isAdminType(user.get('type'));

export const isEndUser = user =>
  user && user.get('type') === USER_TYPES.ENDUSER;

export const isDemoUser = user =>
  user && user.get('type') === USER_TYPES.DEMO_USER;

export const isReadOnlyInCurrentPractice = user =>
  user && user.get('user_type_in_current_practice') === STAFF_PRACTICE_TYPE.READ_ONLY;

export const isNotReadOnlyInCurrentPractice = user =>
  !isReadOnlyInCurrentPractice(user);

export const getCurrentPracticeId = (user) =>
  user && user.getIn(['staffdetail', 'current_practice', 'id']);

export const getCurrentPractice = (user) =>
  user && user.getIn(['staffdetail', 'current_practice']);

export const PAYMENT_STATUS = {
  REQUESTED: 'REQUESTED',
  INITIATED: 'INITIATED',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
};
