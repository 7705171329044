import { fromJS, List } from 'immutable';

export const ADMIN_USER = 'admin/users';
export const ADMIN_STAFF_DETAIL = 'admin/staffdetail';

export const ADMIN_USER_CREATE = 'ADMIN_END_USER_CREATE';
export const ADMIN_USER_CREATE_FIELDS = fromJS([
  'email',
  'first_name',
  'last_name',
]);

const ADMIN_USER_DETAIL_ROUTE = '/admin/users/${id}';

function getUserListFields (detailRoute, isAdminUserList) {

  const fields = fromJS([
    {name: ['email'], display_name: 'Email', type: 'string', sortable: true},
    {name: ['sector'], display_name: 'Sector', type: 'string', sortable: true},
    {name: ['staffdetail', 'full_name'], display_name: 'Full name', type: 'string', sortable: true},
    {name: ['is_locked'], display_name: 'Locked', type: 'boolean', sortable: false},
    {name: ['is_moderated'], display_name: 'Moderated', type: 'boolean', sortable: true},
    {name: ['last_activity'], display_name: 'Last Activity', type: 'datetime', sortable: true},
    {display_name: 'Manage', type: 'detail', link_text: 'Manage', route: detailRoute},
  ]);

  if (isAdminUserList) {
    return fields.filterNot(
      each => each.get('name', List()).contains('is_moderated') ||
      each.get('name', List()).contains('full_name')
    );
  }

  return fields;
}

export const ADMIN_USER_ROW_FIELDS = getUserListFields(ADMIN_USER_DETAIL_ROUTE);
export const ADMIN_USER_END_USER_FILTERS = fromJS({
  'user_types': 'APPUSERS',
});

const ADMIN_USER_ADMIN_DETAIL_ROUTE = '/admin/admin-users/${id}';
export const ADMIN_USER_ADMIN_ROW_FIELDS = getUserListFields(ADMIN_USER_ADMIN_DETAIL_ROUTE, true);
export const ADMIN_USER_ADMIN_USER_FILTERS = fromJS({
  'user_types': 'ADMINUSERS',
});


export const ADMIN_ACCOUNT_DETAILS_VIEW_FIELDS = fromJS([
  {name: ['username'], display_name: 'Username', type: 'string'},
]);

export const ADMIN_STAFF_DETAIL_EDIT_FIELDS = fromJS([
  'first_name',
  'last_name',
  'birth_date',
  'address_line_1',
  'address_line_2',
  'address_line_3',
  'city',
  'county',
  'postal_code',
  'mobile',
  'phone',
  'fax',
  'emergency_name',
  'emergency_address',
  'emergency_phone',
  'emergency_relationship',
  'qualifications',
]);

export const ADMIN_STAFF_DETAIL_VIEW_FIELDS = fromJS([
  {name: ['full_name'], display_name: 'Full name', type: 'string'},
  {name: ['birth_date'], display_name: 'Birth date', type: 'string'},
  {name: ['address_line_1'], display_name: 'Address line 1', type: 'string'},
  {name: ['address_line_2'], display_name: 'Address line 2', type: 'string'},
  {name: ['address_line_3'], display_name: 'Address line 3', type: 'string'},
  {name: ['city'], display_name: 'City', type: 'string'},
  {name: ['county'], display_name: 'County', type: 'string'},
  {name: ['postal_code'], display_name: 'Post code', type: 'string'},
  {name: ['mobile'], display_name: 'Mobile', type: 'string'},
  {name: ['phone'], display_name: 'Phone', type: 'string'},
  {name: ['fax'], display_name: 'Fax', type: 'string'},
  {name: ['emergency_name'], display_name: 'Emergency name', type: 'string'},
  {name: ['emergency_address'], display_name: 'Emergency address', type: 'string'},
  {name: ['emergency_phone'], display_name: 'Emergency phone', type: 'string'},
  {name: ['emergency_relationship'], display_name: 'Emergency relationship', type: 'string'},
  {name: ['qualifications'], display_name: 'Qualifications', type: 'string'},
]);


export const ACCOUNT_DETAILS_VIEW = 'ACCOUNT_DETAILS_VIEW';
export const ACCOUNT_DETAILS_VIEW_FIELDS = fromJS([
  {name: ['last_activity'], display_name: 'Last Activity', type: 'datetime'},
  {name: ['code_id'], display_name: 'iComply Member ID', type: 'string'},
]);

export const ACCOUNT_LOGINS = 'ACCOUNT_LOGINS';
export const ACCOUNT_LOGINS_FIELDS = fromJS([
  {name: ['last_login'], display_name: 'Last Logged In', type: 'datetime'},
  {name: ['last_logout'], display_name: 'Last Logged Out', type: 'datetime'},
  {name: ['is_email_verified'], display_name: 'Email verified', type: 'boolean'},
]);

export const ADMIN_USER_OWNED_PRACTICES = 'ADMIN_USER_OWNED_PRACTICES';

export const ADMIN_ACCOUNT_DETAILS_FIELDS = fromJS([
  'email', 'password', 'type', 'admin_role', 'email_sending_suspended'
]);

export const ADMIN_ACCOUNT_ADMIN_USER_DETAILS_FIELDS = fromJS([
  'first_name', 'last_name', 'email', 'password', 'type', 'admin_role', 'mobile',
]);

export const ADMIN_ACCOUNT_ADMIN_USER_CREATE_FIELDS = fromJS([
  'first_name',
  'last_name',
  'email',
  'password',
  'type',
  'admin_role',
  'mobile',
]);

const ADMIN_USER_MANAGE_PRACTICE_ROUTE = '/admin/practices/${practice.id}';
export const ADMIN_USER_PRACTICE_MEMBERS_FIELDS = fromJS([
  {name: ['practice', 'name'], display_name: 'Practice', type: 'string', sortable: true},
  {name: ['type_display'], display_name: 'Type', type: 'string', sortable: true},
  {name: ['is_owner'], display_name: 'Is Owner', type: 'boolean', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Manage', route: ADMIN_USER_MANAGE_PRACTICE_ROUTE},
  {name: ['id'], display_name: 'Remove from Practice', type: 'remove', sortable: false},
]);
export const ADMIN_USER_PRACTICE_MEMBERS_CREATE_FIELDS = fromJS(['practice', 'type', 'is_owner']);

export const ADMIN_USER_ROLES = 'ADMIN_USER_ROLES';
export const ADMIN_USER_ROLES_FIELDS = fromJS([
  {name: ['name'], display_name: 'name', type: 'string', sortable: true},
]);
