import React from 'react';

import { Link } from 'react-router';
import {GROUP_SECTION_TEXT, GROUP_SECTION_URLS} from '^/consts/groups';


export const GroupNavItem = (props) => {
  const isCurrent = props.section === props.currentSection;
  return (
    <li className={isCurrent ? 'sidenav-active' : ''}>
      <Link className="sidenav-text" to={GROUP_SECTION_URLS.get(props.section, '')}>
        <span>{GROUP_SECTION_TEXT.get(props.section)}</span>
      </Link>
    </li>
  );
};

export default GroupNavItem;
