import React from 'react';
import _ from 'underscore';
import moment from 'moment';
import { fromJS, List } from 'immutable';
import { isRSAA } from 'redux-api-middleware';
import { isFSA } from 'flux-standard-action';
import queryString from 'query-string';

import { getDocumentLink } from '^/models/documents';
import {
  ACTIVITY_TYPE,
  DOCUMENT_REVIEW_STATUSES,
  isOverdue,
  isComplete,
  documentReviewIsOverdue,
} from '^/models/activities';

export const FORMAT_DATE = 'Do MMM YYYY';
const FORMAT_TIME = 'HH:mm';
const FORMAT_DATE_TIME = FORMAT_DATE.concat(' [at] ').concat(FORMAT_TIME);
const FORMAT_DATE_BACKEND = 'YYYY-MM-DD';
const FORMAT_DATE_TIME_BACKEND = FORMAT_DATE_BACKEND.concat('THH:mm:ss');

export const FORMAT_DATE_PICKER = 'DD-MM-YYYY';
export const FORMAT_DATE_TIME_ADMIN = FORMAT_DATE.concat(' ').concat(FORMAT_TIME);

export const ARCHIVED = 'ARCHIVED';

export function getIn(item, path, defaultItem) {
  if (item === undefined) {
    return defaultItem;
  }
  if (path.length === 0) {
    return item;
  }
  return getIn(item[_.first(path)], _.rest(path), defaultItem);
}

export function capitalize(text) {
  let textToCapitalize;

  if (typeof text === 'object' || typeof text === 'undefined') {
    return '';
  }

  if (typeof text !== 'string') {
    textToCapitalize = text.toString();
  }

  textToCapitalize = text.toString() || '';

  return textToCapitalize
    .charAt(0)
    .toUpperCase()
    .concat(
      textToCapitalize
        .substring(1)
        .toLowerCase()
    );
}

export function pluralize(count, singular, plural) {
  count = parseFloat(count);

  if (isNaN(count)) {
    throw new Error('The count provided for pluralize function was not a number');
  }

  if (!singular || typeof singular === 'object') {
    throw new Error('Singular provided for pluralize function was empty or incorrect type');
  }

  plural = plural || singular.toString().concat('s');

  if (!count) {
    return ['No', plural].join(' ');
  } else if (count === 1) {
    return [count, singular].join(' ');
  }
    return [count, plural].join(' ');

}

export function createdByOnDate(userName, date) {
  if (!userName && !date) {
    return '';
  }

  if (!userName) {
    return [
      'Created on',
      moment(date).format(FORMAT_DATE)
    ].join(' ');
  }

  if (!date) {
    return [
      'Created by',
      userName
    ].join(' ');
  }

  return [
    'Created by',
    userName,
    'on',
    moment(date).format(FORMAT_DATE)
  ].join(' ');
}

export function formatDateTime(date, noDate, format = FORMAT_DATE_TIME) {
  if (!date) {
    return noDate || 'unknown';
  }
  return moment(date).format(format);
}

export function formatDate(date) {
  return moment(date).format(FORMAT_DATE);
}

export function formatBackendDate(date) {
  return moment(date).format(FORMAT_DATE_BACKEND);
}

export function formatBackendDateTime(date, time) {
  return moment(`${date} ${time}`).format(FORMAT_DATE_TIME_BACKEND);
}

export function formatTime(datetime) {
  return moment(datetime).format(FORMAT_TIME);
}

export function formatUTCTime(datetime) {
  return moment(datetime).utc().format(FORMAT_TIME);
}

function formatTimeNoDate(time) {
  return moment(time, 'HH:mm:ss').format('ha');
}

export function formatPickerDate(date) {
  return moment(date).format(FORMAT_DATE_PICKER);
}

export function formatChangeType(changeType) {
  return changeType.charAt(0).toUpperCase() + changeType.slice(1).toLowerCase();
}

export function getFirstInstanceOfWeekdayOnOrBeforeDate(initalDate, weekday) {
  const daysBehind = initalDate.isoWeekday() - weekday;
  const daysToSubtract = daysBehind >= 0 ? daysBehind : 7 + daysBehind;
  return initalDate.subtract(daysToSubtract, 'days');
}

export function getFirstInstanceOfWeekdayInMonth(date, weekday) {
  const first = moment(date).startOf('month');
  const weekdayDifference = weekday - moment(first).isoWeekday();
  const daysToAdd = moment(weekdayDifference) >= 0 ? weekdayDifference : 7 + weekdayDifference;
  return moment(first).add(daysToAdd, 'days');
}

export function displayDateIfAvailable(date) {
  if (date) {
    return moment(date).format(FORMAT_DATE);
  }
  return '-';
}

export function sortByDateTime(items, dateTimeAccessor) {
  return items.sort((a, b) => {
    const dateTimeA = new Date(dateTimeAccessor(a));
    const dateTimeB = new Date(dateTimeAccessor(b));
    return dateTimeA - dateTimeB;
  });
}

export function padTens(number) {
  return (number < 10 ? '0' : '') + number;
}

export function compare(a, b) {
  if (a > b) { return 1; }
  if (a < b) { return -1; }
  return 0;
}

export function sort(items, transform, ascending) {
  return items.sort((item1, item2) =>
    compare(transform(item1), transform(item2)) * (ascending ? 1 : -1)
  );
}

export function getQueryParamsFromString(string) {
  return queryString.parse(string);
}

export function momentRangeBounded(range, value) {
  const { start, end } = _.mapObject(range, each => each && moment(each));

  if (start && value.isBefore(start)) {
    return start;
  }
  if (end && value.isAfter(end)) {
    return end;
  }

  return value;
}

export function toProperCase(string) {
  if (!string || !string.length) {
    return '';
  }

  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export function startsWith(haystack, needle) {
  return haystack.lastIndexOf(needle, 0) === 0;
}

export function pathMatches(link, pathname) {
  return pathname && startsWith(pathname, link);
}

export function isAction(obj) {
  return isRSAA(obj) || isFSA(obj);
}

export function parseObjectPropertyString(obj, stringFormat) {
  return stringFormat.replace(/\$\{(.*?)\}/gi, (property, propertyName) => obj[propertyName]);
}

export function parseImmutablePropertyString(obj, stringFormat) {
  return stringFormat.replace(/\$\{(.*?)\}/gi, (property, propertyName) => obj.getIn(propertyName.split('.'), '--'));
}

export function getOrdinal(number) {
  if (!isNaN(number) && parseFloat(number) === parseInt(number, 10)) {
    const strings = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return number + (strings[(v - 20) % 10] || strings[v] || strings[0]);
  }
  return number;
}

// Jackie Onassis becomes Jackie O
export function summariseName(name) {
  const names = name.split(/\s+/);
  if (names.length) {
    names[names.length - 1] = names[names.length - 1][0];
  }
  return names.join(' ');
}

export function rangeBounded(value, max) {
  return (value + max) % max;
}

export function fraction(collection, predicate) {
  return collection.filter(predicate).size / (collection.size || 1);
}

export function asPercent(_fraction) {
  return Math.round(100 * _fraction);
}

function presentBoolean(value) {
  return value ? 'Yes' : 'No';
}

export function isValidUUID(value) {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return value && uuidRegex.test(value);
}

// recursively merge - priority given to properties on source
export function deepMergeObject(target, source) {
  const _target = fromJS(target);
  const _source = fromJS(source);
  return _target.mergeDeep(_source).toJS();
}

// like interleave but keep going until both lists are exhausted
function interweave(list1, list2) {
  const [longest, shortest] = list1.size > list2.size ? [list1, list2] : [list2, list1];

  return list1.interleave(list2).concat(longest.slice(shortest.size));
}

const EMAIL_REGEX = /\b(?:[^\s]+@[^\s]+\.[^\s]+)\b/gi;

export function expandMailtos(text) {
  const matches = text.match(EMAIL_REGEX);

  if (!matches) {
    return text;
  }

  const chunks = List(text.split(EMAIL_REGEX));
  const links = List(matches).map((match, idx) =>
    <a className="underlined" key={`match${idx}`} href={`mailto:${match}`}>{match}</a>
  );

  return <span>{interweave(chunks, links)}</span>;
}

const URL_REGEX = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;

export function linkify(text) {
  return text.split(' ').map((chunk, idx) =>
    URL_REGEX.test(chunk) ? (
      <a className="underlined" key={idx} href={chunk} target="_blank">
        {chunk}{' '}
      </a>
    ) : (
      chunk + ' '
    )
  );
}

// 2.3 is newer than 2.1.4; etc
export function isNewerVersion(version0, version1) {
  const [version0parts, version1parts] = [version0, version1].map(version => String(version).split('.'));
  const firstDifferentPart = List(version0parts).zip(version1parts).find(([v0part, v1part]) => v0part !== v1part);

  if (!firstDifferentPart) {
    return version0parts.length > version1parts.length;
  }

  return parseInt(firstDifferentPart[0], 10) > parseInt(firstDifferentPart[1], 10);
}

export const promptBefore = func => () => window.confirm('Are you sure?') && func();

export function makeStaticPath(path) {
  return window.STATIC_URL + (window.STATIC_MANIFEST[path] || path);
}

export const formatDateOrNull = (date, format, nullRepresentation = null) =>
  date ? moment(date).format(format) : nullRepresentation;

export const getLaterMoment = (m1, m2) => m1.isBefore(m2) ? m2 : m1;

export function isPracticesGroupAdmin(practice, userId) {
  return practice.getIn(['group', 'group_admins'], List()).contains(userId);
}

export function isGroupDocument(practice, profile) {
  return practice &&
    practice.getIn(['group', 'is_using_group_documents']) &&
    isPracticesGroupAdmin(practice, profile.get('id'));
}

const ALLOWED_EXTERNAL_ORIGINS = [
  's3.amazonaws.com'
];

export function handleOnBeforeUnload(event) {
  const triggeringElement = event.target.activeElement;
  if (triggeringElement && triggeringElement.tagName === 'A' && triggeringElement.origin) {
    if (ALLOWED_EXTERNAL_ORIGINS.some(o => triggeringElement.origin.includes(o))) {
      // Don't prompt the user if we're navigating to an allowed origin.
      return undefined;

    }
  }
  return 'leaving the page';
}

export function convertRateToPercentage(rate) {
  if (typeof rate === 'number') {
    return Math.round(rate * 100);
  }
  return null;
}

export const getRateColourClassNames = (rate, thresholdBad, thresholdOk) => ({
  bad: rate <= thresholdBad,
  ok: thresholdBad < rate && rate <= thresholdOk,
  good: rate > thresholdOk
});

const momentFormat = format => moment.utc().format(format);
export const MOMENT_UTC_TODAY = momentFormat(FORMAT_DATE_BACKEND);
export const MOMENT_UTC_A_YEAR_AGO = moment.utc().subtract(1, 'year').format(FORMAT_DATE_BACKEND);

export const getMomentUtcStartOfLastWeek = () =>
  moment()
    .utc()
    .subtract(1, 'week')
    .startOf('isoWeek')
    .format(FORMAT_DATE_BACKEND);
export const getMomentUtcToday = () =>
  moment()
    .utc()
    .format(FORMAT_DATE_BACKEND);

export const GROUPS_SECTION_PREFIX = '/page/groups/group-documents/';
export const PRACTICES_SECTION_PREFIX = '/page/documents/';

export function isGroupsAppSection(currentPathname) {
  return currentPathname.includes(GROUPS_SECTION_PREFIX);
}

export function getAppSectionPathPrefix(currentPathname) {
  return isGroupsAppSection(currentPathname) ?
  GROUPS_SECTION_PREFIX :
  PRACTICES_SECTION_PREFIX;
}

export function isEditingGroupDocument(currentPathname) {
  return Boolean(isGroupsAppSection(currentPathname) || currentPathname.includes('/group/'));
}

export const getPlaceholderImage = () => makeStaticPath('images/icons/placeholder-image.png');

export function formatValue(map, key, format, def = '--') {
  const value = map.getIn(key);
  switch (format) {
    case 'logo':
      return value || getPlaceholderImage();
    case 'date':
      return formatDate(value) || def;
    case 'timeNoDate':
      return formatTimeNoDate(value) || def;
    case 'boolean':
      return presentBoolean(value) || def;
    default:
      return value || def;
  }
}

export function filterObject(obj, predicate) {
  return fromJS(obj).filter(predicate).toJS();
}

export function isLoggedIn(state) {
  return Boolean(state.user && state.user.get('loggedInUserId'));
}

export function makeBackLink(
  activityId,
  templateDocumentVersion,
  isGroupsApp,
  appSectionPath
) {
  if (activityId) {
    return {
      path: `/page/activities/${activityId}/`,
      label: 'activity',
    };
  }

  const copiedDocument = templateDocumentVersion.get(
    'copied_template_document'
  );
  if (copiedDocument) {
    const baseDoc =
      copiedDocument.get('template_document') ||
      copiedDocument.get('group_template_document');
    return {
      path: getDocumentLink(baseDoc.get('id')) + 'copies/',
      label: 'copies list',
    };
  }

  const groupDocument = templateDocumentVersion.get(
    'group_template_document'
  );
  return {
    path: appSectionPath + (groupDocument && !isGroupsApp ? 'group/' : ''),
    label: 'documents',
  };
}

export function removeMembersAlreadyAdded(documentVersionReadRequests, allPracticeMembers) {
  const membersAlreadySentDocReadRequest = documentVersionReadRequests.map(
    (member) => member.get('team_member_id')
  );

  return allPracticeMembers.filter(
    (member) => !membersAlreadySentDocReadRequest.includes(member.getIn(['user', 'id']))
  );
}

export function isDocumentReadByAllMembers(documentReadRequest) {
  return documentReadRequest.get('number_of_read') === documentReadRequest.get('number_of_requests');
}

export function toTitleCase(string) {
  return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
}

export function recordTypeUrlToRecordType(recordTypeUrl) {
  return recordTypeUrl && recordTypeUrl.toUpperCase();
}

export function removeDuplicateDates(activitiesAndTasks) {
  return activitiesAndTasks.map((activityOrTask, index) => {
    if (index >= 1 && activitiesAndTasks[index].date === activitiesAndTasks[index - 1].date) {
      return {...activityOrTask, displayDate: ''}
    }
    return activityOrTask;
  })
}

export function getDueDateText(activityOrTask, date) {
  if (isComplete(activityOrTask)) return "Completed";

  const dueDate = moment(date);
  const today = moment();
  const dateDiff = today.diff(dueDate, 'days');

  return dateDiff <= 0 ?
    `${Math.abs(dateDiff)} days till due date` :
    `${Math.abs(dateDiff)} days overdue`;
}

export function getDueDateForDocumentReview(status, documentReviewScheduledCompletionDate) {
  if (status === DOCUMENT_REVIEW_STATUSES.COMPLETED) return "Completed";

  const dueDate = moment(documentReviewScheduledCompletionDate);
  const today = moment();
  const dateDiff = today.diff(dueDate, 'days');

  return dateDiff <= 0 ?
    `${Math.abs(dateDiff)} days till due date` :
    `${Math.abs(dateDiff)} days overdue`;
}

export function getActivitiesDisplayData(activities) {
  return activities.map((activity) => ({
    id: activity.get('id'),
    date: activity.get('due_date'),
    displayDate: moment(activity.get('due_date')).format('ddd DD MMM'),
    title: activity.get('title'),
    priority: activity.get('priority'),
    assignees: activity.getIn(['responsible_staff', 'full_name']),
    type: activity.get('type'),
    status: getDueDateText(activity, activity.get('due_date')),
    isOverdue: isOverdue(activity),
    isComplete: isComplete(activity),
    activity,
  }));
}

export function getTasksDisplayData(tasks) {
  return tasks.map((task) => ({
    id: task.get('id'),
    date: task.get('deadline'),
    displayDate: moment(task.get('deadline')).format('ddd DD MMM'),
    title: task.get('title'),
    priority: '',
    assignees: task.get('assignees').map(assignee => assignee.get('full_name')).join(', '),
    type: ACTIVITY_TYPE.TASK,
    status: getDueDateText(task, task.get('deadline')),
    isOverdue: isOverdue(task),
    isComplete: isComplete(task),
    task,
  }));
}

export function getDocumentReviewsDisplayData(documentReviews) {
  return documentReviews.map((documentReview) => ({
    id: documentReview.get('adopted_template_document_id'),
    date: documentReview.get('scheduled_completion_date'),
    displayDate: moment(documentReview.get('scheduled_completion_date')).format('ddd DD MMM'),
    title: documentReview.get('adopted_template_document_name'),
    priority: documentReview.get('priority'),
    assignees: '',
    type: ACTIVITY_TYPE.DOCUMENT_REVIEW,
    status: getDueDateForDocumentReview(documentReview.get('status'), documentReview.get('scheduled_completion_date')),
    isOverdue: documentReviewIsOverdue(documentReview),
    isComplete: documentReview.get('status') === DOCUMENT_REVIEW_STATUSES.COMPLETED,
    documentReview,
  }));
}

export function getActivityCalendarEventPopupData(activity) {
  return {
    title: activity.get('title'),
    type: activity.get('type'),
    priority: activity.get('priority', ''),
    assignees: activity.getIn(['responsible_staff', 'full_name']),
    status: getDueDateText(activity, activity.get('due_date')),
    dueDate: moment(activity.get('due_date')).format('Do MMM'),
    isOverdue: isOverdue(activity),
    isComplete: isComplete(activity),
  };
}

export function getTaskCalendarEventPopupData(task) {
  return {
    title: task.get('title'),
    type: ACTIVITY_TYPE.TASK,
    groupTask: task.get('group_task_id'),
    priority: '',
    assignees: task.get('assignees').map(assignee => assignee.get('full_name')).join(', '),
    status: getDueDateText(task, task.get('deadline')),
    dueDate: moment(task.get('deadline')).format('Do MMM'),
    isOverdue: isOverdue(task),
    isComplete: isComplete(task),
  };
}
export const convertDurationToSeconds = (mins, secs) => {
  return parseInt(mins, 10) * 60 + parseInt(secs, 10);
};

export const convertDurationFromSeconds = secs => {
  return { minutes: Math.floor(secs/60), seconds: secs % 60 };
};

export const convertStringToFilterString = (str) => {
  const FILTERMAP = {
    "Status": "event__status",
    "Type of Event": "event__type",
    "Date of Event": "created_before",
    "Lead Team Member": "responsible_staff"
  }

  return FILTERMAP[str];
}

export const createSlugFromString = (str) => {
  return str
  .toLowerCase()
  .trim()
  .replace(/[^\w\s-]/g, '')
  .replace(/[\s_-]+/g, '_')
  .replace(/^-+|-+$/g, '');
}

export const getDocumentReviewTitle = (documentReview) => {
  if (!documentReview) {
    return 'Create First Review';
  }

  return documentReview.get('can_complete_now') ?
    `Mark As Reviewed – ${formatDate(documentReview.get('scheduled_completion_date'))}` :
    'Create ad-hoc review';
};

export function filterHideRulesBySector(templateActivity, fieldConfig) {
  const sector = templateActivity.get('sector');

  return fieldConfig.updateIn(
   ['attribute', 'choices'],
   choices => choices.filter(choice => choice.get('sectors').includes(sector))
 );
}

export function transformHideRuleChoices(templateDocument, fieldConfig) {
  if (!templateDocument) {
    return fieldConfig;
  }

  const valueDropdownConfig = fromJS({
    required: true,
    type: 'choice',
    choices: [
      {
        display_name: 'True',
        value: true,
      },
      {
        display_name: 'False',
        value: false,
      },
    ]
  });

  const currentRules =
    templateDocument
      .get('hide_rules')
      .map(rule => rule.get('attribute'));

  // turn attribute_value into choices field (rather than boolean checkbox), then
  // filter out any rule choices that are already applied to the activity
  return filterHideRulesBySector(
    templateDocument,
    fieldConfig
      .update('attribute_value', config => config.merge(valueDropdownConfig))
      .updateIn(
        ['attribute', 'choices'],
        choices => choices.filterNot(choice => currentRules.includes(choice.get('value')))
      )
  );
}
