import React from 'react';

const Notes = ({value, emptyMessage}) => (
  value ? (
    <p className="completion-notes">
      {value.split('\n').map((line, idx) =>
        <span key={idx}>
          {line}<br />
        </span>
      )}
    </p>
  ) : (
    <p className="hint">{emptyMessage}</p>
  )
);

export default Notes;
