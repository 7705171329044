import React, { Component } from 'react';
import { RouteComponentProps, Link } from 'react-router';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import AuditList from '^/components/app/digital-tools/audit/list';
import Loading from '^/components/app/content/Loading';
import { getAuditTypes } from '^/actions/audit';
import { AuditTypeWithCount } from '^/components/app/digital-tools/audit/types';
import { StoreState } from '^/store/types';

type RouteProps = RouteComponentProps<{}, {}>;

type DispatchProps = {
  getAuditTypes: typeof getAuditTypes;
  routeReplace: typeof routeActions.replace;
};

type StateProps = {
  auditTypes: readonly AuditTypeWithCount[];
  isLoading: boolean;
};

type Props = RouteProps & DispatchProps & StateProps;

class AuditListPage extends Component<Props, {}> {
  public componentDidMount() {
    this.props.getAuditTypes();
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.auditTypes !== this.props.auditTypes &&
      !this.props.location.query.type &&
      this.props.auditTypes.length
    ) {
      this.props.routeReplace(
        `/page/tools/audit/?type=${this.props.auditTypes[0].id}`
      );
    }
  }

  public render() {
    const {
      location: {
        query: { type },
        pathname,
      },
      isLoading,
    } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div className="row mt-2">
        <div className="audits-side-nav col-1-3">
          {this.props.auditTypes.map((auditType, idx) => (
            <div key={idx}>
              {(auditType.practice_audit_count > 0 ||
                auditType.allow_new_creation) && (
                <Link
                  to={{
                    pathname,
                    query: { type: auditType.id },
                  }}
                  key={idx}
                >
                  <div
                    className={`${
                      auditType.id === type ? 'selected nav-item' : 'nav-item'
                    }`}
                  >
                    {auditType.name}
                  </div>
                </Link>
              )}
            </div>
          ))}
        </div>
        <div className="col-2-3">{type && <AuditList type={type} />}</div>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  auditTypes: state.audit.auditTypes,
  isLoading: state.thunkRequests.getAuditTypes.isLoading,
});

export default connect<StateProps, DispatchProps, RouteProps>(mapStateToProps, {
  getAuditTypes,
  routeReplace: routeActions.replace,
})(AuditListPage);
