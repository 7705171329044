import React from 'react';
import { Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { getCollection } from '^/actions/collections';
import { PRACTICES } from '^/consts/collectionKeys';
import { setupNewCycleRedirectToSetupAndSetCurrentPractice } from '^/actions/actionSequences';
import { closeModal } from '^/actions/modals';
import { hasSucceeded } from '^/consts/responseStates';

import SetupCycleDateForm from '^/components/app/users/profile/my-practices/SetupCycleDateForm';
import PureComponent from '^/components/common/PureComponent';


class SetupCycleDateFormModal extends PureComponent {

  componentWillUpdate(newProps) {
    if (hasSucceeded(newProps.response)) {
      // refetch practices and close modal once setup has begun.
      // Not possible to chain in the action sequence
      this.props.getCollection(PRACTICES);
      this.props.closeModal();
    }
  }

  onSubmit(data) {
    const { practice } = this.props;
    this.props.setupNewCycleRedirectToSetupAndSetCurrentPractice(practice.get('id'), data.start_date);
  }

  render() {
    const { response } = this.props;

    return <SetupCycleDateForm
      onSubmit={this.onSubmit.bind(this)}
      response={response}
           />;
  }
}

SetupCycleDateFormModal.propTypes = {
  response: ImmutablePropTypes.map.isRequired,
  practice: ImmutablePropTypes.map.isRequired
};

function mapStateToProps(state) {
  return {
    response: state.responses.get('setupNewCycle', Map())
  };
}

export default connect(
  mapStateToProps,
  {
    setupNewCycleRedirectToSetupAndSetCurrentPractice,
    closeModal,
    getCollection,
  }
)(SetupCycleDateFormModal);
