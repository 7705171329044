import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Icon from '^/components/app/content/Icon';
import classNames from 'classnames';

import { REPEAT_CHOICES } from '^/models/task';
import {
  getActivityCalendarEventPopupData,
  getTaskCalendarEventPopupData,
  getDueDateForDocumentReview,
} from '^/utils';
import MaybeLink from '^/components/app/content/MaybeLink';
import ActivityTypeCapsule from '^/components/app/content/ActivityTypeCapsule';
import { ACTIVITY_TYPE, getActivityTypeText, documentReviewIsOverdue } from '^/models/activities';
import { PRIORITY_CLASSES } from '^/models/constants';
import ActivityLink from './ActivityLink';
import TaskLink from './TaskLink';
import Capsule from '^/components/app/content/Capsule';
import { CALENDAR_EVENT_TYPE } from './CalendarEvent';

const TaskCalendarPopup = ({ task, onClose }) => {
  const calendarData = getTaskCalendarEventPopupData(task);

  const {
    title,
    type,
    priority,
    dueDate,
    status,
    isOverdue,
    assignees,
    isComplete,
    groupTask,
  } = calendarData;

  const taskTypeText = (groupTask ? 'Group ' : '') + type;

  return (
    <div>
      <div className="title">
        <h4>
          <TaskLink task={task}>
            <span
              className={classNames('event-popup-title', {
                complete: isComplete,
              })}
            >
              {title}
            </span>
          </TaskLink>
          <div className="close" onClick={onClose}>
            <Icon type="close" className="small" />
          </div>
        </h4>
      </div>
      <div className="display-flex mb-1-2">
        <Capsule
          className={
            groupTask
              ? 'mr-1-2 calendar-capsule capsule-group-task'
              : 'mr-1-2 calendar-capsule capsule-task'
          }
          type={type}
        />
        {taskTypeText}
        <div className="ml-1-2">{priority}</div>
        {task.get('repeat') && task.get('repeat') !== REPEAT_CHOICES.NONE && (
          <Icon
            type="repeat"
            className="repeat-icon inline small-icon gray-icon ml-1-2"
            title="repeating task"
          />
        )}
      </div>
      <div>
        <Icon type="calendar-o" className="metadata-icon" />
        {`Due: ${dueDate} (${status})`}
        {isOverdue && (
          <Icon type="exclamation" className="step-icon small outstanding" />
        )}
        <Icon type="user" className="ml-1-2 metadata-icon" />
        {assignees}
      </div>
    </div>
  );
};

const ActivityCalendarPopup = ({ activity, onClose }) => {
  const calendarData = getActivityCalendarEventPopupData(activity);

  const { title, priority, type, dueDate, status, isOverdue, assignees, isComplete } = calendarData;

  const activityTypeText = getActivityTypeText(calendarData.type);

  return (
    <div>
      <div className="title">
      <h4>
        <ActivityLink
          activity={activity}
          hideIfReadOnly
        >
          <span className={classNames('event-popup-title', {"complete": isComplete})}>
            {title}
          </span>
        </ActivityLink>
        <div className="close" onClick={onClose}>
          <Icon type="close" className="small" />
        </div>
      </h4>
    </div>
    <div className="display-flex mb-1-2">
      <ActivityTypeCapsule
        className="mr-1-2 calendar-capsule"
        type={type}
      />
      {activityTypeText}
      <div className="ml-1-2">{priority}</div>
    </div>
    <div>
      <Icon type="calendar-o" className="metadata-icon" />
      {`Due: ${dueDate} (${status})`}{isOverdue && <Icon type="exclamation" className="step-icon small outstanding" />}
      <Icon type="user" className="ml-1-2 metadata-icon" />
      {assignees}
    </div>
  </div>
  );
}

const DocumentReviewCalendarPopup = ({ documentReview, onClose }) => {
  const documentReviewPriority = documentReview.get('priority');
  const dueDate = documentReview.get('scheduled_completion_date');
  const status = documentReview.get('status');
  const dueDateForDocumentReviewText = getDueDateForDocumentReview(status, dueDate);

  return (
    <div>
      <div className="title">
        <h4>
          <MaybeLink
            to={`/page/documents/${documentReview.get('adopted_template_document_id')}/`}
          >
            <span className={classNames('event-popup-title')}>
              {documentReview.get('adopted_template_document_name')}
            </span>
          </MaybeLink>
          <div className="close" onClick={onClose}>
            <Icon type="close" className="small" />
          </div>
        </h4>
      </div>
      <div className="display-flex mb-1-2">
        <ActivityTypeCapsule
          className="mr-1-2 calendar-capsule"
          type={ACTIVITY_TYPE.DOCUMENT_REVIEW}
        />
          Document Review
        <div className={`ml-1-2 actvities-priority-pill ${PRIORITY_CLASSES[documentReviewPriority]}`}>
          {documentReviewPriority}
        </div>
      </div>
      <div>
        <Icon type="calendar-o" className="metadata-icon" />
        {`Due: ${dueDate} (${dueDateForDocumentReviewText})`}{documentReviewIsOverdue(documentReview) && <Icon type="exclamation" className="step-icon small outstanding" />}
      </div>
    </div>
  );
}

const CalendarEventPopup = ({ type, object, x, y, onClose }) => {
  const getCalendarEventTypePopup = () => {
    switch (type) {
      case CALENDAR_EVENT_TYPE.ACTIVITY:
        return <ActivityCalendarPopup activity={object} onClose={onClose} />;
      case CALENDAR_EVENT_TYPE.TASK:
        return <TaskCalendarPopup task={object} onClose={onClose} />;
      case CALENDAR_EVENT_TYPE.DOCUMENT_REVIEW:
        return <DocumentReviewCalendarPopup documentReview={object} onClose={onClose} />;
      default:
        return <p>None found</p>;
    }
  }

  return (
    <div className="event-popup" style={{ left: x + 10, top: y - 10 }}>
      {getCalendarEventTypePopup()}
    </div>
  );
};

CalendarEventPopup.propTypes = {
  onClose: React.PropTypes.func.isRequired,
  object: ImmutablePropTypes.map.isRequired,
};

export default CalendarEventPopup;
