import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import LiveButton from '^/components/app/forms/LiveButton';
import PureComponent from '^/components/common/PureComponent';

import { archiveChecklistAndCloseModal } from '^/actions/actionSequences';

export class ArchiveChecklistConfirmationModal extends PureComponent {
  render() {
    const { onClose, recordGroupId } = this.props;
    return (
      <div className="text-center">
        <div className="leaving-modal-list">
          <p className="mt-1">
            Are you sure you want to archive this checklist?
          </p>
          <p className="mt-1">
            Archiving this Checklist will remove the Checklist from the list and you will no longer be prompted to fill this in.
            Do you wish to Archive this Checklist?
          </p>
        </div>
        <div className="leaving-modal-buttons">
          <LiveButton
            className="btn btn-leaving-modal inline-spaced"
            onClick={() => this.props.archiveChecklistAndCloseModal(recordGroupId)}
          >
            Archive
          </LiveButton>
          <LiveButton
            className="btn btn-leaving-modal inline-spaced"
            onClick={onClose}
          >
            Cancel
          </LiveButton>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  onClose: closeModal,
  archiveChecklistAndCloseModal,
})(ArchiveChecklistConfirmationModal);
