import React from 'react';

import { default as BaseSortIcon } from '^/components/common/content/SortIcon';

export const SortIcon = ({
  insight,
  onClick,
  ...restOfProps
}) => (
  <BaseSortIcon
    {...restOfProps}
    className="insights-sort-icon"
    onClick={(itemKey, isAscending) => onClick(insight, itemKey, isAscending)}
  />
);

SortIcon.propTypes = {
  insight: React.PropTypes.string.isRequired,
  itemKey: React.PropTypes.string.isRequired,
  isSorted: React.PropTypes.bool.isRequired,
  isAscending: React.PropTypes.bool,
  onClick: React.PropTypes.func.isRequired
};

export default SortIcon;
