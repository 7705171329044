import React from 'react';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';
import { MODELS } from '^/permissions/capabilities';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';
import PureComponent from '^/components/common/PureComponent';

import {
  ADMIN_PRACTICE_GROUP_CREATE,
  ADMIN_PRACTICE_GROUP_MODEL,
  ADMIN_PRACTICE_GROUP_EDIT_FIELDS,
  ADMIN_PRACTICE_GROUP_VIEW_FIELDS,
  ADMIN_PRACTICE_GROUP_ROW_FIELDS,
} from './practice';


class PracticeGroupsAdmin extends PureComponent {
  render() {
    return (
      <AdminContainer title="Practice Groups">
        <AdminCreate
          permsModel={MODELS.PRACTICE}
          readOnlyFields={ADMIN_PRACTICE_GROUP_VIEW_FIELDS}
          controlName={ADMIN_PRACTICE_GROUP_CREATE}
          collectionName={ADMIN_PRACTICE_GROUP_MODEL}
          model={ADMIN_PRACTICE_GROUP_MODEL}
          fields={ADMIN_PRACTICE_GROUP_EDIT_FIELDS}
          title="Add Practice Group" className="btn-left-align"
        />

        <AdminList
          permsModel={MODELS.PRACTICE}
          model={ADMIN_PRACTICE_GROUP_MODEL}
          fields={ADMIN_PRACTICE_GROUP_ROW_FIELDS}
        />
      </AdminContainer>
    );
  }
}

export default connect(undefined, { routePush: routeActions.push })(PracticeGroupsAdmin);
