import React from 'react';
import { List } from 'immutable';

import Icon from '^/components/app/content/Icon';

const activityTagText = {
  'CQC': 'CQC',
  'NHS': 'NHS',
  'GDC': 'GDC',
  'CLINICAL': 'Clinical',
  'HSE': 'HSE',
  'HIW': 'HIW',
  'MHRA': 'MHRA',
  'SAFETY_NET': 'Safety Net',
  'ICO': 'ICO',
  'BEST_PRACTICE': 'Best Practice',
  'OTHER': 'Other',
  'NICE': 'NICE',
  'DOUBLE_CHECK': 'Double Check',
  'HIGHLY_RECOMMENDED': 'Highly Recommended',
  'OTHER_COMPLIANCE': 'Other Compliance',
};


export const ActivityTags = ({activity}) => {
  const tags = activity.get('tags', []);
  const tagsList = typeof tags === 'string' ? List([tags]) : tags;
  const firstTag = (tagsList.length > 0) ? (tagsList.get(-1)) : "";
  const remainingTags = (tagsList.length > 1) ? tagsList.pop() : [];


  return (
    <span>
        {firstTag && <span className="inline"><Icon type="tag" className="inline-icon smaller-mr" />{activityTagText[firstTag]}</span>}
        <span>
          {remainingTags && remainingTags.map((tag, index)=> <span key={index}>{', '}{activityTagText[tag]}</span>)}
        </span>
    </span>
  );
};
