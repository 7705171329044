import React from 'react';

import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEditCreate from '^/components/admin/content/edit-create/AdminEditCreate';
import AdminList from '^/components/admin/content/list/AdminList';
import PureComponent from '^/components/common/PureComponent';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';

import {
  ADMIN_DISCOUNT_CODES_MODEL,
  ADMIN_DISCOUNT_CODES_ROW_FIELDS,
  ADMIN_DISCOUNT_CODES_CREATE_FIELDS,
} from '^/components/admin/discount-codes/discount-code';

class DiscountCodesAdmin extends PureComponent {
  render() {
    return (
      <div>
        <AdminContainer title="Discount Codes">
          <AdminEditCreate
            model={ADMIN_DISCOUNT_CODES_MODEL}
            fields={ADMIN_DISCOUNT_CODES_CREATE_FIELDS}
            title="Create new discount code"
          />
        </AdminContainer>

        <AdminContainer>
          <AdminList
            model={ADMIN_DISCOUNT_CODES_MODEL}
            fields={ADMIN_DISCOUNT_CODES_ROW_FIELDS}
          />
        </AdminContainer>
      </div>
    );
  }
}

export default connect(undefined, { routePush: routeActions.push })(DiscountCodesAdmin);
