import React from 'react';

import AdminContainer from '^/components/admin/content/AdminContainer';
import PureComponent from '^/components/common/PureComponent';
import { connect } from 'react-redux';
import { getExportTables } from '^/actions/actions';
import { exportTable } from '^/actions/actionSequences';
import { isPending } from '^/consts/responseStates';
import ExportForm from './ExportForm';
import Loading from '^/components/app/content/Loading';

export class ExportAdmin extends PureComponent {
  constructor() {
    super();
    this.renderForm = this.renderForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getExportTables();
  }

  handleSubmit(data) {
    if (data.table === '--' || !data.table) {
      return;
    }
    this.props.exportTable(data.table);
  }

  renderForm() {
    if (isPending(this.props.optionsResponse)) {
      return (<Loading />);
    }
    return (
      <ExportForm
        tables={this.props.tables}
        response={this.props.optionsResponse}
        disabled={isPending(this.props.response)}
        onSubmit={this.handleSubmit}
      />
    );
  }

  render() {
    return (
      <div>
        <AdminContainer className="mb-2" title="Export Table">
          { this.renderForm() }
        </AdminContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tables: state.exportTables.get('tables'),
    optionsResponse: state.responses.get('exportTableOptions'),
    response: state.responses.get('exportTableUrl')
  };
}

export default connect(mapStateToProps, { getExportTables, exportTable })(ExportAdmin);
