import React from 'react';

import { makeStaticPath } from '^/utils';
import LiveButton from '^/components/app/forms/LiveButton';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';


export class ReassignConfirmationModal extends PureComponent {
  render() {
    const { onReassign, onClose, loading } = this.props;
    return (
      <div className="text-center">
        <div className="brand-logo-modal">
          <img src={makeStaticPath('images/brand/logo-color.svg')} className="brand-logo-modal" />
        </div>
        <div className="leaving-modal-list">
          <p className="mt-1">
            Are you sure you want to reassign this document?
          </p>
          <p className="mt-1">
            Changing the owner will remove your ownership of the document.
          </p>
        </div>
        {loading ? (<Loading />) : (
          <div className="leaving-modal-buttons">
            <LiveButton
              className="btn btn-leaving-modal inline-spaced"
              onClick={onReassign}
            >
              Change Owner
            </LiveButton>
            <LiveButton
              className="btn btn-leaving-modal inline-spaced"
              onClick={onClose}
            >
              Cancel
            </LiveButton>
          </div>
        )}
      </div>
    );
  }
}

export default (ReassignConfirmationModal);
