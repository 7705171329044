import { ConnectHOC } from '@dabapps/connect-hoc';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import ProgressBar from '^/components/app/healthcheck/progress-bar';
import { StoreState } from '^/store/types';
import Loading from '^/components/app/content/Loading';
import NonFieldErrorRenderer from '^/components/common/NonFieldErrorRenderer';
import {
  selectCategoryIndexById,
  selectPrevCategoryId,
  selectNextCategoryId,
  selectAuditById,
} from '^/selectors/audit';
import { selectNonImmutableUserProfile } from '^/selectors/healthcheck';
import { clearThunkRequestErrors } from '^/actions/healthcheck';
import {
  AuditResponse,
  AuditCategoriesMerged,
  AuditCategoryMerged,
  BaseItem,
} from '^/components/app/digital-tools/audit/types';
import {
  updateAuditStatusIfMatchAndContinue,
  getAuditById,
} from '^/actions/audit';
import ProgressListComponent from '^/components/app/digital-tools/audit/progress-list';
import { AUDIT_CLIENT_URL_BASE } from '^/consts/audit';
import { cachedItemIsStale } from '^/components/app/healthcheck/utils';
import { CachedItem } from '^/components/app/healthcheck/types';
import { CategoryOptionsAudit } from './types';
import createQuestionRenderer from '^/components/app/digital-tools/audit/question-renderer';
import DeleteAudit from '^/components/app/digital-tools/audit/delete';
import { openModal } from '^/actions/modals';
import { AUDIT_STAGE_CONFIRM_MODAL } from '^/modals/ids';

const connectHOC = connect as ConnectHOC;

interface StateProps<T extends BaseItem> {
  practiceName: string | undefined;
  isLoading: boolean;
  errors: unknown;
  categoryIndex: number | undefined;
  category: AuditCategoryMerged<T> | undefined;
  categories: AuditCategoriesMerged<T> | null;
  audit: CachedItem<AuditResponse> | null;
  nextCategoryId: string | null;
  prevCategoryId: string | null;
}

interface DispatchProps {
  fetchCategoryItems: (healthcheckId: string, categoryId: string) => void;
  navigateToCategory: (healthcheckId: string, categoryId: string) => void;
  updateAuditStatusIfMatchAndContinue: typeof updateAuditStatusIfMatchAndContinue;
  clearThunkRequestErrors: typeof clearThunkRequestErrors;
  getAuditById: typeof getAuditById;
  openModal: typeof openModal;
}

type RouteProps = RouteComponentProps<
  { auditId: string; categoryId: string; correct?: string },
  {}
>;

type Props<T extends BaseItem> = StateProps<T> & DispatchProps & RouteProps;

const createCategoryPage = <T extends BaseItem>(
  options: CategoryOptionsAudit<T>
) => {
  const pushToCategory = (healthcheckId: string, categoryId: string) =>
    push(
      `${AUDIT_CLIENT_URL_BASE}${healthcheckId}${options.urlPart}${categoryId}`
    );
  const QuestionRenderer = createQuestionRenderer<T>();

  class AuditCategoryPage extends Component<Props<T>, {}> {
    public componentDidMount() {
      const {
        audit,
        params: { auditId, categoryId },
      } = this.props;

      if (categoryId) {
        this.props.fetchCategoryItems(auditId, categoryId);
      }

      if (!audit || cachedItemIsStale(audit)) {
        this.props.getAuditById(auditId);
      }
    }

    public componentDidUpdate(prevProps: Props<T>) {
      const { auditId, categoryId } = this.props.params;
      if (prevProps.params.categoryId !== categoryId) {
        options.requestKeys.map(requestKey =>
          this.props.clearThunkRequestErrors(requestKey)
        );
        this.props.fetchCategoryItems(auditId, categoryId);
      }
    }

    public render() {
      const {
        isLoading,
        category,
        practiceName,
        audit,
        prevCategoryId,
        nextCategoryId,
        params,
        categories,
      } = this.props;

      const { itemRenderer } = options;

      if (isLoading) {
        return (
          <div className="healthcheck">
            <Loading />
          </div>
        );
      }

      if (!audit) {
        return (
          <div className="healthcheck">
            <NonFieldErrorRenderer errors="Failed to get active audit" />
          </div>
        );
      }

      return (
        <div className="healthcheck">
          <div className="main">
            <h1>
              <span className="bold">{audit.audit_type.name}</span>
              <span className="ml-1">{practiceName}</span>
            </h1>
            <hr className="thin" />
            <NonFieldErrorRenderer errors={this.props.errors} />
            <p className="content mb-1">
              {category ? options.content : options.noContent}
            </p>
            <h3>
              {category?.name}
              {category?.subtitle && (
                <p className="subtitle">{`Standard: ${category?.subtitle}`}</p>
              )}
            </h3>
            <div>
              <h4>{options.header}</h4>
              <QuestionRenderer
                splitByCorrect={options.splitByCorrect}
                category={category}
                itemRenderer={itemRenderer}
                auditId={params.auditId}
                correct={params.correct}
              />
              <div className="button-group">
                {category && (
                  <button
                    className="btn btn-secondary"
                    onClick={this.onPrevClick}
                    disabled={!prevCategoryId}
                  >
                    {'<'} Previous
                  </button>
                )}
                <button className="btn btn-save" onClick={this.onNextClick}>
                  {nextCategoryId ? 'Next >' : `Complete ${options.type} stage`}
                </button>
              </div>
            </div>
          </div>

          <div className="aside">
            <ProgressBar item={audit} />
            <ProgressListComponent
              type={options.type}
              selectedCategoryId={params.categoryId}
              categories={categories}
              urlPart={options.urlPart}
              id={params.auditId}
              isAuditWorkflow={options.auditWorkflow}
            />
            <DeleteAudit id={params.auditId} />
          </div>
        </div>
      );
    }

    private onNextClick = () => {
      const { params, nextCategoryId, audit } = this.props;

      if (nextCategoryId) {
        this.props.navigateToCategory(params.auditId, nextCategoryId);
      } else if (audit) {
        const callback = () =>
          this.props.updateAuditStatusIfMatchAndContinue(
            audit,
            options.intendedFromStatus
          );
        this.props.openModal({
          id: AUDIT_STAGE_CONFIRM_MODAL,
          props: {
            callback,
            auditId: audit.id,
          },
        });
      }
    };

    private onPrevClick = () => {
      const { params, prevCategoryId } = this.props;
      if (prevCategoryId) {
        this.props.navigateToCategory(params.auditId, prevCategoryId);
      }
    };
  }

  const mapStateToProps = (
    state: StoreState,
    props: RouteProps
  ): StateProps<T> => {
    const categories = options.getCategories(state);

    const categoryIndex = selectCategoryIndexById(
      categories,
      props.params.categoryId
    );

    return {
      practiceName: selectNonImmutableUserProfile(state)?.staffdetail
        .current_practice.name,
      isLoading: options.getIsLoading(state),
      errors: options.getErrors(state),
      categoryIndex,
      category:
        typeof categoryIndex !== 'undefined' && categoryIndex >= 0
          ? categories?.[categoryIndex]
          : undefined,
      categories,
      audit: selectAuditById(state, props.params.auditId),
      nextCategoryId: selectNextCategoryId(categories, props.params.categoryId),
      prevCategoryId: selectPrevCategoryId(categories, props.params.categoryId),
    };
  };

  return connectHOC<StateProps<T>, DispatchProps, RouteProps, StoreState>(
    mapStateToProps,
    {
      fetchCategoryItems: options.fetchCategoryAction,
      navigateToCategory: pushToCategory,
      updateAuditStatusIfMatchAndContinue,
      clearThunkRequestErrors,
      getAuditById,
      openModal,
    }
  )(AuditCategoryPage);
};

export default createCategoryPage;
