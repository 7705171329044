import React from 'react';
import AdminList, { PAGE_SIZE } from '^/components/admin/content/list/AdminList';
import AdminContainer from '^/components/admin/content/AdminContainer';
import LiveButton from '^/components/app/forms/LiveButton';

import { generateHistoryReport } from '^/actions/actionSequences';
import { getCollection } from '^/actions/collections';
import { Map, fromJS } from 'immutable';
import { connect } from 'react-redux';


export const HISTORY_ROW_FIELDS = fromJS([
  {name: ['changed'], display_name: 'Changed', type: 'datetime', sortable: true, sortByName: 'history_date'},
  {name: ['user'], display_name: 'User', type: 'string', sortable: true, sortByName: 'history_user__email'},
  {name: ['description'], display_name: 'Details', type: 'string', sortable: false}
]);

const TEMPLATE_ACTIVITY_MODEL = 'TemplateActivity';

export const buildModel = (model, id) => `history/${model}/${id}`;
export const buildListName = (model) => `HISTORY_${model}`;

export function reloadChangeLog(model, id) {
  return () => getCollection(
    buildModel(model, id),
    { page: 1, pageSize: PAGE_SIZE, shouldAppend: false },
    buildListName(model)
  );
}

export function AdminChangeLog({ model, id, historyReport, response }) {
  return (
    <div>
      <AdminList
        fields={HISTORY_ROW_FIELDS}
        title="History"
        listName={buildListName(model)}
        model={buildModel(model, id)}
      />
      <AdminContainer>
        <LiveButton
          className="btn btn-primary"
          response={response}
          onClick={() => historyReport(model, id)}
        >
          Export History
        </LiveButton>
      </AdminContainer>
      {
        model === TEMPLATE_ACTIVITY_MODEL && (
          <AdminList
            fields={HISTORY_ROW_FIELDS}
            title="Legacy History"
            model={`history/${TEMPLATE_ACTIVITY_MODEL}/${id}/legacy`}
          />
        )
      }
    </div>
  );
}

AdminChangeLog.propTypes = {
  model: React.PropTypes.string.isRequired,
  id: React.PropTypes.string.isRequired,
};

export function mapStateToProps(state) {
  return {
    response: state.responses.get('getHistoryReportURL', Map())
  };
}

export default connect(mapStateToProps, {
  historyReport: generateHistoryReport
})(AdminChangeLog);
