import React from 'react';
import classNames from 'classnames';

const ScrollableTableContainer = ({children, className}) =>
  <div className={classNames('scrollable-table-container', className)}>{children}</div>;

ScrollableTableContainer.propTypes = {
  children: React.PropTypes.any,
  className: React.PropTypes.string,
};

export default ScrollableTableContainer;
