import { Component } from 'react';

import createIntroPage from './hoc/create-intro-page';
import {
  AuditStatus,
  AuditWorkflowTypes,
} from '^/components/app/digital-tools/audit/types';

class Content extends Component<{}, {}> {
  public render() {
    return null;
  }
}

export default createIntroPage({
  type: AuditWorkflowTypes.Actions,
  intendedFromStatus: AuditStatus.ACTIONS_INTRO_1,
})(Content);
