import React from 'react';

import { activeUsersInsightsConfig, FILTER_KEYS } from '^/components/app/groups/insights/constants';
import ActiveUsersTable from '^/components/app/groups/insights/active-user-count/ActiveUsersTable';
import ActiveUserCountDetails from '^/components/app/groups/insights/active-user-count/ActiveUserCountDetails';
import SubGroupFilter from '^/components/app/groups/insights/filters/SubGroupFilter';
import Header from '^/components/app/groups/insights/Header';

const ActiveUsersPage = ({ location: { pathname, query } }) => (
  <div className="col-1">
    <Header
      shouldLinkToDashboard
      subtitle="users"
      query={query}
    />
    <SubGroupFilter
      insightsConfig={activeUsersInsightsConfig}
      filterKey={FILTER_KEYS.SUB_GROUP}
      pathname={pathname}
      selectedSubGroup={query.sub_group}
      country={query.country}
    />
    <ActiveUserCountDetails />
    <ActiveUsersTable />
  </div>
);

export default ActiveUsersPage;
