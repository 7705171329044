import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import { pluralize } from '^/utils';


export const TasksLeftUndeleted = ({numberOfTasks, onClose}) => {
  return (
    <div>
      <div className="row">
        <div className="col-1">
          <p className="alert-info">
            {pluralize(
              numberOfTasks,
              'task had been worked on so was not deleted.',
              'tasks had been worked on so were not deleted.'
            )}
          </p>
        </div>
      </div>
      <div className="row align-right mt-1">
        <div className="col-1">
          <button className="btn btn-default" onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {onClose: closeModal}) (TasksLeftUndeleted);
