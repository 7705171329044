import React from 'react';
import { reduxForm } from 'redux-form';
import { formatDate } from '^/utils';

import LiveButton from '^/components/app/forms/LiveButton';

export const REASSIGN_ACTIVITIES_FORM = 'REASSIGN_ACTIVITIES_FORM';


export const ActivityField = ({fields, activity, memberChoices}) => {
  const field = fields[activity.get('id')];

  return (
    <tr>
      <td>
        <label htmlFor={activity.get('id')}>
          {activity.get('title')}
        </label>
      </td>
      <td>
        {formatDate(activity.get('end_date'))}
      </td>
      <td>
        <select id={field.name} {...field} >
          {memberChoices.map(each =>
              <option key={each.get('value')} value={each.get('value')}>
                {each.get('display')}
              </option>
          )}
        </select>
      </td>
    </tr>
  );
};

export const ReassignActivitiesForm = ({fields, activities, memberChoices, handleSubmit}) =>
  <form onSubmit={handleSubmit}>
    <div className="mt-1 small-text">
      {
        activities.size > 0 ?
        <table>
          <thead>
            <tr>
              <th>
                <strong>Activities ({activities.size})</strong>
              </th>
              <th>Due Date</th>
              <th>New Assignee</th>
            </tr>
          </thead>
          <tbody>
            {
              activities.map(each =>
                <ActivityField
                  key={each.get('id')}
                  memberChoices={memberChoices}
                  activity={each}
                  fields={fields}
                />
              )
            }
          </tbody>
        </table>
        : <p>No activities to reassign.</p>
      }
      <LiveButton className="btn-default mt-1">
        Next...
      </LiveButton>
    </div>
  </form>;

export default reduxForm({ form: REASSIGN_ACTIVITIES_FORM })(ReassignActivitiesForm);

