import { combineReducers } from 'redux';
import { routeReducer as routing } from 'react-router-redux';
import combineImmutableReducers from '^/combineImmutableReducers';
import composeReducers from './composeReducers';
import * as ui from './ui';
import * as insights from './insights';
import * as groupDocuments from './groupDocuments';
import form from './form';
import items from './items';
import stats from './stats';
import yearToDateStats from './yearToDateStats';
import * as dashboardActivitiesAndUpdatedTemplates from './dashboardActivitiesAndUpdatedTemplates';
import collections from './collections';
import * as exportsReducers from './exports';
import * as groupTaskUpdateReducers from './groupTaskUpdate';
import * as reducers from './reducers';
import responses from './responses';
import { clearAllOnLogout } from '^/reducers/rootStateReducers';
import healthcheck from './healthcheck';
import audit from './audit';
import { thunkRequests, thunkRequestsById } from './thunk-requests';

const rootReducer = combineReducers(
  Object.assign(
    {
      routing,
      ui: combineImmutableReducers(ui),
      form,
      items,
      stats,
      yearToDateStats,
      dashboardActivitiesAndUpdatedTemplates: combineImmutableReducers(dashboardActivitiesAndUpdatedTemplates),
      responses,
      collections,
      insights: combineImmutableReducers(insights),
      groupDocuments: combineImmutableReducers(groupDocuments),
      healthcheck,
      audit,
      thunkRequests,
      thunkRequestsById,
    },
    reducers,
    exportsReducers,
    groupTaskUpdateReducers,
  )
);

export default composeReducers([rootReducer, clearAllOnLogout]);
