import React from 'react';
import { connect } from 'react-redux';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEditCreate from '^/components/admin/content/edit-create/AdminEditCreate';
import AdminChangeLog, { reloadChangeLog } from '^/components/admin/content/change-log/AdminChangeLog';

import {
  ADMIN_RESPONSIBILITY_CREATE,
  ADMIN_RESPONSIBILITY_MODEL,
  ADMIN_RESPONSIBILITY_EDIT_FIELDS,
} from './responsibilities';


export const EditResponsibilities = function ({ responsibilityId }) {
  return (
    <div>
      <AdminContainer title="Responsibility">
        <AdminEditCreate
          itemId={responsibilityId}
          controlName={ADMIN_RESPONSIBILITY_CREATE}
          collectionName={ADMIN_RESPONSIBILITY_CREATE}
          onComplete={reloadChangeLog('Responsibility', responsibilityId)}
          model={ADMIN_RESPONSIBILITY_MODEL}
          fields={ADMIN_RESPONSIBILITY_EDIT_FIELDS}
          title="Edit Responsibility"
        />
      </AdminContainer>
      <AdminContainer>
        <AdminChangeLog model="Responsibility" id={responsibilityId} />
      </AdminContainer>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    responsibilityId: props.params.uuid,
  };
}

export default connect(mapStateToProps)(EditResponsibilities);
