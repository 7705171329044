import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { ACTIVITY_DATE_POPUP } from '^/actions/actionSequences';
import { expandUiComponent, collapseUiComponent } from '^/actions/actions';
import { ACTIVITIES } from '^/consts/collectionKeys';
import { openConfirmChangeActivityDateModal } from '^/actions/modals';
import { isPending, hasSucceeded } from '^/consts/responseStates';
import { isLoggedOnUserAdminOfCurrentPractice } from '^/stateHelpers';
import { formatDate, formatBackendDate } from '^/utils';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import Icon from '^/components/app/content/Icon';
import ActivityDatePopupForm from '^/components/app/activities/ActivityDatePopupForm';


const ActivityDue = ({activity}) => {
  const { end_date, completed_date, days_overdue } = activity.toObject();

  return (
    <span>
      <Icon type="calendar-o" className="metadata-icon" />
      {completed_date ? (
        <span>Completed {formatDate(completed_date)}</span>
      ) : (
        <span>Due: {formatDate(end_date)}{days_overdue > 0 && ` (${days_overdue} days outstanding)`}</span>
      )}
    </span>
  );
};


const ActivityDatePopup = ({activity, updateDate, close, response}) =>
  <div className="popup activity-popup">
    <a
      className="close"
      onClick={close}
    >
      <Icon type="close" className="inline-icon" />
    </a>

    <h5>Enter due date</h5>

    <ActivityDatePopupForm
      form={activity.get('id')}
      initialValues={{
        end_date: formatBackendDate(activity.get('end_date')),
      }}
      activity={activity}
      onSubmit={data => updateDate(data.end_date)}
      response={response}
    />
  </div>;


export class ActivityDate extends PureComponent {

  componentWillMount() {
    this.props.close();
  }

  componentDidUpdate(prevProps) {
    const { response, close } = this.props;

    if (hasSucceeded(response) && isPending(prevProps.response)) {
      close();
    }
  }

  toggle() {
    const { isShowingPopup, close, open } = this.props;

    return isShowingPopup ? close() : open();
  }

  render() {
    const {
      isAdmin,
      activity,
      adminStep,
      isShowingPopup,
      close,
      updateDate,
      response,
    } = this.props;

    if (adminStep) {
      return <ActivityDue
        activity={activity.merge({
          end_date: adminStep.get('end_date'),
          days_overdue: adminStep.get('days_overdue'),
        })}
             />;
    }

    if (!isAdmin || activity.get('is_completed')) {
      return <ActivityDue activity={activity} />;
    }

    if (isPending(response)) {
      return <Loading className="small inline" />;
    }

    return (
      <span>
        <ActivityDue activity={activity} />
        <div className="popup-container">
          {isShowingPopup &&
            <ActivityDatePopup
              activity={activity}
              close={close}
              updateDate={updateDate}
              response={response}
            />
          }
        </div>
        &nbsp;
        <a
          className="inline-edit"
          onClick={() => this.toggle()}
        >
          Change
        </a>
      </span>
    );
  }
}

ActivityDate.propTypes = {
  disallowChangeDate: React.PropTypes.bool,
  isShowingPopup: React.PropTypes.bool,
  activity: ImmutablePropTypes.map,
  adminStep: ImmutablePropTypes.map,
  response: ImmutablePropTypes.map,
  close: React.PropTypes.func,
  open: React.PropTypes.func,
  updateDate: React.PropTypes.func,
};

export function mapStateToProps(state) {
  return {
    isShowingPopup: state.ui.get('expandedUiComponents').contains(ACTIVITY_DATE_POPUP),
    response: state.responses.getIn(['updateItem', ACTIVITIES]),
    isAdmin: isLoggedOnUserAdminOfCurrentPractice(state),
  };
}

export function mapDispatchToProps(dispatch, props) {
  const { activity } = props;

  return {
    updateDate: date => dispatch(openConfirmChangeActivityDateModal(date, activity)),
    open: () => dispatch(expandUiComponent(ACTIVITY_DATE_POPUP)),
    close: () => dispatch(collapseUiComponent(ACTIVITY_DATE_POPUP)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityDate);
