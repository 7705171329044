import { fromJS } from 'immutable';

export const ACCOUNT_STAGES = fromJS({
  'PERSONAL_DETAILS': 'PERSONAL_DETAILS',
  'MY_PRACTICES': 'MY_PRACTICES'
});

export const ACCOUNT_STAGE_TEXT = fromJS({
  [ACCOUNT_STAGES.get('PERSONAL_DETAILS')]: 'Personal Details',
  [ACCOUNT_STAGES.get('MY_PRACTICES')]: 'My Practices',
});

export const ACCOUNT_URLS = fromJS({
  [ACCOUNT_STAGES.get('PERSONAL_DETAILS')]: '/page/profile/',
  [ACCOUNT_STAGES.get('MY_PRACTICES')]: '/page/profile/my-practices/'
});
