import React from 'react';
import { browserHistory } from 'react-router';

class BackButton extends React.Component<{}, {}> {
  render() {
    return (
      <button className="back-link" onClick={this.onBack}>
        {'< Back'}
      </button>
    );
  }

  private onBack = () => {
    browserHistory.goBack();
  };
}

export default BackButton;
