import React from 'react';
import { connect } from 'react-redux';

import { openDemoModeDisallowsModal } from '^/actions/modals';
import PureComponent from '^/components/common/PureComponent';
import isDemoUserBlocked from './isDemoUserBlocked';


export class DemoModeMayDisallow extends PureComponent {
  componentDidMount() {
    if (this.props.isBlocked) {
      this.props.openDemoModeDisallowsModal(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isBlocked && !prevProps.isBlocked) {
      this.props.openDemoModeDisallowsModal(this.props);
    }
  }

  render() {
    const {isBlocked, children} = this.props;

    return (
      (!isBlocked && children)
        ? <span>{children}</span>
        : <noscript />
    );
  }
}

export const mapStateToProps = (state, {response}) => ({
  isBlocked: isDemoUserBlocked(response)
});

export default connect(mapStateToProps, { openDemoModeDisallowsModal }) (DemoModeMayDisallow);
