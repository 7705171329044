import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { closeModal } from '^/actions/modals';

export const DemoModeDisallowsModal = ({goBack, message, closeModal: close}) => (
  <div>
    <p>
      Sorry, in demo mode you {message || 'cannot do this action'}.
    </p>

    <p className="secondary-info lighten-text">
      To upgrade your account to full access, please contact iComply.
    </p>

    <div className="row">
      <div className="col-1">
        <button
          className="btn btn-default pull-right"
          onClick={() => {
            if (goBack) {
              browserHistory.goBack();
            }
            close();
          }}
        >
          OK
        </button>
      </div>
    </div>
  </div>
);

export default connect(null, { closeModal }) (DemoModeDisallowsModal);
