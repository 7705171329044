import createCategoryPage from './hoc/create-category-page';
import {
  AuditQuestion,
  AuditStatus,
  AuditWorkflowTypes,
} from '^/components/app/digital-tools/audit/types';
import QuestionAndAnswer from './question-and-answer';
import { getAuditQuestionsCategory } from '^/actions/audit';

export default createCategoryPage<AuditQuestion>({
  type: AuditWorkflowTypes.Audit,
  content: 'Please answer the following questions',
  noContent: '',
  header: 'Question',
  itemRenderer: QuestionAndAnswer,
  fetchCategoryAction: getAuditQuestionsCategory,
  requestKeys: [
    'getAuditQuestionsProgress',
    'getAuditQuestionsCategory',
    'updateAudit',
  ],
  getIsLoading: state =>
    state.thunkRequests.getAuditQuestionsProgress.isLoading ||
    state.thunkRequests.getAuditQuestionsCategory.isLoading ||
    state.thunkRequests.updateAudit.isLoading,
  getErrors: state =>
    state.thunkRequests.getAuditQuestionsProgress.errors ||
    state.thunkRequests.getAuditQuestionsCategory.errors ||
    state.thunkRequests.updateAudit.errors,
  getCategories: state => state.audit.questionsCategories,
  intendedFromStatus: AuditStatus.AUDIT,
  urlPart: '/questions/',
});
