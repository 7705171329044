import { isPending } from '^/consts/responseStates';

export const shouldLoadTaskListAndUpdatedNewTemplates = (props, oldProps = {}) =>
  props.practiceId &&
  !isPending(props.response) &&
  !isPending(props.alertsResponse) &&
  !isPending(props.documentReadRequestsResponse) &&
  (
    props.practiceId !== oldProps.practiceId ||
    props.currentTeamMember !== oldProps.currentTeamMember
  );
