import React from 'react';
import classNames from 'classnames';
import Icon from '^/components/app/content/Icon';

const WarningBlock = ({ children, iconType, className }) => (
  <div className={classNames('alert-warning', className)}>
    <Icon type={iconType} className="tip-icon pull-left incomplete" />
    <div className="pl-2">
      {children}
    </div>
  </div>
);

WarningBlock.defaultProps = {
  iconType: 'exclamation-triangle'
};

WarningBlock.propTypes = {
  iconType: React.PropTypes.string,
  className: React.PropTypes.string,
};

export default WarningBlock;
