import React from 'react';
import { Link } from 'react-router';
import { makeStaticPath } from '^/utils';
import PureComponent from '^/components/common/PureComponent';

export default class LoggedOutLogo extends PureComponent {

  render() {
    return (
      <div className="login-logo">
        <Link className="brand-centered" to="/page/dashboard/">
          <img src={makeStaticPath('images/brand/logo-color.svg')} className="brand-logo-centered bottom-pad" />
        </Link>
      </div>
    );
  }
}
