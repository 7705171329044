export const USERS = 'USERS';
export const ACTIVITIES = 'ACTIVITIES';
export const RESPONSIBILITIES = 'RESPONSIBILITIES';
export const TASKS = 'TASKS';
export const DOCUMENTS = 'DOCUMENTS';

export const categoryOptions = [
  {
    value: USERS,
    label: 'Users',
  },
  {
    value: ACTIVITIES,
    label: 'Activities',
  },
  {
    value: RESPONSIBILITIES,
    label: 'Responsibilities',
  },
  {
    value: TASKS,
    label: 'Tasks',
  },
  {
    value: DOCUMENTS,
    label: 'Documents',
  },
];

// Users
export const USER_INVITED = 'USER_INVITED';
export const USER_INVITE_REMOVED = 'USER_INVITE_REMOVED';
export const USER_ACCEPTED = 'USER_ACCEPTED';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_RETIRED = 'USER_RETIRED';
export const USER_SUSPENDED = 'USER_SUSPENDED';
export const USER_REJECTED = 'USER_REJECTED';
// Activities
export const ACTIVITY_COMPLETED = 'ACTIVITY_COMPLETED';
export const ACTIVITY_RESCHEDULED = 'ACTIVITY_RESCHEDULED';
export const ACTIVITY_REASSIGNED = 'ACTIVITY_REASSIGNED';
export const ACTIVITY_FILE_UPLOADED = 'ACTIVITY_FILE_UPLOADED';
// Responsibilities
export const RESPONSIBILITY_UPDATED = 'RESPONSIBILITY_UPDATED';
// Tasks
export const TASK_CREATED = 'TASK_CREATED';
export const TASK_RESCHEDULED = 'TASK_RESCHEDULED';
export const TASK_ASSIGNEES_CHANGED = 'TASK_ASSIGNEES_CHANGED';
export const TASK_DELETED = 'TASK_DELETED';
export const TASK_ASSIGNEE_COMPLETED = 'TASK_ASSIGNEE_COMPLETED';
// Documents
export const DOCUMENT_VERSION_UPDATED = 'DOCUMENT_VERSION_UPDATED';
export const DOCUMENT_EDITED = 'DOCUMENT_EDITED';
export const DOCUMENT_COPY_CREATED = 'DOCUMENT_COPY_CREATED';

export const typeOptions = [
  // Users
  {
    value: USER_INVITED,
    label: 'User invited',
  },
  {
    value: USER_INVITE_REMOVED,
    label: 'Invite removed',
  },
  {
    value: USER_ACCEPTED,
    label: 'Invite accepted',
  },
  {
    value: USER_UPDATED,
    label: 'User updated',
  },
  {
    value: USER_RETIRED,
    label: 'User retired',
  },
  {
    value: USER_SUSPENDED,
    label: 'User suspended',
  },
  {
    value: USER_REJECTED,
    label: 'User rejected',
  },
  // Activities
  {
    value: ACTIVITY_COMPLETED,
    label: 'Activity completed',
  },
  {
    value: ACTIVITY_RESCHEDULED,
    label: 'Activity rescheduled',
  },
  {
    value: ACTIVITY_REASSIGNED,
    label: 'Activity reassigned',
  },
  {
    value: ACTIVITY_FILE_UPLOADED,
    label: 'File uploaded to activity',
  },
  // Responsibilities
  {
    value: RESPONSIBILITY_UPDATED,
    label: 'Responsibility updated',
  },
  // Tasks
  {
    value: TASK_CREATED,
    label: 'Task created',
  },
  {
    value: TASK_RESCHEDULED,
    label: 'Task rescheduled',
  },
  {
    value: TASK_ASSIGNEES_CHANGED,
    label: 'Task assignees changed',
  },
  {
    value: TASK_DELETED,
    label: 'Task deleted',
  },
  {
    value: TASK_ASSIGNEE_COMPLETED,
    label: 'Task assignee completed',
  },
  // Documents
  {
    value: DOCUMENT_VERSION_UPDATED,
    label: 'Document version updated',
  },
  {
    value: DOCUMENT_EDITED,
    label: 'Document edited',
  },
  {
    value: DOCUMENT_COPY_CREATED,
    label: 'Document copy created',
  },
];

export const categoryTypes = {
  USERS: [
    USER_INVITED,
    USER_INVITE_REMOVED,
    USER_ACCEPTED,
    USER_UPDATED,
    USER_RETIRED,
    USER_SUSPENDED,
    USER_REJECTED,
  ],
  ACTIVITIES: [
    ACTIVITY_COMPLETED,
    ACTIVITY_RESCHEDULED,
    ACTIVITY_REASSIGNED,
    ACTIVITY_FILE_UPLOADED,
  ],
  RESPONSIBILITIES: [RESPONSIBILITY_UPDATED],
  TASKS: [
    TASK_CREATED,
    TASK_RESCHEDULED,
    TASK_ASSIGNEES_CHANGED,
    TASK_DELETED,
    TASK_ASSIGNEE_COMPLETED,
  ],
  DOCUMENTS: [DOCUMENT_VERSION_UPDATED, DOCUMENT_EDITED, DOCUMENT_COPY_CREATED],
};

export const typeCategories = {
  USER_INVITED: USERS,
  USER_INVITE_REMOVED: USERS,
  USER_ACCEPTED: USERS,
  USER_UPDATED: USERS,
  USER_RETIRED: USERS,
  USER_SUSPENDED: USERS,
  USER_REJECTED: USERS,
  ACTIVITY_COMPLETED: ACTIVITIES,
  ACTIVITY_RESCHEDULED: ACTIVITIES,
  ACTIVITY_REASSIGNED: ACTIVITIES,
  ACTIVITY_FILE_UPLOADED: ACTIVITIES,
  RESPONSIBILITY_UPDATED: RESPONSIBILITIES,
  TASK_CREATED: TASKS,
  TASK_RESCHEDULED: TASKS,
  TASK_ASSIGNEES_CHANGED: TASKS,
  TASK_DELETED: TASKS,
  TASK_ASSIGNEE_COMPLETED: TASKS,
  DOCUMENT_VERSION_UPDATED: DOCUMENTS,
  DOCUMENT_EDITED: DOCUMENTS,
  DOCUMENT_COPY_CREATED: DOCUMENTS,
};

export const USER_LEVELS = {
  PRACTICE_ADMIN: 'PRACTICE_ADMIN',
  PRACTICE_MANAGER: 'PRACTICE_MANAGER',
  TEAM_MEMBER: 'TEAM_MEMBER',
};

export const userLevelOptions = [
  { value: USER_LEVELS.PRACTICE_ADMIN, label: 'Practice Admin' },
  { value: USER_LEVELS.PRACTICE_MANAGER, label: 'Practice Manager' },
  { value: USER_LEVELS.TEAM_MEMBER, label: 'Team Member' },
];
