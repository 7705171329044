import React from 'react';
import { connect } from 'react-redux';

import { getCollection } from '^/actions/collections';
import { dismissGroupDocumentAlert } from '^/actions/groupDocuments';
import Loading from '^/components/app/content/Loading';
import PureComponent from '^/components/common/PureComponent';
import { selectGroupDocumentsAlertsCount } from '^/selectors/groupDocuments';
import AlertList from '^/components/app/alerts/AlertList';
import { GROUP_DOCUMENT_ALERTS } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';

const PAGE_SIZE = 12;

export class NewAndUpdatedDocuments extends PureComponent {
  componentWillMount() {
    this.loadGroupDocumentAlerts();
  }

  loadGroupDocumentAlerts(currentPage=0, shouldAppend=false) {
    this.props.getCollection(
      GROUP_DOCUMENT_ALERTS,
      {
        shouldAppend,
        filters: { page_size: PAGE_SIZE },
        page: currentPage + 1,
      }
    );
  }

  render() {
    const { alerts, alertsPage, hasMoreAlerts, isLoading, title } = this.props;
    return (
      <div>
        <h2>{title}</h2>
        { isLoading ? (
          <Loading />
        ) : (
          <AlertList
            isGroupAdmin
            isGroupsApp
            isReadOnlyUser={false}
            alerts={alerts}
            hasMoreAlerts={hasMoreAlerts}
            dismissAlert={id => this.props.dismissGroupDocumentAlert(id)}
            onLoadMore={() => this.loadGroupDocumentAlerts(alertsPage)}
          />
        )}
      </div>

    );
  }
};

export function mapStateToProps(state) {
  const alertsCount = selectGroupDocumentsAlertsCount(state);
  return {
    title: `New and Updated Documents ${alertsCount ? `(${alertsCount})` : ''}`,
    alerts: state.collections.getIn([GROUP_DOCUMENT_ALERTS, 'items']),
    alertsPage: state.collections.getIn([GROUP_DOCUMENT_ALERTS, 'page']),
    isLoading: isPending(state.responses.getIn(['getCollection', GROUP_DOCUMENT_ALERTS])),
    hasMoreAlerts: state.collections.getIn([GROUP_DOCUMENT_ALERTS, 'hasMore']),
  };
};

export default connect(mapStateToProps, {
  dismissGroupDocumentAlert,
  getCollection
})(NewAndUpdatedDocuments);
