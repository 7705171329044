import React from 'react';
import { connect } from 'react-redux';
import { List, fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import {
  createGroupTaskAndCloseModal,
  updateGroupTaskAndCloseModal,
  uploadFile,
} from '^/actions/actionSequences';
import { getAllCollection } from '^/actions/collections';
import {
  TEMPLATES,
  RESPONSIBILITIES,
  GROUP_TASKS,
  LIVE_CYCLES_GROUP_PRACTICES,
  LIVE_CYCLES_GROUP_PRACTICES_UPDATE,
} from '^/consts/collectionKeys';
import { loadItem } from '^/actions/items';

import { isPending, hasSucceeded } from '^/consts/responseStates';
import { resetResponse } from '^/actions/actions';
import { pollInProgress, pollFailed, pollComplete } from '^/models/export';
import { transformRepeatOption, toOptionValue } from '^/models/task';
import { isLoggedOnUserAdminOfCurrentPractice } from '^/stateHelpers';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import CreateEditGroupTasksForm from '^/components/app/groups/group-tasks/CreateEditGroupTasksForm';

export const TEMPLATE_TASKS_SELECT = 'TEMPLATE_TASKS_SELECT';

export class CreateEditGroupTasksModal extends PureComponent {
  componentDidMount() {
    const { isAdmin, isManage, groupTaskId } = this.props;

    this.props.getResponsibilities();
    this.getLiveCycleGroupPractices();
    if (isAdmin) {
      this.props.getTemplateDocuments();
    }

    if (isManage) {
      this.loadGroupTask(groupTaskId);
    }

    this.props.resetResponse('pollUpdateGroupTask');
  }

  componentWillUnmount() {
    window.clearTimeout(this.props.pollTimeout);
  }

  loadGroupTask(groupTaskId) {
    if (groupTaskId) {
      this.props.loadItem(GROUP_TASKS, groupTaskId);
    }
  }

  getLiveCycleGroupPractices() {
    const { isManage, groupTaskId } = this.props;

    if (groupTaskId && isManage) {
      this.props.getGroupPractices(
        `${LIVE_CYCLES_GROUP_PRACTICES_UPDATE}/${groupTaskId}`,
        {},
        LIVE_CYCLES_GROUP_PRACTICES_UPDATE
      );
    }

    if (!isManage) {
      this.props.getGroupPractices(LIVE_CYCLES_GROUP_PRACTICES);
    }
  }

  createEditGroupTask(data, user) {
    const { isManage, updateGroupTask, createGroupTask } = this.props;

    if (isManage) {
      updateGroupTask(data);
    } else {
      createGroupTask(data, user);
    }
  }

  getInitialValues(groupTask) {
    const relatedTemplates = groupTask
      .get('related_templates', List())
      .map(item => item.get('id'))
      .toJS();
    return {
      title: groupTask.get('title', ''),
      description: groupTask.get('description', ''),
      related_templates: relatedTemplates,
      related_files: groupTask.get('related_files', List()).toJS(),
      responsibility: groupTask.getIn(['responsibility', 'id']),
      is_locked_at_practice_level: groupTask.get(
        'is_locked_at_practice_level',
        false
      ),
      repeatOption:
        toOptionValue(
          fromJS({
            repeat: groupTask.get('repeat'),
            repeat_interval: groupTask.get('repeat_interval'),
          })
        ) || '',
    };
  }

  render() {
    const {
      user,
      response,
      templateDocuments,
      templateDocumentsResponse,
      uploadFileResponse,
      responsibilities,
      responsibilitiesResponse,
      groupTask,
      groupTaskId,
      groupTaskResponse,
      groupPractices,
      groupPracticesResponse,
      groupPracticesUpdate,
      groupPracticesUpdateResponse,
      isManage,
      pollUpdateResponse,
      groupTaskUpdateResponse,
      groupTaskCreateResponse,
    } = this.props;

    if (
      isPending(templateDocumentsResponse) ||
      isPending(responsibilitiesResponse) ||
      isPending(groupTaskResponse) ||
      isPending(groupPracticesResponse) ||
      isPending(groupPracticesUpdateResponse)
    ) {
      return <Loading />;
    }

    if (
      isPending(groupTaskUpdateResponse) ||
      isPending(groupTaskCreateResponse) ||
      isPending(pollUpdateResponse) ||
      pollInProgress(pollUpdateResponse)
    ) {
      return (
        <div>
          <p>Group Task Create/Update/Archive in progress... Please wait!</p>
          <Loading />
        </div>
      );
    }

    if (!isManage && pollFailed(pollUpdateResponse)) {
      return (
        <p className="alert mt-1 mb-1 alert-error">
          Group Task creation failed! Please try again.
        </p>
      );
    }

    if (hasSucceeded(groupTaskUpdateResponse) && groupTask.get('is_archived')) {
      return <p>Archiving is done!</p>;
    }

    return (
      <div>
        {pollFailed(pollUpdateResponse) && (
          <p className="alert mt-1 mb-1 alert-error">
            Group Task Update/Archive failed! Please try again.
          </p>
        )}
        {hasSucceeded(groupTaskUpdateResponse) &&
          pollComplete(pollUpdateResponse) && (
            <p className="alert mt-1 mb-1 alert-success">
              Group Task Update is done!
            </p>
          )}
        <CreateEditGroupTasksForm
          responsibilities={responsibilities}
          initialValues={
            isManage && groupTask && this.getInitialValues(groupTask)
          }
          templateDocuments={templateDocuments}
          groupPractices={isManage ? groupPracticesUpdate : groupPractices}
          onSubmit={data => {
            this.createEditGroupTask(transformRepeatOption(data), user);
          }}
          uploadFile={this.props.uploadFile}
          uploadFileResponse={uploadFileResponse}
          response={response}
          isManage={isManage}
          groupTaskId={groupTaskId}
        />
      </div>
    );
  }
}

CreateEditGroupTasksModal.propTypes = {
  templateDocuments: ImmutablePropTypes.iterable.isRequired,
  responsibilities: ImmutablePropTypes.iterable.isRequired,
  uploadFileResponse: ImmutablePropTypes.map,
  groupTaskResponse: ImmutablePropTypes.map,
  user: ImmutablePropTypes.map.isRequired,
  responsibilitiesResponse: ImmutablePropTypes.map,
  groupTask: ImmutablePropTypes.map,
  isAdmin: React.PropTypes.bool,
};

export function mapStateToProps(state) {
  const groupTaskUpdateId = state.items.getIn([
    GROUP_TASKS,
    'group_task_update_job_id',
  ]);

  return {
    groupTask: state.items.get(GROUP_TASKS),
    groupPractices: state.collections.getIn(
      [LIVE_CYCLES_GROUP_PRACTICES, 'items'],
      List()
    ),
    groupPracticesUpdate: state.collections.getIn(
      [LIVE_CYCLES_GROUP_PRACTICES_UPDATE, 'items'],
      List()
    ),
    templateDocuments: state.collections.getIn([TEMPLATES, 'items'], List()),
    responsibilities: state.collections.getIn(
      [RESPONSIBILITIES, 'items'],
      List()
    ),
    user: state.userProfile,
    response:
      state.responses.getIn(['createItem', GROUP_TASKS]) ||
      state.responses.getIn(['updateItem', GROUP_TASKS]),
    groupTaskUpdateResponse: state.responses.getIn(['updateItem', GROUP_TASKS]),
    groupTaskCreateResponse: state.responses.getIn(['createItem', GROUP_TASKS]),
    uploadFileResponse: state.responses.get('uploadFile'),
    isAdmin: isLoggedOnUserAdminOfCurrentPractice(state),
    templateDocumentsResponse: state.responses.getIn([
      'getCollection',
      TEMPLATES,
    ]),
    responsibilitiesResponse: state.responses.getIn([
      'getCollection',
      RESPONSIBILITIES,
    ]),
    groupPracticesResponse: state.responses.getIn([
      'getCollection',
      LIVE_CYCLES_GROUP_PRACTICES,
    ]),
    groupPracticesUpdateResponse: state.responses.getIn([
      'getCollection',
      LIVE_CYCLES_GROUP_PRACTICES_UPDATE,
    ]),
    groupTaskResponse: state.responses.getIn(['loadItem', GROUP_TASKS]),
    pollUpdateResponse: state.responses.getIn([
      'pollUpdateGroupTask',
      groupTaskUpdateId,
    ]),
    pollTimeout: state.pollUpdateGroupTaskTimeouts.get(groupTaskUpdateId),
  };
}

function dispatchDefaultCreateTask(data, user, dispatch) {
  const transformedTaskData = {
    ...data,
    created_by: user.getIn(['staffdetail', 'id']),
  };

  return dispatch(
    createGroupTaskAndCloseModal(GROUP_TASKS, transformedTaskData)
  );
}

function dispatchUpdateGroupTask(data, groupTaskId, dispatch) {
  return dispatch(updateGroupTaskAndCloseModal(GROUP_TASKS, groupTaskId, data));
}

function transformFileData(data) {
  const immutableData = fromJS(data);
  return immutableData
    .set(
      'related_files',
      (immutableData.get('related_files') || List()).map(each => each.get('id'))
    )
    .toJS();
}

export function mapDispatchToProps(dispatch, props) {
  const createGroupTask = props.createGroupTask
    ? (data, user) => props.createGroupTask(transformFileData(data), user)
    : (data, user) =>
        dispatchDefaultCreateTask(transformFileData(data), user, dispatch);
  const updateGroupTask = data =>
    dispatchUpdateGroupTask(
      transformFileData(data),
      props.groupTaskId,
      dispatch
    );
  return {
    createGroupTask,
    updateGroupTask,
    resetResponse: type => dispatch(resetResponse(type)),
    loadItem: (type, id) => dispatch(loadItem(type, id)),
    getResponsibilities: () => dispatch(getAllCollection(RESPONSIBILITIES)),
    getTemplateDocuments: () => dispatch(getAllCollection(TEMPLATES)),
    getGroupPractices: (type, opts = {}, name = LIVE_CYCLES_GROUP_PRACTICES) =>
      dispatch(getAllCollection(type, opts, name)),
    uploadFile: (file, form, field) => dispatch(uploadFile(file, form, field)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditGroupTasksModal);
