import React from 'react';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';
import PureComponent from '^/components/common/PureComponent';

import {
  ADMIN_HELP_CREATE,
  ADMIN_HELP_MODEL,
  ADMIN_HELP_EDIT_FIELDS,
  ADMIN_HELP_ROW_FIELDS,
} from './help';

class HelpAdmin extends PureComponent {
  handleComplete(model, data, collectionName, formName, helpItem) {
    this.props.routePush('/admin/help/' + helpItem.id);
  }

  render() {
    return (
      <div>
        <AdminContainer title="Help">
          <AdminCreate
            controlName={ADMIN_HELP_CREATE}
            onComplete={this.handleComplete.bind(this)}
            collectionName={ADMIN_HELP_CREATE}
            model={ADMIN_HELP_MODEL}
            fields={ADMIN_HELP_EDIT_FIELDS}
            title="Add Help Item"
          />
        </AdminContainer>

        <AdminContainer>
          <AdminList
            title="All Help Items"
            model={ADMIN_HELP_MODEL}
            fields={ADMIN_HELP_ROW_FIELDS}
          />
        </AdminContainer>
      </div>
    );
  }
}

export default connect(null, { routePush: routeActions.push })(HelpAdmin);
