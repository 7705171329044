import React from 'react';
import { List } from 'immutable';

export default function If({children, condition}) {
  if (!condition || !children) {
    return <noscript />;
  }

  const isArrayOrString = List.of(Array, String).contains(children.constructor);
  return isArrayOrString
    ? <span>{children}</span>
    : children;
}
