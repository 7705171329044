import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import queryString from 'query-string';

import { withRouter } from '^/withRouter';
import { getSubGroupsForCurrentGroup } from '^/actions/actions';
import Icon from '^/components/app/content/Icon';
import withFilters from '^/components/app/groups/insights/hoc/withFilters';
import PureComponent from '^/components/common/PureComponent';

const formatSelectedSubGroups = (selectedSubGroups, newValue) =>
  selectedSubGroups ? `${selectedSubGroups},${newValue}` : newValue;

const removeSubGroupId = (selectedSubGroups, subGroupId) =>
  selectedSubGroups.split(',').filter(selectedSubGroupId => selectedSubGroupId !== subGroupId).join(',')

export class SelectedSubGroupOption extends PureComponent {
  constructor(props) {
    super(props);
    this.onRemove = this.onRemove.bind(this);
  }

  onRemove() {
    this.props.onRemove(this.props.subGroup.get('id'));
  }

  render() {
    const { isDisabled } = this.props;

    return (
      <button
        className={classNames(
          'display-flex',
          'flex-align-items-center',
          'pill',
          'active',
          'no-border',
          'small-text',
          'background-brand-blue',
          'focus-outline-none',
          'pointer',
          'pt-1-4',
          'pb-1-4',
          'print-hide', {
            'disabled disabled-styles': isDisabled
          }
        )}
        onClick={this.onRemove}
        title="Click to remove"
        disabled={isDisabled}
      >
        <span className="print-hide">
          {this.props.subGroup.get('name')}
        </span>
        <span className="ml-1-2 mr-0 white-icon svg-20px">
          <Icon type="close" />
        </span>
      </button>
    );
  }
}

export class SubGroupFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.updatePathWithSubGroupFilter = this.updatePathWithSubGroupFilter.bind(this);
  }

  componentWillMount() {
    this.props.getSubGroupsForCurrentGroup();
  }

  updatePathWithSubGroupFilter(subGroup) {
    const { pathname, country } = this.props;

    const queryParams = {
      sub_group: subGroup,
      country,
    }

    this.props.router.replace(`${pathname}${'?' + queryString.stringify(queryParams)}`);
  }

  onSelect(event) {
    this.onChange(
      formatSelectedSubGroups(this.props.selectedSubGroup, event.target.value)
    );
  }

  onRemove(subGroupId) {
    this.onChange(
      removeSubGroupId(this.props.selectedSubGroup, subGroupId)
    );
  }

  onChange(value) {
    this.updatePathWithSubGroupFilter(value);
    this.props.onChange(value);
  }

  render() {
    const { isEmpty, subGroupOptions, selectedSubGroupOptions, isDisabled } = this.props;

    if (isEmpty) {
      return null;
    }

    return (
      <div className="mb-1 mt-1 display-flex flex-align-items-flex-start flex-wrap row-gap-4px">
        <div>
          <h4 className="display-inline mr-1">View by Sub Group:</h4>
        </div>
        <select
          className="insights-dropdown width-145px mr-1"
          value=""
          onChange={this.onSelect}
          disabled={isDisabled}
        >
          <option value="">Select...</option>
          {subGroupOptions.map(subGroup =>
            <option key={subGroup.get('id')} value={subGroup.get('id')}>
              {subGroup.get('name')}
            </option>
          )}
        </select>
        <span className="print-dropdown-as-text">
          {selectedSubGroupOptions.isEmpty() ? '--' : selectedSubGroupOptions.map(
            (subGroup) => subGroup.get('name')
          ).join(', ')}
        </span>
        {selectedSubGroupOptions.map(subGroup => (
          <SelectedSubGroupOption
            key={`selected-sub-group-${subGroup.get('id')}`}
            subGroup={subGroup}
            onRemove={this.onRemove}
            isDisabled={isDisabled}
          />
        ))}
      </div>
    );
  }
}

SubGroupFilter.propTypes = {
  insightsConfig: ImmutablePropTypes.list,
  pathname: React.PropTypes.string.isRequired,
  selectedSubGroup: React.PropTypes.string,
  isEmpty: React.PropTypes.bool,
  subGroupOptions: ImmutablePropTypes.list,
  selectedSubGroupOptions: ImmutablePropTypes.list
};

export function mapStateToProps(state, props) {
  const subGroups = state.subGroups;

  return {
    isEmpty: subGroups.isEmpty(),
    subGroupOptions: subGroups.filter(
      subGroup => !props.selectedSubGroup || !props.selectedSubGroup.includes(subGroup.get('id'))
    ),
    selectedSubGroupOptions: subGroups.filter(
      subGroup => props.selectedSubGroup && props.selectedSubGroup.includes(subGroup.get('id'))
    )
  }
}

export const ConnectedSubGroupFilter = connect(
  mapStateToProps,
  { getSubGroupsForCurrentGroup }
)(withRouter(SubGroupFilter));

export default withFilters(ConnectedSubGroupFilter);
