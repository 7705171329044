import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import PureComponent from '^/components/common/PureComponent';
import { FORMAT_DATE, formatBackendDate } from '^/utils';
import { DatePickerInput } from '^/components/app/groups/insights/filters/DateFilter';

export class DateFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.getValue = this.getValue.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  getValue() {
    const { selectedValue } = this.props;
    return selectedValue && moment(selectedValue).isValid()
      ? moment(selectedValue)
      : null;
  }

  onClear() {
    const { filterKey, onFilterChange } = this.props;
    onFilterChange(filterKey, null);
  }

  onChange(date) {
    const { filterKey, onFilterChange } = this.props;
    onFilterChange(filterKey, formatBackendDate(date));
  }

  render() {
    const {
      className,
      placeholderText,
      minDate,
      maxDate,
      isDisabled,
    } = this.props;

    return (
      <DatePicker
        fixedHeight
        showYearDropdown
        dateFormat={FORMAT_DATE}
        selected={this.getValue()}
        onChange={this.onChange}
        minDate={minDate && moment(minDate)}
        maxDate={moment(maxDate)}
        placeholderText={placeholderText}
        className={className}
        customInput={<DatePickerInput isDisabled={isDisabled} />}
      />
    );
  }
}

export default DateFilter;
