import { CALL_API } from 'redux-api-middleware';
import SETTINGS from '^/consts/settings';
import _ from 'underscore';
import * as Cookies from 'js-cookie';

function removeUndefineds(data) {
  return _.chain(data).pairs().filter(([/*key*/, value]) => value !== undefined).object().value();
}

function getEncodedQueryParam(key, value) {
  const encodedKey = encodeURIComponent(key);
  const queryValue = _.isArray(value) ?
    value.map(encodeURIComponent).join(`&${encodedKey}=`) :
    encodeURIComponent(value);
  return `${encodedKey}=${queryValue}`;
}


// Construct a Redux Standard API-calling Action (RSAA)


export function makeRawRsaAction(
  actionSet,
  url,
  method,
  headers,
  optionalData,
  meta
) {
  const types = _.map([actionSet.REQUEST, actionSet.SUCCESS, actionSet.FAILURE], (requestType) => {
    if (meta) {
      return { type: requestType, meta: _.clone(meta) };
    }
    return { type: requestType };
  });

  const callSpec = {
    types: types,
    endpoint: url,
    method,
    headers,
    credentials: 'same-origin'
  };

  if (optionalData) {
      // If object, remove items where value is 'undefined'
    const data = _.isArray(optionalData) ? optionalData : removeUndefineds(optionalData);

    if (method === 'GET') {
      callSpec.endpoint += '?' +
        _.map(data, (value, key) => getEncodedQueryParam(key, value)).join('&');
    }
    else if (method === 'POST' || method === 'PUT' || method === 'DELETE' || method === 'PATCH') {
      const hasFile = _(data).some(value => value && value.constructor === window.File);
      if (hasFile) {
        const formData = new FormData();
        _.each(data, (value, key) => {
          formData.append(key, value === null ? '' : value);
        });
        callSpec.body = formData;
      }
      else {
        callSpec.headers['Content-Type'] = 'application/json';
        callSpec.body = JSON.stringify(data);
      }
    }
  }

  return {
    [CALL_API]: callSpec
  };
}


export default function makeRsaAction(
  actionSet,
  path,
  method,
  optionalData,
  meta,
  fullPath
) {
  const headers = {
    'Accept': 'application/json'
  };

  const csrf = Cookies.get('csrftoken');
  if (csrf) {
    headers['X-CSRFToken'] = csrf;
  }

  return makeRawRsaAction(
    actionSet,
    !fullPath ? SETTINGS.API_PREFIX + path : path,
    method,
    headers,
    optionalData,
    meta
  );
}
