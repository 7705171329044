import { Map } from 'immutable';

import makeRsaAction from '^/middleware/makeRsaAction';
import { makeAsyncActionSet } from './utils';
import { LAST_DATABASE_SYNC_URL } from '^/components/app/groups/insights/constants';

export const SORT_INSIGHTS_BY = 'SORT_INSIGHTS_BY';
export function sortInsightBy(insight, sortByKey, isAscending = true) {
  return {
    type: SORT_INSIGHTS_BY,
    payload: {
      insight,
      sortByKey,
      isAscending,
    },
  };
}

export const SHOW_ALL_INSIGHT_TABLE_RESULTS = 'SHOW_ALL_INSIGHT_TABLE_RESULTS';
export function showAllInsightTableResults(insight) {
  return {
    type: SHOW_ALL_INSIGHT_TABLE_RESULTS,
    payload: insight,
  };
}

export const LOAD_INSIGHT_DATA = makeAsyncActionSet('LOAD_INSIGHT_DATA');
export function loadInsightData(insight, url, filters = Map()) {
  return makeRsaAction(
    LOAD_INSIGHT_DATA,
    url,
    'GET',
    filters.toObject(),
    {
      insight,
      filters,
      source: insight,
    }
  );
}

export const LOAD_LAST_DATABASE_SYNC = makeAsyncActionSet('LOAD_LAST_DATABASE_SYNC');
export function loadLastDatabaseSync() {
  return makeRsaAction(
    LOAD_LAST_DATABASE_SYNC,
    LAST_DATABASE_SYNC_URL,
    'GET'
  );
}
