import React from 'react';
import { connect } from 'react-redux';

import {
  onReassignSuccess,
  reinstateMemberThenReloadPracticeMembers,
} from '^/actions/actionSequences';

import ConfirmReassignment from '^/components/app/practices/reassign-member/ConfirmReassignment';


function onSuccess (props) {
  props.onReassignSuccess(props.practiceId);
}

const ReinstateModal = props =>
  <ConfirmReassignment
    {...props}
    action="reinstate"
    onSuccess={() => onSuccess(props)}
    onSubmit={props.reinstateMemberThenReloadPracticeMembers.bind(this, props.member.get('id'))}
  />;

const mapStateToProps = state => {
  return {
    response: state.responses.get('reassignMember'),
  };
};

export default connect(mapStateToProps, {
  reinstateMemberThenReloadPracticeMembers,
  onReassignSuccess,
})(ReinstateModal);
