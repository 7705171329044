import { fromJS } from 'immutable';

export const ADMIN_QMR_REPORT_MODEL = 'admin/qmr-reports';
const ADMIN_QMR_REPORT_MANAGE_ROUTE = '/admin/qmr-reports/${id}/';

export const ADMIN_QMR_REPORT_CREATE_FIELDS = fromJS([
  'practice'
]);

export const ADMIN_QMR_REPORT_EDIT_FIELDS = fromJS([
  'content'
]);

export const ADMIN_QMR_REPORT_ROW_FIELDS = fromJS([
  {name: ['practice_display'], display_name: 'Practice', type:'string', sortable: true},
  {name: ['created'], display_name: 'Created', type: 'datetime', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Manage', route: ADMIN_QMR_REPORT_MANAGE_ROUTE},
]);
