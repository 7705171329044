import classNames from 'classnames';
import React from 'react';

import { extractErrorMessage } from '^/utils/common';

interface Props {
  errors: unknown;
  className?: string;
}

const NonFieldErrorRenderer = (props: Props) => {
  const message = extractErrorMessage(props.errors);
  return <p className={classNames('text-error', props.className)}>{message}</p>;
};

export default NonFieldErrorRenderer;
