import React from 'react';
import classNames from 'classnames';

// capsule-admin-step
// capsule-risk-assessment
// capsule-audit
// capsule-meeting
// capsule-task
// capsule-reminder

const Capsule = ({className}) =>
  <div className={classNames(['capsule', className])} />
;

export default Capsule;
