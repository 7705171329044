import { fromJS } from 'immutable';

export const REPORT_TYPES = fromJS([
  { url: 'financial-report', display_name: 'Financial Report', },
  { url: 'qps-report', display_name: 'QPS Report', },
  { url: 'user-report', display_name: 'User Report', },
  { url: 'activity-report', display_name: 'Activity Report', },
  { url: 'demo-user-report', display_name: 'Demo Users', },
  { url: 'overdue-activity-report', display_name: 'Outstanding Activities', },
  { url: 'admin-staff-level-history-report', display_name: '3 Month Practice Admin Level History', },
  { url: 'document-report', display_name: 'Template Documents', },
  { url: 'practice-membership-report', display_name: 'Practice Memberships', },
]);
