import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List, Map } from 'immutable';

import { isPending } from '^/consts/responseStates';
import { ACTIVITY_STATUSES } from '^/models/activities';
import { getAllCollection } from '^/actions/collections';
import { ACTIVITIES } from '^/consts/collectionKeys';
import { setSelectValue, clearSelect } from '^/actions/actions';
import { changeStepAndAddReassignData } from '^/actions/actionSequences';
import { USER_REASSIGN_STEPS, REASSIGN_MEMBER, REASSIGN_STEPS } from '^/consts/retireSuspend';

import Loading from '^/components/app/content/Loading';
import MemberFilter from '^/components/app/practices/reassign-member/MemberFilter';
import EmptyListPage from '^/components/app/practices/reassign-member/EmptyListPage';
import PureComponent from '^/components/common/PureComponent';
import ReassignActivitiesForm
  from '^/components/app/practices/reassign-member/ReassignActivitiesForm';

export class ReassignActivities extends PureComponent {

  componentWillMount() {
    const { loadActivities } = this.props;

    loadActivities();
  }

  render() {
    const {
      loading, activities, setDefaultMember,
      memberSelectValue, memberChoices,
      handleSubmit
    } = this.props;

    if (loading) { return <Loading />; }

    if (activities.isEmpty()) {
      return (
        <EmptyListPage
          reassignType="activities"
          handleSubmit={handleSubmit}
        />
      );
    }

    const activityIds =
      activities
        .map(activity => activity.get('id'));

    const initialValues = Map(
        activityIds
          .map(id => [id, memberSelectValue || memberChoices.first().get('value')])
      ).toJS();

    return (
      <div className="mb-1">
        <em>Reassign all activities to &nbsp;</em>
        <MemberFilter
          choices={memberChoices}
          onChange={setDefaultMember}
          value={memberSelectValue}
        />
        <ReassignActivitiesForm
          key={memberSelectValue}
          memberChoices={memberChoices}
          activities={activities}
          fields={activityIds.toJS()}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      </div>
    );
  }
}

ReassignActivities.propTypes = {
  member: ImmutablePropTypes.map.isRequired,
  practice: ImmutablePropTypes.map.isRequired,
  memberChoices: ImmutablePropTypes.list.isRequired,
  currentStep: React.PropTypes.string.isRequired,
};

export function mapStateToProps(state) {
  const loading = isPending(state.responses.getIn(['getCollection', ACTIVITIES]));

  return {
    activities: state.collections.getIn([ACTIVITIES, 'items'], List()),
    memberSelectValue: state.ui.getIn(['selectState', REASSIGN_MEMBER]),
    loading,
  };
}

export function mapDispatchToProps(dispatch, props) {
  const { currentStep, member, practice } = props;

  const additionalParams = {
    responsible_staff: member.getIn(['user', 'staffdetail', 'id']),
    status: ACTIVITY_STATUSES.COMPLETED,
    exclude_completed: true,
    practice: practice.get('id')
  };

  const nextStepIndex = USER_REASSIGN_STEPS.indexOf(currentStep) + 1;
  const nextStep = USER_REASSIGN_STEPS.get(nextStepIndex);

  return {
    loadActivities: () =>
      dispatch(getAllCollection(ACTIVITIES, { additionalParams })),
    setDefaultMember: val => dispatch(setSelectValue(REASSIGN_MEMBER, val)),
    clearDefaultMember: () => dispatch(clearSelect(REASSIGN_MEMBER)),
    handleSubmit: data =>
      dispatch(changeStepAndAddReassignData(REASSIGN_STEPS, nextStep, currentStep, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReassignActivities);
