import React from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import { createItemRunCallbackAndCloseModal } from '^/actions/actionSequences';
import { closeModal } from '^/actions/modals';
import PublishDocumentVersionForm from '^/components/admin/template-documents/modals/PublishDocumentVersionForm';
import PureComponent from '^/components/common/PureComponent';
import { GROUP_CREATED_TEMPLATE_DOCUMENTS } from '^/consts/collectionKeys';


class PublishGroupDocumentModal extends PureComponent {

  constructor(props) {
    super(props);
    this.onPublish = this.onPublish.bind(this);
    this.onNavigateToNewItem = this.onNavigateToNewItem.bind(this);
  }

  render() {
    const { data, response, onCancel } = this.props;
    const message = `Are you sure you want to publish this as a new version of ${data.name || 'Untitled'}?`;

    return (
      <div className="p-1">
        <PublishDocumentVersionForm
          response={response}
          onSubmit={this.onPublish}
          onCancel={onCancel}
          message={message}
        />
      </div>
    );
  }

  onPublish(formData) {
    const { draftId } = this.props;
    this.props.createItemRunCallbackAndCloseModal(
      GROUP_CREATED_TEMPLATE_DOCUMENTS,
      {
        ...this.props.data,
        ...formData,
        source_item_id: draftId
      },
      undefined,
      undefined,
      this.onNavigateToNewItem
    );
  }

  onNavigateToNewItem(model, data, collectionName, formName, newItem) {
    this.props.routePush(`/page/groups/group-documents/version/${newItem.id}/`)
  }
}

export { PublishGroupDocumentModal as UnconnectedPublishGroupDocumentModal };

function mapStateToProps(state) {
  return {
    response: state.responses.getIn(['createItem', GROUP_CREATED_TEMPLATE_DOCUMENTS])
  };
}

export default connect(mapStateToProps, {
  createItemRunCallbackAndCloseModal,
  routePush: routeActions.push,
  onCancel: closeModal
})(PublishGroupDocumentModal);
