import { fromJS, Map } from 'immutable';

import * as insights from '^/actions/insights';

export function insightData(state = Map(), action) {
  switch (action.type) {
    case insights.LOAD_INSIGHT_DATA.SUCCESS:
      return state.set(action.meta.insight, fromJS(action.payload));
    default:
      return state;
  }
}

export function insightFilters(state = Map(), action) {
  switch (action.type) {
    case insights.LOAD_INSIGHT_DATA.REQUEST:
      const { insight, filters } = action.meta;
      return state.set(insight, filters);
    default:
      return state;
  }
}

export function insightTableSort(state = Map(), action) {
  switch (action.type) {
    case insights.SORT_INSIGHTS_BY:
      const { insight, sortByKey, isAscending } = action.payload;
      return state.set(insight, Map({ sortByKey, isAscending}));
    default:
      return state;
  }
}

export function insightTablePagination(state = Map(), action) {
  switch (action.type) {
    case insights.SHOW_ALL_INSIGHT_TABLE_RESULTS:
      return state.set(action.payload, true);
    default:
      return state;
  }
}

export function lastDatabaseSync(state = Map(), action) {
  switch (action.type) {
    case insights.LOAD_LAST_DATABASE_SYNC.SUCCESS:
      return state.set('allInsights', fromJS(action.payload));
    default:
      return state;
  }
}
