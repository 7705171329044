import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';

import Loading from '^/components/app/content/Loading';
import AdminListItem from '^/components/admin/content/list/AdminListItem';
import AdminListPagination from '^/components/admin/content/list/AdminListPagination';
import AdminListSearch from '^/components/admin/content/list/AdminListSearch';
import SectorFilter from '^/components/admin/content/list/SectorFilter';
import Table from '^/components/common/content/Table';
import ScrollableTableContainer from '^/components/common/content/ScrollableTableContainer';


const SortIcon = ({ sortName, sortBy, reversed, setSortBy }) => (
  <span
    className={classNames('sort-icon', {sorted: sortName === sortBy, reversed})}
    onClick={() => setSortBy && setSortBy(sortName)}
  />
);

const AdminListHeader = ({ header, setSortBy, sortBy, reversed }) => {
  const { display_name, sortable, name, sortByName } = header.toObject();

  return (
    <th>
      {display_name}
      {sortable && (
        <SortIcon sortName={sortByName || name.join('__')} sortBy={sortBy} setSortBy={setSortBy} reversed={reversed} />
      )}
    </th>
  );
};

const AdminListItems = ({
  title, collection, items, noItemsMessage, fields,
  listName, noSearch, sortBy, setSortBy, sortByReversed,
  hidePagination, pageSize, isLoading,
  removeItem, search, filterBySector, loadPage, isSectorFiltered,
  filters,
}) => (
  <div className="admin-list-container">
    <div className="row clearfix mb-1 mt-1">
      {title && (
        <div className={isSectorFiltered ? 'col-1' : 'col-1-2'}>
          <h3>{title}</h3>
        </div>
      )}
      {isSectorFiltered && collection && (
        <div className="col-1-2">
          <SectorFilter
            filterBySector={filterBySector}
            sector={filters.get('sector')}
          />
        </div>
      )}
      <div className={(title || isSectorFiltered) ? 'col-1-2' : 'col-1'}>
        {!noSearch && collection && (
          <AdminListSearch
            onSubmit={({searchString}) => search(searchString)}
            onClear={() => search()}
            form={listName + '_search'}
            loading={isLoading}
            initialValues={{searchString: collection.get('searchString')}}
          />
        )}
      </div>
    </div>

    {isLoading ? (
      <Loading />
    ) : (
      <ScrollableTableContainer>
        <Table
          className="padded"
          renderRow={(item, idx, columns) =>
            <AdminListItem
              key={idx}
              item={item}
              fields={columns}
              removeItem={removeItem}
            />
          }
          renderHeaders={headers => headers.map((header, idx) =>
            <AdminListHeader
              key={idx}
              header={header}
              sortBy={sortBy}
              setSortBy={setSortBy}
              reversed={sortByReversed}
            />
          )}
          rows={collection ? collection.get('items') : items}
          columns={fields}
          emptyMessage={noItemsMessage || 'None Found.'}
        />
      </ScrollableTableContainer>
    )}

    {!hidePagination && collection && (
      <AdminListPagination collection={collection} pageSize={pageSize} loadPage={loadPage} />
    )}
  </div>
);

AdminListItems.propTypes = {
  title: React.PropTypes.string,
  collection: ImmutablePropTypes.map, // Must provide one of these
  items: ImmutablePropTypes.list,     //
  noItemsMessage: React.PropTypes.string,
  fields: ImmutablePropTypes.list.isRequired,
  listName: React.PropTypes.string,
  noSearch: React.PropTypes.bool,
  hidePagination: React.PropTypes.bool,
  pageSize: React.PropTypes.number,
  isLoading: React.PropTypes.bool,
  removeItem: React.PropTypes.func,
  setSortBy: React.PropTypes.func,
  search: React.PropTypes.func,
  loadPage: React.PropTypes.func,
  filters: ImmutablePropTypes.map.isRequired,
};

export default AdminListItems;
