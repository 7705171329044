import React from 'react';

import { MODELS } from '^/permissions/capabilities';
import {
  ADMIN_QPS_FILE_EDIT_FIELDS,
  ADMIN_QPS_FILE_MODEL,
} from '^/components/admin/qps-page/qps-page';

import PureComponent from '^/components/common/PureComponent';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminChangeLog from '^/components/admin/content/change-log/AdminChangeLog';


class ManageQPSFile extends PureComponent {

  render() {
    const { params: { uuid } } = this.props;

    return (
      <div>
        <AdminContainer title="Edit QPS File">
          <AdminEdit
            permsModel={MODELS.TEMPLATE_DOCUMENT}
            itemId={uuid}
            model={ADMIN_QPS_FILE_MODEL}
            fields={ADMIN_QPS_FILE_EDIT_FIELDS}
            title="QPS File"
            className="btn-left-align"
          />
        </AdminContainer>
        <AdminChangeLog model="QPSFile" id={uuid} />
      </div>
    );
  }
}

export default ManageQPSFile;
