import composeReducers from '^/reducers/composeReducers';
import { Map, List, fromJS } from 'immutable';
import { getIn } from '^/utils';
import {
  GET_COLLECTION,
  ADD_TO_COLLECTION,
  DELETE_FROM_COLLECTION,
  CLEAR_COLLECTION,
  CLEAR_COLLECTION_FILTER,
} from '^/actions/collections';
import {
  GROUP_TEMPLATE_DOCUMENT_FOLDERS,
  MY_TASKS,
  PRACTICES,
  TEMPLATE_FOLDERS,
  TASKS,
} from '^/consts/collectionKeys';
import { UPDATE_ITEM, CREATE_ITEM } from '^/actions/items';
import {
  UPGRADE_DEMO,
  MARK_TASK_ASSIGNEE_COMPLETE,
  GROUP_PUBLISH_NEWS_ITEM,
  GROUP_UNPUBLISH_NEWS_ITEM,
} from '^/actions/actions';
import { LIST_NAME_PREFIX } from '^/components/admin/content/list/AdminList';

function collectionResult(collectionMap, action) {
  let collectionItems;
  if (action.type === GET_COLLECTION.SUCCESS) {
    const results = fromJS(getIn(action, ['payload', 'results'], []));
    if (action.meta.shouldAppend) {
      collectionItems = collectionMap.get('items', List()).concat(results);
    } else {
      collectionItems = results;
    }
  } else {
    collectionItems = collectionMap.get('items');
  }

  const hasMore = Boolean(getIn(action, ['payload', 'next'])) || false;
  const newState = collectionMap
    .set('searchString', action.meta.search)
    .set('filters', action.meta.filters)
    .set('page', action.meta.page)
    .set('hasMore', hasMore)
    .set(
      'count',
      getIn(action, ['payload', 'count'], collectionMap.get('count'))
    );

  if (collectionItems) {
    return newState.set('items', collectionItems);
  }
  return newState;
}

function collectionsReducer(state = Map(), action) {
  const updateMultipleItems = collectionNames => updater =>
    List(collectionNames).reduce(
      (_state, collectionName) =>
        _state.updateIn([collectionName, 'items'], List(), updater),
      state
    );
  const updateItems = updater =>
    updateMultipleItems([
      action.meta.collectionName,
      LIST_NAME_PREFIX + action.meta.collectionName,
    ])(updater);

  switch (action.type) {
    case GET_COLLECTION.REQUEST:
    case GET_COLLECTION.SUCCESS:
      const existing = state.get(action.meta.collectionName, Map());
      return state.set(
        action.meta.collectionName,
        collectionResult(existing, action)
      );

    case CREATE_ITEM.SUCCESS:
    case ADD_TO_COLLECTION.SUCCESS: {
      // When a group template document folder is created, append to the TEMPLATE_FOLDERS
      // collection - GROUP_TEMPLATE_DOCUMENT_FOLDERS is create/update only.
      if (action.meta.collectionName === GROUP_TEMPLATE_DOCUMENT_FOLDERS) {
        return state.updateIn(
          [TEMPLATE_FOLDERS, 'items'],
          item => item && item.push(fromJS(action.payload))
        );
      }
      const _updateItems =
        // is it a task and is is assigned to the current user
        action.meta.collectionName === TASKS && action.payload.self_assignee
          ? updateMultipleItems([TASKS, MY_TASKS])
          : updateItems;
      return _updateItems(items => items.unshift(fromJS(action.payload)));
    }
    case MARK_TASK_ASSIGNEE_COMPLETE.SUCCESS: {
      const assigneeId = action.payload.assignee_id;
      return state.updateIn([TASKS, 'items'], items =>
        items.map(task =>
          task.update('assignees', assignees =>
            assignees.map(assignee =>
              assignee.get('assignee_id') === assigneeId
                ? assignee.merge(action.payload)
                : assignee
            )
          )
        )
      );
    }
    case DELETE_FROM_COLLECTION.SUCCESS: {
      const id = action.meta.id;
      return updateItems(items => items.filter(item => item.get('id') !== id));
    }
    case UPDATE_ITEM.SUCCESS: {
      const id = action.payload.id;
      const _updateItems =
        // When a group template document folder is updated, update the TEMPLATE_FOLDERS
        // collection - GROUP_TEMPLATE_DOCUMENT_FOLDERS is create/update only.
        action.meta.collectionName === GROUP_TEMPLATE_DOCUMENT_FOLDERS
          ? updateMultipleItems([TEMPLATE_FOLDERS])
          : updateItems;
      return _updateItems(items =>
        items.map(item =>
          item.get('id') === id ? item.merge(fromJS(action.payload)) : item
        )
      );
    }
    case CLEAR_COLLECTION: {
      const collectionName = action.payload;
      return state.set(collectionName, Map());
    }
    case CLEAR_COLLECTION_FILTER: {
      const collectionName = action.payload;
      return state.setIn([collectionName, 'filters'], Map());
    }
    case UPGRADE_DEMO.SUCCESS: {
      return state.setIn([PRACTICES, 'items'], List());
    }
    case GROUP_PUBLISH_NEWS_ITEM.SUCCESS:
    case GROUP_UNPUBLISH_NEWS_ITEM.SUCCESS: {
      const collectionItemIndex = state
        .getIn([action.meta.collectionName, 'items'])
        .findIndex(item => item.get('id') === action.payload.id);
      if (collectionItemIndex !== -1) {
        return state.setIn(
          [action.meta.collectionName, 'items', collectionItemIndex],
          fromJS(action.payload)
        );
      }
      return state;
    }
    default:
      return state;
  }
}

export default composeReducers([collectionsReducer], Map());
