import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

import { selectCurrentPractice } from './practice';
import { StoreState } from '^/store/types';
import { CurrentUserProfile, UserTypeInPractice } from '^/types/accounts';
import { CurrentPractice } from '^/types/practices';
import {
  BaseItem,
  HealthcheckStatus,
  HealthcheckCategoriesProgress,
  HealthcheckCategoriesMerged,
} from '^/components/app/healthcheck/types';

export const selectNonImmutableUserProfile = createSelector<
  Pick<StoreState, 'userProfile'>,
  Map<string, unknown> | null,
  CurrentUserProfile | null
>(
  state => state.userProfile,
  profile => (profile ? profile.toJS() : null)
);

export const selectNonImmutableCurrentPractice = createSelector<
  Pick<StoreState, 'currentPractice'>,
  Map<string, unknown> | null,
  CurrentPractice | null
>(
  state => state.currentPractice,
  practice => (practice ? practice.toJS() : null)
);

export const selectCurrentUserId = createSelector(
  selectNonImmutableUserProfile,
  userProfile => userProfile?.id
);

export const PRACTICE_ADMIN_TYPES = [
  UserTypeInPractice.PRACTICE_ADMIN,
  UserTypeInPractice.PRACTICE_MANAGER,
];

export const selecthasCreateHealthcheckPermissions = createSelector(
  selectNonImmutableUserProfile,
  userProfile =>
    Boolean(
      userProfile &&
        PRACTICE_ADMIN_TYPES.includes(
          userProfile.user_type_in_current_practice
        ) &&
        userProfile.staffdetail?.current_practice.has_healthcheck_access
    )
);

export const selecthasContinueHealthcheckPermissions = createSelector(
  selectNonImmutableUserProfile,
  userProfile =>
    Boolean(
      userProfile &&
        PRACTICE_ADMIN_TYPES.includes(
          userProfile.user_type_in_current_practice
        ) &&
        userProfile.staffdetail?.current_practice
          .has_inprogress_or_completed_healthchecks
    )
);

export const getInProgressHealthcheck = (
  userProfile: CurrentUserProfile | null
) => {
  return (
    userProfile?.staffdetail?.current_practice?.in_progress_healthcheck ?? null
  );
};

export const selectInProgressHealthcheck = createSelector(
  selectNonImmutableUserProfile,
  userProfile => getInProgressHealthcheck(userProfile)
);

export const selectBannerButtonText = createSelector(
  selectNonImmutableUserProfile,
  selectInProgressHealthcheck,
  (userProfile, inProgressHealthcheck) => {
    if (!inProgressHealthcheck) {
      return 'Start Healthcheck';
    }

    if (inProgressHealthcheck.user.id === userProfile?.id) {
      return inProgressHealthcheck.status === HealthcheckStatus.REVIEW
        ? 'View Healthcheck'
        : 'Continue Healthcheck';
    }

    return 'View Healthcheck Info';
  }
);

export const getFirstIncompleteCategoryId = (
  categories: HealthcheckCategoriesProgress
) => {
  const match = categories.find(({ is_completed }) => !is_completed);

  if (match) {
    return match.id;
  }

  return categories.length ? categories[categories.length - 1].id : null;
};

export const selectHealthcheckById = (
  state: StoreState,
  healthcheckId: string
) => {
  const healthcheckById = state.healthcheck.healthcheckById;
  if (healthcheckById) {
    return healthcheckById[healthcheckId];
  }
  return null;
};

export const selectCurrentPracticeHealthCheck = createSelector(
  selectInProgressHealthcheck,
  selectCurrentPractice,
  (inProgressHealthcheck, currentPractice) => {
    if (inProgressHealthcheck) {
      return {
        ...inProgressHealthcheck,
        user: {
          ...inProgressHealthcheck.user,
          name: currentPractice
            .get('members')
            .find(
              (member: Map<string, unknown>) =>
                member.get('id') === inProgressHealthcheck.user.id,
              undefined,
              Map()
            )
            .get('name'),
        },
      };
    }
  }
);

export const selectCategoryIndexById = createCachedSelector(
  (categories: HealthcheckCategoriesMerged<BaseItem> | null) => categories,
  (
    _categories: HealthcheckCategoriesMerged<BaseItem> | null,
    categoryId: string
  ) => categoryId,
  (categories, categoryId) =>
    categories?.findIndex(category => category.id === categoryId)
)((_categories, categoryId) => categoryId);

export const selectNextCategoryId = createCachedSelector(
  selectCategoryIndexById,
  (categories: HealthcheckCategoriesMerged<BaseItem> | null) => categories,
  (categoryIndex, categories) =>
    categoryIndex !== undefined && categories
      ? categories[categoryIndex + 1]?.id
      : null
)((_categories, categoryId) => categoryId);

export const selectPrevCategoryId = createCachedSelector(
  selectCategoryIndexById,
  (categories: HealthcheckCategoriesMerged<BaseItem> | null) => categories,
  (categoryIndex, categories) =>
    categoryIndex !== undefined && categories
      ? categories[categoryIndex - 1]?.id
      : null
)((_categories, categoryId) => categoryId);
