import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Loading from '^/components/app/content/Loading';
import { isPending } from '^/consts/responseStates';
import { patchItem } from '^/actions/items';
import PureComponent from '^/components/common/PureComponent';


export class DeactivatePractice extends PureComponent {
  render() {
    const { practice, response, model, collectionName } = this.props;

    if (isPending(response) || !practice) {
      return <Loading className="inline" />;
    }

    const isActive = practice.get('is_active', false);

    return (
      <button
        className="btn btn-warning"
        onClick={() => this.props.patchItem(model, practice.get('id'), {is_active: !isActive}, collectionName)}
      >
        { isActive ? 'Deactivate' : 'Activate' }
      </button>
    );
  }

}

DeactivatePractice.propTypes = {
  practice: ImmutablePropTypes.map,
  model: React.PropTypes.string.isRequired,
  collectionName: React.PropTypes.string.isRequired,
};

export const mapStateToProps = (state, props) => ({
  response: state.responses.getIn(['updateItem', props.collectionName])
});

export default connect(mapStateToProps, { patchItem })(DeactivatePractice);
