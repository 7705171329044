import { openMaintenanceModal } from '^/actions/modals';


export default store => next => action => {
  if (
    action.type &&
    typeof action.type === 'string' &&
    action.type.endsWith('_FAILURE') &&
    action.error &&
    action.payload.status === 503
  ) {
    store.dispatch(openMaintenanceModal());
  }
  return next(action);
};
