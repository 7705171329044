import React from 'react';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import PureComponent from '^/components/common/PureComponent';
import { getLatestTerms } from '^/actions/actions';
import { Map } from 'immutable';
import Loading from '^/components/app/content/Loading';

import {
  ADMIN_TERMS_CREATE,
  ADMIN_TERMS_MODEL,
  ADMIN_TERMS_EDIT_FIELDS,
} from './terms';

class CreateTerms extends PureComponent {
  componentWillMount() {
    this.props.getLatestTerms();
  }

  handleComplete(model, data, collectionName, formName, termItem) {
    this.props.routePush('/admin/terms/' + termItem.id);
  }

  render() {
    if (!this.props.latestTerms) {
      return <Loading />;
    }

    return (
      <div>
        <AdminContainer title="Terms">
          <AdminCreate
            controlName={ADMIN_TERMS_CREATE}
            onComplete={this.handleComplete.bind(this)}
            collectionName={ADMIN_TERMS_CREATE}
            model={ADMIN_TERMS_MODEL}
            fields={ADMIN_TERMS_EDIT_FIELDS}
            getInitialValues={() => this.props.latestTerms.toJS()}
            title="Create Terms"
          />
        </AdminContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {latestTerms: state.latestTerms || Map()};
}


export default connect(mapStateToProps, { routePush: routeActions.push, getLatestTerms })(CreateTerms);
