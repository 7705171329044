import React from 'react';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';
import { MODELS } from '^/permissions/capabilities';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';
import PureComponent from '^/components/common/PureComponent';

import {
  ADMIN_TEMPLATE_DOCUMENT_CATEGORY_CREATE,
  ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MODEL,
  ADMIN_TEMPLATE_DOCUMENT_CATEGORY_EDIT_FIELDS,
  ADMIN_TEMPLATE_DOCUMENT_CATEGORY_VIEW_FIELDS,
  ADMIN_TEMPLATE_DOCUMENT_CATEGORY_ROW_FIELDS,
} from './template-documents';


class TemplateDocumentCategoriesAdmin extends PureComponent {
  render() {
    return (
      <AdminContainer title="Template Document Categories">
        <AdminCreate
          permsModel={MODELS.TEMPLATE_DOCUMENT}
          readOnlyFields={ADMIN_TEMPLATE_DOCUMENT_CATEGORY_VIEW_FIELDS}
          controlName={ADMIN_TEMPLATE_DOCUMENT_CATEGORY_CREATE}
          collectionName={ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MODEL}
          model={ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MODEL}
          fields={ADMIN_TEMPLATE_DOCUMENT_CATEGORY_EDIT_FIELDS}
          title="Add Template Document Category" className="btn-left-align"
        />

        <AdminList
          permsModel={MODELS.TEMPLATE_DOCUMENT}
          model={ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MODEL}
          fields={ADMIN_TEMPLATE_DOCUMENT_CATEGORY_ROW_FIELDS}
        />
      </AdminContainer>
    );
  }
}

export default connect(undefined, { routePush: routeActions.push })(TemplateDocumentCategoriesAdmin);
