import React from 'react';
import { connect } from 'react-redux';
import { OrderedMap, Map } from 'immutable';
import _ from 'underscore';

import { MODELS, CAPABILITIES } from '^/permissions/capabilities';
import { hasSucceeded } from '^/consts/responseStates';
import {
  setPendingUploadInForm,
  autosaveWorkingVersion,
  resetResponse,
} from '^/actions/actions';
import {
  ADMIN_TEMPLATE_DOCUMENT_WORKING_VERSIONS,
  ADMIN_TEMPLATE_DOCUMENT_VERSION,
  ADMIN_TEMPLATE_DOCUMENT,
} from '^/consts/collectionKeys';
import { saveAsWorkingDraftAndReloadTemplateVersion } from '^/actions/actionSequences';
import {
  loadItem, getItemOptions,
} from '^/actions/items';
import {
  openDiscardWorkingVersionModal, openPublishAsCurrentVersionModal, openPublishAsNewVersionModal,
} from '^/actions/modals';

import IfCan from '^/components/common/IfCan';
import IfCant from '^/components/common/IfCant';
import PureComponent from '^/components/common/PureComponent';
import EditTemplateDocumentForm from './EditTemplateDocumentForm';
import Loading from '^/components/app/content/Loading';
import AdminChangeLog from '^/components/admin/content/change-log/AdminChangeLog';
import UserCreatedMarkup from '^/components/common/content/UserCreatedMarkup';


import {
  ADMIN_TEMPLATE_DOCUMENT_MODEL,
  ADMIN_TEMPLATE_DOCUMENT_VERSION_EDIT_FIELDS,
  ADMIN_TEMPLATE_DOCUMENT_VERSION_VIEW_FIELDS,
  DOCUMENT_TYPE_CONTENT_FIELDS,
} from './template-documents';


function transform (fieldConfig, fields, item) {
  return fieldConfig
    .setIn(['content', 'isLandscape'], item.get('is_landscape'))
    .setIn(['content', 'read_only'], false)
    .setIn(['content_file', 'read_only'], false)
    .setIn(['content_file', 'fileName'], item.get('content_file_name'))
    .mergeIn(['content'], Map({ includeVariables: true, variablesSector: item.get('sector') }))
    .filter(
      (config, fieldName) =>
        !DOCUMENT_TYPE_CONTENT_FIELDS.get(fieldName) ||
        DOCUMENT_TYPE_CONTENT_FIELDS.get(fieldName).contains(item.get('type'))
    );
}

function getFields(item) {
  return ADMIN_TEMPLATE_DOCUMENT_VERSION_EDIT_FIELDS.filter(
    fieldName =>
      !DOCUMENT_TYPE_CONTENT_FIELDS.get(fieldName) ||
      DOCUMENT_TYPE_CONTENT_FIELDS.get(fieldName).contains(item.get('type'))
  );
}

function getValues(templateDocument, templateDocumentVersion) {
  const values = templateDocument.merge(templateDocumentVersion);
  const { working_version } = templateDocumentVersion.toObject();

  if (working_version && !working_version.isEmpty()) {
    return values.merge(working_version);
  }

  return values;
}


const TemplateDocumentView = ({templateDocumentVersion}) => {
  const { content, content_file, content_file_name } = templateDocumentVersion.toObject();

  return (
    <div className="admin-edit-create-form">
      <h4>Template Document</h4>
      <div className="mb-1-4 admin-edit-create-field">
        {
          content ? (
            <div className="row mt-1 document-bg">
              <div className="col-1">
                <div className="document document-content">
                  <UserCreatedMarkup text={content} />
                </div>
              </div>
            </div>
          ) : (
           <div className="admin-edit-create-field mt-1">
              <label>Download</label>
              <a href={content_file} target="_blank" download>{content_file_name}</a>
            </div>
          )
        }
      </div>
    </div>
  );
};

const TemplateDocumentEdit = ({
  templateDocument, setPendingUpload, itemOptions, templateDocumentVersion, autosaveWorkingDraft,
  discardWorkingVersion, saveAsWorkingDraft, saveAsWorkingDraftResponse, publishAsNewVersionResponse,
  publishAsCurrentVersionResponse, publishAsCurrentVersion, publishAsNewVersion, autosaveAsWorkingDraftResponse
}) => {
  if (!itemOptions || itemOptions.isEmpty()) {
    return <Loading />;
  }

  const fields = getFields(templateDocument);
  const fieldConfig = OrderedMap(fields.map(field =>
    [field, itemOptions.getIn(field.split('.').join('.children.').split('.'), Map())]
  ));

  const values = getValues(templateDocument, templateDocumentVersion);

  // The form needs to be recreated when the values change
  const formName = ADMIN_TEMPLATE_DOCUMENT_VERSION + templateDocumentVersion.get('id') + values.toString();

  return (
    <EditTemplateDocumentForm
      key={formName}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit={() => {}}
      saveAsWorkingDraft={(data) => saveAsWorkingDraft(data, templateDocumentVersion.get('id'))}
      autosaveWorkingDraft={autosaveWorkingDraft}
      saveAsWorkingDraftResponse={saveAsWorkingDraftResponse}
      autosaveAsWorkingDraftResponse={autosaveAsWorkingDraftResponse}
      discardWorkingVersion={discardWorkingVersion}
      publishAsNewVersion={(data) => publishAsNewVersion(templateDocumentVersion, templateDocument, data)}
      publishAsNewVersionResponse={publishAsNewVersionResponse}
      publishAsCurrentVersion={(data) => publishAsCurrentVersion(templateDocumentVersion, data)}
      publishAsCurrentVersionResponse={publishAsCurrentVersionResponse}
      form={formName}
      formName={formName}
      fields={fields.toJS()}
      fieldConfig={fieldConfig}
      initialValues={values.toJS()}
      transform={((_fieldConfig, _fields) => transform(_fieldConfig, _fields, values))}
      setPendingUploadInForm={setPendingUpload}
      templateDocumentVersion={templateDocumentVersion}
      isEditing
    />
  );
};

const PermsWrappedEditTemplateDocument = (props) =>
  <div>
    <IfCan capability={CAPABILITIES.UPDATE} model={props.permsModel}>
      <TemplateDocumentEdit {...props} />
    </IfCan>
    <IfCant capability={CAPABILITIES.UPDATE} model={props.permsModel}>
      <TemplateDocumentView {...props} />
    </IfCant>
  </div>;

export class EditTemplateDocument extends PureComponent {

  constructor (props) {
    super(props);
    this.autosaveWorkingDraftThrottle =  _.throttle(this.autosaveWorkingDraft.bind(this), 10000, true);
}

  autosaveWorkingDraft(data) {
    const { templateDocumentVersion } = this.props;
    this.props.autosaveWorkingDraft(data, templateDocumentVersion.get('id'))
  }

  loadTemplateDocument(props) {
    const { templateDocumentId } = props;
    props.loadItem(ADMIN_TEMPLATE_DOCUMENT_MODEL, templateDocumentId);
  }

  loadLatestVersion(versionId) {
    if (versionId) {
      this.props.getItemOptions(ADMIN_TEMPLATE_DOCUMENT_VERSION, versionId);
      this.props.loadItem(ADMIN_TEMPLATE_DOCUMENT_VERSION, versionId);
    }
  }

  componentWillMount() {
    this.loadTemplateDocument(this.props);
    const { templateDocument } = this.props;
    const latestVersion = templateDocument && templateDocument.get('latest_version');

    if (latestVersion) {
      this.loadLatestVersion(latestVersion);
    }
  }

  componentWillUnmount() {
    this.props.resetResponse('loadItem');
  }

  componentWillUpdate(nextProps) {
    const { templateDocumentId, templateDocument } = nextProps;
    if (this.props.templateDocumentId !== templateDocumentId) {
      this.loadTemplateDocument(nextProps);
    }

    const latestVersion = templateDocument && templateDocument.get('latest_version');
    const oldLatestVersion = this.props.templateDocument && this.props.templateDocument.get('latest_version');

    if (latestVersion !== oldLatestVersion) {
      this.loadLatestVersion(latestVersion);
    }
  }

  render() {
    const { templateDocument, templateDocumentVersion, loadItemResponse } = this.props;

    if (!templateDocument || !templateDocumentVersion || !hasSucceeded(loadItemResponse)) {
      return <Loading />;
    }

    return (
      <div>
        <PermsWrappedEditTemplateDocument
          {...this.props}
          permsModel={MODELS.TEMPLATE_DOCUMENT}
          readOnlyFields={ADMIN_TEMPLATE_DOCUMENT_VERSION_VIEW_FIELDS}
          model={ADMIN_TEMPLATE_DOCUMENT_MODEL}
          getFields={getFields}
          title="Edit Template Document"
          className="btn-left-align"
          transform={transform}
          autosaveWorkingDraft={this.autosaveWorkingDraftThrottle}
        />
        <AdminChangeLog model="TemplateDocumentVersion" id={templateDocumentVersion.get('id')} />
      </div>
    );
  }

}

function mapStateToProps(state, props) {
  const templateDocumentId = props.params.uuid;
  const itemOptions = state.items.getIn(['options', ADMIN_TEMPLATE_DOCUMENT_VERSION], Map());
  return {
    templateDocumentId,
    templateDocument: state.items.get(ADMIN_TEMPLATE_DOCUMENT),
    templateDocumentVersion: state.items.get(ADMIN_TEMPLATE_DOCUMENT_VERSION),
    itemOptions: itemOptions.getIn(['actions', 'POST']) || itemOptions.getIn(['actions', 'PUT']),
    loadItemResponse: state.responses.getIn(['loadItem', ADMIN_TEMPLATE_DOCUMENT_VERSION]),
    saveAsWorkingDraftResponse: state.responses.getIn(['createItem', ADMIN_TEMPLATE_DOCUMENT_WORKING_VERSIONS]),
    autosaveAsWorkingDraftResponse: state.responses.get('autosaveWorkingVersion'),
    publishAsNewVersionResponse: state.responses.getIn(['createItem', ADMIN_TEMPLATE_DOCUMENT_VERSION]),
    publishAsCurrentVersionResponse: state.responses.getIn(['updateItem', ADMIN_TEMPLATE_DOCUMENT_VERSION]),
  };
}

export default connect(
  mapStateToProps,
  {
    loadItem, getItemOptions, setPendingUpload: setPendingUploadInForm,
    discardWorkingVersion: openDiscardWorkingVersionModal,
    saveAsWorkingDraft: saveAsWorkingDraftAndReloadTemplateVersion,
    autosaveWorkingDraft: autosaveWorkingVersion,
    publishAsCurrentVersion: openPublishAsCurrentVersionModal,
    publishAsNewVersion: openPublishAsNewVersionModal,
    resetResponse
  }
)(EditTemplateDocument);
