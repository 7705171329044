import { List, Map } from 'immutable';
import { createSelector } from 'reselect';

import { PAGINATION_PAGE_SIZE } from '^/components/app/groups/insights/constants';

export const selectInsights = state => state.insights;

export const selectInsightData = (insight) => createSelector(
  selectInsights,
  insightState => insightState.getIn(['insightData', insight])
);

export const selectInsightFilters = (insight) => createSelector(
  selectInsights,
  insightState => insightState.getIn(['insightFilters', insight], Map())
);

export const selectInsightFilterByKey = (insight, filterKey) => createSelector(
  selectInsightFilters(insight),
  filter => filter.get(filterKey)
);

export const selectInsightTableSort = (insight) => createSelector(
  selectInsights,
  insightState => insightState.getIn(['insightTableSort', insight], Map())
);

export const selectInsightTableSortByKey = (insight) => createSelector(
  selectInsightTableSort(insight),
  sort => sort.get('sortByKey')
);

export const selectInsightTableSortIsAscending = (insight) => createSelector(
  selectInsightTableSort(insight),
  sort => sort.get('isAscending')
);

export const selectInsightTablePagination = (insight) => createSelector(
  selectInsights,
  insightState => insightState.getIn(['insightTablePagination', insight], false)
);

export const selectInsightTableTotalNumberOfResults = (insight) => createSelector(
  selectInsightData(insight),
  data => List.isList(data) ? data.size : 0
);

export const getNumberOfPaginatedResults = (totalNumberOfResults, areAllShown) => (
  areAllShown || totalNumberOfResults <= PAGINATION_PAGE_SIZE ? totalNumberOfResults : PAGINATION_PAGE_SIZE
);

export const selectInsightTableNumberOfPaginatedResults = (insight) => createSelector(
  selectInsightTableTotalNumberOfResults(insight),
  selectInsightTablePagination(insight),
  (totalNumberOfResults, areAllShown) => getNumberOfPaginatedResults(totalNumberOfResults, areAllShown)
);

export const selectInsightTableData = (insight) => createSelector(
  selectInsightData(insight),
  selectInsightTableTotalNumberOfResults(insight),
  selectInsightTableSortByKey(insight),
  selectInsightTableSortIsAscending(insight),
  selectInsightTablePagination(insight),
  (data, totalNumberOfResults, sortByKey, sortIsAscending, areAllShown) => {
    const dataList = List.isList(data) ? data : List();
    const sortedData = sortIsAscending ?
      dataList.sortBy(item => item.get(sortByKey)) :
      dataList.sortBy(item => item.get(sortByKey)).reverse();
    return sortedData.slice(0, getNumberOfPaginatedResults(totalNumberOfResults, areAllShown));
  }
);

export const selectLastDatabaseSync = createSelector(
  selectInsights,
  insightState => insightState.getIn(['lastDatabaseSync', 'allInsights'], List())
);

export const selectLastUpdatedTimestampByModel = (modelName) => createSelector(
  selectLastDatabaseSync,
  lastDatabaseSync => {
    const lastDatabaseSyncForModel = lastDatabaseSync.filter(
      lastSync => lastSync.get('model_name') === modelName
    ).first();
    return lastDatabaseSyncForModel && lastDatabaseSyncForModel.get('timestamp');
  }
);
