import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import HTMLContent from '^/components/common/content/HTMLContent';
import PolicyWrapper from './PolicyWrapper';
import * as cookiesHTML from './cookies.html';

export default class CookiePolicy extends PureComponent {
  render() {
    return (
      <PolicyWrapper>
        <HTMLContent text={cookiesHTML} />
      </PolicyWrapper>
    );
  }
}
