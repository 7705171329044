import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { setCurrentTeamMember, setCollapse } from '^/actions/actions';
import PureComponent from '^/components/common/PureComponent';
import BlankOption from '^/components/app/content/BlankOption';

export const COLLAPSE_ID = 'STAFF_MEMBER_FILTER';


export class StaffMemberFilter extends PureComponent {

  componentWillMount() {
    if (!this.props.currentTeamMember) {
      this.hideDropdown();
    }
  }

  update(evt) {
    const userId = evt.target.value;
    const { users } = this.props;
    const currentTeamMember = users.find(user => user.get('id') === userId);

    this.props.setCurrentTeamMember(currentTeamMember);
  }

  showDropdown() {
    this.props.setCollapse(COLLAPSE_ID, false);
  }

  hideDropdown() {
    this.props.setCollapse(COLLAPSE_ID, true);
  }

  clear() {
    this.props.setCurrentTeamMember(null);
    this.hideDropdown();
  }

  render() {
    const { showDropdown, currentTeamMember, users, label } = this.props;

    return showDropdown ? (
      <span className="inline-link">
        {label}: <select
          value={currentTeamMember && currentTeamMember.get('id')}
          onChange={evt => this.update(evt)}
        >
          <BlankOption disabled={currentTeamMember} />
          {users.map(user =>
            <option key={user.get('id')} value={user.get('id')}>
              {user.getIn(['staffdetail', 'full_name'])}
            </option>
          )}
        </select> <a onClick={() => this.clear()}>
          Clear
        </a>
      </span>
    ) : (
      <span className="inline-link">
        <a onClick={() => this.showDropdown()}>
          View by staff member...
        </a>
      </span>
    );
  }
}


export function mapStateToProps(state) {
  return {
    currentTeamMember: state.currentTeamMember,
    showDropdown: !state.ui.getIn(['collapse', COLLAPSE_ID]),
  };
}

StaffMemberFilter.defaultProps = {
  label: 'Filtered by'
};

StaffMemberFilter.propTypes = {
  users: ImmutablePropTypes.list,
  label: React.PropTypes.string,
};

export default connect(mapStateToProps, { setCurrentTeamMember, setCollapse })(StaffMemberFilter);
