import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PureComponent from '^/components/common/PureComponent';
import { connect } from 'react-redux';

import { expandUiComponent, collapseUiComponent } from '^/actions/actions';
import { patchItem } from '^/actions/items';
import { isPending } from '^/consts/responseStates';

import Loading from '^/components/app/content/Loading';

export class EditableFieldText extends PureComponent {

  componentWillUnmount() {
    this.props.onCancel();
  }

  render() {
    const { isLoading, isEditing, value, onUpdate, onCancel, startEditing, textarea, children } = this.props;

    let _input = null;

    return isEditing ? (
      <div className="editable-field">
        {textarea ? (
          <textarea rows={15} defaultValue={value} ref={c => _input = c} />
        ) : (
          <input type="text" defaultValue={value} ref={c => _input = c} />
        )} {
          isLoading && <Loading />} <a
            className="inline-edit"
            onClick={() => onUpdate(_input.value)}
          >Save</a> <a
            className="inline-edit"
            onClick={onCancel}
          >Cancel</a>
      </div>
    ) : (
      <div className="editable-field">
        {children || <span>{value}</span>} <a
          className="inline-edit"
          onClick={startEditing}
        >Change</a>
      </div>
    );
  }
}


EditableFieldText.propTypes = {
  object: ImmutablePropTypes.map.isRequired,
  model: React.PropTypes.string.isRequired,
  fieldName: React.PropTypes.string.isRequired,
};

const name = props => `EDITABLE_FIELD.${props.model}.${props.fieldName}.${props.object.get('id')}`;

function mapStateToProps(state, props) {
  return {
    isEditing: state.ui.get('expandedUiComponents').contains(name(props)),
    isLoading: isPending(state.responses.getIn(['updateItem', props.model])),
    value: props.object.get(props.fieldName),
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    startEditing: () => dispatch(expandUiComponent(name(props))),
    onCancel: () => dispatch(collapseUiComponent(name(props))),
    onUpdate: value => {
      dispatch(patchItem(props.model, props.object.get('id'), {[props.fieldName]: value}));
      dispatch(collapseUiComponent(name(props)));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (EditableFieldText);
