import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { List, Set } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { getValues } from 'redux-form';
import { getActiveMembers } from '^/models/practice';
import { withRouter } from '^/withRouter';
import {
  ALL_PRACTICE_MEMBERS,
  RESPONSIBILITIES,
  DIGITAL_RECORDS_CREATE,
  DIGITAL_RECORDS_LIST,
} from '^/consts/collectionKeys';
import { handleOnBeforeUnload, recordTypeUrlToRecordType } from '^/utils';
import { getAllCollection, getAllPracticeMembers } from '^/actions/collections';
import { createItem, loadItem, clearItem } from '^/actions/items';
import {
  resetResponse,
  clearDigitalRecordsTasks,
  deleteDigitalRecordTasks,
} from '^/actions/actions';
import { openExitRecordFormConfirmationModal } from '^/actions/modals';
import {
  transformDataByRecordType,
  getInitialValuesByRecordType,
  getRecordFormByType,
  FORM_NAME_BY_TYPE,
  FORM_TITLE_BY_TYPE,
  COLLAPSIBLE_RECORD_TYPES,
  getDefaultInitialValuesByRecordType,
} from '^/components/app/digital-tools/records/digital-records/recordTypes';
import {
  createDigitalRecordAndNavToListPage,
  archiveDigitalRecordAndReloadItem,
  updateDigitalRecordAndReloadItem,
} from '^/actions/actionSequences';
import { isPending } from '^/consts/responseStates';

import Loading from '^/components/app/content/Loading';
import BackButton from '^/components/app/nav/back-button';
import PureComponent from '^/components/common/PureComponent';

export class RecordsDetailPage extends PureComponent {
  constructor(props) {
    super(props);
    this.confirmBeforeTransition = this.confirmBeforeTransition.bind(this);
    this.state = {
      currentMoment: moment(),
    };
  }
  

  componentWillMount() {
    this.clearTransitionListener = this.props.router.listenBefore(
      this.confirmBeforeTransition
    );
    this.setState({ currentMoment: moment() });
    window.onbeforeunload = handleOnBeforeUnload;
  }

  componentWillUnmount() {
    this.clearTransitionListener();
    this.props.clearItem(DIGITAL_RECORDS_LIST);
    this.props.clearDigitalRecordsTasks()
    window.onbeforeunload = null;
  }

  newStaffTasksAdded(digitalRecord, staffTasks) {
    const existingTasks = digitalRecord ? Set(digitalRecord.get('staff_tasks')) : Set([]);
    return Set(staffTasks).subtract(existingTasks);
  }
 
  confirmBeforeTransition(location, callback) {
    if (location.action === 'REPLACE') {
      callback(true);
      return;
    }

    const {
      formValues,
      digitalRecord,
      staffTasks
    } = this.props;

    const initialValues = this.getInitialValues(digitalRecord);
    const formChanged = JSON.stringify(formValues) !== JSON.stringify(initialValues);
    const newStaffTasksAdded = this.newStaffTasksAdded(digitalRecord, staffTasks);

    if (formChanged || newStaffTasksAdded.size > 0) {
      this.props.openExitRecordFormConfirmationModal(
        () => callback(true),
        () => this.props.deleteDigitalRecordTasks(newStaffTasksAdded),
      );
    } else {
      callback(true);
    }
  }

  componentDidMount() {
    const { currentPracticeId, digitalRecordId, isEditing } = this.props;

    if (isEditing && digitalRecordId) {
      this.getDigitalRecord(digitalRecordId);
    }

    this.props.resetResponse('updateDigitalRecordResponse');
    this.props.resetResponse('archiveDigitalRecordResponse');
    this.getResponsibilities();
    this.loadMembers(currentPracticeId);
  }

  loadMembers(practiceId) {
    if (practiceId) {
      this.props.getAllPracticeMembers(practiceId);
    }
  }

  getResponsibilities() {
    this.props.getAllCollection(RESPONSIBILITIES);
  }

  getDigitalRecord(digitalRecordId) {
    this.props.loadItem(DIGITAL_RECORDS_LIST, digitalRecordId);
  }

  createOrEditDigitalRecord(data) {
    const { recordType, recordTypeUrl, digitalRecordId, isEditing, staffTasks } = this.props;

    const transformedData = transformDataByRecordType(data, recordType, staffTasks);

    if (isEditing) {
      this.props.updateDigitalRecordAndReloadItem(transformedData, digitalRecordId);
    } else {
      this.props.createDigitalRecordAndNavToListPage(transformedData, recordTypeUrl);
    }
  }

  getInitialValues(digitalRecord) {
    const { recordType, isEditing, user } = this.props;

    if (isEditing && digitalRecord) {
      return getInitialValuesByRecordType(digitalRecord, recordType);
    }

    return getDefaultInitialValuesByRecordType(
      recordType,
      this.state.currentMoment,
      user
    );
  }

  handleArchive(event) {
    event.preventDefault();
    const { digitalRecordId } = this.props;

    if (digitalRecordId) {
      this.props.archiveDigitalRecordAndReloadItem(digitalRecordId);
    }
  }

  render() {
    const {
      recordType,
      responsibilities,
      responsibilitiesResponse,
      practiceMembers,
      practiceMembersResponse,
      digitalRecord,
      isEditing,
      staffTasks,
      archiveDigitalRecordResponse,
      updateDigitalRecordResponse,
      createDigitalRecordResponse,
      isArchived,
    } = this.props;
    
    if (
      isPending(responsibilitiesResponse) ||
      isPending(practiceMembersResponse) ||
      !practiceMembers
    ) {
      return <Loading />;
    }

    const RecordForm = getRecordFormByType(recordType);

    return (
      <div>
        <BackButton />
        <div className="record-title">
          {`${isEditing ? "Edit" : "Add"} ${FORM_TITLE_BY_TYPE[recordType]}`}
        </div>
        {!COLLAPSIBLE_RECORD_TYPES.includes(recordType) &&
          <div className="record-detail-line" />
        }
        <RecordForm
          responsibilities={responsibilities}
          practiceMembers={practiceMembers}
          onSubmit={(data) => this.createOrEditDigitalRecord(data)}
          initialValues={this.getInitialValues(digitalRecord)}
          handleArchive={(event) => this.handleArchive(event)}
          archiveDigitalRecordResponse={archiveDigitalRecordResponse}
          updateDigitalRecordResponse={updateDigitalRecordResponse}
          createDigitalRecordResponse={createDigitalRecordResponse}
          isEditing={isEditing}
          isArchived={isArchived}
          staff_tasks={staffTasks}
        />
      </div>
    )
  }
};

RecordsDetailPage.propTypes = {
  recordType: React.PropTypes.string,
  digitalRecordId: React.PropTypes.string,
  responsibilities: ImmutablePropTypes.list,
  responsibilitiesResponse: ImmutablePropTypes.map,
  practiceMembersResponse: ImmutablePropTypes.map,
  practiceMembers: ImmutablePropTypes.list,
  digitalRecord: ImmutablePropTypes.map,
  createDigitalRecordResponse: ImmutablePropTypes.map,
};

export function mapStateToProps(state, props) {
  const digitalRecordId = props.params.uuid;
  const isEditing = digitalRecordId ? true : false;
  const digitalRecord = state.items.get(DIGITAL_RECORDS_LIST);
  const isArchived = digitalRecord ? digitalRecord.get('is_archived') : false;
  const recordTypeUrl = props.params.recordTypeUrl;
  const recordType = recordTypeUrlToRecordType(recordTypeUrl);
  const formValues = getValues(state.form[FORM_NAME_BY_TYPE[recordType]]);
  const staffTasks = state.digitalRecordsTasks;

  return {
    digitalRecordId,
    formValues,
    isEditing,
    isArchived,
    digitalRecord,
    recordType,
    recordTypeUrl,
    staffTasks,
    user: state.userProfile,
    currentPracticeId: state.currentPractice && state.currentPractice.get('id'),
    responsibilities: state.collections.getIn(
      [RESPONSIBILITIES, 'items'],
      List()
    ),
    practiceMembers: getActiveMembers(
        state.collections.getIn(
        [ALL_PRACTICE_MEMBERS, 'items'],
        List()
      )
    ),
    practiceMembersResponse: state.responses.getIn(
      ['getCollection', ALL_PRACTICE_MEMBERS]
    ),
    responsibilitiesResponse: state.responses.getIn([
      'getCollection',
      RESPONSIBILITIES
    ]),
    createDigitalRecordResponse: state.responses.getIn(['createItem', DIGITAL_RECORDS_CREATE]),
    updateDigitalRecordResponse: state.responses.getIn(
      ['updateDigitalRecordResponse', 'default']
    ),
    archiveDigitalRecordResponse: state.responses.getIn(
      ['archiveDigitalRecordResponse', 'default']
    ),
  };
}

export default connect(
  mapStateToProps,
  {
    getAllCollection,
    getAllPracticeMembers,
    createItem,
    loadItem,
    updateDigitalRecordAndReloadItem,
    createDigitalRecordAndNavToListPage,
    archiveDigitalRecordAndReloadItem,
    resetResponse,
    openExitRecordFormConfirmationModal,
    clearItem,
    clearDigitalRecordsTasks,
    deleteDigitalRecordTasks,
  }
)(withRouter(RecordsDetailPage));
