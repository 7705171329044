import React from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import classNames from 'classnames';
import PureComponent from '^/components/common/PureComponent';

import * as keyCodes from '^/consts/keyCodes';
import { getCollectionFromUrl } from '^/actions/collections';
import {
  updateSearchItemQuery, expandUiComponent, collapseUiComponent,
  setHighlightedIndex, clearHighlightedIndex
} from '^/actions/actions';
import { isPending } from '^/consts/responseStates';
import { rangeBounded, parseImmutablePropertyString } from '^/utils';

import AdminFormError from '^/components/admin/content/edit-create/dynamic-forms/AdminFormError';
import Loading from '^/components/app/content/Loading';

const CONTROL_PREFIX = 'LOOKUP_';
const PAGE_SIZE = 5;

const LookupItemOptions = ({
  items, response, selectItem, getItemClassName, highlightedItemIndex, displayFormat, valuePath
}) => {

  if (isPending(response)) {
    return <ul className="options"><Loading /></ul>;
  }

  if (!items || items.isEmpty()) {
    return <ul className="options"><li className="option no-highlight">No results found</li></ul>;
  }

  return (
    <ul className="options">
      {items.map((item, index) => {
        const text = item.get('nullOption') ? '[None]' : parseImmutablePropertyString(item, displayFormat);
        const value = item.get('nullOption') ? null : item.getIn(valuePath);

        return (
          <li
            key={index}
            onMouseDown={event => event.preventDefault()}
            onClick={() => selectItem(value)}
            className={getItemClassName(highlightedItemIndex, index)}
          >
            {text}
          </li>
        );
      })}
      <li className="option no-highlight">
        Showing a maximum of {PAGE_SIZE} results, refine search for closer matches
      </li>
    </ul>
  );
};


export class FieldLookup extends PureComponent {

  componentWillMount() {
    this.searchItems(this.props.value || '');
  }

  componentWillUnmount() {
    this.updateSearchItemQuery('');
    this.collapseComponentAndClearIndex();
  }

  componentDidMount() {
    if (this.props.setFocusOnLoad) {
      this._input.focus();
    }
  }

  componentWillReceiveProps(newProps) {
    if (!newProps.dontUpdateSearch && this.props.value !== newProps.value) {
      this.searchItems(newProps.value);
    }
  }

  getItemClassName(highlightedIndex, index) {
    return 'option' + (highlightedIndex === index ? ' option-highlighted' : '');
  }

  searchItems(searchString = '') {
    const { config: { lookup_url, lookup_parameter, lookup_filter }, controlName, pageSize, defaultFilters } = this.props;

    this.props.getCollectionFromUrl(
      lookup_url,
      {
        filters: {
          ...defaultFilters,
          ...lookup_filter,
          [lookup_parameter]: searchString
        },
        pageSize,
      },
      controlName
    );
  }

  updateSearchItemQuery(value) {
    const { controlName } = this.props;
    this.searchItems(value);
    this.props.updateSearchItemQuery(controlName, value);
  }

  onSearchChange(event) {
    const { controlName } = this.props;
    this.props.clearHighlightedIndex(controlName);
    this.selectItem(null, false);
    this.updateSearchItemQuery(event.target.value);
  }

  selectItem(itemValue, itemSelected = true) {
    this.props.onChange(itemValue);
    if (itemSelected) {
      this._input.blur();
    }
  }

  onKeyDown(event) {
    const { highlightedItemIndex, config: { value_field_path } } = this.props;
    const items = this.getItems();

    if (event.keyCode === keyCodes.ENTER) {
      if (highlightedItemIndex !== undefined && items.count() > 0) {
        event.preventDefault();
        this.selectItem(items.get(highlightedItemIndex).getIn(value_field_path));
      }
    } else if (event.keyCode === keyCodes.ESCAPE) {
      this._input.blur();
    } else if (event.keyCode === keyCodes.DOWN_ARROW) {
      this.adjustHighlightedIndexBy(1);
    } else if (event.keyCode === keyCodes.UP_ARROW) {
      this.adjustHighlightedIndexBy(-1);
    }
  }

  getItems() {
    const { items, config: { allow_null }, searchQuery } = this.props;
    return allow_null && !searchQuery
      ? items.unshift(Map({ nullOption: true }))
      : items;
  }

  adjustHighlightedIndexBy(offset) {
    const { highlightedItemIndex, controlName } = this.props;
    const items = this.getItems();
    const nextIndex = typeof highlightedItemIndex === 'undefined' ? 0 : highlightedItemIndex + offset;

    this.props.setHighlightedIndex(controlName, rangeBounded(nextIndex, items.count()));
  }

  collapseComponentAndClearIndex() {
    const { controlName } = this.props;
    this.props.collapseUiComponent(controlName);
    this.props.clearHighlightedIndex(controlName);
  }

  render() {
    const {
      highlightedItemIndex, value,
      response, displaySelectItemOptions,
      className, placeholder,
      controlName, errors,
      config: { label, display_format_string, value_field_path },
      readOnly, searchQuery, selectedItem, noLabel,
    } = this.props;
    const items = this.getItems();

    return readOnly ? (
      <div className={classNames('mb-1-4', className)}>
        <label>{label}:</label>
        <span>{value}</span>
      </div>
    ) : (
      <div>
        <div className={classNames('mb-1-4 lookup-field', className)}>
          {!noLabel && <label>{label}:</label>}
          <div className="input-wrapper">
            <input
              type="text"
              onFocus={() => this.props.expandUiComponent(controlName)}
              onBlur={() => this.collapseComponentAndClearIndex()}
              placeholder={placeholder}
              value={selectedItem ? parseImmutablePropertyString(selectedItem, display_format_string) : searchQuery}
              onKeyDown={this.onKeyDown.bind(this)}
              onChange={this.onSearchChange.bind(this)}
              ref={(c) => this._input = c}
            />
            {
              displaySelectItemOptions &&
              <LookupItemOptions
                items={items}
                response={response}
                selectItem={this.selectItem.bind(this)}
                getItemClassName={this.getItemClassName.bind(this)}
                highlightedItemIndex={highlightedItemIndex}
                displayFormat={display_format_string}
                valuePath={value_field_path}
              />
            }
          </div>
        </div>
        <AdminFormError errors={errors} />
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  const control = props.name || props.config.lookup_url;
  const controlName = CONTROL_PREFIX + control;
  const items = state.collections.getIn([controlName, 'items'], List());

  return {
    controlName,
    items,
    selectedItem: items.find(item => item.getIn(props.config.value_field_path) === props.value),
    displaySelectItemOptions: state.ui.get('expandedUiComponents').contains(controlName),
    response: state.responses.getIn(['getCollection', controlName]),
    highlightedItemIndex: state.ui.getIn(['selectHighlightedIndex', controlName]),
    searchQuery: state.ui.getIn(['searchItemQuery', controlName], ''),
    pageSize: PAGE_SIZE,
  };
}

export default connect(
  mapStateToProps,
  {
    getCollectionFromUrl,
    updateSearchItemQuery,
    expandUiComponent,
    collapseUiComponent,
    setHighlightedIndex,
    clearHighlightedIndex
  }
)(FieldLookup);
