import React from 'react';
import classNames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';

import withFilters from '^/components/app/groups/insights/hoc/withFilters';
import PureComponent from '^/components/common/PureComponent';

export class FilterPill extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    this.props.onChange(this.props.value);
    if(this.props.additionalOnChange) {
      this.props.additionalOnChange({[this.props.filterKey]: this.props.value})
    };
  }

  render() {
    const {
      title,
      value,
      selectedValue,
    } = this.props;

    return (
      <li className="display-inline-block mt-1-4 mb-1-4">
        <button
          className={classNames('pill', {
            active: selectedValue === value
          })}
          onClick={this.onChange}
        >
          {title}
        </button>
      </li>
    );
  }
}

FilterPill.propTypes = {
  title: React.PropTypes.string.isRequired,
  value: React.PropTypes.string.isRequired,
  selectedValue: React.PropTypes.string.isRequired,
  filterKey: React.PropTypes.string.isRequired,
  insightsConfig: ImmutablePropTypes.list.isRequired,
  additionalOnChange: React.PropTypes.func
};

export default withFilters(FilterPill);
