import React from 'react';
import { connect } from 'react-redux';

import PureComponent from '^/components/common/PureComponent';
import { setCollapse } from '^/actions/actions';

class Collapse extends PureComponent {
  componentWillMount() {
    const { initiallyCollapsed, collapseOnMount, collapseId, collapsed, overrideCollapsed } = this.props;

    if(typeof overrideCollapsed !== 'undefined') {
      this.props.setCollapse(
        collapseId,
        overrideCollapsed
      );
    } else if (collapseOnMount) {
      // Collapse component on mount
      this.props.setCollapse(
        collapseId,
        true
      );
    } else if (typeof collapsed === 'undefined') {
      // Or set initial collapse (defaults to true)
      this.props.setCollapse(
        collapseId,
        typeof initiallyCollapsed === 'undefined' ? true : initiallyCollapsed
      );
    }
  }

  getClassName() {
    const {className, collapsed} = this.props;
    return (className ? className + ' ' : '')
    + 'collapse'
    + (collapsed ? ' collapsed' : '');
  }

  render() {
    const { children } = this.props;
    return (
      <span className={this.getClassName()}>
        {children}
      </span>
    );
  }
}

export { Collapse as CollapseUnconnected };

export function mapStateToProps(state, props) {
  return {
    collapsed: state.ui.getIn(['collapse', props.collapseId])
  };
}

export default connect(
  mapStateToProps,
  {
    setCollapse
  }
)(Collapse);
