import React from 'react';
import { connect } from 'react-redux';

import { loadInsightData } from '^/actions/insights';
import PureComponent from '^/components/common/PureComponent';
import { selectInsightFilters, selectInsightFilterByKey } from '^/selectors/insights';


const withFilters = (Component) => {
  class ComponentWithFilter extends PureComponent {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.getFilters = this.getFilters.bind(this);
      this.storeFilters = this.storeFilters.bind(this);
      this.state = {
        storedFilters: {}
      };
    }

    storeFilters(filters) {
      this.setState({storedFilters: filters})
    }

    getFilters(insightConfig, newValue) {
      const { filterKey, getFilters } = this.props;

      if (getFilters) {
        return getFilters(
          insightConfig.get('filters'),
          newValue,
          this.storeFilters,
          this.state.storedFilters
        );
      }

      return insightConfig.get('filters').set(filterKey, newValue);
    }

    onChange(newValue) {
      const { insightsConfigWithFilters, onChangeInsights } = this.props;

      insightsConfigWithFilters.forEach(insightConfig => {
        if (onChangeInsights && !onChangeInsights.includes(insightConfig.get('insight'))) {
          return;
        }
        this.props.loadInsightData(
          insightConfig.get('insight'),
          insightConfig.get('url'),
          this.getFilters(insightConfig, newValue)
        );
      });
    }

    render() {
      return (
        <Component
          {...this.props}
          onChange={this.onChange}
        />
      );
    }
  }

  function mapStateToProps(state, props) {
    return {
      insightsConfigWithFilters: props.insightsConfig.map(insightConfig => {
        return insightConfig.set('filters', selectInsightFilters(insightConfig.get('insight'))(state));
      }),

      // Note: The selectedValue should be the same for each insight, so we're just considering the first insight
      // Unfortunately we are unable to raise an error if the values are not the same for each insight due to them not updating at the same time
      selectedValue: selectInsightFilterByKey(props.insightsConfig.getIn([0, 'insight']), props.filterKey)(state),
    };
  }

  return connect(mapStateToProps, { loadInsightData })(ComponentWithFilter);
};

export default withFilters;
