import React from 'react';

import ProgressBar, { getProgressClassName } from '^/components/app/widgets/ProgressBar';

const ProgressSpeechBubble = ({percentage, title, text, forceColour}) =>
  <div className={'speechbubble speechbubble-' + (forceColour ? forceColour : getProgressClassName(percentage))}>
    <h4>{title}</h4>
    <p>{text}</p>
    <ProgressBar percentage={percentage} forceColour={forceColour} />
  </div>;

export default ProgressSpeechBubble;
