import { fromJS } from 'immutable';


export const ADMIN_NEWS_CREATE = 'NEWS_ADMIN_CREATE';
export const ADMIN_NEWS_MODEL = 'admin/news';
export const ADMIN_NEWS_REGION_MODEL = 'admin/news-region';
const ADMIN_NEWS_MANAGE_ROUTE = '/admin/news/${id}/';

export const NEWS_REGIONS = 'NEWS_REGIONS';

export const ADMIN_NEWS_EDIT_FIELDS = fromJS([
  'sector', 'title', 'content', 'image'
]);

export const ADMIN_NEWS_ROW_FIELDS = fromJS([
  {name: ['title'], display_name: 'Title', type: 'string', sortable: true},
  {name: ['sector'], display_name: 'Sector', type: 'string', sortable: true},
  {name: ['regions', 'country_display'], display_name: 'Region(s)', type: 'manyToMany', noneMessage: 'All'},
  {name: ['created'], display_name: 'Date', type: 'datetime', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Edit', route: ADMIN_NEWS_MANAGE_ROUTE},
  {name: ['id'], display_name: 'Delete', type: 'remove', sortable: false},
]);

export const ADMIN_NEWS_VIEW_FIELDS = fromJS([
  {name: ['title'], display_name: 'Title', type: 'string'},
  {name: ['regions', 'country_display'], display_name: 'Region(s)', type: 'manyToMany', noneMessage: 'All'},
  {name: ['created'], display_name: 'Date', type: 'datetime'},
  {name: ['image'], display_name: 'Image', type: 'image'},
  {name: ['content'], display_name: 'Content', type: 'html'},
]);

export const ADMIN_NEWS_REGION_CREATE_FIELDS = fromJS(['country']);

export const ADMIN_NEWS_REGION_FIELDS = fromJS([
  {name: ['country_display'], display_name: 'Country', type: 'string', sortable: true},
  {name: ['id'], display_name: 'Delete', type: 'remove', sortable: false},
]);
