import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import Icon from '^/components/app/content/Icon';

import PureComponent from '^/components/common/PureComponent';
import { selectCurrentPracticeName } from '^/selectors/practice';


const COMPLIANCE_REVIEWS = 'COMPLIANCE_REVIEWS';
const AUDITS = 'AUDITS';
const ASSESSMENTS = 'ASSESSMENTS';
const PRACTICE_MEETINGS = 'PRACTICE_MEETINGS';
const GROUP_CONFIG_PAGE = 'GROUP_CONFIG_PAGE';

const GROUP_CONFIG_PATH_URLS = {
  [COMPLIANCE_REVIEWS]: '/page/groups/group-config/compliance-reviews/',
  [AUDITS]: '/page/groups/group-config/audits/',
  [ASSESSMENTS]: '/page/groups/group-config/assessments/',
  [PRACTICE_MEETINGS]: '/page/groups/group-config/practice-meetings/',
  [GROUP_CONFIG_PAGE]: '/page/groups/group-config/',
};

const GROUP_CONFIG_NAMES = {
  [COMPLIANCE_REVIEWS]: 'Compliance Reviews',
  [AUDITS]: 'Audits',
  [ASSESSMENTS]: 'Assessments',
  [PRACTICE_MEETINGS]: 'Practice Meetings',
};

export class GroupConfigPage extends PureComponent {
  render() {
    const { practiceName, currentUrlPath } = this.props;

    const renderGroupConfigNav = (name, showIcon) => {
      return (
        <li>
          <span className='sidenav-text'>
            <Link to={GROUP_CONFIG_PATH_URLS[name]}>
              {GROUP_CONFIG_NAMES[name]}
            </Link>
            {showIcon &&
              <Icon type="long-arrow-left" className="setup-icon" />
            }
          </span>
        </li>
      );
    };

    const showIcon = (pathName) => currentUrlPath === GROUP_CONFIG_PATH_URLS[pathName];

    return (
      <div className='m-1 mt-2 col-1'>
        <div className='col-1-3 secondary-left'>
          <h2>{practiceName}</h2>
          <ul className='list-no-indent icon-right'>
            {renderGroupConfigNav(COMPLIANCE_REVIEWS, showIcon(COMPLIANCE_REVIEWS) || showIcon(GROUP_CONFIG_PAGE))}
            {renderGroupConfigNav(AUDITS, showIcon(AUDITS))}
            {renderGroupConfigNav(ASSESSMENTS, showIcon(ASSESSMENTS))}
            {renderGroupConfigNav(PRACTICE_MEETINGS, showIcon(PRACTICE_MEETINGS))}
          </ul>
        </div>
        <div className='col-2-3 bordered-left'>
          {this.props.children}
        </div>
      </div>
    );
  };
};

export const mapStateToProps = (state) => {
  const path = state.routing.location.pathname || '';
  return {
    currentUrlPath: path,
    practiceName: selectCurrentPracticeName(state),
  };
};

export default connect(mapStateToProps, {})(GroupConfigPage);
