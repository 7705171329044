import React from 'react';
import { pluralize } from '^/utils';

export const OVERDUE = 'red';
export const COMPLETED = 'green';
export const IN_PROGRESS = 'yellow';
export const NOT_STARTED = 'gray';

export function getStatusColor(overdue, completed, inProgress, total) {
  if (overdue > 0) {
    return OVERDUE;
  }
  if (completed === total) {
    return COMPLETED;
  }
  if (inProgress > 0) {
    return IN_PROGRESS;
  }
  return NOT_STARTED;
}

export function getStatusMessage(overdue, completed, inProgress, total) {
  if (overdue > 0) {
    return pluralize(overdue, 'activity', 'activities') + ' outstanding';
  }
  if (completed === total) {
    return pluralize(total, 'activity', 'activities') + ' completed';
  }
  if (inProgress > 0) {
    return pluralize(inProgress, 'activity', 'activities') + ' in progress';
  }
  return 'activities not started';
}

export function getTooltipType(overdue, completed, inProgress, total) {
  if (overdue > 0) {
    return 'error';
  }
  if (completed === total) {
    return 'success';
  }
  if (inProgress > 0) {
    return 'warning';
  }
  return 'light';
}

const StatusDot = ({statusColor, statusMessage, tooltipType}) => {
  return <span
    className={'status-dot ' + statusColor}
    data-tip={statusMessage} data-type={tooltipType}
         />;
};

export default StatusDot;
