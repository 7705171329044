import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { summariseName } from '^/utils';
import { isAdmin as isAdminMembership } from '^/models/staffPractice';


export const UserCapsule = ({staffDetail, userStaffId, isAdmin}) =>
  isAdmin && staffDetail && staffDetail.get('full_name') ? (
    <span
      className={
      classNames(
        'capsule-user',
        staffDetail.get('id') === userStaffId ? 'capsule-user-self' : ''
      )} title={staffDetail.get('full_name')}
    >
      {summariseName(staffDetail.get('full_name'))}
    </span>
  ) : (
    <noscript />
  );

UserCapsule.propTypes = {
  staffDetail: ImmutablePropTypes.map.isRequired
};

export function mapStateToProps(state) {
  const userId = state.userProfile.get('id');
  const members = state.currentPractice && state.currentPractice.get('members');

  const membership = members && members.find(member => member.getIn(['user', 'id']) === userId);

  return {
    userStaffId: state.userProfile.getIn(['staffdetail', 'id']),
    isAdmin: membership && isAdminMembership(membership),
  };
}

export default connect(mapStateToProps) (UserCapsule);
