import React from 'react';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEditCreate from '^/components/admin/content/edit-create/AdminEditCreate';
import AdminList from '^/components/admin/content/list/AdminList';


import {
  ADMIN_REMINDER_CREATE,
  ADMIN_REMINDER_MODEL,
  ADMIN_REMINDER_EDIT_FIELDS,
  ADMIN_REMINDER_ROW_FIELDS,
} from './reminders';


export const RemindersAdmin = () =>
  <div>
    <AdminContainer title="Reminders">
      <AdminEditCreate
        controlName={ADMIN_REMINDER_CREATE}
        collectionName={ADMIN_REMINDER_CREATE}
        model={ADMIN_REMINDER_MODEL}
        fields={ADMIN_REMINDER_EDIT_FIELDS}
        title="Add Reminder"
      />
    </AdminContainer>

    <AdminContainer>
      <AdminList
        title="All Reminders"
        model={ADMIN_REMINDER_MODEL}
        fields={ADMIN_REMINDER_ROW_FIELDS}
      />
    </AdminContainer>
  </div>;

export default connect(undefined, { routePush: routeActions.push })(RemindersAdmin);
