import classNames from 'classnames';
import React from 'react';

import { EVENT_TYPE_OPTIONS } from '^/components/app/digital-tools/records/digital-records/constants';
import withFilters from '^/components/app/groups/insights/hoc/withFilters';
import PureComponent from '^/components/common/PureComponent';

const SELECT_CLASSNAMES = [
  'print-hide',
  'display-block',
  'full-width',
  'medium-text',
  'light-text',
  'base-font-color',
  'p-small'
];

const selectedEventRecordLabel = (selectedValue) =>
  selectedValue ? EVENT_TYPE_OPTIONS.find(option => option.key === selectedValue).value : '--';

export class EventRecordsTypeFilter extends PureComponent {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.props.onChange(e.target.value);
  }

  render() {
    const { selectedValue } = this.props;
    return (
      <div>
        <select
          className={classNames(SELECT_CLASSNAMES)}
          onChange={this.onChange}
          value={selectedValue}
          placeholder="Select..."
        >
          <option value="">Select...</option>
          {EVENT_TYPE_OPTIONS.map(option => (
            <option key={option.key} value={option.key}>{option.value}</option>
          ))}
        </select>
        <span className="print-dropdown-as-text caption mt-1">
          {selectedEventRecordLabel(selectedValue)}
        </span>
      </div>
    );
  }
}

export default withFilters(EventRecordsTypeFilter);
