import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Audit,
  AuditCategoryProgress,
} from '^/components/app/digital-tools/audit/types';
import { getAuditById } from '^/actions/audit';
import { selectAuditById } from '^/selectors/audit';
import Loading from '^/components/app/content/Loading';
import { closeModal } from '^/actions/modals';
import { makeStaticPath } from '^/utils';
import { StoreState } from '^/store/types';

interface DispatchProps {
  onCancel: typeof closeModal;
  getAuditById: typeof getAuditById;
}

type OwnProps = {
  callback: () => void;
  auditId: string;
  audit: Audit;
  isLoading: boolean;
  allQuestionsComplete: boolean;
  statusText: string;
  shouldAllowContinue: boolean;
};

export type Props = DispatchProps & OwnProps;

export class ConfirmAuditStageModal extends Component<Props, {}> {
  public componentDidMount() {
    const { auditId } = this.props;
    this.props.getAuditById(auditId);
  }

  public render() {
    const {
      audit,
      isLoading,
      allQuestionsComplete,
      statusText,
      shouldAllowContinue,
    } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div className="modal-body large-text">
        <div className="p-1 clearfix">
          <div className="text-center">
            <img
              src={makeStaticPath('images/brand/logo-color.svg')}
              className="brand-logo"
            />
          </div>
          {statusText === 'Audit' && !allQuestionsComplete ? (
            <div>
              <p className="mt-2">
                In order to progress to the next stage of the audit you must
                complete all of the questions.
              </p>
              <p className="mt-2">
                Please refer to the green ticks in the top right corner of the
                page to identify incomplete sections.
              </p>
            </div>
          ) : (
            <p className="mt-2">
              Are you sure you want to complete the {statusText} stage? Once you
              confirm you will not be able to return to the {statusText} stage.{' '}
            </p>
          )}
          {statusText === 'Actions' && (
            <p className="mt-1">
              {`${
                audit.incorrect_questions_count
                  ? `You have actioned ${audit.incorrect_questions_actioned_count}/${audit.incorrect_questions_count} actions.`
                  : 'No actions to complete'
              }`}
            </p>
          )}
          {statusText === 'Actions' && (
            <p className="mt-1">
              Make sure you have scheduled all follow-up Tasks required.
            </p>
          )}
          {shouldAllowContinue && (
            <p className="mt-1">
              Click CONFIRM to complete the {statusText} Stage.
            </p>
          )}
          <p className="mt-1">
            Click CANCEL to go back to the {statusText} Stage.
          </p>
          <button
            className="btn btn-secondary pull-left mt-2"
            onClick={this.props.onCancel}
          >
            Cancel
          </button>
          {shouldAllowContinue && (
            <button
              className="btn btn-save pull-right mt-2"
              onClick={this.onConfirm}
            >
              Confirm
            </button>
          )}
        </div>
      </div>
    );
  }

  private onConfirm = () => {
    this.props.callback();
    this.props.onCancel();
  };
}

const mapStateToProps = (state: StoreState, props: OwnProps) => {
  const questionCategories = state.audit.questionsCategories || [];
  const audit = selectAuditById(state, props.auditId);
  const allQuestionsComplete = !questionCategories.filter(
    (category: AuditCategoryProgress) => category.is_completed !== true
  ).length;
  const statusText =
    audit && audit.status[0] + audit.status.toLowerCase().substring(1);
  const shouldAllowContinue = !(
    statusText === 'Audit' && !allQuestionsComplete
  );
  return {
    audit: audit,
    isLoading: state.thunkRequests.getAuditById.isLoading,
    allQuestionsComplete: allQuestionsComplete,
    statusText: statusText,
    shouldAllowContinue: shouldAllowContinue,
  };
};

export default connect<{}, DispatchProps, OwnProps>(mapStateToProps, {
  onCancel: closeModal,
  getAuditById,
})(ConfirmAuditStageModal);
