import composeReducers from '^/reducers/composeReducers';
import { Map, fromJS } from 'immutable';
import * as stats from '^/actions/stats';


function statsReducer(state = Map(), action) {
  switch (action.type) {
    case stats.LOAD_STATS.REQUEST:
      return state.set(action.meta.statsType, null);
    case stats.LOAD_STATS.SUCCESS:
      return state.set(action.meta.statsType, fromJS(action.payload));
    default:
      return state;
  }
}

export default composeReducers([statsReducer], Map());
