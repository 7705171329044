import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { convertStringToFilterString } from '^/utils';
import Loading from '^/components/app/content/Loading';
import ListItem from '^/components/app/tables/ListItem';
import ListPagination from '^/components/app/tables/ListPagination';
import ListPageSize from '^/components/app/tables/ListPageSize';
import TableListSearch from '^/components/app/tables/TableListSearch';
import Table from '^/components/common/content/Table';
import ScrollableTableContainer from '^/components/common/content/ScrollableTableContainer';


const SortIcon = ({ sortName, sortBy, reversed, setSortBy }) => (
  <span
    className={classNames('sort-icon', {sorted: sortName === sortBy, reversed})}
    onClick={() => setSortBy && setSortBy(sortName)}
  />
);

const RenderFilter = ({options, applyFilter, display_name, appliedFilters}) => {
  const SELECT_ID = convertStringToFilterString(display_name)
  const FILTER_VALUE = appliedFilters ? appliedFilters[SELECT_ID] : null

  return(
    <p>
      {options ?
      <select id={SELECT_ID} className="form-select" 
      onChange={applyFilter} value={FILTER_VALUE}>
        <option value=''>All</option>
        {options.map(option => (
          <option key={option.select_name} value={option.select_value}>
            {option.select_name}
          </option>
        ))}
      </select> 
      : null}
    </p>
  )
}

const ListHeader = ({ header, setSortBy, sortBy, reversed, recordType, filterOptions, applyFilter, appliedFilters }) => {
  const { display_name, sortable, name, sortByName } = header.toObject();
  const filter_options = recordType && filterOptions ? filterOptions[recordType] : null


  return (
    <th>
      <p>
      {display_name}
      {sortable && (
        <SortIcon sortName={sortByName || name.join('__')} sortBy={sortBy} setSortBy={setSortBy} reversed={reversed} />
      )}
      </p>
      {recordType && filter_options ? <RenderFilter options={filter_options[display_name]} applyFilter={applyFilter} 
      display_name={display_name} appliedFilters={appliedFilters}/> : null }
    </th>
  );
};

const ListItems = ({
  title, collection, items, noItemsMessage, fields,
  listName, noSearch, sortBy, setSortBy, sortByReversed,
  hidePagination, hidePageSize, pageSize, setPageSize, isLoading,
  removeItem, search, loadPage, searchPlaceholderString, recordType, 
  filterOptions, applyFilter, appliedFilters
}) => {
  return (
  <div className="list-container">
    <div className="row clearfix mb-1 mt-1">
      {title && (
        <div className={'col-1-2'}>
          <h3>{title}</h3>
        </div>
      )}
      <div className={title ? 'col-1-2' : 'col-1'}>
        {!noSearch && collection && (
          <TableListSearch
            onSubmit={({searchString}) => search(searchString)}
            onClear={() => search()}
            form={listName + '_search'}
            loading={isLoading}
            initialValues={{searchString: collection.get('searchString')}}
            placeholderString={searchPlaceholderString}
          />
        )}
      </div>
    </div>
    {!hidePagination && collection && !isLoading && (
      <ListPagination collection={collection} pageSize={pageSize} loadPage={loadPage} />
    )}

    {isLoading ? (
      <Loading />
    ) : (
      <ScrollableTableContainer>
        <Table
          className="padded"
          renderRow={(item, idx, columns) =>
            <ListItem
              key={idx}
              item={item}
              fields={columns}
              removeItem={removeItem}
            />
          }
          renderHeaders={headers => headers.map((header, idx) =>
            <ListHeader
              key={idx}
              header={header}
              sortBy={sortBy}
              setSortBy={setSortBy}
              reversed={sortByReversed}
              recordType={recordType}
              filterOptions={filterOptions}
              applyFilter={applyFilter}
              appliedFilters={appliedFilters}
            />
          )}
          rows={collection ? collection.get('items') : items}
          columns={fields}
          emptyMessage={noItemsMessage || 'None Found.'}
        />
      </ScrollableTableContainer>
    )}
    {!hidePageSize && collection && !isLoading &&(
      <ListPageSize collection={collection} pageSize={pageSize} setPageSize={setPageSize} />
    )}
  </div>)
};

ListItems.propTypes = {
  title: React.PropTypes.string,
  collection: ImmutablePropTypes.map, // Must provide one of these
  items: ImmutablePropTypes.list,     //
  noItemsMessage: React.PropTypes.string,
  fields: ImmutablePropTypes.list.isRequired,
  listName: React.PropTypes.string,
  noSearch: React.PropTypes.bool,
  hidePagination: React.PropTypes.bool,
  pageSize: React.PropTypes.number,
  isLoading: React.PropTypes.bool,
  removeItem: React.PropTypes.func,
  setSortBy: React.PropTypes.func,
  search: React.PropTypes.func,
  loadPage: React.PropTypes.func,
  filters: ImmutablePropTypes.map.isRequired,
};

export default ListItems;
