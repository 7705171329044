/* global tinymce, FileReader */
import { makeStaticPath } from '^/utils';
import store from '^/store';
import { uploadFile } from '^/actions/actionSequences';

const { TEMPLATE_VARIABLES = {} } = window;

const PLUGINS = [
  'autolink',
  'contextmenu',
  'link',
  'lists',
  'noneditable',
  'pagebreak',
  'searchreplace',
  'table',
  'textcolor',
  'image',
  'powerpaste',
];

function getAdminPlugins(sector) {
  const extraAdminPlugins = ['checkboxes', 'code'];
  if (sector) {
    return PLUGINS.concat([`${sector}_variables`]).concat(extraAdminPlugins);
  }
  return PLUGINS.concat(extraAdminPlugins);
}

const EDITOR_HEIGHT = '600';

const STYLE_FORMATS = [
  { title: 'Main heading', block: 'h1' },
  { title: 'Internal heading', block: 'h2' },
  { title: 'Subheading', block: 'h4' },
  { title: 'Body text', block: 'p' },
  { title: 'Body justified', block: 'p', styles: { 'text-align': 'justify' } },
  { title: 'Quote', block: 'blockquote' },
  { title: 'Disclaimer', block: 'p', classes: 'disclaimer' },
  { title: 'Hyperlink', inline: 'span', classes: 'hyperlink' },
  { title: 'Wide spacing', selector: 'li', classes: 'wide-spacing' },
  { title: 'Tick list', selector: 'li', classes: 'tick-list' },
  { title: 'a.b.c. list', selector: 'li', classes: 'alphabetical-list' },
];

const TOOLBAR =
  'undo redo ' +
  '| styleselect fontsizeselect forecolor ' +
  '| bold italic ' +
  '| alignleft aligncenter alignright alignjustify ' +
  '| bullist numlist outdent indent ' +
  '| link image';

function file_picker_callback(callback) {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');

  input.onchange = function() {
    const file = this.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      // Note: Now we need to register the blob in TinyMCEs image blob
      // registry. In the next release this part hopefully won't be
      // necessary, as we are looking to handle it internally.
      const id = 'blobid' + new Date().getTime();
      const blobCache = tinymce.activeEditor.editorUpload.blobCache;
      const blobInfo = blobCache.create(id, file, reader.result);
      blobCache.add(blobInfo);

      // call the callback and populate the Title field with the file name
      callback(blobInfo.blobUri(), { title: file.name });
    };
  };

  input.click();
}

function images_upload_handler(blobInfo, success) {
  store.dispatch(
    uploadFile(blobInfo.blob(), undefined, undefined, uploadedFile => {
      success(uploadedFile.file_path);
    })
  );
}

function _getConfig(
  cssFilename,
  isAdmin = false,
  selector = 'textarea',
  sector = null
) {
  const variablesMenuItems = sector ? `${sector}_variables` : '';

  return {
    selector,
    plugins: (isAdmin ? getAdminPlugins(sector) : PLUGINS).join(' '),
    browser_spellcheck: true,
    pagebreak_separator: '<div class="page"></div>',
    pagebreak_split_block: false,
    menubar: 'edit insert view table tools',
    menu: {
      file: {
        title: 'File',
        items:
          'newdocument restoredraft | preview | export print | deleteallconversations',
      },
      edit: {
        title: 'Edit',
        items:
          'undo redo | cut copy paste pastetext | selectall | searchreplace',
      },
      view: {
        title: 'View',
        items:
          'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments',
      },
      insert: {
        title: 'Insert',
        items: `image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime | ${variablesMenuItems} checkbox`,
      },
      format: {
        title: 'Format',
        items:
          'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat',
      },
      tools: {
        title: 'Tools',
        items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount',
      },
      table: {
        title: 'Table',
        items:
          'inserttable | cell row column | advtablesort | tableprops deletetable',
      },
      help: { title: 'Help', items: 'help' },
    },
    height: EDITOR_HEIGHT,
    content_css: makeStaticPath('css/' + cssFilename),
    extended_valid_elements:
      'var[contenteditable|value],input[checked|type=checkbox]',
    convert_urls: false,
    elementpath: isAdmin,
    statusbar: isAdmin,
    style_formats: STYLE_FORMATS,
    toolbar: TOOLBAR,
    file_browser_callback_types: 'image',
    file_picker_callback,
    images_upload_handler,
    automatic_uploads: true,
    file_picker_types: 'image',
    setup: function(ed) {
      ed.on('init', () =>
        ed.getBody().addEventListener('change', evt => {
          if (evt.target.type === 'checkbox') {
            if (evt.target.checked) {
              evt.target.setAttribute('checked', 'checked');
            } else {
              evt.target.removeAttribute('checked');
            }
          }
        })
      );
    },
  };
}

const makeCssFilename = isLandscape =>
  isLandscape ? 'tinymce_wide.css' : 'tinymce.css';
export function getConfig(isLandscape) {
  return _getConfig(makeCssFilename(isLandscape), false);
}
export function getAdminConfig(selector, sector, isLandscape) {
  return _getConfig(makeCssFilename(isLandscape), true, selector, sector);
}
export function getNewsConfig(selector, sector) {
  return _getConfig('tinymce_news.css', false, selector, sector);
}

export const TINYMCE_ID = 'tinymce';

Object.entries(TEMPLATE_VARIABLES).forEach(([sector, variables]) => {
  tinymce.PluginManager.add(`${sector}_variables`, function(editor) {
    const submenuItems = variables.map(function(variable) {
      return {
        type: 'menuitem',
        text: variable,
        onAction: function() {
          editor.insertContent(
            `<var contenteditable="false" value="${variable}">${variable}</var>`
          );
          editor.fire('change', { value: editor.getContent() }); // For some reason, insert doesnt trigger change by default
        },
      };
    });

    editor.ui.registry.addMenuItem(`${sector}_variables`, {
      icon: 'sourcecode',
      text: 'Variable',
      getSubmenuItems: () => submenuItems,
    });
  });
});

tinymce.PluginManager.add('checkboxes', function(editor) {
  editor.ui.registry.addMenuItem('checkbox', {
    icon: 'sourcecode',
    text: 'Checkbox',
    onAction: function() {
      editor.insertContent('<input type="checkbox" />');
      editor.fire('change', { value: editor.getContent() }); // For some reason, insert doesnt trigger change by default
    },
  });
});
