import { Map } from 'immutable';
import * as actions from '^/actions/actions';

export function pollExportTimeouts(state = Map(), action) {
  switch (action.type) {
    case actions.STORE_POLL_TIMEOUT:
      return state.set(action.payload.exportId, action.payload.timeout);

    case actions.CLEAR_ALL_EXPORT_TIMEOUTS:
      state.forEach(window.clearTimeout);
      return Map();

    default:
      return state;
  }
}
