import React, { PropTypes } from 'react';
import classNames from 'classnames';

const Small = ({children, className}) =>
  <p className={classNames('small-text', className)}>{children}</p>;

Small.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default Small;
