import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';

const Status = {
  PENDING: 'pending',
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
  UNSUPPORTED: 'unsupported',
};

interface Props {
  src: string;
  className?: string;
  children?: ReactNode | readonly ReactNode[];
}

interface State {
  text: string | null;
  status: typeof Status[keyof typeof Status];
}

const supportsInlineSVG = (() => {
  if (!document) {
    return false;
  }

  const div = document.createElement('div');
  div.innerHTML = '<svg />';
  return (
    !!div.firstChild &&
    div.firstChild.namespaceURI === 'http://www.w3.org/2000/svg'
  );
})();

class InlineSVG extends Component<Props, State> {
  state: State = {
    text: null,
    status: supportsInlineSVG ? Status.PENDING : Status.UNSUPPORTED,
  };

  loadSVG() {
    if (this.state.status === Status.UNSUPPORTED) {
      return;
    }

    this.setState({
      status: Status.LOADING,
    });

    fetch(this.props.src)
      .then(response => response.text())
      .then(text => {
        this.setState({
          text,
          status: Status.LOADED,
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({
          text: null,
          status: Status.FAILED,
        });
      });
  }

  componentDidMount() {
    this.loadSVG();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.src !== prevProps.src) {
      this.loadSVG();
    }
  }

  render() {
    const { status, text } = this.state;
    const dangerProps =
      status !== Status.UNSUPPORTED && !!text
        ? { dangerouslySetInnerHTML: { __html: text } }
        : {};

    return (
      <span
        {...dangerProps}
        className={classNames('isvg', this.state.status, this.props.className)}
      >
        {this.state.status === Status.UNSUPPORTED ? this.props.children : null}
      </span>
    );
  }
}

export default InlineSVG;
