import React from 'react';
import classNames from 'classnames';

const FieldStatic = ({config, className}) =>
  <div className={classNames('mb-1-4', className)}>
    <label>{config.label}</label>
    <span>{config.text}</span>
  </div>
;

FieldStatic.propTypes = {
  config: React.PropTypes.object.isRequired,
};

export default FieldStatic;
