import React from 'react';
import { connect } from 'react-redux';

import { changePracticeAndNavToDashboard } from '^/actions/actionSequences';

import PureComponent from '^/components/common/PureComponent';


export class PracticeProgress extends PureComponent {
  render() {
    const { practice, navToDashboard } = this.props;
    const cycle = practice.get('current_cycle');

    return (
      <div>
        <button
          className="btn btn-default pull-right"
          onClick={() => navToDashboard(practice.get('id'))}
        >dashboard
        </button>
        <h5>Currently in Month {cycle.get('current_month_index') + 1}</h5>
        <p className="text-success">
          {Math.round(cycle.get('progress_through_month', 0) * 100).toFixed(0)}% complete this month
        </p>
        <hr className="thin" />
      </div>
    );
  }
}

export default connect(null, { navToDashboard: changePracticeAndNavToDashboard })(PracticeProgress);
