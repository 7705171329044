import React from 'react';
import moment from 'moment';
import { Map } from 'immutable';

import Icon from '^/components/app/content/Icon';
import LandingCard from '^/components/app/groups/insights/LandingCard';
import { INSIGHTS, INSIGHTS_API_URLS, PATHS } from '^/components/app/groups/insights/constants';
import { getSubGroupFilterFromPath } from '^/components/app/groups/insights/utils';
import withInsight from '^/components/app/groups/insights/hoc/withInsight';

const ActiveUserCountLanding = (props) => {
  return (
    <LandingCard
      title="Number of active users"
      icon="user"
      link={PATHS.ACTIVE_USERS}
      linkText="VIEW MORE"
      lastUpdatedTimestamp={moment()}
      query={props.query}
    >
      <div className="active-user-count">
        {props.data || '--'}{' '}<Icon type="user" />
        <p className="caption">
          {props.hasFailedToLoad ? 'Failed to load' : 'iComply users over all locations'}
        </p>
      </div>
    </LandingCard>
  );
};

export { ActiveUserCountLanding };

export default withInsight({
  insight: INSIGHTS.ACTIVE_USER_COUNT_LANDING,
  url: INSIGHTS_API_URLS.ACTIVE_USER_COUNT,
  getFilters: () => (
    Map({
      practice_sub_group_id: getSubGroupFilterFromPath(),
   })
  )
})(ActiveUserCountLanding);
