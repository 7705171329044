import React from 'react';
import { connect } from 'react-redux';

import { publishAsNewVersionAndReloadTemplateVersion } from '^/actions/actionSequences';
import { closeModal } from '^/actions/modals';
import { ADMIN_TEMPLATE_DOCUMENT_VERSION } from '^/consts/collectionKeys';

import PublishDocumentVersionForm from './PublishDocumentVersionForm';


export function PublishAsNewVersionModal({
  response, templateDocument, templateVersion, publish, onCancel, data
}) {
  const message = `Are you sure you want to publish this as a new version of ${
    templateVersion.get('template_document_display')}?`;

  return (
    <div className="p-1">
      <PublishDocumentVersionForm
        response={response}
        onSubmit={formData => publish(templateDocument.get('id'), Object.assign({}, data, formData))}
        onCancel={onCancel}
        message={message}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    response: state.responses.getIn(['createItem', ADMIN_TEMPLATE_DOCUMENT_VERSION])
  };
}

export default connect(
  mapStateToProps,
  { publish: publishAsNewVersionAndReloadTemplateVersion, onCancel: closeModal }
) (PublishAsNewVersionModal);
