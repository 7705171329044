import React from 'react';
import Dropzone from 'react-dropzone';

import { STYLE, ACTIVE_STYLE, REJECT_STYLE } from '^/consts/dropzone';
import PureComponent from '^/components/common/PureComponent';


export default class FileUpload extends PureComponent {
  onOpenClick() {
    this.refs.dropzone.open();
  }

  onDrop(files) {
    if (files.length) {
      this.props.setPendingUpload(files[0]);
    }
  }

  render() {
    const { src, file, className } = this.props;
    return (
      <div className={className}>
        <Dropzone
          ref="dropzone" multiple={false}
          disableClick
          onDrop={this.onDrop.bind(this)} style={STYLE} activeStyle={ACTIVE_STYLE} rejectStyle={REJECT_STYLE}
        >
          {this.props.display(file ? file.preview : src)}
        </Dropzone>

        <button type="button" className="btn btn-default" onClick={this.onOpenClick.bind(this)}>
          Select a {src || file ? 'new' : ''} file
        </button>

        {file && (
          <button type="button" className="btn btn-primary" onClick={() => this.props.onSubmit()}>
            Upload File
          </button>
        )}
      </div>
    );
  }
}
