import React from 'react';
import { connect } from 'react-redux';

import { reassignAndRetireMemberAndReloadPracticeMembers } from '^/actions/actionSequences';

import SuspendOrRetireTeamMemberPage from '^/components/app/practices/SuspendOrRetireTeamMemberPage';


const RetireMemberModal = props =>
  <SuspendOrRetireTeamMemberPage
    {...props}
    action="retire"
    onSubmit={props.reassignAndRetireMemberAndReloadPracticeMembers}
  />;

export default connect(null, {
  reassignAndRetireMemberAndReloadPracticeMembers
})(RetireMemberModal);
