import React from 'react';
import Icon from '^/components/app/content/Icon';


export const DemoSplash = ({ onClickSignup, onClickReset }) =>
  <div className="alert-warning alert-warning-demomode">
    <h2>Viewing in Demo Mode</h2>
    <p>
      Make small work of compliance &ndash; sign up today.
      iComply saves staff time and money and also solves compliance headaches,
       so that you can get on with looking after your patients.
    </p>
    <button
      className="btn btn-default indented"
      onClick={onClickSignup}
    >
      <Icon type="check-square-o" className="button-icon" /> Sign up your practice today!
    </button>
    <button
      className="btn btn-warning indented pull-right"
      onClick={onClickReset}
    >
      <Icon type="refresh" className="button-icon" /> Reset Demo
    </button>
  </div>;

DemoSplash.propTypes = {
  onClickSignup: React.PropTypes.func.isRequired,
  onClickReset: React.PropTypes.func.isRequired,
};

export default DemoSplash;

