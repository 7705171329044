import React from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';
import { Link } from 'react-router';
import classNames from 'classnames';

import PureComponent from '^/components/common/PureComponent';
import {
  practiceHasAccessToDigitalAudits,
  practiceHasAccessToDigitalRecords
} from '^/stateHelpers';

import { startsWith } from '^/utils';

export const AUDIT_PATHNAME = '/page/tools/audit/';
export const RECORDS_PATHNAME = '/page/tools/records/';
export const TOOLS_PATHNAME = '/page/tools/'

export class DigitalToolsPage extends PureComponent {
  componentWillMount() {
    const {
      hasAccessToAudits,
      hasAccessToRecords,
      routePush,
      pathname,
    } = this.props;

    if (pathname === TOOLS_PATHNAME) {
      if (hasAccessToAudits) {
        routePush(AUDIT_PATHNAME);
      } else if (hasAccessToRecords) {
        routePush(RECORDS_PATHNAME);
      }
    }
  }

  pathMatches(link) {
    const { pathname } = this.props;
    return pathname && startsWith(pathname, link);
  }

  render() {
    const { hasAccessToAudits, hasAccessToRecords, children } = this.props;

    return (
      <div className="tools-wrapper">
        <div className="tools-nav-wrapper">
          {hasAccessToAudits && 
            <Link 
              to={AUDIT_PATHNAME}
              className={classNames('tools-primary-link', {active: this.pathMatches(AUDIT_PATHNAME)})}
            >
              Audits
            </Link>
          }
          {hasAccessToRecords &&
            <Link
              to={RECORDS_PATHNAME}
              className={classNames('tools-primary-link', {active: this.pathMatches(RECORDS_PATHNAME)})}
            >
              Records
            </Link>
          }
        </div>
        {children && 
          <div>
            {children}
          </div>
        }
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    pathname: state.routing.location.pathname,
    hasAccessToAudits: practiceHasAccessToDigitalAudits(state),
    hasAccessToRecords: practiceHasAccessToDigitalRecords(state),
  };
}

export default connect(mapStateToProps, { routePush: routeActions.push })(DigitalToolsPage);
