import React from 'react';
import { connect } from 'react-redux';

import { resetResponse } from '^/actions/actions';
import {
  adminSubmitAndInheritGroupsFunctionality,
  adminSubmitWithoutInheritGroupsFunctionality,
} from '^/actions/actionSequences';
import { closeModal } from '^/actions/modals';
import Loading from '^/components/app/content/Loading';
import PureComponent from '^/components/common/PureComponent';
import { hasFailed, hasSucceeded, isPending } from '^/consts/responseStates';
import Messages from '^/components/admin/content/edit-create/dynamic-forms/Messages';

class InheritGroupsFunctionalityModal extends PureComponent {
  constructor(props) {
    super(props);
    this.submitAndInherit = this.submitAndInherit.bind(this);
    this.submitWithoutInheriting = this.submitWithoutInheriting.bind(this);
  }

  componentDidMount() {
    const { updateItemResponseKey } = this.props;
    this.props.resetResponse(updateItemResponseKey);
  }

  submitAndInherit() {
    const { practiceId, practiceGroupId, updateItemParams } = this.props;
    this.props.adminSubmitAndInheritGroupsFunctionality(
      practiceId,
      practiceGroupId,
      updateItemParams
    );
  }

  submitWithoutInheriting() {
    const { updateItemParams } = this.props;
    this.props.adminSubmitWithoutInheritGroupsFunctionality(updateItemParams);
  }

  render() {
    const {
      isResponsePending,
      updateItemResponse,
      inheritGroupsFunctionalityResponse,
    } = this.props;

    if (isResponsePending) {
      return <Loading />;
    }

    return (
      <div>
        <div className="row">
          <div className="col-1">
            <p>
              Do you wish to inherit the Groups pre-set functionality and audits
              or keep the practice settings as they are?
            </p>
          </div>
          {hasFailed(updateItemResponse) && (
            <div className="col-1">
              <Messages response={updateItemResponse} isEditing />
            </div>
          )}
          {hasFailed(inheritGroupsFunctionalityResponse) ||
            (hasSucceeded(inheritGroupsFunctionalityResponse) && (
              <div className="col-1">
                <Messages
                  response={inheritGroupsFunctionalityResponse}
                  isEditing
                />
              </div>
            ))}
        </div>
        <div className="row align-right mt-1">
          <div className="col-1">
            {hasSucceeded(inheritGroupsFunctionalityResponse) ? (
              <div>
                <button
                  className="btn btn-default"
                  onClick={this.props.closeModal}
                >
                  Close
                </button>
              </div>
            ) : (
              <div>
                <button
                  className="btn btn-default"
                  onClick={this.submitAndInherit}
                >
                  Change
                </button>
                <button
                  className="btn btn-default ml-1"
                  onClick={this.submitWithoutInheriting}
                >
                  Keep
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const {
    updateItemResponseKey,
    updateItemResponse,
    inheritGroupsFunctionalityResponse,
  } = props;

  return {
    inheritGroupsFunctionalityResponse: state.responses.get(
      'inheritGroupsFunctionality'
    ),
    updateItemResponse: state.responses.getIn([
      'updateItem',
      updateItemResponseKey,
    ]),
    isResponsePending:
      isPending(updateItemResponse) ||
      isPending(inheritGroupsFunctionalityResponse),
  };
}

export default connect(mapStateToProps, {
  adminSubmitAndInheritGroupsFunctionality,
  adminSubmitWithoutInheritGroupsFunctionality,
  closeModal,
  resetResponse,
})(InheritGroupsFunctionalityModal);
