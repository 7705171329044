import React from 'react';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import moment from 'moment';

import { MODELS } from '^/permissions/capabilities';
import PureComponent from '^/components/common/PureComponent';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';
import { generatePracticeReport } from '^/actions/actionSequences';
import { formatBackendDate } from '^/utils';

import {
  ADMIN_PRACTICE_MODEL, ALL_PRACTICES, ADMIN_ALL_PRACTICE_FIELDS,
  PRACTICES_PAST_RENEWAL, ADMIN_ADMIN_PRACTICE_PAST_RENEWAL_FIELDS,
  PAST_RENEWAL_PRACTICES_FILTERS,
  ADMIN_PRACTICE_CREATE, ADMIN_PRACTICE_CREATE_FIELDS,
  DEFAULT_BUSINESS_DAYS, transformConfig
} from '^/components/admin/practices/practice';


export class PracticesAdmin extends PureComponent {
  handleComplete(model, data, collectionName, formName, newItem) {
    this.props.routePush('/admin/practices/' + newItem.id);
  }

  render() {
    return (
      <div>
        <Link to="/admin/practices/groups/" className="btn btn-primary">
          Manage Practice Groups &gt;
        </Link>

        <AdminContainer className="mb-2" title="Practices">
          <AdminCreate
            permsModel={MODELS.PRACTICE}
            controlName={ADMIN_PRACTICE_CREATE}
            collectionName={ADMIN_PRACTICE_CREATE}
            model={ADMIN_PRACTICE_MODEL}
            fields={ADMIN_PRACTICE_CREATE_FIELDS}
            transform={transformConfig}
            getInitialValues={() => ({
              'renewal_date': formatBackendDate(moment().add(3, 'years')),
              'business_days' : DEFAULT_BUSINESS_DAYS
            })}
            onComplete={this.handleComplete.bind(this)}
            title="Create a Practice"
          />
        </AdminContainer>
        <AdminContainer className="mb-2">
          <AdminList
            permsModel={MODELS.PRACTICE}
            title="All Practices"
            listName={ALL_PRACTICES}
            model={ADMIN_PRACTICE_MODEL}
            fields={ADMIN_ALL_PRACTICE_FIELDS}
            isSectorFiltered
          />
        </AdminContainer>
        <AdminContainer className="mt-2">
          <AdminList
            permsModel={MODELS.PRACTICE}
            title="Practices Past Renewal Date"
            listName={PRACTICES_PAST_RENEWAL}
            model={ADMIN_PRACTICE_MODEL}
            fields={ADMIN_ADMIN_PRACTICE_PAST_RENEWAL_FIELDS}
            defaultFilters={PAST_RENEWAL_PRACTICES_FILTERS}
          />
        </AdminContainer>
        <AdminContainer title="Practice Report">
          <button
            className="btn btn-primary"
            onClick={this.props.generatePracticeReport}
          >
            Generate Report
          </button>
        </AdminContainer>
      </div>
    );
  }
}

export default connect(null, { routePush: routeActions.push, generatePracticeReport })(PracticesAdmin);
