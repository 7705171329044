import React from 'react';


export default function MaintenanceModal() {
  return (
    <div>
      The server responded with an error. iComply may have been placed in maintenance mode,
      or your request may have taken too long. Please <a href="/">refresh the page</a>.
    </div>
  );
}
