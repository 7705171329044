import React from 'react';
import { fromJS } from 'immutable';

const JUST_STARTED = 'juststarted';
const IN_PROGRESS = 'inprogress';
export const COMPLETE = 'complete';

const PROGRESS_BAR_CLASSNAMES = fromJS([
  { className: JUST_STARTED, begin: 0, end: 29 },
  { className: IN_PROGRESS, begin: 30, end: 99 },
  { className: COMPLETE, begin: 100, end: 100 },
]);

export const getProgressClassName = (percentage) => {
  return PROGRESS_BAR_CLASSNAMES
    .find(item => percentage >= item.get('begin') && percentage <= item.get('end'))
    .get('className', IN_PROGRESS);
};

const ProgressBar = ({percentage, forceColour}) =>
  <div className={'progressbar ' + (forceColour ? forceColour : getProgressClassName(percentage))}>
    <div
      style={{width: percentage.toString() + '%'}}
      className={'progressbar-bar ' + (forceColour ? forceColour : getProgressClassName(percentage))}
    />
  </div>;

export default ProgressBar;
