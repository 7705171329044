import React from 'react';
import ReactTooltip from 'react-tooltip';

import Icon from '^/components/app/content/Icon';


export const HelpTooltip = props =>
  <span>
    <ReactTooltip effect="solid" type="info" id="usertype">
      {props.children}
    </ReactTooltip>
    <span data-tip data-for="usertype" className="ml-1-2">
      <Icon type="info-circle" className="tip-icon" />
    </span>
  </span>;

export default HelpTooltip;
