import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router';

import Icon from '^/components/app/content/Icon';
import {
  HealthcheckTypes,
  CategoryProgress,
} from '^/components/app/healthcheck/types';
import { AuditWorkflowTypes } from '^/components/app/digital-tools/audit/types';

interface OwnProps<T> {
  type: HealthcheckTypes | AuditWorkflowTypes;
  id: string;
  selectedCategoryId: string;
  categories: T | null;
  urlPart: string;
  isAuditWorkflow?: boolean;
}

export interface Options<T> {
  urlBase: string;
}

export type ProgressListProps<T> = OwnProps<T>;

const createProgressList = <T extends readonly CategoryProgress[]>(
  options: Options<T>
) => {
  class ProgressList extends Component<ProgressListProps<T>, {}> {
    render() {
      const {
        type,
        selectedCategoryId,
        categories,
        urlPart,
        id,
        isAuditWorkflow,
      } = this.props;

      if (!categories) {
        return null;
      }

      return (
        <div className="underline light">
          <div className="m-1-2">
            <h3 className="progress-list-header">
              <span className="inline-icon xlarge pull-left">
                <Icon type="clipboard-check" />
              </span>
              {type} progress
            </h3>
          </div>

          {categories.map(category => {
            return (
              <div key={category.id} className="m-1-2">
                <div
                  className={classNames('inline-icon pull-left', {
                    complete: category.is_completed,
                  })}
                >
                  {category.is_completed && <Icon type="check" />}
                </div>
                <div
                  className={classNames('progress-list-item', {
                    'semi-bold': category.id === selectedCategoryId,
                    'audit-workflow-list-item':
                      category.id === selectedCategoryId &&
                      type === AuditWorkflowTypes.Actions &&
                      isAuditWorkflow,
                  })}
                >
                  <Link to={`${options.urlBase}${id}${urlPart}${category.id}`}>
                    {category.name}
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }

  return ProgressList;
};

export default createProgressList;
