import React from 'react';

const SwitcherSection = ({text, isCurrent, onClick}) =>
  isCurrent ? (
    <span className="toggle-text">
      {text}
    </span>
  ) : (
    <a
      className="toggle-text toggle-text-link"
      onClick={onClick}
    >
      {text}
    </a>
  )
;

const Switcher = ({texts, currentIndex, onSwitch}) =>
  <div className="toggle-control">
    <SwitcherSection text={texts[0]} isCurrent={currentIndex === 0} onClick={onSwitch} />
    <span className="toggle-text-separator">|</span>
    <SwitcherSection text={texts[1]} isCurrent={currentIndex === 1} onClick={onSwitch} />
  </div>
;

export default Switcher;
