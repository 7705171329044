import React from 'react';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';


const MaybeCollapsible = ({children, collapseButton, collapsible, id, initiallyCollapsed, onExpand}) =>
  collapsible ? (
    <div className="toggle">
      <CollapseButton onExpand={onExpand} collapseId={id}>
        {collapseButton}
      </CollapseButton>

      <Collapse initiallyCollapsed={initiallyCollapsed} collapseId={id} className="content">
        {children}
      </Collapse>
    </div>
  ) : (
    <div>
      {collapseButton}
      {children}
    </div>
  )
;

export default MaybeCollapsible;
