import { AuditStatus } from '^/components/app/digital-tools/audit/types';

export const AUDIT_API_URL_BASE = '/api/audits/';
export const AUDIT_CLIENT_URL_BASE = '/page/tools/audit/';

export const STATUS_ORDER = [
  AuditStatus.AUDIT_INTRO_1,
  AuditStatus.AUDIT,
  AuditStatus.AUDIT_RESULTS,
  AuditStatus.ACTIONS_INTRO_1,
  AuditStatus.ACTIONS,
  AuditStatus.REVIEW,
  AuditStatus.COMPLETED,
];
