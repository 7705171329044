import React, { Component, ChangeEvent } from 'react';

type OwnProps = {
  onClick: (notes: string) => void;
  notes: string | null;
  disabled?: boolean;
  className?: string;
};

interface NotesEditorState {
  temporaryNotes: string;
  editing: boolean;
  isFocussedTextArea: boolean;
}

export type NotesEditorProps = OwnProps;

class NotesEditor extends Component<NotesEditorProps, NotesEditorState> {
  constructor(props: NotesEditorProps) {
    super(props);
    this.state = {
      temporaryNotes: props.notes ?? '',
      editing: false,
      isFocussedTextArea: false,
    };
  }

  render() {
    const { className, disabled } = this.props;
    const { editing, temporaryNotes } = this.state;

    return (
      <div className={className}>
        {!temporaryNotes && !editing && (
          <button
            className={`btn-minimal ${disabled ? 'hidden' : ''}`}
            onClick={this.onToggleEdit}
            disabled={disabled}
          >
            add note
          </button>
        )}

        {temporaryNotes && !editing && (
          <div>
            <p>
              <strong className="semi-bold">Notes</strong>
            </p>
            <p className="pre-wrap mr-1 display-inline">{temporaryNotes}</p>
            <button
              className={`btn-minimal ${disabled ? 'hidden' : ''}`}
              onClick={this.onToggleEdit}
              disabled={disabled}
            >
              edit
            </button>
          </div>
        )}

        {editing && (
          <div>
            <p>
              <strong className="semi-bold">Notes</strong>
            </p>
            <form onSubmit={this.onSubmit}>
              <textarea
                value={temporaryNotes}
                onChange={this.onChangeNotes}
                onFocus={this.onFocusTextArea}
                onBlur={this.onBlurTextArea}
              />
              <button
                type="submit"
                className="btn btn-save mt-1"
                disabled={disabled}
              >
                Save
              </button>
            </form>
          </div>
        )}
      </div>
    );
  }

  private onSubmit = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();
    if (!this.state.isFocussedTextArea) {
      this.setState({ editing: false });
      this.props.onClick(this.state.temporaryNotes);
    }
  };

  private onChangeNotes = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    this.setState({ temporaryNotes: event.currentTarget.value });
  };

  private onFocusTextArea = () =>
    this.setState({ ...this.state, isFocussedTextArea: true });

  private onBlurTextArea = () =>
    this.setState({ ...this.state, isFocussedTextArea: false });

  private onToggleEdit = () => {
    this.setState({ editing: !this.state.editing });
  };
}

export default NotesEditor;
