import React from 'react';

import { MODELS } from '^/permissions/capabilities';
import { isAdminType } from '^/models/user';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminList from '^/components/admin/content/perms/AdminList';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';

import {
  ADMIN_USER, ADMIN_USER_ADMIN_ROW_FIELDS,
  ADMIN_USER_ADMIN_USER_FILTERS,
  ADMIN_ACCOUNT_ADMIN_USER_CREATE_FIELDS,
} from '^/components/admin/users/user';

function transform(fieldConfig) {
  return fieldConfig.setIn(
    ['type', 'choices'],
    fieldConfig
      .getIn(['type', 'choices'])
      .filter(each => isAdminType(each.get('value')))
  );
}

const AdminUserAccountsAdmin = () =>
  <AdminContainer>

    <AdminContainer title="Admin User Accounts">
      <AdminList
        permsModel={MODELS.USER}
        model={ADMIN_USER}
        fields={ADMIN_USER_ADMIN_ROW_FIELDS}
        defaultFilters={ADMIN_USER_ADMIN_USER_FILTERS}
      />
    </AdminContainer>

    <AdminContainer className="mt-2" title="Create a New Admin User">
      <AdminCreate
        permsModel={MODELS.USER}
        model={ADMIN_USER}
        transform={transform}
        fields={ADMIN_ACCOUNT_ADMIN_USER_CREATE_FIELDS}
      />
    </AdminContainer>

  </AdminContainer>;

export default AdminUserAccountsAdmin;
