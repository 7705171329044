import React from 'react';
import { reduxForm } from 'redux-form';
import { List, fromJS, Map } from 'immutable';

import { isOptional, isOperational, isOptionalOrOperational } from '^/models/compliancereviews';

import FormError from '^/components/app/forms/FormError';
import LiveButton from '^/components/app/forms/LiveButton';
import DayOfTheWeekInput from '^/components/app/forms/DayOfTheWeekInput';
import Icon from '^/components/app/content/Icon';
import HelpTooltip from '^/components/app/widgets/HelpTooltip';
import { ADMIN_STEP_START_DAY } from '^/consts/account-set-up/fieldNames';
import { ActivityPriority } from '^/components/app/activities/ActivityPriority';

export const COMPLIANCE_REVIEWS_SETUP_FORM = 'COMPLIANCE_REVIEWS_SETUP_FORM';

export const TemplateAdminStepField = ({fields, templateAdminStep, isSubsequentSetup}) => {
  const { id, compliance_reviews } = templateAdminStep.toObject();
  const adminStepFields = fields[id];
  const operationalReviews = compliance_reviews.filter(isOperational);
  const optionalReviews = compliance_reviews.filter(isOptional).filterNot(isOperational);
  const mandatoryReviews = compliance_reviews.filterNot(isOptionalOrOperational)

  return (
    <div className="mt-1 small-text pl-1 pr-1">
      <h3>
        Monthly Focus: {templateAdminStep.getIn(['template_activity', 'name'])}
        {templateAdminStep.getIn(['template_activity', 'help_text']) && (
          <HelpTooltip>
            <p>{templateAdminStep.getIn(['template_activity', 'help_text'])}</p>
          </HelpTooltip>
        )}
      </h3>
      <div className="row">
        {!isSubsequentSetup && (
          <div className="col-1-2">
            <h5>Compulsory Compliance Reviews ({mandatoryReviews.size})</h5>
            {mandatoryReviews.size > 0 ? (
              <table className="setup-compliance-review-table">
                <tbody>
                  {mandatoryReviews.map(each => (
                    <tr key={each.get('id')}>
                      <td><Icon type="check-square" className="inline-icon" /></td>
                      <td><label title={each.getIn(['template_activity', 'help_text'])}>
                        {each.getIn(['template_activity', 'name'])}
                      </label></td>
                      <td><ActivityPriority activity={each.get('template_activity')}/></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : <p>There are no compulsory compliance reviews in this Monthly Focus</p>
            }
          </div>
        )}
        <div className="col-1-2">
            <h5>Operational Compliance Reviews ({operationalReviews.size})</h5>
              {operationalReviews.size > 0 ? (
                  <table className="setup-compliance-review-table selectable">
                    <tbody>
                      {operationalReviews.map(each => (
                        <tr key={each.get('id')}>
                          <td><input type="checkbox" {...adminStepFields[each.get('id')]} id={each.get('id')} /></td>
                          <td className="table-col-lg"><label title={each.getIn(['template_activity', 'help_text'])} htmlFor={each.get('id')}>
                            {each.getIn(['template_activity', 'name'])}
                          </label></td>
                          <td><ActivityPriority activity={each.get('template_activity')}/></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : <p>There are no operational compliance reviews in this Monthly Focus</p>}
          <br/>
            <h5>Optional Compliance Reviews ({optionalReviews.size})</h5>
            {optionalReviews.size > 0 ? (
                <table className="setup-compliance-review-table selectable">
                  <tbody>
                    {optionalReviews.map(each => (
                      <tr key={each.get('id')}>
                        <td><input type="checkbox" {...adminStepFields[each.get('id')]} id={each.get('id')} /></td>
                        <td className="table-col-lg"><label title={each.getIn(['template_activity', 'help_text'])} htmlFor={each.get('id')}>
                          {each.getIn(['template_activity', 'name'])}
                        </label></td>
                        <td><ActivityPriority activity={each.get('template_activity')}/></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : <p>There are no optional compliance reviews in this Monthly Focus</p>}
          </div>
        </div>
      </div>);
};

export const TemplateAdminStepFields = ({fields, templateAdminSteps, isSubsequentSetup}) =>
  <div className="mb-2">
    {
      templateAdminSteps && templateAdminSteps.size > 0 ?
        templateAdminSteps.map(templateAdminStep => (
          <TemplateAdminStepField
            key={templateAdminStep.get('id')}
            fields={fields}
            isSubsequentSetup={isSubsequentSetup}
            templateAdminStep={templateAdminStep}
          />
          )
        ) : <p>There are no compliance activities to choose.</p>
     }
   </div>;

export const ComplianceReviewsSetupForm = ({
   handleSubmit, response, fields, templateAdminSteps, isSubsequentSetup, buttonText
}) =>
  <form onSubmit={handleSubmit}>

    {!isSubsequentSetup && (
      <div className="form-group">
        <div className="col-1">

          <label htmlFor={ADMIN_STEP_START_DAY} className="mr-1">
            Which day would you like to perform your Monthly Focus?
          </label>
          <DayOfTheWeekInput
            field={fields[ADMIN_STEP_START_DAY]}
            className="form-select mr-1"
            id={ADMIN_STEP_START_DAY}
          />
          <FormError response={response} formKey={ADMIN_STEP_START_DAY} />
        </div>
      </div>
    )}

    <hr className="thin" />
    <TemplateAdminStepFields
      fields={fields}
      templateAdminSteps={templateAdminSteps}
      isSubsequentSetup={isSubsequentSetup}
    />

    <LiveButton className="btn-default" pendingMessage="Saving..." response={response}>
      {buttonText || 'Next...'}
    </LiveButton>
  </form>;

export function transformAdminStepData(data) {
  return fromJS(data)
    .remove(ADMIN_STEP_START_DAY)
    .map(
      (complianceReviews, adminStepId) =>
        Map({
          id: adminStepId,
          compliance_reviews: complianceReviews.filterNot(isSelected => !isSelected).keySeq()
        })
    )
    .valueSeq()
    .toJS();
}

export const getFields = (templateAdminSteps) => {
  const templateAdminStepFields = templateAdminSteps
    .map(
      adminStep =>
        adminStep
          .get('compliance_reviews', List())
          .filter(isOptionalOrOperational)
          .map(complianceReview => adminStep.get('id') + '.' + complianceReview.get('id'))
    )
    .flatten()
    .toJS();
  return [ADMIN_STEP_START_DAY].concat(templateAdminStepFields);
};

export const getInitialValues = (templateAdminSteps) =>
  Map(
    templateAdminSteps
      .map(
      adminStep =>
        [adminStep.get('id'), Map(
          adminStep
            .get('compliance_reviews', List())
            .filter(isOptionalOrOperational)
            .filter(review => review.get('included_last_cycle'))
            .map(review => [review.get('id'), true])
        )]
    )
      .flatten()
  ).set(ADMIN_STEP_START_DAY, 1)
    .toJS();

export const mapStateToProps = (state, props) => ({
  fields: getFields(props.templateAdminSteps),
  initialValues: getInitialValues(props.templateAdminSteps),
});

export default reduxForm({
  form: COMPLIANCE_REVIEWS_SETUP_FORM,
}, mapStateToProps)(ComplianceReviewsSetupForm);
