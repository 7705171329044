import { List } from 'immutable';

export const EXPORT_STATUS = {
  NOTSTARTED: 'NOTSTARTED',
  PROGRESS: 'PROGRESS',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED',
};

const pollStatusIn = statuses => responseOrAction =>
  responseOrAction && statuses.contains(responseOrAction.getIn(['payload', 'status']));

const pollStatusIs = status => pollStatusIn(List.of(status));

export const pollInProgress = pollStatusIn(List.of(EXPORT_STATUS.PROGRESS, EXPORT_STATUS.NOTSTARTED));
export const pollComplete = pollStatusIs(EXPORT_STATUS.COMPLETE);
export const pollFailed = pollStatusIs(EXPORT_STATUS.FAILED);
