import React from 'react';
import moment from 'moment';
import { reduxForm } from 'redux-form';
import { Map, List, fromJS } from 'immutable';

import LiveButton from '^/components/app/forms/LiveButton';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import { INCLUDED, DEADLINE, REMINDER, REPEAT, STAFF, TITLE, ENTRY } from '^/consts/account-set-up/fieldNames';
import ReadOnlySuggestedTaskField from '^/components/app/account-set-up/ReadOnlySuggestedTaskField';
import SuggestedTaskField from '^/components/app/account-set-up/SuggestedTaskField';
import SuggestedTasksSetupFormDateSection from '^/components/app/account-set-up/SuggestedTasksSetupFormDateSection';
import { getCycleEndDate, getCycleStartDate } from '^/models/cycle';
import { getSuggestedTasksSetupInitialValues } from './utils';

export const SUGGESTED_TASK_SETUP_FORM_ID = 'setupCycleRemindersForm';


export const SuggestedTasksSetupTable = ({
  templateOrGroupTasks, fields, teamMembers, cycleStartDate, newCycleTasks, cycleEndDate
}) =>
  <div className="reminder-table-container mt-1">
    <table className="reminder-table">
      <thead>
        <tr>
          <th colSpan={2}>Task</th>
          <th>Due Date</th>
          <th>Repeat</th>
          <th>Reminder</th>
          <th>Staff</th>
        </tr>
      </thead>
      <tbody>
        {templateOrGroupTasks.map(each =>
          <SuggestedTaskField
            key={each.get('id')}
            field={fields[each.get('id')]}
            suggestedTask={each}
            cycleStartDate={getCycleStartDate(cycleStartDate, cycleEndDate)}
            cycleEndDate={getCycleEndDate(cycleStartDate, cycleEndDate)}
            teamMembers={teamMembers}
          />
        )}
        {((newCycleTasks || []).map((each, idx) =>
          <ReadOnlySuggestedTaskField
            key={idx}
            suggestedTask={each}
            teamMembers={teamMembers}
          />
        ))}
      </tbody>
    </table>
  </div>;


export const SuggestedTasksSetupForm = ({
  fields, handleSubmit, response, templateTasks, teamMembers,
  fields: { start_date, end_date }, formErrors, values, newCycleTasks
}) =>
  <form onSubmit={handleSubmit}>

    <SuggestedTasksSetupFormDateSection start_date={start_date} end_date={end_date} response={response} />

    {values.start_date && (
      <div>
        <hr className="thin" />
        <HelpBlock className="small-text">
          Select suggested Tasks you require.
          Dates should be set now but these can be changed later in the calendar.
          You can add your own custom Tasks from the calendar later.
        </HelpBlock>

        <SuggestedTasksSetupTable
          templateOrGroupTasks={templateTasks}
          values={values}
          formErrors={formErrors}
          fields={fields}
          newCycleTasks={newCycleTasks}
          cycleStartDate={values.start_date}
          cycleEndDate={values.end_date}
          teamMembers={teamMembers}
        />

        <LiveButton className="btn-default" pendingMessage="Saving..." response={response}>
          Next...
        </LiveButton>
      </div>
    )}

  </form>;

export const getTaskFields = (templateOrGroupTasks) =>
  templateOrGroupTasks
    .map(
      templateOrGroupTask =>
        List.of(
          DEADLINE, INCLUDED, REMINDER, REPEAT, STAFF, TITLE, ENTRY
        ).map(each => templateOrGroupTask.get('id') + '.' + each)
    )
    .flatten()
    .toJS();

export const getFields = (templateOrGroupTasks) =>
  ['start_date', 'end_date'].concat(getTaskFields(templateOrGroupTasks));

export function validateTask(task, cycleStartDate, props) {
  if (!task) {
    return null;
  }

  const { deadline, included, staff } = task.toObject();

  let errors = Map();
  if (included) {
    if (!deadline) {
      errors = errors.set(
        [DEADLINE], 'You must set a deadline for a task (this can be updated in the calendar later).'
      );
    }

    if (deadline && moment(deadline).isBefore(cycleStartDate.startOf('day'))) {
      errors = errors.set(
        [DEADLINE], 'You cannot have a task before the start of the cycle.'
      );
    }

    if (!staff || staff && !props.teamMembers.flatten().includes(staff)) {
      errors = errors.set(
        [STAFF], 'You must choose a team member for a task (this can be updated in the calendar later).'
      );
    }

    return errors;
  }
}

export function validateTasks(data, startDate, props) {
  const taskData = fromJS(data).remove('start_date').remove('end_date');
  return taskData
    .map(task => validateTask(task, moment(startDate), props))
    .filterNot(value => !value || value && value.isEmpty());
}

export const validateStartDate = (startDate) => {
  if (!startDate) {
    return 'A start month is required.';
  }

  if (moment(startDate).isBefore(moment().startOf('day'))) {
    return 'You cannot start a cycle in the past.';
  }
};

export const validate = (data, props) =>
  Map({ start_date: validateStartDate(data.start_date), }).merge(validateTasks(data, data.start_date, props)).toJS();

export const mapStateToProps = (state, { templateTasks, user, }) => {
  return {
    fields: getFields(templateTasks),
    initialValues: getSuggestedTasksSetupInitialValues(templateTasks, user),
  };
};

export default reduxForm(
  {
    form: SUGGESTED_TASK_SETUP_FORM_ID,
    validate,
  },
  mapStateToProps
)(SuggestedTasksSetupForm);
