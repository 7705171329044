import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import { archiveDocumentToReadAndCloseModal } from '^/actions/actionSequences';

export const ArchiveDocumentToReadModal = ({ 
    documentVersionToReadId,
    documentName,
    onArchive,
    onClose
}) => {
  return (
    <div>
      <div className="row">
        <div className="col-1">
          <p>
            Are you sure you wish to archive the read request for: {documentName}?
          </p>
        </div>
      </div>
      <div className="row align-right mt-1">
        <div className="col-1">
          <button className="btn btn-default" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-warning ml-1" onClick={() => onArchive(documentVersionToReadId)}>
            Archive
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
    onArchive: archiveDocumentToReadAndCloseModal,
    onClose: closeModal
})(ArchiveDocumentToReadModal);
