import { connect } from 'react-redux';

import { isCurrentPracticeDemoCalendarOnly } from '^/stateHelpers';
import If from '^/components/common/If';

export const mapStateToProps = state => ({
  condition: !isCurrentPracticeDemoCalendarOnly(state)
});

export default connect(mapStateToProps, null) (If);
