import React from 'react';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminList from '^/components/admin/content/list/AdminList';

import {
  ADMIN_RESPONSIBILITY_MODEL,
  ADMIN_RESPONSIBILITY_ROW_FIELDS,
} from './responsibilities';


export const ResponsibilitiesAdmin = () =>
  <div>
    <AdminContainer>
      <AdminList
        title="All Responsibilities"
        model={ADMIN_RESPONSIBILITY_MODEL}
        fields={ADMIN_RESPONSIBILITY_ROW_FIELDS}
      />
    </AdminContainer>
  </div>;

export default connect(undefined, { routePush: routeActions.push })(ResponsibilitiesAdmin);
