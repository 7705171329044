import React from 'react';

import Pagination from '^/components/app/groups/insights/visualisations/Pagination';
import InsightsCSVExportButton from '^/components/app/groups/insights/InsightsCSVExportButton';
import PrintButton from '^/components/app/groups/insights/visualisations/PrintButton';

const TableFooter = ({insight, csvUrl, csvExportButton}) => {
  return (
    <div className="row">
      <div className="col-1 table-footer display-flex flex-justify-space-between flex-align-items-center">
        <Pagination
          insight={insight}
        />
        <div className="pull-right">
          {csvUrl && (
            <InsightsCSVExportButton
              url={csvUrl}
              insight={insight}
            />
          )}
          {csvExportButton}
          <PrintButton />
        </div>
      </div>
    </div>
  );
};

TableFooter.propTypes = {
  insight: React.PropTypes.string.isRequired,
  csvUrl: React.PropTypes.string,
};

export default TableFooter;
