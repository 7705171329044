import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { AnyAction, Dispatch } from 'redux';
import { push } from 'react-router-redux';

import { StoreState } from '^/store/types';
import {
  AuditStatus,
  Audit,
  AuditCategoriesProgress,
  AuditCategory,
  AuditAction,
  AnswerQuestionAction,
  CategoryExtension,
  AuditQuestion,
  AuditReview,
} from '^/components/app/digital-tools/audit/types';
import {
  STATUS_ORDER,
  AUDIT_API_URL_BASE,
  AUDIT_CLIENT_URL_BASE,
} from '^/consts/audit';
import { makeThunkRequest } from '^/utils/requests';
import { makeAsyncActionSet } from './utils';
import { closeModal } from './modals';

const noop = () => null;

export const GENERIC_AUDIT_ERROR = 'GENERIC_AUDIT_ERROR';

export const GET_AUDIT_QUESTIONS_PROGRESS = makeAsyncActionSet(
  'GET_AUDIT_QUESTIONS_PROGRESS'
);
export const getAuditQuestionsCategories = (auditId: string) =>
  makeThunkRequest<AuditCategoriesProgress>(
    GET_AUDIT_QUESTIONS_PROGRESS,
    {
      url: `${AUDIT_API_URL_BASE}${auditId}/questions-progress/`,
    },
    { rethrowErrors: true }
  );

export const GET_AUDIT_QUESTIONS_CATEGORY = makeAsyncActionSet(
  'GET_AUDIT_QUESTIONS_CATEGORY'
);
export const getAuditQuestionsCategory = (
  auditId: string,
  categoryId: string
) => (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>,
  getState: () => StoreState
) => {
  const state = getState();

  const requestAction = makeThunkRequest<CategoryExtension<AuditQuestion>>(
    GET_AUDIT_QUESTIONS_CATEGORY,
    {
      url: `${AUDIT_API_URL_BASE}${auditId}/categories/${categoryId}/questions/`,
    }
  );

  if (state.audit.questionsCategories) {
    return dispatch(requestAction);
  }

  return getAuditQuestionsCategories(auditId)(dispatch)
    .then(() => {
      return dispatch(requestAction);
    })
    .catch(noop);
};

const getFirstIncompleteCategoryId = (categories: AuditCategoriesProgress) => {
  const match = categories.find(({ is_completed }) => !is_completed);

  if (match) {
    return match.id;
  }

  return categories.length ? categories[categories.length - 1].id : null;
};

const getAuditQuestionsProgressAndRedirect = (auditId: string) => (
  dispatch: Dispatch<StoreState>
) =>
  makeThunkRequest<AuditCategoriesProgress>(
    GET_AUDIT_QUESTIONS_PROGRESS,
    {
      url: `${AUDIT_API_URL_BASE}${auditId}/questions-progress/`,
    },
    { rethrowErrors: true }
  )(dispatch)
    .then(response => {
      const categoryId = getFirstIncompleteCategoryId(response.data);
      dispatch(
        push(`${AUDIT_CLIENT_URL_BASE}${auditId}/questions/${categoryId}/`)
      );
    })
    .catch(noop);

export const GET_AUDIT_ACTIONS_PROGRESS = makeAsyncActionSet(
  'GET_AUDIT_ACTIONS_PROGRESS'
);
export const getAuditActionsCategories = (auditId: string) =>
  makeThunkRequest<AuditCategoriesProgress>(
    GET_AUDIT_ACTIONS_PROGRESS,
    {
      url: `${AUDIT_API_URL_BASE}${auditId}/actions-progress/`,
    },
    { rethrowErrors: true }
  );

const getAuditActionsProgressAndRedirect = (auditId: string) => (
  dispatch: Dispatch<StoreState>
) =>
  makeThunkRequest<AuditCategoriesProgress>(
    GET_AUDIT_ACTIONS_PROGRESS,
    {
      url: `${AUDIT_API_URL_BASE}${auditId}/actions-progress/`,
    },
    { rethrowErrors: true }
  )(dispatch)
    .then(response => {
      const categoryId = getFirstIncompleteCategoryId(response.data);
      dispatch(
        push(
          `${AUDIT_CLIENT_URL_BASE}${auditId}/actions/${
            categoryId ? categoryId : ''
          }`
        )
      );
    })
    .catch(noop);

export const continueAuditFrom = (status: AuditStatus, auditId: string) => (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>
) => {
  const unexpectedStatus = {
    type: GENERIC_AUDIT_ERROR,
    payload: `Unexpected audit status: ${status}`,
  };

  const statusActions: Record<
    AuditStatus,
    AnyAction | ThunkAction<unknown, StoreState, unknown, AnyAction>
  > = {
    [AuditStatus.AUDIT_INTRO_1]: push(
      `${AUDIT_CLIENT_URL_BASE}${auditId}/audit-intro-1/`
    ),
    [AuditStatus.AUDIT_RESULTS]: push(
      `${AUDIT_CLIENT_URL_BASE}${auditId}/audit-results/`
    ),
    [AuditStatus.ACTIONS_INTRO_1]: push(
      `${AUDIT_CLIENT_URL_BASE}${auditId}/actions-intro-1/`
    ),
    [AuditStatus.REVIEW]: push(`${AUDIT_CLIENT_URL_BASE}${auditId}/review/`),
    [AuditStatus.COMPLETED]: push(`${AUDIT_CLIENT_URL_BASE}${auditId}/review/`),

    [AuditStatus.AUDIT]: getAuditQuestionsProgressAndRedirect(auditId),
    [AuditStatus.ACTIONS]: getAuditActionsProgressAndRedirect(auditId),
  };

  const action = statusActions[status] ?? unexpectedStatus;

  dispatch(action);
};

export const UPDATE_AUDIT = makeAsyncActionSet('UPDATE_AUDIT_STATUS');
export const updateAudit = (auditId: string, data: Partial<Audit>) =>
  makeThunkRequest<Audit>(
    UPDATE_AUDIT,
    {
      url: `${AUDIT_API_URL_BASE}${auditId}/`,
      method: 'PATCH',
      data,
    },
    { rethrowErrors: true }
  );

export const updateAuditStatusIfMatchAndContinue = (
  audit: Audit,
  intendedFromStatus: AuditStatus
) => (dispatch: ThunkDispatch<StoreState, unknown, AnyAction>) => {
  if (audit) {
    const currentStatusIndex = STATUS_ORDER.indexOf(intendedFromStatus);
    const nextStatus =
      currentStatusIndex < STATUS_ORDER.length - 1
        ? STATUS_ORDER[currentStatusIndex + 1]
        : null;

    if (audit.status === intendedFromStatus && nextStatus) {
      updateAudit(audit.id, { status: nextStatus })(dispatch).then(() => {
        continueAuditFrom(nextStatus, audit.id)(dispatch);
      });
    } else if (nextStatus) {
      continueAuditFrom(nextStatus, audit.id)(dispatch);
    }
  }
};

export const GET_CURRENT_PRACTICE_AUDITS = makeAsyncActionSet(
  'GET_CURRENT_PRACTICE_AUDITS'
);
export const getCurrentPracticeAudits = () =>
  makeThunkRequest(GET_CURRENT_PRACTICE_AUDITS, {
    url: `${AUDIT_API_URL_BASE}`,
    method: 'GET',
  });

export const GET_AUDIT_REVIEW_SCORES = makeAsyncActionSet(
  'GET_AUDIT_REVIEW_SCORES'
);
export const getAuditReviewScores = (auditId: string) => (
  dispatch: Dispatch<StoreState>
) =>
  makeThunkRequest(
    GET_AUDIT_REVIEW_SCORES,
    {
      url: `${AUDIT_API_URL_BASE}${auditId}/review-scores/`,
      method: 'GET',
    },
    { rethrowErrors: true }
  )(dispatch);

export const GET_AUDIT_REVIEW_TASKS = makeAsyncActionSet(
  'GET_AUDIT_REVIEW_TASKS'
);
export const getAuditReviewTasks = (auditId: string) => (
  dispatch: Dispatch<StoreState>
) =>
  makeThunkRequest(
    GET_AUDIT_REVIEW_TASKS,
    {
      url: `${AUDIT_API_URL_BASE}${auditId}/review-tasks/`,
      method: 'GET',
    },
    { rethrowErrors: true },
    { auditId }
  )(dispatch);

export const GET_AUDIT_REVIEW_CATEGORIES = makeAsyncActionSet(
  'GET_AUDIT_REVIEW_CATEGORIES'
);
export const getAuditReviewCategories = (auditId: string) => (
  dispatch: Dispatch<StoreState>
) =>
  makeThunkRequest(
    GET_AUDIT_REVIEW_CATEGORIES,
    {
      url: `${AUDIT_API_URL_BASE}${auditId}/review-categories/`,
      method: 'GET',
    },
    { rethrowErrors: true },
    { auditId }
  )(dispatch);

export const GET_AUDIT_ACTIONS_CATEGORY = makeAsyncActionSet(
  'GET_AUDIT_ACTIONS_CATEGORY'
);
export const getAuditActionsCategory = (
  auditId: string,
  categoryId: string
) => (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>,
  getState: () => StoreState
) => {
  const state = getState();

  const requestAction = makeThunkRequest<AuditCategory<AuditAction>>(
    GET_AUDIT_ACTIONS_CATEGORY,
    {
      url: `${AUDIT_API_URL_BASE}${auditId}/categories/${categoryId}/actions/`,
    }
  );

  if (state.audit.actionsCategories) {
    return dispatch(requestAction);
  }

  return getAuditActionsCategories(auditId)(dispatch)
    .then(() => {
      return dispatch(requestAction);
    })
    .catch(noop);
};

export const UPDATE_AUDIT_QUESTION = makeAsyncActionSet(
  'UPDATE_AUDIT_QUESTION'
);
export const updateAuditQuestion = (
  auditId: string,
  categoryId: string,
  questionId: string,
  data: Partial<AuditQuestion>
) => (dispatch: Dispatch<StoreState>) =>
  makeThunkRequest<unknown, AnswerQuestionAction>(
    UPDATE_AUDIT_QUESTION,
    {
      url: `${AUDIT_API_URL_BASE}${auditId}/questions/${questionId}/`,
      method: 'PATCH',
      data,
    },
    {
      rethrowErrors: false,
      requestId: questionId,
    },
    {
      questionId,
      categoryId,
      auditId,
    }
  )(dispatch);

export const DELETE_AUDIT = makeAsyncActionSet('DELETE_AUDIT');
const deleteAudit = (auditId: string) => (dispatch: Dispatch<StoreState>) =>
  makeThunkRequest(
    DELETE_AUDIT,
    {
      url: `${AUDIT_API_URL_BASE}${auditId}/`,
      method: 'DELETE',
    },
    { rethrowErrors: true },
    auditId
  )(dispatch);

export const deleteAuditAndNavigateToDashboard = (auditId: string) => (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>
) => {
  deleteAudit(auditId)(dispatch)
    .then(() => {
      dispatch(closeModal());
      dispatch(push('/page/dashboard'));
    })
    .catch(noop);
};

export const CREATE_AUDIT = makeAsyncActionSet('CREATE_AUDIT');
export const createAudit = (auditType: string) =>
  makeThunkRequest<Audit>(
    CREATE_AUDIT,
    {
      url: `${AUDIT_API_URL_BASE}`,
      method: 'POST',
      data: { audit_type: auditType },
    },
    { rethrowErrors: true }
  );

export const CREATE_AUDIT_FROM_ACTIVITY = makeAsyncActionSet(
  'CREATE_AUDIT_FROM_ACTIVITY'
);
export const createAuditFromActivity = (activity: string) =>
  makeThunkRequest<Audit>(
    CREATE_AUDIT_FROM_ACTIVITY,
    {
      url: `${AUDIT_API_URL_BASE}`,
      method: 'POST',
      data: { activity },
    },
    { rethrowErrors: true }
  );

export const createAuditAndContinue = (auditType: string) => (
  dispatch: Dispatch<StoreState>
) => {
  createAudit(auditType)(dispatch)
    .then(response =>
      continueAuditFrom(response.data.status, response.data.id)(dispatch)
    )
    .catch(noop);
};

export const createAuditFromActivityAndContinue = (activity: string) => (
  dispatch: Dispatch<StoreState>
) => {
  createAuditFromActivity(activity)(dispatch)
    .then(response =>
      continueAuditFrom(response.data.status, response.data.id)(dispatch)
    )
    .catch(noop);
};

export const GET_AUDIT_BY_ID = makeAsyncActionSet('GET_AUDIT_BY_ID');
export const getAuditById = (auditId: string) => (
  dispatch: Dispatch<StoreState>
) =>
  makeThunkRequest(
    GET_AUDIT_BY_ID,
    {
      url: `${AUDIT_API_URL_BASE}${auditId}/`,
      method: 'GET',
    },
    { rethrowErrors: true }
  )(dispatch);

export const GET_AUDIT_BY_TYPE = makeAsyncActionSet('GET_AUDIT_BY_TYPE');
export const getAuditsByType = (auditTypeId: string) => (
  dispatch: Dispatch<StoreState>
) =>
  makeThunkRequest(
    GET_AUDIT_BY_TYPE,
    {
      url: `${AUDIT_API_URL_BASE}?audit_type=${auditTypeId}`,
      method: 'GET',
    },
    { rethrowErrors: true },
    auditTypeId
  )(dispatch);

export const GET_AUDIT_TYPES = makeAsyncActionSet('GET_AUDIT_TYPES');
export const getAuditTypes = () => (dispatch: Dispatch<StoreState>) =>
  makeThunkRequest(
    GET_AUDIT_TYPES,
    {
      url: `${AUDIT_API_URL_BASE}audit-types/`,
      method: 'GET',
    },
    { rethrowErrors: true }
  )(dispatch);

export const GET_AUDIT_AUDIT_RESULTS = makeAsyncActionSet(
  'GET_AUDIT_AUDIT_RESULTS'
);
export const getAuditAuditResults = (healthcheckId: string) =>
  makeThunkRequest<AuditReview>(GET_AUDIT_AUDIT_RESULTS, {
    url: `${AUDIT_API_URL_BASE}${healthcheckId}/review-scores/`,
  });
