import React from 'react';
import MaybeLink from '^/components/app/content/MaybeLink';
import ImmutablePropTypes from 'react-immutable-proptypes';

const ActivityLink = ({ activity, children, className, hideIfReadOnly }) => {
  const { read_only, help_text, id } = activity.toObject();

  return hideIfReadOnly && read_only ? (
    <noscript />
  ) : (
    <MaybeLink
      className={className}
      title={help_text}
      to={!read_only && `/page/activities/${id}/`}
    >
      {children}
    </MaybeLink>
  );
};

ActivityLink.propTypes = {
  activity: ImmutablePropTypes.map.isRequired,
};

export default ActivityLink;
