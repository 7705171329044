import React from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { getAllCollection } from '^/actions/collections';
import { setShowing } from '^/actions/actions';
import { isPending } from '^/consts/responseStates';
import { ADMIN_COMPLIANCE_SYSTEMS } from '^/consts/collectionKeys';
import PureComponent from '^/components/common/PureComponent';
import AdminManyRelatedFieldAdd from '^/components/admin/content/many-related-field/AdminManyRelatedFieldAdd';

const ROOT_COMPLIANCE_SYSTEMS = 'ROOT_COMPLIANCE_SYSTEMS';

export class AdminComplianceSystemFieldAdd extends PureComponent {

  componentWillMount() {
    this.props.setSystem(null);
    this.props.getAllCollection(ADMIN_COMPLIANCE_SYSTEMS, {filters: {root: 'True', ...this.props.defaultLookupFilters}}, ROOT_COMPLIANCE_SYSTEMS);
  }

  setRootComplianceSystem(system) {
    this.props.setSystem(system);
    this.props.getAllCollection(ADMIN_COMPLIANCE_SYSTEMS, {filters: {ancestor: system, ...this.props.defaultLookupFilters}});
  }

  render() {
    const { rootComplianceSystems, complianceSystems, loading, onSubmit, form, system } = this.props;

    return (
      <div className="admin-edit-create-form">
        <select
          value={system}
          onChange={evt => this.setRootComplianceSystem(evt.target.value)}
          disabled={loading}
        >
          <option>--</option>
          {rootComplianceSystems.map((option, key) =>
            <option key={key} value={option.get('id')}>{option.get('name')}</option>
          )}
        </select>

        <AdminManyRelatedFieldAdd
          onSubmit={onSubmit}
          disabled={loading}
          form={form}
          options={complianceSystems.map(each =>
            Map({
              value: each.get('id'),
              display_name: each.get('prefix') + ' : ' + each.get('name'),
              disabled: !each.get('is_leaf'),
            })
          )}
        />
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    rootComplianceSystems: state.collections.getIn([ROOT_COMPLIANCE_SYSTEMS, 'items'], List()),
    complianceSystems: state.collections.getIn([ADMIN_COMPLIANCE_SYSTEMS, 'items'], List()),
    loading: isPending(state.responses.getIn(['getCollection', ADMIN_COMPLIANCE_SYSTEMS])),
    system: state.ui.getIn(['showing', ADMIN_COMPLIANCE_SYSTEMS]),
  };
}

export default connect(
  mapStateToProps,
  { getAllCollection, setSystem: system => setShowing(ADMIN_COMPLIANCE_SYSTEMS, system) }
) (AdminComplianceSystemFieldAdd);
