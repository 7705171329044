import withInsightData from './withInsightData';
import withLoadInsightDataOnMount from './withLoadInsightDataOnMount';


const withInsight = ({ insight, url, getFilters }) =>
  (Component) =>
    withLoadInsightDataOnMount({ insight, url, getFilters })(
      withInsightData(insight)(Component)
    );

export default withInsight;
