import React from 'react';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';

import PureComponent from '^/components/common/PureComponent';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';

import {
  ADMIN_HEALTHCHECK_QUESTION_CREATE,
  ADMIN_HEALTHCHECK_QUESTION_MODEL,
  ADMIN_HEALTHCHECK_QUESTION_CREATE_FIELDS,
  ADMIN_HEALTHCHECK_ROW_FIELDS,
} from '^/components/admin/healthcheck/healthcheck';


export class HealthcheckAdmin extends PureComponent {

  handleComplete(model, data, collectionName, formName, newItem) {
    this.props.routePush('/admin/healthcheck/question/' + newItem.id);
  }
  render() {
    return (
      <div>
        <AdminContainer className="mb-2" title="Healthcheck">
          <AdminCreate
            controlName={ADMIN_HEALTHCHECK_QUESTION_CREATE}
            collectionName={ADMIN_HEALTHCHECK_QUESTION_CREATE}
            model={ADMIN_HEALTHCHECK_QUESTION_MODEL}
            fields={ADMIN_HEALTHCHECK_QUESTION_CREATE_FIELDS}
            onComplete={this.handleComplete.bind(this)}
            title="Create a new Healthcheck Question"
          />
        </AdminContainer>

        <AdminContainer>
          <AdminList
            model={ADMIN_HEALTHCHECK_QUESTION_MODEL}
            fields={ADMIN_HEALTHCHECK_ROW_FIELDS}
            title="Healthcheck Questions"
          />
        </AdminContainer>
      </div>
    );
  }
};

export default connect(null, { routePush: routeActions.push })(HealthcheckAdmin);
