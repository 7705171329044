import React from 'react';
import { Link } from 'react-router';
import { MODELS } from '^/permissions/capabilities';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminList from '^/components/admin/content/perms/AdminList';
import PureComponent from '^/components/common/PureComponent';

import {
  ADMIN_TEMPLATE_DOCUMENT_MODEL,
  ADMIN_TEMPLATE_DOCUMENT_ROW_FIELDS,
} from './template-documents';


export default class TemplateDocumentsAdmin extends PureComponent {
  render() {
    return (
      <div>
        <Link to="/admin/template-documents/categories/" className="btn btn-primary">
          Manage Categories
        </Link>
        <Link to="/admin/template-documents/folders/" className="btn btn-primary ml-1">
          Manage Folders
        </Link>
        <Link to="/admin/template-documents/create/" className="btn btn-primary ml-1">
          Create new Document
        </Link>
        <Link to="/admin/template-documents/drafts/" className="btn btn-primary ml-1">
          Drafts
        </Link>

        <AdminContainer>
          <AdminList
            permsModel={MODELS.TEMPLATE_DOCUMENT}
            title="All Template Documents"
            model={ADMIN_TEMPLATE_DOCUMENT_MODEL}
            fields={ADMIN_TEMPLATE_DOCUMENT_ROW_FIELDS}
            isSectorFiltered
          />
        </AdminContainer>
      </div>
    );
  }
}
