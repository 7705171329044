import { createReducer } from '@dabapps/redux-create-reducer';
import { combineReducers, AnyAction } from 'redux';

import {
  GENERIC_AUDIT_ERROR,
  GET_CURRENT_PRACTICE_AUDITS,
  GET_AUDIT_REVIEW_SCORES,
  GET_AUDIT_REVIEW_TASKS,
  GET_AUDIT_REVIEW_CATEGORIES,
  GET_AUDIT_BY_ID,
  DELETE_AUDIT,
  UPDATE_AUDIT,
  GET_AUDIT_ACTIONS_PROGRESS,
  GET_AUDIT_ACTIONS_CATEGORY,
  GET_AUDIT_TYPES,
  GET_AUDIT_QUESTIONS_PROGRESS,
  GET_AUDIT_QUESTIONS_CATEGORY,
  UPDATE_AUDIT_QUESTION,
  GET_AUDIT_BY_TYPE,
  GET_AUDIT_AUDIT_RESULTS,
} from '^/actions/audit';
import {
  AuditListSummary,
  AuditReviewScores,
  AuditReviewCategory,
  Audit,
  Task,
  AuditType,
  AuditState,
  AuditCategoriesMerged,
  AuditQuestion,
  AuditCategoriesProgress,
  AuditCategory,
  AnswerQuestionAction,
  AuditAction,
  AuditCategoryMerged,
  AuditAuditResult,
} from '^/components/app/digital-tools/audit/types';
import { CREATE_ITEM } from '^/actions/items';
import { ItemKeyedById, CachedItem } from '^/components/app/healthcheck/types';
import {
  ThunkActionWithPayload,
  ThunkRequestSuccessAction,
  createThunkRequestReducer,
} from '^/utils/requests';

const genericAuditError = createReducer<
  string | null,
  { type: string; payload: string }
>(
  {
    [GENERIC_AUDIT_ERROR]: (_state, action) => action.payload,
  },
  null
);

function returnPayloadData<T>(_state: T, action: AnyAction): T {
  return action.payload.data;
}

const currentPracticeAudits = createReducer<AuditListSummary[]>(
  {
    [GET_CURRENT_PRACTICE_AUDITS.SUCCESS]: returnPayloadData,
  },
  []
);

const reviewScoresById = createReducer<ItemKeyedById<AuditReviewScores | null>>(
  {
    [GET_AUDIT_REVIEW_SCORES.SUCCESS]: (state, action) => ({
      ...state,
      [action.payload.data.id]: {
        ...action.payload.data,
      },
    }),
  },
  {}
);

const reviewTasksById = createReducer<ItemKeyedById<readonly Task[] | null>>(
  {
    [GET_AUDIT_REVIEW_TASKS.SUCCESS]: (state, action) => ({
      ...state,
      [action.meta.extra.auditId]: action.payload.data,
    }),
  },
  {}
);

const reviewCategoriesById = createReducer<
  ItemKeyedById<readonly AuditReviewCategory[] | null>
>(
  {
    [GET_AUDIT_REVIEW_CATEGORIES.SUCCESS]: (state, action) => ({
      ...state,
      [action.meta.extra.auditId]: action.payload.data,
    }),
  },
  {}
);

const auditById = createReducer<ItemKeyedById<CachedItem<Audit>>>(
  {
    [GET_AUDIT_BY_ID.SUCCESS]: (state, action) => ({
      ...state,
      [action.payload.data.id]: {
        ...action.payload.data,
        modified: action.payload.data.modified,
      },
    }),
    [UPDATE_AUDIT.SUCCESS]: (state, action) => ({
      ...state,
      [action.payload.data.id]: {
        ...state[action.payload.data.id],
        review_summary: action.payload.data.review_summary,
        status: action.payload.data.status,
        modified: action.payload.data.modified,
      },
    }),
    [DELETE_AUDIT.SUCCESS]: (state, action) => ({
      ...state,
      [action.meta.extra]: null,
    }),
  },
  {}
);

export const actionsCategories = createReducer<
  AuditCategoriesMerged<AuditAction> | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ThunkActionWithPayload<any, any>
>(
  {
    [GET_AUDIT_ACTIONS_PROGRESS.SUCCESS]: (
      _state,
      action: ThunkRequestSuccessAction<AuditCategoriesProgress, {}>
    ) => action.payload.data,
    [UPDATE_AUDIT_QUESTION.SUCCESS]: (
      state,
      action: ThunkRequestSuccessAction<AuditQuestion, AnswerQuestionAction>
    ) => {
      if (!state) {
        return state;
      }

      return state.map(category => {
        if (category.id !== action.meta.extra.categoryId) {
          return category;
        }
        const updatedQuestions = category.questions?.map(question => {
          if (question.id !== action.meta.extra.questionId) {
            return question;
          }

          return {
            ...question,
            is_actioned: action.payload.data.is_actioned,
            note: action.payload.data.note,
          };
        });
        return {
          ...category,
          questions: updatedQuestions,
          is_completed: Boolean(
            updatedQuestions
              ?.filter(question => !question.is_correct)
              .every(question => question.is_actioned)
          ),
        };
      });
    },
    [GET_AUDIT_ACTIONS_CATEGORY.SUCCESS]: (
      state,
      action: ThunkRequestSuccessAction<AuditCategoryMerged<AuditAction>>
    ) => {
      if (!state) {
        return state;
      }

      return state.map(category => {
        if (category.id !== action.payload.data.id) {
          return category;
        }
        return { ...category, ...action.payload.data };
      });
    },
  },
  null
);

export const tasksByAuditQuestionId = createReducer<
  ItemKeyedById<readonly Task[]>
>(
  {
    [GET_AUDIT_ACTIONS_CATEGORY.SUCCESS]: (state, action) => {
      const tasksByQuestion = action.payload.data.questions.reduce(
        (
          object: ItemKeyedById<readonly Task[]> = {},
          question: AuditAction
        ) => {
          object[question.id] = question.tasks;
          return object;
        },
        {}
      );

      return {
        ...state,
        ...tasksByQuestion,
      };
    },
    [CREATE_ITEM.SUCCESS]: (state, action) => {
      if (
        action.meta.collectionName === 'tasks' &&
        action.payload.practice_audit_question
      ) {
        return {
          ...state,
          [action.payload.practice_audit_question]: [
            ...state[action.payload.practice_audit_question],
            action.payload,
          ],
        };
      }
      return state;
    },
  },
  {}
);

export const auditTypes = createReducer<readonly AuditType[]>(
  {
    [GET_AUDIT_TYPES.SUCCESS]: (_state, action) => action.payload.data,
  },
  []
);

export const auditsByType = createReducer<ItemKeyedById<AuditListSummary[]>>(
  {
    [GET_AUDIT_BY_TYPE.SUCCESS]: (state, action) => {
      return {
        ...state,
        [action.meta.extra]: action.payload.data,
      };
    },
  },
  {}
);

const questionsCategories = createReducer<
  AuditCategoriesMerged<AuditQuestion> | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ThunkActionWithPayload<any, any>
>(
  {
    [GET_AUDIT_QUESTIONS_PROGRESS.SUCCESS]: (
      _state,
      action: ThunkRequestSuccessAction<AuditCategoriesProgress, {}>
    ) => action.payload.data,
    [GET_AUDIT_QUESTIONS_CATEGORY.SUCCESS]: (
      state,
      action: ThunkRequestSuccessAction<AuditCategory<AuditQuestion>, {}>
    ) => {
      if (!state) {
        return state;
      }

      return state.map(category => {
        if (category.id !== action.payload.data.id) {
          return category;
        }

        return {
          ...category,
          ...action.payload.data,
        };
      });
    },
    [UPDATE_AUDIT_QUESTION.SUCCESS]: (
      state,
      action: ThunkRequestSuccessAction<AuditQuestion, AnswerQuestionAction>
    ) => {
      if (!state) {
        return state;
      }

      return state.map(category => {
        if (category.id !== action.meta.extra.categoryId) {
          return category;
        }

        const updatedQuestions = category.questions?.map(question => {
          if (question.id !== action.meta.extra.questionId) {
            return question;
          }

          return action.payload.data;
        });

        return {
          ...category,
          questions: updatedQuestions,
          is_completed: Boolean(
            updatedQuestions?.every(question => question.selected_response)
          ),
        };
      });
    },
  },
  null
);

const auditResults = createThunkRequestReducer<AuditAuditResult[] | null>(
  GET_AUDIT_AUDIT_RESULTS,
  null
);

export default combineReducers<AuditState>({
  actionsCategories,
  auditById,
  auditTypes,
  auditsByType,
  auditResults,
  currentPracticeAudits,
  genericAuditError,
  questionsCategories,
  reviewScoresById,
  reviewTasksById,
  reviewCategoriesById,
  tasksByAuditQuestionId,
});
