import React from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import { IS_USING_SSO_AUTH_SERVICE } from '^/consts/single-sign-on';
import { MODELS } from '^/permissions/capabilities';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';

import {
  ADMIN_USER,
  ADMIN_USER_ROW_FIELDS,
  ADMIN_USER_END_USER_FILTERS,
  ADMIN_USER_CREATE,
  ADMIN_USER_CREATE_FIELDS,
} from '^/components/admin/users/user';

export const UserAccountsAdmin = ({ handleComplete }) => {
  return (
    <div>
      <AdminContainer title="User Accounts">
        <AdminList
          permsModel={MODELS.USER}
          model={ADMIN_USER}
          fields={ADMIN_USER_ROW_FIELDS}
          defaultFilters={ADMIN_USER_END_USER_FILTERS}
          title={'All Users'}
          isSectorFiltered
        />
      </AdminContainer>
      {!IS_USING_SSO_AUTH_SERVICE && (
        <AdminContainer title="Create a new user">
          <AdminCreate
            permsModel={MODELS.USER}
            controlName={ADMIN_USER_CREATE}
            onComplete={handleComplete}
            collectionName={ADMIN_USER_CREATE}
            model={ADMIN_USER}
            fields={ADMIN_USER_CREATE_FIELDS}
          />
        </AdminContainer>
      )}
    </div>
  );
};

export default connect(undefined, {
  handleComplete: (model, data, collectionName, formName, newItem) => {
    return routeActions.push('/admin/users/' + newItem.id);
  },
})(UserAccountsAdmin);
