import React from 'react';

import { hasStats } from '^/models/activities';
import LandingCard from '^/components/app/groups/insights/LandingCard';
import ActivitiesChart from '^/components/app/groups/insights/activities/chart/ActivitiesChart';
import { TASKS_CHART_ID } from '^/components/app/groups/insights/activities/chart/constants';

export const TaskStatsProgress = ({ stats }) => {
  return (
    <div className="mt-1 mt-2-sm">
      <LandingCard
        title="Tasks (last 12 months)"
        link={''}
        linkText=""
        lastUpdatedTimestamp={null}
        query={{}}
        className='activities-insights-card full-height text-center'
      >
        {hasStats(stats) ?
          <ActivitiesChart data={stats} chartId={TASKS_CHART_ID} />
        :
          <div className="mt-4">Complete your first Task to see how your progress will show</div>
        }
      </LandingCard>
    </div>
  );

};

export default TaskStatsProgress;
