import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { setActiveTab } from '^/actions/actions';
import Icon from '^/components/app/content/Icon';
import PureComponent from '^/components/common/PureComponent';

export class ContentTab extends PureComponent {

  setActiveTab() {
    if (this.props.tabsetName) {
      this.props.setActiveTab(this.props.tabsetName, this.props.tabId);
      if (this.props.onSetActive) { this.props.onSetActive(); }
    }
  }

  getClassNames() {
    const { className, isActive } = this.props;
    return 'tab ' + (className ? className : '') + (isActive ? ' active' : '');
  }

  renderTitle() {
    const { tabName, icon } = this.props;

    return (
      <span>
        {icon && <Icon type={icon} className="tip-icon" />}
        {tabName}
      </span>
    );
  }

  render() {
    const { isActive } = this.props;
    const title = this.renderTitle();

    return (
      <div className={this.getClassNames()}>
        {isActive ? (
          <span className="tab-link">
            {title}
          </span>
        ) : (
          <a className="tab-link text-underlined-on-hover" onClick={() => this.setActiveTab()}>
            {title}
          </a>
        )}
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  const activeTab = state.ui.get('activeTabs', Map()).get(props.tabsetName);
  return {
    activeTab: activeTab,
    isActive: activeTab !== undefined ? activeTab === props.tabId : false
  };
}

ContentTab.propTypes = {
  tabName: React.PropTypes.string.isRequired,
  tabsetName: React.PropTypes.string.isRequired,
  tabId: React.PropTypes.any.isRequired,
  className: React.PropTypes.string,
  icon: React.PropTypes.string,
  onSetActive: React.PropTypes.func,
};

export default connect(mapStateToProps, { setActiveTab })(ContentTab);
