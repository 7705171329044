import React from 'react';
import { connect } from 'react-redux';
import ActivityEvidenceNotesForm from './ActivityEvidenceNotesForm';
import { patchItem } from '^/actions/items';
import { ACTIVITIES } from '^/consts/collectionKeys';

export const ActivityEvidenceNotesSave = ({activity, response, update, displayActionsTaken}) => {
  const { is_completed, info, notes, doc_location } = activity.toObject();

  return (
    <ActivityEvidenceNotesForm
      initialValues={{info, notes, doc_location}}
      onSubmit={update}
      isCompleted={is_completed}
      activity={activity}
      onMarkComplete={data => update(Object.assign({}, data, {is_completed: true}))}
      response={response}
      displayActionsTaken={displayActionsTaken}
    />
  );
};

export const mapStateToProps = state => ({
  response: state.responses.getIn(['updateItem', ACTIVITIES])
});

export const mapDispatchToProps = (dispatch, props) => ({
  update: data => dispatch(patchItem(ACTIVITIES, props.activity.get('id'), data, ACTIVITIES, true)),
});

export default connect(mapStateToProps, mapDispatchToProps) (ActivityEvidenceNotesSave);
