import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { List } from 'immutable';

import AdminFormError from '^/components/admin/content/edit-create/dynamic-forms/AdminFormError';

const PASSWORD_FIELDS = List.of('password', 'password_1', 'password_2');

const FieldChar = (
  {readOnly, config, name, errors, value, onChange, className}
) => {
  return readOnly ? (
    <div className={classNames('mb-1-2', className)}>
      <label>{config.label}:</label>
      <span className="ml-1-4">{value}</span>
    </div>
  ) : (
    <div className={classNames('mb-1-4', className)}>
      <div>
        <label>{config.label}:</label>
        {config.max_length ? (
          <input
            type={PASSWORD_FIELDS.contains(name) ? 'password' : 'text'}
            name={name}
            placeholder={config.placeholder}
            onChange={(e) => onChange(e.target.value)}
            value={value}
          />
        ) : (
          <textarea
            className="valign-top"
            type="text"
            name={name}
            placeholder={config.placeholder}
            onChange={(e) => onChange(e.target.value)}
            value={value}
          />
        )}
      </div>
      <AdminFormError errors={errors} />
    </div>
  );
};

FieldChar.propTypes = {
  readOnly: React.PropTypes.bool,
  config: React.PropTypes.object.isRequired,
  name: React.PropTypes.string.isRequired,
  errors: ImmutablePropTypes.list,
  value: React.PropTypes.any,
  onChange: React.PropTypes.func.isRequired,
  className: React.PropTypes.string,
};

export default FieldChar;
