import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';

import {
  STATUS_CLASSES,
  STATUS_NAMES,
  ACTIVITY_STATUSES,
  canEditActivity,
  COMPLIANCE_REVIEW_LIST_ORDERING
} from '^/models/activities';
import { PRIORITY_CLASSES } from '^/models/constants';
import ActivityStatusIcon from '^/components/app/activities/ActivityStatusIcon';
import UserCapsule from '^/components/app/calendar/UserCapsule';


const GenericComplianceReviewListItem = ({activity, isSelected, user}) => {
  const { id, title, status, help_text, priority, responsible_staff } = activity.toObject();
  const className = STATUS_CLASSES[status || ACTIVITY_STATUSES.NOT_STARTED];
  const to = `/page/activities/${id}/`;

  return canEditActivity(activity, user) ? (
    <li className={classNames({'sidenav-active': isSelected})}>
      <Link className={classNames('step-link', `step-${className}`)} to={to} title={help_text}>
        <div className="display-flex">
          {priority && <span className={classNames('vertical-lozenge', `${PRIORITY_CLASSES[priority]}`)} />}
          {title}
          <span>
            <UserCapsule staffDetail={responsible_staff} />
            <ActivityStatusIcon activity={activity} />
          </span>
        </div>
      </Link>
    </li>
  ) : (
    <li>
      <span className="sidenav-text inactive" title={help_text}>
        {title}
      </span>
    </li>
  );
};

export const GenericComplianceReviewList = ({selectedActivity, activities, user}) =>
  <div>
    {activities
      .sortBy(activity => activity.get('end_date'))
      .groupBy(activity => activity.get('status'))
      .toOrderedMap()
      .sortBy((activityGroup, status) => COMPLIANCE_REVIEW_LIST_ORDERING.indexOf(status))
      .map((activityGroup, status) =>
        <div key={status}>
          {status === ACTIVITY_STATUSES.COMPLETED &&
            <h5 className={STATUS_CLASSES[status]}>
              {STATUS_NAMES[status]}:
            </h5>
          }
          <ul className="list-no-indent icon-right">
            {activityGroup
              .sortBy(each => each.get('start_date'))
              .map(each =>
                <GenericComplianceReviewListItem
                  key={each.get('id')}
                  activity={each}
                  isSelected={each.get('id') === selectedActivity.get('id')}
                  user={user}
                />
              )
            }
          </ul>
        </div>
      )
    }
  </div>
;

GenericComplianceReviewList.propTypes = {
  selectedActivity: ImmutablePropTypes.map,
  activities: ImmutablePropTypes.list,
};

export default connect(state => ({user: state.userProfile})) (GenericComplianceReviewList);
