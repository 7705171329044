import React from 'react';
import Icon from '^/components/app/content/Icon';
import PureComponent from '^/components/common/PureComponent';
import { reduxForm } from 'redux-form';


const MINIMUM_SEARCH_TEXT_LENGTH = 3;


export class TemplatesSearchForm extends PureComponent {

  componentWillReceiveProps(nextProps) {
    const {fields: {searchText}} = this.props;
    if(this.props.overrideDefaultValue !== nextProps.overrideDefaultValue) {
      searchText.value = nextProps.overrideDefaultValue;
    }
  }

  render() {
    const {handleSubmit, fields: {searchText}, children, hintText} = this.props;

    return (
      <div>
        <h4 className="inline">Search documents</h4>
        {children}

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="search"
            {...searchText}
          />
          {searchText.touched && searchText.error && (
            <span className="text-error x-small-text">{searchText.error}</span>
          )}
        </form>
        <p className="help-block">
          <Icon type="lightbulb-o" className="tip-icon" />
          {hintText || 'You can search by code, folder number, module or keyword'}
        </p>
      </div>
    );
  }
}


export function validate(values) {
  const errors = {};

  if (values.searchText && values.searchText.length < MINIMUM_SEARCH_TEXT_LENGTH) {
    errors.searchText = 'Search query must be at least three characters long.';
  }

  return errors;
}

export default reduxForm({
  form: 'templateSearch',
  fields: ['searchText'],
  validate
})(TemplatesSearchForm);
