import React from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import PureComponent from '^/components/common/PureComponent';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEditCreate from '^/components/admin/content/edit-create/AdminEditCreate';
import AdminList from '^/components/admin/content/list/AdminList';
import Messages from '^/components/admin/content/edit-create/dynamic-forms/Messages';
import { SectorTypes } from '^/consts/sector';

import {
  ADMIN_CALENDAR_PREVIEWS_MODEL,
  ADMIN_CALENDAR_PREVIEWS_ROW_FIELDS,
  ADMIN_CALENDAR_PREVIEWS_TASK_CREATE_FIELDS,
  ADMIN_CALENDAR_PREVIEWS_REMINDER_CREATE_FIELDS,
  ADMIN_CALENDAR_PREVIEWS_ACTIVITY_CREATE_FIELDS,
} from '^/components/admin/calendar-previews/calendar-preview';

class CalendarPreviewsAdmin extends PureComponent {
  render() {
    const { response } = this.props;

    return (
      <div>
        <AdminContainer title="Calendar Previews">
          <AdminList
            model={ADMIN_CALENDAR_PREVIEWS_MODEL}
            fields={ADMIN_CALENDAR_PREVIEWS_ROW_FIELDS}
          />
          <Messages response={response} />
        </AdminContainer>

        <AdminContainer>
          <AdminEditCreate
            model={ADMIN_CALENDAR_PREVIEWS_MODEL}
            collectionName={ADMIN_CALENDAR_PREVIEWS_MODEL}
            controlName="ADMIN_CALENDAR_PREVIEWS_TASK_CREATE"
            fields={ADMIN_CALENDAR_PREVIEWS_TASK_CREATE_FIELDS}
            title="Create new task"
            hideMessages
          />
        </AdminContainer>

        <AdminContainer>
          <AdminEditCreate
            model={ADMIN_CALENDAR_PREVIEWS_MODEL}
            collectionName={ADMIN_CALENDAR_PREVIEWS_MODEL}
            controlName="ADMIN_CALENDAR_PREVIEWS_REMINDER_CREATE"
            fields={ADMIN_CALENDAR_PREVIEWS_REMINDER_CREATE_FIELDS}
            transform={fieldConfig => fieldConfig.setIn(['template_task', 'lookup_filter'], {sector: SectorTypes.DENTAL})}
            title="Create new reminder"
            hideMessages
          />
        </AdminContainer>

        <AdminContainer>
          <AdminEditCreate
            model={ADMIN_CALENDAR_PREVIEWS_MODEL}
            collectionName={ADMIN_CALENDAR_PREVIEWS_MODEL}
            controlName="ADMIN_CALENDAR_PREVIEWS_ACTIVITY_CREATE"
            fields={ADMIN_CALENDAR_PREVIEWS_ACTIVITY_CREATE_FIELDS}
            title="Create new activity"
            transform={fieldConfig => fieldConfig.setIn(['template_activity', 'lookup_filter'], {sector: SectorTypes.DENTAL})}
            hideMessages
          />
        </AdminContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.getIn(['createItem', ADMIN_CALENDAR_PREVIEWS_MODEL]),
  };
}

export default connect(mapStateToProps, { routePush: routeActions.push })(CalendarPreviewsAdmin);
