import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { MODELS } from '^/permissions/capabilities';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import AdminChangeLog from '^/components/admin/content/change-log/AdminChangeLog';
import AdminManyRelatedField from '^/components/admin/content/perms/AdminManyRelatedField';

import {
  ADMIN_PRACTICE_SUB_GROUP_MODEL,
  ADMIN_PRACTICE_SUB_GROUP_PRACTICE_ROW_FIELDS,
  ADMIN_PRACTICE_SUB_GROUP_EDIT,
  ADMIN_PRACTICE_SUB_GROUP_VIEW_FIELDS,
  ADMIN_PRACTICE_SUB_GROUP_EDIT_FIELDS,
} from './practice';


export const ManagePracticeSubGroup = ({practiceGroupId, practiceSubGroupId}) => {
  return (
    <div>
      <AdminContainer title="Practice Sub Group">
        <AdminEdit
          permsModel={MODELS.PRACTICE}
          readOnlyFields={ADMIN_PRACTICE_SUB_GROUP_VIEW_FIELDS}
          itemId={practiceSubGroupId}
          controlName={ADMIN_PRACTICE_SUB_GROUP_EDIT}
          collectionName={ADMIN_PRACTICE_SUB_GROUP_MODEL}
          model={ADMIN_PRACTICE_SUB_GROUP_MODEL}
          fields={ADMIN_PRACTICE_SUB_GROUP_EDIT_FIELDS}
          title="Edit Practice Sub Group"
        />
      </AdminContainer>

      <AdminContainer className="mt-2">
        <AdminManyRelatedField
          permsModel={MODELS.PRACTICE}
          itemId={practiceSubGroupId}
          controlName="PRACTICE_SUB_GROUP_PRACTICES"
          model={ADMIN_PRACTICE_SUB_GROUP_MODEL}
          fieldName={['practices']}
          fields={ADMIN_PRACTICE_SUB_GROUP_PRACTICE_ROW_FIELDS}
          listTitle="Sub Group Practices"
          sortBy={['name']}
          defaultLookupFilters={{group: practiceGroupId}}
        />
      </AdminContainer>

      <AdminContainer>
        <AdminChangeLog model="PracticeSubGroup" id={practiceSubGroupId} />
      </AdminContainer>
    </div>
  );
};

export function mapStateToProps(state, props) {
  return {
    practiceGroupId: state.responses.getIn(['loadItem', ADMIN_PRACTICE_SUB_GROUP_MODEL, 'payload', 'group']),
    practiceSubGroupId: props.params.uuid,
  };
}

export default connect(mapStateToProps)(ManagePracticeSubGroup);
