import React from 'react';
import Slik from 'slik';

import PureComponent from '^/components/common/PureComponent';
import { convertRateToPercentage } from '^/utils';

const RADIUS = 68;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

function calculatePointerPositionInDegrees(value) {
  const startPointOffsetDegrees = -90;
  const totalRotationDegrees = 180;
  return !isNaN(value) && (value.toFixed(2) * totalRotationDegrees) + startPointOffsetDegrees;
}

function calculateStrokeDashoffset(value) {
  return CIRCUMFERENCE * (1 - value / 2);
}

export default class GaugeChart extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      opacity: 0
    };

    this.setAnimationValues = (values) => {
      this.setState(values.toObject());
    };
  }

  componentDidMount() {
    const { value } = this.props;

    this.animation = new Slik.Animation({
      from: {
        value: this.state.value,
        opacity: this.state.opacity
      },
      to: {
        value,
        opacity: 1
      }
    })
      .duration(1000)
      .delay(500)
      .ease(Slik.Easing.EaseInOutSine)
      .bind('update', this.setAnimationValues)
      .start();
  }

  componentWillUnmount() {
    if (this.animation) {
      this.animation.unbind('update', this.setAnimationValues);
    }
  }

  render() {
    const { good, ok, bad } = this.props;
    const { value, opacity } = this.state;

    return (
      <div className="gauge-chart" style={{opacity}}>
        <svg
          className="gauge-progress"
          width="150px"
          height="78px"
          viewBox="0 0 150 78"
        >
          {!isNaN(this.props.value) && (
            <svg width="150px" height="150px" viewBox="0 0 150 150">
              <g
                className="gauge-value-group"
                stroke="none"
                strokeWidth="14"
                fill="none"
                fillRule="evenodd"
                transform={`rotate(${calculatePointerPositionInDegrees(
                  value
                )} 75,75)`}
              >
                <circle
                  className="gauge-progress-meter"
                  cx="75"
                  cy="75"
                  strokeWidth="14"
                  fill="none"
                  fillRule="evenodd"
                  r={RADIUS}
                />
                <path
                  stroke="null"
                  fillRule="nonzero"
                  fill="#21485B"
                  // eslint-disable-next-line max-len
                  d="m73.551,17.364l1.63,-3.364l1.418,3.509a5.5,5.5 0 0 1 0.401,2.06l0,7.431a2,2 0 1 1 -4,0l0,-7.237a5.5,5.5 0 0 1 0.551,-2.4l0,0.001z"
                />
              </g>
            </svg>
          )}

          <svg
            width="150"
            height="150"
            fill="none"
            fillRule="evenodd"
            viewBox="0 0 150 150"
          >
            <circle
              className="gauge-progress-value good"
              cx="75"
              cy="75"
              r={RADIUS}
              strokeWidth="14"
              style={{
                strokeDasharray: CIRCUMFERENCE,
                strokeDashoffset: calculateStrokeDashoffset(good)
              }}
              transform="rotate(180 75,75)"
            />

            <circle
              className="gauge-progress-value ok"
              cx="75"
              cy="75"
              r={RADIUS}
              strokeWidth="14"
              style={{
                strokeDasharray: CIRCUMFERENCE,
                strokeDashoffset: calculateStrokeDashoffset(ok)
              }}
              transform="rotate(180 75,75)"
            />

            <circle
              className="gauge-progress-value bad"
              cx="75"
              cy="75"
              r={RADIUS}
              strokeWidth="14"
              style={{
                strokeDasharray: CIRCUMFERENCE,
                strokeDashoffset: calculateStrokeDashoffset(bad)
              }}
              transform="rotate(180 75,75)"
            />
          </svg>
        </svg>

        <span className="gauge-value">
          {!isNaN(this.props.value) && (
            <span className="gauge-value-number">
              {convertRateToPercentage(Number(this.props.value))}
              <span className="value__percent">%</span>
            </span>
          )}
        </span>
      </div>
    );
  }
}
