import { stringify } from 'query-string';

const OPEN_ID_BASE_URL = window.OPEN_ID_BASE_URL
export const SITE_URL = window.SITE_URL;
export const IS_USING_SSO_AUTH_SERVICE =
  window.USE_SSO_AUTH_SERVICE &&
  JSON.parse(window.USE_SSO_AUTH_SERVICE.toLowerCase());

export const SSO_REDIRECT_BASE_URL = `${SITE_URL}/login-splash-page/`;


export const SSO_FORGOT_PASSWORD_URL = `${OPEN_ID_BASE_URL}Account/ForgotPassword?ReturnUrl=${SITE_URL}`;
// TODO - NEED THE REGISTRATION PATH
export const SSO_REGISTRATION_URL = `${OPEN_ID_BASE_URL}sso/#/register?${SITE_URL}`;

export const DEVELOPMENT_LOGIN_PATH = '/development-login/';
const DEVELOPMENT_LOGOUT_PATH = '/development-logout/';
export const OPENID_LOGIN_PATH = '/open-id-login-redirect/'
export const OPENID_LOGOUT_PATH = OPENID_LOGIN_PATH

export const SSO_SECURITY_QUESTION_PATH = `${OPEN_ID_BASE_URL}profile/ChangeSecurityQuestion?returnUrl=${SITE_URL}/page/profile/`;
export const SSO_CORRESPONDENCE_PATH = `${OPEN_ID_BASE_URL}profile/ChangeCorrespondencePreference?returnUrl=${SITE_URL}/page/profile/`;

export const LOGOUT_URL = IS_USING_SSO_AUTH_SERVICE ? OPENID_LOGOUT_PATH : DEVELOPMENT_LOGOUT_PATH;

export const getSSOAuthRedirectWithNextPath = (nextPath) => {
  const queryData = {
    nextUrl: nextPath,
  };
  return `${OPENID_LOGIN_PATH}?${stringify(queryData)}`
};

export const getUserActionLogoutUrl = (logoutAction) => {
  if(IS_USING_SSO_AUTH_SERVICE) {
    const { id_token, open_id_logout_url } = logoutAction.payload;
    if (id_token && open_id_logout_url) {
      return open_id_logout_url;
    }
  }

  return LOGOUT_URL;
};
