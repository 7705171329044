import React from 'react';
import { TemplateVersionInfo, TemplateInfo, getTemplateDocumentVersion } from '^/components/app/template-documents/DocumentEditor';


const DocumentHeader = props => {
  const { doc, showDocInfo } = props;
  const { activity } = doc.toObject();
  const isAttached = !!activity;
  const templateDocumentVersion = getTemplateDocumentVersion(doc, isAttached);

  // This is a version of an adopted, copied or a global template_document
  const adoptedDocument = templateDocumentVersion.get('adopted_template_document')
    || templateDocumentVersion.get('group_template_document');
  const isAdopted = Boolean(adoptedDocument);
  const copiedDocument = templateDocumentVersion.get('copied_template_document');
  const isCopied = Boolean(copiedDocument);
  const isGlobal = !isAdopted && !isCopied;

  const baseTemplateDocumentVersion = isGlobal
    ? templateDocumentVersion
    : (adoptedDocument || copiedDocument).get('template_document_version');

  return (
    <div>
      <TemplateVersionInfo
        baseTemplateDocumentVersion={baseTemplateDocumentVersion}
        adoptedTemplateDocumentVersion={isAdopted && templateDocumentVersion}
        attachedDocument={isAttached && props.templateDocumentVersion}
        editingGroupDocument={props.editingGroupDocument}
      />
      {showDocInfo && (
        <TemplateInfo
          baseTemplateDocumentVersion={baseTemplateDocumentVersion}
        />
      )}
    </div>
  );
};

export default DocumentHeader;
