import React from 'react';

import { HealthcheckArea, HealthcheckCategoryMerged, BaseItem } from './types';
import { CategoryOptionsHealthcheck } from './hoc/types';

export interface CategoryAreaProps<T extends BaseItem>
  extends Pick<CategoryOptionsHealthcheck<T>, 'itemRenderer'> {
  area: HealthcheckArea<T>;
  category: HealthcheckCategoryMerged<T>;
  healthcheckId: string;
  areaHeaders: Array<string>;
  disabled?: boolean;
}

const CategoryArea = <T extends BaseItem>({
  area,
  itemRenderer: ItemRenderer,
  category,
  healthcheckId,
  areaHeaders,
  disabled,
}: CategoryAreaProps<T>) => (
  <div className="underline">
    <h3>{area.name}</h3>
    <h4>
      {areaHeaders.map((header, idx) => (
        <span key={idx}>{header}</span>
      ))}
    </h4>
    {area.items.map(item => (
      <ItemRenderer
        key={item.id}
        item={item}
        areaId={area.id}
        categoryId={category.id}
        healthcheckId={healthcheckId}
        disabled={disabled}
      />
    ))}
  </div>
);

export default CategoryArea;
