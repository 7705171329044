import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';

import { QPS_FILES } from '^/consts/collectionKeys';
import { getAllCollection } from '^/actions/collections';
import { isPending } from '^/consts/responseStates';

import Loading from '^/components/app/content/Loading';
import PureComponent from '^/components/common/PureComponent';


export class QPSPage extends PureComponent {
  componentDidMount() {
    this.props.getAllCollection(QPS_FILES);
  }

  render() {
    const { qpsFiles, loading } = this.props;

    return (
      <div className="col-1 mt-2">
        <h2>QPS Members Page</h2>

        <p>You can download QPS media, including logos which you can use on your own website.</p>
        <p>Find out more about the <a
          href="https://codeuk.com/qps/"
          target="_blank" className="text-underlined"
        >QPS scheme</a></p>
        {loading && <Loading />}
        <ul className="qps-files-list">
          {qpsFiles.map(qpsFile =>
            <li key={qpsFile.get('id')}>
              <img className="qps-preview-image" src={qpsFile.get('file')} alt={qpsFile.get('title')} />
              <a className="text-underlined" href={qpsFile.get('file')}>
                {qpsFile.get('title')} (Click to download)
              </a>
              <p>{qpsFile.get('description')}</p>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  const loading = isPending(state.responses.getIn(['getCollection', QPS_FILES]));

  return {
    qpsFiles: state.collections.getIn([QPS_FILES, 'items'], List()),
    loading,
  };
}

export default connect(
  mapStateToProps,
  {
    getAllCollection,
  }
)(QPSPage);
