import React from 'react';
import PureComponent from '^/components/common/PureComponent';

export default class ContentBox extends PureComponent {
  getClassName() {
    const baseClassName = 'col-xs-12 content-box';
    if (!this.props.className) {
      return baseClassName;
    }
    return [baseClassName, this.props.className].join(' ');
  }

  render() {
    return (
      <div {...this.props} className={this.getClassName()}>
        {this.props.children}
      </div>
    );
  }
}
