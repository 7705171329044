import React from 'react';

import { downloadFromURL } from '^/actions/actionSequences';
import { openDocumentTakeOverModal } from '^/actions/modals';
import { connect } from 'react-redux';
import { MaybeDocumentEditLink } from '^/components/app/perms/DocumentEditingMayDisallow';

export const TemplateDocumentLink = (props) => {
  const {
    downloadLink, webLink, editDocumentLink, children, templateVersion, targetBlank, userId, isUploadedFile,
    userIsAdmin,
  } = props;

  if (webLink) {
    return (
      <a className="underlined" href={webLink}>{children}</a>
    );
  } else if (downloadLink) {
    // uploaded files are tracked and have urls starting /api/activities/tracked-download/
    if (isUploadedFile) {
      return (
        <a className="underlined" onClick={() => props.downloadFromURL(downloadLink.replace('/api', ''))}>
          {children}
        </a>
      );
    }
    return (
      <a className="underlined" href={downloadLink}>{children}</a>
    );
  }
    return (
      <MaybeDocumentEditLink
        className="underlined"
        to={editDocumentLink}
        templateVersion={templateVersion}
        targetBlank={targetBlank}
        userId={userId}
        userIsAdmin={userIsAdmin}
        openModal={props.openDocumentTakeOverModal}
      >
      {children}
      </MaybeDocumentEditLink>
    );

};


export default connect(undefined, { downloadFromURL, openDocumentTakeOverModal })(TemplateDocumentLink);
