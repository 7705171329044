import React from 'react';
import Icon from '^/components/app/content/Icon';


const DemoBanner = ({ onClickSignup }) => (
  <div className="nav-banner">
    <div className="wrapper">
      <div className="row">
        <div className="col-1">
          <div className="demo-banner">
            <h2>Demo Mode</h2>
            <button
              className="btn btn-save indented"
              onClick={onClickSignup}
            >
              <Icon type="check-square-o" className="button-icon" /> Sign up your practice today!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

DemoBanner.propTypes = {
  onClickSignup: React.PropTypes.func.isRequired,
};

export default DemoBanner;
