import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import classNames from 'classnames';

import PureComponent from '^/components/common/PureComponent';
import { startsWith } from '^/utils';

export class Tab extends PureComponent {

  pathMatches() {
    const { link, pathname } = this.props;
    return pathname && startsWith(pathname, link);
  }

  render() {
    const { link, children } = this.props;

    return (
      <li className="tab">
        <Link to={link} className={classNames('nav-primary-link', {active: this.pathMatches()})}>
          {children}
        </Link>
      </li>
    );
  }
}

Tab.propTypes = {
  link: React.PropTypes.string.isRequired,
};

export function mapStateToProps(state) {
  return {
    pathname: state.routing.location.pathname
  };
}

export default connect(mapStateToProps)(Tab);
