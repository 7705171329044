import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import ContentBox from '^/components/app/content/ContentBox';
import LoggedOutLogo from '^/components/app/users/LoggedOutLogo';

export default class PolicyWrapper extends PureComponent {
  render() {
    return (
      <div className="container">
        <LoggedOutLogo />
        <div className="row mt-1">
          <div className="policy-wrapper">
            <ContentBox className="content-box-padded">
              { this.props.children }
            </ContentBox>
          </div>
        </div>
      </div>
    );
  }
}
