import React from 'react';
import { MODELS } from '^/permissions/capabilities';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminList from '^/components/admin/content/perms/AdminList';
import PureComponent from '^/components/common/PureComponent';

import {
  ADMIN_QPS_FILE_MODEL,
  ADMIN_QPS_FILE_ROW_FIELDS,
  ADMIN_QPS_FILE_CREATE_FIELDS
} from '^/components/admin/qps-page/qps-page';


class QPSPageAdmin extends PureComponent {
  render() {
    return (
      <div>
        <AdminContainer title="QPS Files">
          <AdminCreate
            title="Add new file"
            permsModel={MODELS.QPS_FILE}
            collectionName={ADMIN_QPS_FILE_MODEL}
            model={ADMIN_QPS_FILE_MODEL}
            fields={ADMIN_QPS_FILE_CREATE_FIELDS}
          />
        </AdminContainer>

        <AdminContainer>
          <AdminList
            permsModel={MODELS.QPS_FILE}
            title="All QPS Files"
            model={ADMIN_QPS_FILE_MODEL}
            fields={ADMIN_QPS_FILE_ROW_FIELDS}
          />
        </AdminContainer>
      </div>
    );
  }
}

export default QPSPageAdmin;
