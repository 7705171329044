import React from 'react';

import PureComponent from '^/components/common/PureComponent';
import LiveButton from '^/components/app/forms/LiveButton';


export class DocumentToReadForm extends PureComponent {
  handleCheckBox(event) {
    const checked = event.target.checked;
    this.props.toggleMarkAsReadButton(checked);
  }

  render() {
    const {
      response,
      onClose,
      markDocAsRead,
      scrollIsAtBottom,
      canMarkAsRead,
    } = this.props;

    return (
      <div className="mt-1">
        <div className="form-group">
          <div className="col-1">
            <label className="pull-left">
              <input 
                type="checkbox"
                disabled={!scrollIsAtBottom}
                onChange={(event) => this.handleCheckBox(event)}
              />
              I confirm I have read and understood the contents of this document
            </label>
          </div>
        </div>
        <div className="col-1 pb-2">
          <LiveButton
            onClick={onClose}
            className="btn btn-default pull-left"
          >
            Cancel
          </LiveButton>
          <LiveButton
            pendingMessage={"Confirming..."}
            response={response}
            className="btn btn-warning pull-right"
            disabled={!canMarkAsRead}
            onClick={markDocAsRead}
          >
            Mark as read
          </LiveButton>
        </div>
      </div>
    );
  }
}

DocumentToReadForm.propTypes = {
  canMarkAsRead: React.PropTypes.bool,
  scrollIsAtBottom: React.PropTypes.bool,
  onClose: React.PropTypes.func,
  toggleMarkAsReadButton: React.PropTypes.func,
};

export default DocumentToReadForm;
