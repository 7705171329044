import classNames from 'classnames';
import React from 'react';

import Icon from '^/components/app/content/Icon';

interface AppConfig {
  icon: string;
  url: string;
  readmore: string;
}

type Apps = 'iPlan' | 'iLearn' | 'iService' | 'iTeam' | 'iShop';

type Config = {
  icon: string;
  agilio: {
    icon: string;
  };
} & {
  [app in Apps]: AppConfig;
};

interface OwnProps {
  config: Config;
}

export type AppSwitcherProps = OwnProps;

interface OwnState {
  isOpen?: boolean;
}

class AppSwitcher extends React.Component<OwnProps, OwnState> {
  constructor(props: OwnProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  public displaySubMenu() {
    const { config } = this.props;

    return (
      <div>
        <div
          className="app-switcher-modal-overlay"
          onClick={this.onToggleVisibility}
        />
        <div className="app-switcher-submenu">
          <div className="app-switcher-submenu-items">
            <div className="app-switcher-submenu-item-header">
              <div className="app-switcher-submenu-item-header-logo">
                <img src={config.agilio.icon} alt="agilio logo" />
              </div>
              <div className="app-switcher-submenu-item-text">Agilio Apps</div>
            </div>
            <div className="app-switcher-submenu-item">
              <a
                target="_blank"
                href={config.iTeam.url}
                className="app-switcher-submenu-item-primary"
              >
                <div className="app-switcher-submenu-item-logo">
                  <img src={config.iTeam.icon} alt="iTeam logo" />
                </div>
                <div className="app-switcher-submenu-item-text">
                  Your virtual HR department
                </div>
              </a>
              <a
                target="_blank"
                href={config.iTeam.readmore}
                className="app-switcher-submenu-item-learnmore"
              >
                <Icon type="question-circle" width="24" height="auto" />
                <div className="app-switcher-submenu-item-learnmore-text">
                  Learn more
                </div>
              </a>
            </div>
            <div className="app-switcher-submenu-item">
              <a
                target="_blank"
                href={config.iService.url}
                className="app-switcher-submenu-item-primary"
              >
                <div className="app-switcher-submenu-item-logo">
                  <img src={config.iService.icon} alt="iService logo" />
                </div>
                <div className="app-switcher-submenu-item-text">
                  Manage your dental assets with ease
                </div>
              </a>
              <a
                target="_blank"
                href={config.iService.readmore}
                className="app-switcher-submenu-item-learnmore"
              >
                <Icon type="question-circle" width="24" height="auto" />
                <div className="app-switcher-submenu-item-learnmore-text">
                  Learn more
                </div>
              </a>
            </div>
            <div className="app-switcher-submenu-item">
              <a
                target="_blank"
                href={config.iLearn.url}
                className="app-switcher-submenu-item-primary"
              >
                <div className="app-switcher-submenu-item-logo">
                  <img src={config.iLearn.icon} alt="iLearn logo" />
                </div>
                <div className="app-switcher-submenu-item-text">
                  All your CPD in one place
                </div>
              </a>
              <a
                target="_blank"
                href={config.iLearn.readmore}
                className="app-switcher-submenu-item-learnmore"
              >
                <Icon type="question-circle" width="24" height="auto" />
                <div className="app-switcher-submenu-item-learnmore-text">
                  Learn more
                </div>
              </a>
            </div>
            <div className="app-switcher-submenu-item">
              <a
                target="_blank"
                href={config.iPlan.url}
                className="app-switcher-submenu-item-primary"
              >
                <div className="app-switcher-submenu-item-logo">
                  <img src={config.iPlan.icon} alt="iPlan logo" />
                </div>
                <div className="app-switcher-submenu-item-text">
                  Dental plans with a difference
                </div>
              </a>
              <a
                target="_blank"
                href={config.iPlan.readmore}
                className="app-switcher-submenu-item-learnmore"
              >
                <Icon type="question-circle" width="24" height="auto" />
                <div className="app-switcher-submenu-item-learnmore-text">
                  Learn more
                </div>
              </a>
            </div>
            <div className="app-switcher-submenu-item">
              <a
                target="_blank"
                href={config.iShop.url}
                className="app-switcher-submenu-item-primary"
              >
                <div className="app-switcher-submenu-item-logo">
                  <img src={config.iShop.icon} alt="iShop logo" />
                </div>
                <div className="app-switcher-submenu-item-text">
                  For your compliance logbooks and consumables
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private onToggleVisibility = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  public render() {
    const { isOpen } = this.state;
    const { config } = this.props;

    return (
      <div
        className={classNames('app-switcher-container pull-left', {
          'is-open': isOpen,
        })}
      >
        <button
          className="app-switcher-button"
          onClick={this.onToggleVisibility}
        >
          <img
            className="app-switcher-button-icon"
            src={config.icon}
            alt="icon for switching between apps"
          />
        </button>

        {this.displaySubMenu()}
      </div>
    );
  }
}

export default AppSwitcher;
