import React, { Component } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';

import { CurrentPractice } from '^/types/practices';
import {
  selectNonImmutableCurrentPractice,
  selecthasCreateHealthcheckPermissions,
  selecthasContinueHealthcheckPermissions,
} from '^/selectors/healthcheck';
import {
  getCurrentPracticeHealthchecks,
  startContinueOrViewHealthcheck,
} from '^/actions/healthcheck';
import { StoreState } from '^/store/types';
import Loading from '^/components/app/content/Loading';
import SummaryTable from '^/components/app/healthcheck/summary-table';
import { HealthcheckListSummary, HealthcheckStatus } from './types';

interface StateProps {
  currentPractice: CurrentPractice | null;
  currentPracticeHealthchecks: HealthcheckListSummary[];
  userProfile: Map<string, unknown> | null;
  isLoading: boolean;
  hasCreateHealthcheckPermissions: boolean;
  hasContinueHealthcheckPermissions: boolean;
}

interface DispatchProps {
  getCurrentPracticeHealthchecks: typeof getCurrentPracticeHealthchecks;
  startContinueOrViewHealthcheck: typeof startContinueOrViewHealthcheck;
}

export type PracticeHealthcheckListProps = DispatchProps & StateProps;

export class PracticeHealthcheckList extends Component<
  PracticeHealthcheckListProps,
  {}
> {
  public componentWillMount() {
    this.props.getCurrentPracticeHealthchecks();
  }

  render() {
    const { currentPracticeHealthchecks, isLoading } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div className="healthcheck">
        <h1>{this.props.currentPractice?.name}:</h1>
        <h2 className="underline">Compliance healthcheck</h2>
        <p>
          You can only continue a healthcheck if you were the user who started
          it.
        </p>
        <p>
          If this is not you, please contact the user who started the
          healthcheck or contact the iComply team.
        </p>
        <SummaryTable summaryList={currentPracticeHealthchecks} />

        {this.shouldRenderStartHealthcheckButton() && (
          <div>
            <button
              onClick={this.onStartOrContinueHealthcheck}
              className="btn btn-save m-1"
            >
              Start Healthcheck
            </button>
          </div>
        )}
      </div>
    );
  }

  private shouldRenderStartHealthcheckButton = () =>
    this.props.hasCreateHealthcheckPermissions &&
    this.props.currentPracticeHealthchecks.filter(
      healthcheck => HealthcheckStatus.COMPLETED !== healthcheck.status
    ).length === 0;

  private onStartOrContinueHealthcheck = () =>
    this.props.startContinueOrViewHealthcheck();
}

const mapStateToProps = (state: StoreState): StateProps => ({
  currentPractice: selectNonImmutableCurrentPractice(state),
  currentPracticeHealthchecks: state.healthcheck.currentPracticeHealthchecks,
  userProfile: state.userProfile,
  isLoading: state.thunkRequests.getCurrentPracticeHealthchecks.isLoading,
  hasCreateHealthcheckPermissions: selecthasCreateHealthcheckPermissions(state),
  hasContinueHealthcheckPermissions: selecthasContinueHealthcheckPermissions(
    state
  ),
});

export default connect<StateProps, DispatchProps, {}>(mapStateToProps, {
  getCurrentPracticeHealthchecks,
  startContinueOrViewHealthcheck,
})(PracticeHealthcheckList);
