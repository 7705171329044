import withLastUpdatedTimestampData from './withLastUpdatedTimestampData';
import withLoadLastUpdatedTimestampOnMount from './withLoadLastUpdatedTimestampOnMount';


const withLastUpdatedTimestamp = (modelName) =>
  (Component) =>
    withLoadLastUpdatedTimestampOnMount(
      withLastUpdatedTimestampData(modelName)(Component)
    );

export default withLastUpdatedTimestamp;
