import React from "react";
import { connect } from "react-redux";
import { Map, List } from "immutable";

import { getCollection } from "^/actions/collections";
import { setFilter, clearFilter, clearFilters } from "^/actions/actions";
import { MY_PRACTICES_FILTER, PRACTICE_FILTER, COUNTY_FILTER } from '^/models/practice';
import Loading from "^/components/app/content/Loading";
import PureComponent from "^/components/common/PureComponent";
import PracticesFilterDropdown, { DEFAULT_SELECTION_VALUE } from "^/components/app/users/profile/my-practices/PracticesFilterDropdown";
import { MY_PRACTICES, PRACTICES_DETAILS } from "^/consts/collectionKeys";

export class PracticesFilter extends PureComponent {
  componentDidMount() {
    this.props.getCollection(MY_PRACTICES, {
      pageSize: 100,
    });
  }

  componentWillUnmount() {
    this.props.clearFilters(MY_PRACTICES_FILTER);
  }

  addFilter(collection, val) {
    if (val === DEFAULT_SELECTION_VALUE) {
      this.props.clearFilter(MY_PRACTICES_FILTER, collection);
    } else {
      this.props.setFilter(MY_PRACTICES_FILTER, collection, val);
    }
  }

  setCountyFilter(county) {
    this.props.clearFilter(MY_PRACTICES_FILTER, PRACTICE_FILTER);
    this.addFilter(COUNTY_FILTER, county);
    this.props.getCollection(
      PRACTICES_DETAILS,
      county !== DEFAULT_SELECTION_VALUE ? { additionalParams: { county } } : undefined
    );
  }

  setPracticeFilter(id) {
    const { countyFilter } = this.props;
    const additionalParams = {
      id: id !== DEFAULT_SELECTION_VALUE ? id : undefined, county: countyFilter
    };

    this.addFilter(PRACTICE_FILTER, id);
    this.props.getCollection(PRACTICES_DETAILS, { additionalParams });
  }

  render() {
    const { practicesWithCounties, countyFilter, practiceFilter } = this.props;

    if (!practicesWithCounties) {
      return <Loading />;
    }

    const counties = practicesWithCounties
      .map(practice => practice.get("county"))
      .toSet()
      .toList()
      .map(county => Map({ value: county, display: county }));

    const isInCounty = practice => practice.get("county") === countyFilter;
    const filteredPractices = countyFilter
      ? practicesWithCounties.filter(isInCounty)
      : practicesWithCounties;

      const practiceChoices = filteredPractices.map(practice =>
      Map({ value: practice.get("id"), display: practice.get("name") })
    );

    return (
      <div>
        {counties.count() > 1 && (
          <PracticesFilterDropdown
            className="mr-1"
            defaultDisplay="region"
            onChange={(val) => this.setCountyFilter(val)}
            value={countyFilter}
            choices={counties}
          />
        )}
        {practiceChoices.count() > 1 && (
          <PracticesFilterDropdown
            defaultDisplay="practice"
            onChange={(val) => this.setPracticeFilter(val)}
            value={practiceFilter}
            choices={practiceChoices}
          />
        )}
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    countyFilter: state.filterSpec.getIn([MY_PRACTICES_FILTER, COUNTY_FILTER]),
    practiceFilter: state.filterSpec.getIn([MY_PRACTICES_FILTER, PRACTICE_FILTER]),
    practicesWithCounties: state.collections.getIn(
      [MY_PRACTICES, "items"],
      List()
    ),
  };
}

export default connect(mapStateToProps, {
  setFilter,
  clearFilter,
  clearFilters,
  getCollection,
})(PracticesFilter);
