import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

const Memberships = ({practices}) => {
  const memberPractices = practices
    .filter(practice => practice.get('base_membership') !== 'NONE')
    .map(practice => practice.toObject());

  return (
    memberPractices && memberPractices.size ? (
      <div className="inline memberships">
        <h4>Membership(s)</h4>
        <div>
          {memberPractices.map(({base_membership, membership, id}) =>
            <span className="membership" key={id}>
              {base_membership}{membership !== 'NONE' ? `: ${membership}` : ''}<br />
            </span>
          )}
        </div>
      </div>
    ) : (
      <noscript />
    )
  );
};


Memberships.propTypes = {
  practices: ImmutablePropTypes.list,
};

export default Memberships;
