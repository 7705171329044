import React from 'react';
import { connect } from 'react-redux';
import Breadcrumbs from 'react-breadcrumbs';

export const Main = ({routes, params, path, children}) =>
  <div className="col-3-4 mb-2">
    <Breadcrumbs
      customClass="breadcrumbs mt-1 mb-1"
      routes={routes}
      params={params}
      key={path}
      excludes={['App']}
    />
    {children}
  </div>;

export const mapStateToProps = (state) => ({
  path: state.routing.location.pathname || '',
});

export default connect(mapStateToProps, null)(Main);
