import { fromJS } from 'immutable';

export const ADMIN_CALENDAR_PREVIEWS_MODEL = 'admin/previews';

export const ADMIN_CALENDAR_PREVIEWS_ROW_FIELDS = fromJS([
  {
    name: ['type_display'],
    display_name: 'Type',
    type: 'string',
    sortable: true,
  },
  {
    name: ['day_of_month'],
    display_name: 'Day of month',
    type: 'string',
    sortable: true,
  },
  { name: ['title'], display_name: 'Title', type: 'string', sortable: true },
  { name: ['id'], display_name: 'Remove', type: 'remove' },
]);

export const ADMIN_CALENDAR_PREVIEWS_TASK_CREATE_FIELDS = fromJS([
  'day_of_month',
  'title',
]);
export const ADMIN_CALENDAR_PREVIEWS_REMINDER_CREATE_FIELDS = fromJS([
  'day_of_month',
  'template_task',
]);
export const ADMIN_CALENDAR_PREVIEWS_ACTIVITY_CREATE_FIELDS = fromJS([
  'day_of_month',
  'template_activity',
]);
