import React from 'react';
import { reduxForm } from 'redux-form';

import {
  PRACTICE_DETAILS_DISPLAY_SECTION_1,
  PRACTICE_DETAILS_DISPLAY_SECTION_2,
  PRACTICE_DETAILS_DISPLAY_SECTION_3,
  PRACTICE_DETAILS_DISPLAY_SECTION_4_REQUIRED_DENTAL_SECTOR_ONLY,
  BUSINESS_DAY_CHOICES,
} from '^/models/practice';


import PictureUpload from '^/components/app/forms/PictureUpload';
import Img from '^/components/app/content/Img';
import PureComponent from '^/components/common/PureComponent';
import LiveButton from '^/components/app/forms/LiveButton';
import TextField from '^/components/app/widgets/TextField';
import FormError from '^/components/app/forms/FormError';
import CheckboxGroup from '^/components/app/widgets/CheckboxGroup';
import { SectorTypes } from '^/consts/sector';

const EDIT_PRACTICE_FORM = 'EDIT_PRACTICE_FORM';


export class EditPracticeForm extends PureComponent {

  render() {
    const {
      fields: { name, logo, business_days },
      fields,
      handleSubmit,
      response,
      setPendingUploadInForm,
      practice,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <FormError response={response} />

        <TextField field={name} display="Name" response={response} required />

        <hr className="thin" />

        {PRACTICE_DETAILS_DISPLAY_SECTION_1.map((display, fieldName) =>
          <TextField key={fieldName} field={fields[fieldName]} display={display} response={response} />
        )}

        <hr className="thin" />

        {PRACTICE_DETAILS_DISPLAY_SECTION_2.map((display, fieldName) =>
          <TextField key={fieldName} field={fields[fieldName]} display={display} response={response} />
        )}

        <CheckboxGroup field={business_days} display={"Business Days"} response={response} options={BUSINESS_DAY_CHOICES} />

        { business_days.value !== business_days.initialValue && <div className="p-1 mb-1 alert alert-warning">Editing business days will update all your pending daily tasks.</div>}

          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="logo">Logo</label>
            </div>
            <div className="col-2-3">
              { logo.defaultValue ? (
                <div>
                  <Img cover className="image-fit" src={logo.defaultValue} />
                  <p>To change your logo, please contact iComply.</p>
                </div>
              ) : (
                <PictureUpload
                  id="logo" src={logo.value && (logo.value.preview || logo.value)}
                  display={src => src && <Img cover className="image-fit" src={src} />}
                  setPendingUpload={(file) => setPendingUploadInForm(EDIT_PRACTICE_FORM, 'logo', file)}
                  onSubmit={handleSubmit}
                />
              )}
              <FormError response={response} formKey="logo" />
            </div>
          </div>

        <hr className="thin" />

        {PRACTICE_DETAILS_DISPLAY_SECTION_3.map((display, fieldName) =>
          <TextField key={fieldName} field={fields[fieldName]} display={display} response={response} />
        )}

        <hr className="thin" />

        {practice.get('sector') === SectorTypes.DENTAL &&
          PRACTICE_DETAILS_DISPLAY_SECTION_4_REQUIRED_DENTAL_SECTOR_ONLY.map(
            (display, fieldName) => (
              <TextField
                key={fieldName}
                field={fields[fieldName]}
                display={display}
                response={response}
                required
              />
            )
          )}

        <div className="modal-footer text-right">
          <LiveButton pendingMessage="Saving practice..." response={response}>
            Save
          </LiveButton>
        </div>

      </form>
    );
  }
}

export const FORM_FIELDS = PRACTICE_DETAILS_DISPLAY_SECTION_1.keySeq()
  .concat(PRACTICE_DETAILS_DISPLAY_SECTION_2.keySeq())
  .concat(PRACTICE_DETAILS_DISPLAY_SECTION_3.keySeq())
  .toArray()
  .concat([
    'name',
    'logo',
    'business_days',
    'practices_in_group',
    'treatment_rooms',
  ]);

export default reduxForm({
  form: EDIT_PRACTICE_FORM,
  fields: FORM_FIELDS
})(EditPracticeForm);
