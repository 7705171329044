import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import { loadItem } from '^/actions/items';
import { getAllCollection } from '^/actions/collections';
import { CYCLES, PRACTICES } from '^/consts/collectionKeys';
import { getUserInfo } from '^/actions/actions';

import PureComponent from '^/components/common/PureComponent';
import AccountSetupHeader from '^/components/app/account-set-up/AccountSetupHeader';
import { AccountSetUpNav } from '^/components/app/account-set-up/AccountSetUpPage';
import { getUserAccountStagesToComplete, CYCLE_SETUP_STAGES, getUserAccountStages } from '^/consts/setup';
import Loading from '^/components/app/content/Loading';
import Icon from '^/components/app/content/Icon';

const SetupCompleteSection = ({title, children, link, icon}) =>
  <div>
    <div className="row">
      <div className="col-3-4">
        <Icon type={icon} className="pull-left text-icon mr-1" />
        <div className="pl-4">
          <h3>{title}</h3>
          <p>{children}</p>
        </div>
      </div>
      <div className="col-1-4">
        <Link className="btn btn-default mt-1" to={link}>
          Go &gt;
        </Link>
      </div>
    </div>
    <hr className="thin" />
  </div>;

export class SetupCompletePage extends PureComponent {

  componentWillMount() {
    const { cycleId } = this.props;
    this.loadPracticesAndCycle(cycleId);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.cycleId !== newProps.cycleId) {
      this.loadPracticesAndCycle(newProps.cycleId);
    }
  }

  loadPracticesAndCycle(cycleId) {
    if (cycleId) {
      this.props.loadItem(CYCLES, cycleId);
    }
    this.props.getUserInfo();
    this.props.getAllCollection(PRACTICES);
  }

  render() {

    const { user, practice, cycle, setupStagesToComplete, allSetupStages } = this.props;

    if (!user || !practice) {
      return <Loading />;
    }

    return (
      <div className="mt-2">

        <AccountSetUpNav className="col-1-3" currentStage={CYCLE_SETUP_STAGES.COMPLETED} setupStagesToComplete={setupStagesToComplete} allSetupStages={allSetupStages}  />

        <div className="col-2-3 bordered-left">

          <AccountSetupHeader user={user} practice={practice}>
            Setup - cycle setup complete
          </AccountSetupHeader>

          <p className="mt-1 mb-2">Your cycle setup is complete. Where do you want to go next?</p>

          <SetupCompleteSection
            icon="calendar"
            link="/page/calendar/"
            title="Explore the calendar"
          >
            See where all your meetings, activities and audits are scheduled and make edits.
          </SetupCompleteSection>

          <SetupCompleteSection
            icon="dashboard"
            link="/page/dashboard/"
            title="See your dashboard"
          >
            See what tasks are due and outstanding and check your progress.
          </SetupCompleteSection>

          {cycle && cycle.get('next_admin_step_id') && (
            <SetupCompleteSection
              icon="list"
              link={`/page/activities/${cycle.get('next_admin_step_id')}/`}
              title="Your first Monthly Focus"
            >
              Keen to get started right away?
              Have a look at your first Monthly Focus and see what is assigned to you and what you need to do.
            </SetupCompleteSection>
          )}
        </div>
      </div>
    );
  }

}

export const mapStateToProps = (state, props) => {
  const practice = state.currentPractice;
  return {
    user: state.userProfile,
    cycleId: props.params.uuid,
    practice: practice,
    cycle: state.items.get(CYCLES),
    setupStagesToComplete: getUserAccountStagesToComplete(practice),
    allSetupStages: getUserAccountStages(practice),
  };
};

export default connect(mapStateToProps,
  { loadItem, getUserInfo, getAllCollection, }
) (SetupCompletePage);
