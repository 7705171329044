import React from 'react';


export default function ExportModalContent({ model, url, valid }) {
  return (
    <div>
      <p>Your export of { model } is ready.</p>
      <p>Please note: The link is only valid for the next { valid } minutes!</p>
      <a href={url} className="btn btn-primary fullwidth">Download Now</a>
    </div>
  );
}
