import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import { reduxForm } from 'redux-form';
import moment from 'moment';
import DateInput from '^/components/app/forms/DateInput';
import FormError from '^/components/app/forms/FormError';
import ImmutablePropTypes from 'react-immutable-proptypes';
import CheckBoxBulkDocRead from '^/components/app/widgets/CheckBoxBulkDocRead';
import CheckBoxBulkDocMembers from '^/components/app/widgets/CheckBoxBulkDocMembers';
import LiveButton from '^/components/app/forms/LiveButton';

export const CREATE_BULK_DOC_READ_FORM = 'CREATE_BULK_DOC_READ_FORM';
const ONE_MONTH_FROM_TODAY = moment().add(1, 'M');

export class CreateBulkDocReadForm extends PureComponent {
  constructor(props) {
    super();

    this.state = {
      previousDocList: props.bulkDocuments
        .map(document => {
          return {
            label: document.getIn(['document_name']),
            code: document.getIn(['document_code']),
            number: document.getIn(['document_version_number']),
            value: document.getIn(['id']),
          };
        })
        .toJS(),
      previousMemList: props.allActivePracticeMembers
        .map(member => {
          return {
            label: member.getIn(['user', 'staffdetail', 'full_name']),
            role: member.getIn(['user', 'staffdetail',  'roles' , '0' , 'name']),
            value: member.get('id'),
          };
        })
        .toJS(),
    };
  }
  render() {
    const {
      handleSubmit,
      allActivePracticeMembers,
      fields,
      response,
      bulkDocuments,
      hasSucceeded,
    } = this.props;

    const {
      staff_practices,
      due_date,
      message,
      document_versions_to_read,
    } = fields;
    const handleDocumentSearch = event => {
      const docList = bulkDocuments
        .map(document => {
          return {
            label: document.getIn(['document_name']),
            code: document.getIn(['document_code']),
            number: document.getIn(['document_version_number']),
            value: document.getIn(['id']),
          };
        })
        .toJS();
      const BULK_DOCUMENT_OPTIONS_SEARCH = docList.filter(document =>
        document.label.toLowerCase().includes(event.target.value.toLowerCase())
      );
      this.setState({
        previousDocList: BULK_DOCUMENT_OPTIONS_SEARCH,
      });
    };

    const handleNameSearch = event => {
      const memList = allActivePracticeMembers
        .map(member => {
          return {
            label: member.getIn(['user', 'staffdetail', 'full_name']),
            role: member.getIn(['user', 'staffdetail',  'roles' , '0' , 'name']),
            value: member.get('id'),
          };
        })
        .toJS();
      const ALL_PRACTICE_MEMBERS_OPTIONS = memList.filter(member =>
        member.label.toLowerCase().includes(event.target.value.toLowerCase())
      );
      this.setState({
        previousMemList: ALL_PRACTICE_MEMBERS_OPTIONS,
      });
    };

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="col-1 mb-1">
              <label className="bold-text">Step 1: Select a Document(s):</label>
              <input
                className="pull-right"
                key="name-search-bar"
                placeholder={'search documents'}
                onChange={handleDocumentSearch}
              />
            </div>
            <CheckBoxBulkDocRead
              field={document_versions_to_read}
              response={response}
              options={this.state.previousDocList}
            />
          </div>
          <div className="form-group">
            <div className="col-1 mb-1">
              <label className="bold-text">
                Step 2: Select Team Member(s):
              </label>
              <input
                className="pull-right"
                key="doc-search-bar"
                placeholder={'search members'}
                onChange={handleNameSearch}
              />
            </div>
            <CheckBoxBulkDocMembers
              field={staff_practices}
              response={response}
              options={this.state.previousMemList}
            />
          </div>
          <div className="form-group mb-1">
            <div className="col-1">
              <label className="bold-text">Step 3: Set due Date:</label>
            </div>
            <div className="col-1">
              <DateInput
                id="due_date"
                value={ONE_MONTH_FROM_TODAY}
                {...due_date}
              />
              <FormError response={response} formKey={'due_date'} />
              <FormError
                error={due_date && due_date.touched && due_date.error}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="col-1">
              <label className="bold-text"> Step 4: Send a Message:</label>
            </div>
            <textarea id="message" value={message.value || ''} {...message} />
          </div>
          {!hasSucceeded && (
            <LiveButton
              pendingMessage={'Saving...'}
              response={response}
              className="btn-default pull-right"
            >
              Save
            </LiveButton>
          )}
        </form>
      </div>
    );
  }
}

export function validate(values) {
  const { staff_practices, document_versions_to_read } = values;
  const errors = {};

  if (!staff_practices) {
    errors.staff_practices = 'Please select a staff';
  }
  if (!document_versions_to_read) {
    errors.document_versions_to_read = 'Please select a docuemnt.';
  }
  return errors;
}

CreateBulkDocReadForm.propTypes = {
  allActivePracticeMembers: ImmutablePropTypes.list,
  onClose: React.PropTypes.func,
};
export default reduxForm({
  form: CREATE_BULK_DOC_READ_FORM,
  fields: [
    'staff_practices',
    'due_date',
    'message',
    'document_versions_to_read',
  ],
})(CreateBulkDocReadForm);
