import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { List } from 'immutable';

import { resetResponse, setPendingUploadInForm } from '^/actions/actions';
import FieldTinyMCE from '^/components/admin/content/edit-create/dynamic-forms/field-types/FieldTinyMCE';
import FileUpload from '^/components/app/forms/FileUpload';
import PureComponent from '^/components/common/PureComponent';
import FormError from '^/components/app/forms/FormError';
import LiveButton, { DefaultErrorMessage } from '^/components/app/forms/LiveButton';
import {
  GROUP_TEMPLATE_DOCUMENT_DRAFTS,
  GROUP_CREATED_TEMPLATE_DOCUMENTS
} from '^/consts/collectionKeys';
import { isPending, hasFailed } from '^/consts/responseStates';
import { KINDS, KINDS_DISPLAY, TYPES, TYPES_DISPLAY, DOCUMENT_PRIORITY_OPTIONS } from '^/models/documents';
import { selectCurrentPracticeSector } from '^/selectors/practice';

const FORM_NAME = 'GroupTemplateDocumentForm';

export const FormGroupInput = ({field, fieldName, label, type, response, required}) => {
  return (
    <div className="form-group form-group-input">
      <div className="col-1-3">
        <label htmlFor={fieldName}>{label}:</label>
      </div>
      <div className="col-2-3">
        <input
          {...field}
          id={fieldName}
          type={type}
          placeholder={label}
          required={required}
        />
        <FormError formKey={fieldName} response={response} />
      </div>
    </div>
  )
};

export const FormGroupSelect = ({
  field,
  fieldName,
  label,
  options=List(),
  response,
  disabled,
}) => {
  return (
    <div className="form-group form-group-select">
      <div className="col-1-3">
        <label htmlFor={fieldName}>{label}:</label>
      </div>
      <div className="col-2-3">
        <select id={fieldName} disabled={disabled} {...field} required defaultValue="">
          <option value="" key="0" disabled>Please select</option>
          {options.map(option =>
            <option value={option.get('id')} key={option.get('id')}>{option.get('name')}</option>
          )}
        </select>
        <FormError formKey={fieldName} response={response} />
      </div>
    </div>
  )
};

export class GroupTemplateDocumentForm extends PureComponent {

  constructor(props) {
    super(props);
    this.setContentFileValueToFile = this.setContentFileValueToFile.bind(this);
    this.handleSubmitDraft = this.handleSubmitDraft.bind(this);
    this.handleKindChange = this.handleKindChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fields.type.value !== this.props.fields.type.value) {
      this.props.resetResponse('createItem');
    }
  }

  handleKindChange(event) {
    const { kind, enable_doc_reads } = this.props.fields;
    const isOverviewOrPolicy = event.target.value === KINDS.OVERVIEW || event.target.value === KINDS.POLICY;

    enable_doc_reads.onChange(isOverviewOrPolicy);
    enable_doc_reads.disabled = isOverviewOrPolicy;

    kind.onChange(event);
  }

  render() {
    const {
      categories,
      folders,
      draftsResponse,
      createGroupDocResponse,
      hasFailedCreateOrUpdate,
      isDisabledButtons,
      isEditingDraft,
      contentError,
      practiceSector
    } = this.props;
    const {
      template_category,
      code,
      folder,
      is_landscape,
      kind,
      name,
      type,
      content,
      content_file,
      priority,
      enable_doc_reads,
    } = this.props.fields;

    return (
      <form className="pv-large clearfix" onSubmit={this.props.handleSubmit}>
        <FormGroupInput
          field={name}
          fieldName="name"
          label="Name"
          type="text"
          response={draftsResponse}
          required
        />
        <FormGroupSelect
          field={type}
          fieldName="type"
          label="Type"
          options={TYPES_DISPLAY}
          response={draftsResponse}
          disabled={isEditingDraft}
        />
        <FormGroupSelect
          field={kind}
          fieldName="kind"
          label="Kind"
          options={KINDS_DISPLAY}
          response={draftsResponse}
          onChange={(e) => this.handleKindChange(e)}
        />
        <FormGroupInput
          field={enable_doc_reads}
          fieldName="enable_doc_reads"
          label="Enable 'Send to team'"
          type="checkbox"
          response={draftsResponse}
        />
        <FormGroupInput
          field={code}
          fieldName="code"
          label="Code"
          type="text"
          response={draftsResponse}
          required
        />
        <FormGroupSelect
          field={folder}
          fieldName="folder"
          label="Folder"
          options={folders}
          response={draftsResponse}
        />
        <FormGroupSelect
          field={template_category}
          fieldName="template_category"
          label="Document category"
          options={categories}
          response={draftsResponse}
        />
        <FormGroupSelect
          field={priority}
          fieldName="priority"
          label="Priority"
          options={DOCUMENT_PRIORITY_OPTIONS}
        />
        {type.value === TYPES.FILE && (
          <div className="form-group form-group-input">
            <div className="col-1-3">
              <label htmlFor="content_file">Content file:</label>
            </div>
            <div className="col-2-3">
              <FileUpload
                setPendingUpload={this.setContentFileValueToFile}
                onSubmit={this.props.handleSubmit}
                src={content_file.value}
                display={src =>
                  src && <a href={src.preview || src} target="_blank">{src.name || 'Download'}</a>
                }
              />
            </div>
          </div>
        )}
        {type.value === TYPES.HTML && (
          <FormGroupInput
            field={is_landscape}
            fieldName="is_landscape"
            label="Is landscape"
            type="checkbox"
            response={draftsResponse}
          />
        )}
        <div className="col-1 pb-1 pt-1">
          <div className="clearfix">
            {type.value === TYPES.HTML && (
              <LiveButton
                className="btn btn-default pull-left mr-1"
                onClick={this.handleSubmitDraft}
                response={draftsResponse}
                disabled={isDisabledButtons}
                hideDefaultErrorMessage
              >
                Save as draft
              </LiveButton>
            )}
            <LiveButton
              className="btn btn-primary pull-left"
              response={createGroupDocResponse}
              disabled={isDisabledButtons}
              hideDefaultErrorMessage
            >
              Publish as new version
            </LiveButton>
          </div>
          {hasFailedCreateOrUpdate && <DefaultErrorMessage /> }
          {contentError && (
            <div className="mt-1">
              <FormError error={contentError} />
            </div>
          )}
        </div>
        {type.value === TYPES.HTML && (
          <FieldTinyMCE
            {...content}
            config={{isLandscape: is_landscape.value, includeVariables: true, variablesSector: practiceSector }}
            className="col-1 pt-1"
          />
        )}
      </form>
    );
  }

  handleSubmitDraft(event) {
    event.preventDefault();
    this.props.submitDraft(this.props.values);
  }

  setContentFileValueToFile(file) {
    this.props.setPendingUploadInForm(FORM_NAME, 'content_file', file);
  }
}

const FormifiedGroupTemplateDocumentForm = reduxForm({
  form: FORM_NAME,
  fields: [
    'code',
    'folder',
    'is_landscape',
    'kind',
    'name',
    'template_category',
    'type',
    'content',
    'content_file',
    'priority',
    'enable_doc_reads',
  ],
})(GroupTemplateDocumentForm);

export const mapStateToProps = (state) => {
  const createGroupDocResponse = state.responses.getIn(['createItem', GROUP_CREATED_TEMPLATE_DOCUMENTS]);
  const createDraftResponse = state.responses.getIn(['createItem', GROUP_TEMPLATE_DOCUMENT_DRAFTS]);
  const updateDraftResponse = state.responses.getIn(['updateItem', GROUP_TEMPLATE_DOCUMENT_DRAFTS]);
  return {
    isDisabledButtons: isPending(createGroupDocResponse) ||
      isPending(createDraftResponse) ||
      isPending(updateDraftResponse),
    hasFailedCreateOrUpdate: hasFailed(createGroupDocResponse) ||
      hasFailed(createDraftResponse) ||
      hasFailed(updateDraftResponse),
    draftsResponse: createDraftResponse || updateDraftResponse,
    createGroupDocResponse,
    contentError: createGroupDocResponse && createGroupDocResponse.get('errors'),
    practiceSector: selectCurrentPracticeSector(state),
  }
};

export default connect(mapStateToProps, {
  setPendingUploadInForm,
  resetResponse
})(FormifiedGroupTemplateDocumentForm);
