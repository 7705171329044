import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { setActiveTab, setSearchText, setShowing, } from '^/actions/actions';
import { getAllCollection, } from '^/actions/collections';
import { isPending } from '^/consts/responseStates';
import PureComponent from '^/components/common/PureComponent';
import TemplatesSearchForm from './TemplatesSearchForm';
import TemplatesNav from './TemplatesNav';
import UploadedDocumentsListing from './UploadedDocumentsListing';
import { TEMPLATE_VIEW_BY, TEMPLATE_VIEW_BY_COLLECTION } from '^/consts/templateViewBy';


export const SEARCH_NAME = 'UPLOADED_DOCUMENTS_SEARCH';

export class UploadedDocuments extends PureComponent {
  render() {
    const { searchText, showingSearch, response, folders, activeTab, } = this.props;
    const selectedFolder = activeTab || folders.first();

    return (
      <div className="wrapper md-2">
        <div className="row">
          <div className="col-1-3 secondary-left pt-1">
            {showingSearch ? (
              <TemplatesSearchForm
                onSubmit={values => this.props.setSearchText(values.searchText)}
                hintText="You can search by name, description or filename"
              >
                <a
                  className="toggle-text toggle-text-link pull-right"
                  onClick={() => this.props.setShowing(SEARCH_NAME, !showingSearch)}
                >
                  View by Folder
                </a>
              </TemplatesSearchForm>
            ) : (
              <div>
                <h4 className="inline">View by Folder</h4>
                <a
                  className="toggle-text toggle-text-link pull-right"
                  onClick={() => this.props.setShowing(SEARCH_NAME, !showingSearch)}
                >
                  Search
                </a>
                <TemplatesNav
                  isLoading={isPending(response)}
                  viewBy={TEMPLATE_VIEW_BY.FOLDER}
                  viewByValues={folders}
                  selectedViewByValue={selectedFolder}
                  setViewByValue={viewByValue => this.props.setActiveTab(TEMPLATE_VIEW_BY.FOLDER, viewByValue)}
                />
              </div>
            )}
          </div>

          <div className="col-2-3 bordered-left pt-1">
            <UploadedDocumentsListing
              search={searchText}
              showingSearch={showingSearch}
              folder={selectedFolder}
            />
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    response: state.responses.getIn(['getCollection', TEMPLATE_VIEW_BY_COLLECTION[TEMPLATE_VIEW_BY.FOLDER]]),
    folders: state.collections.getIn([TEMPLATE_VIEW_BY_COLLECTION[TEMPLATE_VIEW_BY.FOLDER], 'items'], List()),
    activeTab: state.ui.getIn(['activeTabs', TEMPLATE_VIEW_BY.FOLDER]),
    searchText: state.ui.get('searchText'),
    showingSearch: state.ui.getIn(['showing', SEARCH_NAME]),
  };
}

export default connect(
  mapStateToProps,
  { setSearchText, setShowing, setActiveTab, getAllCollection, }
) (UploadedDocuments);
