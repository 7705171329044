import React from 'react';
import classNames from 'classnames';
import Icon from '^/components/app/content/Icon';

const HelpBlock = ({ children, iconType, className }) => (
  <div className={classNames('alert-info', className)}>
    <Icon type={iconType} className="tip-icon pull-left" />
    <div className="pl-2">
      {children}
    </div>
  </div>
);

HelpBlock.defaultProps = {
  iconType: 'info-circle'
};

HelpBlock.propTypes = {
  iconType: React.PropTypes.string,
  className: React.PropTypes.string,
};

export default HelpBlock;
