import React, { PropTypes } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

export const CycleSetupHeader = ({practice, children}) =>
  <div className="account-setup-header">
    <div>
      {practice && !practice.isEmpty() &&
        <div className="text-capitalize mt-1">{practice.get('name')}:</div>}
      <h1>{children}</h1>
    </div>
    <hr className="strong" />
  </div>;


CycleSetupHeader.propTypes = {
  practice: ImmutablePropTypes.map,
  children: PropTypes.any,
};


export default CycleSetupHeader;
