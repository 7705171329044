import composeReducers from '^/reducers/composeReducers';
import { Map, fromJS } from 'immutable';
import * as stats from '^/actions/stats';


function yearToDateStatsReducer(state = Map(), action) {
  switch (action.type) {
    case stats.LOAD_YEAR_TO_DATE_STATS.REQUEST:
      return state.set(action.meta.statsType, null)
                  .set('filters', action.meta.filters);
    case stats.LOAD_YEAR_TO_DATE_STATS.SUCCESS:
      return state.set(action.meta.statsType, fromJS(action.payload));
    default:
      return state;
  }
}

export default composeReducers([yearToDateStatsReducer], Map());
