import React from 'react';

import { SSO_REGISTRATION_URL } from '^/consts/single-sign-on';
import Loading from '^/components/app/content/Loading';

export default class RegistrationPage extends React.Component<{}, {}> {
  componentDidMount() {
    window.location.replace(SSO_REGISTRATION_URL);
  }

  render() {
    return <Loading />;
  }
}
