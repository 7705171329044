import React from 'react';
import { connect } from 'react-redux';

import { loadItem } from '^/actions/items';
import { PRACTICES } from '^/consts/collectionKeys';
import { loadTemplateActivities } from '^/actions/actions';
import { setupComplianceReviewsAndReloadUser } from '^/actions/actionSequences';

import { getCurrentPracticeId } from '^/models/user';
import { getCycleToSetupId } from '^/consts/setup';

import AccountSetupHeader from '^/components/app/account-set-up/AccountSetupHeader';
import
  ComplianceReviewsSetupForm,
  { transformAdminStepData }
from '^/components/app/account-set-up/ComplianceReviewsSetupForm';
import Loading from '^/components/app/content/Loading';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import PureComponent from '^/components/common/PureComponent';
import { ADMIN_STEP_START_DAY } from '^/consts/account-set-up/fieldNames';

export const ADMIN_STEP_TEMPLATE_TYPE = 'activities/setup/admin-steps';

export class ComplianceReviewsSetupPage extends PureComponent {

  componentWillMount() {
    const { practiceId, cycleId } = this.props;
    this.loadPracticeAndAdminSteps(practiceId, cycleId);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.practiceId !== newProps.practiceId || this.props.cycleId !== newProps.cycleId) {
      this.loadPracticeAndAdminSteps(newProps.practiceId, newProps.cycleId);
    }
  }

  loadPracticeAndAdminSteps(practiceId, cycleId) {
    if (practiceId && cycleId) {
      this.props.loadItem(PRACTICES, practiceId);
      this.props.loadTemplateActivities(ADMIN_STEP_TEMPLATE_TYPE, practiceId, cycleId);
    }
  }

  transformAndSubmitData(data) {
    const { cycleId } = this.props;

    const dataToSubmit = {
      [ADMIN_STEP_START_DAY]: data[ADMIN_STEP_START_DAY],
      admin_steps: transformAdminStepData(data),
    }; // reconstruct data for submitting

    this.props.setupComplianceReviewsAndReloadUser(cycleId, dataToSubmit);
  }

  render() {
    const { user, practice, templateAdminSteps, response } = this.props;

    if (!user || !practice || templateAdminSteps === undefined || templateAdminSteps === null) {
      return <Loading />;
    }

    return (
      <div>
        <AccountSetupHeader user={user} practice={practice}>
          Setup - compliance reviews
        </AccountSetupHeader>

        <HelpBlock>
          <p className="small-text">
            We've already selected all of the compulsory compliance activities for you, so all
            you need to do is pick the optional ones you'd like to add, and choose the day of the week you'd like to be
            alerted.
          </p>
        </HelpBlock>

        <hr className="thin" />
        <ComplianceReviewsSetupForm
          response={response}
          templateAdminSteps={templateAdminSteps}
          onSubmit={data => this.transformAndSubmitData(data)}
        />
      </div>
    );
  }
}


export const mapStateToProps = (state) => {
  const practiceId = getCurrentPracticeId(state.userProfile);
  return {
    user: state.userProfile,
    practiceId,
    cycleId: getCycleToSetupId(state.userProfile, practiceId),
    templateAdminSteps: state.templateActivities.get(ADMIN_STEP_TEMPLATE_TYPE),
    practice: state.currentPractice,
    response: state.responses.get('setupComplianceReviews'),
  };
};

export default connect(mapStateToProps,
  { loadItem, loadTemplateActivities, setupComplianceReviewsAndReloadUser }
) (ComplianceReviewsSetupPage);
