import React from 'react';

import FormError from '^/components/app/forms/FormError';


const CheckboxGroup = ({ field, display, response, options, required }) => {

  const handleCheckedBoxes = event => {
    const checkboxValue = event.target.value;
    const selectedValues = field.value || [];

    return event.target.checked
      ? field.onChange([...selectedValues, checkboxValue])
      : field.onChange(
          selectedValues.filter(
            selectedValue => selectedValue !== checkboxValue
          )
        );
  };

  return (
    <div className="form-group">
      <div className="col-1-3">
        <label htmlFor={field.name}>{display}</label>
        {required && <span className="help-block">Required</span>}
      </div>
      <div className="col-2-3">
        {options.map(({ label, value }, index) => (
            <label key={`label${index}`} className="label-inline">
              {label}
              <input
                key={`label${index}`}
                type="checkbox"
                id={`label${index}`}
                name={field.name}
                checked={
                  (field.value || []).filter(
                    selectedValue => selectedValue === value
                  ).length > 0
                }
                value={value}
                onChange={handleCheckedBoxes}
              />
            </label>
        ))}
        <FormError response={response} formKey={field.name} />
      </div>
    </div>
  );
};

export default CheckboxGroup;
