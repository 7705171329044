import React from 'react';
import { connect } from 'react-redux';

import PureComponent from '^/components/common/PureComponent';
import CreateCustomFieldForm from '^/components/app/digital-tools/records/checklist/forms/CreateCustomFieldForm';

import { closeModal } from '^/actions/modals';
import { createSlugFromString } from '^/utils';
import { addCustomField } from '^/actions/actions';

export class CreateCustomFieldModal extends PureComponent {
  constructor(props) {
    super(props);
    this.createCustomField = this.createCustomField.bind(this);
  }

  createCustomField(data) {
    const slug = createSlugFromString(data.name);

    const headingData = data.heading.split('|');
    const order = parseInt(headingData[0], 10);
    const heading = headingData[1];

    const submitData = {
      slug,
      name: data.name,
      type: data.field_type,
      heading: heading,
      order: order + 1, // Position after the existing field at 'order'
    };
    this.props.addCustomField(submitData);
    this.props.closeModal();
  }

  render() {
    const { headings, customChecklistFields } = this.props;

    return (
      <div>
        <CreateCustomFieldForm
          onSubmit={this.createCustomField}
          headings={headings}
          customChecklistFields={customChecklistFields}
        />
      </div>
    );
  }
}

export default connect(null, {
  addCustomField,
  closeModal,
})(CreateCustomFieldModal);
