import React from 'react';
import { connect } from 'react-redux';

import { expandUiComponent, collapseUiComponent } from '^/actions/actions';
import { reassignPrivateDocumentsCopiesAndRedirect } from '^/actions/actionSequences';
import Icon from '^/components/app/content/Icon';
import PureComponent from '^/components/common/PureComponent';
import { isPending } from '^/consts/responseStates';
import ReactTooltip from 'react-tooltip';
import ReassignPrivateDocumentOwnershipControl
  from '^/components/app/template-documents/ReassignPrivateDocumentOwnershipControl';

const REASSIGN_PRIVATE_DOCUMENT_OWNERSHIP = 'REASSIGN_PRIVATE_DOCUMENT_OWNERSHIP';

const name = (props, popup) => `${props.copiedDocumentId}.${popup}`;

export class ReassignPrivateDocumentOwnership extends PureComponent {

  constructor(props) {
    super(props);
    this.reassignToNewMember = this.reassignToNewMember.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
    this.onTogglePopup = this.onTogglePopup.bind(this);
    this.onOpenPopup = this.onOpenPopup.bind(this);
  }

  reassignToNewMember (newMemberId, staffdetailId) {
    this.props.reassignPrivateDocumentsCopiesAndRedirect(
      newMemberId, {[this.props.copiedDocumentId]: staffdetailId}
    );
  }

  onClosePopup () {
    this.props.collapseUiComponent(this.props.popupName);
  }

  onOpenPopup () {
    this.props.expandUiComponent(this.props.popupName);
  }

  onTogglePopup() {
    const { isPopupOpen } = this.props;

    if (isPopupOpen) {
      this.onClosePopup();
    } else {
      this.onOpenPopup();
    }
  }

  render () {
    const { isPopupOpen, response, isWorkingVersion } = this.props;
    return (
      <div className="popup-container ml-1-2">
        {isPopupOpen && (
          <div className="popup collaborators">
            <a className="close" onClick={this.onClosePopup}>
              <Icon type="close" className="inline-icon" />
            </a>
            <h5>Change Owner</h5>
            <ReassignPrivateDocumentOwnershipControl
              onReassign={this.reassignToNewMember}
              isLoading={isPending(response)}
            />
          </div>
         )}
        <ReactTooltip effect="solid" />
        {
          isWorkingVersion ? (
            <a data-tip="Save the document to reassign" className="underlined">
              Reassign
            </a>
          ) : (
            <a onClick={this.onTogglePopup} className="underlined">
              Reassign
            </a>
          )
        }
      </div>
    );
  }
}

export function mapStateToProps (state, props) {
  const popupName = name(props, REASSIGN_PRIVATE_DOCUMENT_OWNERSHIP);
  return {
    isPopupOpen: state.ui.get('expandedUiComponents').contains(popupName),
    response: state.responses.get('reassignPrivateDocuments'),
    popupName
  };
}

export default connect(
  mapStateToProps,
  { collapseUiComponent, expandUiComponent, reassignPrivateDocumentsCopiesAndRedirect }
)(ReassignPrivateDocumentOwnership);
