import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { routeActions } from 'react-router-redux';
import _ from 'underscore';

import {
  adoptTemplateVersionRenderDocumentAndOptionallyRedirect,
  createTemplateVersionRenderDocumentAndOptionallyRedirect,
  discardWorkingDraftLoadLatestAndThen,
  openExitConfirmationModalAndCheckForLogin,
} from '^/actions/actionSequences';
import {
  closeModal,
  openPracticeAdoptionModal,
  openNameDocumentCopyModal,
  openRestoreAdoptedVersionModal,
  openGroupCreatedTemplateDocumentNewVersionModal,
  openDocReadAddTeamMembersModal,
  openDocumentTakeOverModal,
  openDocumentReviewModal
} from '^/actions/modals';
import {
  resetResponseAndOpenGroupDocumentModal,
  updateGroupTemplateDocumentFileVersionReloadAndCloseEditor,
} from '^/actions/groupDocuments';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import { EMPTY_FILE_ERROR_MESSAGE } from '^/components/app/groups/group-documents/GroupTemplateDocumentPage';
import { isPending } from '^/consts/responseStates';
import {
  isFileUploadTemplateDocument,
  isHTMLDocument,
  isCopiable,
  getBaseTemplateDocument,
  getDocumentLink,
  isGroupCreatedDocument,
} from '^/models/documents';
import { STAFF_PRACTICE_TYPE } from '^/models/staffPractice';
import {
  loadTemplateLatestVersion,
  loadTemplateLatestGroupVersion,
  saveAsWorkingDraft,
  discardWorkingDraft,
  clearItem,
} from '^/actions/items';
import { TEMPLATE_DOCUMENT_VERSION } from '^/consts/collectionKeys';
import {
  expandUiComponent,
  collapseUiComponent,
  setEditorContent,
  resetResponse,
  setResponseErrorForKey,
} from '^/actions/actions';
import {
  isGroupDocument,
  handleOnBeforeUnload,
  isGroupsAppSection,
  getAppSectionPathPrefix,
  isEditingGroupDocument,
} from '^/utils';
import { practiceHasAccessToDocumentReviews, practiceHasDocumentsToReadAccess } from '^/stateHelpers';
import DocumentEditor from './DocumentEditor';
import DemoModeMayDisallow from '^/components/app/perms/DemoModeMayDisallow';
import { withRouter } from '^/withRouter';
import DocumentBackButton from '^/components/app/nav/document-back-button';

const GROUP_TEMPLATE_DOCUMENT_FILE_VERSION = 'updateGroupTemplateDocumentFileVersion';

const FIRST_VERSION_NUMBER = '1';
export const CONFIRM_LEAVE_PAGE_MESSAGE =
  'Are you sure you want to leave this page?';

export class TemplateDocumentPage extends PureComponent {
  constructor(props) {
    super(props);
    this.isEditing = this.isEditing.bind(this);
    this.confirmBeforeTransition = this.confirmBeforeTransition.bind(this);
    this.createNewVersionGroupCreatedDocument = this.createNewVersionGroupCreatedDocument.bind(this);
  }

  componentWillMount() {
    const {
      onCancel,
      setContent,
      loadTemplate,
      params: { uuid },
      resetSaveResponses,
      editingGroupDocument,
      loadGroupTemplate,
    } = this.props;

    onCancel();
    setContent('');
    resetSaveResponses();
    if (editingGroupDocument) {
      loadGroupTemplate(uuid);
    } else {
      loadTemplate(uuid);
    }

    this.clearTransitionListener = this.props.router.listenBefore(
      this.confirmBeforeTransition
    );
    window.onbeforeunload = handleOnBeforeUnload;
  }

  confirmBeforeTransition(_location, callback) {
    callback(false);
    this.confirmIfEditing(() => callback(true));
  }

  confirmIfEditing(onContinue) {
    const {
      userId,
      templateDocumentVersion,
      editorContent,
      editingGroupDocument,
      currentPracticeGroupId,
      saveResponse,
      copyResponse,
    } = this.props;

    const isCurrentUserEditing =
      templateDocumentVersion &&
      templateDocumentVersion.getIn(['working_version', 'user', 'id']) ===
        userId;

    if (
      this.isEditing() &&
      isCurrentUserEditing &&
      !isPending(saveResponse) &&
      !isPending(copyResponse)
    ) {
      this.props.exitConfirmation(
        templateDocumentVersion,
        editorContent,
        editingGroupDocument && currentPracticeGroupId,
        () => {
          this.onSave(null, false, onContinue);
        },
        () => {
          this.props.discardWorkingDraftLoadLatestAndThen(
            templateDocumentVersion.get('id'),
            editingGroupDocument && currentPracticeGroupId,
            onContinue
          );
        },
        this.isEditing
      );
    } else {
      onContinue();
    }
  }

  componentWillReceiveProps(newProps) {
    const {
      templateDocumentVersion,
      setContent,
      loadTemplate,
      params: { uuid },
      editingGroupDocument,
      loadGroupTemplate,
      userId,
      editorContent,
      currentPracticeGroupId,
    } = this.props;

    if (
      newProps.templateDocumentVersion &&
      templateDocumentVersion !== newProps.templateDocumentVersion
    ) {
      setContent(
        newProps.templateDocumentVersion.getIn([
          'working_version',
          'user',
          'id',
        ]) === userId
          ? newProps.templateDocumentVersion.getIn([
              'working_version',
              'content',
            ])
          : newProps.templateDocumentVersion.get('content')
      );
      if (
        newProps.templateDocumentVersion.getIn([
          'copied_template_document',
          'id',
        ]) === uuid &&
        newProps.templateDocumentVersion.get('version_number') ===
          FIRST_VERSION_NUMBER &&
        !newProps.templateDocumentVersion.get('working_version')
      ) {
        this.props.startEditing(
          newProps.templateDocumentVersion,
          editingGroupDocument && currentPracticeGroupId
        );
      }
    }
    if (newProps.params && newProps.params.uuid !== uuid) {
      const newUuid = newProps.params.uuid;
      if (editingGroupDocument) {
        loadGroupTemplate(newUuid);
      } else {
        loadTemplate(newUuid);
      }
    } else if (
      templateDocumentVersion &&
      editorContent !== newProps.editorContent &&
      !this.props.isFileUpload
    ) {
      const savedContent = templateDocumentVersion.get('content');
      const workingDraft = templateDocumentVersion.get('working_version');

      if (!workingDraft && savedContent !== newProps.editorContent) {
        this.props.startEditing(
          templateDocumentVersion,
          editingGroupDocument && currentPracticeGroupId
        );
      } else if (workingDraft && workingDraft.getIn(['user', 'id']) === userId) {
        this.props.saveAsWorkingDraft(
          templateDocumentVersion.get('id'),
          newProps.editorContent,
          editingGroupDocument && currentPracticeGroupId
        );
      }
    }
  }

  componentWillUnmount() {
    this.props.resetItem();
    this.clearTransitionListener();
    window.onbeforeunload = null;
  }

  createNewVersionGroupCreatedDocument(shouldRedirect, extraData) {
    this.props.createNewVersion(
      this.props.editorContent,
      null,
      this.props.templateDocumentVersion.get('id'),
      this.props.currentPracticeGroupId,
      shouldRedirect,
      closeModal,
      extraData
    );
  }

  onSave(documentType = null, shouldRedirect = true, andThen) {
    const {
      editorContent,
      currentPracticeId,
      currentPracticeGroupId,
      templateDocumentVersion,
      readOnly,
      editingGroupDocument,
      isEditingGroupCreatedDocument,
      createNewVersion,
      adoptTemplate,
      copyTemplate,
      adoptTemplateToGroup,
      isGroupAdmin,
    } = this.props;

    const isAdopted = editingGroupDocument
      ? templateDocumentVersion.get('group_template_document')
      : templateDocumentVersion.get('adopted_template_document');
    const isCopied = templateDocumentVersion.get('copied_template_document');
    const isCopiableDoc = isCopiable(templateDocumentVersion);
    const id = templateDocumentVersion.get('id');

    if (isCopied || isAdopted) {
      if (isEditingGroupCreatedDocument || (isGroupAdmin && editingGroupDocument)) {
        this.props.openGroupCreatedTemplateDocumentNewVersionModal(
          templateDocumentVersion.getIn(['group_template_document', 'template_document', 'name']),
          extraData => this.createNewVersionGroupCreatedDocument(shouldRedirect, extraData)
        );
      } else {
        createNewVersion(
          editorContent,
          documentType,
          id,
          editingGroupDocument && currentPracticeGroupId,
          shouldRedirect,
          andThen
        );
      }
    } else if (!readOnly) {
      if (editingGroupDocument) {
        adoptTemplateToGroup(
          editorContent,
          currentPracticeGroupId,
          documentType,
          id,
          shouldRedirect,
          andThen
        );
      } else if (isCopiableDoc) {
        copyTemplate(
          editorContent,
          currentPracticeId,
          documentType,
          id,
          shouldRedirect,
          andThen
        );
      } else {
        adoptTemplate(
          editorContent,
          currentPracticeId,
          documentType,
          id,
          shouldRedirect,
          andThen
        );
      }
    }
  }

  onSaveCopy(documentType = null) {
    const {
      editorContent,
      createNewCopy,
      currentPracticeId,
      templateDocumentVersion,
      currentPracticeGroupId,
      editingGroupDocument,
    } = this.props;

    const baseVersion =
      templateDocumentVersion.get('copied_template_document') ||
      templateDocumentVersion;
    const baseDocument =
      baseVersion.get('template_document') ||
      baseVersion.get('group_template_document');

    createNewCopy(
      baseDocument.get('id'),
      editorContent,
      currentPracticeId,
      documentType,
      templateDocumentVersion.get('id'),
      editingGroupDocument && currentPracticeGroupId
    );
  }

  confirmAndCancel() {
    if (window.confirm(CONFIRM_LEAVE_PAGE_MESSAGE)) {
      this.cancel();
    }
  }

  cancel() {
    const {
      templateDocumentVersion,
      currentPracticeGroupId,
      editingGroupDocument,
    } = this.props;

    if (
      templateDocumentVersion.get('copied_template_document') &&
      templateDocumentVersion.get('version_number') === FIRST_VERSION_NUMBER
    ) {
      this.props.createNewVersion(
        templateDocumentVersion.get('content'),
        null,
        templateDocumentVersion.get('id'),
        editingGroupDocument && currentPracticeGroupId
      );
    } else if (templateDocumentVersion.get('working_version')) {
      this.props.saveAsWorkingDraft.cancel();
      this.props.discardWorkingDraftLoadLatestAndThen(
        templateDocumentVersion.get('id'),
        editingGroupDocument && currentPracticeGroupId,
        this.props.onCancel
      );
    } else {
      this.props.onCancel();
    }
  }

  isEditing() {
    const { isEditing, templateDocumentVersion, userId } = this.props;

    return (
      isEditing ||
      Boolean(
        templateDocumentVersion &&
          templateDocumentVersion.getIn(['working_version', 'user', 'id']) ===
            userId
      )
    );
  }

  renderDocument() {
    const {
      editorContent,
      templateDocumentVersion,
      response,
      setContent,
      editingGroupDocument,
      currentPracticeGroupId,
      isEditingGroupCreatedFileDocument,
    } = this.props;

    const adoptedDocument = templateDocumentVersion && templateDocumentVersion.get(
      editingGroupDocument ? 'group_template_document' : 'adopted_template_document'
    );

    if (isPending(response) || !templateDocumentVersion) {
      return <Loading />;
    }

    return (
      <div className="wrapper">
        <DocumentBackButton retainLoadedDocumentsProp/>

        <DocumentEditor
          {...this.props}
          setEditorContent={setContent}
          doc={templateDocumentVersion}
          isEditing={this.isEditing()}
          onSave={documentType => this.onSave(documentType, false)}
          onSaveAndClose={documentType => this.onSave(documentType, true)}
          onSaveCopy={documentType => this.onSaveCopy(documentType)}
          onSaveWorking={() =>
            this.props.saveAsWorkingDraft(
              templateDocumentVersion.get('id'),
              editorContent,
              currentPracticeGroupId
            )
          }
          discard={() =>
            this.props.discardWorkingDraftAndLoadLatest(
              templateDocumentVersion.get('id'),
              editingGroupDocument && currentPracticeGroupId
            )
          }
          startEditing={isEditingGroupCreatedFileDocument ?
            undefined :
            () => this.props.startEditing(
              templateDocumentVersion,
              editingGroupDocument && currentPracticeGroupId
            )
          }
          onCancel={() => this.cancel()}
          restoreAdoptedVersion={() =>
            this.props.restoreAdoptedVersion(templateDocumentVersion.get('id'))
          }
          updateGroupTemplateDocumentFileVersion={this.props.updateGroupTemplateDocumentFileVersion}
          openDocumentReviewModal={() => this.props.openDocumentReviewModal(adoptedDocument)
          }
        />
      </div>
    );
  }

  render() {
    const { response } = this.props;

    return (
      <DemoModeMayDisallow
        message="cannot see documents"
        response={response}
        goBack
      >
        {this.renderDocument()}
      </DemoModeMayDisallow>
    );
  }
}

export const name = props =>
  `TEMPLATE_DOCUMENT_VERSION.content.${props.params && props.params.uuid}`;

export function mapStateToProps(state, props) {
  const templateDocumentVersion = state.items.get(TEMPLATE_DOCUMENT_VERSION);
  const groupTemplateDocument = templateDocumentVersion && templateDocumentVersion.get('group_template_document');
  const templateDocument = templateDocumentVersion && getBaseTemplateDocument(templateDocumentVersion);
  const editingGroupDocument = isEditingGroupDocument(props.location.pathname);
  const showingCopies = props.location.pathname.includes('/copies/');

  const { userProfile, currentPractice } = state;

  const isGroupAdmin = isGroupDocument(currentPractice, userProfile);
  const isGroupsApp = isGroupsAppSection(props.location.pathname);
  const userId = userProfile.get('id');
  const isAdmin = userProfile.get('is_admin_of_current_practice');

  const isFileUpload = isFileUploadTemplateDocument(templateDocumentVersion);
  const isEditingGroupCreatedDocument = (
    isGroupAdmin &&
    isGroupsApp &&
    (groupTemplateDocument || isFileUpload) &&
    isGroupCreatedDocument(templateDocument)
  );
  const isEditingGroupCreatedFileDocument = isFileUpload && isEditingGroupCreatedDocument;

  const hasDocumentsToReadAccess = practiceHasDocumentsToReadAccess(state);
  // Can only edit and adopt HTML documents that have not already been adopted.
  // Group-created file upload documents can have a new file uploaded.
  const readOnly =
    !templateDocumentVersion ||
    (
      !isHTMLDocument(templateDocumentVersion) &&
      !isEditingGroupCreatedFileDocument
    ) ||
    templateDocumentVersion.get(
      editingGroupDocument ? 'is_adopted_to_group' : 'is_adopted'
    );

  return {
    templateDocumentVersion,
    hasDocumentsToReadAccess,
    autosaveAsWorkingDraftResponse: state.responses.get('saveAsWorkingDraft'),
    response: editingGroupDocument
      ? state.responses.getIn([
          'loadTemplateLatestGroupVersion',
          TEMPLATE_DOCUMENT_VERSION,
        ])
      : state.responses.getIn([
          'loadTemplateLatestVersion',
          TEMPLATE_DOCUMENT_VERSION,
        ]),
    groupTemplateDocumentNewFileVersionResponse: state.responses.getIn(
      [GROUP_TEMPLATE_DOCUMENT_FILE_VERSION, 'newVersion']
    ),
    groupTemplateDocumentCurrentFileVersionResponse: state.responses.getIn(
      [GROUP_TEMPLATE_DOCUMENT_FILE_VERSION, 'currentVersion']
    ),
    isEditing: state.ui.get('expandedUiComponents').contains(name(props)),
    editorContent: state.ui.get('editorContent'),
    currentPracticeId: state.currentPractice && state.currentPractice.get('id'),
    currentPracticeGroupId:
      state.currentPractice && state.currentPractice.getIn(['group', 'id']),
    readOnly,
    sendDocReadToTeamResponse: state.responses.get('sendDocReadToTeamResponse'),
    hasAccessToDocumentReviews: practiceHasAccessToDocumentReviews(state),
    editingGroupDocument,
    showingCopies,
    userId,
    isGroupAdmin,
    isGroupsApp,
    isEditingGroupCreatedDocument,
    isEditingGroupCreatedFileDocument,
    isFileUpload,
    isAdmin,
    saveResponse: Map().merge(
      state.responses.get('createTemplateVersion'),
      state.responses.get('adoptTemplateVersion')
    ),
    copyResponse: state.responses.get('copyTemplateVersion'),
    isPracticeAdmin:
      state.userProfile.get('user_type_in_current_practice') ===
      STAFF_PRACTICE_TYPE.PRACTICE_ADMIN,
    currentPathname: state.routing.location.pathname,
  };
}

export function mapDispatchToProps(dispatch, props) {
  const paramsId = props.params.uuid;
  return {
    openDocumentTakeOverModal: (templateVersion, userCurrentlyEditing, to) => {dispatch(openDocumentTakeOverModal(templateVersion, userCurrentlyEditing, to))},
    loadTemplate: id => dispatch(loadTemplateLatestVersion(id)),
    loadGroupTemplate: id => dispatch(loadTemplateLatestGroupVersion(id)),
    startEditing: (doc, groupId) => {
      dispatch(expandUiComponent(name(props)));
      if (!doc.get('working_version')) {
        dispatch(
          saveAsWorkingDraft(doc.get('id'), doc.get('content'), false, groupId)
        );
      }
      dispatch(resetResponse('renderDocument'));
    },
    startEditingGroupCreatedFileDocument: () => {
      dispatch(resetResponse(GROUP_TEMPLATE_DOCUMENT_FILE_VERSION));
      dispatch(expandUiComponent(name(props)))
    },
    createNewVersion: (
      content,
      documentType,
      workingId,
      groupId,
      shouldRedirect,
      andThen,
      extraData
    ) => {
      dispatch(
        createTemplateVersionRenderDocumentAndOptionallyRedirect(
          props.params.uuid,
          content,
          null,
          extraData,
          documentType,
          shouldRedirect,
          andThen
        )
      );
      if (shouldRedirect) {
        dispatch(collapseUiComponent(name(props)));
      }
      dispatch(discardWorkingDraft(workingId, groupId));
      dispatch(setEditorContent(content));
    },
    updateGroupTemplateDocumentFileVersion: data => {
      if (!data.content_file) {
        dispatch(
          setResponseErrorForKey(
            GROUP_TEMPLATE_DOCUMENT_FILE_VERSION,
            'newVersion',
            EMPTY_FILE_ERROR_MESSAGE
          )
        );
      } else {
        dispatch(
          updateGroupTemplateDocumentFileVersionReloadAndCloseEditor(
            name(props),
            paramsId,
            data
          )
        );
      }
    },
    restoreAdoptedVersion: workingId => {
      dispatch(openRestoreAdoptedVersionModal(paramsId));
      dispatch(discardWorkingDraft(workingId));
      dispatch(collapseUiComponent(name(props)));
    },
    createNewCopy: (
      docId,
      content,
      currentPracticeId,
      documentType,
      workingId,
      groupId
    ) => {
      dispatch(
        openNameDocumentCopyModal(
          docId,
          content,
          currentPracticeId,
          null,
          null,
          null,
          documentType
        )
      );
      dispatch(discardWorkingDraft(workingId, groupId));
      dispatch(collapseUiComponent(name(props)));
      dispatch(setEditorContent(content));
    },
    adoptTemplate: (
      content,
      currentPracticeId,
      documentType,
      workingId,
      shouldRedirect,
      andThen
    ) => {
      dispatch(
        adoptTemplateVersionRenderDocumentAndOptionallyRedirect(
          props.params.uuid,
          content,
          currentPracticeId,
          null,
          version => getDocumentLink(version.adopted_template_document.id),
          null,
          documentType,
          shouldRedirect,
          andThen
        )
      );
      if (shouldRedirect) {
        dispatch(discardWorkingDraft(workingId));
        dispatch(collapseUiComponent(name(props)));
      }
      dispatch(setEditorContent(content));
    },
    copyTemplate: (
      content,
      currentPracticeId,
      documentType,
      workingId,
      andThen
    ) => {
      dispatch(
        openNameDocumentCopyModal(
          paramsId,
          content,
          currentPracticeId,
          null,
          andThen,
          null,
          documentType
        )
      );
      dispatch(discardWorkingDraft(workingId));
      dispatch(setEditorContent(content));
    },
    adoptTemplateToGroup: (
      content,
      currentPracticeGroupId,
      documentType,
      workingId,
      shouldRedirect,
      andThen
    ) => {
      const {
        location: { pathname },
      } = props;
      dispatch(
        adoptTemplateVersionRenderDocumentAndOptionallyRedirect(
          props.params.uuid,
          content,
          null,
          currentPracticeGroupId,
          version =>
            `${getAppSectionPathPrefix(pathname)}${
              !isGroupsAppSection(pathname) ? 'group/' : ''
            }${version.group_template_document.id}/`,
          null,
          documentType,
          shouldRedirect,
          andThen
        )
      );
      if (shouldRedirect) {
        dispatch(discardWorkingDraft(workingId, currentPracticeGroupId));
        dispatch(collapseUiComponent(name(props)));
      }
      dispatch(setEditorContent(content));
    },
    discardWorkingDraftAndLoadLatest: (id, groupId) =>
      dispatch(
        discardWorkingDraftLoadLatestAndThen(id, groupId, paramsId, () =>
          dispatch(collapseUiComponent(name(props)))
        )
      ),
    discardWorkingDraftLoadLatestAndThen: (id, groupId, andThen) =>
      dispatch(
        discardWorkingDraftLoadLatestAndThen(id, groupId, paramsId, () => {
          dispatch(collapseUiComponent(name(props)));
          andThen();
        })
      ),
    saveAsWorkingDraft: _.throttle(
      (docId, content, groupId) =>
        dispatch(saveAsWorkingDraft(docId, content, false, groupId)),
      10000,
      true
    ),
    onCancel: () => {
      dispatch(collapseUiComponent(name(props)));
      dispatch(resetResponse('renderDocument'));
    },
    setContent: content => dispatch(setEditorContent(content)),
    openPracticeAdoptionModal: templateDocument =>
      dispatch(openPracticeAdoptionModal(templateDocument)),
    resetResponseAndOpenGroupDocumentModal: templateDocument =>
      dispatch(resetResponseAndOpenGroupDocumentModal(templateDocument)),
    openGroupCreatedTemplateDocumentNewVersionModal: (documentName, onPublishNewVersion) =>
      dispatch(openGroupCreatedTemplateDocumentNewVersionModal(documentName, onPublishNewVersion)),
    toggleShowingCopies: () => {
      const {
        location: { pathname },
      } = props;
      const newPath = pathname.endsWith('/copies/')
        ? pathname.slice(0, -7)
        : pathname + 'copies/';
      dispatch(routeActions.push(newPath));
    },
    resetSaveResponses: () => {
      dispatch(resetResponse('createTemplateVersion'));
      dispatch(resetResponse('adoptTemplateVersion'));
      dispatch(resetResponse('renderDocument'));
      dispatch(resetResponse('sendDocReadToTeamResponse'));
    },
    exitConfirmation: (
      document,
      content,
      groupId,
      onSave,
      discard,
      isEditing
    ) => {
      dispatch(
        openExitConfirmationModalAndCheckForLogin(
          document,
          content,
          groupId,
          paramsId,
          onSave,
          discard,
          isEditing
        )
      );
    },
    resetItem: () => dispatch(clearItem(TEMPLATE_DOCUMENT_VERSION)),
    openDocReadAddTeamMembersModal: (
      templateDocumentVersionId,
      practiceId
    ) => dispatch(openDocReadAddTeamMembersModal(templateDocumentVersionId, practiceId)),
    openDocumentReviewModal: (adoptedDocument) => dispatch(openDocumentReviewModal(adoptedDocument)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TemplateDocumentPage));
