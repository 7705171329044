import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { List } from 'immutable';
import classNames from 'classnames';

import { formatDate } from '^/utils';
import { isDemoUser } from '^/models/user';
import {
  getActivityTypeText, STATUS_NAMES, STATUS_CLASSES
} from '^/models/activities';
import { getAttachedDocumentListsFromActivity } from '^/models/documents';
import { isPending, hasSucceeded, hasFailed } from '^/consts/responseStates';
import { setActiveTab, setSortBy, setSortByReversed } from '^/actions/actions';
import { getAllCollection, clearCollection } from '^/actions/collections';
import { openDemoModeDisallowsModal } from '^/actions/modals';
import PureComponent from '^/components/common/PureComponent';
import Icon from '^/components/app/content/Icon';
import ActivityStatusIcon from '^/components/app/reports/ActivityStatusIcon';
import TasksList from '^/components/app/activities/TasksList';
import Loading from '^/components/app/content/Loading';
import TemplateDocumentLink from '^/components/app/activities/documents/TemplateDocumentLink';

export const COMPLIANCE_ACTIVITIES = 'compliance-activities';

export const getCollectionName = (id) => `activities/compliance-report/${id}`;

const SortIcon = ({sort}) => {
  const { reversed, sorted, setSorted } = sort;
  return (
    <span
      className={classNames('sort-icon', {sorted, reversed})}
      onClick={setSorted}
    />
  );
};

const SortableHeader = ({sort, children, className}) => (
  <th className={className}>
    {children}
    {sort && <SortIcon sort={sort} />}
  </th>
);

export class ActivitiesListing extends PureComponent {
  componentWillReceiveProps(newProps) {
    const isLoadingOrHasEverTried = (
      newProps.isLoading ||
      newProps.hasFailed ||
      newProps.hasSucceeded
    );
    if (newProps.isVisible && !isLoadingOrHasEverTried) {
      this.props.getAllCollection(getCollectionName(newProps.id), {filters: newProps.filter.toJS()});
    }
  }

  hideAllEvidence() {
    this.props.setActiveTab(COMPLIANCE_ACTIVITIES, null);
  }

  showEvidence(activity) {
    this.props.setActiveTab(COMPLIANCE_ACTIVITIES, activity.get('id'));
  }

  renderExpandedRow() {
    const { activities, activeTab } = this.props;
    const activity = activities.find(_activity => _activity.get('id') === activeTab);

    const {
      id, info, doc_location, tasks, uploaded_activity_files
    } = activity.toObject();

    const { attachedDocuments } = getAttachedDocumentListsFromActivity(activity);
    return (
      <tr key={`${id}-evidence`} className="row-child">
        <td colSpan={7}>
          <div className="row">
            <div className="col-1-3 pl-1">
              <h5>Evidence notes</h5>
              <p className={!info && 'help-block'}>{info || 'NONE'}</p>
              <h5>Hard copy evidence</h5>
              <p className={!doc_location && 'help-block'}>{doc_location || 'NONE'}</p>
            </div>

            <div className="col-1-3">
              <h5>Documents</h5>
              <h6>Attached documents</h6>
              {attachedDocuments.isEmpty() ? (
                <p className="help-block">NONE</p>
              ) : (
                <ul className="list-no-indent">
                  {attachedDocuments.map((doc, idx) =>
                    <li key={idx}>
                      <Icon
                        type="file-text-o" className="inline-icon"
                      />
                      <TemplateDocumentLink webLink={doc.getIn(['export', 'download_url'])}>
                        {doc.get('document_name')} v{doc.get('document_version')}
                      </TemplateDocumentLink>
                    </li>
                  )}
                </ul>
              )}

              <h6>Uploaded files</h6>
              {uploaded_activity_files.isEmpty() ? (
                <p className="help-block">NONE</p>
              ) : (
                <ul className="list-no-indent">
                  {uploaded_activity_files.map((file, idx) =>
                    <li key={idx}>
                      <Icon
                        type="file-text-o" className="inline-icon"
                      />
                      <TemplateDocumentLink downloadLink={file.get('file')} isUploadedFile>
                        {file.get('name')}
                      </TemplateDocumentLink>
                    </li>
                  )}
                </ul>
              )}
            </div>

            <div className="col-1-3">
              <h5>Related Tasks</h5>
              {tasks && tasks.size ? (
                <TasksList tasks={tasks} className="list-tight" />
              ) : (
                <p className="help-block">NONE</p>
              )}
            </div>
          </div>
        </td>
      </tr>
    );
  }

  isExpanded(activity) {
    return this.props.activeTab === activity.get('id');
  }

  alertEvidenceDocsHidden() {
    this.props.openDemoModeDisallowsModal({message: 'cannot see evidence and documents'});
  }

  renderExpandHideEvidence(activity) {
    return this.isExpanded(activity) ? (
      <button className="btn btn-default small" onClick={() => this.hideAllEvidence()} >
        &#8673; Hide
      </button>
    ) : (
      <button className="btn btn-default small" onClick={() => this.showEvidence(activity)} >
        &#8675; Evidence &amp; Docs
      </button>
    );
  }

  renderRows(activities) {
    const { isDemoMode } = this.props;
    return activities
      .map(activity => {
        const { id, status, date_last_done, type, title } = activity.toObject();
        return (
          <tr key={id} className={this.isExpanded(activity) && 'row-expanded'}>
            <td className="nowrap" data-title="Status">
              <span className={STATUS_CLASSES[status] + ' text-capitalize bold-text'}>
                <ActivityStatusIcon activity={activity} className="inline-icon" /> {STATUS_NAMES[status]}
              </span>
            </td>
            <td data-title="Date">{date_last_done && formatDate(date_last_done)}</td>
            <td data-title="Type">{getActivityTypeText(type)}</td>
            <td data-title="Title"><Link
              to={`/page/activities/${id}/`}
              className="underlined" target="_blank"
            >{title}</Link></td>
            <td className="print-hide">
              {isDemoMode ? (
                <button className="btn btn-default small" onClick={() => this.alertEvidenceDocsHidden()} >
                  &#8675; Evidence &amp; Docs
                </button>
              ) : (
                this.renderExpandHideEvidence(activity)
              )}
            </td>
          </tr>
        );
      });
  }

  setSortBy(_sortBy) {
    const { sortByReversed, sortBy } = this.props;
    const shouldReverse = _sortBy === sortBy && !sortByReversed;
    this.props.setSortBy(COMPLIANCE_ACTIVITIES, _sortBy);
    this.props.setSortByReversed(COMPLIANCE_ACTIVITIES, shouldReverse);
  }

  renderSortableHeader(title, _sortBy, constrainWidth = true) {
    const { sortBy, sortByReversed } = this.props;
    return (
      <SortableHeader
        className={constrainWidth ? 'table-col-sm' : ''}
        sort={{
          reversed: sortByReversed,
          sorted: sortBy === _sortBy,
          setSorted: () => this.setSortBy(_sortBy),
        }}
      >
        {title}
      </SortableHeader>
    );
  }

  render() {
    if (this.props.isLoading) {
      return <Loading />;
    }
    const { activeTab, sortBy, sortByReversed, section } = this.props;

    const searchedActivityIds = section.get('activities').map(activity => activity.get('id'));
    const activities = this.props.activities.filter(activity => searchedActivityIds.includes(activity.get('id')));

    const sortedActivities = activities.sortBy(activity => activity.get(sortBy));
    const finalSortedActivities = sortByReversed ? sortedActivities.reverse() : sortedActivities;
    const expandedRowIdx = activeTab && finalSortedActivities.findIndex(activity => this.isExpanded(activity));
    const rows = this.renderRows(finalSortedActivities);

    if (this.props.hasFailed) {
      return (
        <div className="mb-1">
          <p className="text-error">Failed to load activities.</p>
        </div>
      );
    }

    if (this.props.hasSucceeded && activities.isEmpty()) {
      return (
        <div className="mb-1">
          <p>No activities found.</p>
        </div>
      );
    }

    return (
      <table className="responsive padded padded-sm ml-1">
        <thead className="light">
          <tr>
            {this.renderSortableHeader('Status', 'status')}
            {this.renderSortableHeader('Date Last Done', 'date_last_done')}
            {this.renderSortableHeader('Type', 'type')}
            {this.renderSortableHeader('Title', 'title', false)}
            <th className="table-col-md print-hide" />
          </tr>
        </thead>
        <tbody>
          {activeTab && expandedRowIdx > -1
            ? rows.insert(expandedRowIdx + 1, this.renderExpandedRow())
            : rows}
        </tbody>
      </table>
    );
  }
}

export function mapStateToProps(state, props) {
  const collection = getCollectionName(props.id);
  const response = state.responses.getIn(['getCollection', collection]);
  return {
    collection,
    isVisible: !state.ui.getIn(['collapse', props.id]),
    isLoading: isPending(response),
    hasFailed: hasFailed(response),
    hasSucceeded: hasSucceeded(response),
    activities: state.collections.getIn([collection, 'items'], List()),
    isDemoMode: isDemoUser(state.userProfile),
    activeTab: state.ui.getIn(['activeTabs', COMPLIANCE_ACTIVITIES]),
    sortBy: state.ui.getIn(['sortBy', COMPLIANCE_ACTIVITIES], 'type'),
    sortByReversed: state.ui.getIn(['sortByReversed', COMPLIANCE_ACTIVITIES]),
  };
}

export default connect(
  mapStateToProps,
  { getAllCollection, clearCollection, setActiveTab, setSortBy, setSortByReversed, openDemoModeDisallowsModal }
) (ActivitiesListing);
