import { Map, List } from 'immutable';
import moment from 'moment';

export const ACTIVITY_TYPE = {
  ACTIVITY: 'ACTIVITY',
  ADMIN_STEP: 'ADMIN_STEP',
  ASSESSMENT: 'ASSESSMENT',
  AUDIT: 'AUDIT',
  COMPLIANCE_REVIEW: 'COMPLIANCE_REVIEW',
  INSPECTION_MEETING: 'INSPECTION_MEETING',
  MANAGEMENT_MEETING: 'MANAGEMENT_MEETING',
  PRACTICE_MEETING: 'PRACTICE_MEETING',
  TRAINING_MEETING: 'TRAINING_MEETING',
  TASK: 'TASK',
  DOCUMENT_REVIEW: 'DOCUMENT_REVIEW',
};

export const ACTIVITY_STATUSES = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  OVERDUE: 'OVERDUE',
  NOT_STARTED: 'NOT_STARTED',
};

export const DOCUMENT_REVIEW_STATUSES = {
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  SCHEDULED: 'SCHEDULED',
};

export const STATUS_ICONS = {
  [ACTIVITY_STATUSES.COMPLETED]: 'check',
  [ACTIVITY_STATUSES.IN_PROGRESS]: 'hourglass-2',
  [ACTIVITY_STATUSES.OVERDUE]: 'exclamation',
};

export const STATUS_CLASSES = {
  [ACTIVITY_STATUSES.COMPLETED]: 'complete',
  [ACTIVITY_STATUSES.IN_PROGRESS]: 'inprogress',
  [ACTIVITY_STATUSES.OVERDUE]: 'outstanding',
  [ACTIVITY_STATUSES.NOT_STARTED]: 'notstarted',
};

export const STATUS_NAMES = {
  [ACTIVITY_STATUSES.COMPLETED]: 'Complete',
  [ACTIVITY_STATUSES.IN_PROGRESS]: 'In progress',
  [ACTIVITY_STATUSES.OVERDUE]: 'Outstanding',
  [ACTIVITY_STATUSES.NOT_STARTED]: 'Upcoming',
};

export const COMPLIANCE_REVIEW_LIST_ORDERING = [
  ACTIVITY_STATUSES.OVERDUE,
  ACTIVITY_STATUSES.NOT_STARTED,
  ACTIVITY_STATUSES.IN_PROGRESS,
  ACTIVITY_STATUSES.COMPLETED,
];

const activityTypeText = {
  [ACTIVITY_TYPE.ACTIVITY]: 'Activity',
  [ACTIVITY_TYPE.ADMIN_STEP]: 'Monthly Focus',
  [ACTIVITY_TYPE.ASSESSMENT]: 'Assessment',
  [ACTIVITY_TYPE.AUDIT]: 'Audit',
  [ACTIVITY_TYPE.COMPLIANCE_REVIEW]: 'Compliance review',
  [ACTIVITY_TYPE.INSPECTION_MEETING]: 'Inspection meeting',
  [ACTIVITY_TYPE.MANAGEMENT_MEETING]: 'Management meeting',
  [ACTIVITY_TYPE.PRACTICE_MEETING]: 'Practice meeting',
  [ACTIVITY_TYPE.TRAINING_MEETING]: 'Training meeting',
  [ACTIVITY_TYPE.TASK]: 'Task',
  [ACTIVITY_TYPE.DOCUMENT_REVIEW]: 'Document Review',
};

export const ACTIVITY_TYPE_FIELDS = Map({
  [ACTIVITY_TYPE.ADMIN_STEP]: 'admin_step_field',
  [ACTIVITY_TYPE.ASSESSMENT]: 'assessment_field',
  [ACTIVITY_TYPE.AUDIT]: 'audit_field',
  [ACTIVITY_TYPE.COMPLIANCE_REVIEW]: 'compliance_review_field',
  [ACTIVITY_TYPE.PRACTICE_MEETING]: 'practice_meeting_field',
});

export const ACTIVITY_TYPE_CLASSES = {
  [ACTIVITY_TYPE.ACTIVITY]: '',
  [ACTIVITY_TYPE.ADMIN_STEP]: 'capsule-admin-step',
  [ACTIVITY_TYPE.ASSESSMENT]: 'capsule-risk-assessment',
  [ACTIVITY_TYPE.AUDIT]: 'capsule-audit',
  [ACTIVITY_TYPE.COMPLIANCE_REVIEW]: 'capsule-compliance-review',
  [ACTIVITY_TYPE.INSPECTION_MEETING]: 'capsule-meeting',
  [ACTIVITY_TYPE.MANAGEMENT_MEETING]: 'capsule-meeting',
  [ACTIVITY_TYPE.PRACTICE_MEETING]: 'capsule-meeting',
  [ACTIVITY_TYPE.TRAINING_MEETING]: 'capsule-meeting',
  [ACTIVITY_TYPE.TASK]: 'capsule-task',
  [ACTIVITY_TYPE.DOCUMENT_REVIEW]: 'capsule-document-review',
};

export const CALENDAR_ACTIVITY_FILTERS = [
  ACTIVITY_TYPE.COMPLIANCE_REVIEW,
  ACTIVITY_TYPE.AUDIT,
  ACTIVITY_TYPE.ASSESSMENT,
  ACTIVITY_TYPE.PRACTICE_MEETING,
  ACTIVITY_TYPE.ADMIN_STEP,
];

export const SORT_ACTIVITY_TYPE_ORDER = {
  [ACTIVITY_TYPE.ADMIN_STEP]: 0,
  [ACTIVITY_TYPE.COMPLIANCE_REVIEW]: 1,
  [ACTIVITY_TYPE.ACTIVITY]: 2,
  [ACTIVITY_TYPE.ASSESSMENT]: 3,
  [ACTIVITY_TYPE.AUDIT]: 4,
  [ACTIVITY_TYPE.INSPECTION_MEETING]: 5,
  [ACTIVITY_TYPE.MANAGEMENT_MEETING]: 6,
  [ACTIVITY_TYPE.PRACTICE_MEETING]: 7,
  [ACTIVITY_TYPE.TRAINING_MEETING]: 8,
  [ACTIVITY_TYPE.TASK]: 9,
}

export function getActivityTypeText(activityType) {
  return Map(activityTypeText).get(activityType, '');
}

export const isOverdue = activityOrTask =>
  activityOrTask.get('status', null) === ACTIVITY_STATUSES.OVERDUE;

export function documentReviewIsOverdue(documentReview) {
  if (documentReview.get('status') === DOCUMENT_REVIEW_STATUSES.COMPLETED) return false;

  const dueDate = moment(documentReview.get('scheduled_completion_date'));
  const today = moment();
  const dateDiff = today.diff(dueDate, 'days');

  return dateDiff > 0;
}

export function dueSameDay(activity) {
  const { start_date, end_date } = activity.toObject();
  return moment(end_date).startOf('day').isSame(moment(start_date).startOf('day'));
}

export const isComplete = activity =>
  activity.get('status', null) === ACTIVITY_STATUSES.COMPLETED;

export const isInProgress = activity =>
  activity.get('status', null) === ACTIVITY_STATUSES.IN_PROGRESS;

export const isNotStarted = activity =>
  activity.get('status', null) === ACTIVITY_STATUSES.NOT_STARTED;

export const AUDIT_TEMPLATE_TYPE = 'activities/setup/security-audits';

export const isLiveCycleApplicationPending = (liveApplicationProgress) =>
  liveApplicationProgress && List(['IN_PROGRESS', 'NOT_STARTED']).contains(liveApplicationProgress.get('status'));

export const hasLiveCycleApplicationFailed = (liveApplicationProgress) =>
  liveApplicationProgress && liveApplicationProgress.get('status') === 'FAILED';

export const hasLiveCycleApplicationSucceeded = (liveApplicationProgress) =>
  liveApplicationProgress && liveApplicationProgress.get('status') === 'COMPLETED';

export const canEditActivity = (activity, user) =>
  user && (
    user.get('is_admin_of_current_practice')
    || activity.getIn(['responsible_staff', 'id']) === user.getIn(['staffdetail', 'id'])
  );

export function getComplianceReviews(activity) {
  return activity.get('compliance_reviews', activity.getIn(['admin_step_field', 'compliance_reviews'], List()));
}

export function getCompletedDate(activity) {
  if (activity.get('type') === ACTIVITY_TYPE.ADMIN_STEP) {
    const complianceReviews = getComplianceReviews(activity).filter(isComplete);
    if (!complianceReviews.isEmpty()) {
      return complianceReviews.sortBy(
        complianceReview => complianceReview.get('completed_date')
      ).last().get('completed_date');
    }
  }
  return activity.get('completed_date');
}

export const linkRelatedTemplates = (activity, docId) => `/page/activities/${activity}/related-templates/${docId}/`;

export const linkAttachedDocuments = (activity, docId) => `/page/activities/${activity}/attached-documents/${docId}/`;

export const getActivityLink = (activityId) => `/page/activities/${activityId}/`;

export function sortActivitiesInCalendar(a, b) {
  if (
    a.activity.get('type') === ACTIVITY_TYPE.COMPLIANCE_REVIEW &&
    b.activity.get('type') === ACTIVITY_TYPE.COMPLIANCE_REVIEW
  ) {
    return a.activity.get('position') - b.activity.get('position');
  }

  return SORT_ACTIVITY_TYPE_ORDER[a.activity.get('type')] - SORT_ACTIVITY_TYPE_ORDER[b.activity.get('type')];
}

export const hasStats = stats =>
  stats && (stats.get('COMPLETED') > 0 || stats.get('IN_PROGRESS') > 0 || stats.get('OVERDUE') > 0);
