import React from 'react';
import { reduxForm } from 'redux-form';

import LiveButton from '^/components/app/forms/LiveButton';

export const FORM_NAME = 'chooseExistingTeamMemberForm';


export const MemberCheckboxField = ({ member, fields }) => {
  const { full_name, roles } = member.get('staffdetail').toObject();
  const id = member.get('id');
  const field = fields[id];

  const roleNames =
    roles
      .map(role => role.get('name'));

  const rolesDisplay = !roleNames.isEmpty() ? ' - ' + roleNames.join(', ') : '';

  return (
    <div key={id} className="label-stacked">
      <input type="checkbox" id={id} {...field} />
      <label htmlFor={id}>
        {full_name + rolesDisplay}
      </label>
    </div>
  );
};

export const ChooseExistingTeamMemberForm = ({ fields, handleSubmit, response, members }) =>
  <form onSubmit={handleSubmit}>

    <div className="p-1 form-group">
      {members.map(member =>
        <MemberCheckboxField
          key={member.get('id')}
          member={member}
          fields={fields}
        />
      )}
    </div>

    <div className="form-group">
      <div className="col-1">
        <LiveButton pendingMessage="Saving..." response={response} className="btn-default">
          Add
        </LiveButton>
      </div>
    </div>

  </form>;


export default reduxForm({form: FORM_NAME})(ChooseExistingTeamMemberForm);
