import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';


function diffMonths(month, date) {
  return moment(month).startOf('month').diff(moment(date).startOf('month'), 'months');
}

const startOfMonth = date => moment(date).startOf('month');

export const startsBefore = month => cycle =>
  startOfMonth(month).isSameOrAfter(startOfMonth(cycle.get('start_date')));

export const CycleMonth = ({month, cycles, showCycleNumber}) => {
  if (!cycles || !cycles.size || !month) { return <noscript />; }

  const cyclesByDate = cycles.sortBy(cycle => cycle.get('start_date'));
  const lastLiveCycleIndex = cyclesByDate.findLastIndex(startsBefore(month));

  if (lastLiveCycleIndex === -1) { return <noscript />; }

  const liveCycle = cyclesByDate.get(lastLiveCycleIndex);
  const cycleMonthIdx = diffMonths(month, liveCycle.get('start_date'));

  return (
    <span className="light-text">
      {showCycleNumber && <span>Cycle {lastLiveCycleIndex + 1}</span>} Month {cycleMonthIdx + 1}
    </span>
  );
};

CycleMonth.propTypes = {
  month: React.PropTypes.any.isRequired,
};

export function mapStateToProps(state) {
  const currentPractice = state.currentPractice;

  return {
    cycles: currentPractice && currentPractice.get('cycles'),
  };
}

export default connect(mapStateToProps) (CycleMonth);
