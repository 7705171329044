import React from 'react';

import { getGroupsTabConfig, TABS } from './tabConfig';
import Tab from './Tab';
import classNames from 'classnames';
import Collapse from '^/components/app/content/Collapse';
import ShowIfAdmin from '^/components/app/perms/ShowIfAdmin';
import ShowIfApplication from '^/components/app/perms/ShowIfApplication';
import Icon from '^/components/app/content/Icon';
import { TABS_PATH } from '^/components/app/nav/constants';

export const NAV_ID = 'NAV_ID';

function tabVisibility(
  applicationOnly,
  adminOnly,
  tab,
  key,
  hasAccessToDigitalTools,
  hasAccessToReports
) {
  const showDigitalTool =
    hasAccessToDigitalTools || tab.key !== TABS_PATH.DIGITAL_TOOLS;
  const showReports = hasAccessToReports || tab.key !== TABS_PATH.REPORTS;
  const _tab = adminOnly ? <ShowIfAdmin key={key}>{tab}</ShowIfAdmin> : tab;
  return (
    showDigitalTool &&
    showReports &&
    (applicationOnly ? (
      <ShowIfApplication key={key}>{_tab}</ShowIfApplication>
    ) : (
      _tab
    ))
  );
}

const tabs = (
  isOnGroupAdminPage,
  hasGroupDocumentsAccess,
  hasAccessToGroupConfiguration,
  hasGroupNewsTriageAccess,
  hasGroupPracticeInteractionReportAccess
) => {
  if (isOnGroupAdminPage) {
    return getGroupsTabConfig(
      hasGroupDocumentsAccess,
      hasAccessToGroupConfiguration,
      hasGroupNewsTriageAccess,
      hasGroupPracticeInteractionReportAccess
    );
  }
  return TABS;
};

const Tabs = props => (
  <div className="nav-links">
    <div className="wrapper">
      <div className="row">
        <nav
          className={classNames(
            'nav-primary col-1',
            props.navDisabled ? 'disabled' : ''
          )}
        >
          <Collapse collapseId={NAV_ID}>
            <ul>
              {tabs(
                props.isOnGroupAdminPage,
                props.hasGroupDocumentsAccess,
                props.hasAccessToGroupConfiguration,
                props.hasGroupNewsTriageAccess,
                props.hasGroupPracticeInteractionReportAccess
              ).map(
                ({
                  path,
                  label,
                  counter,
                  applicationOnly,
                  adminOnly,
                  iconType,
                  iconClassName,
                }) => {
                  const key = path;
                  const practiceIdPath =
                    path === 'practices' && props.practiceId
                      ? `${props.practiceId}/`
                      : '';
                  const tab = (
                    <Tab key={key} link={`/page/${path}/${practiceIdPath}`}>
                      {label}
                      {iconType && (
                        <Icon type={iconType} className={iconClassName} />
                      )}
                      {counter && props[counter] > 0 && (
                        <div className="nav-primary-notification">
                          {props[counter]}
                        </div>
                      )}
                    </Tab>
                  );
                  const hasAccessToDigitalTools = props.hasAccessToDigitalTools;
                  const hasAccessToReports = props.hasAccessToReports;
                  return tabVisibility(
                    applicationOnly,
                    adminOnly,
                    tab,
                    key,
                    hasAccessToDigitalTools,
                    hasAccessToReports
                  );
                }
              )}
            </ul>
          </Collapse>
        </nav>
      </div>
    </div>
  </div>
);
export default Tabs;
