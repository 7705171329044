import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';

import { getAllCollection } from '^/actions/collections';
import { changeFileFolderAndCloseModal } from '^/actions/actionSequences';
import ChangeFileFolderForm from '^/components/app/activities/documents/modals/ChangeFileFolderForm';
import DemoModeMayDisallow from '^/components/app/perms/DemoModeMayDisallow';
import PureComponent from '^/components/common/PureComponent';
import { TEMPLATE_FOLDERS } from '^/consts/collectionKeys';

const UPLOAD_DOCUMENTS_MODAL = 'UPLOAD_DOCUMENTS_MODAL';

export class ChangeUploadedTaskFileFolderModal extends PureComponent {
  componentWillMount() {
    this.props.getAllCollection(TEMPLATE_FOLDERS, {}, UPLOAD_DOCUMENTS_MODAL);
  }

  render () {
    const { response, activityTaskId, fileId, folders, currentFolderId, } = this.props;
    return (
      <DemoModeMayDisallow message="cannot change folder" response={response}>
        <ChangeFileFolderForm
          response={response}
          folders={folders}
          initialValues={{folder: currentFolderId}}
          onSubmit={(data) => this.props.changeFileFolderAndCloseModal(activityTaskId, fileId, data)}
        />
      </DemoModeMayDisallow>
    );
  }
}

export const mapStateToProps = (state) => ({
  response: state.responses.get('uploadActivityTaskAssigneeIdFile'),
  folders: state.collections.getIn([UPLOAD_DOCUMENTS_MODAL, 'items'], List()),
});

ChangeUploadedTaskFileFolderModal.propTypes = {
  fileId: React.PropTypes.string.isRequired,
  activityTaskId: React.PropTypes.string.isRequired,
  currentFolderId: React.PropTypes.string,
  changeFileFolderAndCloseModal: React.PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  {
    getAllCollection,
    changeFileFolderAndCloseModal,
  }
) (ChangeUploadedTaskFileFolderModal);
