import React from 'react';
import { Link } from 'react-router';

const MaybeLink = props =>
  props.to ? (
    <Link {...props}>{props.children}</Link>
  ) : (
    <span {...props}>{props.children}</span>
  );

export default MaybeLink;
