import React from 'react';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import SortIcon from './SortIcon';


export default function Table({
  className,
  columns,
  rows,
  groupBy,
  emptyMessage,
  renderHeaders,
  renderRow,
  noHeader,
  sortBy,
  sortedBy,
  isSortAscending,
}) {
  const _columns = columns && columns.filterNot(column => column.get && column.get('groupBy'));

  const _renderRow = (row, rowIdx, key = '') =>
    renderRow ? (
      renderRow(row, rowIdx, _columns, key)
    ) : (
      <tr key={key + rowIdx}>
        {_columns.map((column, idx) =>
          <td data-title={column.header} key={idx}>
            {column.value(row, rowIdx)}
          </td>
        )}
      </tr>
    );

  return (
    <table className={className}>
      {!noHeader && (
      <thead>
        <tr>
          {renderHeaders ? (
            renderHeaders(_columns)
          ) : (
            _columns.map((column, idx) =>
              <th key={idx}>
                {column.header}
                {column.sortKey && typeof sortBy === 'function' && (
                  <SortIcon
                    itemKey={column.sortKey}
                    isSorted={column.sortKey === sortedBy}
                    isAscending={isSortAscending}
                    onClick={sortBy}
                  />
                )}
              </th>
            )
          )}
        </tr>
      </thead>
      )}
      <tbody>
        {groupBy ? (
          rows.groupBy(row => row.getIn(groupBy.get('name'))).map((group, key) =>
            List.of(
              <tr><td colSpan={_columns.size}><b>{key}</b></td></tr>
            ).concat(
              group.map((row, rowIdx) => _renderRow(row, rowIdx, key))
            )
          ).valueSeq().flatten(1)
        ) : (
          rows.map((row, rowIdx) => _renderRow(row, rowIdx, row.get('id')))
        )}
        {rows.isEmpty() && emptyMessage && (
          <tr>
            <td colSpan={_columns.size}>
              {emptyMessage}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  className: React.PropTypes.string,
  columns: ImmutablePropTypes.list,
  rows: ImmutablePropTypes.iterable.isRequired,
  emptyMessage: React.PropTypes.string,
  renderHeaders: React.PropTypes.func,
  renderRow: React.PropTypes.func,
  noHeader: React.PropTypes.bool,
  groupBy: ImmutablePropTypes.map,
  sortBy: React.PropTypes.func,
  sortedBy: React.PropTypes.string,
  isSortAscending: React.PropTypes.bool,
};
