import React from 'react';
import { connect } from 'react-redux';
import PureComponent from '^/components/common/PureComponent';
import { storeImageStyle } from '^/actions/actions';

export class Img extends PureComponent {
  getClassName() {
    let className = 'image';
    if (this.props.circular) {
      className = className.concat(' circular');
    } else if (this.props.square) {
      className = className.concat(' square');
    }

    return className;
  }

  toPercentage(value) {
    return (value * 100).toString().concat('%');
  }

  getStylesForLandscapeImage(aspect) {
    return {
      width: '100%',
      height: this.toPercentage(aspect),
      left: 0,
      top: this.toPercentage((1 - aspect) / 2)
    };
  }

  getStylesForPortraitImage(aspect) {
    return {
      height: '100%',
      width: this.toPercentage(aspect),
      top: 0,
      left: this.toPercentage((1 - aspect) / 2)
    };
  }

  setImageSize() {
    if (this.props.circular || this.props.square) {
      const width = this.refs.image.width;
      const height = this.refs.image.height;

      let style;

      if (this.props.contain) {
        if (width >= height) {
          style = this.getStylesForLandscapeImage(height / width);
        } else {
          style = this.getStylesForPortraitImage(width / height);
        }
      } else if (this.props.cover) {
        if (width >= height) {
          style = this.getStylesForPortraitImage(width / height);
        } else {
          style = this.getStylesForLandscapeImage(height / width);
        }
      }

      if (style) {
        this.props.storeImageStyle(
          this.generateIdent(),
          style
        );
      }
    }
  }

  generateIdent() {
    let containOrCover;

    if (this.props.contain || this.props.cover) {
      containOrCover = this.props.contain ? 'contain' : 'cover';
    } else {
      containOrCover = null;
    }

    return [this.props.src || '', containOrCover].join(':');
  }

  componentDidMount() {
    this.refs.image.onload = this.setImageSize.bind(this);
  }

  componentWillUnmount() {
    this.refs.image.onload = null;
  }

  render() {
    const style = this.props.imageStyles.get(this.generateIdent());

    return (
      <div className={this.getClassName()} >
        <div className="image-wrapper">
          <img {...this.props} ref="image" src={this.props.src} style={style} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    imageStyles: state.ui.get('imageStyles')
  };
}

export default connect(mapStateToProps, {storeImageStyle})(Img);
