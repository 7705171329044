import makeRsaAction from '^/middleware/makeRsaAction';

import { makeAsyncActionSet } from '^/actions/utils';
import { deleteItem } from '^/actions/collections';
import makeActionChain from '^/actions/makeActionChain';
import { ALERTS } from '^/consts/collectionKeys';

export const LOAD_DASHBOARD_ACTIVITY_AND_UPDATED_TEMPLATES = makeAsyncActionSet('LOAD_DASHBOARD_ACTIVITY_AND_UPDATED_TEMPLATES');
export function loadDashboardActivitiesAndUpdatedTemplates(practiceId, user) {
  return makeRsaAction(LOAD_DASHBOARD_ACTIVITY_AND_UPDATED_TEMPLATES, `/activities/dashboard-activity/${practiceId}/`, 'GET', user && { user });
}

export const DECREMENT_UPDATED_NEW_TEMPLATE_ALERTS_COUNT = 'DECREMENT_UPDATED_NEW_TEMPLATE_ALERTS_COUNT';
export function decrementUpdatedNewTemplateAlertsCount() {
  return { type: DECREMENT_UPDATED_NEW_TEMPLATE_ALERTS_COUNT };
}

export const DOCUMENT_REVIEWS_COUNT = makeAsyncActionSet('DOCUMENT_REVIEWS_COUNT');
export function loadDocumentReviewsCount() {
  return makeRsaAction(
    DOCUMENT_REVIEWS_COUNT,
    `/document-reviews/count/`,
    'GET',
    null,
  );
}

export function dismissAlert(id) {
  return makeActionChain([
    () => deleteItem(ALERTS, id),
    () => decrementUpdatedNewTemplateAlertsCount()
  ]);
}
