import React from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { getDocumentLink, getUpdateLink, getGroupDocumentLink } from '^/models/documents';
import PureComponent from '^/components/common/PureComponent';


export const NUM_COLS = 2;

export default class AlertList extends PureComponent {
  updateLink(alert) {
    const { isGroupsApp, isGroupAdmin, isReadOnlyUser } = this.props;
    const {
      template_document_version: {
        document: { id: doc_id, practice_group_creator },
        group_template_document,
        adopted_version,
        group_version,
      }
    } = alert.toJS();

    //adopted_version or group_version can be null
    const adopted_version_id = adopted_version && adopted_version.id;
    const adopted_version_number = adopted_version && adopted_version.full_version_number;
    const group_version_id = group_version && group_version.id;
    const group_version_number = group_version && group_version.full_version_number;

    if(!isGroupsApp){
      if(adopted_version_id && group_version_id && adopted_version_number && group_version_number) {
        //if version numbers are the same do not attempt to update
        if(adopted_version_number === group_version_number) {
          return getDocumentLink(adopted_version_id);
        }
  
        //if not, then allow a non read only user to perform the update
        if(!isReadOnlyUser){
          return getUpdateLink(adopted_version_id, isGroupsApp);
        }
      }
  
      if (group_template_document && adopted_version_id) {
        return isReadOnlyUser ?
          getDocumentLink(adopted_version_id) :
          getUpdateLink(adopted_version_id, isGroupsApp);
      }
    }

    if (group_version_id && !practice_group_creator) {
      //Do not use an update link if the document does not need updating
      //In this case adopted_version_number will be ==== ...get_latest_version_for(practice).full_version_id
      if(!isGroupsApp && group_version_number && group_version_number === adopted_version_number){
        return getDocumentLink(group_version_id);
      }

      return isGroupAdmin ?
        getUpdateLink(group_version_id, isGroupsApp) :
        getDocumentLink(group_version_id);
    }

    if (adopted_version_id) {
      return isReadOnlyUser ?
        getDocumentLink(adopted_version_id) :
        getUpdateLink(adopted_version_id, isGroupsApp);
    }

    return isGroupAdmin ?
      getGroupDocumentLink(doc_id, isGroupsApp) :
      getDocumentLink(doc_id);
  }

  render() {
    const { alerts, dismissAlert, hasMoreAlerts } = this.props;

    if ((!alerts || alerts.isEmpty()) && !hasMoreAlerts) {
      return (
        <div className="help-block mb-1">
          No updated or new documents.
        </div>
      );
    }

    const alertGroups = alerts.groupBy((each, idx) => Math.floor(idx / Math.round(alerts.size / NUM_COLS)));

    return (
      <div>
        <div className="row clearfix">
          {alertGroups.map((alertGroup, groupIdx) => {
            return (
              <div key={groupIdx} className="col-1-2">
                <table className="task-list">
                  <tbody>
                    {alertGroup.map(alert => {
                      const {
                        id,
                        created,
                        template_document_version
                      } = alert.toJS();
                      if (template_document_version) {
                        const {
                          document: { name: doc_name, help_text },
                          full_version_number,
                          is_new_document
                        } = template_document_version;
                        return (
                          <tr key={id}>
                            <td>
                              <span className="task-list-date outstanding">
                                {is_new_document ? 'NEW!' : 'UPDATED'}
                              </span>
                            </td>
                            <td>
                              <span className="task-list-date grey">{moment(created).format('Do MMM')}</span>
                            </td>
                            <td>
                              <Link
                                className="task-list-title text-underlined-on-hover"
                                to={this.updateLink(alert)}
                                title={help_text}
                              >
                                {doc_name} v{full_version_number}
                              </Link>
                            </td>
                            <td>
                              <a className="inline-link" onClick={() => dismissAlert(id)}>
                                Dismiss
                              </a>
                            </td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
        { hasMoreAlerts &&
          <a className="inline-link" onClick={this.props.onLoadMore}>
            See older alerts...
          </a>
        }
      </div>
    );
  }
}
