import { createSelector } from 'reselect';

import { getCurrentPractice } from '^/models/user';
import { selectLoggedInUser } from '^/selectors/user';

export const selectCurrentPractice = createSelector(
  selectLoggedInUser,
  getCurrentPractice
);

export const selectCurrentPracticeName = createSelector(
  selectCurrentPractice,
  currentPractice => currentPractice && currentPractice.get('name')
);

export const selectCurrentPracticeId = createSelector(
  selectCurrentPractice,
  currentPractice => currentPractice && currentPractice.get('id')
);

export const selectCurrentPracticeSector = createSelector(
  selectCurrentPractice,
  currentPractice => currentPractice && currentPractice.get('sector')
);
