import * as actions from '^/actions/actions';

export function clearAllOnLogout(state = {}, action) {
  switch (action.type) {
    case actions.LOGIN.REQUEST:
      return {rememberedLocation: state.rememberedLocation}
    case actions.LOGOUT.SUCCESS:
    case actions.RESET_DEMO.SUCCESS:
      return {};
    default:
      return state;
  }
}
