import { fromJS, Map, List } from 'immutable';
import moment from 'moment';
import { getCurrentPracticeId, isAdmin } from '^/models/user';
import { hasPracticeGroup } from '^/models/practice';

export const USER_SETUP_STAGES = {
  PERSONAL_DETAILS: 'PERSONAL_DETAILS',
  COMPLETED: 'COMPLETED',
};

export const PRACTICE_SETUP_STAGES = {
  PRACTICE_DETAILS: 'PRACTICE_DETAILS',
  TEAM_MEMBERS: 'TEAM_MEMBERS',
  COMPLETED: 'COMPLETED'
};

export const CYCLE_SETUP_STAGES = {
  CYCLE_RESPONSIBILITIES: 'CYCLE_RESPONSIBILITIES',
  REMINDERS: 'REMINDERS',
  COMPLIANCE_REVIEWS: 'COMPLIANCE_REVIEWS',
  SECURITY_AUDITS: 'SECURITY_AUDITS',
  RISK_ASSESSMENTS: 'RISK_ASSESSMENTS',
  MEETING_DAYS: 'MEETING_DAYS',
  COMPLETED: 'COMPLETED',
  SUGGESTED_TASKS: 'SUGGESTED_TASKS',
  SUGGESTED_GROUP_TASKS: 'SUGGESTED_GROUP_TASKS',

  PRACTICE_COMPLIANCE_DETAILS: 'PRACTICE_COMPLIANCE_DETAILS',
  OPTIONAL_AUDITS: 'OPTIONAL_AUDITS',
  REVIEW_RISK_ASSESSMENTS: 'REVIEW_RISK_ASSESSMENTS',
  REVIEW_MEETING_DAYS: 'REVIEW_MEETING_DAYS',
  REVIEW_TASKS: 'REVIEW_TASKS',
  REVIEW_GROUP_TASKS: 'REVIEW_GROUP_TASKS',
  ADDITIONAL_COMPLIANCE_REVIEWS: 'ADDITIONAL_COMPLIANCE_REVIEWS',
};

const BASE_SUBSEQUENT_CYCLE_SETUP_STAGES_TO_COMPLETE = fromJS([
  CYCLE_SETUP_STAGES.PRACTICE_COMPLIANCE_DETAILS,
  CYCLE_SETUP_STAGES.OPTIONAL_AUDITS,
  CYCLE_SETUP_STAGES.REVIEW_RISK_ASSESSMENTS,
  CYCLE_SETUP_STAGES.REVIEW_MEETING_DAYS,
  CYCLE_SETUP_STAGES.REVIEW_TASKS,
  CYCLE_SETUP_STAGES.REVIEW_GROUP_TASKS,
  CYCLE_SETUP_STAGES.ADDITIONAL_COMPLIANCE_REVIEWS
]);

export const SUBSEQUENT_SETUP_URLS = (cycleId) => fromJS({
  [CYCLE_SETUP_STAGES.PRACTICE_COMPLIANCE_DETAILS]: `/page/setup-cycle/${cycleId}/practice-details/`,
  [CYCLE_SETUP_STAGES.OPTIONAL_AUDITS]:  `/page/setup-cycle/${cycleId}/optional-audits/`,
  [CYCLE_SETUP_STAGES.REVIEW_RISK_ASSESSMENTS]:  `/page/setup-cycle/${cycleId}/risk-assessments/`,
  [CYCLE_SETUP_STAGES.REVIEW_MEETING_DAYS]:  `/page/setup-cycle/${cycleId}/meeting-days/`,
  [CYCLE_SETUP_STAGES.REVIEW_TASKS]:  `/page/setup-cycle/${cycleId}/review-tasks/`,
  [CYCLE_SETUP_STAGES.REVIEW_GROUP_TASKS]:  `/page/setup-cycle/${cycleId}/review-group-tasks/`,
  [CYCLE_SETUP_STAGES.ADDITIONAL_COMPLIANCE_REVIEWS]: `/page/setup-cycle/${cycleId}/compliance-reviews/`,
});

export const SETUP_URLS = fromJS({
  [USER_SETUP_STAGES.PERSONAL_DETAILS]: '/page/set-up/personal-details/',
  [PRACTICE_SETUP_STAGES.PRACTICE_DETAILS]: '/page/set-up/practice-details/',
  [PRACTICE_SETUP_STAGES.TEAM_MEMBERS]: '/page/set-up/team-members/',
  [CYCLE_SETUP_STAGES.CYCLE_RESPONSIBILITIES]: '/page/set-up/cycle-responsibilities/',
  [CYCLE_SETUP_STAGES.SUGGESTED_TASKS]: '/page/set-up/suggested-task/',
  [CYCLE_SETUP_STAGES.SUGGESTED_GROUP_TASKS]: '/page/set-up/group-tasks/',
  [CYCLE_SETUP_STAGES.COMPLIANCE_REVIEWS]: '/page/set-up/compliance-reviews/',
  [CYCLE_SETUP_STAGES.SECURITY_AUDITS]: '/page/set-up/audits/',
  [CYCLE_SETUP_STAGES.RISK_ASSESSMENTS]: '/page/set-up/risk-assessments/',
  [CYCLE_SETUP_STAGES.MEETING_DAYS]: '/page/set-up/meeting-days/',
});

export const SETUP_STAGE_TEXT = fromJS({
  [USER_SETUP_STAGES.PERSONAL_DETAILS]: 'Personal Details',
  [PRACTICE_SETUP_STAGES.PRACTICE_DETAILS]: 'Practice Details',
  [PRACTICE_SETUP_STAGES.TEAM_MEMBERS]: 'Team Members',
  [CYCLE_SETUP_STAGES.CYCLE_RESPONSIBILITIES]: 'Cycle Responsibilites',
  [CYCLE_SETUP_STAGES.SUGGESTED_TASKS]: 'Suggested Tasks',
  [CYCLE_SETUP_STAGES.SUGGESTED_GROUP_TASKS]: 'Group Tasks',
  [CYCLE_SETUP_STAGES.COMPLIANCE_REVIEWS]: 'Compliance Reviews',
  [CYCLE_SETUP_STAGES.SECURITY_AUDITS]: 'Audits',
  [CYCLE_SETUP_STAGES.RISK_ASSESSMENTS]: 'Assessments',
  [CYCLE_SETUP_STAGES.MEETING_DAYS]: 'Meeting Days',

  [CYCLE_SETUP_STAGES.PRACTICE_COMPLIANCE_DETAILS]: 'Practice Compliance Details',
  [CYCLE_SETUP_STAGES.OPTIONAL_AUDITS]: 'Review Audits',
  [CYCLE_SETUP_STAGES.REVIEW_RISK_ASSESSMENTS]: 'Review Assessments',
  [CYCLE_SETUP_STAGES.REVIEW_MEETING_DAYS]: 'Review Meeting Days',
  [CYCLE_SETUP_STAGES.REVIEW_TASKS]: 'Review Tasks',
  [CYCLE_SETUP_STAGES.REVIEW_GROUP_TASKS]: 'Review Group Tasks',
  [CYCLE_SETUP_STAGES.ADDITIONAL_COMPLIANCE_REVIEWS]: 'Additional Compliance Reviews',
});

const BASE_USER_ACCOUNT_STAGES_TO_COMPLETE = fromJS([
  USER_SETUP_STAGES.PERSONAL_DETAILS,
  PRACTICE_SETUP_STAGES.PRACTICE_DETAILS,
  PRACTICE_SETUP_STAGES.TEAM_MEMBERS,
  CYCLE_SETUP_STAGES.CYCLE_RESPONSIBILITIES,
  CYCLE_SETUP_STAGES.SUGGESTED_TASKS,
  CYCLE_SETUP_STAGES.SUGGESTED_GROUP_TASKS,
  CYCLE_SETUP_STAGES.COMPLIANCE_REVIEWS,
  CYCLE_SETUP_STAGES.SECURITY_AUDITS,
  CYCLE_SETUP_STAGES.RISK_ASSESSMENTS,
  CYCLE_SETUP_STAGES.MEETING_DAYS,
]);

export function getUserAccountStagesToComplete(practice) {
  if (practice && hasPracticeGroup(practice)) {
    return BASE_USER_ACCOUNT_STAGES_TO_COMPLETE;
  }
  return BASE_USER_ACCOUNT_STAGES_TO_COMPLETE.filter(setupStage => setupStage !== CYCLE_SETUP_STAGES.SUGGESTED_GROUP_TASKS);
}

export function getSubsequentCyclesSetupStagesToComplete(practice) {
  if (practice && hasPracticeGroup(practice)) {
    return BASE_SUBSEQUENT_CYCLE_SETUP_STAGES_TO_COMPLETE;
  }
  return BASE_SUBSEQUENT_CYCLE_SETUP_STAGES_TO_COMPLETE.filter(setupStage => setupStage !== CYCLE_SETUP_STAGES.REVIEW_GROUP_TASKS);
}

export const getUserAccountStages = (practice) => getUserAccountStagesToComplete(practice).push(CYCLE_SETUP_STAGES.COMPLETED);
export const getCycleStages = (practice) => getSubsequentCyclesSetupStagesToComplete(practice).push(CYCLE_SETUP_STAGES.COMPLETED);

export const userPracticesSetupData = (user) =>
  (user && user.getIn(['setup_stages', 'practices'])) || List();

export const getPracticeSetupData = (user, practiceId) =>
  userPracticesSetupData(user)
    .find(each => each.get('id') === practiceId, null, Map());

export const getCycleToSetup = (user, practiceId) =>
  getPracticeSetupData(user, practiceId)
    .get('cycles', List())
    .find(each => each.get('setup_stage') !== CYCLE_SETUP_STAGES.COMPLETED, null, Map());

export const getCycleToSetupId = (user, practiceId) =>
  getCycleToSetup(user, practiceId).get('id');

export function getSetupStage(user) {
  if (!user || user.isEmpty() || isAdmin(user)) { return null; }

  const userSetupStage = user.getIn(['setup_stages', 'user']);

  if (userSetupStage !== USER_SETUP_STAGES.COMPLETED) {
    return userSetupStage || USER_SETUP_STAGES.PERSONAL_DETAILS;
  }

  const currentPracticeId = getCurrentPracticeId(user);
  const currentPracticeSetupData = getPracticeSetupData(user, currentPracticeId);

  if (currentPracticeSetupData.get('setup_stage') !== PRACTICE_SETUP_STAGES.COMPLETED) {
    return currentPracticeSetupData.get('setup_stage') || PRACTICE_SETUP_STAGES.PRACTICE_DETAILS;
  }

  if (user.getIn(['staffdetail', 'current_practice', 'is_demo_calendar_only'])) {
    return null;
  }

  if (!currentPracticeSetupData.get('cycles', List()).count()) {
    return CYCLE_SETUP_STAGES.CYCLE_RESPONSIBILITIES;
  }

  return getCycleToSetup(user, currentPracticeId).get('setup_stage');

}

export function getSetupUrl(user) {
  return SETUP_URLS.get(getSetupStage(user));
}

export const shouldSetupNextCycle = (user, practiceId) =>
  user &&
  getPracticeSetupData(user, practiceId)
    .get('should_setup_next_cycle', false);

export const nextCycleSetupComplete = (user, practiceId) => {
  const practiceSetupData = user && getPracticeSetupData(user, practiceId);
  return !practiceSetupData.get('should_setup_next_cycle', false) &&
    !!practiceSetupData
      .get('cycles', List())
      .find(
        each =>
          each.get('setup_stage') === CYCLE_SETUP_STAGES.COMPLETED &&
          each.get('start_date') &&
          moment().isBefore(moment(each.get('start_date')))
      );
};

export const isOnSetupStage = (stage, path) => path === SETUP_URLS.get(stage);

export function getSubsequentCycleSetupStageUrl(user, practiceId) {
  if (!user) {
    return null;
  }
  const setupStage = getCycleToSetup(user, practiceId).get('setup_stage');
  const cycleId = getCycleToSetupId(user, practiceId);
  const cycles = getPracticeSetupData(user, practiceId).get('cycles', List());

  if(cycles.length === 0) {
    return SETUP_URLS.get(CYCLE_SETUP_STAGES.CYCLE_RESPONSIBILITIES);
  }

  return cycleId && (SUBSEQUENT_SETUP_URLS(cycleId).get(setupStage) ? 
    SUBSEQUENT_SETUP_URLS(cycleId).get(setupStage) : SETUP_URLS.get(setupStage));
}

export function getNextCycleStartDate(user, practiceId) {
  return moment(
    user && getPracticeSetupData(user, practiceId).get('next_cycle_start_date')
  );
}

export function getMinimumNextCycleStartDate(user, practiceId) {
  return moment(
    user && getPracticeSetupData(user, practiceId).get('minimum_next_cycle_start_date')
  );
}
