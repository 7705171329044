import { List, Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { changePracticeAndNavToDashboard } from '^/actions/actionSequences';
import { getCollection } from '^/actions/collections';
import PureComponent from '^/components/common/PureComponent';
import Table from '^/components/common/content/Table';
import { GROUP_PRACTICES } from '^/consts/collectionKeys';
import { isPending, hasFailed } from '^/consts/responseStates';

import { getColumns } from './config';

const DEFAULT_PAGE = 1;
const DEFAULT_SORT = 'name';

export class PracticeList extends PureComponent {
  constructor(props) {
    super(props);
    this.getPractices = this.getPractices.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.sortPracticesBy = this.sortPracticesBy.bind(this);
    this.getEmptyMessage = this.getEmptyMessage.bind(this);
  }

  componentDidMount () {
    this.getPractices(DEFAULT_PAGE, DEFAULT_SORT);
  }

  getEmptyMessage () {
    const {
      isLoading,
      hasFailedToLoad,
    } = this.props;

    if (isLoading) {
      return 'Loading...';
    }
    return hasFailedToLoad ? 'Failed to load.' : 'No data available.';
  }

  getPractices (page, sortBy = null) {
    this.props.getCollection(
      GROUP_PRACTICES,
      {
        page,
        shouldAppend: page !== DEFAULT_PAGE,
        ...(sortBy && { filters: { ordering: sortBy }, }),
      }
    );
  }

  loadMore () {
    this.getPractices(this.props.page + 1);
  }

  sortPracticesBy(key, isAscending = true) {
    this.getPractices(1, `${isAscending ? '' : '-'}${key}`);
  }

  render () {
    const {
      practices,
      hasMore,
      sortedBy,
      isSortAscending,
    } = this.props;

    return (
      <div>
        <Table
          className="padded"
          rows={practices}
          columns={getColumns(practices, this.props.changePracticeAndNavToDashboard)}
          hasMore={hasMore}
          loadMore={this.loadMore}
          emptyMessage={this.getEmptyMessage()}
          sortBy={this.sortPracticesBy}
          sortedBy={sortedBy}
          isSortAscending={isSortAscending}
        />

        {hasMore && (
          <div className="mt-1 text-right small-text">
            <a onClick={this.loadMore}>Load more&hellip;</a>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const practiceCollection = state.collections.get(GROUP_PRACTICES) || Map();
  const filters = practiceCollection.get('filters');
  const ordering = filters && filters.ordering;
  const sortedBy = ordering && ordering.replace('-', '');
  const isSortAscending = ordering === sortedBy;
  const response = state.responses.getIn(['getCollection', GROUP_PRACTICES]);
  return {
    practices: practiceCollection.get('items') || List(),
    hasMore: practiceCollection.get('hasMore'),
    page: practiceCollection.get('page'),
    isLoading: isPending(response),
    hasFailedToLoad: hasFailed(response),
    sortedBy,
    isSortAscending,
  };
}

export default connect(
  mapStateToProps,
  { getCollection, changePracticeAndNavToDashboard }
)(PracticeList);
