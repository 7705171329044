import { fromJS } from 'immutable';

export const ADMIN_TEMPLATE_ACTIVITIES_MODEL = 'admin/activities';
export const ADMIN_TEMPLATE_ACTIVITY_HIDE_RULES_MODEL = 'admin/hide-rules';
export const ADMIN_TEMPLATE_ACTIVITY_REGION_HIDE_RULES_MODEL = 'admin/region-hide-rules';
export const ADMIN_TEMPLATE_ACTIVITIES_CYCLE_CONFIGURATION_SHOW_RULE_MODEL = 'admin/template-activity-cycle-configuration-show-rules';
export const ADMIN_TEMPLATE_ACTIVITIES_OPTIONS = 'TEMPLATE_ACTIVITY_OPTIONS';
export const ADMIN_TEMPLATE_ACTIVITY_MANAGE_ROUTE = '/admin/activities/${id}';
export const ADMIN_TEMPLATE_ACTIVITY_REGION_OVERRIDES_MODEL = 'admin/template-activity-regional-overrides';

export const ADMIN_TEMPLATE_ACTIVITIES_ROW_FIELDS = fromJS([
  {name: ['compliance_review_field', 'position'], display_name: 'Position', type: 'string', sortable: true},
  {name: ['compliance_review_field', 'is_operational'], display_name: 'Operational', type: 'boolean'},
  {name: ['name'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['sector_display'], display_name: 'Sector', type: 'string', sortable: true},
  {name: ['type_display'], display_name: 'Type', type: 'string', sortable: true},
  {name: ['dependency', 'name'], display_name: 'Dependency', type: 'string', sortable: false},
  {name: ['admin_step_display'], display_name: 'Monthly Focus', type: 'string', sortable: false},
  {name: ['is_active'], display_name: 'Active', type: 'boolean', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Manage', route: ADMIN_TEMPLATE_ACTIVITY_MANAGE_ROUTE},
]);

export const TEMPLATE_ACTIVITY_CREATE = 'TEMPLATE_ACTIVITY_CREATE';
export const TEMPLATE_ACTIVITY_PAGE_ID = 'admin/template-documents/${id}';

export const ADMIN_TEMPLATE_ACTIVITIES_CREATE_FIELDS = fromJS([
  'sector', 'name', 'type'
]);

export const ADMIN_TEMPLATE_ACTIVITIES_VIEW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string'},
  {name: ['type_display'], display_name: 'Type', type: 'string'},

  {name: ['responsibility_display'], display_name: 'Responsibility', type: 'string'},
  {name: ['is_optional'], display_name: 'Optional', type: 'boolean'},

  {name: ['audit_field', 'repetition_interval_display'], display_name: 'Repetition interval', type: 'string'},

  {name: ['compliance_review_field', 'admin_step_display'], display_name: 'Monthly focus', type: 'string'},

  {name: ['default_month_display'], display_name: 'Default month', type: 'string'},
  {name: ['period'], display_name: 'Period', type: 'string'},
  {name: ['description'], display_name: 'Description', type: 'html'},
  {name: ['dependency_display'], display_name: 'Dependency', type: 'string'},
  {name: ['suppress_alert'], display_name: 'Suppress alert', type: 'boolean'},
  {name: ['is_active'], display_name: 'Active', type: 'boolean'},
  {name: ['help_text'], display_name: 'Help text', type: 'string'},
]);

export const ADMIN_COMPLIANCE_SYSTEMS_FIELDS = fromJS([
  {name: ['root'], display_name: 'Root', type: 'string', sortable: true, groupBy: true},
  {name: ['prefix'], display_name: 'Prefix', type: 'string', sortable: true},
  {name: ['name'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['id'], display_name: 'Remove from Practice', type: 'remove', sortable: false},
]);

export const ADMIN_TEMPLATE_DOCUMENT_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'detail', route: TEMPLATE_ACTIVITY_PAGE_ID, sortable: true},
  {name: ['template_category_name'], display_name: 'Category', type: 'string', sortable: true},
  {name: ['code'], display_name: 'Code', type: 'string', sortable: true},
  {name: ['folder_display'], display_name: 'Folder Number', type: 'string', sortable: true},
  {name: ['version_number'], display_name: 'Version', type: 'string', sortable: true},
  {name: ['is_print_recommended'], display_name: 'Print', type: 'boolean', sortable: true},
  {name: ['id'], display_name: 'Remove', type: 'remove', sortable: false},
]);

export const TEMPLATE_ACTIVITY_HIDE_RULE = 'TEMPLATE_ACTIVITY_HIDE_RULE';

export const ADMIN_TEMPLATE_ACTIVITY_HIDE_RULE_ROW_FIELDS = fromJS([
  {name: ['attribute_display'], display_name: 'Attribute', type: 'string', sortable: true},
  {name: ['attribute_value'], display_name: 'Attribute Value', type: 'boolean', sortable: true},
  {name: ['id'], display_name: 'Remove', type: 'remove'},
]);

export const ADMIN_TEMPLATE_ACTIVITY_HIDE_RULE_CREATE_FIELDS = fromJS(['attribute', 'attribute_value']);

export const TEMPLATE_ACTIVITY_REGION_HIDE_RULE = 'TEMPLATE_ACTIVITY_REGION_HIDE_RULE';

export const TEMPLATE_ACTIVITY_REGION_OVERRIDES = 'TEMPLATE_ACTIVITY_REGION_OVERRIDES';

export const ADMIN_TEMPLATE_ACTIVITY_REGION_HIDE_RULE_ROW_FIELDS = fromJS([
  {name: ['region_display'], display_name: 'Region', type: 'string', sortable: true},
  {name: ['id'], display_name: 'Remove', type: 'remove'},
]);

export const ADMIN_TEMPLATE_ACTIVITY_REGION_HIDE_RULE_CREATE_FIELDS = fromJS(['region']);

export const TEMPLATE_ACTIVITY_CYCLE_CONFIGURATION_SHOW_RULE = 'CYCLE_CONFIGURATION_SHOW_RULE';

export const ADMIN_TEMPLATE_ACTIVITY_CYCLE_CONFIGURATION_SHOW_RULE_ROW_FIELDS = fromJS([
  {name: ['cycle_configuration_display'], display_name: 'Cycle configuration', type: 'string', sortable: false},
  {name: ['id'], display_name: 'Remove', type: 'remove'},
]);

export const ADMIN_TEMPLATE_ACTIVITY_CYCLE_CONFIGURATION_SHOW_RULE_CREATE_FIELDS = fromJS(['cycle_configuration']);

export const ADMIN_TEMPLATE_ACTIVITIES_EDIT_FIELDS = fromJS([
  'sector_display',
  'name',
  'responsibility',
  'is_optional',
  'compliance_review_field.is_operational',
  'priority',
  'tags',

  'audit_field.repetition_interval',

  'compliance_review_field.admin_step',
  'compliance_review_field.position',

  'default_month',
  'period',
  'description',
  'dependency',
  'suppress_alert',
  'is_active',
  'help_text',
  'keywords',
]);

export function transformRegionOverridesChoices(templateActivity, fieldConfig) {
  if (!templateActivity) {
    return fieldConfig;
  }

  const currentRegionOverrides =
    templateActivity
      .get('regional_overrides')
      .map(rule => rule.get('region'));

  // filter out any rule choices that are already applied to the document
  return fieldConfig
    .updateIn(
      ['region', 'choices'],
      choices => choices.filterNot(choice => currentRegionOverrides.includes(choice.get('value')))
    );
}

export const ADMIN_TEMPLATE_ACTIVITY_REGION_OVERRIDES_CREATE_FIELDS = fromJS(['region', 'priority']);

export const ADMIN_TEMPLATE_ACTIVITY_REGION_OVERRIDES_ROW_FIELDS = fromJS([
  {name: ['region_display'], display_name: 'Region', type: 'string', sortable: false},
  {name: ['priority_display'], display_name: 'Priority', type: 'string', sortable: false},
  {name: ['id'], display_name: 'Remove', type: 'remove'}
]);
