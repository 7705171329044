import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { Link } from 'react-router';

import { STATUS_NAMES, STATUS_CLASSES } from '^/models/activities';
import { formatDate } from '^/utils';
import { isPending } from '^/consts/responseStates';
import { getAllCollection, clearCollection } from '^/actions/collections';
import { TASKS } from '^/consts/collectionKeys';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';
import ActivityStatusIcon from '^/components/app/reports/ActivityStatusIcon';
import Table from '^/components/common/content/Table';

const TASKS_SECTION = 'tasks-section';

export class TasksListing extends PureComponent {

  componentWillMount() {
    this.props.clearCollection(TASKS);
  }

  componentWillReceiveProps(newProps) {
    const { filter: newFilter } = newProps;
    const { filter } = this.props;
    const filterChanged = newFilter && !newFilter.equals(filter);

    if (filterChanged && newFilter.get('cycle') && newFilter.get('cqc_system')) {
      this.props.getAllCollection(TASKS, {filters: newFilter.toJS()});
    }
  }

  render() {
    const { tasks: allTasks, isLoading, searchText } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    const tasks = allTasks && searchText
      ? allTasks.filter(each => each.get('title').toLowerCase().includes(searchText.toLowerCase()))
      : allTasks;

    return tasks ? (
      <div key={TASKS_SECTION} className="collapsible-section mb-2">
        <hr className="mt-2 mb-2 dark" />

        <CollapseButton collapseId={TASKS_SECTION}>
          <h2>Tasks <span className="small-text lighten-text">({tasks.size})</span></h2>
        </CollapseButton>

        <Collapse initiallyCollapsed collapseId={TASKS_SECTION}>
          <Table
            className="responsive padded padded-sm"
            columns={List([
              {
                header: 'Title',
                value: task => (
                  <Link to={`/page/tasks/manage/${task.get('id')}/`} className="underlined" target="_blank">
                    {task.get('title')}
                  </Link>
                )
              },
              {
                header: 'Assignee(s)',
                value: task => task.get('assignees').map(each => each.get('full_name')).join(', ')
              },
              {
                header: 'Due',
                value: task => formatDate(task.get('deadline'))
              },
              {
                header: 'Status',
                value: task => (
                  <span className={STATUS_CLASSES[task.get('status')] + ' text-capitalize bold-text'}>
                    <ActivityStatusIcon activity={task} className="inline-icon" /> {STATUS_NAMES[task.get('status')]}
                  </span>
                )
              },
            ])}
            rows={tasks}
            emptyMessage="No tasks in this cycle"
          />
        </Collapse>
      </div>
    ) : <noscript />;
  }
}

export function mapStateToProps(state) {
  return {
    tasks: state.collections.getIn([TASKS, 'items']),
    isLoading: isPending(state.responses.getIn(['getCollection', TASKS])),
  };
}

export default connect(mapStateToProps, { getAllCollection, clearCollection }) (TasksListing);
