import React from 'react';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';
import { MODELS } from '^/permissions/capabilities';
import { PRACTICE_ALERT_TYPES } from '^/models/alerts';

import {
  ADMIN_ALERT_CREATE,
  ADMIN_ALERT_MODEL,
  ADMIN_ALERT_EDIT_FIELDS,
  ADMIN_ALERT_ROW_FIELDS,
  ADMIN_ALERT_TYPE_FIELDS
} from './alerts';


const transformFields = (fieldConfig, fields) => fieldConfig.filter(
    (config, fieldName) =>
      !ADMIN_ALERT_TYPE_FIELDS.get(fieldName) ||
      ADMIN_ALERT_TYPE_FIELDS.get(fieldName).contains(fields.type.value || PRACTICE_ALERT_TYPES.ALL_PRACTICES)
  );


export const AlertsAdmin = () =>
  <div>
    <AdminContainer title="Alerts">
      <AdminCreate
        permsModel={MODELS.ALERT}
        controlName={ADMIN_ALERT_CREATE}
        collectionName={ADMIN_ALERT_CREATE}
        model={ADMIN_ALERT_MODEL}
        fields={ADMIN_ALERT_EDIT_FIELDS}
        transform={transformFields}
        title="Add Alert"
      />
    </AdminContainer>

    <AdminContainer>
      <AdminList
        permsModel={MODELS.ALERT}
        title="All Alerts"
        model={ADMIN_ALERT_MODEL}
        fields={ADMIN_ALERT_ROW_FIELDS}
      />
    </AdminContainer>
  </div>;

export default connect(undefined, { routePush: routeActions.push })(AlertsAdmin);
