import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';

import { getCollection } from '^/actions/collections';
import { DOCUMENT_REVIEWS } from '^/consts/collectionKeys';
import { getDocumentLink } from '^/models/documents';
import { PRIORITY_NAMES, PRIORITY_CLASSES } from '^/models/constants';
import { isPending } from '^/consts/responseStates';
import { practiceHasAccessToDocumentReviews } from '^/stateHelpers';


export class DocumentReviews extends PureComponent {
  componentWillMount() {
    if (this.props.hasDocumentsToReviewAccess) {
      this.loadDocumentsReviews();
    }
  }

  loadDocumentsReviews(currentPage=0, shouldAppend=false) {
    this.props.getCollection(
      DOCUMENT_REVIEWS,
      {
        shouldAppend,
        page: currentPage + 1,
      }
    );
  }

  render() {
    const {
      documentReviews,
      documentReviewsResponse,
      emptyMessage,
      hasMoreReviews,
      currentPage,
    } = this.props;

    if (isPending(documentReviewsResponse) || !documentReviews) {
      return <Loading />;
    }

    if (documentReviews.isEmpty()) {
      return (
        <div className="help-block mb-1">
          {emptyMessage}
        </div>
      );
    }

    return (
      <div>
        <table className='task-list'>
          <tbody>
            {documentReviews.map((documentReview, index) => {
              const documentId = documentReview.get('adopted_template_document_id');
              const documentName = documentReview.get('adopted_template_document_name');
              const scheduledDate = documentReview.get('scheduled_completion_date');
              const documentPriority = documentReview.get('priority');
    
              return (
                <tr key={index}>
                  <td>
                    <span className='task-list-title'>
                      <Link to={`${getDocumentLink(documentId)}`}>
                        {documentName}
                      </Link>
                    </span>
                  </td>
                  <td>
                    <div className={`actvities-priority-pill ${PRIORITY_CLASSES[documentPriority]}`}>
                      {PRIORITY_NAMES[documentPriority]}
                    </div>
                  </td>
                  <td>
                    <span className='task-list-date'>
                      <p className={moment() > moment(scheduledDate) && 'outstanding'}>
                        {moment(scheduledDate).format('Do MMM, YYYY')}
                      </p>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {hasMoreReviews &&
          <a className="inline-link pull-right mb-1" onClick={() => this.loadDocumentsReviews(currentPage, true)}>
            Load more &gt;&gt;
          </a>
        }
      </div>
    );
  }
};

export function mapStateToProps(state) {
  return {
    documentReviews: state.collections.getIn([DOCUMENT_REVIEWS, 'items']),
    hasMoreReviews: state.collections.getIn([DOCUMENT_REVIEWS, 'hasMore']),
    currentPage: state.collections.getIn([DOCUMENT_REVIEWS, 'page']),
    documentReviewsResponse: state.responses.getIn(['getAllCollection', DOCUMENT_REVIEWS]),
    hasDocumentsToReviewAccess: practiceHasAccessToDocumentReviews(state),
  }
}

export default connect(mapStateToProps, {
  getCollection,  
})(DocumentReviews);
