import classNames from 'classnames';
import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { removeInvite, removeSSOInvite } from '^/actions/actions';
import LiveButton from '^/components/app/forms/LiveButton';
import { INVITED_MEMBER_BUTTON_CLASSNAMES } from '^/components/app/practices/constants';

const BUTTON_CLASS_NAME = classNames(INVITED_MEMBER_BUTTON_CLASSNAMES, 'ml-1-4')
const CONFIRM_MESSAGE = 'Are you sure you want to remove this invite?';

export const RemoveInviteButton = ({response, onClick}) => (
  <LiveButton
    className={BUTTON_CLASS_NAME}
    response={response}
    successContent={(
      <button className={BUTTON_CLASS_NAME} disabled>Done!</button>
    )}
    onClick={onClick}
    loadingClassName="small-centered hide-sibling"
    hideDefaultErrorMessage
  >
    Remove
  </LiveButton>
);

function mapStateToProps(state, {practiceInviteId, ssoInviteId}) {
  return {
    response: (
      practiceInviteId ?
      state.responses.getIn(['removeInvite', practiceInviteId], Map()) :
      state.responses.getIn(['removeSSOInvite', ssoInviteId], Map())
    ),
  };
}

function mapDispatchToProps(dispatch, {practiceInviteId, ssoInviteId}) {
  return {
    onClick: () => {
      const removeInviteFn = practiceInviteId ? removeInvite : removeSSOInvite;
      const inviteId = practiceInviteId || ssoInviteId;
      if (window.confirm(CONFIRM_MESSAGE)) {
        dispatch(removeInviteFn(inviteId));
      }
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveInviteButton);
