import React from 'react';
import { Link } from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes';


export const TaskActivityDisplay = ({ activity }) =>
  <div>
    <h2>Related Activity</h2>
      <Link className="inline-link" to={`/page/activities/${activity.get('id')}/`}>
        {activity.get('title')}
      </Link>
    <hr className="thin" />
  </div>;

TaskActivityDisplay.propTypes = {
  activity: ImmutablePropTypes.map,
};

export default TaskActivityDisplay;
