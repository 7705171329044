import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { isOverdue, getActivityTypeText } from '^/models/activities';
import ActivityLink from '^/components/app/calendar/ActivityLink';
import UserCapsule from '^/components/app/calendar/UserCapsule';
import { ActivityPriority } from '^/components/app/activities/ActivityPriority';
import { ActivityTags } from '^/components/app/activities/ActivityTags';


const DashboardActivity = ({ activities }) => {
const NUM_COLS = 1;
  const activityGroups = activities.groupBy((each, idx) =>
    Math.floor(idx / Math.round(activities.size / NUM_COLS))
  );

  return (
    <div className="row clearfix">
      {activityGroups.map((activityGroup, groupIdx) => (
        <div key={groupIdx} className={classNames('col-12-12')}>
          <table className="task-list">
            <tbody>
              {activityGroup.map((activity, index) => {
                const {
                  id,
                  title,
                  priority,
                  type,
                  due_date,
                  responsible_staff,
                  tags,
                } = activity.toObject();

                return (
                  <tr key={index}>
                    <td>
                      <span className="task-list-title">
                        <ActivityLink
                          key={id}
                          activity={activity}
                          className="text-underlined-on-hover"
                        >
                          {title}
                        </ActivityLink>
                      </span>
                    </td>
                    <td>
                      {priority && <div>
                        <ActivityPriority activity={activity} />
                      </div>}
                    </td>
                    <td className="ml-1 mr-1 inline">
                      <span>
                        {getActivityTypeText(type)}
                      </span>
                    </td>
                    <td>
                      <span
                        className={classNames(
                          'task-list-date',
                          isOverdue(activity) && 'outstanding'
                        )}
                      >
                        {moment(due_date).format('Do MMM')}
                      </span>
                    </td>
                    <td className="task-list-user">
                      {responsible_staff && (
                        <UserCapsule staffDetail={responsible_staff} />
                      )}
                    </td>
                    <td>
                      {tags && <div className="task-list-tags">
                        <ActivityTags activity={activity} />
                      </div>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default DashboardActivity;
