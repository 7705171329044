import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import { connect } from 'react-redux';
import { updatePracticeAndCloseModal, setPendingUploadAndResetError } from '^/actions/actionSequences';
import { PRACTICES } from '^/consts/collectionKeys';
import EditPracticeForm from '^/components/app/practices/modals/EditPracticeForm';

export class EditPracticeModal extends PureComponent {

  updatePractice(data) {
    if (typeof data.logo === 'string') {
      delete data.logo;
    }
    this.props.updatePracticeAndCloseModal(this.props.practice.get('id'), data);
  }

  render() {
    const { response, practice, setFileUpload } = this.props;

    return (
      <EditPracticeForm
        response={response}
        onSubmit={data => this.updatePractice(data)}
        setPendingUploadInForm={(form, field, file) => setFileUpload(form, field, file, form)}
        initialValues={practice.toJS()}
        practice={practice}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.getIn(['updateItem', PRACTICES])
  };
}

export default connect(
  mapStateToProps,
  { updatePracticeAndCloseModal, setFileUpload: setPendingUploadAndResetError }
) (EditPracticeModal);
