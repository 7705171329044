import React from 'react';
import classNames from 'classnames';
import PureComponent from '^/components/common/PureComponent';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';

export const LIST_NAV_ID = 'LIST_NAV_ID';

export default class CollapsibleLeftNav extends PureComponent {

  render() {

    const { children } = this.props;

    return (
      <div>
        <CollapseButton
          collapseId={LIST_NAV_ID} initiallyCollapsed collapseOnMount
          className={classNames('hidden-d left-nav-control-m')}
        >
            <span aria-hidden="true" className="left-nav-menu-button-indicator open">&#9776;</span>
        </CollapseButton>
        <Collapse collapseId={LIST_NAV_ID} className="left-nav-m">
          <CollapseButton collapseId={LIST_NAV_ID} className={classNames('hidden-d left-nav-control-m-open')}>
            <span aria-hidden="true" className="left-nav-menu-button-indicator">&times;</span>
          </CollapseButton>
          {children}
        </Collapse>
      </div>
    );
  }
}
