import React from 'react';
import { reduxForm } from 'redux-form';

import PureComponent from '^/components/common/PureComponent';
import FormError from '^/components/app/forms/FormError';
import LiveButton from '^/components/app/forms/LiveButton';

const CREATE_CHECKLIST_RECORD_GROUP_FORM = "CREATE_CHECKLIST_RECORD_GROUP_FORM";

export class CreateChecklistRecordGroupForm extends PureComponent {
  render() {
    const { fields: { name }, handleSubmit, response } = this.props;
    return (
      <form onSubmit={handleSubmit} className="">
        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="name">Name:</label>
          </div>
          <div className="col-2-3">
            <input
              type="text"
              className="form-control"
              id={name}
              {...name}
            />
            <FormError response={response} formKey="name" />
            <FormError error={name && name.touched && name.error} />
          </div>
        </div>
        <div className="form-group">
          <div className="col-1">
            <LiveButton
              pendingMessage="Creating..."
              response={response}
              className="btn-default pull-right"
            >
              Create
            </LiveButton>
          </div>
        </div>
      </form>
    );
  }
}

export function validate(values) {
  const { name } = values;
  const errors = {};

  if (!name) {
    errors.name = 'Name is required';
  }

  return errors;
}

export default reduxForm({
  form: CREATE_CHECKLIST_RECORD_GROUP_FORM,
  fields: ['name'],
  validate,
})(CreateChecklistRecordGroupForm);
