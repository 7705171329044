import { fromJS } from 'immutable';


export const ADMIN_HELP_CREATE = 'HELP_ADMIN_CREATE';
export const ADMIN_HELP_MODEL = 'admin/help';

const ADMIN_HELP_MANAGE_ROUTE = '/admin/help/${id}/';

export const ADMIN_HELP_EDIT_FIELDS = fromJS([
  'sector', 'title', 'content', 'is_faq', 'image', 'video'
]);

export const ADMIN_HELP_ROW_FIELDS = fromJS([
  {name: ['title'], display_name: 'Title', type: 'string', sortable: true},
  {name: ['is_faq'], display_name: 'Is FAQ', type: 'boolean', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Edit', route: ADMIN_HELP_MANAGE_ROUTE},
  {name: ['id'], display_name: 'Delete', type: 'remove', sortable: false},
]);
