import React from 'react';
import { connect } from 'react-redux';
import PureComponent from '^/components/common/PureComponent';
import { closeMenuAndFollowLink } from '^/actions/actionSequences';

export class LinkAndCloseMenu extends PureComponent {
  getClassName() {
    return (this.props.className ? ' '.concat(this.props.className) : '');
  }

  render() {
    const { children, onClick } = this.props;
    return (
      <a onClick={onClick} className={this.getClassName()}>{children}</a>
    );
  }
}

LinkAndCloseMenu.propTypes = {
  collapseId: React.PropTypes.string.isRequired,
  to: React.PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch, props) => ({
  onClick: () => dispatch(closeMenuAndFollowLink(props.collapseId, props.to)),
});

export default connect(null, mapDispatchToProps)(LinkAndCloseMenu);
