import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import { reduxForm } from 'redux-form';
import LiveButton from '^/components/app/forms/LiveButton';
import { List } from 'immutable';
import FormError from '^/components/app/forms/FormError';

export class ChangePasswordForm extends PureComponent {

  render() {
    const { fields: {new_password1, new_password2}, handleSubmit, response } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <p>Enter your new password below:</p>

        <div className="form-group mt-1">
          <div className="col-1-3">
            <label htmlFor="new_password1" className="label-left padded">New Password</label>
          </div>
          <div className="col-2-3">
            <input type="password" placeholder="Password" id="new_password1" {...new_password1} />
            <FormError error={new_password1 && new_password1.touched && new_password1.error} />
            <FormError response={response} formKey="new_password1" />
          </div>
        </div>
        <div className="form-group">
          <div className="col-1-3">
            <label htmlFor="new_password2" className="label-left padded">Confirm Password</label>
          </div>
          <div className="col-2-3">
            <input type="password" placeholder="Confirm Password" id="new_password2" {...new_password2} />
            <FormError error={new_password2 && new_password2.touched && new_password2.error} />
            <FormError response={response} formKey="new_password2" />
          </div>
        </div>

        <LiveButton
          response={this.props.response}
          pendingMessage="Changing password..."
          className="btn-primary"
          successContent={
            <p>
              Password has been sucessfully changed.
            </p>
          }
          getAdditionalErrors={(responseData) => responseData.getIn(['errors', 'non_field_errors'], List())}
        >
          Change Password
        </LiveButton>
      </form>
    );
  }
}

export function validateData(data) {
  if (data.new_password1 === '' || !data.new_password1) {
    return { new_password1: 'Password must not be empty' };
  }
  else if (data.new_password1 !== data.new_password2) {
    return { new_password2: 'Passwords must match' };
  }
  return {};
}

export default reduxForm({
  form: 'changePassword',
  fields: ['new_password1', 'new_password2'],
  validate: validateData
})(ChangePasswordForm);
