import { connect } from 'react-redux';

import { selectInsightFilterByKey } from '^/selectors/insights';
import { FILTER_KEYS } from '^/components/app/groups/insights/constants';

const withSelectedFilters = (insight) => (Component) => {
  function mapStateToProps(state) {
    return {
      type: selectInsightFilterByKey(insight, FILTER_KEYS.TYPE)(state),
      dateFrom: selectInsightFilterByKey(insight, FILTER_KEYS.DATE_FROM)(state),
      dateTo: selectInsightFilterByKey(insight, FILTER_KEYS.DATE_TO)(state),
      cycleIndex: selectInsightFilterByKey(insight, FILTER_KEYS.CYCLE_INDEX)(state),
    };
  }

  return connect(mapStateToProps)(Component);
};

export default withSelectedFilters;
