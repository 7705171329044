import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { promptBefore, transformHideRuleChoices } from '^/utils';
import { loadItem, getItemOptions } from '^/actions/items';
import {
  reloadTemplateDocumentAndRules,
  deleteTemplateDocAlertsAndReload,
  deleteItemAndRedirectTo,
} from '^/actions/actionSequences';
import { CAPABILITIES, MODELS } from '^/permissions/capabilities';
import FormError from '^/components/app/forms/FormError';
import IfCan from '^/components/common/IfCan';
import IfCant from '^/components/common/IfCant';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';

import AdminChangeLog from '^/components/admin/content/change-log/AdminChangeLog';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import AdminManyRelatedField from '^/components/admin/content/perms/AdminManyRelatedField';

import {
  ADMIN_TEMPLATE_DOCUMENT_MODEL,
  ADMIN_TEMPLATE_DOCUMENT_OPTIONS,
  ADMIN_TEMPLATE_DOCUMENT_CREATE,
  ADMIN_TEMPLATE_DOCUMENT_EDIT_FIELDS,
  ADMIN_TEMPLATE_DOCUMENT_VIEW_FIELDS,
  ADMIN_TEMPLATE_DOCUMENT_VERSION_MODEL,
  getAdminTemplateDocumentVersionRowFields,
  ADMIN_TEMPLATE_DOCUMENT_ACTIVITY_ROW_FIELDS,
  DOCUMENT_TYPE_CONTENT_FIELDS,
  ADMIN_TEMPLATE_DOCUMENT_REGION_HIDE_RULE_MODEL,
  ADMIN_TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE_MODEL,
  ADMIN_TEMPLATE_DOCUMENT_REGION_OVERRIDES_MODEL,
  TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE,
  ADMIN_TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE_ROW_FIELDS,
  ADMIN_TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE_CREATE_FIELDS,
  transformMembershipShowRuleChoices,
  TEMPLATE_DOCUMENT_REGION_OVERRIDES,
  ADMIN_TEMPLATE_DOCUMENT_REGION_OVERRIDES_ROW_FIELDS,
  ADMIN_TEMPLATE_DOCUMENT_REGION_OVERRIDES_CREATE_FIELDS,
  transformRegionOverridesChoices,
  TEMPLATE_DOCUMENT_REGION_HIDE_RULE,
  ADMIN_TEMPLATE_DOCUMENT_REGION_HIDE_RULE_ROW_FIELDS,
  ADMIN_TEMPLATE_DOCUMENT_REGION_HIDE_RULE_CREATE_FIELDS,
  ADMIN_TEMPLATE_DOCUMENT_HIDE_RULES_MODEL,
  TEMPLATE_DOCUMENT_DOCUMENT_HIDE_RULE,
  ADMIN_TEMPLATE_DOCUMENT_HIDE_RULE_CREATE_FIELDS,
  ADMIN_TEMPLATE_DOCUMENT_HIDE_RULE_ROW_FIELDS,
  transformRegionHideRuleChoices,
  transformDataToSubmit,
  isKindPolicyOrOverview,
  getPolicyAndOverviewKindOptions,
  filterFolderBySector
} from './template-documents';


function getFields(item) {
  return ADMIN_TEMPLATE_DOCUMENT_EDIT_FIELDS.filter(
    fieldName =>
      !DOCUMENT_TYPE_CONTENT_FIELDS.get(fieldName) ||
      DOCUMENT_TYPE_CONTENT_FIELDS.get(fieldName).contains(item.get('type'))
  );
}

function transform (fieldConfig, fields, templateDocument) {
  const sector = templateDocument.get('sector');
  fieldConfig = filterFolderBySector(fieldConfig, sector);
  
  if (isKindPolicyOrOverview(templateDocument)) {
    return fields.kind ? fieldConfig.setIn(['kind', 'choices'], getPolicyAndOverviewKindOptions(fieldConfig)).setIn(['sector', 'read_only'], true) : fieldConfig;
  }
  return fields.kind ? fieldConfig.setIn(['kind', 'read_only'], true) : fieldConfig;
}

class ManageTemplateDocument extends PureComponent {

  componentWillMount() {
    const { templateDocumentId, itemOptions } = this.props;
    this.props.loadItem(ADMIN_TEMPLATE_DOCUMENT_MODEL, templateDocumentId);

    if (itemOptions.isEmpty()) {
      this.getItemOptions(templateDocumentId);
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.templateDocumentId !== newProps.templateDocumentId) {
      this.getItemOptions(newProps.templateDocumentId);
    }
  }

  getItemOptions(templateDocumentId) {
    this.props.getItemOptions(ADMIN_TEMPLATE_DOCUMENT_MODEL, templateDocumentId, ADMIN_TEMPLATE_DOCUMENT_OPTIONS);
  }

  deleteDocument() {
    const { templateDocumentId } = this.props;
    this.props.deleteItemAndRedirectTo(ADMIN_TEMPLATE_DOCUMENT_MODEL, templateDocumentId, '/admin/template-documents');
  }

  render() {
    const {
      readOnly,
      templateDocumentId,
      itemOptions,
      templateDocumentFilter,
      templateDocument,
      reloadRules,
      deleteResponse,
    } = this.props;

    const isReferenced = templateDocument && templateDocument.get('is_referenced');

    return (
      <div>
        <AdminContainer>
          <AdminEdit
            permsModel={MODELS.TEMPLATE_DOCUMENT}
            readOnlyFields={ADMIN_TEMPLATE_DOCUMENT_VIEW_FIELDS}
            itemId={templateDocumentId}
            controlName={ADMIN_TEMPLATE_DOCUMENT_CREATE}
            collectionName={ADMIN_TEMPLATE_DOCUMENT_MODEL}
            model={ADMIN_TEMPLATE_DOCUMENT_MODEL}
            getFields={getFields}
            transform={transform}
            title="Template Document"
            transformDataToSubmit={transformDataToSubmit}
            className="btn-left-align"
          />

          <div>
            <IfCan capability={CAPABILITIES.UPDATE} model={MODELS.TEMPLATE_DOCUMENT} >
              <Link to={`/admin/template-documents/${templateDocumentId}/edit/`} className="btn btn-primary mt-1">
                Edit Document &gt;
              </Link>
            </IfCan>
            <IfCant capability={CAPABILITIES.UPDATE} model={MODELS.TEMPLATE_DOCUMENT} >
              <Link to={`/admin/template-documents/${templateDocumentId}/edit/`} className="btn btn-primary mt-1">
                View Document &gt;
              </Link>
            </IfCant>

            <button
              className="btn btn-warning mt-1 pull-right"
              disabled={isReferenced}
              title={isReferenced
                ? 'This document cannot be deleted as it has been adopted, copied or attached.' : null
              }
              onClick={promptBefore(() => this.deleteDocument())}
            >
              Delete
            </button>
            <FormError response={deleteResponse} />
          </div>

          <AdminList
            permsModel={MODELS.TEMPLATE_DOCUMENT}
            title="Versions"
            model={ADMIN_TEMPLATE_DOCUMENT_VERSION_MODEL}
            fields={getAdminTemplateDocumentVersionRowFields(this.props.deleteTemplateDocAlerts)}
            defaultFilters={Map({template_document: templateDocumentId})}
          />
        </AdminContainer>

        <AdminContainer className="mt-2">
          <AdminManyRelatedField
            permsModel={MODELS.TEMPLATE_DOCUMENT}
            itemId={templateDocumentId}
            controlName={ADMIN_TEMPLATE_DOCUMENT_MODEL}
            options={itemOptions}
            model={ADMIN_TEMPLATE_DOCUMENT_MODEL}
            fieldName={['template_activities']}
            fields={ADMIN_TEMPLATE_DOCUMENT_ACTIVITY_ROW_FIELDS}
            listTitle="Assigned to activities:"
            sortBy={['name']}
            defaultLookupFilters={{sector: templateDocument && templateDocument.get('sector')}}
          />
        </AdminContainer>

        <AdminContainer title="Membership Show Rules">
          <HelpBlock>
            <p className="small-text">
              This document will be shown only for the following memberships.
            </p>
          </HelpBlock>

          <AdminList
            permsModel={MODELS.TEMPLATE_DOCUMENT}
            noSearch
            hidePagination
            listName={TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE}
            pageSize={100}
            onRemove={() => reloadRules(templateDocumentId, templateDocumentFilter)}
            model={ADMIN_TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE_MODEL}
            defaultFilters={Map(templateDocumentFilter)}
            fields={ADMIN_TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE_ROW_FIELDS}
          />
          <AdminCreate
            permsModel={MODELS.TEMPLATE_DOCUMENT}
            collectionName={TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE}
            controlName={TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE}
            onComplete={() => reloadRules(templateDocumentId, templateDocumentFilter)}
            transform={config => transformMembershipShowRuleChoices(templateDocument, config)}
            model={ADMIN_TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE_MODEL}
            fields={ADMIN_TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE_CREATE_FIELDS}
            defaultValues={templateDocumentFilter}
            title="Add Membership"
          />
        </AdminContainer>

        <AdminContainer title="Hide Rules">
          <HelpBlock>
            <p className="small-text">
              This document will be hidden for a practice if ANY of the
              rules added below match the value set for a particular
              attribute on that practice.
            </p>
          </HelpBlock>
          <AdminList
            permsModel={MODELS.TEMPLATE_DOCUMENT}
            noSearch
            hidePagination
            pageSize={100}
            onRemove={() => reloadRules(templateDocumentId, templateDocumentFilter)}
            listName={TEMPLATE_DOCUMENT_DOCUMENT_HIDE_RULE}
            model={ADMIN_TEMPLATE_DOCUMENT_HIDE_RULES_MODEL}
            fields={ADMIN_TEMPLATE_DOCUMENT_HIDE_RULE_ROW_FIELDS}
            defaultFilters={Map(templateDocumentFilter)}
          />
          {!readOnly &&
            <AdminCreate
              permsModel={MODELS.TEMPLATE_DOCUMENT}
              collectionName={TEMPLATE_DOCUMENT_DOCUMENT_HIDE_RULE}
              onComplete={() => reloadRules(templateDocumentId, templateDocumentFilter)}
              controlName={TEMPLATE_DOCUMENT_DOCUMENT_HIDE_RULE}
              transform={config => transformHideRuleChoices(templateDocument, config)}
              model={ADMIN_TEMPLATE_DOCUMENT_HIDE_RULES_MODEL}
              fields={ADMIN_TEMPLATE_DOCUMENT_HIDE_RULE_CREATE_FIELDS}
              defaultValues={templateDocumentFilter}
              title="Add Rule"
            />
          }
        </AdminContainer>

        <AdminContainer title="Region Hide Rules">
          <HelpBlock>
            <p className="small-text">
              This document will be hidden for a practice if it is
              in ANY of the regions added below.
            </p>
          </HelpBlock>

          <AdminList
            permsModel={MODELS.TEMPLATE_ACTIVITY}
            noSearch
            hidePagination
            listName={TEMPLATE_DOCUMENT_REGION_HIDE_RULE}
            pageSize={100}
            onRemove={() => reloadRules(templateDocumentId, templateDocumentFilter)}
            model={ADMIN_TEMPLATE_DOCUMENT_REGION_HIDE_RULE_MODEL}
            defaultFilters={Map(templateDocumentFilter)}
            fields={ADMIN_TEMPLATE_DOCUMENT_REGION_HIDE_RULE_ROW_FIELDS}
          />
          <AdminCreate
            permsModel={MODELS.TEMPLATE_DOCUMENT}
            collectionName={TEMPLATE_DOCUMENT_REGION_HIDE_RULE}
            controlName={TEMPLATE_DOCUMENT_REGION_HIDE_RULE}
            onComplete={() => reloadRules(templateDocumentId, templateDocumentFilter)}
            transform={config => transformRegionHideRuleChoices(templateDocument, config)}
            model={ADMIN_TEMPLATE_DOCUMENT_REGION_HIDE_RULE_MODEL}
            fields={ADMIN_TEMPLATE_DOCUMENT_REGION_HIDE_RULE_CREATE_FIELDS}
            defaultValues={templateDocumentFilter}
            title="Add Rule"
          />
        </AdminContainer>

        <AdminContainer title="Region Overrides">
          <HelpBlock>
            <p className="small-text">
              The following regions have overrides for specific values.
            </p>
          </HelpBlock>

          <AdminList
            permsModel={MODELS.TEMPLATE_DOCUMENT}
            noSearch
            hidePagination
            listName={TEMPLATE_DOCUMENT_REGION_OVERRIDES}
            pageSize={100}
            onRemove={() => reloadRules(templateDocumentId, templateDocumentFilter)}
            model={ADMIN_TEMPLATE_DOCUMENT_REGION_OVERRIDES_MODEL}
            defaultFilters={Map(templateDocumentFilter)}
            fields={ADMIN_TEMPLATE_DOCUMENT_REGION_OVERRIDES_ROW_FIELDS}
          />
          <AdminCreate
            permsModel={MODELS.TEMPLATE_DOCUMENT}
            collectionName={TEMPLATE_DOCUMENT_REGION_OVERRIDES}
            controlName={TEMPLATE_DOCUMENT_REGION_OVERRIDES}
            onComplete={() => reloadRules(templateDocumentId, templateDocumentFilter)}
            transform={config => transformRegionOverridesChoices(templateDocument, config)}
            model={ADMIN_TEMPLATE_DOCUMENT_REGION_OVERRIDES_MODEL}
            fields={ADMIN_TEMPLATE_DOCUMENT_REGION_OVERRIDES_CREATE_FIELDS}
            defaultValues={templateDocumentFilter}
            title="Add Region Override"
          />
        </AdminContainer>

        <AdminContainer>
          <AdminChangeLog model="TemplateDocument" id={templateDocumentId} />
        </AdminContainer>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const templateDocumentId = props.params.uuid;

  return {
    templateDocumentId,
    templateDocument: state.items.get(ADMIN_TEMPLATE_DOCUMENT_MODEL),
    itemOptions: state.items.getIn(['options', ADMIN_TEMPLATE_DOCUMENT_OPTIONS], Map()),
    templateDocumentFilter: { template_document: templateDocumentId },
    deleteResponse: state.responses.getIn(['deleteItem', ADMIN_TEMPLATE_DOCUMENT_MODEL]),
  };
}

export default connect(
  mapStateToProps,
  {
    deleteTemplateDocAlerts: deleteTemplateDocAlertsAndReload,
    getItemOptions,
    loadItem,
    deleteItemAndRedirectTo,
    reloadRules: reloadTemplateDocumentAndRules,
  }
)(ManageTemplateDocument);
