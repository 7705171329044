import React from 'react';
import classnames from 'classnames';

import PureComponent from '^/components/common/PureComponent';
import { convertRateToPercentage } from '^/utils';
import { COMPLIANCE_ACTIVITIES_THRESHOLDS } from '^/components/app/groups/insights/constants';

const RADIUS = 64;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

export default class RadialChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      style: {
        strokeDasharray: CIRCUMFERENCE,
        strokeDashoffset: CIRCUMFERENCE
      }
    };
    this.setChartRef = element => {
      this.chart = element;
    };
  }

  render() {
    const dashoffset = CIRCUMFERENCE * (1 - this.props.value);

    return (
      <div className="radial-chart">
        <svg
          className="progress"
          width="120"
          height="120"
          viewBox="0 0 120 120"
          data-value="50"
        >
          <circle
            className="progress__meter"
            cx="60"
            cy="60"
            r="54"
            strokeWidth="12"
          />
          <circle
            className={classnames('progress__value', {
              good: this.props.value >= COMPLIANCE_ACTIVITIES_THRESHOLDS.OK,
              ok: this.props.value >= COMPLIANCE_ACTIVITIES_THRESHOLDS.BAD,
              bad: this.props.value < COMPLIANCE_ACTIVITIES_THRESHOLDS.BAD
            })}
            cx="60"
            cy="60"
            r="54"
            strokeWidth="12"
            style={{
              strokeDasharray: CIRCUMFERENCE,
              strokeDashoffset: dashoffset,
            }}
          />
        </svg>
        <span className="value">
          <span className={classnames('value__number', {
            'ml-1': !!this.props.value
          })}>
            {convertRateToPercentage(this.props.value)}
            {this.props.value ? (
              <span className="value__percent">%</span>
            ) : null}
          </span>
        </span>
      </div>
    );
  }
}
