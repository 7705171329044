import { Dispatch, AnyAction } from 'redux';
import { push } from 'react-router-redux';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';

import { makeThunkRequest } from '^/utils/requests';
import { makeAsyncActionSet } from './utils';
import { StoreState, ThunkRequestsState } from '^/store/types';
import {
  selectNonImmutableUserProfile,
  getInProgressHealthcheck,
  getFirstIncompleteCategoryId,
  selectInProgressHealthcheck,
} from '^/selectors/healthcheck';
import {
  HEALTHCHECK_API_URL_BASE,
  HEALTHCHECK_CLIENT_URL_BASE,
  STATUS_ORDER,
} from '^/consts/healthcheck';
import { closeModal } from './modals';
import {
  HealthcheckCategoriesProgress,
  HealthcheckCategoriesWithAreas,
  HealthcheckQuestion,
  HealthcheckCategoryWithAreas,
  HealthcheckAction,
  HealthcheckReview,
  HealthcheckStatus,
  Healthcheck,
  HealthcheckQuestionResponse,
} from '^/components/app/healthcheck/types';

interface BaseAnswerActionExtra {
  healthcheckId: string;
  areaId: string;
  categoryId: string;
}

export interface AnswerQuestionActionExtra extends BaseAnswerActionExtra {
  questionId: string;
}

export interface MarkActionCompleteActionExtra extends BaseAnswerActionExtra {
  actionId: string;
}

const noop = () => null;

export const GET_HEALTHCHECK_QUESTIONS_PROGRESS = makeAsyncActionSet(
  'GET_HEALTHCHECK_QUESTIONS_PROGRESS'
);
const getHealthcheckQuestionsCategories = (healthcheckId: string) =>
  makeThunkRequest<HealthcheckCategoriesProgress>(
    GET_HEALTHCHECK_QUESTIONS_PROGRESS,
    {
      url: `${HEALTHCHECK_API_URL_BASE}${healthcheckId}/questions-progress/`,
    },
    { rethrowErrors: true }
  );

export const GET_HEALTHCHECK_QUESTIONS_CATEGORY = makeAsyncActionSet(
  'GET_HEALTHCHECK_QUESTIONS_CATEGORY'
);
export const getHealthcheckQuestionsCategory = (
  healthcheckId: string,
  categoryId: string
) => (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>,
  getState: () => StoreState
) => {
  const state = getState();

  const requestAction = makeThunkRequest<
    HealthcheckCategoriesWithAreas<HealthcheckQuestion>
  >(GET_HEALTHCHECK_QUESTIONS_CATEGORY, {
    url: `${HEALTHCHECK_API_URL_BASE}${healthcheckId}/category/${categoryId}/questions/`,
  });

  if (state.healthcheck.questionsCategories) {
    return dispatch(requestAction);
  }

  return getHealthcheckQuestionsCategories(healthcheckId)(dispatch)
    .then(() => {
      return dispatch(requestAction);
    })
    .catch(noop);
};

export const GET_HEALTHCHECK_ACTIONS_PROGRESS = makeAsyncActionSet(
  'GET_HEALTHCHECK_ACTIONS_PROGRESS'
);
const getHealthcheckActionsCategories = (healthcheckId: string) =>
  makeThunkRequest<HealthcheckCategoriesProgress>(
    GET_HEALTHCHECK_ACTIONS_PROGRESS,
    {
      url: `${HEALTHCHECK_API_URL_BASE}${healthcheckId}/actions-progress/`,
    },
    { rethrowErrors: true }
  );

export const GET_HEALTHCHECK_ACTIONS_CATEGORY = makeAsyncActionSet(
  'GET_HEALTHCHECK_ACTIONS_CATEGORY'
);
export const getHealthcheckActionsCategory = (
  healthcheckId: string,
  categoryId: string
) => (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>,
  getState: () => StoreState
) => {
  const state = getState();

  const requestAction = makeThunkRequest<
    HealthcheckCategoryWithAreas<HealthcheckAction>
  >(GET_HEALTHCHECK_ACTIONS_CATEGORY, {
    url: `${HEALTHCHECK_API_URL_BASE}${healthcheckId}/category/${categoryId}/actions/`,
  });

  if (state.healthcheck.actionsCategories) {
    return dispatch(requestAction);
  }

  return getHealthcheckActionsCategories(healthcheckId)(dispatch)
    .then(() => {
      return dispatch(requestAction);
    })
    .catch(noop);
};

export const GET_HEALTHCHECK_REVIEW = makeAsyncActionSet(
  'GET_HEALTHCHECK_REVIEW'
);

export const getHealthcheckReview = (healthcheckId: string) =>
  makeThunkRequest<HealthcheckReview>(GET_HEALTHCHECK_REVIEW, {
    url: `${HEALTHCHECK_API_URL_BASE}${healthcheckId}/review/`,
  });

export const GET_HEALTHCHECK_AUDIT_RESULTS = makeAsyncActionSet(
  'GET_HEALTHCHECK_AUDIT_RESULTS'
);

export const getHealthcheckAuditResults = (healthcheckId: string) =>
  makeThunkRequest<HealthcheckReview>(GET_HEALTHCHECK_AUDIT_RESULTS, {
    url: `${HEALTHCHECK_API_URL_BASE}${healthcheckId}/audit-results/`,
  });

const getHealthcheckQuestionsProgressAndRedirect = (healthcheckId: string) => (
  dispatch: Dispatch<StoreState>
) =>
  makeThunkRequest<HealthcheckCategoriesProgress>(
    GET_HEALTHCHECK_QUESTIONS_PROGRESS,
    {
      url: `${HEALTHCHECK_API_URL_BASE}${healthcheckId}/questions-progress/`,
    },
    { rethrowErrors: true }
  )(dispatch)
    .then(response => {
      const categoryId = getFirstIncompleteCategoryId(response.data);
      dispatch(
        push(
          `${HEALTHCHECK_CLIENT_URL_BASE}${healthcheckId}/questions/${categoryId}/`
        )
      );
    })
    .catch(noop);

export const getHealthcheckActionsProgressAndRedirect = (
  healthcheckId: string
) => (dispatch: Dispatch<StoreState>) =>
  makeThunkRequest<HealthcheckCategoriesProgress>(
    GET_HEALTHCHECK_ACTIONS_PROGRESS,
    {
      url: `${HEALTHCHECK_API_URL_BASE}${healthcheckId}/actions-progress/`,
    },
    { rethrowErrors: true }
  )(dispatch)
    .then(response => {
      const categoryId = getFirstIncompleteCategoryId(response.data);
      dispatch(
        push(
          `${HEALTHCHECK_CLIENT_URL_BASE}${healthcheckId}/actions/${
            categoryId ? categoryId : ''
          }`
        )
      );
    })
    .catch(noop);

export const GENERIC_HEALTHCHECK_ERROR = 'GENERIC_HEALTHCHECK_ERROR';

export const continueHealthcheckFrom = (
  status: HealthcheckStatus,
  healthcheckId: string
) => (dispatch: ThunkDispatch<StoreState, unknown, AnyAction>) => {
  const unexpectedStatus = {
    type: GENERIC_HEALTHCHECK_ERROR,
    payload: `Unexpected healthcheck status: ${status}`,
  };

  const statusActions: Record<
    HealthcheckStatus,
    AnyAction | ThunkAction<unknown, StoreState, unknown, AnyAction>
  > = {
    [HealthcheckStatus.CREATED]: push(
      `${HEALTHCHECK_CLIENT_URL_BASE}${healthcheckId}/info/`
    ),
    [HealthcheckStatus.AUDIT_INTRO_1]: push(
      `${HEALTHCHECK_CLIENT_URL_BASE}${healthcheckId}/audit-intro-1/`
    ),
    [HealthcheckStatus.AUDIT_RESULTS]: push(
      `${HEALTHCHECK_CLIENT_URL_BASE}${healthcheckId}/audit-results/`
    ),
    [HealthcheckStatus.ACTIONS_INTRO_1]: push(
      `${HEALTHCHECK_CLIENT_URL_BASE}${healthcheckId}/actions-intro-1/`
    ),
    [HealthcheckStatus.REVIEW]: push(
      `${HEALTHCHECK_CLIENT_URL_BASE}${healthcheckId}/review/`
    ),
    [HealthcheckStatus.COMPLETED]: push(
      `${HEALTHCHECK_CLIENT_URL_BASE}${healthcheckId}/review/`
    ),
    [HealthcheckStatus.AUDIT]: getHealthcheckQuestionsProgressAndRedirect(
      healthcheckId
    ),
    [HealthcheckStatus.ACTIONS]: getHealthcheckActionsProgressAndRedirect(
      healthcheckId
    ),
  };

  const action = statusActions[status] ?? unexpectedStatus;

  dispatch(action);
};

export const CREATE_HEALTHCHECK = makeAsyncActionSet('CREATE_HEALTHCHECK');
const createHealthcheck = makeThunkRequest<Healthcheck>(
  CREATE_HEALTHCHECK,
  {
    url: HEALTHCHECK_API_URL_BASE,
    method: 'POST',
  },
  { rethrowErrors: true }
);

export const createHealthcheckAndContinueHealthcheckForm = () => (
  dispatch: Dispatch<StoreState>
) => {
  createHealthcheck(dispatch)
    .then(response =>
      continueHealthcheckFrom(response.data.status, response.data.id)(dispatch)
    )
    .catch(noop);
};

export const startContinueOrViewHealthcheck = () => (
  dispatch: Dispatch<StoreState>,
  getState: () => StoreState
) => {
  const userProfile = selectNonImmutableUserProfile(getState());
  const inProgressHealthcheck = getInProgressHealthcheck(userProfile);

  if (inProgressHealthcheck) {
    if (inProgressHealthcheck.user.id !== userProfile?.id) {
      dispatch(
        push(`${HEALTHCHECK_CLIENT_URL_BASE}${inProgressHealthcheck.id}/info/`)
      );
    } else {
      continueHealthcheckFrom(
        inProgressHealthcheck.status,
        inProgressHealthcheck.id
      )(dispatch);
    }
  } else {
    createHealthcheck(dispatch)
      .then(response =>
        continueHealthcheckFrom(
          response.data.status,
          response.data.id
        )(dispatch)
      )
      .catch(noop);
  }
};

export const ANSWER_HEALTHCHECK_QUESTION = makeAsyncActionSet(
  'ANSWER_HEALTHCHECK_QUESTION'
);
export const answerHealthcheckQuestion = (
  healthcheckId: string,
  categoryId: string,
  areaId: string,
  questionId: string,
  response: HealthcheckQuestionResponse
) => (dispatch: Dispatch<StoreState>) =>
  makeThunkRequest<unknown, AnswerQuestionActionExtra>(
    ANSWER_HEALTHCHECK_QUESTION,
    {
      url: `${HEALTHCHECK_API_URL_BASE}${healthcheckId}/questions/${questionId}/`,
      method: 'PUT',
      data: { response },
    },
    {
      rethrowErrors: false,
      requestId: questionId,
    },
    {
      questionId,
      categoryId,
      areaId,
      healthcheckId,
    }
  )(dispatch);

export const UPDATE_ACTION = makeAsyncActionSet('UPDATE_ACTION');
export const updateAction = (
  healthcheckId: string,
  categoryId: string,
  areaId: string,
  actionId: string,
  action: Partial<HealthcheckAction>
) => (dispatch: Dispatch<StoreState>) =>
  makeThunkRequest<unknown, MarkActionCompleteActionExtra>(
    UPDATE_ACTION,
    {
      url: `${HEALTHCHECK_API_URL_BASE}${healthcheckId}/actions/${actionId}/`,
      method: 'PUT',
      data: action,
    },
    {
      rethrowErrors: false,
      requestId: actionId,
    },
    {
      actionId,
      categoryId,
      areaId,
      healthcheckId,
    }
  )(dispatch);

export const UPDATE_HEALTHCHECK_STATUS = makeAsyncActionSet(
  'UPDATE_HEALTHCHECK_STATUS'
);
const updateHealthcheckStatus = (
  healthcheckId: string,
  status: HealthcheckStatus
) =>
  makeThunkRequest<Healthcheck>(
    UPDATE_HEALTHCHECK_STATUS,
    {
      url: `${HEALTHCHECK_API_URL_BASE}${healthcheckId}/`,
      method: 'PUT',
      data: { status },
    },
    { rethrowErrors: true }
  );

export const updateToSpecifiedHealthcheckStatusAndContinue = (
  intendedFromStatus: HealthcheckStatus,
  intendedToStatus: HealthcheckStatus
) => (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>,
  getState: () => StoreState
) => {
  const inProgressHealthcheck = selectInProgressHealthcheck(getState());

  if (inProgressHealthcheck) {
    if (
      inProgressHealthcheck.status === intendedFromStatus &&
      intendedToStatus
    ) {
      updateHealthcheckStatus(
        inProgressHealthcheck.id,
        intendedToStatus
      )(dispatch).then(() => {
        continueHealthcheckFrom(
          intendedToStatus,
          inProgressHealthcheck.id
        )(dispatch);
      });
    }
  }
};

export const updateHealthcheckStatusIfMatchAndContinue = (
  intendedFromStatus: HealthcheckStatus
) => (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>,
  getState: () => StoreState
) => {
  const inProgressHealthcheck = selectInProgressHealthcheck(getState());

  if (inProgressHealthcheck) {
    const currentStatusIndex = STATUS_ORDER.indexOf(intendedFromStatus);
    const nextStatus =
      currentStatusIndex < STATUS_ORDER.length - 1
        ? STATUS_ORDER[currentStatusIndex + 1]
        : null;

    if (inProgressHealthcheck.status === intendedFromStatus && nextStatus) {
      updateHealthcheckStatus(
        inProgressHealthcheck.id,
        nextStatus
      )(dispatch).then(() => {
        continueHealthcheckFrom(nextStatus, inProgressHealthcheck.id)(dispatch);
      });
    } else if (nextStatus) {
      continueHealthcheckFrom(nextStatus, inProgressHealthcheck.id)(dispatch);
    }
  }
};

export const completeHealthcheckAndCloseModal = () => (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>,
  getState: () => StoreState
) => {
  const inProgressHealthcheck = selectInProgressHealthcheck(getState());
  const intendedFromStatus = HealthcheckStatus.REVIEW;
  const nextStatus = HealthcheckStatus.COMPLETED;

  if (inProgressHealthcheck) {
    if (inProgressHealthcheck.status === intendedFromStatus && nextStatus) {
      updateHealthcheckStatus(
        inProgressHealthcheck.id,
        nextStatus
      )(dispatch).then(() => {
        continueHealthcheckFrom(nextStatus, inProgressHealthcheck.id)(dispatch);
        dispatch(closeModal());
      });
    }
  }
};

export const DELETE_HEALTHCHECK = makeAsyncActionSet('DELETE_HEALTHCHECK');
const deleteHealthcheck = (healthcheckId: string) => (
  dispatch: Dispatch<StoreState>
) =>
  makeThunkRequest(
    DELETE_HEALTHCHECK,
    {
      url: `${HEALTHCHECK_API_URL_BASE}${healthcheckId}/`,
      method: 'DELETE',
    },
    { rethrowErrors: true },
    healthcheckId
  )(dispatch);

export const deleteInProgressHealthcheckAndNavigateToDashboard = () => (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>,
  getState: () => StoreState
) => {
  const inProgressHealthcheck = selectInProgressHealthcheck(getState());

  if (inProgressHealthcheck) {
    deleteHealthcheck(inProgressHealthcheck.id)(dispatch)
      .then(() => {
        dispatch(closeModal());
        dispatch(push('/page/dashboard'));
      })
      .catch(noop);
  }
};

export const CLEAR_THUNK_REQUEST_ERRORS = 'CLEAR_THUNK_REQUEST_ERRORS';
export function clearThunkRequestErrors(requestKey: keyof ThunkRequestsState) {
  return { type: CLEAR_THUNK_REQUEST_ERRORS, payload: requestKey };
}

export const GET_CURRENT_PRACTICE_HEALTHCHECKS = makeAsyncActionSet(
  'GET_CURRENT_PRACTICE_HEALTHCHECKS'
);
export const getCurrentPracticeHealthchecks = () =>
  makeThunkRequest(GET_CURRENT_PRACTICE_HEALTHCHECKS, {
    url: `${HEALTHCHECK_API_URL_BASE}list/`,
    method: 'GET',
  });

export const GET_HEALTHCHECK_BY_ID = makeAsyncActionSet(
  'GET_HEALTHCHECK_BY_ID'
);
export const getHealthcheckById = (healthcheckId: string) => (
  dispatch: Dispatch<StoreState>
) =>
  makeThunkRequest(
    GET_HEALTHCHECK_BY_ID,
    {
      url: `${HEALTHCHECK_API_URL_BASE}${healthcheckId}/`,
      method: 'GET',
    },
    { rethrowErrors: true }
  )(dispatch);
