import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import ContentBox from '^/components/app/content/ContentBox';

export default class NoAuth extends PureComponent {
  // FIXME: Add logo

  render() {
    return (
      <div className="container">
        <div className="no-auth">
          <div className="row">
            <div className="col-xs-12" />
          </div>

          <div className="row mt-1">
            <ContentBox>
              {this.props.children}
            </ContentBox>
          </div>
        </div>
      </div>
    );
  }
}
