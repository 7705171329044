import React from 'react';
import { reduxForm } from 'redux-form';

import { getStaffPracticeTypes, STAFF_PRACTICE_TYPE } from '^/models/staffPractice';

import FormError from '^/components/app/forms/FormError';
import HelpTooltip from '^/components/app/widgets/HelpTooltip';
import Icon from '^/components/app/content/Icon';
import LiveButton from '^/components/app/forms/LiveButton';

export const FORM_NAME = 'addTeamMemberToPracticeForm';

export const AddNewTeamMemberToPracticeForm = props => {

  const {
    fields: {
      email,
      staffdetail: {
        first_name,
        last_name,
      },
      staffpractice: { type }
    },
    handleSubmit,
    response,
    isPracticeAdmin,
  } = props;


  return (
    <form onSubmit={handleSubmit}>

      <FormError response={response} />

      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor="first_name" className="label-left padded">First name</label>
          <span className="help-inline">required</span>
        </div>
        <div className="col-2-3">
          <input type="text" id="first_name" placeholder="eg. Joe" {...first_name} />
          <FormError response={response} formKeyPath={["staffdetail", "first_name"]} />
        </div>
      </div>

      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor="last_name" className="label-left padded">Last Name</label>
          <span className="help-inline">required</span>
        </div>
        <div className="col-2-3">
          <input type="text" id="last_name" placeholder="eg. Bloggs" {...last_name} />
          <FormError response={response} formKeyPath={["staffdetail", "last_name"]} />
        </div>
      </div>

      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor="email" className="label-left padded">Email address</label>
          <span className="help-inline">required</span>
          </div>
        <div className="col-2-3">
          <input type="text" id="email" placeholder="eg. mail@icomply.cc" {...email} />
        </div>
        <div className="col-2-3 col-md-1-3-offset">
          <span className="help-block">
            <Icon type="lightbulb-o" className="tip-icon" />
            users will be invited to set their passwords
          </span>
          <FormError response={response} formKey="email" />
        </div>
      </div>

      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor="type" className="label-left padded">User level</label>
          <span className="help-inline">required</span>
        </div>
        <div className="col-2-3">
          <select id="type" {...type}>
            {getStaffPracticeTypes(isPracticeAdmin).map((display, value) =>
              <option value={value} key={value}>{display}</option>
            )}
          </select>
          <FormError response={response} formKeyPath={['staffdetail', 'type']} />
          <HelpTooltip>
            {isPracticeAdmin && (
              <p className="x-small-text">
                Practice Administrators have total control of the iComply application.
                Administrators are able to see and edit all activities for the practice,
                add new practices, edit practice details, create iComply cycles, add and
                edit users, update team responsibilities and create Tasks for users.
              </p>
            )}
            <p className="x-small-text">
              Practice Managers are able to see and complete all activities within
              iComply, edit user details, reschedule activities and re-delegate
              responsible team members.
            </p>
            <p className="x-small-text">
              Team Members can only see and complete their own delegated activities.
              They are unable to reschedule and re-delegate.
            </p>
          </HelpTooltip>
        </div>
      </div>

      <div className="form-group">
        <div className="col-1">
          <LiveButton pendingMessage="Saving..." response={response} className="btn-default">
            Add
          </LiveButton>
        </div>
      </div>

    </form>
  );
};

export default reduxForm({
  form: FORM_NAME,
  fields: [
    'email',

    //staffpractice field
    'staffpractice.type',

    // staffdetail fields
    'staffdetail.first_name',
    'staffdetail.last_name',
  ],
  initialValues: {staffpractice: {type: STAFF_PRACTICE_TYPE.TEAM_MEMBER}},
})(AddNewTeamMemberToPracticeForm);
