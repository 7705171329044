import React from 'react';
import { CAPABILITIES } from '^/permissions/capabilities';
import IfCan from '^/components/common/IfCan';
import AdminEditCreate from '^/components/admin/content/edit-create/AdminEditCreate';

export default function AdminCreate(props) {
  return (
    <IfCan capability={CAPABILITIES.CREATE} model={props.permsModel}>
      <AdminEditCreate {...props} />
    </IfCan>
  );
}
