import React, { Component } from 'react';

import createIntroPage from './hoc/create-intro-page';
import { HealthcheckTypes, HealthcheckStatus } from './types';

class Content extends Component<{}, {}> {
  public render() {
    return (
      <div className="content">
        <p>Congratulations on completing the audit stage of the Healthcheck!</p>
        <p className="mt-1">Next up is the ‘Actions’ section where you can:</p>
        <ul className="mt-1">
          <li>See your compliance gaps and recommended actions list</li>
          <li>Adopt missing or out of date documents</li>
          <li>
            Add notes to tell an inspector what you did to close a particular
            gap
          </li>
          <li>Mark off your actions as complete</li>
        </ul>
        <p className="mt-1">
          It’s important to bear in mind that for practices who are
          significantly behind on compliance, an Agilio consultant would
          normally take around 40 hours, with the help of the practice’s
          compliance manager, to complete all the actions. Therefore, for a
          non-consultant, this could take longer.
        </p>
        <p className="mt-1">
          It is important to add notes as you go along as these will enable you
          to show an inspector what you found and what you did about it.
        </p>
        <p className="mt-1">
          Only mark the action as complete once you have dealt with it. For
          example, if you are missing DBS checks then mark the activity off once
          you have received and filed them, not once you have submitted the
          paperwork to get them.
        </p>
      </div>
    );
  }
}

export default createIntroPage({
  type: HealthcheckTypes.Actions,
  header: 'Preparing for Actions - what you need:',
  buttonText: 'Start Actions',
  intendedFromStatus: HealthcheckStatus.ACTIONS_INTRO_1,
})(Content);
