import classNames from 'classnames';
import React from 'react';
import { Map } from 'immutable';

import { isPracticesGroupAdmin } from '^/utils';
import { STATUS_TYPES } from '^/models/user';
import ShowIfAdmin from '^/components/app/perms/ShowIfAdmin';
import { getStaffPracticeTypeDisplay, STAFF_PRACTICE_TYPE, STATUS_CHOICES } from '^/models/staffPractice';
import LiveButton from '^/components/app/forms/LiveButton';
import { INVITED_MEMBER_BUTTON_CLASSNAMES } from '^/components/app/practices/constants';
import InvitedMemberErrors from '^/components/app/practices/InvitedMemberErrors';
import RemoveInviteButton from '^/components/app/practices/RemoveInviteButton';


export const ActiveMemberButtons = ({
  isLoggedInAdmin, openMemberModal, setTeamMemberAndNavToDashboard, openRetireModal, openSuspendModal, canEdit
}) =>
  <div>
    <div className="mb-1-2 text-right">
      <button
        disabled={!canEdit && !isLoggedInAdmin}
        className="btn btn-default fixed-width mr-1-2"
        onClick={openMemberModal}
      >
        Edit
      </button>
      <button
        disabled={!canEdit}
        className="btn btn-default fixed-width"
        onClick={setTeamMemberAndNavToDashboard}
      >
        Activities
      </button>
    </div>
    <div className="text-right">
     <button
       disabled={isLoggedInAdmin || !canEdit}
       title={isLoggedInAdmin ? 'You cannot reassign yourself.' : 'Retire member'}
       className="btn btn-default fixed-width mr-1-2"
       onClick={openRetireModal}
     >
          Retire
      </button>
      <button
        disabled={isLoggedInAdmin || !canEdit}
        title={isLoggedInAdmin ? 'You cannot reassign yourself.' : 'Suspend member'}
        className="btn btn-default fixed-width"
        onClick={openSuspendModal}
      >
          Suspend
      </button>
    </div>
  </div>;

export const InvitedMemberButtons = ({
  openMemberModal,
  resendInvite,
  resendInviteResponse,
  userId,
  practiceInviteId,
  ssoInviteId,
  canEdit
}) =>
  <div className="display-flex flex-wrap flex-align-items-flex-start flex-justify-end">
    {canEdit && !practiceInviteId && (
      <button
        className={classNames(INVITED_MEMBER_BUTTON_CLASSNAMES, 'mr-1-4')}
        onClick={openMemberModal}
      >
        Edit
      </button> 
    )}
    <LiveButton
      className={classNames(INVITED_MEMBER_BUTTON_CLASSNAMES)}
      response={
        resendInviteResponse.getIn(['meta', 'invitedUserId']) === userId
          ? resendInviteResponse : undefined
      }
      successContent={(
        <button className={classNames(INVITED_MEMBER_BUTTON_CLASSNAMES)} disabled>Sent!</button>
      )}
      onClick={resendInvite}
      loadingClassName="small-centered hide-sibling"
      hideDefaultErrorMessage
    >
      Resend
    </LiveButton>
    <RemoveInviteButton practiceInviteId={practiceInviteId} ssoInviteId={ssoInviteId} />
    <InvitedMemberErrors
      practiceInviteId={practiceInviteId}
      ssoInviteId={ssoInviteId}
      userId={userId}
    />
  </div>;

export const SuspendedMemberButtons = ({ isLoggedInAdmin, openRetireModal, openReinstateModal}) =>
  <div className="text-right">
    <button
      disabled={isLoggedInAdmin}
      title={isLoggedInAdmin ? 'You cannot reassign yourself.' : 'Retire member'}
      className="btn btn-default mr-1-2 fixed-width"
      onClick={openRetireModal}
    >
        Retire
    </button>
    <button
      disabled={isLoggedInAdmin}
      title={isLoggedInAdmin ? 'You cannot reassign yourself.' : 'Reinstate member'}
      className="btn btn-default fixed-width"
      onClick={openReinstateModal}
    >
        Reinstate
    </button>
  </div>;


export const RetiredMemberButton = ({ openReinstateModal, isLoggedInAdmin }) =>
  <div className="text-right">
    <button
      disabled={isLoggedInAdmin}
      title={isLoggedInAdmin ? 'You cannot reassign yourself.' : 'Reinstate member'}
      className="btn btn-default fixed-width"
      onClick={openReinstateModal}
    >
        Reinstate
    </button>
  </div>;

const canEditMember = (loggedInMember, member) => {
  if (loggedInMember.get('type') === STAFF_PRACTICE_TYPE.PRACTICE_ADMIN 
    && member.get('status') !== STATUS_TYPES.INVITED) {
    return true;
  }

  if (loggedInMember.get('type') === STAFF_PRACTICE_TYPE.PRACTICE_MANAGER) {
    const memberType = member.get('type');
    if (memberType === STAFF_PRACTICE_TYPE.TEAM_MEMBER || memberType === STAFF_PRACTICE_TYPE.READ_ONLY) {
      return true;
    }
  }
  return false;
};

export const MemberRow = props => {
  const { member, group, currentPractice, loggedInMember } = props;
  const staffdetail = member.getIn(['user', 'staffdetail'], Map());
  const practiceInviteFirstName = member.get('practice_invite_first_name');
  const practiceInviteLastName = member.get('practice_invite_last_name');
  const practiceInviteFullName = (practiceInviteFirstName || practiceInviteLastName) 
    ? (practiceInviteFirstName && practiceInviteFirstName + ' ') + practiceInviteLastName
    : null;
  const { full_name, roles } = staffdetail.toObject();

  const isGroupAdmin = isPracticesGroupAdmin(currentPractice, member.getIn(['user', 'id']));

  // admins should not be able to reassign themselves
  const isLoggedInAdmin = member.get('id') === loggedInMember.get('id');
  const canEdit = canEditMember(loggedInMember, member);

  return (
    <tr>
      <td>{ !practiceInviteFullName ? full_name : practiceInviteFullName }</td>
      <td>{ roles.map((role) => role.get('name')).join(', ') }</td>
      <td>
        { getStaffPracticeTypeDisplay(member.get('type'), isGroupAdmin) || '--' }
        {member.get('is_owner') && (
          <span className="grey"><br />OWNER</span>
        )}
      </td>
      <td>
        <ShowIfAdmin>
          {group === STATUS_CHOICES.ACTIVE &&
            <ActiveMemberButtons
              canEdit={canEdit}
              isLoggedInAdmin={isLoggedInAdmin}
              {...props}
            />
          }
          {group === STATUS_TYPES.INVITED &&
            <InvitedMemberButtons
              canEdit={canEdit}
              isLoggedInAdmin={isLoggedInAdmin}
              {...props}
            />
          }
          {group === STATUS_CHOICES.SUSPENDED &&
            <SuspendedMemberButtons
              canEdit={canEdit}
              isLoggedInAdmin={isLoggedInAdmin}
              {...props}
            />
          }
          {group === STATUS_CHOICES.RETIRED &&
            <RetiredMemberButton
              canEdit={canEdit}
              isLoggedInAdmin={isLoggedInAdmin}
              {...props}
            />
          }
        </ShowIfAdmin>
      </td>
    </tr>
  );
};


export const TeamMembersTable = ({
  group,
  members,
  currentPractice,
  loggedInMember,
  setTeamMemberAndNavToDashboard,
  openMemberModal,
  openSuspendModal,
  openRetireModal,
  openReinstateModal,
  resendInvite,
  resendInviteResponse,
}) =>
  <table className="padded">
    <thead>
    <tr>
      <th className="text-left">Name</th>
      <th className="text-left">Role(s)</th>
      <th className="text-left">User level</th>
      <th className="user-actions text-center">Manage</th>
    </tr>
    </thead>
    <tbody>
      {members.map((member) =>
        <MemberRow
          key={member.get('id')}
          member={member}
          userId={member.getIn(['user', 'id'])}
          practiceInviteId={member.get('practice_invite_id')}
          ssoInviteId={member.get('sso_invite_id')}
          group={group}
          currentPractice={currentPractice}
          loggedInMember={loggedInMember}
          setTeamMemberAndNavToDashboard={() => setTeamMemberAndNavToDashboard(member)}
          openMemberModal={() => openMemberModal(member)}
          openSuspendModal={() => openSuspendModal(member)}
          openRetireModal={() => openRetireModal(member)}
          resendInvite={() => resendInvite(member.getIn(['user', 'id']))}
          resendInviteResponse={resendInviteResponse}
          openReinstateModal={() => openReinstateModal(member)}
        />
      )}
    </tbody>
  </table>;

export default TeamMembersTable;
