
import React, { PropTypes } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';

import { isPending } from '^/consts/responseStates';

import FormError from '^/components/app/forms/FormError';
import Loading from '^/components/app/content/Loading';
import FileUpload from './FileUpload';


function removeFile(onChange, files, fileId) {
  return onChange(List(files || []).filterNot(each => each.id === fileId));
}


const File = ({file, onRemove}) =>
  <div>
    <a href={file.file} target="_blank" className="inline inline-label" download>
      {file.name || file.filename || 'Download'}
    </a>
    <a className="pull-right inline-edit" onClick={onRemove}>Remove</a>
  </div>;


const Files = ({ field: { value, onChange, }, }) =>
  <div className="mt-1-2">
    {List(value || []).map(
      each =>
        <File key={each.id} file={each} onRemove={() => removeFile(onChange, value, each.id)} />
    )}
  </div>;


function SelectFile ({ field: { name, }, formName, handleSubmit, uploadFile, uploadFileResponse, }) {

  if (isPending(uploadFileResponse)) {
    return <div>Uploading file... <Loading className="inline small" /></div>;
  }

  return (
    <div>
      <FileUpload
        id={name}
        setPendingUpload={(_file) => uploadFile(_file, formName, name)}
        display={() => <noscript />}
        onSubmit={handleSubmit}
      />
      <FormError response={uploadFileResponse} formKey="file" />
      <FormError response={uploadFileResponse} formKeyPath={['errors', 'non_field_errors']} />
    </div>
  );
}


const MultipleFileUpload = (props) =>
  <div className="multiple-file-upload">
    <SelectFile {...props} />
    <Files {...props} />
  </div>;

MultipleFileUpload.propTypes = {
  field: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  uploadFileResponse: ImmutablePropTypes.map,
};

export default MultipleFileUpload;
