import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { OrderedMap } from 'immutable';

import { REMINDER_CHOICES, REMINDER_CHOICES_TEXT, getRepeatOptions, toOptionValue, } from '^/models/task';

import DateInput from '^/components/app/forms/DateInput';
import Icon from '^/components/app/content/Icon';


const ReadOnlySuggestedTaskField = ({ suggestedTask, teamMembers }) =>
  <tr>
    <td className="top-aligned"><Icon type="check-square" className="inline-icon" /></td>
    <td className="top-aligned">
      <label className="padded reminder-label">{suggestedTask.get('title')}</label>
    </td>
    <td><DateInput value={suggestedTask.get('deadline')} disabled /></td>
    <td>
      <select className="small" value={suggestedTask.get('repeatOption')} disabled>
        {getRepeatOptions(suggestedTask).map(option =>
          <option key={option} value={toOptionValue(option)}>{option.get('text')}</option>)}
      </select>
    </td>
    <td>
      <select className="small" value={suggestedTask.get('reminder')} disabled>
        {OrderedMap(REMINDER_CHOICES).map(reminderValue =>
          <option key={reminderValue} value={reminderValue}>{REMINDER_CHOICES_TEXT[reminderValue]}</option>)}
      </select>
    </td>
    <td>
      <select className="small" value={suggestedTask.get('staff')} disabled>
        {teamMembers.map(member =>
          <option value={member.get('staffdetail')} key={member}>{member.get('full_name')}</option>)}
      </select>
    </td>
  </tr>;

ReadOnlySuggestedTaskField.propTypes = {
  suggestedTask: ImmutablePropTypes.map.isRequired,
  teamMembers: ImmutablePropTypes.list.isRequired,
};

export default ReadOnlySuggestedTaskField;
