import classNames from 'classnames';
import { Map } from 'immutable';
import moment from 'moment';
import React from 'react';

import Loading from '^/components/app/content/Loading';
import { EventRecordStatus, EventRecordStatusLabels } from '^/components/app/digital-tools/records/digital-records/constants';
import { INSIGHTS, INSIGHTS_API_URLS, PATHS } from '^/components/app/groups/insights/constants';
import withInsight from '^/components/app/groups/insights/hoc/withInsight';
import LandingCard from '^/components/app/groups/insights/LandingCard';
import { getSubGroupFilterFromPath } from '^/components/app/groups/insights/utils';

const STAT_FIGURE_CLASSNAMES = [
  'display-block',
  'bold-text',
  'font-size-90px',
];

export function EventRecordsLanding({
  data,
  isLoading,
  hasFailedToLoad,
  query
}) {
  return (
    <LandingCard
      title="Event records"
      link={PATHS.EVENT_RECORDS}
      linkText="VIEW MORE"
      lastUpdatedTimestamp={moment()}
      hasFailedToLoad={hasFailedToLoad}
      query={query}
    >
      {isLoading && <Loading/>}
      {(data && !isLoading) && (
        <div className="display-flex flex-wrap flex-justify-center base-font-color-secondary">
          <div className="text-center">
            <span className={classNames(STAT_FIGURE_CLASSNAMES, 'outstanding')}>
              {data.get('open')}
            </span>
            {EventRecordStatusLabels[EventRecordStatus.OPEN]}
          </div>
          <div className="text-center ml-6">
            <span className={classNames(STAT_FIGURE_CLASSNAMES, 'inprogress')}>
              {data.get('in_progress')}
            </span>
            {EventRecordStatusLabels[EventRecordStatus.IN_PROGRESS]}
          </div>
          <div className="text-center ml-6">
            <span className={classNames(STAT_FIGURE_CLASSNAMES, 'complete')}>
              {data.get('closed')}
            </span>
            {EventRecordStatusLabels[EventRecordStatus.CLOSED]}
          </div>
        </div>
      )}
    </LandingCard>
  );
}

export default withInsight({
  insight: INSIGHTS.EVENT_RECORDS_STATS,
  url: INSIGHTS_API_URLS.EVENT_RECORDS_STATS,
  getFilters: () => (
    Map({
      practice_sub_group_id: getSubGroupFilterFromPath(),
    })
  )
})(EventRecordsLanding);
