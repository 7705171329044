import React from 'react';
import { connect } from 'react-redux';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import AdminChangeLog, { reloadChangeLog } from '^/components/admin/content/change-log/AdminChangeLog';

import {
  ADMIN_TERMS_CREATE,
  ADMIN_TERMS_MODEL,
  ADMIN_TERMS_EDIT_FIELDS
} from './terms';


const EditTerms = function ({ termId }) {
  return (
    <div>
      <AdminContainer title="Terms">
        <AdminEdit
          itemId={termId}
          controlName={ADMIN_TERMS_CREATE}
          collectionName={ADMIN_TERMS_CREATE}
          onComplete={reloadChangeLog('Terms', termId)}
          model={ADMIN_TERMS_MODEL}
          fields={ADMIN_TERMS_EDIT_FIELDS}
          title="Edit Terms"
        />
      </AdminContainer>
      <AdminContainer>
        <AdminChangeLog model="Terms" id={termId} />
      </AdminContainer>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    termId: props.params.uuid,
  };
}

export default connect(mapStateToProps)(EditTerms);
