import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import {
  shouldSetupNextCycle,
  getSubsequentCycleSetupStageUrl,
  getNextCycleStartDate,
  nextCycleSetupComplete,
} from '^/consts/setup';
import { formatDate } from '^/utils';
import LiveButton from '^/components/app/forms/LiveButton';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import { openSetupCycleDateFormModal } from '^/actions/modals';

export const SubsequentCycleSetupAlertNewCycle = ({
  response,
  setupSubsequentCycle,
}) => (
  <HelpBlock className="small-text mb-1">
    <h5 className="m0 mb-1-2 mt-1-4 extra-bold-text">
      Set up next year's cycle
    </h5>
    <p>
      Your current cycle is nearing the end. To make sure you're ready to
      rollover to next year without interruption we recommend that you set up
      next year's cycle before this cycle ends.
    </p>
    <LiveButton
      className="btn btn-primary mt-1 pull-right"
      response={response}
      onClick={() => setupSubsequentCycle()}
    >
      Setup cycle now
    </LiveButton>
  </HelpBlock>
);

export const SubsequentCycleSetupAlertPartiallyComplete = ({ setupUrl }) => (
  <HelpBlock className="small-text mb-1">
    <h5 className="m0 mb-1-2 mt-1-4 extra-bold-text">
      Continue setup of next year's cycle
    </h5>
    <p>
      You or someone else at your practice has begun setting up next year's
      cycle. You can <Link to={setupUrl}>continue setting up the cycle</Link> at
      any time.
    </p>
    <Link to={setupUrl} className="btn btn-primary mt-1 pull-right">
      Continue setting up cycle
    </Link>
  </HelpBlock>
);

export const SubsequentCycleSetupAlertComplete = ({ nextCycleStartDate }) => (
  <HelpBlock className="small-text mb-1">
    <h5 className="m0 mb-1-2 mt-1-4 extra-bold-text">
      Next year's cycle is all ready!
    </h5>
    <p>It will begin automatically on {formatDate(nextCycleStartDate)}.</p>
  </HelpBlock>
);

export const SubsequentCycleSetupAlert = ({
  setupUrl,
  response,
  setupSubsequentCycle,
  shouldShowSetupNextCycle,
  nextCycleStartDate,
  shouldShowSetupNextCycleComplete,
  practice,
}) => {
  if (setupUrl) {
    return <SubsequentCycleSetupAlertPartiallyComplete setupUrl={setupUrl} />;
  }
  if (shouldShowSetupNextCycle) {
    return (
      <SubsequentCycleSetupAlertNewCycle
        response={response}
        setupSubsequentCycle={() => setupSubsequentCycle(practice)}
      />
    );
  }
  if (shouldShowSetupNextCycleComplete) {
    return (
      <SubsequentCycleSetupAlertComplete
        nextCycleStartDate={nextCycleStartDate}
      />
    );
  }
  return <noscript />;
};

export const mapStateToProps = state => {
  const practiceId = state.currentPractice && state.currentPractice.get('id');
  const user = state.userProfile;
  return {
    practice: state.currentPractice,
    shouldShowSetupNextCycleComplete: nextCycleSetupComplete(user, practiceId),
    shouldShowSetupNextCycle: shouldSetupNextCycle(user, practiceId),
    nextCycleStartDate: getNextCycleStartDate(user, practiceId),
    setupUrl: getSubsequentCycleSetupStageUrl(user, practiceId),
    response: state.responses.get('setupNewCycle'),
  };
};

export default connect(mapStateToProps, {
  setupSubsequentCycle: openSetupCycleDateFormModal,
})(SubsequentCycleSetupAlert);
