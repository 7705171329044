import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';

import AdminFormError from '^/components/admin/content/edit-create/dynamic-forms/AdminFormError';

const FieldSelect = (
  {readOnly, config, name, errors, value, onChange, className}
) => {
  return readOnly ? (
    <div className={classNames('mb-1-4', className)}>
      <label>{config.label}</label>
      <span>{value}</span>
    </div>
  ) : (
    <div className={classNames('mb-1-4', className)}>
      <div>
        <label>{config.label}:</label>
        <select
          value={value || ""}
          name={name}
          placeholder={config.placeholder}
          onChange={(e) => onChange(e.target.value)}
        >
            <option value="">--</option>
            {config.choices.map(choice =>
              <option key={choice.value} value={choice.value}>{choice.display_name}</option>)}
        </select>
      </div>
      <AdminFormError errors={errors} />
    </div>
  );
};

FieldSelect.propTypes = {
  readOnly: React.PropTypes.bool,
  config: React.PropTypes.object.isRequired,
  name: React.PropTypes.string.isRequired,
  errors: ImmutablePropTypes.list,
  value: React.PropTypes.any,
  onChange: React.PropTypes.func.isRequired,
  className: React.PropTypes.string,
};

export default FieldSelect;
