import React from 'react';

import FormError from '^/components/app/forms/FormError';


const CheckBoxGroupForGroupPractices = ({ field, response, options, selectAllID }) => {
  const handleCheckedBoxes = event => {
    const checkboxValue = event.target.value;
    const selectedValues = field.value || [];

    if (event.target.id === selectAllID) {
      return event.target.checked && checkboxValue !== ''
        ? field.onChange(checkboxValue.split(","))
        : field.onChange([]);
    }
    
    return event.target.checked
      ? field.onChange([...selectedValues, checkboxValue])
      : field.onChange(
          selectedValues.filter(
            selectedValue => selectedValue !== checkboxValue
          )
        );
  };

  const enabledOptionValues = options.filter(option => option.disableOption === false)

  return (
    <div className="form-group doc-read-team-members-wrapper">
      <div className="col-1 doc-read-team-members">
        <div className="doc-read-team-member">
          <label>
            <input
              id={selectAllID}
              type="checkbox"
              name={field.name}
              value={enabledOptionValues.map(option => option.value)}
              onChange={handleCheckedBoxes}
            />
            Select All
          </label>
        </div>
        {options.map(({ label, value, disableOption }, index) => (
          <div key={`label${index}`} className="doc-read-team-member">
            <label>
              <input
                type="checkbox"
                name={field.name}
                checked={
                  (field.value || []).filter(
                    selectedValue => selectedValue === value
                  ).length > 0
                }
                disabled={disableOption}
                value={value}
                onChange={handleCheckedBoxes}
              />
              {label}
            </label>
          </div>
        ))}
        <FormError response={response} formKey={field.name} />
        <FormError error={field && field.touched && field.error} />
      </div>
    </div>
  );
};

export default CheckBoxGroupForGroupPractices;
