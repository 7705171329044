import { fromJS } from 'immutable';


export const ADMIN_TEMPLATE_DOCUMENT_CREATE = 'ADMIN_TEMPLATE_DOCUMENT_CREATE';
export const ADMIN_TEMPLATE_DOCUMENT_MODEL = 'admin/template-documents';
export const ADMIN_TEMPLATE_DOCUMENT_CREATE_MODEL = 'admin/template-documents-create';
export const ADMIN_TEMPLATE_DOCUMENT_DRAFT_MODEL = 'admin/template-document-drafts';
export const ADMIN_TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE_MODEL = 'admin/template-document-membership-show-rules';
export const ADMIN_TEMPLATE_DOCUMENT_REGION_OVERRIDES_MODEL = 'admin/template-document-regional-overrides';
export const ADMIN_TEMPLATE_DOCUMENT_HIDE_RULES_MODEL = 'admin/doc-hide-rules';
export const ADMIN_TEMPLATE_DOCUMENT_REGION_HIDE_RULE_MODEL = 'admin/doc-region-hide-rules';
export const ADMIN_TEMPLATE_DOCUMENT_OPTIONS = 'TEMPLATE_DOCUMENT_OPTIONS';
export const ADMIN_TEMPLATE_DOCUMENT_MANAGE_ROUTE = '/admin/template-documents/${id}/';
export const ADMIN_TEMPLATE_DOCUMENT_DRAFT_MANAGE_ROUTE = '/admin/template-documents/drafts/${id}/edit';
export const ADMIN_TEMPLATE_DOCUMENT_FOLDER_HIDE_RULE_MODEL = 'admin/doc-folder-hide-rules'
export const ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE_MODEL = 'admin/doc-category-membership-permission-rules'


export const ADMIN_TEMPLATE_DOCUMENT_EDIT_FIELDS = fromJS([
  'sector', 'name', 'is_landscape', 'is_print_recommended', 'kind', 'enable_doc_reads',
  'is_secure_document', 'code', 'folder', 'template_category', 'help_text', 
  'keywords', 'priority',
]);

export const ADMIN_TEMPLATE_DOCUMENT_DRAFT_EDIT_FIELDS = fromJS([
  'sector', 'name', 'is_landscape', 'is_print_recommended', 'kind', 'is_secure_document',
  'code', 'folder', 'region_hide_rules', 'template_category', 'content',
]);

export const ADMIN_TEMPLATE_DOCUMENT_CREATE_FIELDS = fromJS([
  'sector', 'name', 'type', 'is_landscape', 'is_print_recommended', 'kind', 'is_secure_document',
  'code', 'folder', 'region_hide_rules', 'template_category', 'help_text', 'content', 'content_file', 'priority',
]);

export const ADMIN_TEMPLATE_DOCUMENT_HIDE_RULE_CREATE_FIELDS = fromJS(['attribute', 'attribute_value']);

export const ADMIN_TEMPLATE_DOCUMENT_HIDE_RULE_ROW_FIELDS = fromJS([
  {name: ['attribute_display'], display_name: 'Attribute', type: 'string', sortable: true},
  {name: ['attribute_value'], display_name: 'Attribute Value', type: 'boolean', sortable: true},
  {name: ['id'], display_name: 'Remove', type: 'remove'},
]);


export const ADMIN_TEMPLATE_DOCUMENT_ROW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['sector'], display_name: 'Sector', type: 'string', sortable: true},
  {name: ['version_number'], display_name: 'Version', type: 'string', sortable: true},
  {name: ['code'], display_name: 'Code', type: 'string', sortable: true},
  {name: ['folder_display'], display_name: 'Folder', type: 'string', sortable: true},
  {name: ['template_category_name'], display_name: 'Category', type: 'string', sortable: true},
  {name: ['created'], display_name: 'Created', type: 'datetime', sortable: true},
  {name: ['type'], display_name: 'Type', type:'string', sortable: true},
  {name: ['priority_display'], display_name: 'Priority', type: 'string', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Manage', route: ADMIN_TEMPLATE_DOCUMENT_MANAGE_ROUTE},
]);

export const ADMIN_TEMPLATE_DOCUMENT_DRAFT_ROW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['code'], display_name: 'Code', type: 'string', sortable: true},
  {name: ['folder_display'], display_name: 'Folder', type: 'string', sortable: true},
  {name: ['template_category_name'], display_name: 'Category', type: 'string', sortable: true},
  {name: ['created'], display_name: 'Created', type: 'datetime', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Manage', route: ADMIN_TEMPLATE_DOCUMENT_DRAFT_MANAGE_ROUTE},
]);

export const ADMIN_TEMPLATE_DOCUMENT_VIEW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string'},
  {name: ['version_number'], display_name: 'Version', type: 'string'},
  {name: ['code'], display_name: 'Code', type: 'string'},
  {name: ['folder'], display_name: 'Folder', type: 'string'},
  {name: ['template_category_name'], display_name: 'Category', type: 'string'},
  {name: ['created'], display_name: 'Created', type: 'datetime'},
  {name: ['type'], display_name: 'Type', type:'string'},
  {name: ['priority'], display_name: 'Priority', type: 'string', sortable: true},
]);

export const ADMIN_TEMPLATE_DOCUMENT_CATEGORY_CREATE = 'ADMIN_TEMPLATE_DOCUMENT_CATEGORY_CREATE';
export const ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MODEL = 'admin/template-document-categories';
export const ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MANAGE_ROUTE = '/admin/template-documents/categories/${id}/';

export const ADMIN_TEMPLATE_DOCUMENT_CATEGORY_EDIT_FIELDS = fromJS([
  'name', 'position'
]);

export const ADMIN_TEMPLATE_DOCUMENT_CATEGORY_ROW_FIELDS = fromJS([
  {name: ['position'], display_name: 'Position', type: 'position', sortable: true},
  {name: ['name'], display_name: 'Name', type: 'string', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Edit', route: ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MANAGE_ROUTE},
  {name: ['id'], display_name: 'Remove', type: 'remove', sortable: false},
]);

export const ADMIN_TEMPLATE_DOCUMENT_CATEGORY_VIEW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string'},
]);

export const ADMIN_TEMPLATE_DOCUMENT_FOLDER_CREATE = 'ADMIN_TEMPLATE_DOCUMENT_FOLDER_CREATE';
export const ADMIN_TEMPLATE_DOCUMENT_FOLDER_MODEL = 'admin/template-document-folders';
export const ADMIN_TEMPLATE_DOCUMENT_FOLDER_MANAGE_ROUTE = '/admin/template-documents/folders/${id}/';
export const ADMIN_TEMPLATE_DOCUMENT_PAGE = '/admin/activities/${id}';

export const ADMIN_TEMPLATE_DOCUMENT_FOLDER_EDIT_FIELDS = fromJS([
  'sector', 'name', 'number', 'position'
]);

export const ADMIN_TEMPLATE_DOCUMENT_FOLDER_ROW_FIELDS = fromJS([
  {name: ['position'], display_name: 'Position', type: 'position', sortable: true},
  {name: ['number'], display_name: 'Number', type: 'string', sortable: true},
  {name: ['name'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['sector_display'], display_name: 'Sector', type: 'string', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Edit', route: ADMIN_TEMPLATE_DOCUMENT_FOLDER_MANAGE_ROUTE},
]);

export const ADMIN_TEMPLATE_DOCUMENT_FOLDER_VIEW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string'},
]);

export const ADMIN_TEMPLATE_DOCUMENT_WORKING_VERSION_MODEL = 'admin/template-document-working-versions';
export const ADMIN_TEMPLATE_DOCUMENT_VERSION_MODEL = 'admin/template-document-versions';

export const TEMPLATE_DOCUMENT_DOCUMENT_HIDE_RULE = 'TEMPLATE_DOCUMENT_DOCUMENT_HIDE_RULE';

export function getAdminTemplateDocumentVersionRowFields(deleteHandle) {
  return fromJS([
    {name: ['version_number'], display_name: 'Version', type: 'string', sortable: true},
    {name: ['user', 'email'], display_name: 'Created by', type: 'string', sortable: true},
    {name: ['modified'], display_name: 'Created/modified on', type: 'datetime', sortable: true},
    {name: ['notes'], display_name: 'Notes', type: 'string'},
    {
      name: ['id'], display_name: 'Delete Alerts', type: 'function', sortable: false,
      link_text: 'Delete Alerts', handler: deleteHandle, prompt: true, hideIf: (alert) => !alert.get('alerts_count')
    },
  ]);
}

export const ADMIN_TEMPLATE_DOCUMENT_VERSION_EDIT_FIELDS = fromJS([
  'content', 'content_file'
]);

export const ADMIN_TEMPLATE_DOCUMENT_VERSION_VIEW_FIELDS = fromJS([
  {name: ['content'], display_name: 'Content', type: 'html'},
  {name: ['content_file'], display_name: 'File', type: 'string'},
]);

export const ADMIN_TEMPLATE_DOCUMENT_ACTIVITY_ROW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Activity', type: 'detail', route: ADMIN_TEMPLATE_DOCUMENT_PAGE, sortable: false},
  {name: ['id'], display_name: 'Remove', type: 'remove', sortable: false},
]);


export const DOCUMENT_TYPE_CONTENT_FIELDS = fromJS({
  content_file: ['FILE', ],
  content: ['HTML', ],
  is_landscape: ['HTML', ],
  kind: ['HTML', ],
  is_secure_document: ['HTML', ],
});

export const TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE = 'TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE';

export const ADMIN_TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE_ROW_FIELDS = fromJS([
  {name: ['base_membership_display'], display_name: 'Membership', type: 'string', sortable: false},
  {name: ['id'], display_name: 'Remove', type: 'remove'}
]);

export const ADMIN_TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE_CREATE_FIELDS = fromJS(['base_membership']);


export function transformMembershipShowRuleChoices(templateDocument, fieldConfig) {
  if (!templateDocument) {
    return fieldConfig;
  }

  const currentMembershipShowRules =
    templateDocument
      .get('membership_show_rules')
      .map(rule => rule.get('base_membership'));

  // filter out any rule choices that are already applied to the document
  return fieldConfig
    .updateIn(
      ['base_membership', 'choices'],
      choices => choices.filterNot(choice => currentMembershipShowRules.includes(choice.get('value')))
    );
}


export const TEMPLATE_DOCUMENT_REGION_OVERRIDES = 'TEMPLATE_DOCUMENT_REGION_OVERRIDES';

export const ADMIN_TEMPLATE_DOCUMENT_REGION_OVERRIDES_ROW_FIELDS = fromJS([
  {name: ['region_display'], display_name: 'Region', type: 'string', sortable: false},
  {name: ['priority_display'], display_name: 'Priority', type: 'string', sortable: false},
  {name: ['id'], display_name: 'Remove', type: 'remove'}
]);

export const ADMIN_TEMPLATE_DOCUMENT_REGION_OVERRIDES_CREATE_FIELDS = fromJS(['region', 'priority']);


export function transformRegionOverridesChoices(templateDocument, fieldConfig) {
  if (!templateDocument) {
    return fieldConfig;
  }

  const currentRegionOverrides =
    templateDocument
      .get('regional_overrides')
      .map(rule => rule.get('region'));

  // filter out any rule choices that are already applied to the document
  return fieldConfig
    .updateIn(
      ['region', 'choices'],
      choices => choices.filterNot(choice => currentRegionOverrides.includes(choice.get('value')))
    );
}


export const TEMPLATE_DOCUMENT_REGION_HIDE_RULE = 'TEMPLATE_DOCUMENT_REGION_HIDE_RULE';

export const ADMIN_TEMPLATE_DOCUMENT_REGION_HIDE_RULE_ROW_FIELDS = fromJS([
  {name: ['region_display'], display_name: 'Region', type: 'string', sortable: true},
  {name: ['id'], display_name: 'Remove', type: 'remove'},
]);

export const ADMIN_TEMPLATE_DOCUMENT_REGION_HIDE_RULE_CREATE_FIELDS = fromJS(['region']);


export const TEMPLATE_DOCUMENT_FOLDER_HIDE_RULE =
  'TEMPLATE_DOCUMENT_FOLDER_HIDE_RULE';

export const TEMPLATE_DOCUMENT_FOLDER_ROW_FIELDS = fromJS([
  {
    name: ['base_membership_display'],
    display_name: 'Base membership',
    type: 'string',
    sortable: false,
  },
  { name: ['id'], display_name: 'Remove', type: 'remove' },
]);

export const TEMPLATE_DOCUMENT_FOLDER_CREATE_FIELDS = fromJS([
  'base_membership',
]);

export const TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE =
  'TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE';

export const TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE_ROW_FIELDS = fromJS(
  [
    {
      name: ['base_membership_display'],
      display_name: 'Base membership',
      type: 'string',
      sortable: false,
    },
    {
      name: ['permission_display'],
      display_name: 'Permission',
      type: 'string',
      sortable: false,
    },
    { name: ['id'], display_name: 'Remove', type: 'remove' },
  ]
);

export const TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE_CREATE_FIELDS = fromJS(
  ['base_membership', 'permission']
);


export function transformRegionHideRuleChoices(templateDocument, fieldConfig) {
  if (!templateDocument) {
    return fieldConfig;
  }

  const currentRegionRules =
    templateDocument
      .get('region_hide_rules')
      .map(rule => rule.get('region'));

  // filter out any rule choices that are already applied to the document
  return fieldConfig
    .updateIn(
      ['region', 'choices'],
      choices => choices.filterNot(choice => currentRegionRules.includes(choice.get('value')))
    );
}

export function isKindPolicyOrOverview(templateDocument) {
  return ['POLICY', 'OVERVIEW'].includes(templateDocument.get('kind'))
}

export function getPolicyAndOverviewKindOptions(fieldConfig) {
  return fieldConfig.getIn(['kind', 'choices'], []).filter(
    choice => ['POLICY', 'OVERVIEW'].includes(choice.get('value'))
  )
}

export function transformDataToSubmit (data) {
  const nullFilteredData = fromJS(data).filterNot(
    value => value === undefined ||
    value === null ||
    value === '<p></p>'
  );
  if (
    !nullFilteredData.get('name') &&
    nullFilteredData.get('content_file') &&
    nullFilteredData.get('content_file').name
  ) {
    return nullFilteredData.set('name', nullFilteredData.get('content_file').name).toJS();
  }

  return nullFilteredData.toJS();
}

export function filterFolderBySector(fieldConfig, sector) {
  return fieldConfig.updateIn(
    ['folder', 'choices'],
    folders => folders.filter(folder => folder.get('sector') === sector)
    );
}


export function transformTemplateDocumentFolderHideRuleChoices(
  templateDocumentFolder,
  fieldConfig
) {
  if (!templateDocumentFolder) {
    return fieldConfig;
  }

  const currentHideRules = templateDocumentFolder
    .get('hide_rules')
    .map(rule => rule.get('base_membership'));

  // filter out any rule choices that are already applied to the folder
  return fieldConfig.updateIn(['base_membership', 'choices'], choices =>
    choices.filterNot(choice => currentHideRules.includes(choice.get('value')))
  );
}

export function transformTemplateDocumentCategoryMembershipPermissionRuleChoices(
  templateDocumentFolder,
  fieldConfig
) {
  if (!templateDocumentFolder) {
    return fieldConfig;
  }

  const currentPermissionRules = templateDocumentFolder
    .get('membership_permission_rules')
    .map(rule => rule.get('base_membership'));

  // filter out any rule choices that are already applied to the folder
  return fieldConfig.updateIn(['base_membership', 'choices'], choices =>
    choices.filterNot(choice => currentPermissionRules.includes(choice.get('value')))
  );
}
