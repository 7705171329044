import React from 'react';
import { connect } from 'react-redux';

import { hasSucceeded } from '^/consts/responseStates';
import { formatDateTime } from '^/utils';
import {
  isLiveCycleApplicationPending,
  hasLiveCycleApplicationFailed,
  hasLiveCycleApplicationSucceeded,
} from '^/models/activities';

import {
  clearActivityCycleApplicationProgress, checkTemplateActivityLiveApplicationProgress, storeApplyToLiveCyclesMonthOverride
} from '^/actions/actions';
import { openApplyTemplateActivityToLiveCyclesModal } from '^/actions/modals';

import AdminContainer from '^/components/admin/content/AdminContainer';
import Loading from '^/components/app/content/Loading';
import settings from '^/consts/settings';


const MONTH_CHOICES = [
  {value: 'JAN', text: 'January'},
  {value: 'FEB', text: 'Febuary'},
  {value: 'MAR', text: 'March'},
  {value: 'APR', text: 'April'},
  {value: 'MAY', text: 'May'},
  {value: 'JUN', text: 'June'},
  {value: 'JUL', text: 'July'},
  {value: 'AUG', text: 'August'},
  {value: 'SEP', text: 'September'},
  {value: 'OCT', text: 'October'},
  {value: 'NOV', text: 'November'},
  {value: 'DEC', text: 'December'},
];

const CanApplyMessage = ({ templateActivity }) =>
  <div className="mt-1 mb-1">
    <p>
      This activity is eligible be applied to live cycles -
      this will place it into all live cycles in its default month ({ templateActivity.get('default_month_display')}).
    </p>
    <p><strong>Warning:</strong> this is not revertable and can only be done once.</p>
    <p><strong>Warning:</strong> Only use Month Override if you MUST apply the activity to a specific month!</p>
  </div>;

const CantApplyMessage = () =>
  <div className="mt-1 mb-1">
    <p>This activity cannot be applied to live cycles. To be eligible the activity must:</p>
    <ul>
      <li>be active.</li>
      <li>never have been applied to live cycles.</li>
      <li>have no live instances.</li>
      <li>not be a monthly focus.</li>
    </ul>
  </div>;


const ApplicationPendingMessage = ({liveApplicationProgress}) =>
  isLiveCycleApplicationPending(liveApplicationProgress) ? (
    <div className="alert alert-info">
      <Loading className="inline small" /> Processing application to live cycles&hellip;
    </div>
  ) : <noscript />;

const ApplicationFailureMessage = ({liveApplicationProgress}) =>
  hasLiveCycleApplicationFailed(liveApplicationProgress) ? (
    <div className="alert alert-error">
      <p>The live application failed with the following error(s):</p>
      <p>{liveApplicationProgress.get('errors')}</p>
    </div>
  ) : <noscript />;

const ApplicationSuccessfulMessage = ({liveApplicationProgress}) =>
  hasLiveCycleApplicationSucceeded(liveApplicationProgress) ? (
    <div className="alert alert-success">
      <p>
        The template activity was successfully applied to all live cycles on {
          formatDateTime(liveApplicationProgress.get('completed_time'))
        }.
      </p>
    </div>
  ) : <noscript />;


function ApplyButtonAndMessage({templateActivity, applyToLiveCycles, monthOverride}) {

  const canApply = templateActivity.get('can_apply_to_live_cycles');

  return (
    <div>
      {
        canApply ?
          <CanApplyMessage templateActivity={templateActivity} /> :
          <CantApplyMessage />
      }

      <button
        className="btn btn-warning"
        onClick={() => canApply ? applyToLiveCycles(templateActivity, monthOverride) : null}
        disabled={!canApply}
      >
        Apply to Live Cycles
      </button>
    </div>
  );
}


export class ApplyTemplateActivityToLiveCycles extends React.Component {

  componentWillMount() {
    this.props.clearActivityCycleApplicationProgress();
    this.checkProgress(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const previousTemplateActivityId = this.props.templateActivity && this.props.templateActivity.get('id');
    const nextTemplateActivityId = nextProps.templateActivity && nextProps.templateActivity.get('id');

    if (previousTemplateActivityId !== nextTemplateActivityId) {
      this.props.clearActivityCycleApplicationProgress();
      this.checkProgress(nextProps);
    } else if (
      isLiveCycleApplicationPending(nextProps.liveApplicationProgress) &&
      hasSucceeded(nextProps.progressResponse)
    ) {
      window.setTimeout(() => this.checkProgress(nextProps), settings.POLL_INTERVAL);
    }
  }

  checkProgress(props) {
    if (props.templateActivity) {
      props.checkTemplateActivityLiveApplicationProgress(props.templateActivity.get('id'));
    }
  }

  render () {
    const {
      templateActivity, applyToLiveCycles, liveApplicationProgress, monthOverride
    } = this.props;

    if (!templateActivity) {
      return <Loading />;
    }

    return (
      <AdminContainer title="Apply Activity to Live Cycles" className="admin-edit-create-form">
        <ApplicationFailureMessage liveApplicationProgress={liveApplicationProgress} />
        <ApplicationPendingMessage liveApplicationProgress={liveApplicationProgress} />
        <ApplicationSuccessfulMessage liveApplicationProgress={liveApplicationProgress} />

        {
          (
            !isLiveCycleApplicationPending(liveApplicationProgress) &&
            !hasLiveCycleApplicationSucceeded(liveApplicationProgress)
          ) && (
            <div className="apply-to-live-cycles-button">
              <ApplyButtonAndMessage 
                templateActivity={templateActivity}
                applyToLiveCycles={applyToLiveCycles}
                monthOverride={monthOverride}
              />
              <div className="margin-top">
                <label>Month Override</label>
                <select 
                  id="apply-to-live-cycles-month-override"
                  onChange={(event) => this.props.storeApplyToLiveCyclesMonthOverride(event.target.value)}
                  defaultValue={monthOverride}
                >
                  <option value='' key=''>--</option>
                  { MONTH_CHOICES.map(item => <option value={item.value} key={item.value}>{item.text}</option>) }
                </select>
              </div>
            </div>
          )
        }
      </AdminContainer>
    );
  }
}

export function mapStateToProps(state) {
  return {
    liveApplicationProgress: state.templateActivityLiveCycleApplicationProgress,
    progressResponse: state.responses.get('checkTemplateActivityLiveApplicationProgress'),
    monthOverride: state.ui.get('applyToLiveCyclesMonthOverride'),
  };
}

export default connect(
  mapStateToProps,
  {
    applyToLiveCycles: openApplyTemplateActivityToLiveCyclesModal,
    clearActivityCycleApplicationProgress,
    checkTemplateActivityLiveApplicationProgress,
    storeApplyToLiveCyclesMonthOverride
  }
)(ApplyTemplateActivityToLiveCycles);
