import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { isAdmin } from '^/models/staffPractice';
import StaffMemberFilter from '^/components/app/dashboard/StaffMemberFilter';
import { getActivePracticeUsers } from '^/stateHelpers';


export const PracticeMembersFilter = props => {
  const { user, practiceMembers } = props;
  const ownMembership = practiceMembers && practiceMembers.find(each => each.getIn(['user', 'id']) === user.get('id'));
  const showFilter = ownMembership && isAdmin(ownMembership);

  return showFilter ? (
    <StaffMemberFilter label="Showing for" users={practiceMembers.map(each => each.get('user'))} />
  ) : <noscript />;
};


export function mapStateToProps(state) {
  return {
    user: state.userProfile,
    currentPractice: state.currentPractice,
    practiceMembers: getActivePracticeUsers(state)
  };
}

PracticeMembersFilter.propTypes = {
  currentPractice: ImmutablePropTypes.map,
};

export default connect(mapStateToProps)(PracticeMembersFilter);
