import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import { resetResponse } from '^/actions/actions';
import { markTaskAssigneeCompleteAndCloseModal } from '^/actions/actionSequences';
import { CompletionNotes } from '^/components/app/tasks/enduser/CompletionNotes';


const mapStateToProps = (state, props) => ({
  response: state.responses.getIn(['markTaskAssigneeComplete', props.assigneeId])
});

const mapDispatchToProps = (dispatch, props) => ({
  update: data => dispatch(markTaskAssigneeCompleteAndCloseModal(props.assigneeId, props.taskId, data)),
  reset: () => dispatch(resetResponse('markTaskAssigneeComplete')),
});

const AdminCompletionNotes = connect(mapStateToProps, mapDispatchToProps) (CompletionNotes);


const MarkTaskCompleteModal = ({taskAssignee, task, onCancel}) => {
  const { assignee_id, is_completed } = taskAssignee.toObject();

  return (
    <AdminCompletionNotes
      assigneeId={assignee_id}
      taskId={task.get('id')}
      isCompleted={is_completed}
      onCancel={onCancel}
      hideSave
    />
  );
};

export default connect(null, {onCancel: closeModal}) (MarkTaskCompleteModal);
