import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { getItemOptions } from '^/actions/items';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEditCreate from '^/components/admin/content/edit-create/AdminEditCreate';
import AdminChangeLog, { reloadChangeLog } from '^/components/admin/content/change-log/AdminChangeLog';
import AdminManyRelatedField from '^/components/admin/content/many-related-field/AdminManyRelatedField';
import ComplianceSystemFieldAddForm from './ComplianceSystemFieldAddForm';

import {
  ADMIN_COMPLIANCE_SYSTEM_CREATE,
  ADMIN_COMPLIANCE_SYSTEM_MODEL,
  ADMIN_COMPLIANCE_SYSTEM_EDIT_FIELDS,
  ADMIN_COMPLIANCE_SYSTEM_OPTIONS,
  ADMIN_COMPLIANCE_SYSTEM_CHILD_FIELDS
} from './compliance-systems';


export class EditComplianceSystems extends PureComponent {
  componentWillMount() {
    const { complianceSystemId, itemOptions } = this.props;
    if (itemOptions.isEmpty()) {
      this.getItemOptions(complianceSystemId);
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.complianceSystemId !== newProps.complianceSystemId) {
      this.getItemOptions(newProps.complianceSystemId);
    }
  }

  getItemOptions(complianceSystemId) {
    this.props.getItemOptions(ADMIN_COMPLIANCE_SYSTEM_MODEL, complianceSystemId, ADMIN_COMPLIANCE_SYSTEM_OPTIONS);
  }

  render() {
    const { complianceSystemId, itemOptions, complianceSystem } = this.props;
    return (
      <div>
        <AdminContainer title="Compliance System">
          <AdminEditCreate
            itemId={complianceSystemId}
            controlName={ADMIN_COMPLIANCE_SYSTEM_CREATE}
            collectionName={ADMIN_COMPLIANCE_SYSTEM_CREATE}
            onComplete={reloadChangeLog('CQCSystem', complianceSystemId)}
            transform={fieldConfig => fieldConfig.setIn(['sector', 'read_only'], true)}
            model={ADMIN_COMPLIANCE_SYSTEM_MODEL}
            fields={ADMIN_COMPLIANCE_SYSTEM_EDIT_FIELDS}
            title="Edit Compliance System"
          />
        </AdminContainer>
        <AdminContainer className="mt-2">
          <AdminManyRelatedField
            itemId={complianceSystemId}
            controlName={ADMIN_COMPLIANCE_SYSTEM_MODEL}
            options={itemOptions}
            model={ADMIN_COMPLIANCE_SYSTEM_MODEL}
            fieldName={['children']}
            fields={ADMIN_COMPLIANCE_SYSTEM_CHILD_FIELDS}
            listTitle="Systems"
            addItemTitle="Add another system"
            adminManyRelatedFieldAddType={ComplianceSystemFieldAddForm}
            defaultLookupFilters={{sector: complianceSystem && complianceSystem.get('sector')}}
            sortBy={['name']}
          />
        </AdminContainer>
        <AdminContainer>
          <AdminChangeLog model="CQCSystem" id={complianceSystemId} />
        </AdminContainer>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    complianceSystemId: props.params.uuid,
    itemOptions: state.items.getIn(['options', ADMIN_COMPLIANCE_SYSTEM_OPTIONS], Map()),
    complianceSystem: state.items.get(ADMIN_COMPLIANCE_SYSTEM_MODEL)
  };
}

export default connect(mapStateToProps, { getItemOptions })(EditComplianceSystems);
