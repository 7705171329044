import React, { MouseEvent, Component } from 'react';
import classNames from 'classnames';

export interface ButtonProp<Value extends string | number> {
  text: string;
  value: Value;
  selected?: boolean;
  disabled?: boolean;
}

interface BaseProps<Value extends string | number> {
  onSelect: (value: Value, event: MouseEvent<HTMLButtonElement>) => void;
}

interface ButtonProps<Value extends string | number> extends BaseProps<Value> {
  button: ButtonProp<Value>;
}

interface ButtonRowProps<Value extends string | number>
  extends BaseProps<Value> {
  buttons: ReadonlyArray<ButtonProp<Value>>;
}

class Button<Value extends string | number> extends Component<
  ButtonProps<Value>,
  {}
> {
  public render() {
    const { children, button } = this.props;

    return (
      <button
        onClick={this.onSelect}
        className={classNames('btn btn-row bold-text', {
          selected: button.selected,
        })}
        disabled={button.disabled}
      >
        {children}
      </button>
    );
  }

  private onSelect = (event: MouseEvent<HTMLButtonElement>) => {
    this.props.onSelect(this.props.button.value, event);
  };
}

const ButtonRow = <Value extends string | number>({
  onSelect,
  buttons,
}: ButtonRowProps<Value>) => (
  <div className="button-row">
    {buttons.map(button => (
      <Button key={button.value} button={button} onSelect={onSelect}>
        {button.text}
      </Button>
    ))}
  </div>
);

export default ButtonRow;
