import React from 'react';

import PracticeList from './PracticeList';

const PracticeListPage = () => (
  <div>
    <h1>Practice List</h1>
    <hr className="dark mb-2" />
    <PracticeList />
  </div>
);

export default PracticeListPage;
