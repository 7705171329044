import React from 'react';
import { connect } from 'react-redux';

import PureComponent from '^/components/common/PureComponent';
import { resetResponse } from '^/actions/actions';
import { patchItem } from '^/actions/items';
import { MY_TASK_ASSIGNEE } from '^/consts/collectionKeys';
import { patchItemThen } from '^/actions/actionSequences';

import Icon from '^/components/app/content/Icon';
import CompletionNotesForm from './CompletionNotesForm';
import Notes from '^/components/app/widgets/Notes';


export class CompletionNotes extends PureComponent {

  componentWillMount() {
    this.props.reset();
  }

  submitData(data, onMarkCompleteComplete = undefined) {
    const { reset, update } = this.props;

    reset();
    update(data, onMarkCompleteComplete);
  }

  markComplete(data) {
    this.submitData(
      Object.assign({}, data, {is_completed: true}),
      this.props.onMarkCompleteComplete
    );
  }

  render() {
    const { isCompleted, response, value, onCancel, hideSave } = this.props;
    return (
      <div className="mt-1">
        <h5>Completion notes</h5>

        <p className="help-block">
          <Icon type="lightbulb-o" className="tip-icon" />
          What you write here will show on your compliance report<br />
          Eg: what you did, and where you filed it.
        </p>

        <div className="form-group">
          {isCompleted ? (
            <div>
              <Notes emptyMessage="Please add some completion notes" value={value} />

              <div>
                <Icon type="check" className="complete-icon" /> Completed
              </div>
            </div>
          ) : (
            <CompletionNotesForm
              initialValues={{completion_notes: value}}
              response={response}
              onSubmit={data => this.submitData(data)}
              onMarkComplete={data => this.markComplete(data)}
              onCancel={onCancel}
              hideSave={hideSave}
            />
          )}

        </div>
      </div>
    );
  }
}

CompletionNotes.propTypes = {
  value: React.PropTypes.string.isRequired,
  id: React.PropTypes.string.isRequired,
  isCompleted: React.PropTypes.bool.isRequired,
};

export function mapStateToProps(state) {
  return {
    response: state.responses.getIn(['updateItem', MY_TASK_ASSIGNEE]),
  };
}

export function mapDispatchToProps(dispatch, props) {
  return {
    update: (data, onMarkCompleteComplete) => dispatch(
      onMarkCompleteComplete
        ? patchItemThen(onMarkCompleteComplete, MY_TASK_ASSIGNEE, props.id, data)
        : patchItem(MY_TASK_ASSIGNEE, props.id, data)
    ),
    reset: () => dispatch(resetResponse('updateItem')),
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (CompletionNotes);
