import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import AdminItemField from '^/components/admin/content/detail/AdminItemField';

const AdminDetailItem = ({item, row}) => (
  <tr>
    <td><strong>{row.get('display_name')}</strong></td>
    <td><AdminItemField item={item} field={row} /></td>
  </tr>
);

AdminDetailItem.propTypes = {
  item: ImmutablePropTypes.map.isRequired,
  row: ImmutablePropTypes.map.isRequired,
};

export default AdminDetailItem;
