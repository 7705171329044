import React from 'react';
import { List } from 'immutable';
import { isPending, hasSucceeded, hasFailed } from '^/consts/responseStates';
import Loading from '^/components/app/content/Loading';


export const SuccessMessage = ({response, children}) =>
  hasSucceeded(response) ? (
    <div className="mt-1 alert-success">{children}</div>
  ) : (
    <noscript />
  );

export const PendingMessage = ({response}) =>
  isPending(response) ? (
    <Loading />
  ) : (
    <noscript />
  );

export const FailureMessage = ({response}) =>
  hasFailed(response) ? (
    <div className="mt-1 small-text alert-error">
      <p>Failed to update.</p>
      {response.getIn(['errors', 'non_field_errors'], List()).map((error, idx) => <p key={idx}>{error}</p>)}
    </div>
  ) : (
    <noscript />
  );

const Messages = ({response, isEditing}) => (
  <div>
    <PendingMessage response={response} />
    <SuccessMessage response={response}>
      {isEditing ? 'Successfully updated.' : 'Successfully created.'}
    </SuccessMessage>
    <FailureMessage response={response} />
  </div>
);

export default Messages;
