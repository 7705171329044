import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Chart from '^/components/app/widgets/Chart';

import Placeholder from './Placeholder';
import { CHART_CONFIG, CHART_HEIGHT, CHART_ID, CHART_TYPE } from './constants';
import { transformChartData } from './utils';

const ActivitiesChart = (props) => {
  const transformDataFunction = props.transformChartData || transformChartData
  return (
    <div className="mt-1-2 mb-1-2">
      {props.hasFailedToLoad ? (
        <p className="text-error">Failed to load</p>
      ) : (
        <Chart
          options={CHART_CONFIG}
          data={transformDataFunction(props.data)}
          chartType={CHART_TYPE}
          height={CHART_HEIGHT}
          loader={<Placeholder />}
          graph_id={props.chartId || CHART_ID}
        />
      )}
    </div>
  );
};

ActivitiesChart.propTypes = {
  data: ImmutablePropTypes.map,
  hasFailedToLoad: React.PropTypes.bool
};

export default ActivitiesChart;
