import React from 'react';
import { connect } from 'react-redux';

import Loading from '^/components/app/content/Loading';
import PureComponent from '^/components/common/PureComponent';
import AuditsGroupConfigForm from '^/components/app/groups/group-config/AuditsGroupConfigForm';

import { excludeTemplateActivitiesByGroup } from '^/actions/actions';
import { isPending } from '^/consts/responseStates';
import { getAllCollection } from '^/actions/collections';
import { GROUP_CONFIG_AUDITS } from '^/consts/collectionKeys';

export class AuditsGroupConfigPage extends PureComponent {

  componentDidMount() {
    this.props.getAllCollection(GROUP_CONFIG_AUDITS);
  }

  transformAndSubmitData(data) {
    const submitData = Object.entries(data).map(
      item => ({template_activity: item[0], is_excluded: !item[1] })
    );
    
    this.props.excludeTemplateActivitiesByGroup(submitData);
  }

  render() {
    const { templateAudits, isLoading, excludeTemplateActivitiesByGroupResponse } = this.props;

    if (isLoading || !templateAudits) {
      return <Loading />;
    }

    return (
      <AuditsGroupConfigForm
        templateAudits={templateAudits}
        onSubmit={data => this.transformAndSubmitData(data)}
        response={excludeTemplateActivitiesByGroupResponse}
      />
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    templateAudits: state.collections.getIn([GROUP_CONFIG_AUDITS, 'items']),
    isLoading: isPending(state.responses.getIn(['getCollection', GROUP_CONFIG_AUDITS])),
    excludeTemplateActivitiesByGroupResponse: state.responses.get('excludeTemplateActivitiesByGroup'),
  };
};

export default connect(mapStateToProps,
  { getAllCollection, excludeTemplateActivitiesByGroup }
)(AuditsGroupConfigPage);
