import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';

import AdminFormError from '^/components/admin/content/edit-create/dynamic-forms/AdminFormError';
import Icon from '^/components/app/content/Icon';
import FileUpload from '^/components/app/forms/FileUpload';


export const FieldFile = (
  {
    readOnly, config, errors, value, handleSubmit, className, setUpload, formName, name
  }
) => {
  return readOnly ? (
    <div className={classNames('mb-1-4', className)}>
      <label>{config.label}</label>
      <a href={value}><Icon type="file-text-o" /> Download</a>
    </div>
  ) : (
    <div className={classNames('mb-1-4', className)}>
      <div>
        <label>{config.label}:</label>
        <FileUpload
          className="image-field" src={value}
          display={src =>
            src && <a href={src.preview || src} target="_blank">{src.name || config.fileName || 'Download'}</a>
          }
          setPendingUpload={(file) => setUpload(formName, name, file)} onSubmit={handleSubmit}
        />
      </div>
      <AdminFormError errors={errors} />
    </div>
  );
};

FieldFile.propTypes = {
  readOnly: React.PropTypes.bool,
  config: React.PropTypes.object.isRequired,
  name: React.PropTypes.string.isRequired,
  formName: React.PropTypes.string.isRequired,
  errors: ImmutablePropTypes.list,
  value: React.PropTypes.any,
  onChange: React.PropTypes.func.isRequired,
  className: React.PropTypes.string,
};

export default FieldFile;
