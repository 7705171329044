import React from 'react';

import PureComponent from '^/components/common/PureComponent';
import LiveButton from '^/components/app/forms/LiveButton';

export class PrintButton extends PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    window.print();
  }

  render() {
    return (
      <LiveButton
        onClick={this.onClick}
        className="btn btn-default ml-1"
      >
        Print
      </LiveButton>
    );
  }
}

export default PrintButton;
