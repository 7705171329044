import React from 'react';
import { connect } from 'react-redux';

import Icon from '^/components/app/content/Icon';
import { formatValue } from '^/utils';
import { getCurrentGroup } from '^/stateHelpers';
import { openEditGroupDetailsModal } from '^/actions/modals';

import PureComponent from '^/components/common/PureComponent';


export class GroupDetails extends PureComponent {
  render() {
    const { currentGroup } = this.props;
    return (
      <div>
        <h1 className="inline">My Group Details</h1>
        <button
          className="btn btn-default indented pull-right-sm"
          onClick={() => currentGroup && this.props.openEditModal(currentGroup)}
        >
          <Icon type="pencil" className="button-icon" /> Edit Group details
        </button>
        <div className="employee-details">
          <hr className="dark" />
          <div className="form-group">
            <div className="col-1-3 label-left">Group name</div>
            <div className="col-2-3">{formatValue(currentGroup, ['name'])}</div>
          </div>
          <hr className="thin" />
          <div className="form-group">
            <div className="col-1-3 label-left">Address Line 1</div>
            <div className="col-2-3">{formatValue(currentGroup, ['address_line_1'])}</div>
          </div>
          <div className="form-group">
            <div className="col-1-3 label-left">Address Line 2</div>
            <div className="col-2-3">{formatValue(currentGroup, ['address_line_2'])}</div>
          </div>
          <div className="form-group">
            <div className="col-1-3 label-left">Address Line 3</div>
            <div className="col-2-3">{formatValue(currentGroup, ['address_line_3'])}</div>
          </div>
          <div className="form-group">
            <div className="col-1-3 label-left">City</div>
            <div className="col-2-3">{formatValue(currentGroup, ['city'])}</div>
          </div>
          <div className="form-group">
            <div className="col-1-3 label-left">Country</div>
            <div className="col-2-3">{formatValue(currentGroup, ['country'])}</div>
          </div>
          <div className="form-group">
            <div className="col-1-3 label-left">Postcode</div>
            <div className="col-2-3">{formatValue(currentGroup, ['postcode'])}</div>
          </div>
          <hr className="thin" />
          <div className="form-group">
            <div className="col-1-3 label-left">Phone</div>
            <div className="col-2-3">{formatValue(currentGroup, ['phone'])}</div>
          </div>
          <div className="form-group">
            <div className="col-1-3 label-left">Email</div>
            <div className="col-2-3">
              <a
                href={`mailto:${formatValue(currentGroup, ['email'])}`}
                className="underlined"
              >
                {formatValue(currentGroup, ['email'])}
              </a>
            </div>
          </div>
          <div className="form-group">
            <div className="col-1-3 label-left">Website</div>
            <div className="col-2-3">{formatValue(currentGroup, ['url'])}</div>
          </div>
          <div className="form-group">
            <div className="col-1-3 label-left">Suppress ALL iComply document alerts at Practice level</div>
            <div className="col-2-3">
              {formatValue(currentGroup, ['suppress_all_code_document_alerts_to_practices'], 'boolean')}
            </div>
          </div>
          <div className="form-group">
            <div className="col-1-3 label-left">Override suppressed alerts at a Group Admin level</div>
            <div className="col-2-3">
              {formatValue(currentGroup, ['override_code_document_alert_suppression_to_group'], 'boolean')}
            </div>
          </div>
          <hr className="thin" />
          <div className="form-group">
            <div className="col-1-3 label-left">Logo</div>
            <div className="col-2-3">
              <img
                src={formatValue(currentGroup, ['logo'], 'logo')}
                alt="logo"
                style={{ maxWidth: '100%' }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentGroup: getCurrentGroup(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openEditModal: group => dispatch(openEditGroupDetailsModal(group))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails);
