import React from 'react';
import { CAPABILITIES } from '^/permissions/capabilities';
import IfCan from '^/components/common/IfCan';
import IfCant from '^/components/common/IfCant';
import AdminManyRelatedField from '^/components/admin/content/many-related-field/AdminManyRelatedField';
import { hideRemove } from '^/permissions/adminHelpers';

export default function _AdminManyRelatedField(props) {
  return (
    <span>
      <IfCan capability={CAPABILITIES.UPDATE} model={props.permsModel}>
        <AdminManyRelatedField {...props} />
      </IfCan>
      <IfCant capability={CAPABILITIES.UPDATE} model={props.permsModel}>
        <AdminManyRelatedField
          {...props}
          fields={hideRemove(props.fields)}
          hideAddItem
        />
      </IfCant>
    </span>
  );
}
