import React from 'react';
import { connect } from 'react-redux';

import { resetDemoAndLoadMe } from '^/actions/actionSequences';
import LiveButton from '^/components/app/forms/LiveButton';


export const ConfirmDemoResetModal = props =>
  <div className="mb-1">
    <p>Are you sure you want to reset the demo? Any saved data will be lost.</p>

    <LiveButton
      className="btn btn-default mt-1"
      onClick={props.resetDemoAndLoadMe}
      response={props.response}
      pendingMessage="Resetting demo mode..."
    >
      Confirm
    </LiveButton>
  </div>;

function mapStateToProps(state) {
  return {
    response: state.responses.get('resetDemo'),
  };
}

export default connect(
  mapStateToProps,
  {
    resetDemoAndLoadMe,
  }
)(ConfirmDemoResetModal);
