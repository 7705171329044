import React from 'react';
import { connect } from 'react-redux';

import { reassignAndSuspendMemberThenReloadPracticeMembers } from '^/actions/actionSequences';

import SuspendOrRetireTeamMemberPage from '^/components/app/practices/SuspendOrRetireTeamMemberPage';


const SuspendMemberModal = props =>
  <SuspendOrRetireTeamMemberPage
    {...props}
    action="suspend"
    onSubmit={props.reassignAndSuspendMemberThenReloadPracticeMembers}
  />;

export default connect(null, {
  reassignAndSuspendMemberThenReloadPracticeMembers
})(SuspendMemberModal);
