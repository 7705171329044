import { CurrentPractice } from './practices';

export enum UserTypeInPractice {
  PRACTICE_ADMIN = 'PRACTICE_ADMIN',
  PRACTICE_MANAGER = 'PRACTICE_MANAGER',
  TEAM_MEMBER = 'TEAM_MEMBER',
  READ_ONLY = 'READ_ONLY',
}

export interface CurrentUserProfile {
  id: string;
  user_type_in_current_practice: UserTypeInPractice;
  staffdetail: {
    current_practice: CurrentPractice;
  };
}
