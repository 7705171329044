import React from 'react';


export default class HTMLContent extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div dangerouslySetInnerHTML={{__html: this.props.text}} />
    );
  }
}
