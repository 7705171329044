import moment from 'moment';
import { formatBackendDate } from '^/utils';

export const START_MONTHS = {
  MONTH_1: 1,
  MONTH_2: 2,
  MONTH_3: 3,
  MONTH_4: 4,
  MONTH_5: 5,
  MONTH_6: 6,
  MONTH_7: 7,
  MONTH_8: 8,
  MONTH_9: 9,
  MONTH_10: 10,
  MONTH_11: 11,
  MONTH_12: 12,
};

export const START_MONTHS_TEXT = {
  [START_MONTHS.MONTH_1]: 'Month 1',
  [START_MONTHS.MONTH_2]: 'Month 2',
  [START_MONTHS.MONTH_3]: 'Month 3',
  [START_MONTHS.MONTH_4]: 'Month 4',
  [START_MONTHS.MONTH_5]: 'Month 5',
  [START_MONTHS.MONTH_6]: 'Month 6',
  [START_MONTHS.MONTH_7]: 'Month 7',
  [START_MONTHS.MONTH_8]: 'Month 8',
  [START_MONTHS.MONTH_9]: 'Month 9',
  [START_MONTHS.MONTH_10]: 'Month 10',
  [START_MONTHS.MONTH_11]: 'Month 11',
  [START_MONTHS.MONTH_12]: 'Month 12',
};

export function getCycleEndDate(start, end) {
  const numMonthsToAdd = 12 - ((START_MONTHS[`MONTH_${end}`] || 2) - 1);
  return start && formatBackendDate(moment(start).add(numMonthsToAdd, 'months').endOf('month'));
}

export function getCycleStartDate(start, end) {
  return start && formatBackendDate(moment(start).subtract(end - 1, 'months'));
}
