import { Map, fromJS } from 'immutable';

import {
  LOAD_DASHBOARD_ACTIVITY_AND_UPDATED_TEMPLATES,
  DECREMENT_UPDATED_NEW_TEMPLATE_ALERTS_COUNT,
  DOCUMENT_REVIEWS_COUNT,  
} from '^/actions/dashboardActivitiesAndUpdatedTemplates';
import { UPDATED_NEW_TEMPLATES_COUNT } from '^/components/app/dashboard/DashboardActivitiesAndUpdatedTemplate';


export function dashboardActivitiesAndUpdatedTemplates(state = Map(), action) {
  switch (action.type) {
    case LOAD_DASHBOARD_ACTIVITY_AND_UPDATED_TEMPLATES.REQUEST:
      return null;
    case LOAD_DASHBOARD_ACTIVITY_AND_UPDATED_TEMPLATES.SUCCESS:
      return fromJS(action.payload);
    case DECREMENT_UPDATED_NEW_TEMPLATE_ALERTS_COUNT:
      const updatedNewTemplatesCount = state.get(UPDATED_NEW_TEMPLATES_COUNT);
      return updatedNewTemplatesCount ? state.set(UPDATED_NEW_TEMPLATES_COUNT, updatedNewTemplatesCount - 1) : state;
    default:
      return state;
  }
}

export function documentReviewsCount(state = Map(), action) {
  switch(action.type) {
    case DOCUMENT_REVIEWS_COUNT.REQUEST:
      return null;
    case DOCUMENT_REVIEWS_COUNT.SUCCESS:
      return fromJS(action.payload);
    default:
      return state;
  }
}
