import React from 'react';
import { connect } from 'react-redux';

import LiveButton from '^/components/app/forms/LiveButton';
import PureComponent from '^/components/common/PureComponent';

import { closeModal } from '^/actions/modals';
import { updateDynamicChecklistAndCloseModal } from '^/actions/actionSequences';

export class RefreshChecklistConfirmationModal extends PureComponent {
  render() {
    const { onClose, recordGroupId, date } = this.props;

    return (
      <div className="text-center">
        <div className="leaving-modal-list">
          <p className="mt-1">
            Are you sure you want to refresh this checklist?
          </p>
          <p className="mt-1">
            The entire checklist will be reset and the checklist process will have to be repeated.
          </p>
        </div>
        <div className="leaving-modal-buttons">
          <LiveButton
            className="btn btn-leaving-modal inline-spaced"
            onClick={() => this.props.updateDynamicChecklistAndCloseModal(recordGroupId, date)}
          >
            Refresh
          </LiveButton>
          <LiveButton
            className="btn btn-leaving-modal inline-spaced"
            onClick={onClose}
          >
            Cancel
          </LiveButton>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    response: state.responses.get('updateDynamicChecklistSnapshot')
  };
};

export default connect(mapStateToProps, {
  onClose: closeModal,
  updateDynamicChecklistAndCloseModal,
})(RefreshChecklistConfirmationModal);
