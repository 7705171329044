import React from 'react';
import { connect } from 'react-redux';

import { getSetupTeamMembers, setFormError } from '^/actions/actions';
import { setupCycleRemindersAndReloadUser } from '^/actions/actionSequences';
import { getAllCollection } from '^/actions/collections';
import { TEMPLATE_TASKS } from '^/consts/collectionKeys';
import { getCurrentPracticeId } from '^/models/user';
import { getCycleToSetupId } from '^/consts/setup';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import AccountSetupHeader from '^/components/app/account-set-up/AccountSetupHeader';
import SuggestedTasksSetupForm from '^/components/app/account-set-up/SuggestedTasksSetupForm';
import { getCycleEndDate, getCycleStartDate } from '^/models/cycle';
import { transformSuggestedTaskData } from './utils';
import { getActiveCycleSetUpPracticeMembers } from '^/stateHelpers';

export class SuggestedTasksSetupPage extends PureComponent {

  componentWillMount() {
    this.loadData(this.props.practiceId);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.practiceId !== newProps.practiceId) {
      this.loadData(newProps.practiceId);
    }
  }

  loadData(practiceId) {
    if (practiceId) {
      this.props.getSetupTeamMembers(practiceId);
      this.props.getAllCollection(TEMPLATE_TASKS);
    }
  }

  transformAndSubmitData(data) {
    const { cycleId } = this.props;
    const dataToSubmit = {
      start_date: getCycleStartDate(data.start_date, data.end_date),
      end_date: getCycleEndDate(data.start_date, data.end_date),
      template_tasks: transformSuggestedTaskData(data),
      other_tasks: []
    }; // reconstruct data for submitting

    this.props.setupCycleRemindersAndReloadUser(cycleId, dataToSubmit);
  }

  render () {
    const { user, teamMembers, templateTasks, practice, response } = this.props;

    if (!user || !practice || !teamMembers || !templateTasks) {
      return <Loading />;
    }

    return (
      <div>
        <AccountSetupHeader user={user} practice={practice}>
          Setup - Suggested Tasks
        </AccountSetupHeader>

        <SuggestedTasksSetupForm
          response={response}
          teamMembers={teamMembers}
          user={user}
          templateTasks={templateTasks}
          onSubmit={data => this.transformAndSubmitData(data)}
        />

      </div>
    );
  }

}

export const mapStateToProps = (state) => {
  const practiceId = getCurrentPracticeId(state.userProfile);
  return {
    user: state.userProfile,
    practiceId,
    cycleId: getCycleToSetupId(state.userProfile, practiceId),
    teamMembers: getActiveCycleSetUpPracticeMembers(state),
    practice: state.currentPractice,
    response: state.responses.get('setupCycleReminders'),
    templateTasks: state.collections.getIn([TEMPLATE_TASKS, 'items']),
  };
};

export default connect(mapStateToProps, {
  getSetupTeamMembers, setupCycleRemindersAndReloadUser, getAllCollection, setFormError
}) (SuggestedTasksSetupPage);
