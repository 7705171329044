import React from 'react';
import { reset } from 'redux-form';
import { connect } from 'react-redux';

import { PRACTICES } from '^/consts/collectionKeys';
import { patchItemSetUpdatingForm } from '^/actions/actionSequences';
import { transformInitialValues } from '^/models/practice';
import { openEditPracticeModal } from '^/actions/modals';

import PureComponent from '^/components/common/PureComponent';
import Icon from '^/components/app/content/Icon';
import Loading from '^/components/app/content/Loading';
import PracticeDetailsDisplay from '^/components/app/practices/PracticeDetailsDisplay';
import PracticeDetailsViewForm, { getPracticeDetailsFields } from '^/components/app/practices/forms/PracticeDetailsViewForm';
import ShowIfAdmin from '^/components/app/perms/ShowIfAdmin';


export class PracticeDetails extends PureComponent {

  showEditModal() {
    this.props.openEditModal(this.props.practice);
  }

  render() {
    const { practice, updatePractice, resetForm, response, pendingFields } = this.props;

    if (!practice) {
      return <Loading />;
    }

    return (
      <div>
        <h5>{practice.getIn(['name'], '--')}:</h5>
        <ShowIfAdmin>
          <button className="btn btn-default indented pull-right-sm" onClick={() => this.showEditModal()}>
            <Icon type="pencil" className="button-icon" /> Edit practice details
          </button>
        </ShowIfAdmin>
        <h1>Practice details</h1>
        <hr className="dark" />
        <PracticeDetailsDisplay practice={practice} />
        <ShowIfAdmin>
          <PracticeDetailsViewForm
            response={response}
            valuesChanged={data => updatePractice(data)}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
            initialValues={transformInitialValues(practice)}
            pendingFields={pendingFields}
            resetForm={resetForm}
            fields={getPracticeDetailsFields(practice)}
            practice={practice}
          />
        </ShowIfAdmin>
      </div>
    );
  }
}


export function mapStateToProps(state) {
  return {
    response: state.responses.getIn(['updateItem', PRACTICES]),
    pendingFields: state.pendingFormFields.get(PRACTICES),
    practice: state.currentPractice,
  };
}

export function mapDispatchToProps(dispatch, props) {
  const id = props.params.uuid;
  return {
    updatePractice: data => dispatch(patchItemSetUpdatingForm(PRACTICES, id, data)),
    openEditModal: practice => dispatch(openEditPracticeModal(practice)),
    resetForm: formName => dispatch(reset(formName)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PracticeDetails);
