import React from 'react';
import { reduxForm } from 'redux-form';
import LiveButton from '^/components/app/forms/LiveButton';
import FormError from '^/components/app/forms/FormError';
import DateInput from '^/components/app/forms/DateInput';
import TimeInput from '^/components/app/forms/TimeInput';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';
import { FORMAT_DATE_PICKER } from '^/utils';


export const COLLAPSE_ID = 'PERSONAL_DETAILS_FORM_COLLLAPSE';

export const PersonalDetailsSetupForm = ({
  fields: { phone, mobile, birth_date, alert_time }, handleSubmit, response
}) => {

  return (
    <form onSubmit={handleSubmit}>

      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor="phone" className="label-left padded">Phone</label>
          <span className="help-inline">required</span>
          </div>
        <div className="col-2-3">
          <input type="text" id="phone" placeholder="eg. +44 1230 456789" {...phone} />
          <FormError response={response} formKey="phone" />
        </div>
      </div>

      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor="mobile" className="label-left padded">Mobile</label>
          <span className="help-inline">required</span>
        </div>
        <div className="col-2-3">
          <input type="text" id="mobile" placeholder="eg. +44 1230 456789" {...mobile} />
          <FormError response={response} formKey="mobile" />
        </div>
      </div>

      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor="birth_date" className="label-left padded">Date of birth</label>
          <span className="help-inline">required</span>
          </div>
        <div className="col-2-3">
          <DateInput id="birth_date" placeholder={FORMAT_DATE_PICKER} {...birth_date} />
          <FormError response={response} formKey="birth_date" />
        </div>
      </div>

      <div className="mb-2 optional-field">
        <CollapseButton collapseId={COLLAPSE_ID} className="optional-field-toggle">
          change preferred email alert time (currently {alert_time.value})
        </CollapseButton>

        <Collapse initiallyCollapsed collapseId={COLLAPSE_ID}>
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="alert_time" className="label-left padded">Email alert time</label>
              <span className="help-inline">required</span>
              </div>
            <div className="col-2-3">
              <TimeInput id="alert_time" {...alert_time} />
              <FormError response={response} formKey="alert_time" />
            </div>
          </div>
        </Collapse>
      </div>

      <LiveButton pendingMessage="Saving..." response={response}>
        Next...
      </LiveButton>
    </form>
  );
};

export default reduxForm({
  form: 'setupPersonalDetails',
  fields: ['phone', 'mobile', 'birth_date', 'alert_time'],
})(PersonalDetailsSetupForm);
