import React from 'react';

import NonFieldErrorRenderer from '^/components/common/NonFieldErrorRenderer';
import { asPercent } from '^/utils';
import Loading from '^/components/app/content/Loading';
import { HealthcheckAuditResult } from './types';

interface Props {
  isLoading: boolean;
  results: HealthcheckAuditResult[] | null;
}

const AuditResultsTable = ({ isLoading, results }: Props) => {
  if (isLoading) {
    return <Loading />;
  }

  if (!results) {
    return (
      <NonFieldErrorRenderer
        errors={['Could not get audit results by section.']}
      />
    );
  }

  return (
    <div className="audit-results-table">
      <table>
        <tbody>
          {results.map(result => {
            return (
              <tr key={result.id}>
                <td>{result.name}</td>
                <td>{asPercent(result.score)}%</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AuditResultsTable;
