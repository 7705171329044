import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import { connect } from 'react-redux';
import { exportTable } from '^/actions/actionSequences';
import LiveButton from '^/components/app/forms/LiveButton';

export class ExportActivities extends PureComponent {
  handleSubmit(evt) {
    evt.preventDefault();  // Stop reloading the page
    this.props.exportTable('TemplateActivity');
  }

  render() {
    const response = this.props.response;
    return (
      <form className="admin-edit-create-form">
        <LiveButton response={response} onClick={this.handleSubmit.bind(this)}>
          Export Activities
        </LiveButton>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.get('exportTableUrl')
  };
}


export default connect(mapStateToProps, { exportTable })(ExportActivities);
