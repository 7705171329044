export const STYLE = {
  borderWidth: 2,
  borderColor: '#ddd',
  borderStyle: 'dashed',
  borderRadius: 2,
  marginBottom: 8,
  padding: 8,
};

export const ACTIVE_STYLE = Object.assign(
  {},
  STYLE,
  {
    borderStyle: 'solid',
    backgroundColor: '#eee',
  }
);

export const DISABLED_STYLE = Object.assign(
  {},
  STYLE,
  {
    opacity: 0.6,
  }
);

export const REJECT_STYLE = Object.assign(
  {},
  STYLE,
  {
    borderStyle: 'solid',
    backgroundColor: '#fdd',
  }
);
