export const DATE_LAST_DONE = 'date_last_done';
export const DUE_DATE = 'due_date';
export const NEVER_DONE = 'never_done';
export const ADMIN_STEP_START_DAY = 'admin_step_start_day';
export const MEETING_DAY = 'meeting_day';
export const MEETING_TIME = 'meeting_time';
export const INCLUDED = 'included';
export const DEADLINE = 'deadline';
export const REMINDER = 'reminder';
export const REPEAT = 'repeatOption';
export const STAFF = 'staff';
export const TITLE = 'title';
export const ENTRY = 'entry';
export const SECURITY_AUDIT_START_DAY = 'security_audit_start_day';
