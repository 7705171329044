import { fromJS } from 'immutable';

export const ADMIN_DISCOUNT_CODES_MODEL = 'admin/discount-codes';

export const ADMIN_DISCOUNT_CODES_ROW_FIELDS = fromJS([
  {name: ['code'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['discount_percent'], display_name: '% discount', type: 'string', sortable: true},
  {name: ['id'], display_name: 'Remove', type: 'remove', sortable: false},
]);

export const ADMIN_DISCOUNT_CODES_CREATE_FIELDS = fromJS([
  'code', 'discount_percent'
]);
