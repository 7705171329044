import React from 'react';
import { connect } from 'react-redux';

import { CYCLES } from '^/consts/collectionKeys';
import { loadItem } from '^/actions/items';
import { loadTemplateActivities, loadTemplateActivityTypeLastDone } from '^/actions/actions';
import { setupCycleReviewMeetingsAndRedirectToReviewTasks } from '^/actions/actionSequences';

import { getCurrentPracticeId } from '^/models/user';
import { getCycleToSetupId } from '^/consts/setup';

import PureComponent from '^/components/common/PureComponent';
import AccountSetupHeader from '^/components/app/account-set-up/AccountSetupHeader';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import Loading from '^/components/app/content/Loading';
import MeetingsSetupForm from '^/components/app/account-set-up/MeetingsSetupForm';


export const MEETINGS_TEMPLATE_TYPE = 'activities/setup/meetings';

export class CycleSetupReviewMeetingDaysPage extends PureComponent {

  componentWillMount() {
    const { practiceId, cycleId } = this.props;
    this.loadPracticeCycleAndTemplateActivities(practiceId, cycleId);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.practiceId !== newProps.practiceId || this.props.cycleId !== newProps.cycleId) {
      this.loadPracticeCycleAndTemplateActivities(newProps.practiceId, newProps.cycleId);
    }
  }

  loadPracticeCycleAndTemplateActivities(practiceId, cycleId) {
    if (practiceId && cycleId) {
      this.props.loadItem(CYCLES, cycleId);
      this.props.loadTemplateActivities(MEETINGS_TEMPLATE_TYPE, practiceId, cycleId);
      this.props.loadTemplateActivityTypeLastDone(MEETINGS_TEMPLATE_TYPE, cycleId);
    }
  }

  render() {

    const {
      user, practice, response, cycle, templateMeetings, templateActivityTypeLastDone, cycleId
    } = this.props;

    if (!user || !cycle || !practice || !templateMeetings || !templateActivityTypeLastDone) {
      return <Loading />;
    }

    return (
      <div>

        <AccountSetupHeader user={user} practice={practice}>
          Review meeting days
        </AccountSetupHeader>

        <HelpBlock>
          <p className="small-text">
            Confirm your preferred days for the meetings below.
            If you need to, you can change these once the cycle is setup.
            Other required meetings are listed at the bottom of this page.
          </p>
        </HelpBlock>


        <MeetingsSetupForm
          response={response}
          cycleStartingMonth={cycle.get('start_month', 0) + 1}  // This is an index so add 1 for the non l33t h4x0rz
          templateMeetings={templateMeetings}
          onSubmit={data => this.props.setup(cycleId, data)}
          initialValues={{ PRACTICE_MEETING: templateActivityTypeLastDone.toJS()
          }}
        />

      </div>
    );
  }

}


export const mapStateToProps = (state) => {
  const practiceId = getCurrentPracticeId(state.userProfile);
  return {
    user: state.userProfile,
    practiceId,
    cycleId: getCycleToSetupId(state.userProfile, practiceId),
    practice: state.currentPractice,
    templateActivityTypeLastDone: state.templateActivityTypeLastDone.get(MEETINGS_TEMPLATE_TYPE),
    templateMeetings: state.templateActivities.get(MEETINGS_TEMPLATE_TYPE),
    cycle: state.items.get(CYCLES),
    response: state.responses.get('setupMeetings'),
  };
};

export default connect(mapStateToProps,
  {
    loadItem,
    loadTemplateActivities,
    loadTemplateActivityTypeLastDone,
    setup: setupCycleReviewMeetingsAndRedirectToReviewTasks
  }
) (CycleSetupReviewMeetingDaysPage);
