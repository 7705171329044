import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import FieldChar from '^/components/admin/content/edit-create/dynamic-forms/field-types/FieldChar';
import FieldDate from '^/components/admin/content/edit-create/dynamic-forms/field-types/FieldDate';
import FieldNumber from '^/components/admin/content/edit-create/dynamic-forms/field-types/FieldNumber';
import FieldImage from '^/components/admin/content/edit-create/dynamic-forms/field-types/FieldImage';
import FieldFile from '^/components/admin/content/edit-create/dynamic-forms/field-types/FieldFile';
import FieldSelect from '^/components/admin/content/edit-create/dynamic-forms/field-types/FieldSelect';
import FieldBoolean from '^/components/admin/content/edit-create/dynamic-forms/field-types/FieldBoolean';
import FieldLookup from '^/components/admin/content/edit-create/dynamic-forms/field-types/FieldLookup';
import FieldStatic from '^/components/admin/content/edit-create/dynamic-forms/field-types/FieldStatic';
import FieldTinyMCE from '^/components/admin/content/edit-create/dynamic-forms/field-types/FieldTinyMCE';
import FieldMultiSelect from '^/components/admin/content/edit-create/dynamic-forms/field-types/FieldMultiSelect';
import FieldMultiCheckbox from '^/components/admin/content/edit-create/dynamic-forms/field-types/FieldMultiCheckbox';

function renderField(fieldConfig, fieldProps) {
  switch (fieldConfig.get('type')) {
    case 'date':
      return <FieldDate {...fieldProps} />;
    case 'image upload':
      return <FieldImage {...fieldProps} />;
    case 'file upload':
      return <FieldFile {...fieldProps} />;
    case 'integer':
    case 'float':
      return <FieldNumber {...fieldProps} />;
    case 'boolean':
      return <FieldBoolean {...fieldProps} />;
    case 'lookup':
      return <FieldLookup {...fieldProps} />;
    case 'static':
      return <FieldStatic {...fieldProps} />;
    case 'html':
      return <FieldTinyMCE {...fieldProps} />;
    case 'multiple choice':
      return <FieldMultiSelect {...fieldProps} />;
    case 'multiple check-box':
      return <FieldMultiCheckbox {...fieldProps} />;
    default:
      if (fieldConfig.get('choices')) {
        return <FieldSelect {...fieldProps} />;
      }
      return <FieldChar {...fieldProps} />;
  }
}

export const Field = ({
  fieldConfig,
  field,
  setUpload,
  readOnly,
  className,
  errors,
  formName,
  isEditing,
  defaultFilters,
}) => {
  const fieldProps = Object.assign(
    {},
    field,
    { errors, setUpload, className, formName, isEditing },
    {
      readOnly: readOnly || fieldConfig.get('read_only'),
      config: fieldConfig.toJS(),
      defaultFilters: defaultFilters && defaultFilters.toJS(),
    }
  );

  const renderedField = renderField(fieldConfig, fieldProps);

  const { help_text } = fieldConfig.toObject();
  if (help_text) {
    return (
      <div>
        <p className="admin-helptext">{help_text}</p>
        {renderedField}
      </div>
    );
  }

  return renderedField;
};

Field.propTypes = {
  formName: React.PropTypes.string.isRequired,
  field: React.PropTypes.object.isRequired,
  fieldConfig: ImmutablePropTypes.map.isRequired,
  errors: ImmutablePropTypes.list,
  readOnly: React.PropTypes.bool,
  className: React.PropTypes.string,
  isEditing: React.PropTypes.bool,
  defaultFilters: React.PropTypes.map,
};

export default Field;
