import React from 'react';
import { connect } from 'react-redux';

import { setupPersonalDetailsAndReloadUser } from '^/actions/actionSequences';
import Loading from '^/components/app/content/Loading';
import PersonalDetailsSetupForm from '^/components/app/account-set-up/PersonalDetailsSetupForm';
import AccountSetupHeader from '^/components/app/account-set-up/AccountSetupHeader';

export const PersonalDetailsSetUpPage = ({user, setup, response}) => {

  if (!user) {
    return <Loading />;
  }

  return (
    <div>
      <AccountSetupHeader user={user}>
        Setup - personal details
      </AccountSetupHeader>

        <div className="row mb-1">
          <div className="col-2-3">
            <div className="speechbubble welcome">
              <strong>Welcome</strong> ...as this is your first time logging into iComply, let's
                 set up your first practice.
              You can add a practice, add your colleagues and team members, and set up your first compliance cycle for
              the next year. Start by adding some personal details.
            </div>
          </div>

      </div>

      <hr className="thin" />

    <h3>Enter your details:</h3>
      <PersonalDetailsSetupForm
        initialValues={{
          birth_date: user.getIn(['staffdetail', 'birth_date'], ''),
          phone: user.getIn(['staffdetail', 'phone'], ''),
          mobile: user.getIn(['staffdetail', 'mobile'], ''),
          alert_time: user.get('alert_time') || '9:00'
        }}
        user={user}
        onSubmit={data => setup(user.get('id'), data)}
        response={response}
      />
    </div>
  );
};

export const mapStateToProps = (state) => ({
  user: state.userProfile,
  response: state.responses.get('setupPersonalDetails'),
});

export default connect(
  mapStateToProps,
  { setup: setupPersonalDetailsAndReloadUser }
) (PersonalDetailsSetUpPage);
