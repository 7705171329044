import React from 'react';
import { connect } from 'react-redux';
import PureComponent from '^/components/common/PureComponent';
import { getAllCollection, clearCollection} from '^/actions/collections';
import { COMPLIANCE_REPORT } from '^/consts/collectionKeys';
import { List } from 'immutable';
import { isPending } from '^/consts/responseStates';
import Loading from '^/components/app/content/Loading';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';
import { pluralize } from '^/utils';
import ExpandAllButton from '^/components/app/content/ExpandAllButton';
import DownloadReportButton from './DownloadReportButton';
import ActivitiesListing, { getCollectionName } from './ActivitiesListing';

const SectionHeader = ({section, activities}) => {
  const parent = section.get('parent');
  return (
    <div className="section-header">
      <h3>
        {parent.get('name')} {parent.get('number')}.{section.get('number')}&nbsp;
        <span className="small-text lighten-text">
          ({pluralize(activities.count(), 'activity', 'activities')})
        </span>
      </h3>
      <div className="section-name small-text lighten-text mb-1">
        {section.get('name')}
      </div>
    </div>
  );
};

export class SystemsListing extends PureComponent {
  componentWillMount() {
    this.props.clearCollection(COMPLIANCE_REPORT);
  }

  componentWillReceiveProps(newProps) {
    const { filter: newFilter } = newProps;
    const { filter } = this.props;
    const filterChanged = newFilter && !newFilter.equals(filter);

    if (filterChanged) {
      if (newFilter.get('cycle') && newFilter.get('cqc_system')) {
        this.props.systems.forEach((system) => this.props.clearCollection(getCollectionName(system.get('id'))));
        this.props.getAllCollection(COMPLIANCE_REPORT, {filters: newFilter.toJS()});
      }
    }
  }

  filterSystems(systems) {
    if (!this.props.searchText || systems.isEmpty()) {
      return systems;
    }

    const searchText = this.props.searchText.toLowerCase();
    const stripActivities = (activities) => activities.filter(
      (activity) => activity.get('title').toLowerCase().includes(searchText)
    );
    return systems
      .map(system => system.update('activities', stripActivities))
      .filter(system => !system.get('activities').isEmpty());
  }

  render() {
    if (this.props.isLoading) {
      return <Loading />;
    }
    const systems = this.filterSystems(this.props.systems);
    return (
      <div>
        {!systems.isEmpty() && (
          <div className="mb-1-2">
            <ExpandAllButton
              className="btn btn-default pull-right print-hide"
              collapseIds={systems.map(section => section.get('id'))}
            />

            <DownloadReportButton className="btn btn-save pull-right mr-1 print-hide" filter={this.props.filter} />

            <div className="clearfix" />
          </div>
        )}
        {
          systems.map((section) =>
            <div key={section.get('id')} className="collapsible-section">
              <CollapseButton collapseId={section.get('id')}>
                <SectionHeader section={section} activities={section.get('activities')} />
              </CollapseButton>
              <Collapse initiallyCollapsed collapseId={section.get('id')}>
                <ActivitiesListing id={section.get('id')} section={section} filter={this.props.filter} />
              </Collapse>
            </div>
          )
        }
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    systems: state.collections.getIn([COMPLIANCE_REPORT, 'items'], List()),
    isLoading: isPending(state.responses.getIn(['getCollection', COMPLIANCE_REPORT])),
  };
}

export default connect(mapStateToProps, {
  getAllCollection,
  clearCollection
})(SystemsListing);
