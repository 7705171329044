import React from 'react';
import { connect } from 'react-redux';
import { getAllCollection } from '^/actions/collections';
import { PRACTICES } from '^/consts/collectionKeys';
import { switchPractice } from '^/actions/actions';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';


export class CurrentPractice extends PureComponent {
  componentWillMount() {
    this.props.getAllCollection(PRACTICES);
  }

  onChange(evt) {
    this.props.switchPractice(evt.target.value);
  }

  render() {
    const { practices, currentPracticeId } = this.props;

    if (!practices) {
      return <Loading />;
    }

    if (practices.size === 0) {
      return <span>{practices.getIn([0, 'name'])}</span>;
    }

    return (
      <select
        className="nav-switcher"
        key={currentPracticeId}
        defaultValue={currentPracticeId}
        onChange={evt => this.onChange(evt)}
      >
        {practices.map(practice =>
          <option key={practice.get('id')} value={practice.get('id')}>
            {practice.get('name')}
          </option>
        )}
      </select>
    );
  }
}

export function mapStateToProps(state) {
  return {
    currentPracticeId: state.currentPractice && state.currentPractice.get('id'),
    practices: state.collections.getIn([PRACTICES, 'items']),
  };
}

export default connect(
  mapStateToProps,
  { getAllCollection, switchPractice }
)(CurrentPractice);
