import { fromJS, Map } from 'immutable';
import { INCLUDED, DEADLINE, REMINDER, REPEAT, STAFF } from '^/consts/account-set-up/fieldNames';
import { transformRepeatOption, REMINDER_CHOICES, toOptionValue, wasIncludedLastCycle, getNextDue  } from '^/models/task';

function transformTaskData(data, templateKey) {
  return fromJS(data)
    .filterNot(value => typeof value === 'undefined' || value === null)
    .remove('start_date')
    .remove('end_date')
    .filter(task => task.get(INCLUDED, false))
    .map((task, templateOrGroupTask) =>
      task.set(templateKey, templateOrGroupTask)
    )
    .valueSeq()
    .toJS()
    .map(transformRepeatOption);
}

export const transformSuggestedTaskData = (data) => transformTaskData(data, 'template');
export const transformGroupTaskData = (data) => transformTaskData(data, 'group_task');

const getSuggestedTaskStaff = (templateTask, user) => {
  const { last_assignees } = templateTask.toObject();
  return (
    wasIncludedLastCycle(templateTask) &&
    last_assignees &&
    last_assignees.count() > 0
  ) ?
    last_assignees.first() :
    user.getIn(['staffdetail', 'id']);
};

const getGroupTaskStaff = (groupTask) => groupTask.get('assignee');

const getTasksSetupInitialValues = (getStaff, templateOrGroupTasks, user) =>
  Map(
    templateOrGroupTasks.map(
      each =>
        [each.get('id'), {
          [REMINDER]: REMINDER_CHOICES.ONE_WEEK_BEFORE,
          [REPEAT]: toOptionValue(Map({
            repeat: each.get('recommended_repeat'),
            repeat_interval: each.get('recommended_repeat_interval'),
          })),
          [STAFF]: getStaff(each, user),
          [INCLUDED]: each.get('is_initial_setup') ?
            true :
            wasIncludedLastCycle(each),
          [DEADLINE]: getNextDue(each),
        }]
    )
  )
  .toJS();

export const getSuggestedTasksSetupInitialValues = (templateTasks, user) =>
  getTasksSetupInitialValues(getSuggestedTaskStaff, templateTasks, user);

export const getGroupTasksSetupInitialValues = (groupTasks, user) =>
  getTasksSetupInitialValues(getGroupTaskStaff, groupTasks, user);
