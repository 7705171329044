import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import classNames from 'classnames';
import * as _ from 'underscore';
import PureComponent from '^/components/common/PureComponent';
import { setActiveTab } from '^/actions/actions';

export class ContentTabset extends PureComponent {

  componentWillMount() {
    this.setDefaultActiveTab();
  }

  setDefaultActiveTab() {
    const { name, defaultActiveTab } = this.props;

    if (name) {
      this.props.setActiveTab(name, defaultActiveTab);
    }
  }

  renderActiveTabChildren() {
    const { activeTab, children } = this.props;
    if (children && children.props) {
      return children.props.children;
    }

    const tab = _.find(
      children,
      child => child && child.props && child.props.tabId === activeTab
    );
    return tab ? tab.props.children : null;
  }

  render() {
    const { children, className } = this.props;

    return (
      <div className={classNames('tabset-wrapper', className)}>
        <div className="tabs">
          {children}
        </div>
        <div className="tab-content clearfix">
          {this.renderActiveTabChildren()}
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  return {
    activeTab: state.ui.get('activeTabs', Map()).get(props.name)
  };
}

ContentTabset.propTypes = {
  children: React.PropTypes.any.isRequired,
  name: React.PropTypes.string.isRequired,
  defaultActiveTab: React.PropTypes.any.isRequired,
};

export default connect(mapStateToProps, { setActiveTab })(ContentTabset);
