import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { List } from 'immutable';
import { Link } from 'react-router';
import classNames from 'classnames';

import { isComplete, isOverdue } from '^/models/activities';
import { getCollection } from '^/actions/collections';
import { MY_TASKS_INCOMPLETE } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';
import { forSelf } from '^/models/task';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';
import Icon from '^/components/app/content/Icon';


const Task = ({task}) => {
  const { id, title, deadline, self_assignee } = task.toObject();
  const due = moment(deadline);
  const overdue = isOverdue(self_assignee);

  return (
    <li>
      <Link className="list-link indented-link" to={`/page/tasks/${id}/`}>
        <Icon
          type="check-circle-o"
          className={classNames('task-icon', overdue ? 'incomplete' : 'complete')}
        /> {title}
        <span className={classNames({'task-outstanding': overdue})}> (Due {due.format('Do MMM')})</span>
      </Link>
    </li>
  );
};


const MY_TASKS_LIST_LENGTH = 7;


export class Tasks extends PureComponent {

  componentWillMount() {
    this.props.getCollection(MY_TASKS_INCOMPLETE);
  }

  render() {
    const { tasks, response } = this.props;

    const notCompleteTasks = tasks.filterNot(forSelf(isComplete));

    if (isPending(response)) {
      return <Loading />;
    }

    return (
      <div>
        <h4 className="m-toggle">
          <CollapseButton collapseId="mobile-tasks" initiallyCollapsed collapseOnMount>
            <Icon type="check-square-o" className="title" />Your Tasks:
          </CollapseButton>
        </h4>
        <Collapse collapseId="mobile-tasks" className="m-content">
          {notCompleteTasks.size ? (
            <div>
              <ul className="list-no-indent">
                {notCompleteTasks.sortBy(task => task.get('deadline'))
                  .take(MY_TASKS_LIST_LENGTH)
                  .map((task, idx) => <Task key={idx} task={task} />
                )}
              </ul>

                <Link className="btn btn-default centered" to="/page/tasks/">
                  View all tasks
                </Link>
            </div>
          ) : (
            <h5 className="text-empty-state">You have no tasks.</h5>
          )}
        </Collapse>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    tasks: state.collections.getIn([MY_TASKS_INCOMPLETE, 'items'], List()),
    response: state.responses.getIn(['getCollection', MY_TASKS_INCOMPLETE]),
  };
}

export default connect(mapStateToProps, { getCollection })(Tasks);
