import React from 'react';
import { connect } from 'react-redux';

import { getCurrentGroup } from '^/stateHelpers';
import GroupNav from '^/components/app/groups/group-details/GroupNav';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';


export class GroupPage extends PureComponent {

  render() {
    const { children, currentGroup } = this.props;

    if (!currentGroup) {
      return <Loading />;
    }

    return <div className="mt-2">
      <GroupNav
        groupName={currentGroup.get('name')}
        className="col-1-3"
      />
      <div className="col-2-3 bordered-left">
        <div className="ml-1">{children}</div>
      </div>
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    currentGroup: getCurrentGroup(state),
  };
};

export default connect(mapStateToProps)(GroupPage);
