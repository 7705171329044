import { fromJS } from 'immutable';
import classNames from 'classnames';

const STATUS_COLOR_MAP = {
  'Expired': 'drug-expired',
  'Active': 'drug-active',
  'Expiring soon': 'drug-expiring-soon',
}

const expiryDateStyleHandler = (record) => {
  const status = STATUS_COLOR_MAP[record.get('status')];
  return classNames(`${status}`);
}

const statusStyleHandler = (record) => {
  const status = STATUS_COLOR_MAP[record.get('status')];
  return classNames(`${status}`, 'bold-text');
}

const actionDisplayHandler = (record) => {
  return record.get('is_archived') ? 'View' : 'Edit';
}

export const RECORD_FIELDS = {
    EMERGENCY_DRUGS_AND_EQUIPMENT: fromJS([
    {name: ['emergency_drugs_and_equipment','product'], display_name: 'Product', type: 'string', sortable: true},
    {name: ['emergency_drugs_and_equipment','type'], display_name: 'Type', type: 'string', sortable: true},
    {
      name: ['emergency_drugs_and_equipment','expiry_date'],
      display_name: 'Expiry date',
      type: 'string',
      sortable: true,
      styleHandler: expiryDateStyleHandler,
    },
    {
      name: ['status'],
      display_name: 'Status',
      type: 'string',
      sortable: true,
      styleHandler: statusStyleHandler,
    },
    {
      display_name: 'Action',
      type: 'detail',
      displayHandler: actionDisplayHandler,
      route: '/page/tools/records/${type_url}/${id}/',
    },
  ]),

  FIRE_DRILL: fromJS([
    {name: ['fire_drill','completed_date'], display_name: 'Date', type: 'string', sortable: true},
    {name: ['responsible_staff_name'], display_name: 'Team Member in Charge', type: 'string', sortable: true},
    {name: ['open_actions'], display_name: 'Completed Actions', type: 'string', sortable: true},
    {
      display_name: 'Action',
      type: 'detail',
      displayHandler: actionDisplayHandler,
      route: '/page/tools/records/${type_url}/${id}/',
    },
  ]),

  EVENT: fromJS([
    {name: ['event', 'reference'], display_name: 'Ref Number', type: 'string', sortable: true},
    {name: ['sub_type'], display_name: 'Type of Event', type: 'string', sortable: true},
    {name: ['event', 'event_date'], display_name: 'Date of Event', type: 'string', sortable: true},
    {name: ['responsible_staff_name'], display_name: 'Lead Team Member', type: 'string', sortable: true},
    {name: ['open_actions'], display_name: 'Completed Actions', type: 'string', sortable: true},
    {name: ['status'], display_name: 'Status', type: 'string', sortable: true},
    {
      display_name: 'Action',
      type: 'detail',
      displayHandler: actionDisplayHandler,
      route: '/page/tools/records/${type_url}/${id}/',
    },
  ]),
  COSHH: fromJS([
    {name: ['coshh', 'type'], display_name: 'Product Type', type: 'string', sortable: true},
    {name: ['coshh', 'product_name'], display_name: 'Product Name', type: 'string', sortable: true},
    {name: ['coshh', 'used_by'], display_name: 'Used by', type: 'string', sortable: true},
    {name: ['responsible_staff_name'], display_name: 'Assigned to', type: 'string', sortable: true},
    {name: ['status'], display_name: 'Status', type: 'string', sortable: true},
    {
      display_name: 'Action',
      type: 'detail',
      displayHandler: actionDisplayHandler,
      route: '/page/tools/records/${type_url}/${id}/',
    },
  ]),
}
