import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { makeStaticPath } from '^/utils';
import PureComponent from '^/components/common/PureComponent';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';
import Icon from '^/components/app/content/Icon';
import ActivityTypeCapsule from '^/components/app/content/ActivityTypeCapsule';
import Capsule from '^/components/app/content/Capsule';
import { ACTIVITY_TYPE } from '^/models/activities';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import DateInput from '^/components/app/forms/DateInput';
import TimeInput from '^/components/app/forms/TimeInput';
import LoggedInAs from '^/components/app/nav/LoggedInAs';
import { logout } from '^/actions/actions';
import { openTeamMemberModal } from '^/actions/modals';

class Styleguide extends PureComponent {

  render() {

    const navSearchCollapseId = 'js-navSearchCollapseId';
    const navCollapseId = 'js-navCollapseId';
    const MOBILE_DESC_COLLAPSE = 'mobile-desc';
    const MOBILE_ALERTS_COLLAPSE = 'mobile-alerts';
    const MOBILE_REVIEWS_COLLAPSE = 'mobile-reviews';
    const MOBILE_TASKS_COLLAPSE = 'mobile-tasks';
    const MOBILE_TEMPLATES_COLLAPSE = 'mobile-templates';

    return (
      <div className="styleguide">
        <div className="styleguide-nav">
          <ul className="clearfix">
            <li><a name="top">Styleguide:</a></li>
            <li><a href="#1">Nav + Calendar/Sidebar</a></li>
            <li><a href="#2">Admin Step/Form Elements</a></li>
            <li><a href="#3">My Practices</a></li>
            <li><a href="#4">Buttons</a></li>
            <li><a href="#5">Components</a></li>
            <li><a href="#6">Page sections</a></li>
            <li><a href="#7">Footer</a></li>
          </ul>
        </div>
        <div className="styleguide-section">
          <div className="wrapper">Styleguide: <a name="1">Nav + Calendar/Sidebar</a></div>
        </div>
        <div className="nav">
          <div className="nav-logo">
            <div className="wrapper relative">
              <div className="row">
                <CollapseButton
                  collapseId={navSearchCollapseId}
                  initiallyCollapsed collapseOnMount
                  className="collapse-search pull-left pt-1-2 pl-1-2"
                >
                  <button className="search-button">
                    <Icon type="search" className="search-button-icon" />
                  </button>
                </CollapseButton>
                <Link className="brand" to="/page/dashboard/">
                  <img src={makeStaticPath('images/brand/logo-color.svg')} className="brand-logo" />
                  <img src={makeStaticPath('images/brand/logo-color.svg')} className="brand-logo-mobile" />
                </Link>
                <CollapseButton
                  collapseId={navCollapseId} initiallyCollapsed collapseOnMount
                  className="collapse-nav pull-right pr-1 pt-1-2"
                >
                  <button className="nav-menu-button" tabIndex="0">
                    <span>
                      menu
                    {this.props.collapse.get(navCollapseId) ?
                      (<span aria-hidden="true" className="nav-menu-button-indicator open">&#9776;</span>) :
                      (<span aria-hidden="true" className="nav-menu-button-indicator">&times;</span>)}
                    </span>
                  </button>
                </CollapseButton>
              </div>
            </div>
          </div>

          <div className="nav-strip">
            <div className="wrapper relative">
              <div className="row">
                <Collapse collapseId={navCollapseId}>
                  <div className="nav-strip-content">
                    <div className="view-switcher">
                      <Link to="/page/dashboard/" className="pill active">Practices</Link>
                      <Link to="/page/dashboard/" className="pill">Groups</Link>
                      <Link to="/page/dashboard/" className="pill">HR</Link>
                    </div>
                    <LoggedInAs
                      userName="Ava-Grace Velez"
                      userType="ICOMPLY ADMIN"
                      logout={this.props.logout}
                    />
                    <div className="nav-currently-viewing">
                      <select className="nav-switcher">
                        <option value="">Powersmile Brighton</option>
                      </select>
                    </div>
                  </div>
                </Collapse>
                <Collapse collapseId={navSearchCollapseId}>
                  <div className="nav-strip-search">
                    <input type="text" name="search-box" placeholder="Search iComply" className="nav-search-box" />
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
          <div className="nav-links">
            <div className="wrapper">
              <div className="row">
                <nav className="nav-primary col-1">
                  <Collapse collapseId={navCollapseId}>
                  <ul>
                    <li>
                      <Link className="nav-primary-link active" to="/page/dashboard/">
                        dashboard
                        <div className="nav-primary-notification">16</div>
                      </Link>
                    </li>
                    <li><Link className="nav-primary-link" to="/page/dashboard/">calendar</Link></li>
                    <li><Link className="nav-primary-link" to="/page/dashboard/">tasks</Link></li>
                    <li><Link className="nav-primary-link" to="/page/dashboard/">template documents</Link></li>
                    <li><Link className="nav-primary-link" to="/page/dashboard/">reports</Link></li>
                    <li>
                      <Link className="nav-primary-link" to="/page/dashboard/">
                        help
                        <Icon type="comments" className="white-icon title ml-1" />
                      </Link>
                    </li>
                  </ul>
                  </Collapse>
                </nav>
              </div>
            </div>
          </div>
        </div> {/* end nav */}
        <div className="wrapper mb-2">
          <div className="row">
            <div className="col-3-4 col-md-none bordered">
              <nav className="nav-calendar">
                <h1>January 2016 <span className="light-text">Cycle 1 Month 1</span></h1>
                <div className="nav-calendar-controls">
                  <button className="nav-calendar-arrow" tabIndex="0" disabled>&lt;</button>
                  <button className="btn btn-default" tabIndex="0">Today</button>
                  <button className="nav-calendar-arrow" tabIndex="0">&gt;</button>
                </div>
              </nav>
              <div className="nav-calendar-grid">Calendar component goes here</div>
            </div>
            <div className="col-1-4 col-md-1 col-last">
                <div className="sidebar-item">
                  <h4 className="m-toggle">
                    <CollapseButton
                      collapseId={MOBILE_ALERTS_COLLAPSE}
                      initiallyCollapsed collapseOnMount
                    >
                      <Icon type="bullhorn" className="title" />Alerts
                    </CollapseButton>
                  </h4>
                  <Collapse collapseId={MOBILE_ALERTS_COLLAPSE} className="m-content">
                  <ul className="list-no-indent alert-items">
                    <li>
                      You have been removed from the responsibility of blah blah
                      <Link className="alert-dismiss-link" to="/page/dashboard/">
                        <Icon type="close" className="alert-dismiss" />
                      </Link>
                    </li>
                    <li>
                      You have been removed from the responsibility of blah blah
                      <Link className="alert-dismiss-link" to="/page/dashboard/">
                        <Icon type="close" className="alert-dismiss" />
                      </Link>
                    </li>
                    <li>
                      You have been removed from the responsibility of blah blah
                      <Link className="alert-dismiss-link" to="/page/dashboard/">
                        <Icon type="close" className="alert-dismiss" />
                      </Link>
                    </li>
                  </ul>

                  <Link className="alert-dismiss-all-link" to="/page/dashboard/">
                    Dismiss All
                    <Icon type="close" className="alert-dismiss" />
                  </Link>
                  </Collapse>
                </div>

                <hr className="no-margin" />

                <div className="sidebar-item">
                  <h4><Icon type="calendar-check-o" className="title" /> January 2016 tasks</h4>
                  <h5 className="text-empty-state">You have no tasks in January</h5>
                  <h5>5th January</h5>
                    <ul className="list-no-indent">
                      <li>
                        <Link className="list-link list-link__tasks indented-link" to="/page/dashboard/">
                          <ActivityTypeCapsule type={ACTIVITY_TYPE.ASSESSMENT} />
                          <span className="task">Carry out a risk-assessment</span>
                          <Icon type="exclamation" className="step-icon small complete" />
                          <span className="list-metadata">
                            <span className="list-metadata-type">
                              Assessment:</span> Paul Mitchell G - <span>Due 2nd Jan</span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link className="list-link list-link__tasks indented-link" to="/page/dashboard/">
                          <ActivityTypeCapsule type={ACTIVITY_TYPE.PRACTICE_MEETING} />
                          <span className="task">Carry out a meeting</span>
                          <Icon type="exclamation" className="step-icon small outstanding" />
                          <span className="list-metadata">
                            <span className="list-metadata-type">Practice Meeting:</span> Al P -
                            <span className="outstanding"> Due 20th Jan</span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link className="list-link list-link__tasks indented-link" to="/page/dashboard/">
                          <ActivityTypeCapsule type={ACTIVITY_TYPE.ADMIN_STEP} />
                           <span className="task">Specific Admin Step</span>
                          <Icon type="exclamation" className="step-icon small outstanding" />
                          <span className="list-metadata">
                            <span className="list-metadata-type">Admin Step:</span> Paul Mitchell G -
                            <span className="outstanding"> Due 20th Jan</span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link className="list-link list-link__tasks indented-link" to="/page/dashboard/">
                          <ActivityTypeCapsule type={ACTIVITY_TYPE.AUDIT} />
                          <span className="task">Carry out an audit of things</span>
                          <Icon type="exclamation" className="step-icon small outstanding" />
                          <span className="list-metadata">
                            <span className="list-metadata-type">Audit:</span> Al P -
                            <span className="outstanding"> Due 20th Jan</span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link className="list-link list-link__tasks indented-link" to="/page/dashboard/">
                          <Capsule className="capsule-task" />
                          <span className="task">What a bit of a Task</span>
                          <Icon type="check" className="step-icon small complete" />
                            <span className="list-metadata">
                              <span className="list-metadata-type">Task:</span> Al P - <span>Due 20th Jan</span>
                            </span>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <hr className="no-margin" />

                  <div className="sidebar-item">
                    <h4><Icon type="check-square-o" className="title" /> Your Tasks</h4>
                  </div>

                  <hr className="no-margin" />

                  <div className="sidebar-item">
                    <h4><Icon type="newspaper-o" className="title" /> Latest News</h4>
                  </div>

                  <hr className="no-margin" />

                  <div className="sidebar-item">
                  <button className="btn btn-complete indented fullwidth">
                    <Icon type="check" className="button-icon" /> mark as complete
                  </button>

                  <button className="btn btn-complete indented fullwidth" disabled>
                    <Icon type="check" className="button-icon" /> mark as complete
                  </button>

                  <button className="btn btn-save indented fullwidth">
                    <Icon type="folder-open" className="button-icon" /> save
                  </button>

                  <button className="btn btn-save indented fullwidth  " disabled>
                    <Icon type="folder-open" className="button-icon" /> save
                  </button>

                  <button className="btn btn-default indented fullwidth">
                    <Icon type="check-square-o" className="button-icon" /> create related task
                  </button>

                  <button className="btn btn-default centered fullwidth">
                    view all tasks
                  </button>

                  <button className="btn btn-default centered fullwidth">
                    view all news
                  </button>
                </div>
              </div>
          </div>
        </div>

        <div className="styleguide-section">
          <div className="wrapper">Styleguide: <a name="2">Admin Step/Form Elements</a> -
            <a href="#top">back to top</a></div>
        </div>

        <div className="wrapper mb-2">
          <div className="row">
            <div className="col-1-3 secondary-left hidden-m">
              <h5 className="outstanding">Outstanding:</h5>
              <ul className="list-no-indent icon-right">
                <li>
                  <Link className="step-link step-outstanding" to="/page/dashboard/">
                    Prepare for your admin cycle
                    <Icon type="exclamation" className="step-icon step-icon-right outstanding" />
                  </Link>
                </li>
                <li>
                  <Link className="step-link step-outstanding" to="/page/dashboard/">
                    Patient Satisfaction
                    <Icon type="exclamation" className="step-icon step-icon-right outstanding" />
                  </Link>
                </li>
                <li className="sidenav-active">
                  <Link className="step-link step-outstanding" to="/page/dashboard/">
                    Implement/Review comments and suggestions box
                    <Icon type="exclamation" className="step-icon step-icon-right outstanding" />
                  </Link>
                </li>
              </ul>
              <h5 className="inprogress">In Progress:</h5>
              <ul className="list-no-indent icon-right">
                <li>
                  <Link className="step-link step-inprogress" to="/page/dashboard/">
                    Prepare for your admin cycle
                    <Icon type="hourglass-2" className="step-icon step-icon-right inprogress" />
                  </Link>
                </li>
                <li>
                  <Link className="step-link step-inprogress" to="/page/dashboard/">
                    Patient Satisfaction
                    <Icon type="hourglass-2" className="step-icon step-icon-right inprogress" />
                  </Link>
                </li>
                <li className="sidenav-active">
                  <Link className="step-link step-inprogress" to="/page/dashboard/">
                    Implement/Review comments and suggestions box
                    <Icon type="hourglass-2" className="step-icon step-icon-right inprogress" />
                  </Link>
                </li>
              </ul>
              <h5 className="complete">Complete:</h5>
              <ul className="list-no-indent icon-right">
                <li>
                  <Link className="step-link step-complete" to="/page/dashboard/">
                    Prepare for your admin cycle
                    <Icon type="check" className="step-icon step-icon-right complete" />
                  </Link>
                </li>
                <li>
                  <Link className="step-link step-complete" to="/page/dashboard/">
                    Patient Satisfaction
                    <Icon type="check" className="step-icon step-icon-right complete" />
                  </Link>
                </li>
                <li className="sidenav-active">
                  <Link className="step-link step-complete" to="/page/dashboard/">
                    Implement/Review comments and suggestions box
                    <Icon type="check" className="step-icon step-icon-right complete" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-2-3 bordered-left">
              <div className="back-link-container relative">
                <Link className="back-link" to="/page/dashboard/">
                  Admin Step 2 iCompy Setup, Patient Involvement and Consent
                </Link> (10 days remaining)
                <Icon type="hourglass-2" className="step-icon step-icon-right inprogress" />
              </div>
              <h1>Implement/review comments and suggestions box</h1>

              <div className="metadata">
                <Icon type="user" className="metadata-icon" /> Alistair Power <em>This is emphasised</em>
              </div>
              <hr className="dark" />

              <div className="row">
                <div className="col-3-4">

                  <div className="speechbubble speechbubble-complete hidden-d">
                    <h4>100% Complete</h4>
                    <p>Great work!</p>
                    <div className="progressbar complete">
                      <div
                        className="progressbar-bar complete"
                        style={{width: '100%'}}
                      />
                    </div>
                  </div>


                  <h2 className="m-toggle">
                    <CollapseButton collapseId={MOBILE_DESC_COLLAPSE} initiallyCollapsed collapseOnMount>
                      Description
                    </CollapseButton>
                  </h2>
                  <Collapse collapseId={MOBILE_DESC_COLLAPSE} className="m-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                     nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                       pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                     nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                       pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                     nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                       pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.</p>
                  </Collapse>

                  <h4 className="m-toggle hidden-d">
                    <CollapseButton
                      collapseId={MOBILE_REVIEWS_COLLAPSE}
                      initiallyCollapsed collapseOnMount
                    >
                      Compliance Reviews
                    </CollapseButton>
                  </h4>
                  <Collapse collapseId={MOBILE_REVIEWS_COLLAPSE} className="m-content">
                    <h5 className="outstanding">Outstanding:</h5>
                    <ul className="list-no-indent icon-right">
                      <li>
                        <Link className="step-link step-outstanding" to="/page/dashboard/">
                          Prepare for your admin cycle
                          <Icon type="exclamation" className="step-icon step-icon-right outstanding" />
                        </Link>
                      </li>
                      <li>
                        <Link className="step-link step-outstanding" to="/page/dashboard/">
                          Patient Satisfaction
                          <Icon type="exclamation" className="step-icon step-icon-right outstanding" />
                        </Link>
                      </li>
                      <li>
                        <Link className="step-link step-outstanding" to="/page/dashboard/">
                          Implement/Review comments and suggestions box
                          <Icon type="exclamation" className="step-icon step-icon-right outstanding" />
                        </Link>
                      </li>
                    </ul>
                    <h5 className="inprogress">In Progress:</h5>
                    <ul className="list-no-indent icon-right">
                      <li>
                        <Link className="step-link step-inprogress" to="/page/dashboard/">
                          Prepare for your admin cycle
                          <Icon type="hourglass-2" className="step-icon step-icon-right inprogress" />
                        </Link>
                      </li>
                      <li>
                        <Link className="step-link step-inprogress" to="/page/dashboard/">
                          Patient Satisfaction
                          <Icon type="hourglass-2" className="step-icon step-icon-right inprogress" />
                        </Link>
                      </li>
                      <li>
                        <Link className="step-link step-inprogress" to="/page/dashboard/">
                          Implement/Review comments and suggestions box
                          <Icon type="hourglass-2" className="step-icon step-icon-right inprogress" />
                        </Link>
                      </li>
                    </ul>
                    <h5 className="complete">Complete:</h5>
                    <ul className="list-no-indent icon-right">
                      <li>
                        <Link className="step-link step-complete" to="/page/dashboard/">
                          Prepare for your admin cycle
                          <Icon type="check" className="step-icon step-icon-right complete" />
                        </Link>
                      </li>
                      <li>
                        <Link className="step-link step-complete" to="/page/dashboard/">
                          Patient Satisfaction
                          <Icon type="check" className="step-icon step-icon-right complete" />
                        </Link>
                      </li>
                      <li>
                        <Link className="step-link step-complete" to="/page/dashboard/">
                          Implement/Review comments and suggestions box
                          <Icon type="check" className="step-icon step-icon-right complete" />
                        </Link>
                      </li>
                    </ul>

                    <h2>Actions taken:</h2>
                    <div className="form-group">
                      <div className="col-1-3">
                       <label className="label-inline">
                         <input type="radio" name="action1" value="yes" />
                         Yes
                       </label>
                       <label className="label-inline">
                         <input type="radio" name="action1" value="na" />
                         N/A
                       </label>
                      </div>
                      <div className="col-2-3">
                       <div className="small-text">Any needed templates have been downloaded (read only version)
                        and reviewed if necessary</div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-1-3">
                       <label className="label-inline nowrap">
                         <input type="radio" name="action2" value="yes" disabled checked />
                         Yes
                       </label>
                       <label className="label-inline nowrap">
                         <input type="radio" name="action2" value="na" disabled />
                         N/A
                       </label>
                      </div>
                      <div className="col-2-3">
                       <div className="small-text">Any needed templates have been downloaded
                        and reviewed if necessary</div>
                      </div>
                    </div>

                  <hr className="thin" />
                  {/* form with label left */}

                  <div className="form-group">
                   <div className="col-1-3 label-left">label for radios</div>
                   <div className="col-2-3">
                     <label className="label-inline">
                       <input type="radio" name="action2" value="yes" />
                       Yes
                     </label>
                     <label className="label-inline">
                       <input type="radio" name="action2" value="na" />
                       N/A
                     </label>
                   </div>
                  </div>

                  <div className="form-group">
                    <div className="col-1-3 label-left">label for checkboxes</div>
                    <div className="col-2-3">
                     <label className="label-inline">
                       <input type="checkbox" name="action2" value="yes" />
                       Yes
                     </label>
                     <label className="label-inline">
                       <input type="checkbox" name="action2" value="na" />
                       N/A
                     </label>
                    </div>
                  </div>

                  <div className="form-group">
                   <div className="col-1-3 label-left">label for checkboxes stacked</div>
                   <div className="col-2-3">

                     <div className="label-stacked">
                       <input type="checkbox" name="action3" value="red" id="action3_1" />
                       <label htmlFor="action3_1">red</label>
                     </div>

                     <div className="label-stacked">
                       <input type="checkbox" name="action3" value="blue" id="action3_2" />
                       <label htmlFor="action3_2">
                         blue I'm a really long label that goes on and on and on and on and on a bit more
                       </label>
                     </div>

                     <div className="label-stacked">
                        <input type="checkbox" name="action3" value="orange" id="action3_3" />
                        <label htmlFor="action3_3">orange</label>
                     </div>

                     <div className="label-stacked">
                       <input type="checkbox" name="action3" value="red" id="action3_4" />
                       <label htmlFor="action3_4">
                         red
                       </label>
                       <span className="help-block">This is some more help text</span>
                     </div>

                   </div>
                 </div>


                  <div className="form-group">
                   <div className="col-1-3">
                     <label htmlFor="firstName" className="label-left padded">First name</label>
                     <span className="help-inline">optional</span>
                     <span className="help-block">help text</span>
                   </div>
                   <div className="col-2-3">
                     <input type="text" id="firstName" placeholder="Username" className="form-control" />
                   </div>
                  </div>

                  <div className="form-group">
                   <div className="col-1-3">
                     <label htmlFor="dob" className="label-left padded">Date of birth</label>
                   </div>
                   <div className="col-2-3">
                     <input type="text" id="dob" placeholder="dd/mm/yyyy" className="form-control small" />
                   </div>
                  </div>

                  <div className="form-group">
                   <div className="col-1-3">
                     <label htmlFor="mytext" className="label-left padded">some text</label>
                     <span className="help-inline">optional</span>
                     <span className="help-block">help text</span>
                   </div>
                   <div className="col-2-3">
                     <textarea
                       name="mytext" id="mytext"
                       cols="30" rows="10" placeholder="some placeholder text"
                     />
                   </div>
                  </div>

                  <div className="form-group">
                   <div className="col-1-3">
                     <label htmlFor="myselect" className="label-left">A select box</label>
                   </div>
                   <div className="col-2-3">
                     <select name="myselect" id="myselect" className="form-select">
                       <option value="dentist">dentist</option>
                       <option value="nurse">nurse</option>
                       <option value="owner">owner</option>
                     </select>
                   </div>

                   <HelpBlock className="mt-1 fade-in-animate" iconType="lightbulb-o">
                     What you write here will show on your compliance report.
                     This is instructional text. Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore.
                   </HelpBlock>

                   <hr className="thin" />

                   <HelpBlock>
                     <p className="small-text">
                       <strong>Help: </strong>We've already selected all of the compulsory audits for you, so all
                       you need to do is pick the optional ones you'd like to add, and add the 'date last done' dates.
                     </p>
                     <p className="small-text">
                       Next due dates will populate automatically on entering a last done date.
                     </p>
                     <p className="small-text">
                       You'll be able to edit these dates via the calendar once the cycle setup is complete.
                     </p>
                     <p className="small-text">
                       Audits due after the end of the cycle (2nd November 2016)
                       will not be created in the current cycle.
                     </p>
                   </HelpBlock>

                  </div>

                  {/* form stacked */}
                  <hr className="thin" />
                  <div className="form-group">
                   <label>Email</label>
                   <input type="text" placeholder="Username" className="form-control" />
                  </div>

                  <div className="form-group">
                   <label>Email</label>
                   <span className="help-block">help text as block</span>
                   <input type="text" placeholder="Username" className="form-control" />
                  </div>

                  <div className="form-group">
                   <label>Email</label>
                   <span className="help-inline">help text inline</span>
                   <input type="text" placeholder="Username" className="form-control" />
                  </div>


                  <div className="form-group">
                    <label>Password</label>
                    <input type="password" placeholder="Password" className="form-control" />
                  </div>

                  <div className="form-group">
                    <label htmlFor="mytext">Some Large Text</label>
                   <textarea
                     name="mytext" id="mytext"
                     cols="30" rows="10" placeholder="some placeholder text"
                   />
                  </div>

                  <div className="mt-1">
                     <button className="btn btn-save indented mr-1">
                       <Icon type="folder-open" className="button-icon" /> save
                     </button>
                     <button className="btn btn-complete indented">
                       <Icon type="check" className="button-icon" /> mark as complete
                     </button>
                  </div>
                </Collapse>
                </div>
                <div className="col-1-4">
                  <div className="sidebar-step">
                    <p className="alert-success bold-text mb-1">
                      <Icon type="check" className="inline-icon complete" /> Completed.
                      <span className="help-block">Completed 2nd November 2016</span>
                    </p>
                    <p className="alert-warning bold-text mb-1">
                      <Icon type="exclamation-triangle" className="inline-icon incomplete" /> Outstanding.
                      <span className="help-block">Assigned to Paul Smith.</span>
                    </p>
                    <div className="speechbubble speechbubble-juststarted hidden-m">
                      <h4>10% Complete</h4>
                      <div className="progressbar juststarted">
                        <div className="progressbar-bar" style={{width: '10%'}} />
                      </div>
                    </div>
                  </div>
                  <div className="speechbubble hidden-m">
                    <h4>60% Complete</h4>
                    <p>Almost there…</p>
                    <div className="progressbar">
                      <div className="progressbar-bar" style={{width: '60%'}} />
                    </div>
                  </div>
                  {/*duplicating this collapse component to hide stuff in
                    styleguide on mobile - will have to figure out how this is done IRL */}
                  <Collapse collapseId={MOBILE_REVIEWS_COLLAPSE} className="m-content">
                    <button className="btn btn-complete indented fullwidth mb-1">
                      <Icon type="check" className="button-icon" /> mark as complete
                    </button>
                    <button className="btn btn-save indented fullwidth mb-1">
                      <Icon type="folder-open" className="button-icon" /> save
                    </button>
                    <button className="btn btn-default indented fullwidth mb-1">
                      <Icon type="check-square-o" className="button-icon" /> create related task
                    </button>
                  </Collapse>

                  <h4 className="m-toggle">
                    <CollapseButton collapseId={MOBILE_TASKS_COLLAPSE} initiallyCollapsed collapseOnMount>
                      Related Tasks:
                    </CollapseButton>
                  </h4>
                  <Collapse collapseId={MOBILE_TASKS_COLLAPSE} className="m-content">
                    <ul className="list-no-indent">
                      <li>
                        <Link className="list-link indented-link" to="/page/dashboard/">
                          <Icon type="square-o" className="task-icon incomplete" /> This is a Task with some text
                        </Link>
                      </li>
                      <li>
                        <Link className="list-link indented-link" to="/page/dashboard/">
                          <Icon type="square-o" className="task-icon incomplete" /> This is a Task with some text
                        </Link>
                      </li>
                      <li>
                        <Link className="list-link indented-link" to="/page/dashboard/">
                          <Icon type="square-o" className="task-icon incomplete" /> This is a Task with some text
                        </Link>
                      </li>
                    </ul>
                    <h4 className="m-toggle">Completed Tasks:</h4>
                    <ul className="list-no-indent">
                      <li>
                        <Link className="list-link indented-link" to="/page/dashboard/">
                          <Icon type="check-circle-o" className="task-icon complete" /> This is a Task with some text
                        </Link>
                      </li>
                      <li>
                        <Link className="list-link indented-link" to="/page/dashboard/">
                          <Icon type="check-circle-o" className="task-icon complete" /> This is a Task with some text
                        </Link>
                      </li>
                      <li>
                        <Link className="list-link indented-link" to="/page/dashboard/">
                          <Icon type="check-circle-o" className="task-icon complete" /> This is a Task with some text
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                  <h4 className="m-toggle m-toggle-last">
                  <CollapseButton
                    collapseId={MOBILE_TEMPLATES_COLLAPSE}
                    initiallyCollapsed collapseOnMount
                  >
                    Related Templates:
                  </CollapseButton>
                  </h4>
                  <Collapse collapseId={MOBILE_TEMPLATES_COLLAPSE} className="m-content">
                    <ul className="list-no-indent">
                      <li>
                        <Link className="list-link indented-link" to="/page/dashboard/">
                          <Icon type="file-text-o" className="template-icon" /> This is a related template
                        </Link>
                      </li>
                      <li>
                        <Link className="list-link indented-link" to="/page/dashboard/">
                          <Icon type="file-text-o" className="template-icon" /> This is a related template
                        </Link>
                      </li>
                      <li>
                        <Link className="list-link indented-link" to="/page/dashboard/">
                          <Icon type="file-text-o" className="template-icon" /> This is a related template
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </div>
              </div> {/* end sub row */}
            </div> {/* end col-2-3 */}
          </div> {/* end row */}
        </div> {/* end wrapper */}

        <div className="wrapper mb-2">
          <div className="row">
            <div className="col-1-3 secondary-left hidden-m">
              <ul className="list-no-indent icon-right">
                <li>
                  <span className="sidenav-text">
                    <span>Personal Details</span>
                    <Icon type="check" className="setup-icon complete" />
                  </span>
                </li>
                <li>
                  <span className="sidenav-text">
                    <span>Different Details</span>
                    <Icon type="check" className="setup-icon complete" />
                  </span>
                </li>
                <li className="sidenav-active">
                  <span className="sidenav-text">
                    <span>Something else Details</span>
                    <Icon type="long-arrow-left" className="setup-icon" />
                  </span>
                </li>
                <li className="">
                  <span className="sidenav-text">
                    <span>I'm not done yet</span>
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-2-3 bordered-left">
              <h1>This is an example admin step</h1>

              <div className="metadata">
                <Icon type="user" className="metadata-icon" /> Alistair Power <em>This is emphasised</em>
              </div>
              <hr className="dark" />
              <div>
                <Icon type="info-circle" className="icon-left" />
                <p className="small-text">
                  <strong>Welcome</strong>
                  <span> ...as this is your first time logging into iComply, let's set up your first practice.
                     You can add a practice, add your colleagues and team members,
                      and set up your first compliance cycle for the next year.
                       Start by adding some personal details.
                  </span>
                </p>
              </div>
              <hr className="thin" />
              <h3>Enter your details:</h3>
              <form>
                <div className="form-group">
                  <div className="col-1-3">
                    <label htmlFor="phone" className="label-left padded">Phone</label>
                    <span className="help-inline">required</span>
                    </div>
                  <div className="col-2-3">
                    <input type="text" id="phone" placeholder="eg. +44 1230 456789" />
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-1-3">
                    <label htmlFor="mobile" className="label-left padded">Mobile</label>
                  </div>
                  <div className="col-2-3">
                    <input type="text" id="mobile" placeholder="eg. +44 1230 456789" />
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-1-3">
                    <label htmlFor="birth_date" className="label-left padded">Date of birth</label>
                    <span className="help-inline">required</span>
                    </div>
                  <div className="col-2-3">
                    <DateInput id="birth_date" />
                  </div>
                </div>

                <div className="mb-2 optional-field">
                  <CollapseButton collapseId={9999} className="optional-field-toggle">
                    change preferred email alert time (currently 9:00AM)
                  </CollapseButton>
                  <Collapse initiallyCollapsed collapseId={9999}>
                    <div className="form-group">
                      <div className="col-1-3">
                        <label htmlFor="email_alert_time" className="label-left padded">Email alert time</label>
                        <span className="help-inline">required</span>
                        </div>
                      <div className="col-2-3">
                        <TimeInput id="email_alert_time" />
                      </div>
                    </div>
                  </Collapse>
                </div>
                <button className="btn btn-primary" type="submit">Next...</button>
              </form>
              <button
                className="btn btn-default mt-1"
                onClick={() => this.props.openTeamMemberModal()}
              >Add/Choose Existing Team Members Modal</button>

              <hr />
              <div className="mt-1">
                <h2>Attached documents</h2>
                <div>
                  <div>
                    <a className="underlined">
                      <Icon type="file-text-o" className="inline-icon" />Practice Safety checklist V5
                    </a>
                    <div className="pull-right cr">
                      <button className="btn btn-default small indented">
                        <Icon type="edit" className="button-icon" />Edit
                      </button>
                      <button className="btn btn-default small indented ml-1-2">
                        <Icon type="cloud-download" className="button-icon" />Download
                      </button>
                    </div>
                    <div className="pull-right cr">
                      <button className="btn btn-default small indented">
                        <Icon type="edit" className="button-icon" />Edit
                      </button>
                      <button className="btn btn-default small indented ml-1-2" disabled>
                        <Icon type="cog" className="button-icon rotate" /> Generating PDF...
                      </button>
                    </div>
                    <div className="pull-right cr">
                      <button className="btn btn-default small indented">
                        <Icon type="edit" className="button-icon" /> Edit
                      </button>
                      <button className="btn btn-save small indented ml-1-2">
                        <Icon type="check" className="button-icon" /> Open PDF
                      </button>
                    </div>
                    <div className="pull-right cr">
                      <p className="alert-error">Computer says no.</p>
                    </div>
                  </div>
                  <div className="hint">Uploaded by Alistair Power on 7th Nov 2016. Stored in Folder 5</div>
                </div>

                <hr className="thin" />

                <div>
                  <div>
                    <Icon type="file-text-o" className="inline-icon" /> Another document v4
                    <div className="pull-right">
                      <a className="btn btn-default small indented">
                        <Icon type="cloud-download" className="button-icon" /> Download
                        </a>
                    </div>
                  </div>
                  <div className="hint">Uploaded by Alistair Power on 7th Nov 2016. Stored in Folder 5</div>
                </div>

                <hr className="thin" />
              </div>

              <div className="mt-1">
                <h2>Related templates</h2>
                <div className="help-block mb-1">
                  <Icon type="lightbulb-o" className="tip-icon" />
                  You can use these to help you complete this compliance review.
                </div>

                <div>
                  <div>
                    <a className="underlined">
                      <Icon type="file-text-o" className="inline-icon" />Practice Safety checklist V5
                    </a>
                    <div className="pull-right">
                      <a className="btn btn-default small indented">
                        <Icon type="edit" className="button-icon" /> Complete
                      </a>
                      <a className="btn btn-default small indented ml-1-2">
                        <Icon type="cloud-download" className="button-icon" /> Download
                      </a>
                    </div>
                  </div>
                  <div className="hint">Uploaded by Alistair Power on 7th Nov 2016. Stored in Folder 5</div>
                </div>
                <hr className="thin" />
                <div>
                  <div>
                    <a className="underlined">
                      <Icon type="file-text-o" className="inline-icon" />Another document v4
                    </a>
                    <div className="pull-right">
                      <a className="btn btn-default small indented">
                        <Icon type="edit" className="button-icon" /> Complete
                      </a>
                      <a className="btn btn-default small indented ml-1-2">
                        <Icon type="cloud-download" className="button-icon" /> Download
                      </a>
                    </div>
                  </div>
                  <div className="hint">Uploaded by Alistair Power on 7th Nov 2016. Stored in Folder 5</div>
                </div>
                <hr className="thin" />
              </div>

              <div className="mt-1">
                <h2>Link to or upload documents</h2>
                  <div className="help-block mb-1">
                    <Icon type="lightbulb-o" className="tip-icon" />
                    You can upload or describe where to find any
                     additional documents completed outside of the iComply system.
                    For example anything you have scanned in or edited on your desktop.
                  </div>
                  <button className="btn btn-default indented">
                    <Icon type="upload" className="button-icon" /> add documents
                  </button>
              </div>

            </div> {/* end col-2-3 */}
          </div> {/* end row */}
        </div> {/* end wrapper */}

        <div className="styleguide-section">
          <div className="wrapper">Styleguide: <a name="3">My Practices</a> - <a href="#top">back to top</a></div>
        </div>

        <div className="wrapper mb-2">
          <div className="row">
            <div className="col-1-3 secondary-left hidden-m">
              <h3>My account:</h3>
              <ul className="list-no-indent icon-right">
                <li>
                  <span className="sidenav-text">Personal Details</span>
                </li>
                <li className="sidenav-active">
                  <span className="sidenav-text">My Practices</span>
                </li>
              </ul>
            </div>
            <div className="col-2-3 bordered-left">
              <h1>My Practices</h1>

              <hr className="dark" />
              <h3>Practice filter</h3>
              <div>
                <select name="region" className="form-select mr-1">
                  <option value="East Sussex">East Sussex</option>
                  <option value="West Sussex">West Sussex</option>
                </select>
                <select name="practice" className="form-select mr-1">
                  <option value="">MeowMeow TeethWhite Brighton</option>
                  <option value="">MeowMeow TeethWhite Hove</option>
                </select>
                <button className="btn btn-default inline">view</button>
              </div>
              <ul className="card-container">
                {[1, 2, 3, 4].map((idx) =>
                  <li key={idx}>
                    <div className="card">
                      <button className="btn btn-default pull-right">details</button>
                      <h4>MeowMeow TeethWhite Brighton {idx}</h4>
                      <div className="card-content mt-1">
                        <img src="http://placekitten.com/g/300/120" alt="logo" className="centered" />
                        <hr className="thin" />
                        <h5>Cycle 1</h5>
                        <p><strong>Started:</strong> 01/02/2016 <strong>Ends:</strong> 01/02/2016</p>

                        <hr className="thin" />
                        <button className="btn btn-default pull-right">dashboard</button>
                        <h5>Currently in Month 1</h5>
                        <p className="text-success">88% complete this month</p>

                        <hr className="thin" />
                        <button className="btn btn-default pull-right">view</button>
                        <h5>Team members</h5>
                        <p>13 team members</p>


                        <hr className="thin" />
                        {idx === 1 ?
                          <div>
                            <button className="btn btn-default pull-right">view</button>
                            <h5 className="mb-2">Team responsibilities</h5>
                          </div> :
                          <div>
                            <button className="btn btn-default pull-right" disabled>view</button>
                            <h5 className="mb-2">Team responsibilities</h5>
                          </div>
                        }
                        <hr className="thin" />
                        <button className="btn btn-default pull-right">view</button>
                        <h5>Membership level</h5>
                        <p>iComply full member</p>
                        <p>working towards QPS award</p>

                        <hr className="thin" />
                        <button className="btn btn-default pull-right">view</button>
                        <h5>Payment details</h5>
                        <p>Direct Debit</p>
                        {idx === 1 ?
                          <p>Next payment due: <strong>20th March</strong></p> :
                          <p className="text-warning">
                            <Icon type="exclamation-circle" className="tip-icon warning" />
                            Your account needs updating!</p>
                      }
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div> {/* end col-2-3 */}
          </div> {/* end row */}
        </div> {/* end wrapper */}

        <div className="styleguide-section">
          <div className="wrapper">Styleguide: <a name="4">Buttons</a> - <a href="#top">back to top</a></div>
        </div>

        <div className="wrapper mb-2">
          <div className="row">
            <div className="col-1">
            <h1>some buttons</h1>
              <pre className="code">
                &lt;button className=&quot;btn btn-complete&quot;&gt;mark as complete&lt;/button&gt;</pre>
              <button className="btn btn-complete">mark as complete</button>
              <hr />

              <pre className="code">
                &lt;button className=&quot;btn btn-complete&quot;&gt;mark as complete&lt;/button&gt;</pre>
              <button className="btn btn-complete">mark as complete</button>
              <hr />

              <pre className="code">
                &lt;button className=&quot;btn btn-save indented&quot;&gt;
                <br />&nbsp;&nbsp;&lt;Icon type=&quot;folder-open&quot; className=&quot;button-icon&quot; /&gt; save
                <br />&lt;/button&gt;
              </pre>
              <button className="btn btn-save indented">
                <Icon type="folder-open" className="button-icon" /> save
              </button>
              <hr />

              <button className="btn btn-warning indented">
                <Icon type="close" className="button-icon" /> close
              </button>
              <hr />

              <pre className="code">
                &lt;button className=&quot;btn btn-save indented fullwidth&quot;&gt;
                <br />&nbsp;&nbsp;&lt;Icon type=&quot;folder-open&quot; className=&quot;button-icon&quot; /&gt; save
                <br />&lt;/button&gt;
              </pre>
              <button className="btn btn-save indented fullwidth">
                <Icon type="folder-open" className="button-icon" /> save
              </button>
              <hr />

              <pre className="code">
                &lt;button className=&quot;btn btn-save indented small&quot;&gt;
                <br />&nbsp;&nbsp;&lt;Icon type=&quot;folder-open&quot; className=&quot;button-icon&quot; /&gt; save
                <br />&lt;/button&gt;
              </pre>
              <button className="btn btn-save indented small">
                <Icon type="folder-open" className="button-icon" /> save
              </button>
              <hr />

              <pre className="code">
                &lt;button className=&quot;btn btn-save small&quot;&gt;
                <br />&nbsp;&nbsp; save
                <br />&lt;/button&gt;
              </pre>
              <button className="btn btn-save small">
                save
              </button>
              <hr />

              <pre className="code">
                &lt;button className=&quot;btn btn-default indented&quot;&gt;
                <br />&nbsp;&nbsp;&lt;Icon type=&quot;check-square-o&quot;
                   className=&quot;button-icon&quot; /&gt; create related task
                <br />&lt;/button&gt;
              </pre>
              <button className="btn btn-default indented">
                <Icon type="check-square-o" className="button-icon" /> create related task
              </button>

              <hr />
              <pre className="code">
                &lt;button className=&quot;btn btn-default inline&quot;&gt;
                <br />&nbsp;&nbsp;&lt;Icon type=&quot;check-square-o&quot;
                   className=&quot;button-icon&quot; /&gt; create related task
                <br />&lt;/button&gt;
              </pre>
              <button className="btn btn-default inline">
                do something
              </button>

              <hr />
              <pre className="code">
                &lt;button className=&quot;btn btn-default fixed-width&quot;&gt;
                <br />&nbsp;&nbsp;&lt;Icon type=&quot;check-square-o&quot;
                   className=&quot;button-icon&quot; /&gt; create related task
                <br />&lt;/button&gt;
              </pre>
              <button className="btn btn-default fixed-width">
                fixed size
              </button>

              <hr />
              <pre className="code">
                &lt;button className=&quot;btn btn-default inline&quot;
                tabIndex=&quot;0&quot;&gt;Today&lt;/button&gt;
              </pre>
              <button className="btn btn-default inline" tabIndex="0">Today</button>

              <hr />
            </div>
          </div>
        </div>

        <div className="styleguide-section">
          <div className="wrapper">Styleguide: <a name="5">Components</a> - <a href="#top">back to top</a></div>
        </div>

        <div className="wrapper mb-2">
          <div className="row">
            <div className="col-1 mb-2">
              <form className="discount-form mt-1">
                <label htmlFor="discountCode">Have a discount code?</label>
                <div>
                  <input type="text" placeholder="Code" id="discountCode" />
                  <button className="btn btn-default small">Apply</button>
                </div>
                <p className="alert-error">This is an error relating to the code.</p>
              </form>
            </div>
          </div>
        </div>

        <div className="styleguide-section">
          <div className="wrapper">Styleguide: <a name="6">Page sections</a> - <a href="#top">back to top</a></div>
        </div>

        <div className="wrapper mb-2">
          <div className="row">
            <div className="col-1-3 secondary-left">secondary nav</div>
            <div className="col-2-3 col-last">
              <div className="main-primary col-1-2">main primary</div>
              <div className="main-secondary col-1-2 col-last">main secondary</div>
            </div>
          </div>
        </div>
        <div className="styleguide-section">
          <div className="wrapper">Styleguide: <a name="7">Footer</a> - <a href="#top">back to top</a></div>
        </div>
        <div className="footer">
          <div className="wrapper">
            <div className="row">
              <div className="col-1">
                © 2017 | Agilio iComply is a trading name of CODEplan Limited | Unit 18, Jessops Riverside, 
                800 Brightside Lane, Sheffield, England, S9 2RX  | Registered in
                England and Wales | Company Number: 03927086 | VAT 865046024
              </div>
            </div>
          </div>
        </div>

      {/* end styleguide */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    collapse: state.ui.get('collapse', Map())
  };
}

export default connect(
  mapStateToProps,
  {
    logout,
    openTeamMemberModal,
  }
)(Styleguide);
