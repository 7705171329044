import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';

import { getDigitalRecordNavigationList } from '^/actions/actions';
import { openCreateChecklistRecordGroupModal } from '^/actions/modals';
import { isPending } from '^/consts/responseStates';

export const CHECKLIST_RECORD_GROUP_COLLAPSE_ID = 'CHECKLIST_COLLAPSE_ID';

export class RecordsChecklistPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { activeChecklistRecordGroupId: '' };
  }

  componentDidMount() {
    this.props.getDigitalRecordNavigationList();
  }

  setActiveChecklistRecordGroupId(checklistRecordGroupId) {
    this.setState({ activeChecklistRecordGroupId: checklistRecordGroupId });
  }

  render() {
    const {
      practiceName,
      digitalRecordsNavigationListResponse,
      digitalRecordsNavigationList,
    } = this.props;

    if (
      isPending(digitalRecordsNavigationListResponse) ||
      !digitalRecordsNavigationList
    ) {
      return <Loading />;
    }

    const checklistRegex = /checklist\/([\w-]+)\//;
    const recordRegex = /records\/([\w-]+)\//;

    let currentNavigationItem = '';
    let match = window.location.href.match(checklistRegex);
    if (match) {
      currentNavigationItem = `checklist|${match[1]}`;
    } else {
      match = window.location.href.match(recordRegex);

      if (match) {
        currentNavigationItem = `record|${match[1]}`;
      }
    }

    const renderChecklistRecordGroup = recordGroup => {
      const recordGroupId = recordGroup.get('id');
      const recordGroupName = recordGroup.get('name');
      const currentDateId = recordGroup.get('current_date_id');
      const listItemClassNames =
        `checklist|${recordGroup.get('id')}` === currentNavigationItem
          ? 'display-flex mt-1-2 pt-1-2 checklist-active'
          : 'display-flex mt-1-2 pt-1-2';

      return (
        <li key={recordGroupId} className={listItemClassNames}>
          <Link
            onClick={() => this.setActiveChecklistRecordGroupId(recordGroupId)}
            to={`/page/tools/records/checklist/${recordGroupId}/${currentDateId}/`}
          >
            <h5>{recordGroupName}</h5>
          </Link>
        </li>
      );
    };

    const renderChecklistRecordType = navigationItem => {
      const slug = navigationItem.get('slug');
      const name = navigationItem.get('name');

      const collapseId = `${CHECKLIST_RECORD_GROUP_COLLAPSE_ID}_${slug}`;
      const displayAddNewButton =
        !navigationItem.get('allow_only_single_record_group') ||
        navigationItem.get('record_groups').size < 1;
      const relatedType = navigationItem.get('related_digital_record_type');
      const relatedTypeListItemClassNames =
        relatedType &&
        `record|${relatedType.get('slug')}` === currentNavigationItem
          ? 'display-flex mt-1-2 pt-1-2 checklist-active'
          : 'display-flex mt-1-2 pt-1-2';

      return (
        <div key={slug}>
          <div className="checklist-border mb-1 mt-1" />
          <CollapseButton collapseId={collapseId}>
            <label>{` ${name} `}</label>
          </CollapseButton>
          <Collapse collapseId={collapseId}>
            <ul className="list-indented">
              {navigationItem
                .get('record_groups')
                .map(recordGroup => renderChecklistRecordGroup(recordGroup))}
              {displayAddNewButton && (
                <li className="mt-1-2">
                  <Link to={`/page/tools/records/checklist/${slug}/add-new/`}>
                    <h5>+ Add New</h5>
                  </Link>
                </li>
              )}
              {relatedType && (
                <li className={relatedTypeListItemClassNames}>
                  <Link
                    className="base-font-color"
                    to={`/page/tools/records/${relatedType.get('slug')}/`}
                  >
                    <label className="pointer">
                      <h5>{relatedType.get('name')} records</h5>
                    </label>
                  </Link>
                </li>
              )}
            </ul>
          </Collapse>
        </div>
      );
    };

    const renderDigitalRecordType = navigationItem => {
      const listItemClassNames =
        `record|${navigationItem.get('slug')}` === currentNavigationItem
          ? 'display-flex mt-1-2 pt-1-2 pb-1-2 checklist-active'
          : 'display-flex mt-1-2 pt-1-2 pb-1-2';

      return (
        <div key={navigationItem.get('name')}>
          <div className="checklist-border mb-1 mt-1" />
          <Link
            className={listItemClassNames}
            to={`/page/tools/records/${navigationItem.get('slug')}/`}
          >
            <label className="pointer">
              {navigationItem.get('name')} records
            </label>
          </Link>
        </div>
      );
    };

    const renderNavigationItem = navigationItem => {
      const itemType = navigationItem.get('item_type');

      if (itemType === 'CHECKLIST_RECORD_TYPE') {
        return renderChecklistRecordType(navigationItem);
      } else if (itemType === 'DIGITAL_RECORD_TYPE') {
        return renderDigitalRecordType(navigationItem);
      }
    };

    return (
      <div className="row">
        <div className="col-1-3 collapsible-section-checklist">
          <h4>{practiceName}:</h4>
          {digitalRecordsNavigationList.map(navigationItem => {
            return renderNavigationItem(navigationItem);
          })}
        </div>
        <div className="col-2-3 bordered-left">{this.props.children}</div>
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  const digitalRecordsNavigationListResponse = state.responses.get(
    'getDigitalRecordNavigationList'
  );

  return {
    digitalRecordsNavigationListResponse: digitalRecordsNavigationListResponse,
    digitalRecordsNavigationList: state.digitalRecordsNavigationList,
    practiceName: state.currentPractice && state.currentPractice.get('name'),
    onChecklistView: Boolean(props.params.recordGroupId && props.params.date),
  };
}

export default connect(mapStateToProps, {
  openCreateChecklistRecordGroupModal,
  getDigitalRecordNavigationList,
})(RecordsChecklistPage);
