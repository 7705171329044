/* Displays a select dropdown that allows adding to the
 * displayed list of values for the many related field.
 * Requires a list of items, with ids, and a fieldKey
 * (can be nested) which will be used to display the choice.
*/

import React, { PropTypes } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Select from 'react-select';
import { Map } from 'immutable';


function getNotSelectedItems(items, selectedItems) {
  return items
    .filterNot(
      item =>
        selectedItems.find(
          selectedItem =>
            selectedItem.get('id') === item.get('id')
        )
    );
}


const SelectedItems = ({selectedItems, onRemove, displayKey}) =>
  <div className="mt-1-2">
    {selectedItems.map(
      selectedItem =>
        <div key={selectedItem.get('id')}>
          <span className="inline-label inline">{selectedItem.getIn(displayKey)}</span>
          <a className="pull-right inline-edit" onClick={() => onRemove(selectedItem.get('id'))}>
            Remove
          </a>
        </div>
    )}
  </div>;


const Picker = props => {
  const {
    items,
    selectedItems,
    onAdd,
    displayKey,
    controlName,
  } = props;

  const notSelectedItems = getNotSelectedItems(items, selectedItems);

  if (notSelectedItems.count() === 0) {
    return <noscript />;
  }

  const options =
    notSelectedItems
      .map(each => Map({ value: each.get('id'), label: each.getIn(displayKey), }));

  return (
    <Select
      placeholder="Search..."
      name={controlName}
      onChange={({value}) => onAdd(value)}
      options={options.toJS()}
      className="form-control-margin"
    />
  );
};


const ManyRelatedSelect = props =>
  <div>
    <Picker {...props} />
    <SelectedItems {...props} />
  </div>;


ManyRelatedSelect.propTypes = {
  items: ImmutablePropTypes.iterable.isRequired,
  selectedItems: ImmutablePropTypes.iterable.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  displayKey: PropTypes.array.isRequired,
  controlName: PropTypes.string.isRequired,
};

export default ManyRelatedSelect;
