import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { MODELS } from '^/permissions/capabilities';
import { reloadTemplateDocumentFolderAndRules } from '^/actions/actionSequences';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import AdminChangeLog from '^/components/admin/content/change-log/AdminChangeLog';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';
import HelpBlock from '^/components/app/widgets/HelpBlock';

import {
  ADMIN_TEMPLATE_DOCUMENT_FOLDER_CREATE,
  ADMIN_TEMPLATE_DOCUMENT_FOLDER_MODEL,
  ADMIN_TEMPLATE_DOCUMENT_FOLDER_EDIT_FIELDS,
  ADMIN_TEMPLATE_DOCUMENT_FOLDER_VIEW_FIELDS,
  TEMPLATE_DOCUMENT_FOLDER_HIDE_RULE,
  ADMIN_TEMPLATE_DOCUMENT_FOLDER_HIDE_RULE_MODEL,
  TEMPLATE_DOCUMENT_FOLDER_ROW_FIELDS,
  TEMPLATE_DOCUMENT_FOLDER_CREATE_FIELDS,
  transformTemplateDocumentFolderHideRuleChoices,
} from './template-documents';

const EditTemplateDocumentFolder = function({
  reloadRules,
  templateDocumentId,
  templateDocumentFolder,
  templateDocumentFolderFilter,
}) {
  return (
    <div>
      <AdminContainer title="Template Documents">
        <AdminEdit
          permsModel={MODELS.TEMPLATE_DOCUMENT}
          readOnlyFields={ADMIN_TEMPLATE_DOCUMENT_FOLDER_VIEW_FIELDS}
          itemId={templateDocumentId}
          controlName={ADMIN_TEMPLATE_DOCUMENT_FOLDER_CREATE}
          collectionName={ADMIN_TEMPLATE_DOCUMENT_FOLDER_MODEL}
          transform={fieldConfig =>
            fieldConfig.setIn(['sector', 'read_only'], true)
          }
          model={ADMIN_TEMPLATE_DOCUMENT_FOLDER_MODEL}
          fields={ADMIN_TEMPLATE_DOCUMENT_FOLDER_EDIT_FIELDS}
          title="Edit Template Document Folder"
        />
      </AdminContainer>
      <AdminContainer title="Folder hide rules">
        <HelpBlock>
          <p className="small-text">
            If folder hide rules exist for one or more memberships then ONLY
            those memberships can see this folder. Otherwise all memberships can
            see this folder.
          </p>
        </HelpBlock>

        <AdminList
          permsModel={MODELS.TEMPLATE_DOCUMENT}
          noSearch
          hidePagination
          listName={TEMPLATE_DOCUMENT_FOLDER_HIDE_RULE}
          pageSize={100}
          onRemove={() =>
            reloadRules(templateDocumentId, templateDocumentFolderFilter)
          }
          model={ADMIN_TEMPLATE_DOCUMENT_FOLDER_HIDE_RULE_MODEL}
          defaultFilters={Map(templateDocumentFolderFilter)}
          fields={TEMPLATE_DOCUMENT_FOLDER_ROW_FIELDS}
        />
        <AdminCreate
          permsModel={MODELS.TEMPLATE_DOCUMENT}
          collectionName={TEMPLATE_DOCUMENT_FOLDER_HIDE_RULE}
          controlName={TEMPLATE_DOCUMENT_FOLDER_HIDE_RULE}
          onComplete={() =>
            reloadRules(templateDocumentId, templateDocumentFolderFilter)
          }
          transform={config =>
            transformTemplateDocumentFolderHideRuleChoices(
              templateDocumentFolder,
              config
            )
          }
          model={ADMIN_TEMPLATE_DOCUMENT_FOLDER_HIDE_RULE_MODEL}
          fields={TEMPLATE_DOCUMENT_FOLDER_CREATE_FIELDS}
          defaultValues={templateDocumentFolderFilter}
          title="Add Template Folder Hide Rule"
        />
      </AdminContainer>
      <AdminContainer>
        <AdminChangeLog
          model="TemplateDocumentFolder"
          id={templateDocumentId}
        />
      </AdminContainer>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    templateDocumentId: props.params.uuid,
    templateDocumentFolder: state.items.get(
      ADMIN_TEMPLATE_DOCUMENT_FOLDER_MODEL
    ),
    templateDocumentFolderFilter: { folder: props.params.uuid },
  };
}

export default connect(mapStateToProps, {
  reloadRules: reloadTemplateDocumentFolderAndRules,
})(EditTemplateDocumentFolder);
