import React from 'react';


const ChooseAssignees = ({onAdd, onRemove, practiceMembers, assignees, singleAssignee}) => {
  const practiceMembersNotAssigned = practiceMembers
    .filterNot(member => assignees.find(assignee => assignee.get('id') === member.get('id')));

  return (
    <div>
      <div>
        {assignees.map(assignee =>
          <div className="nowrap" key={assignee.get('id')}>
            {assignee.get('full_name')} <a
              className="inline-edit"
              onClick={() => onRemove(assignee.get('id'))}
            >
              Remove
            </a>
          </div>
        )}
      </div>

      {(!singleAssignee || (singleAssignee && assignees.count() < 1)) && (
        <select
          defaultValue={0} onChange={evt => evt.target.value && onAdd(evt.target.value)}
          id="assignee" className="form-control-margin"
        >
          <option value={0}>{assignees.isEmpty() ? 'Choose an' : 'Add another'} assignee...</option>
          {practiceMembersNotAssigned.map(user =>
            <option key={user.get('id')} value={user.get('id')}>
              {user.get('full_name')}
            </option>
            )}
        </select>
      )}
    </div>
  );
};

export default ChooseAssignees;
