import React from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';

import { resetResponse, setResponseErrorForKey } from '^/actions/actions';
import { getAllCollection } from '^/actions/collections';
import { createGroupDraftShowDraftsAndRedirect } from '^/actions/groupDocuments';
import { loadItem, patchItem } from '^/actions/items';
import { openPublishGroupDocumentModal } from '^/actions/modals';
import { DOCUMENT_TYPE_CONTENT_FIELDS } from '^/components/admin/template-documents/template-documents';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import GroupTemplateDocumentForm from '^/components/app/groups/group-documents/GroupTemplateDocumentForm';
import {
  GROUP_CREATED_TEMPLATE_DOCUMENTS,
  GROUP_TEMPLATE_DOCUMENT_DRAFTS,
  TEMPLATE_CATEGORIES,
  TEMPLATE_FOLDERS
} from '^/consts/collectionKeys';
import { hasFailed, isPending } from '^/consts/responseStates';
import { TYPES } from '^/models/documents';
import { withRouter } from '^/withRouter';

const EMPTY_CONTENT_ERROR_MESSAGE = 'Please add some content to publish.';
export const EMPTY_FILE_ERROR_MESSAGE = 'Please upload a file to publish.';

function cleanData(data) {
  return Map(data).filter((_value, key) => {
    const allowedContentTypes = DOCUMENT_TYPE_CONTENT_FIELDS.get(key);
    return allowedContentTypes ?
      Boolean(allowedContentTypes.find(type => type === data.type)) :
      true;
  }).toObject();
}

export class GroupTemplateDocumentPage extends PureComponent {
  constructor(props) {
    super(props);
    this.onSaveAsDraft = this.onSaveAsDraft.bind(this);
    this.onPublishAsNewDocument = this.onPublishAsNewDocument.bind(this);
    this.canPublish = this.canPublish.bind(this);
    this.setResponseError = this.setResponseError.bind(this);
  }

  componentDidMount() {
    const { groupTemplateDocumentDraftId } = this.props;
    if (groupTemplateDocumentDraftId) {
      this.props.loadItem(GROUP_TEMPLATE_DOCUMENT_DRAFTS, groupTemplateDocumentDraftId);
    }

    this.props.getAllCollection(TEMPLATE_CATEGORIES);
    this.props.getAllCollection(TEMPLATE_FOLDERS);
  }

  onSaveAsDraft(data) {
    const { groupTemplateDocumentDraftId } = this.props;
    this.props.resetResponse('createItem');
    if (groupTemplateDocumentDraftId) {
      this.props.patchItem(GROUP_TEMPLATE_DOCUMENT_DRAFTS, groupTemplateDocumentDraftId, data);
    } else {
      this.props.createGroupDraftShowDraftsAndRedirect(cleanData(data));
    }
  }

  onPublishAsNewDocument(data) {
    this.props.resetResponse('createItem');
    this.props.resetResponse('updateItem');
    if (this.canPublish(data)) {
      this.props.openPublishGroupDocumentModal(
        cleanData(data),
        this.props.groupTemplateDocumentDraftId
      );
    }
  }

  canPublish(data) {
    if (data.type === TYPES.HTML && !data.content) {
      this.setResponseError(EMPTY_CONTENT_ERROR_MESSAGE);
      return false;
    } else if (data.type === TYPES.FILE && !data.content_file) {
      this.setResponseError(EMPTY_FILE_ERROR_MESSAGE);
      return false;
    }
    return true;
  }

  setResponseError(error) {
    this.props.setResponseErrorForKey('createItem', GROUP_CREATED_TEMPLATE_DOCUMENTS, error);
  }

  render() {
    const {
      groupTemplateDocumentDraftId,
      groupTemplateDocumentDraft,
      templateCategories,
      templateFolders,
      isLoadingDraft,
      hasFailedLoadingDraft,
    } = this.props;
    const initialValues = groupTemplateDocumentDraftId &&
      groupTemplateDocumentDraftId === groupTemplateDocumentDraft.get('id') ? {
      ...groupTemplateDocumentDraft.toObject(),
      type: groupTemplateDocumentDraftId ? TYPES.HTML : ''
    } : undefined;

    if (isLoadingDraft) {
      return <Loading className="mt-1" />
    }

    if (hasFailedLoadingDraft) {
      return <p className="mt-1 text-error">Failed to load draft.</p>
    }

    return (
      <GroupTemplateDocumentForm
        onSubmit={this.onPublishAsNewDocument}
        categories={templateCategories}
        folders={templateFolders}
        initialValues={initialValues}
        isEditingDraft={!!groupTemplateDocumentDraftId}
        submitDraft={this.onSaveAsDraft}
      />
    );
  }
};

export const mapStateToProps = (state, props) => {
  const loadDraftResponse = state.responses.getIn(['loadItem', GROUP_TEMPLATE_DOCUMENT_DRAFTS])
  return {
    groupTemplateDocumentDraftId: props.params.uuid,
    groupTemplateDocumentDraft: state.items.get(GROUP_TEMPLATE_DOCUMENT_DRAFTS) || Map(),
    templateCategories: state.collections.getIn([TEMPLATE_CATEGORIES, 'items'], List()),
    templateFolders: state.collections.getIn([TEMPLATE_FOLDERS, 'items'], List()),
    isLoadingDraft: isPending(loadDraftResponse),
    hasFailedLoadingDraft: hasFailed(loadDraftResponse),
  }
};

export default connect(mapStateToProps, {
  getAllCollection,
  createGroupDraftShowDraftsAndRedirect,
  loadItem,
  patchItem,
  openPublishGroupDocumentModal,
  setResponseErrorForKey,
  resetResponse
}) (withRouter(GroupTemplateDocumentPage));
