import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import { makeStaticPath } from '^/utils';

import LiveButton from '^/components/app/forms/LiveButton';

function actionAndCloseModal(action, deleteDigitalRecordTasks, onClose) {
  deleteDigitalRecordTasks();
  action();
  onClose();
}

export const ExitRecordFormConfirmationModal = ({
  discard,
  deleteDigitalRecordTasks,
  closeModal: onClose,
}) => {
  return (
    <div className="leaving-confirmation-modal-wrapper">
      <div className="brand-logo-modal">
        <img src={makeStaticPath('images/brand/logo-color.svg')} className="brand-logo-modal" />
      </div>
      <div className="leaving-modal-list">
        <ul>
          <li>Have you saved your changes?</li>
          <li>
            If you click 'Discard Changes' you will lose any edits to the record that you have
            made since you last saved it.
          </li>
          <li>Click 'Cancel' to go back to edit/save the record.</li>
        </ul>
      </div>
      <div className="leaving-modal-buttons">
        <LiveButton
          className="btn btn-leaving-modal inline-spaced"
          onClick={() => actionAndCloseModal(discard, deleteDigitalRecordTasks, onClose)}
        >
          Discard Changes
        </LiveButton>
        <LiveButton
          className="btn btn-leaving-modal inline-spaced"
          onClick={onClose}
        >
          Cancel
        </LiveButton>
      </div>
    </div>
  );
};

export default connect(null, { closeModal })(ExitRecordFormConfirmationModal);
