
import { fromJS } from 'immutable';

export const ADMIN_QPS_FILE_MODEL = 'admin/qps-files';
const ADMIN_QPS_FILE_MANAGE_ROUTE = '/admin/qps-page/${id}/';

export const ADMIN_QPS_FILE_CREATE_FIELDS = fromJS([
  'title',
  'description',
  'file',
]);

export const ADMIN_QPS_FILE_EDIT_FIELDS = fromJS([
  'title',
  'description',
  'file',
]);

export const ADMIN_QPS_FILE_ROW_FIELDS = fromJS([
  {name: ['title'], display_name: 'Title', type:'string', sortable: true},
  {name: ['created'], display_name: 'Created', type: 'datetime', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Manage', route: ADMIN_QPS_FILE_MANAGE_ROUTE},
  {name: ['id'], display_name: 'Remove', type: 'remove', sortable: false},
]);
