import React from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';

import { loadItem } from '^/actions/items';
import { CYCLES } from '^/consts/collectionKeys';
import { loadTemplateActivities } from '^/actions/actions';
import { setupSecurityAuditsAndReloadUser } from '^/actions/actionSequences';
import { getCurrentPracticeId } from '^/models/user';
import { getCycleToSetupId } from '^/consts/setup';
import { formatDate } from '^/utils';

import { AUDIT_TEMPLATE_TYPE } from '^/models/activities';

import AccountSetupHeader from '^/components/app/account-set-up/AccountSetupHeader';
import SecurityAuditsSetupForm, { transformAuditData } from '^/components/app/account-set-up/SecurityAuditsSetupForm';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import Loading from '^/components/app/content/Loading';
import PureComponent from '^/components/common/PureComponent';
import { SECURITY_AUDIT_START_DAY } from '^/consts/account-set-up/fieldNames';

export class SecurityAuditsSetupPage extends PureComponent {

  componentWillMount() {
    const { practiceId, cycleId } = this.props;
    this.loadPracticeCycleAndSecurityAudits(practiceId, cycleId);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.practiceId !== newProps.practiceId || this.props.cycleId !== newProps.cycleId) {
      this.loadPracticeCycleAndSecurityAudits(newProps.practiceId, newProps.cycleId);
    }
  }

  loadPracticeCycleAndSecurityAudits(practiceId, cycleId) {
    if (practiceId && cycleId) {
      this.props.loadItem(CYCLES, cycleId);
      this.props.loadTemplateActivities(AUDIT_TEMPLATE_TYPE, practiceId, cycleId);
    }
  }

  transformAndSubmitData(data) {
    const { cycleId, templateSecurityAudits, cycle } = this.props;
    const preferredWeekday = data[SECURITY_AUDIT_START_DAY];
    const audits = fromJS(data).remove(SECURITY_AUDIT_START_DAY);

    const dataToSubmit = {
      [SECURITY_AUDIT_START_DAY]: preferredWeekday,
      audits: transformAuditData(audits, templateSecurityAudits, cycle, preferredWeekday)
    };

    this.props.setupSecurityAuditsAndReloadUser(cycleId, dataToSubmit);
  }

  render() {
    const { user, practice, templateSecurityAudits, response, cycle } = this.props;

    if (!user || !practice || templateSecurityAudits === undefined || templateSecurityAudits === null || !cycle) {
      return <Loading />;
    }

    return (
      <div>
        <AccountSetupHeader user={user} practice={practice}>
          Setup - audit dates
        </AccountSetupHeader>

        <HelpBlock>
          <p className="small-text">
            We've already selected all of the compulsory audits for you, so all
            you need to do is pick the optional ones you'd like to add, and add the 'date last done' dates.
          </p>
          <p className="small-text">
            For compulsory audits, next due dates will populate automatically on entering a last done date.
            You'll be able to edit these dates via the calendar once the cycle setup is complete.
          </p>
          <p className="small-text">
            Audits due after the end of the cycle ({ formatDate(cycle.get('end_date')) })
            will not be created in the current cycle.
          </p>
          <p className="small-text">
            For optional audits, you may enter a date if you wish to schedule any.
          </p>
        </HelpBlock>

        <hr className="thin" />
        <SecurityAuditsSetupForm
          response={response}
          templateSecurityAudits={templateSecurityAudits}
          cycleStartDate={cycle.get('start_date')}
          onSubmit={data => this.transformAndSubmitData(data)}
        />
      </div>
    );
  }
}


export const mapStateToProps = (state) => {
  const practiceId = getCurrentPracticeId(state.userProfile);
  return {
    user: state.userProfile,
    practiceId,
    cycleId: getCycleToSetupId(state.userProfile, practiceId),
    templateSecurityAudits: state.templateActivities.get(AUDIT_TEMPLATE_TYPE),
    practice: state.currentPractice,
    cycle: state.items.get(CYCLES),
    response: state.responses.get('setupSecurityAudits'),
  };
};

export default connect(mapStateToProps,
  { loadItem, loadTemplateActivities, setupSecurityAuditsAndReloadUser }
) (SecurityAuditsSetupPage);
