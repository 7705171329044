import React from 'react';

import InlineSVG from '^/components/common/inlinesvg';
import { makeStaticPath } from '^/utils';
import PureComponent from '^/components/common/PureComponent';

export default class Icon extends PureComponent {
  getClassName() {
    return 'icon '
      + (this.props.set ? 'icon-' + this.props.set : 'icon-fa')
      + (' icon-' + this.props.type)
      + (this.props.className ? ' '.concat(this.props.className) : '');
  }

  render() {
    const { type, title } = this.props;
    const svg = (
      <InlineSVG
        {...this.props}
        className={this.getClassName()}
        src={makeStaticPath(`images/icons/fa/${type}.svg`)}
        key={type}
      />
    );

    return title ? (
      <span title={title}>
        {svg}
      </span>
    ) : (
      svg
    );
  }
}
