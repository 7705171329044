import React from 'react';
import { connect } from 'react-redux';
import { fromJS, Map } from 'immutable';
import classNames from 'classnames';

import { DOCUMENT_VERSIONS_TO_READ } from '^/consts/collectionKeys';
import { loadItem } from '^/actions/items';
import { documentReadRequestSendReminder } from '^/actions/actions';
import { openDocReadAddTeamMembersModal, openArchiveDocumentToReadModal } from '^/actions/modals';
import { deleteDocReadRequestAndUpdateCollections } from '^/actions/actionSequences';
import { isPending } from '^/consts/responseStates';
import { ARCHIVED } from '^/utils';

import PureComponent from '^/components/common/PureComponent';
import TableList from '^/components/app/tables/TableList';
import BackButton from '^/components/app/nav/back-button';
import Loading from '^/components/app/content/Loading';
import CSVExporterButton from '^/components/common/CSVExporterButton';

export const PAGE_SIZE = 20

export class DocumentReadDetailPage extends PureComponent {
  constructor(props) {
    super(props);
    this.removeDocReadUserFromList = this.removeDocReadUserFromList.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
  }

  componentDidMount() {
    const { documentVersionToReadId } = this.props;

    this.loadDocumentToReadDetail(documentVersionToReadId);
  }

  loadDocumentToReadDetail(documentVersionToReadId) {
    if (documentVersionToReadId) {
      this.props.loadItem(documentVersionToReadId);
    }
  }

  isDisabled(documentReadRequest) {
    const { documentVersionToRead } = this.props;

    return documentReadRequest.get('read_time') || documentVersionToRead.get('status') === ARCHIVED;
  }

  getExportUrl(documentVersionToReadId) {
    return `/documents/document-versions-to-read/${documentVersionToReadId}/documents-read-requests/csv-export/`
  }

  removeDocReadUserFromList(documentReadRequest) {
    const { documentVersionToRead } = this.props;

    if (documentVersionToRead && documentVersionToRead.get('status') === ARCHIVED) {
      return false;
    }

    const { documentVersionToReadId } = this.props;

    if (documentReadRequest && documentVersionToReadId) {
      this.props.deleteDocReadRequestAndUpdateCollections(
        documentReadRequest.get('id'),
        documentVersionToReadId
      )
    }
  }

  getDisableRemoveLinkStyle(documentReadRequest) {
    return documentReadRequest.get('status') === ARCHIVED ? classNames('not-allowed disabled') : '';
  }

  render() {
    const {
      documentVersionToReadId,
      documentVersionToRead,
      documentVersionToReadResponse,
      practiceId,
      sendReminderResponse,
      sendReminder
    } = this.props;

    if (isPending(documentVersionToReadResponse)) {
      return <Loading />;
    }

    const {
      document_name,
      document_code,
      status,
      template_document_version_id,
    } = documentVersionToRead.toObject()

    const fields = fromJS([
      {name: ['team_member'], display_name: 'Team Member', type: 'string', sortable: true},
      {name: ['status'], display_name: 'Status', type: 'string', sortable: true},
      {name: ['sender'], display_name: 'Sent by', type: 'string', sortByName: 'outstanding_read', sortable: true},
      {name: ['due_date'], display_name: 'Due Date', type: 'string', sortable: true},
      {name: ['read_time'], display_name: 'Date Read', type: 'string', sortable: true},
      {
        display_name: 'Send Reminder',
        type: 'live_button',
        liveButton: { text: 'Send', successText: 'Sent!', keyedResponse: sendReminderResponse, disabled: this.isDisabled },
        handler: sendReminder
      },
      {
        display_name: 'Remove User',
        type: 'function', 
        sortable: false, 
        link_text: 'Remove', 
        handler: this.removeDocReadUserFromList,
        styleHandler: this.getDisableRemoveLinkStyle,
      },
    ]);

    return (
      <div className="wrapper">
        <BackButton />
        <div className="mb-2">
          <div className="pull-left">
            <p><span className="bold-text">Document Name: </span>{document_name}</p>
            <p><span className="bold-text">Code: </span>{document_code}</p>
            <p><span className="bold-text">Status: </span>{status}</p>
          </div>
          <div className="pull-right">
            <div>
              <CSVExporterButton
                url={this.getExportUrl(documentVersionToReadId)}
                className="btn btn-default fullwidth centered mb-1"
              >
                DOWNLOAD CSV
              </CSVExporterButton>
            </div>
            <div>
              <button
                onClick={() => this.props.openDocReadAddTeamMembersModal(
                  template_document_version_id,
                  practiceId,
                  documentVersionToReadId,
                )}
                className="btn btn-primary"
                disabled={status === ARCHIVED}
              >
              + Send To New Team Member
            </button>
          </div>
          </div>
          <TableList
            model={`${DOCUMENT_VERSIONS_TO_READ}/${documentVersionToReadId}/document-read-requests`}
            fields={fields}
            pageSize={PAGE_SIZE}
            noSearch
          />
          <button
            onClick={() => this.props.openArchiveDocumentToReadModal(
              documentVersionToReadId,
              document_name
            )}
            type="button"
            className="btn btn-warning pull-right force-move-up"
            disabled={status === ARCHIVED}
          >
            Archive
          </button>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendReminder: (documentReadRequest) => dispatch(documentReadRequestSendReminder(documentReadRequest.get('id'))),
    loadItem: (documentVersionToReadId) => dispatch(loadItem(DOCUMENT_VERSIONS_TO_READ, documentVersionToReadId)),
    openDocReadAddTeamMembersModal: (template_document_version_id, practiceId, documentVersionToReadId) => dispatch(
      openDocReadAddTeamMembersModal(template_document_version_id, practiceId, documentVersionToReadId, true)
    ),
    openArchiveDocumentToReadModal: (documentVersionToReadId, document_name) => dispatch(
      openArchiveDocumentToReadModal(documentVersionToReadId, document_name)
    ),
    deleteDocReadRequestAndUpdateCollections: (docReadRequestId, documentVersionToReadId) => {
      return dispatch(
        deleteDocReadRequestAndUpdateCollections(docReadRequestId, documentVersionToReadId)
    )}
  };
}

function mapStateToProps(state, props) {
  const documentVersionToReadId = props.params.uuid;
  const practiceId = state.currentPractice.get("id");
  return {
    practiceId,
    documentVersionToReadId: documentVersionToReadId,
    documentVersionToRead: state.items.get(
      DOCUMENT_VERSIONS_TO_READ,
      Map()
    ),
    documentVersionToReadResponse: state.responses.getIn([
      'loadItem',
      DOCUMENT_VERSIONS_TO_READ
    ]),
    sendReminderResponse: state.responses.get('documentReadRequestSendReminderResponse'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentReadDetailPage);
