import React from 'react';
import { reduxForm } from 'redux-form';
import { Map } from 'immutable';

import LiveButton from '^/components/app/forms/LiveButton';

import { ActivityPriority } from '^/components/app/activities/ActivityPriority';

export const ASESSMENTS_GROUP_CONFIG_FORM = 'ASESSMENTS_GROUP_CONFIG_FORM';

export const TemplateAssessmentsFields = ({
  fields,
  optionalAssessments,
  mandatoryAssessments
}) => {
  return (
    <div className="mt-1 small-text pl-1 pr-1">
      <div className="row">
        <div className="col-1-2">
          <h5>Compulsory Assessments ({mandatoryAssessments.size})</h5>
            {mandatoryAssessments.size > 0 ? (
                <table className="setup-compliance-review-table selectable">
                  <tbody>
                    {mandatoryAssessments.map(each => (
                      <tr key={each.get('id')}>
                        <td>
                          <input type="checkbox" {...fields[each.get('id')]} id={each.get('id')} />
                        </td>
                        <td className="table-col-lg">
                          <label title={each.get('help_text')} htmlFor={each.get('id')}>
                            {each.get('name')}
                          </label>
                        </td>
                        <td>
                          <ActivityPriority activity={each}/>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
            ) : <p>There are no compulsory assessments for this practice group</p>
          }
        </div>
        <div className="col-1-2">
            <h5>Optional Assessments ({optionalAssessments.size})</h5>
            {optionalAssessments.size > 0 ? (
                <table className="setup-compliance-review-table selectable">
                  <tbody>
                    {optionalAssessments.map(each => (
                      <tr key={each.get('id')}>
                        <td>
                          <input type="checkbox" {...fields[each.get('id')]} id={each.get('id')} />
                        </td>
                        <td className="table-col-lg">
                          <label title={each.get('help_text')} htmlFor={each.get('id')}>
                            {each.get('name')}
                          </label>
                        </td>
                        <td>
                          <ActivityPriority activity={each}/>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : <p>There are no optional assessments for this practice group</p>
						}
        </div>
      </div>
    </div>
  );
};

export const AssessmentsGroupConfigForm = ({
  handleSubmit,
  fields,
  templateAssessments,
  response,
}) => {
  const optionalAssessments = templateAssessments
    .map((templateAssessment) => templateAssessment.get('template_activity'))
    .filter((templateActivity) => templateActivity.getIn(['template_activity', 'is_optional']));

  const mandatoryAssessments = templateAssessments
    .map((templateAssessment) => templateAssessment.get('template_activity'))
    .filter((templateActivity) => !templateActivity.getIn(['template_activity', 'is_optional']));

  return (
    <form onSubmit={handleSubmit}>
      <hr className="thin" />
      <div className="mb-2">
        <TemplateAssessmentsFields
          fields={fields}
          optionalAssessments={optionalAssessments}
          mandatoryAssessments={mandatoryAssessments}
        />
      </div>
  
      <LiveButton className="btn-default" pendingMessage="Saving..." response={response}>
        Save
      </LiveButton>
    </form>
  );
};


export const getFields = (templateAssessments) => templateAssessments
  .map(templateAssessment => templateAssessment.getIn(['template_activity', 'id']))
  .flatten()
  .toJS();

export const getInitialValues = (templateAssessments) =>
  Map(
    templateAssessments
      .map(
      templateAssessment =>
        [
          templateAssessment.getIn(['template_activity', 'id']),
          !templateAssessment.getIn(['template_activity', 'is_excluded'])
        ]
    )
  .flatten())
  .toJS();

export const mapStateToProps = (state, props) => {
  return {
    fields: getFields(props.templateAssessments),
    initialValues: getInitialValues(props.templateAssessments),
  };
};

export default reduxForm({
  form: ASESSMENTS_GROUP_CONFIG_FORM,
}, mapStateToProps)(AssessmentsGroupConfigForm);
