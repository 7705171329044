import React from 'react';
import { connect } from 'react-redux';
import { isLoggedOnUserAdminOfCurrentPractice } from '^/stateHelpers';
import { userPracticesSetupData } from '^/consts/setup';
import Small from '^/components/app/content/Small';

const hasAnyPractices = (user) => !userPracticesSetupData(user).isEmpty();

const canUserSetup = (state) => // User can setup if
  !hasAnyPractices(state.userProfile) || // they don't have any practices
  isLoggedOnUserAdminOfCurrentPractice(state); // or they are the admin of the current practice

const SetupDisallowedMessage = ({className}) =>
  <div className={className}>
    <div className="alert alert-warning p-1">
      <p><strong>Sorry you are not allowed here!</strong></p>
        <Small>
          Your practice admin has partially completed setting up your practice.
        </Small>
        <Small>
          Until this has been completed you will not be able to access iComply for this practice.
        </Small>
        <Small>
          If you belong to any other practices in iComply you can still access these as you would normally by switching
          the practice you are viewing in the menu at the top of this page.
        </Small>
    </div>
  </div>;

export const SetupMayDisallow = ({isBlocked, children, className}) =>
  !isBlocked ?
    <div className={className}>{children}</div> :
    <SetupDisallowedMessage className={className} />;

SetupMayDisallow.propTypes = {
  children: React.PropTypes.any.isRequired,
  className: React.PropTypes.string,
};

export const mapStateToProps = (state) => ({
  isBlocked: !canUserSetup(state),
});

export default connect(mapStateToProps) (SetupMayDisallow);
