import React from 'react';

import CycleProgress from '^/components/app/widgets/CycleProgress';
import ActivityStatsThisYearChart from '^/components/app/stats/activities/ActivityStatsThisYearChart';
import CycleProgressToDateText from '^/components/app/widgets/CycleProgressToDateText';


export const ActivityStatsThisYear = ({stats}) => {
  const practiceId = stats.get('current_practice_id');
  const practiceData = stats.getIn(['practice_activity_data', practiceId, 'activity_stats']);
  const cycleProgress = stats.getIn(['practice_activity_data', practiceId, 'progress_through_cycle'], 0);
  const cycleProgressToDate = stats.getIn(['practice_activity_data', practiceId, 'progress_to_date']);

  return practiceData ? (
    <div className="stats-graph-wrapper row mt-1">
      <div className="col-3-4">
        <ActivityStatsThisYearChart practiceData={practiceData} chartId={practiceId} />
      </div>
      <div className="col-1-4">
        <CycleProgress progress={cycleProgress} />
        <CycleProgressToDateText progress={cycleProgressToDate} />
      </div>
    </div>
  ) : <p>No data found for current year.</p>;
};

export default ActivityStatsThisYear;
