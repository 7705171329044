import React from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import PureComponent from '^/components/common/PureComponent';

import { getItemOptions, loadItem } from '^/actions/items';
import { generateCycleReport } from '^/actions/actionSequences';
import { getCollection } from '^/actions/collections';
import { isPending } from '^/consts/responseStates';
import { CAPABILITIES, MODELS } from '^/permissions/capabilities';
import IfCan from '^/components/common/IfCan';
import {
  ADMIN_PRACTICE_MODEL,
  ADMIN_STAFF_PRACTICE_MODEL,
  ADMIN_COMPLIANCE_DETAILS_MODEL,
  ADMIN_PRACTICE_OPTIONS,
  ADMIN_PRACTICE_AUDIT_ROW_FIELDS,
  ADMIN_COMPLIANCE_DETAILS_OPTIONS,
  ADMIN_PRACTICE_EDIT_FIELDS,
  ADMIN_COMPLIANCE_DETAILS_EDIT_FIELDS,
  ADMIN_PRACTICE_VIEW_FIELDS,
  ADMIN_PRACTICE_MEMBERS_FIELDS,
  ADMIN_PRACTICE_SERVICES_FIELDS,
  ADMIN_PRACTICE_CHARGES_FIELDS,
  PRACTICES_STAFF_MEMBERS,
  PRACTICES_MEMBERSHIPS,
  ADMIN_PRACTICE_MEMBERS_CREATE_FIELDS,
  ADMIN_PRACTICE_MEMBERSHIP_MODEL,
  ADMIN_PRACTICE_MEMBERSHIP_FIELDS,
  ADMIN_PRACTICE_MEMBERSHIP_CREATE_FIELDS,
  getAdminPracticeCycleRowFields,
  transformMembershipField,
  transformPracticeDetailsFields
} from '^/components/admin/practices/practice';

import {
  ADMIN_CYCLE_MODEL
} from '^/components/admin/cycles/cycles';

import Loading from '^/components/app/content/Loading';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';
import AdminManyRelatedField from '^/components/admin/content/many-related-field/AdminManyRelatedField';
import DeactivatePractice from '^/components/admin/practices/DeactivatePractice';
import AdminChangeLog, { reloadChangeLog } from '^/components/admin/content/change-log/AdminChangeLog';
import RestorePolicyDocuments from './RestorePolicyDocuments';


export class ManagePractice extends PureComponent {
  componentWillMount() {
    const { practiceId, isEditing, itemOptions } = this.props;
    if (isEditing && itemOptions.isEmpty()) {
      this.getOptions(practiceId);
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.practiceId !== newProps.practiceId) {
      this.getOptions(newProps.practiceId);
    }
  }

  getOptions(practiceId) {
    this.props.getItemOptions(ADMIN_PRACTICE_MODEL, practiceId, ADMIN_PRACTICE_OPTIONS);
    this.props.getItemOptions(ADMIN_COMPLIANCE_DETAILS_MODEL, null, ADMIN_COMPLIANCE_DETAILS_OPTIONS);
  }

  isLoading() {
    const { isEditing, optionsResponse, itemOptions } = this.props;
    return isEditing && ((isPending(optionsResponse)) || !itemOptions);
  }

  reloadStaffMembers() {
    const { practiceId } = this.props;
    return getCollection(
      ADMIN_STAFF_PRACTICE_MODEL,
      { pageSize: 100, filters: { practice: practiceId }},
      PRACTICES_STAFF_MEMBERS
    );
  }

  reloadPractice() {
    const { practiceId } = this.props;
    this.props.loadItem(ADMIN_PRACTICE_MODEL, practiceId, practiceId);
  }

  getComplianceDetailsField(practice) {
    const sector = practice && practice.get('sector');
    if (sector) {
      return ADMIN_COMPLIANCE_DETAILS_EDIT_FIELDS.get(sector) || List();
    }
    return List();
  }

  render() {
    const {
      practiceId, itemOptions, readOnly, complianceDetailsOptions,
      practice, collectionName, staffPracticePageSize
    } = this.props;

    if (this.isLoading()) {
      return (
        <AdminContainer title="Loading practice...">
          <Loading />
        </AdminContainer>
      );
    }
      return (
        <AdminContainer itemId={practiceId} model={ADMIN_PRACTICE_MODEL} collectionName={collectionName}>
          <AdminContainer>
            <AdminEdit
              permsModel={MODELS.PRACTICE}
              readOnly={readOnly}
              controlName="PRACTICES_DETAILS"
              model={ADMIN_PRACTICE_MODEL}
              collectionName={ADMIN_PRACTICE_MODEL}
              transform={transformPracticeDetailsFields}
              itemId={practiceId}
              options={itemOptions}
              onComplete={reloadChangeLog('Practice', practiceId)}
              fields={ADMIN_PRACTICE_EDIT_FIELDS}
              readOnlyFields={ADMIN_PRACTICE_VIEW_FIELDS}
              title="Practice Details"
            />
          </AdminContainer>

          <AdminContainer title="Compliance Details">
            {practice && (
              practice.get('compliance_details') ? (
                <AdminEdit
                  className="wide-label"
                  permsModel={MODELS.PRACTICE}
                  readOnly={readOnly}
                  controlName="COMPLIANCE_DETAILS"
                  model={ADMIN_COMPLIANCE_DETAILS_MODEL}
                  collectionName={ADMIN_COMPLIANCE_DETAILS_MODEL}
                  options={complianceDetailsOptions}
                  onComplete={reloadChangeLog('Practice', practiceId)}
                  fields={this.getComplianceDetailsField(practice)}
                  itemId={practice.getIn(['compliance_details', 'id'])}
                  readOnlyFields={[]}
                />
              ) : (
                <AdminCreate
                  className="wide-label"
                  permsModel={MODELS.PRACTICE}
                  readOnly={readOnly}
                  controlName="COMPLIANCE_DETAILS"
                  model={ADMIN_COMPLIANCE_DETAILS_MODEL}
                  collectionName={ADMIN_COMPLIANCE_DETAILS_MODEL}
                  options={complianceDetailsOptions}
                  fields={this.getComplianceDetailsField(practice)}
                  transformDataToSubmit={data => Object.assign({}, data, {practice: practice.get('id')})}
                  onComplete={() => this.reloadPractice()}
                  saveButtonText="Save"
                />
              )
            )}
          </AdminContainer>

          <AdminContainer className="mt-2">
            <AdminManyRelatedField
              addLabel="Assign Audits to a practice"
              itemId={practiceId}
              controlName="PRACTICE_ACTIVE_AUDITS"
              options={itemOptions}
              model={ADMIN_PRACTICE_MODEL}
              fieldName={['active_audit_types']}
              fields={ADMIN_PRACTICE_AUDIT_ROW_FIELDS}
              listTitle="Digital Audits"
              defaultLookupFilters={{sector: practice && practice.get('sector')}}
            />
          </AdminContainer>

          <AdminContainer className="mt-2">
            <AdminManyRelatedField
              hideAddItem
              itemId={practiceId}
              controlName="PRACTICES_CONTACTS"
              options={itemOptions}
              model={ADMIN_PRACTICE_MODEL}
              fieldName={['contacts']}
              fields={ADMIN_PRACTICE_SERVICES_FIELDS}
              listTitle="Contacts"
            />
          </AdminContainer>

          <AdminContainer className="mt-2" title="Memberships">
            <AdminList
              permsModel={MODELS.PRACTICE}
              noSearch
              hidePagination
              pageSize={staffPracticePageSize}
              listName={PRACTICES_MEMBERSHIPS}
              model={ADMIN_PRACTICE_MEMBERSHIP_MODEL}
              fields={ADMIN_PRACTICE_MEMBERSHIP_FIELDS}
              defaultFilters={Map({ practice: practiceId })}
            />
            {!readOnly && (
              <AdminCreate
                permsModel={MODELS.PRACTICE}
                collectionName={PRACTICES_MEMBERSHIPS}
                transform={transformMembershipField}
                controlName={PRACTICES_MEMBERSHIPS}
                model={ADMIN_PRACTICE_MEMBERSHIP_MODEL}
                onComplete={reloadChangeLog('Practice', practiceId)}
                fields={ADMIN_PRACTICE_MEMBERSHIP_CREATE_FIELDS}
                defaultValues={{ practice: practiceId }}
                title="Add Membership"
              />
              )}
          </AdminContainer>

          <AdminContainer className="mt-2" title="Members">
            <AdminList
              permsModel={MODELS.PRACTICE}
              noSearch
              pageSize={staffPracticePageSize}
              listName={PRACTICES_STAFF_MEMBERS}
              model={ADMIN_STAFF_PRACTICE_MODEL}
              fields={ADMIN_PRACTICE_MEMBERS_FIELDS}
              defaultFilters={Map({practice: practiceId})}
            />
            {!readOnly && (
              <AdminCreate
                permsModel={MODELS.PRACTICE}
                onComplete={() => this.reloadStaffMembers()}
                collectionName={PRACTICES_STAFF_MEMBERS}
                controlName={PRACTICES_STAFF_MEMBERS}
                model={ADMIN_STAFF_PRACTICE_MODEL}
                fields={ADMIN_PRACTICE_MEMBERS_CREATE_FIELDS}
                defaultValues={{ practice: practiceId }}
                title="Add Member"
              />
              )}
          </AdminContainer>

          <AdminContainer title="Cycles">
            <AdminList
              permsModel={MODELS.PRACTICE}
              noSearch
              hidePagination
              pageSize={100}
              model={ADMIN_CYCLE_MODEL}
              defaultFilters={Map({ practice: practiceId })}
              fields={getAdminPracticeCycleRowFields((item) => this.props.generateCycleReport(item))}
            />
          </AdminContainer>

          <AdminContainer className="mt-2">
            <AdminManyRelatedField
              hideAddItem
              itemId={practiceId}
              controlName="PRACTICES_SERVICES"
              options={itemOptions}
              model={ADMIN_PRACTICE_MODEL}
              fieldName={['services']}
              fields={ADMIN_PRACTICE_SERVICES_FIELDS}
              listTitle="Services"
            />
          </AdminContainer>

          <AdminContainer className="mt-2">
            <AdminCreate
              permsModel={MODELS.PRACTICE}
              readOnly={readOnly}
              controlName="charges_form"
              collectionName={ADMIN_PRACTICE_MODEL}
              onComplete={reloadChangeLog('Practice', practiceId)}
              options={itemOptions}
              model={ADMIN_PRACTICE_MODEL}
              itemId={practiceId}
              fields={ADMIN_PRACTICE_CHARGES_FIELDS}
              title="Charges"
            />
          </AdminContainer>

          {!readOnly && (
            <IfCan capability={CAPABILITIES.UPDATE} model={MODELS.PRACTICE}>
              <AdminContainer className="mt-2">
                <h4>Options</h4>
                <DeactivatePractice practice={practice} model={ADMIN_PRACTICE_MODEL} collectionName={collectionName} />
                <RestorePolicyDocuments practice={practice} />
              </AdminContainer>
            </IfCan>
          )}
          <AdminContainer>
            <AdminChangeLog model="Practice" id={practiceId} />
          </AdminContainer>
        </AdminContainer>
      );

  }
}

export function mapStateToProps(state, props) {
  const practiceId = props.params.uuid;
  const itemOptions = state.items.getIn(['options', ADMIN_PRACTICE_OPTIONS], Map());
  const complianceDetailsOptions = state.items.getIn(['options', ADMIN_COMPLIANCE_DETAILS_OPTIONS], Map());
  const collectionName = practiceId;
  const practice = state.items.get(collectionName);

  return {
    practiceId,
    itemOptions,
    complianceDetailsOptions,
    optionsResponse: state.responses.getIn(['getItemOptions', ADMIN_PRACTICE_OPTIONS]),
    isEditing: !!practiceId,
    practice,
    collectionName,
  };
}

ManagePractice.propTypes = {
  params: React.PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getItemOptions, loadItem, generateCycleReport })(ManagePractice);
