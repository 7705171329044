import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router';
import { List } from 'immutable';

import { formatDateTime, FORMAT_DATE_TIME_ADMIN, parseImmutablePropertyString, promptBefore, getPlaceholderImage } from '^/utils';
import Img from '^/components/app/content/Img';
import UserCreatedMarkup from '^/components/common/content/UserCreatedMarkup';
import LiveButton from '^/components/app/forms/LiveButton';

export default function ItemField({item, field, removeItem}) {
  const {
    type, name, link_text, route, valueMap, noneMessage, handler, prompt, hideIf,
    liveButton, styleHandler, displayHandler,
   } = field.toObject();

   const value = name && item.getIn(name);

  if (hideIf && hideIf(item)) {
    return <span>--</span>;
  }

  switch (type) {
    case 'manyToMany':
      const subItems = item.get(name.get(0)) || List();
      return (!subItems || subItems.isEmpty()) ? (
        <span>{noneMessage || 'None'}</span>
      ) : (
        <div className="inline">
          {subItems.map((subItem, idx) => <div key={idx}>{subItem.getIn(name.rest())}</div>)}
        </div>
      );
    case 'boolean':
      return value ? <span>Y</span> : <span>N</span>;
    case 'detail':
      return (
        <span>
          <Link to={parseImmutablePropertyString(item, route)}>
            {displayHandler ? displayHandler(item) : link_text || (name && value)}
          </Link>
        </span>
      );
    case 'datetime':
      return <span>{formatDateTime(value, ' ', FORMAT_DATE_TIME_ADMIN) || '--'}</span>;
    case 'remove':
      return <span><a onClick={() => removeItem(value, name)}>Remove</a></span>;
    case 'function':
      const onClick = () => handler(item);
      return <span><a className={styleHandler ? styleHandler(item) : ''} onClick={prompt ? promptBefore(onClick) : onClick}>{link_text}</a></span>;
    case 'live_button':
      const { successResponse, keyedResponse, successText, text, disabled } = liveButton.toObject()
      const response = successResponse || keyedResponse && keyedResponse.get(item.get('id'))

      return (
        <span>
          <LiveButton
            className="btn btn-default"
            response={response}
            successContent={(
              <button className="btn btn-default" disabled>{successText}</button>
            )}
            onClick={() => handler(item)}
            disabled={disabled && disabled(item)}
          >
            {text}
          </LiveButton>
        </span>
      );
    case 'valuemap':
      return <span>{ valueMap.get(value, '--')}</span>;
    case 'image':
      return <Img className="image-field" circular cover src={value || getPlaceholderImage()} />;
    case 'html':
      // TODO: handle landscape className: config.isLandscape ? ' landscape' : ''
      return (
        <div className="row mt-1 document-bg">
          <div className="col-1">
            <div className="document document-content">
              <UserCreatedMarkup text={value} />
            </div>
          </div>
        </div>
      );
    case 'integer':
    case 'float':
      return <span>{value}</span>;
    case 'position':
      return <span>{value === -1 ? '--' : value}</span>;
    case 'string':
      return <span className={styleHandler ? styleHandler(item) : ''}>{value}</span>;
    default:
      return <span>{typeof value === 'undefined' || value === null ? '--' : value}</span>;
  }
}

ItemField.propTypes = {
  item: ImmutablePropTypes.map.isRequired,
  field: ImmutablePropTypes.map.isRequired,
  removeItem: React.PropTypes.func,
};
