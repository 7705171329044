import React from 'react';
import { reduxForm } from 'redux-form';

import LiveButton from '^/components/app/forms/LiveButton';

export const REASSIGN_RESPONSIBILITIES_FORM = 'REASSIGN_RESPONSIBILITIES_FORM';


export const ResponsibilityField = ({fields, responsibility, memberChoices}) => {
  const field = fields[responsibility.get('id')];

  return (
    <tr>
      <td>
        <label htmlFor={responsibility.get('id')}>
          {responsibility.get('name')}
        </label>
      </td>
      <td>
        <select id={field.name} {...field} >
          {memberChoices.map(each =>
              <option key={each.get('value')} value={each.get('value')}>
                {each.get('display')}
              </option>
          )}
        </select>
      </td>
    </tr>
  );
};

export const ReassignResponsibilitiesForm = ({fields, responsibilities, memberChoices, handleSubmit}) =>
  <form onSubmit={handleSubmit}>
    <div className="mt-1 small-text">
      {
        responsibilities.size > 0 ? (
        <table>
          <thead>
            <tr>
              <th>
                <strong>Responsibilities ({responsibilities.size})</strong>
              </th>
              <th>New Assignee</th>
            </tr>
          </thead>
          <tbody>
            {
              responsibilities.map(each =>
                <ResponsibilityField
                  key={each.get('id')}
                  memberChoices={memberChoices}
                  responsibility={each}
                  fields={fields}
                />
              )
            }
            </tbody>
          </table>
          ) : <p>No responsibilities to reassign.</p>
      }
      <LiveButton className="btn-default mt-1">
        Next...
      </LiveButton>
    </div>
  </form>;

export default reduxForm({ form: REASSIGN_RESPONSIBILITIES_FORM })(ReassignResponsibilitiesForm);
