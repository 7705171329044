import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from '^/actions/modals';
import { applyTemplateToLiveCyclesCloseModalAndCheckProgress } from '^/actions/actionSequences';
import LiveButton from '^/components/app/forms/LiveButton';

export function ApplyTemplateActivityToLiveCyclesModal ({ templateActivity, monthOverride, applyToLiveCycles, cancel, response }) {

  return (
    <div>

      <p>Are you sure you want to apply <strong>{templateActivity.get('name')}</strong> to all live cycles.</p>

      <p><strong>Warning: </strong> once started this cannot be reverted or stopped.</p>

      {monthOverride && <p><strong>Warning: Month Override is set! This will override the default month!</strong></p>}

      <p>Please check the success of this process as if it fails it will not be applied to any live cycles.</p>

      <div className="mt-1">
        <LiveButton
          className="btn btn-warning"
          response={response}
          onClick={() => applyToLiveCycles(templateActivity, monthOverride && {'month_override': monthOverride})}
        >
          Apply to Live Cycles
        </LiveButton>

        <button className="btn btn-default ml-1" onClick={() => cancel()}>Cancel</button>
      </div>

    </div>
  );
}

export const mapStateToProps = (state) => ({
  response: state.responses.get('applyTemplateActivityToLiveCycle'),
});

export default connect(
  mapStateToProps,
  {
    cancel: closeModal,
    applyToLiveCycles: applyTemplateToLiveCyclesCloseModalAndCheckProgress,
  }
)(ApplyTemplateActivityToLiveCyclesModal);
