import { connect } from 'react-redux';

import { loadLastDatabaseSync } from '^/actions/insights';
import { selectLastUpdatedTimestampByModel } from '^/selectors/insights';


const withLastUpdatedTimestampData = (modelName) => (Component) => {
  function mapStateToProps(state) {
    return {
      lastUpdatedTimestamp: selectLastUpdatedTimestampByModel(modelName)(state),
    };
  }

  return connect(mapStateToProps, { loadLastDatabaseSync })(Component);
};

export default withLastUpdatedTimestampData;
