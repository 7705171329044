import classNames from 'classnames';
import React, { Component, ChangeEvent } from 'react';
import { connect } from 'react-redux';

import { AuditStatus } from '^/components/app/digital-tools/audit/types';
import { updateAudit } from '^/actions/audit';
import NonFieldErrorRenderer from '^/components/common/NonFieldErrorRenderer';
import { selectAuditById } from '^/selectors/audit';
import { selectCurrentUserId } from '^/selectors/healthcheck';
import { StoreState } from '^/store/types';
import { isLoggedOnUserAdminOfCurrentPractice } from '^/stateHelpers';

interface OwnProps {
  auditId: string;
}

interface StateProps {
  auditReviewSummary: string | null;
  isLoading: boolean;
  errors: unknown;
  isAuditOwner: boolean;
  currentUserId: string;
  auditUserId: string;
  isAdmin: boolean;
}

interface DispatchProps {
  updateAudit: typeof updateAudit;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  isEditing: boolean;
  summaryText: string;
}

class Summary extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isEditing: false,
      summaryText: props.auditReviewSummary || '',
    };
  }

  public render() {
    if (this.props.isAuditOwner) {
      return (
        <div
          className={classNames('audit-review-summary mt-3', {
            ['print-hide']: !this.state.summaryText || this.state.isEditing,
          })}
        >
          <h2>Audit findings summary</h2>
          {!this.props.auditReviewSummary && (
            <p>Summarise your findings from the audit</p>
          )}
          {this.state.isEditing ? (
            <form onSubmit={this.onSubmit}>
              <textarea
                value={this.state.summaryText}
                onChange={this.onChangeSummary}
              />
              <button
                type="submit"
                className="btn btn-save mt-1"
                disabled={this.props.isLoading}
              >
                Save
              </button>
            </form>
          ) : (
            <div>
              <p className="mt-1">{this.props.auditReviewSummary}</p>
              <button
                className="btn btn-save mt-1"
                disabled={this.props.isLoading}
                onClick={this.onToggleEdit}
              >
                Edit
              </button>
            </div>
          )}
          {this.props.errors && (
            <NonFieldErrorRenderer errors={this.props.errors} />
          )}
        </div>
      );
    } else if (this.state.summaryText) {
      return (
        <div className="audit-review-summary mt-3">
          <h2>Audit findings summary</h2>
          <p className="mt-1">{this.props.auditReviewSummary}</p>
        </div>
      );
    }
    return null;
  }

  public onSubmit = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();
    this.props.updateAudit(this.props.auditId, {
      ...{
        review_summary: this.state.summaryText,
        status: AuditStatus.COMPLETED,
      },
      ...(this.props.isAdmin &&
        this.props.currentUserId !== '' &&
        this.props.currentUserId !== this.props.auditUserId && {
          taking_over_user_id: this.props.currentUserId,
        }),
    });
    this.setState({ isEditing: false });
  };

  private onToggleEdit = () =>
    this.setState({ isEditing: !this.state.isEditing });

  private onChangeSummary = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    this.setState({ summaryText: event.currentTarget.value });
  };
}

export { Summary as SummaryUnconnected };

const mapStateToProps = (state: StoreState, props: OwnProps) => {
  const audit = selectAuditById(state, props.auditId);
  const isAdmin = isLoggedOnUserAdminOfCurrentPractice(state);
  const currentUserId = selectCurrentUserId(state);

  return {
    auditReviewSummary: audit && audit.review_summary,
    isLoading:
      state.thunkRequests.updateAudit.isLoading ||
      state.thunkRequests.getAuditReviewScores.isLoading ||
      state.thunkRequests.getAuditReviewTasks.isLoading,
    errors: state.thunkRequests.updateAudit.errors,
    isAuditOwner: !!audit && (audit.user.id === currentUserId || isAdmin),
    currentUserId: currentUserId ? currentUserId : '',
    auditUserId: audit ? audit.user.id : '',
    isAdmin: isAdmin,
  };
};

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, {
  updateAudit,
})(Summary);
