import React from 'react';


const EmptyListPage = ({ reassignType, handleSubmit }) =>
 <div className="mb-1">
    No {reassignType} to reassign.

    <div className="mt-1 small-text">
      <button
        className="btn btn-default mt-1"
        onClick={() => handleSubmit()}
      >
        Next...
      </button>
    </div>
  </div>;

EmptyListPage.propTypes = {
  handleSubmit: React.PropTypes.func.isRequired,
  reassignType: React.PropTypes.string.isRequired,
};

export default EmptyListPage;
