import React from 'react';
import { connect } from 'react-redux';
import { appInit } from '^/actions/actions';
import PureComponent from '^/components/common/PureComponent';
import Modal from '^/components/app/Modal';
import Loading from '^/components/app/content/Loading';
import { isLoggedIn } from '^/utils';

export class App extends PureComponent {
  componentWillMount() {
    this.props.appInit();
  }

  render() {
    const { user, children } = this.props;
    const isLoading = !children || this.props.isLoggedIn && !user;

    return !isLoading ? (
        <div>
          <Modal />
          {children}
        </div>
      ) : (
        <div className="container loading-app">
          <div className="no-auth">
            <div>
              {/*Logo here*/}
            </div>

            <div>
              <Loading />
            </div>
          </div>
        </div>
      );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userProfile && !state.userProfile.isEmpty() ? state.userProfile : null,
    isLoggedIn: isLoggedIn(state),
  };
}

export default connect(
  mapStateToProps,
  { appInit }
) (App);
