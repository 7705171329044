import React from 'react';
import PureComponent from '^/components/common/PureComponent';

export default class Main extends PureComponent {
  render() {
    return (
      <div className="wrapper mb-2">
        <div className="row">
          {this.props.children}
        </div>
      </div>
    );
  }
}
