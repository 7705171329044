import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import PureComponent from '^/components/common/PureComponent';


export default class FormError extends PureComponent {

  _renderError(error) {
    const { renderError } = this.props;
    return renderError ? renderError(error) : error;
  }

  getFormKey() {
    const { formKey, formKeyPath } = this.props;
    return ['errors'].concat(formKeyPath || (formKey ? [formKey] : []));
  }

  render() {
    const { error, response } = this.props;

    const responseErrors = response && response.get('state') && response.getIn(this.getFormKey());

    if (!error && !responseErrors) {
      return null;
    }

    return (
      <div className="form-error text-error">
        {this._renderError(error || responseErrors)}
      </div>
    );
  }

}

FormError.propTypes = {
  response: ImmutablePropTypes.map,
  formKey: React.PropTypes.string,
  formKeyPath: React.PropTypes.array,
  error: React.PropTypes.any,
  renderError: React.PropTypes.func,
};
