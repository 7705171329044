import { List, Map, Set } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import {
  collapseAllUiComponents,
  expandAllUiComponents,
} from '^/actions/actions';
import { getAllCollection } from '^/actions/collections';
import { loadInsightData } from '^/actions/insights';
import Loading from '^/components/app/content/Loading';
import {
  FILTER_KEYS,
  INSIGHTS,
  INSIGHTS_API_URLS,
} from '^/components/app/groups/insights/constants';
import { ConnectedSubGroupFilter as SubGroupFilter } from '^/components/app/groups/insights/filters/SubGroupFilter';
import { getSubGroupFilterFromPath } from '^/components/app/groups/insights/utils';
import DateRangeFilter from '^/components/app/groups/reports/filters/DateRangeFilter';
import {
  categoryOptions,
  categoryTypes,
  typeCategories,
  typeOptions,
  userLevelOptions,
} from '^/components/app/groups/reports/constants';
import FilterSelect from '^/components/app/groups/reports/filters/FilterSelect';
import DateGroupedEvents from '^/components/app/groups/reports/DateGroupedEvents';
import PureComponent from '^/components/common/PureComponent';
import { GROUP_PRACTICES } from '^/consts/collectionKeys';
import { getMomentUtcStartOfLastWeek, getMomentUtcToday } from '^/utils';
import { selectInsightData } from '^/selectors/insights';
import { hasFailed, hasSucceeded, isPending } from '^/consts/responseStates';

const INSIGHT = INSIGHTS.PRACTICE_INTERACTION;

function ExpandableSections({
  isLoading,
  data,
  hasFailedToLoad,
  filtersApplied,
  errorMessage,
}) {
  if (isLoading) {
    return <Loading />;
  }

  if (hasFailedToLoad) {
    return (
      <p>
        {errorMessage ||
          'Too many events to display. Please narrow your date range or apply more filters.'}
      </p>
    );
  }

  if (!filtersApplied) {
    return <p>Please select your filters and click Go.</p>;
  }

  if (!data || data.size === 0) {
    return <p>No data found for these dates.</p>;
  }

  return <DateGroupedEvents data={data} />;
}

export class PracticeInteraction extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilters: Map({
        [FILTER_KEYS.DATE_FROM]: getMomentUtcStartOfLastWeek(),
        [FILTER_KEYS.DATE_TO]: getMomentUtcToday(),
        [FILTER_KEYS.SUB_GROUP]: getSubGroupFilterFromPath()
      }),
      filtersApplied: props.filtersApplied || false,
    };

    this.handleSubGroupFilterChange = this.handleSubGroupFilterChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.toggleCollapseAll = this.toggleCollapseAll.bind(this);
  }

  componentDidMount() {
    this.props.getAllCollection(GROUP_PRACTICES);
  }

  handleSubGroupFilterChange(value) {
    this.handleFilterChange(FILTER_KEYS.SUB_GROUP, value);
  }

  handleFilterChange(filterKey, value) {
    this.setState(prevState => ({
      selectedFilters: prevState.selectedFilters.set(filterKey, value),
    }));
  }

  applyFilters() {
    const { selectedFilters } = this.state;

    this.setState({ filtersApplied: true });

    this.props.loadInsightData(
      INSIGHT,
      INSIGHTS_API_URLS.PRACTICE_INTERACTION,
      selectedFilters
    );
  }

  toggleCollapseAll() {
    const { isAnyExpanded, data } = this.props;
    if (isAnyExpanded) {
      this.props.collapseAllUiComponents();
    } else if (data) {
      const componentIds = data.map(item =>
        item.get('date_practice_type_user')
      );
      this.props.expandAllUiComponents(componentIds);
    }
  }

  render() {
    const {
      data,
      practiceOptions,
      isLoading,
      hasFailedToLoad,
      isAnyExpanded,
      errorMessage,
      location: { pathname, query },
    } = this.props;

    const { selectedFilters, filtersApplied } = this.state;

    // Disable type and category options that are not valid given the current selection
    const selectedEventType = selectedFilters.get(FILTER_KEYS.TYPE);
    const selectedEventCategory = selectedFilters.get(FILTER_KEYS.CATEGORY);
    const activeCategoryOptions = categoryOptions.map(option => ({
      ...option,
      disabled:
        selectedEventType &&
        typeCategories[selectedEventType].indexOf(option.value) === -1,
    }));
    const activeTypeOptions = typeOptions.map(option => ({
      ...option,
      disabled:
        selectedEventCategory &&
        categoryTypes[selectedEventCategory].indexOf(option.value) === -1,
    }));

    return (
      <div className="pl-1 pr-1 pt-1 pb-2">
        {/* TODO - page title and link back to reports dashboard missing */}
        <div className="mb-2 row">
          <div className="col-12-12 pt-1">
            <SubGroupFilter
              onChange={this.handleSubGroupFilterChange}
              pathname={pathname}
              selectedSubGroup={query.sub_group}
              isDisabled={isLoading}
            />
          </div>
          <div className="col-2-12 pt-1">
            <FilterSelect
              label="Practice"
              options={practiceOptions ? practiceOptions.toJS() : []}
              filterKey={FILTER_KEYS.PRACTICE}
              value={selectedFilters.get(FILTER_KEYS.PRACTICE)}
              onFilterChange={this.handleFilterChange}
              isDisabled={isLoading}
            />
          </div>
          <div className="col-2-12 pt-1">
            <FilterSelect
              label="User level"
              options={userLevelOptions}
              filterKey={FILTER_KEYS.USER_LEVEL}
              value={selectedFilters.get(FILTER_KEYS.USER_LEVEL)}
              onFilterChange={this.handleFilterChange}
              isDisabled={isLoading}
            />
          </div>
          <div className="col-2-12 pt-1">
            <FilterSelect
              label="Event category"
              options={activeCategoryOptions}
              filterKey={FILTER_KEYS.CATEGORY}
              value={selectedFilters.get(FILTER_KEYS.CATEGORY)}
              onFilterChange={this.handleFilterChange}
              isDisabled={isLoading}
            />
          </div>
          <div className="col-2-12 pt-1">
            <FilterSelect
              label="Event type"
              options={activeTypeOptions}
              filterKey={FILTER_KEYS.TYPE}
              value={selectedFilters.get(FILTER_KEYS.TYPE)}
              onFilterChange={this.handleFilterChange}
              isDisabled={isLoading}
            />
          </div>
          <div className="col-3-12 pt-1">
            <label className="display-block mb-1-4 light-text">&nbsp;</label>
            <DateRangeFilter
              dateFrom={selectedFilters?.get(FILTER_KEYS.DATE_FROM) || ''}
              dateTo={selectedFilters?.get(FILTER_KEYS.DATE_TO) || ''}
              onFilterChange={this.handleFilterChange}
              isDisabled={isLoading}
            />
          </div>
          <div className="col-1-12 pt-1">
            <label className="display-block mb-1-4 light-text">&nbsp;</label>
            <button
              className="btn btn-primary"
              onClick={this.applyFilters}
              disabled={isLoading}
            >
              Go
            </button>
          </div>
          <div className="pull-right pt-2 pl-1 pr-1">
            <button
              className={classNames(
                'no-border',
                'collapse-button',
                'focus-outline-none',
                'underlined',
                'small-text',
                !isAnyExpanded && 'collapsed'
              )}
              onClick={this.toggleCollapseAll}
            >
              {isAnyExpanded ? 'Collapse all' : 'Expand all'}
            </button>
          </div>
        </div>
        <ExpandableSections
          isLoading={isLoading}
          data={data}
          hasFailedToLoad={hasFailedToLoad}
          filtersApplied={filtersApplied}
          errorMessage={errorMessage}
        />
      </div>
    );
  }
}

const getOptionsFromList = list =>
  list.map(item => ({
    value: item.get('id'),
    label: item.get('name'),
  }));

export function mapStateToProps(state) {
  const response = state.responses.getIn(['loadInsightData', INSIGHT]);

  return {
    data: selectInsightData(INSIGHT)(state),
    isLoading: isPending(response),
    hasFailedToLoad: hasFailed(response),
    hasLoaded: hasSucceeded(response),
    errorMessage: hasFailed(response) ? response.getIn(['errors', 0]) : null,
    practiceOptions: getOptionsFromList(
      state.collections.getIn([GROUP_PRACTICES, 'items'], List())
    ),
    isAnyExpanded: !state.ui.get('expandedUiComponents', Set()).isEmpty(),
  };
}

export default connect(mapStateToProps, {
  getAllCollection,
  collapseAllUiComponents,
  expandAllUiComponents,
  loadInsightData,
})(PracticeInteraction);
