import React, { PropTypes } from 'react';
import { reduxForm } from 'redux-form';

import { mergeFieldChange } from '^/models/practice';
import { hasFailed } from '^/consts/responseStates';

import PureComponent from '^/components/common/PureComponent';
import FormError from '^/components/app/forms/FormError';
import Icon from '^/components/app/content/Icon';
import PracticeComplianceDetailsForm, { getComplianceDetailsFields } from '^/components/app/practices/forms/PracticeComplianceDetailsForm';


export const PRACTICE_DETAILS_VIEW_FORM = 'PRACTICE_DETAILS_VIEW_FORM';

export function getPracticeDetailsFields(practice) {
  return ['has_dental_hygienist', 'has_dental_plan', 'dental_plan_provider'].concat(getComplianceDetailsFields(practice).map(fieldName => `compliance_details.${fieldName}`));
}

export class PracticeDetailsViewForm extends PureComponent {

  componentWillUpdate(nextProps) {
    if (this.props.response !== nextProps.response && hasFailed(nextProps.response)) {
      this.props.resetForm(PRACTICE_DETAILS_VIEW_FORM);
    }
  }

  onFieldChange(field, event) {
    mergeFieldChange(this.props.valuesChanged, field, field.name, event);
  }

  onEnter(field, event) {
    if (event.keyCode === 13 || event.keyCode === '13') {
      this.refs[field.name].blur();
      this.onFieldChange(field, event);
    }
  }

  isPendingField(field) {
    const { pendingFields } = this.props;
    return pendingFields && pendingFields.has(field.name);
  }

  render () {
    const { fields, practice, handleSubmit, valuesChanged, pendingFields, response } = this.props;
    const { has_dental_hygienist, has_dental_plan, dental_plan_provider } = fields;

    return (
      <form onSubmit={handleSubmit}>

        <div className="help-block mb-1">
          <Icon type="lightbulb-o" className="tip-icon" />
          Keep these questions up to date to help us select the right compliance reviews for your practice.
        </div>

        <PracticeComplianceDetailsForm
          valuesChanged={values => valuesChanged({compliance_details: values})}
          nestedFormPath={['compliance_details']}
          response={response}
          fields={fields.compliance_details}
          practice={practice}
          pendingFields={pendingFields}
        />

        <h4>General questions about your practice</h4>

        <div className="form-group">
          <div className="col-2-3 label-left">
            <label>Do you have a dental hygienist?</label>
          </div>
          <div className="col-1-3">
            <label className="label-inline">
              <input
                type="radio"
                onChange={e => this.onFieldChange(has_dental_hygienist, e)}
                disabled={this.isPendingField(has_dental_hygienist)}
                value
                checked={has_dental_hygienist.value === 'true'}
              /> Yes
            </label>
            <label className="label-inline">
              <input
                type="radio"
                onChange={e => this.onFieldChange(has_dental_hygienist, e)}
                disabled={this.isPendingField(has_dental_hygienist)}
                value={false}
                checked={has_dental_hygienist.value !== 'true'}
              /> No
            </label>

          </div>

          <FormError response={response} key="has_dental_hygienist" />

        </div>

        <div className="form-group">
          <div className="col-2-3 label-left">
            <label>Do you have a dental plan? (If so, who is your provider?)</label>
          </div>
          <div className="col-1-3">
            <label className="label-inline">
              <input
                type="radio"
                onChange={e => this.onFieldChange(has_dental_plan, e)}
                disabled={this.isPendingField(has_dental_plan)}
                value
                checked={has_dental_plan.value === 'true'}
              /> Yes
            </label>
            <label className="label-inline">
              <input
                type="radio"
                onChange={e => this.onFieldChange(has_dental_plan, e)}
                disabled={this.isPendingField(has_dental_plan)}
                value={false}
                checked={has_dental_plan.value !== 'true'}
              /> No
            </label>

            {has_dental_plan.value === 'true' &&
              <div>
                <label>
                  Provider:
                  <input
                    type="text"
                    ref={dental_plan_provider.name}
                    value={dental_plan_provider.value}
                    disabled={this.isPendingField(dental_plan_provider)}
                    onChange={dental_plan_provider.onChange}
                    onKeyDown={e => this.onEnter(dental_plan_provider, e)}
                    onBlur={e => this.onFieldChange(dental_plan_provider, e)}
                  />
                </label>

                <FormError response={response} key="dental_plan_provider" />

              </div>}

          </div>

          <FormError response={response} key="has_dental_plan" />

        </div>

      </form>
    );
  }
}

PracticeDetailsViewForm.propTypes = {
  fields: PropTypes.object.isRequired,
  valuesChanged: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired
};

export default reduxForm({
  form: PRACTICE_DETAILS_VIEW_FORM,
})(PracticeDetailsViewForm);
