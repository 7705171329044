import React from 'react';
import { Map } from 'immutable';

import DetailsCard from '^/components/app/groups/insights/DetailsCard';
import {
  groupDocumentsInsightsConfig,
  INSIGHTS,
  INSIGHTS_API_URLS,
  GROUP_DOCUMENTS_ADOPTED_THRESHOLDS,
  DATABASE_MODEL_NAME,
  FILTER_KEYS,
} from '^/components/app/groups/insights/constants';
import { convertEndDateToPeriod, getSubGroupFilterFromPath } from '^/components/app/groups/insights/utils';
import withInsight from '^/components/app/groups/insights/hoc/withInsight';
import withSelectedFilters from '^/components/app/groups/insights/hoc/withSelectedFilters';
import withLastUpdatedTimestamp from '^/components/app/groups/insights/hoc/withLastUpdatedTimestamp';
import BatteryBar from '^/components/app/groups/insights/visualisations/BatteryBar';
import { convertRateToPercentage, formatDateTime } from '^/utils';
import DateFilter from '^/components/app/groups/insights/filters/DateFilter';

const INSIGHT = INSIGHTS.GROUP_DOCUMENTS_ADOPTED;

const GroupDocumentsDetails = ({data, hasFailedToLoad, dateTo, lastUpdatedTimestamp}) => {
  return (
    <DetailsCard>
      <h3>
        Group document adoption
        <DateFilter
          isClearable
          className="pl-1"
          placeholderText="Choose end date"
          filterKey={FILTER_KEYS.DATE_TO}
          insightsConfig={groupDocumentsInsightsConfig}
        />
      </h3>
      { lastUpdatedTimestamp &&
        <p className="insights-timestamp pb-1">Updated: {formatDateTime(lastUpdatedTimestamp)}</p>
      }
      <BatteryBar
        rate={data}
        height={30}
        borderRadius={4}
        thresholdBad={GROUP_DOCUMENTS_ADOPTED_THRESHOLDS.BAD}
        thresholdOk={GROUP_DOCUMENTS_ADOPTED_THRESHOLDS.OK}
      />
      { hasFailedToLoad ?
        <p className="pt-1 text-error">Failed to load</p> :
        <p className="pt-1">
          <span className="bold-text">
            {data ? `${convertRateToPercentage(data)}%` : '--'}
          </span>
          {' '}of group docs adopted by practices ({convertEndDateToPeriod(dateTo)})
        </p>
      }
    </DetailsCard>
  );
};

export { GroupDocumentsDetails };

const GroupDocumentsDetailsWithLastUpdatedTimestamp = withLastUpdatedTimestamp(
  DATABASE_MODEL_NAME.DOCUMENT
)(GroupDocumentsDetails);

const GroupDocumentsDetailsWithLastUpdatedTimestampAndSelectedFilters = withSelectedFilters(
  INSIGHT
)(GroupDocumentsDetailsWithLastUpdatedTimestamp);

export default withInsight({
  insight: INSIGHT,
  url: INSIGHTS_API_URLS.GROUP_DOCUMENTS_ADOPTED,
  getFilters: () => (
    Map({
      practice_sub_group_id: getSubGroupFilterFromPath(),
    })
  )
})(GroupDocumentsDetailsWithLastUpdatedTimestampAndSelectedFilters);
