import React from 'react';

import NewsStoriesPage from '^/components/app/news/NewsStoriesPage';

const GroupNewsStoriesPage = ({ params, pathname, location }) => {
  return (
    <NewsStoriesPage
      isGroupNewsTriagePage
      params={params}
      pathname={pathname}
      location={location}
    />
  );
};

export default GroupNewsStoriesPage;
