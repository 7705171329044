import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import { isPending } from '^/consts/responseStates';

export default class BlankOption extends PureComponent {
  render() {
    return (
      <option value="" disabled={this.props.disabled}>
        {isPending(this.props.response) ? 'Loading...' : 'Please select...'}
      </option>
    );
  }
}
