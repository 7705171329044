import React from 'react';
import { CAPABILITIES } from '^/permissions/capabilities';
import IfCan from '^/components/common/IfCan';
import IfCant from '^/components/common/IfCant';
import AdminList from '^/components/admin/content/list/AdminList';
import { hideRemove } from '^/permissions/adminHelpers';

export default function _AdminList(props) {
  return (
    <span>
      <IfCan capability={CAPABILITIES.DELETE} model={props.permsModel}>
        <AdminList {...props} />
      </IfCan>
      <IfCant capability={CAPABILITIES.DELETE} model={props.permsModel}>
        <AdminList
          {...props}
          fields={hideRemove(props.fields)}
        />
      </IfCant>
    </span>
  );
}
