import React from 'react';
import classNames from 'classnames';

const SortIcon = ({
  itemKey,
  isSorted,
  isAscending,
  onClick,
  className,
}) => (
  <span
    className={classNames('sort-icon', className, {sorted: isSorted, reversed: !isAscending})}
    onClick={() => onClick(itemKey, isSorted ? !isAscending : undefined)}
  />
);

SortIcon.propTypes = {
  className: React.PropTypes.string,
  itemKey: React.PropTypes.string.isRequired,
  isSorted: React.PropTypes.bool.isRequired,
  isAscending: React.PropTypes.bool,
  onClick: React.PropTypes.func.isRequired
};

export default SortIcon;
