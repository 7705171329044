import React from 'react';
import { connect } from 'react-redux';

import ImmutablePropTypes from 'react-immutable-proptypes';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';

import { closeModal } from '^/actions/modals';
import { loadItem } from '^/actions/items';
import { archiveGroupTask } from '^/actions/actionSequences';
import { GROUP_TASKS } from '^/consts/collectionKeys';
import { isPending, hasSucceeded, hasFailed } from '^/consts/responseStates';
import { resetResponse } from '^/actions/actions';
import { pollInProgress, pollFailed } from '^/models/export';

export class ArchiveGroupTasksModal extends PureComponent {
  componentDidMount() {
    const { groupTaskId } = this.props;

    if (groupTaskId) {
      this.props.loadItem(GROUP_TASKS, groupTaskId);
    }

    this.props.resetResponse('pollUpdateGroupTask');
  }

  componentWillUnmount() {
    window.clearTimeout(this.props.pollTimeout);
  }

  render() {
    const { title, onClose, id, onArchive, pollResponse, archiveResponse } = this.props;

    if (isPending(archiveResponse) || isPending(pollResponse) || pollInProgress(pollResponse)) {
      return (
        <div>
          <p>Archiving in progress... please wait!</p>
          <Loading />
        </div>
      );
    }

    if (hasSucceeded(archiveResponse)) {
      return (
        <p>Archiving is done!</p>
      );
    }

    if (hasFailed(archiveResponse) || pollFailed(pollResponse)) {
      return (
        <div>
          <div className="row">
            <p className="col-1 text-center mb-1">
              Something has gone wrong with the archiving task (perhaps an archive/update job is already in progress).
            </p>
            <p className="col-1 text-center mb-1">Please close the modal and try again later.</p>
          </div>
          <div className="row align-right mt-1">
            <div className="col-1">
              <button className="btn btn-default" onClick={() => onClose()}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="row">
          <p className="col-1 text-center mb-1">
              Are you sure you wish to archive this group task: {title}?
          </p>
          <p className="col-1 text-center mb-1">
            Archiving will remove this task from all practices within the group. 
            This will only affect Tasks from the last completed Task onwards.
          </p>
          <p className="col-1 text-center mb-1">Choose 'Archive' to remove the group task or 'Cancel' to go back.</p>
        </div>
        <div className="row align-right mt-1">
          <div className="col-1">
            <button className="btn btn-default" onClick={() => onClose()}>
              Cancel
            </button>
            <button
              className="btn btn-warning ml-1"
              onClick={() => onArchive(GROUP_TASKS, id, {is_archived: true})}
            >
              Archive
            </button>
          </div>
        </div>
      </div>
    )
  };
};

ArchiveGroupTasksModal.propTypes = {
  id: React.PropTypes.string.isRequired,
  response: ImmutablePropTypes.map,
  pollTimeout: React.PropTypes.number,
  groupTaskId: React.PropTypes.string.isRequired,
  title: React.PropTypes.string.isRequired,
  onArchive: React.PropTypes.func.isRequired,
  onClose: React.PropTypes.func.isRequired,
  resetResponse: React.PropTypes.func.isRequired,
  loadItem: React.PropTypes.func.isRequired,
}

export const mapStateToProps = (state, props) => {
  const groupTaskUpdateId = state.items.getIn([GROUP_TASKS, 'group_task_update_job_id']);

  return {
    groupTaskId: props.id,
    pollResponse: state.responses.getIn(['pollUpdateGroupTask', groupTaskUpdateId]),
    pollTimeout: state.pollUpdateGroupTaskTimeouts.get(groupTaskUpdateId),
    archiveResponse: state.responses.getIn(['updateItem', GROUP_TASKS]),
  }
};

export const mapDispatchToProps = (dispatch) => ({
  resetResponse: (type) => dispatch(resetResponse(type)),
  onArchive: (type, groupTaskId, data) => dispatch(archiveGroupTask(type, groupTaskId, data)),
  loadItem: (type, id) => dispatch(loadItem(type, id)),
  onClose: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps) (ArchiveGroupTasksModal);
