import { fromJS } from 'immutable';


export const ADMIN_RESPONSIBILITY_CREATE = 'ADMIN_RESPONSIBILITY_CREATE';
export const ADMIN_RESPONSIBILITY_MODEL = 'admin/responsibilities';
const ADMIN_RESPONSIBILITY_MANAGE_ROUTE = '/admin/responsibilities/${id}/';

export const ADMIN_RESPONSIBILITY_EDIT_FIELDS = fromJS([
  'sector', 'name', 'is_optional', 'position', 'allow_override', 'help_text',
]);

export const ADMIN_RESPONSIBILITY_ROW_FIELDS = fromJS([
  {name: ['position'], display_name: 'Position', type: 'position', sortable: true},
  {name: ['name'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['sector'], display_name: 'Sector', type: 'string', sortable: true},
  {name: ['is_optional'], display_name: 'Optional', type: 'boolean', sortable: true},
  {name: ['allow_override'], display_name: 'Allow override text', type: 'boolean', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Edit', route: ADMIN_RESPONSIBILITY_MANAGE_ROUTE}
]);
