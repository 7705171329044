import React from 'react';
import { connect } from 'react-redux';
import PureComponent from '^/components/common/PureComponent';

import { loadItem } from '^/actions/items';
import Loading from '^/components/app/content/Loading';
import AdminChangeLog, { reloadChangeLog } from '^/components/admin/content/change-log/AdminChangeLog';
import { CAPABILITIES, MODELS } from '^/permissions/capabilities';

import { isAdminType } from '^/models/user';
import {
  setUserLockAndReloadUser,
} from '^/actions/actionSequences';
import {
  ADMIN_USER,
  ACCOUNT_LOGINS,
  ACCOUNT_LOGINS_FIELDS,
  ADMIN_ACCOUNT_ADMIN_USER_DETAILS_FIELDS,
  ADMIN_ACCOUNT_DETAILS_VIEW_FIELDS,
} from '^/components/admin/users/user';

import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import AdminDetail from '^/components/admin/content/detail/AdminDetail';
import LockUser from '^/components/admin/users/end-users/LockUser';
import IfCan from '^/components/common/IfCan';

function transform(fieldConfig, fields) {
  const { type, } = fields;
  return isAdminType(type && (type.value || type.initialValue))
    ? fieldConfig
    : fieldConfig.filterNot((config, fieldName) => fieldName === 'admin_role');
}

class AdminUserAccountEditAdmin extends PureComponent {

  componentWillMount() {
    const { userId } = this.props;
    this.props.loadItem(ADMIN_USER, userId);
  }

  render() {
    const { user, userId, lockResponse, } = this.props;

    if (!user) {
      return <Loading />;
    }

    return (
      <AdminContainer>
        <AdminContainer className="admin-edit-create-form" title={'Account Details: ' + user.get('email')}>
            <AdminEdit
              className="admin-form-no-border"
              permsModel={MODELS.USER}
              readOnlyFields={ADMIN_ACCOUNT_DETAILS_VIEW_FIELDS}
              model={ADMIN_USER}
              itemId={userId}
              onComplete={reloadChangeLog('User', userId)}
              fields={ADMIN_ACCOUNT_ADMIN_USER_DETAILS_FIELDS}
              transform={transform}
          />
        </AdminContainer>

        <IfCan capability={CAPABILITIES.UPDATE} model={MODELS.USER}>
          <div className="mt-1">
            <LockUser
              isLocked={user.get('is_locked')}
              setLock={(lockUser) => this.props.setUserLockAndReloadUser(userId, lockUser, ADMIN_USER)}
              response={lockResponse}
            />
          </div>
        </IfCan>

        <div className="mt-1">
          <AdminDetail
            item={user}
            detailName={ACCOUNT_LOGINS}
            fields={ACCOUNT_LOGINS_FIELDS}
            title="Set user to logged out"
          />
        </div>

        <AdminContainer>
          <AdminChangeLog model="User" id={userId} />
        </AdminContainer>
      </AdminContainer>
    );
  }
}

function mapStateToProps(state, props) {
  const userId = props.params.uuid;
  return {
    user: state.items.get(ADMIN_USER),
    userId,
    lockResponse: state.responses.get('setUserLock'),
  };
}

export default connect(
  mapStateToProps,
  {
    loadItem,
    setUserLockAndReloadUser,
  }
)(AdminUserAccountEditAdmin);
