import { fromJS, Map } from 'immutable';
import {
  removeCloseButtonWhilePolling,
  addCloseButtonAfterPolling,
  closeModal,
} from '^/actions/modals';
import * as actions from '^/actions/actions';
import { pollComplete, pollFailed, pollInProgress } from '^/models/export';
import { loadItem } from '^/actions/items';
import { getCollection, getAllCollection } from '^/actions/collections';
import settings from '^/consts/settings';
import {
  GROUP_TASKS,
  LIVE_CYCLES_GROUP_PRACTICES_UPDATE,
} from '^/consts/collectionKeys';

function pollGroupTaskUpdateAgainAfterTimeout(
  store,
  groupTaskUpdateId,
  groupTaskId
) {
  return store.dispatch(
    actions.storeUpdateGroupTaskPollTimeout(
      groupTaskUpdateId,
      window.setTimeout(
        () =>
          store.dispatch(
            actions.pollUpdateGroupTask(groupTaskUpdateId, groupTaskId)
          ),
        settings.POLL_INTERVAL
      )
    )
  );
}

export default store => next => action => {
  const state = store.getState();
  const groupTasksCollection = state.collections.get(GROUP_TASKS);
  const groupTasksFilters = groupTasksCollection
    ? Map(groupTasksCollection.get('filters'))
    : Map();

  switch (action.type) {
    case actions.POLL_UPDATE_GROUP_TASK.REQUEST:
      store.dispatch(removeCloseButtonWhilePolling());
      break;
    case actions.POLL_UPDATE_GROUP_TASK.SUCCESS: {
      const immutableAction = fromJS(action);
      const groupTaskUpdateId = immutableAction.getIn(['meta', 'source']);
      let groupTaskId = immutableAction.getIn(['meta', 'groupTaskId']);
      if (pollInProgress(immutableAction)) {
        pollGroupTaskUpdateAgainAfterTimeout(
          store,
          groupTaskUpdateId,
          groupTaskId
        );
      } else if (pollComplete(immutableAction)) {
        let create = false;
        if (groupTaskId === null) {
          create = true;
          groupTaskId = immutableAction.getIn(['payload', 'group_task']);
        }

        store.dispatch(loadItem(GROUP_TASKS, groupTaskId));
        if (create) {
          store.dispatch(getAllCollection(LIVE_CYCLES_GROUP_PRACTICES_UPDATE));
        } else {
          store.dispatch(
            getAllCollection(
              `${LIVE_CYCLES_GROUP_PRACTICES_UPDATE}/${groupTaskId}`,
              {},
              LIVE_CYCLES_GROUP_PRACTICES_UPDATE
            )
          );
        }
        store.dispatch(
          getCollection(GROUP_TASKS, { filters: groupTasksFilters.toJS() })
        );
        store.dispatch(addCloseButtonAfterPolling());
        store.dispatch(actions.clearAllExportTimeouts());
        if (create === true) {
          store.dispatch(closeModal());
        }
      } else if (pollFailed(immutableAction)) {
        store.dispatch(
          getCollection(GROUP_TASKS, { filters: groupTasksFilters.toJS() })
        );
        store.dispatch(addCloseButtonAfterPolling());
        store.dispatch(actions.clearAllExportTimeouts());
      }
      break;
    }
  }

  return next(action);
};
