import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import PureComponent from '^/components/common/PureComponent';
import LiveButton from '^/components/app/forms/LiveButton';
import PictogramCheckboxGroup from '^/components/app/digital-tools/records/digital-records/forms/PictogramCheckboxGroup';

import { hasSucceeded } from '^/consts/responseStates';
import { uploadFile } from '^/actions/actionSequences';
import { setCollapse } from '^/actions/actions';
import {
  COSHH_SAFETY_PICTOGRAM_CHOICES,
  renderTextAreaField,
  renderTextField,
  renderRelatedTasksField,
  renderMultipleFileUploadField,
} from '^/components/app/digital-tools/records/digital-records/forms/FormFields';
import { extraInfo } from '^/components/app/digital-tools/records/digital-records/forms/CoshHoverInfo';


export const COSHH_FORM = 'COSHH_FORM';

export class CoshhForm extends PureComponent {
  render() {
    const {
      handleSubmit,
      fields,
      response,
      isEditing,
      isArchived,
      staff_tasks,
      handleArchive,
      archiveDigitalRecordResponse,
      updateDigitalRecordResponse,
      createDigitalRecordResponse,
      uploadFileResponse,
    } = this.props;

    const {
      type,
      product_name,
      used_by,
      used_for,
      location,
      amount_used,
      usage_frequency,
      usage_conditions,
      signal_word,
      hazard_statement,
      risks_to_health,
      precautionary_statement,
      safety_measures,
      ppe_required,
      first_aid_proceedures,
      emergency_proceedures,
      special_storage,
      disposal_measures,
      health_monitoring,
      additional_info,
      related_files,
      safety_pictograms,
      notes,
    } = fields;

    return (
      <form onSubmit={handleSubmit}>
        <div className="mt-2">
            <div className="mt-2">
              {renderTextField(type, "Product Type:*", response, isArchived, extraInfo.productType)}
              {renderTextField(product_name, "Product Name:", response, isArchived, extraInfo.productName)}
              {renderTextField(used_by, "Used by:", response, isArchived, extraInfo.usedBy)}
              {renderTextField(used_for, "Used for:", response, isArchived, extraInfo.usedFor)}
              {renderTextField(location, "Location:", response, isArchived, extraInfo.location)}
              {renderTextField(amount_used, "How much is used:", response, isArchived, extraInfo.amountUsed)}
              {renderTextField(usage_frequency, "How often is it used:", response, isArchived, extraInfo.usageFrequency)}
              {renderTextField(usage_conditions, "How is it used:", response, isArchived, extraInfo.usageConditions)}
              {renderTextField(signal_word, "Signal word:", response, isArchived, extraInfo.signalWord)}
              {renderTextField(hazard_statement, "Hazard Statement(s):", response, isArchived, extraInfo.hazardStatement)}
              {renderTextField(risks_to_health, "Risks to health:", response, isArchived, extraInfo.risksToHealth)}
              {renderTextField(precautionary_statement, "Precautionary statements(s):", response, isArchived, extraInfo.precautionaryStatement)}
              {renderTextField(safety_measures, "Safety measures:", response, isArchived, extraInfo.safetyMeasures)}
              {renderTextField(ppe_required, "PPE Required:", response, isArchived, extraInfo.ppeRequired)}
              {renderTextField(first_aid_proceedures, "First Aid Procedures:", response, isArchived, extraInfo.firstAidProceedures)}
              {renderTextField(emergency_proceedures, "Emergency Procedures:", response, isArchived, extraInfo.emergencyProceedures)}
              {renderTextField(special_storage, "Special storage:", response, isArchived, extraInfo.specialStorage)}
              {renderTextField(disposal_measures, "Special disposal measures:", response, isArchived, extraInfo.disposalMeasures)}
              {renderTextField(health_monitoring, "Health surveillance / monitoring needed:", response, isArchived, extraInfo.healthMonitoring)}
              {renderTextField(additional_info, "Additional information:", response, isArchived, extraInfo.additionalInfo)}
              <PictogramCheckboxGroup
                field={safety_pictograms}
                display="Select pictograms as shown on the product or safety data sheet:"
                response={response}
                options={COSHH_SAFETY_PICTOGRAM_CHOICES}
                isArchived={isArchived}
              />
              {renderMultipleFileUploadField(
                related_files,
                "Upload safety data sheet:",
                handleSubmit,
                this.props.uploadFile,
                uploadFileResponse,
                COSHH_FORM,
                isArchived
              )}
              {renderTextAreaField(notes, "Summary", response, isArchived)}
              {renderRelatedTasksField(staff_tasks, isArchived)}
            </div>
        </div>
        {!isEditing && !isArchived &&
          <div className="form-group">
            <div className="col-1-3">
              <LiveButton
                pendingMessage={"Creating..."}
                response={createDigitalRecordResponse}
                className="btn-default pd-2"
              >
                Save
              </LiveButton>
            </div>
          </div>
        }
        {!isArchived && isEditing &&
          <div className="form-group">
            <div className="col-2-3">
              <LiveButton
                pendingMessage={"Archiving..."}
                response={archiveDigitalRecordResponse}
                className="btn-warning pd-2"
                onClick={handleArchive}
              >
                Archive
              </LiveButton>
            </div>
            <div className="col-1-3">
              <LiveButton
                pendingMessage={"Saving..."}
                response={updateDigitalRecordResponse}
                className="btn-default pd-2"
              >
                Save
              </LiveButton>
            </div>
          </div>
        }
        {hasSucceeded(updateDigitalRecordResponse) && (
          <div className="form-group mb-2">
            <p className="alert mt-1 alert-success">Successfully updated!</p>
          </div>
          )
        }
        {hasSucceeded(archiveDigitalRecordResponse) && (
          <div className="form-group mb-2">
            <p className="alert mt-1 alert-warning">Successfully archived!</p>
          </div>
          )
        }
      </form>
    );
  }
};

CoshhForm.propTypes = {
  fields: React.PropTypes.object,
  handleSubmit: React.PropTypes.func.isRequired,
  isEditing: React.PropTypes.bool,
  isArchived: React.PropTypes.bool,
  response: ImmutablePropTypes.map,
  archiveDigitalRecordResponse: ImmutablePropTypes.map,
  updateDigitalRecordResponse: ImmutablePropTypes.map,
};

export function validate(values) {
  const {
    type,
  } = values;

  const errors = {};


  if (!type) {
    errors.type = "Please provide a Product type.";
  }

  return errors;
}

export function mapStateToProps(state) {
  return {
    uploadFileResponse: state.responses.get('uploadFile'),
  };
}

export const FormifiedEventForm = reduxForm({
  form: COSHH_FORM,
  fields: [
    'type',
    'product_name',
    'used_by',
    'used_for',
    'location',
    'amount_used',
    'usage_frequency',
    'usage_conditions',
    'signal_word',
    'hazard_statement',
    'risks_to_health',
    'precautionary_statement',
    'safety_measures',
    'ppe_required',
    'first_aid_proceedures',
    'emergency_proceedures',
    'special_storage',
    'disposal_measures',
    'health_monitoring',
    'additional_info',
    'related_files',
    'assign_to',
    'safety_pictograms',
    'notes',
  ],
  validate,
})(CoshhForm);

export default connect(
  mapStateToProps,
  { uploadFile, setCollapse }
)(FormifiedEventForm);
