import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { reset as resetForm } from 'redux-form';

import { getSetupTeamMembers } from '^/actions/actions';
import { loadItem } from '^/actions/items';
import { USERS, ALL_PRACTICE_MEMBERS, PRACTICES } from '^/consts/collectionKeys';
import { getAllPracticeMembers } from '^/actions/collections';
import { hasSucceeded } from '^/consts/responseStates';
import { deepMergeObject } from '^/utils';
import { STATUS_TYPES } from '^/models/user';
import {
  setupTeamMembersAndReloadUser,
  setupAddTeamMember,
  setupAddExistingTeamMembers
} from '^/actions/actionSequences';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import AddNewOrExistingTeamMemberTabset from '^/components/app/users/modals/AddNewOrExistingTeamMemberTabset';
import AccountSetupHeader from '^/components/app/account-set-up/AccountSetupHeader';
import { FORM_NAME } from '^/components/app/users/forms/AddNewTeamMemberToPracticeForm';
import TeamMemberList from '^/components/app/account-set-up/TeamMemberList';
import LiveButton from '^/components/app/forms/LiveButton';

const TEAM_MEMBERS_TABSET = 'TEAM_MEMBERS_TABSET';
const CONFIRM_NO_TEAM_MEMBERS = 'You haven\'t added any team members, are you sure you want to continue?';


export class TeamMembersSetupPage extends PureComponent {

  componentWillMount() {
    this.loadTeamMembers(this.getPractice(this.props));
  }

  getUser(props, notFound = Map()) {
    return props.user || notFound;
  }

  getPractice(props) {
    return this.getUser(props).getIn(['staffdetail', 'current_practice', 'id']);
  }

  componentWillReceiveProps(newProps) {
    const oldPractice = this.getPractice(this.props);
    const newPractice = this.getPractice(newProps);
    if (oldPractice !== newPractice) {
      this.loadTeamMembers(newPractice);
    }

    if (
      !hasSucceeded(this.props.addMemberResponse) &&
      hasSucceeded(newProps.addMemberResponse)
    ) {
      this.props.resetForm(FORM_NAME);
    }
  }

  loadTeamMembers(practiceId) {
    if (practiceId) {
      this.props.getSetupTeamMembers(practiceId);
      this.props.loadItem(PRACTICES, practiceId);
      if (!this.props.allPracticeMembers) {
        this.props.getAllPracticeMembers(practiceId);
      }
    }
  }

  canFinish() {
    const { teamMembers } = this.props;

    return (teamMembers && teamMembers.size > 1) || window.confirm(CONFIRM_NO_TEAM_MEMBERS);
  }

  finish() {
    const practice = this.getPractice(this.props);
    if (this.canFinish()) {
      this.props.setupTeamMembersAndReloadUser(practice, {completed: true});
    }
  }

  addTeamMember(data) {
    const practiceId = this.getPractice(this.props);

    const mergedData =
      deepMergeObject(
        data,
        {
          status: STATUS_TYPES.INVITED,
          staffdetail: {current_practice: practiceId},
          staffpractice: {practice: practiceId}
        }
      );

    this.props.setupAddTeamMember(practiceId, mergedData);
  }

  addExistingMembers(data) {
    const practiceId = this.getPractice(this.props);

    this.props.setupAddExistingTeamMembers(practiceId, data);
  }

  render() {
    const { user, teamMembers, finishResponse, getTeamMembersResponse, allPracticeMembers } = this.props;

    if (!user || !allPracticeMembers) {
      return <Loading />;
    }

    return (
      <div>
        <AccountSetupHeader user={user}>
          Setup - team members
        </AccountSetupHeader>

        <h3>Add Team Members</h3>

        <hr className="thin" />

        <AddNewOrExistingTeamMemberTabset
          className="mt-1 mb-1"
          onSubmitNewMember={data => this.addTeamMember(data)}
          onSubmitExistingMembers={data => this.addExistingMembers(data)}
          tabset={TEAM_MEMBERS_TABSET}
        />

        <hr className="thin" />

        <TeamMemberList
          className="mt-1"
          teamMembers={teamMembers}
          response={getTeamMembersResponse}
        />

        <hr className="thin" />

        <LiveButton
          pendingMessage="Saving..."
          response={finishResponse}
          className="btn-default"
          onClick={() => this.finish()}
        >
          Next...
        </LiveButton>
      </div>
    );
  }

}

export const mapStateToProps = state => ({
  user: state.userProfile,
  addMemberResponse: state.responses.getIn(['createItem', USERS]),
  finishResponse: state.responses.get('setupTeamMembers'),
  teamMembers: state.teamMembers,
  getTeamMembersResponse: state.responses.get('getSetupTeamMembers'),
  allPracticeMembers: state.collections.getIn([ALL_PRACTICE_MEMBERS, 'items']),
});

export default connect(
  mapStateToProps,
  {
    setupAddTeamMember,
    setupAddExistingTeamMembers,
    setupTeamMembersAndReloadUser,
    getSetupTeamMembers,
    resetForm,
    loadItem,
    getAllPracticeMembers,
  }
)(TeamMembersSetupPage);
