import React from 'react';
import { Link } from 'react-router';
import UserCreatedMarkup from '^/components/common/content/UserCreatedMarkup';
import { formatDate } from '^/utils';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import LiveButton from '^/components/app/forms/LiveButton';

const NewsDetail = ({
  newsStory,
  newerNewsStory,
  olderNewsStory,
  isGroupNewsTriagePage,
  groupPublishResponse,
  groupUnpublishResponse,
  toggleGroupPublished,
  practiceGroupHasNewsTriage,
}) => {
  const {
    title,
    created,
    content,
    image,
    is_group_published,
  } = newsStory.toObject();

  return (
    <div>
      <div className="row">
        <p className="breadcrumb news-image">NEWS</p>

        <div className="news-wrapper mb-1">
          <h1>{title}</h1>

          <p className="lighten-text">{formatDate(created)}</p>
        </div>
      </div>

      <div className="row">
        {image && <img className="news-image" src={image} />}

        <div className="mb-1 news-wrapper">
          {practiceGroupHasNewsTriage && !is_group_published && (
            <HelpBlock className="small-text mb-1">
              <h5 className="m0 mb-1-2 mt-1-4 extra-bold-text">
                This article is unpublished and is not visible to your group's
                practices.
              </h5>
            </HelpBlock>
          )}

          <div className="news-content">
            <UserCreatedMarkup text={content} />
          </div>

          <div className="mt-1 display-flex flex-justify-space-between">
            {newerNewsStory ? (
              <Link
                className="btn btn-default"
                to={
                  isGroupNewsTriagePage
                    ? `/page/groups/news/${newerNewsStory.get('id')}/`
                    : `/page/news/${newerNewsStory.get('id')}/`
                }
              >
                &lt; Newer
              </Link>
            ) : (
              <a disabled className="btn btn-default disabled">
                &lt; Newer
              </a>
            )}
            {isGroupNewsTriagePage && !is_group_published && (
              <LiveButton
                className="btn btn-primary"
                response={groupPublishResponse}
                onClick={toggleGroupPublished}
              >
                Publish to practices
              </LiveButton>
            )}
            {isGroupNewsTriagePage && is_group_published && (
              <LiveButton
                className="btn btn-primary"
                response={groupUnpublishResponse}
                onClick={toggleGroupPublished}
              >
                Un-publish from practices
              </LiveButton>
            )}
            {olderNewsStory ? (
              <Link
                className="btn btn-default btn-right-margin"
                to={
                  isGroupNewsTriagePage
                    ? `/page/groups/news/${olderNewsStory.get('id')}/`
                    : `/page/news/${olderNewsStory.get('id')}/`
                }
              >
                Older &gt;
              </Link>
            ) : (
              <a disabled className="btn btn-default btn-right-margin disabled">
                Older &gt;
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
