import React from 'react';
import { Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { setSortBy, setSortByReversed } from '^/actions/actions';
import { getCollection, deleteItem, clearCollectionFilter } from '^/actions/collections';
import AdminListItems from '^/components/admin/content/list/AdminListItems';
import Loading from '^/components/app/content/Loading';
import PureComponent from '^/components/common/PureComponent';
import { isPending } from '^/consts/responseStates';

export const PAGE_SIZE = 10;
export const LIST_NAME_PREFIX = 'ADMINLIST_';

export class AdminList extends PureComponent {
  componentDidMount() {
    this.loadItems();
  }

  componentWillUnmount() {
    const { listName } = this.props;
    this.props.clearCollectionFilter(listName);
  }

  search(searchString) {
    const { sortBy, sortByReversed } = this.props;
    const orderBy = sortBy && sortByReversed ? '' + sortBy : sortBy;
    this.loadItems(searchString, 1, orderBy);
  }

  filterBySector(sector) {
    const { sortBy, sortByReversed, collection } = this.props;
    const filters = this.props.filters || Map();
    const newFilters = sector ? filters.set('sector', sector) : filters.delete('sector');
    const orderBy = sortBy && sortByReversed ? '' + sortBy : sortBy;
    this.loadItems(collection && collection.get('searchString', ''), 1, orderBy, newFilters);
  }

  loadPage(page) {
    const { sortBy, sortByReversed, collection, filters } = this.props;
    const orderBy = sortBy && sortByReversed ? '' + sortBy : sortBy;
    this.loadItems(collection && collection.get('searchString', ''), page, orderBy, filters);
  }

  loadItems(searchString = '', page = 1, ordering = null, newFilters = null) {
    const { listName, model, defaultFilters, isLoading, pageSize } = this.props;
    const filters = newFilters ? newFilters.merge(defaultFilters) : this.props.filters;

    if (!isLoading) {
      this.props.getCollection(
        model,
        {
          page, pageSize: pageSize, searchString, shouldAppend: false,
          additionalParams: {ordering}, filters: filters.toJS()
        },
        listName
      );
    }
  }

  removeItem(itemId) {
    const { model, listName, onRemove } = this.props;
    if (window.confirm('Are you sure you want to remove?')) {
      this.props.deleteItem(model, itemId, listName);
      if (onRemove) {
        onRemove();
      }
    }
  }

  setSortBy(sortPath) {
    const { searchString, page } = this.props.collection.toObject();
    const { listName, sortByReversed, sortBy } = this.props;
    const shouldReverse = sortPath === sortBy && !sortByReversed;
    this.props.setSortBy(listName, sortPath);
    this.props.setSortByReversed(listName, shouldReverse);
    this.loadItems(searchString, page, shouldReverse ? sortPath : '-' + sortPath);
  }

  render() {
    const { collection, removeItemResponse } = this.props;
    if (!collection || isPending(removeItemResponse)) {
      return <Loading />;
    }

    return (
      <AdminListItems
        {...Object.assign({}, this.props, {
          removeItem: itemId => this.removeItem(itemId),
          setSortBy: sortPath => this.setSortBy(sortPath),
          search: searchString => this.search(searchString),
          filterBySector: (sector) => this.filterBySector(sector),
          loadPage: page => this.loadPage(page),
        })}
      />
    );
  }
}

export function mapStateToProps(state, props) {
  const listName = props.listName || (LIST_NAME_PREFIX + props.model);
  const collection = state.collections.get(listName);
  const defaultFilters = props.defaultFilters || Map();
  const appliedFilters = collection ? Map(collection.get('filters')) : Map();
  return {
    collection,
    filters: defaultFilters.merge(appliedFilters),
    isLoading: isPending(state.responses.getIn(['getCollection', listName])),
    sortBy: state.ui.getIn(['sortBy', listName]),
    sortByReversed: state.ui.getIn(['sortByReversed', listName]),
    listName,
    pageSize: props.pageSize || PAGE_SIZE,
    removeItemResponse: state.responses.getIn(['deleteItem', listName]),
  };
}

AdminList.propTypes = {
  model: React.PropTypes.string,
  fields: ImmutablePropTypes.list.isRequired,
  hidePagination: React.PropTypes.bool,
  removeItem: React.PropTypes.func,
  onRemove: React.PropTypes.func,
};

export default connect(mapStateToProps, {
  getCollection,
  setSortBy,
  setSortByReversed,
  deleteItem,
  clearCollectionFilter,
})(AdminList);
