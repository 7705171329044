import React from 'react';
import { connect } from 'react-redux';

import PureComponent from '^/components/common/PureComponent';
import DocumentReviewForm from '^/components/app/template-documents/forms/DocumentReviewForm';

import {
  createDocumentReviewAndCloseModal,
  completeDocumentReviewAndCloseModal,
} from '^/actions/actionSequences';

export class DocumentReviewModal extends PureComponent {
  createOrCompleteDocumentReview = (data) => {
    const { adoptedDocument } = this.props;

    const adoptedDocumentId = adoptedDocument.get('id');

    const documentReview = adoptedDocument.get('document_review');

    if (!documentReview) {
      this.props.createDocumentReviewAndCloseModal(adoptedDocumentId, data);
    } else if (!documentReview.get('can_complete_now')) {
      this.props.createDocumentReviewAndCloseModal(adoptedDocumentId, data);
    } else {
      this.props.completeDocumentReviewAndCloseModal(adoptedDocumentId, data);
    }
  }

  render() {
    const { adoptedDocument } = this.props;

    const documentReview = adoptedDocument.get('document_review');

    const scheduleDate = adoptedDocument.get('document_review_default_scheduled_date');
    
    const rescheduleExisting = documentReview ? documentReview.get('can_complete_now') : false

    const canRescheduleReview = documentReview ? !documentReview.get('can_complete_now') : false

    return (
      <div>
          <DocumentReviewForm
            initialValues={{
              schedule_date: scheduleDate,
              reschedule_existing: rescheduleExisting,
            }}
            onSubmit={this.createOrCompleteDocumentReview}
            canRescheduleReview={canRescheduleReview}
          />
      </div>
    );
  }
}

export default connect(null, {
  createDocumentReviewAndCloseModal,
  completeDocumentReviewAndCloseModal,
})(DocumentReviewModal);
