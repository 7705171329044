import React from 'react';
import { reduxForm } from 'redux-form';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { hasSucceeded } from '^/consts/responseStates';
import LiveButton from '^/components/app/forms/LiveButton';
import PureComponent from '^/components/common/PureComponent';
import {
  renderTypeField,
  renderTextField,
  renderExpiryDateFieldWithNull,
  renderNotesField,
  renderAssignToLabel,
  renderPersonOrRoleDropDown
} from '^/components/app/digital-tools/records/digital-records/forms/FormFields';


export const EMERGENCY_DRUGS_AND_EQUIPMENT_FORM = 'EMERGENCY_DRUGS_AND_EQUIPMENT_FORM';

export class EmergencyDrugsAndEquipmentForm extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      isChecked: false
    }
    this.setExpiryDateNull.bind(this)
  }
  setExpiryDateNull = () => {
    this.setState((prevState) => ({isChecked: !prevState.isChecked}));
  }

  render() {
    const {
      handleSubmit,
      fields,
      response,
      responsibilities,
      practiceMembers,
      isEditing,
      isArchived,
      handleArchive,
      archiveDigitalRecordResponse,
      updateDigitalRecordResponse,
      createDigitalRecordResponse,
    } = this.props;
    const {
      type,
      product,
      pack_size,
      reorder_code,
      batch_number,
      location,
      expiry_date,
      notes,
      person_or_role,
      assign_to,
      expiry_date_null,
    } = fields;

    const ALL_PRACTICE_MEMBERS_OPTIONS = practiceMembers.map((member) => {
      return {
        name: member.getIn(['user', 'staffdetail', 'full_name']),
        id: member.getIn(['user', 'staffdetail', 'id']),
      }
    }).toJS()

    return (
      <form onSubmit={handleSubmit}>
        {renderTypeField(type, response, isArchived)}
        {renderTextField(product, "Product*", response, isArchived)}
        {renderTextField(pack_size, "Pack size", response, isArchived)}
        {renderTextField(reorder_code, "Re-order code", response, isArchived)}
        {renderTextField(batch_number, "Reference number", response, isArchived)}
        {renderTextField(location, "Location", response, isArchived)}
        {renderExpiryDateFieldWithNull(expiry_date, "Expiry Date", response, isArchived, this.state.isChecked, expiry_date_null, 
        this.state.isChecked,this.setExpiryDateNull)}
        {renderAssignToLabel(person_or_role, isArchived)}
        {renderPersonOrRoleDropDown(
          assign_to,
          person_or_role,
          ALL_PRACTICE_MEMBERS_OPTIONS,
          responsibilities,
          response,
          isArchived
          )
        }
        {renderNotesField(notes, response, isArchived)}
        {!isEditing && !isArchived &&
          <div className="form-group">
            <div className="col-1-3">
              <LiveButton
                pendingMessage={"Creating..."}
                response={createDigitalRecordResponse}
                className="btn-default pd-2"
              >
                Save
              </LiveButton>
            </div>
          </div>
        }
        {!isArchived && isEditing &&
          <div className="form-group">
            <div className="col-2-3">
              <LiveButton
                pendingMessage={"Archiving..."}
                response={archiveDigitalRecordResponse}
                className="btn-warning pd-2"
                onClick={handleArchive}
              >
                Archive
              </LiveButton>
            </div>
            <div className="col-1-3">
              <LiveButton
                pendingMessage={"Saving..."}
                response={updateDigitalRecordResponse}
                className="btn-default pd-2"
              >
                Save
              </LiveButton>
            </div>
          </div>
        }
        {hasSucceeded(updateDigitalRecordResponse) && (
          <div className="form-group mb-2">
            <p className="alert mt-1 alert-success">Successfully updated!</p>
          </div>
          )
        }
        {hasSucceeded(archiveDigitalRecordResponse) && (
          <div className="form-group mb-2">
            <p className="alert mt-1 alert-warning">Successfully archived!</p>
          </div>
          )
        }
      </form>
    )
  }
};

EmergencyDrugsAndEquipmentForm.propTypes = {
  fields: React.PropTypes.object,
  handleSubmit: React.PropTypes.func.isRequired,
  isEditing: React.PropTypes.bool,
  isArchived: React.PropTypes.bool,
  responsibilities: ImmutablePropTypes.list.isRequired,
  practiceMembers: ImmutablePropTypes.list,
  response: ImmutablePropTypes.map,
  archiveDigitalRecordResponse: ImmutablePropTypes.map,
  updateDigitalRecordResponse: ImmutablePropTypes.map,
};

export function validate(values) {
  const {
    type,
    product,
    assign_to,
  } = values;

  const errors = {};

  if (!type) {
    errors.type = "Type is required";
  }

  if (!product) {
    errors.product = "Product is required";
  }

  if (!assign_to) {
    errors.assign_to = "You must select either a role or a person";
  }

  return errors;
}


export const FormifiedEmergencyDrugsAndEquipmentForm = reduxForm({
  form: EMERGENCY_DRUGS_AND_EQUIPMENT_FORM,
  fields: [
    'type',
    'product',
    'pack_size',
    'reorder_code',
    'batch_number',
    'location',
    'expiry_date',
    'notes',
    'person_or_role',
    'assign_to',
    'expiry_date_null',
  ],
  validate,
})(EmergencyDrugsAndEquipmentForm);

export default FormifiedEmergencyDrugsAndEquipmentForm;
