import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as jsCookie from 'js-cookie';
import { Action, Dispatch } from 'redux';
import { createReducer } from '@dabapps/redux-create-reducer';

import { AsyncActionSet } from '^/actions/types';

export interface ThunkAction<E extends {} | undefined = undefined>
  extends Action {
  meta: {
    actionSet: AsyncActionSet;
    options: ThunkRequestOptions;
    config: AxiosRequestConfig;
    extra: E;
  };
}

export interface ThunkActionWithPayload<
  P,
  E extends {} | undefined = undefined
> extends ThunkAction<E> {
  payload: P;
}

export type ThunkRequestSuccessAction<
  P,
  E extends {} | undefined = undefined
> = ThunkActionWithPayload<AxiosResponse<P>, E>;

const BASE_HEADERS = {
  Accept: 'application/json',
};

const getRequestHeaders = () => {
  const csrf = jsCookie.get('csrftoken');

  return {
    ...BASE_HEADERS,
    ...(csrf ? { 'X-CSRFToken': csrf } : null),
  };
};

export interface ThunkRequestOptions {
  rethrowErrors?: boolean;
  requestId?: string;
}

const DEFAULT_THUNK_REQUEST_OPTIONS: ThunkRequestOptions = {
  rethrowErrors: false,
};

export function createThunkRequestReducer<S>(
  actionSet: AsyncActionSet,
  initialState: S
) {
  return createReducer<S, ThunkRequestSuccessAction<S>>(
    {
      [actionSet.SUCCESS]: (_state, action) => {
        return action.payload.data;
      },
    },
    initialState
  );
}

export function makeThunkRequest<P>(
  actionSet: AsyncActionSet,
  config: AxiosRequestConfig,
  options?: { rethrowErrors: false } & ThunkRequestOptions,
  extra?: undefined
): (dispatch: Dispatch<unknown>) => Promise<unknown>;
export function makeThunkRequest<P>(
  actionSet: AsyncActionSet,
  config: AxiosRequestConfig,
  options?: { rethrowErrors: true } & ThunkRequestOptions,
  extra?: undefined
): (dispatch: Dispatch<unknown>) => Promise<AxiosResponse<P>>;
export function makeThunkRequest<P, E extends {} = {}>(
  actionSet: AsyncActionSet,
  config: AxiosRequestConfig,
  options: { rethrowErrors: false } & ThunkRequestOptions,
  extra: E
): (dispatch: Dispatch<unknown>) => Promise<unknown>;
export function makeThunkRequest<P, E extends {} = {}>(
  actionSet: AsyncActionSet,
  config: AxiosRequestConfig,
  options: { rethrowErrors: true } & ThunkRequestOptions,
  extra: E
): (dispatch: Dispatch<unknown>) => Promise<AxiosResponse<P>>;
export function makeThunkRequest<P, E extends {} = {}>(
  actionSet: AsyncActionSet,
  config: AxiosRequestConfig,
  options: ThunkRequestOptions = DEFAULT_THUNK_REQUEST_OPTIONS,
  extra: E
) {
  return (dispatch: Dispatch<unknown>) => {
    if (!config.url) {
      return Promise.reject(Error('No URL provided in request config'));
    }

    const headers = getRequestHeaders();

    const meta = {
      actionSet,
      config,
      options,
      extra,
    };

    dispatch({ type: actionSet.REQUEST, meta });

    return axios
      .request<P>({
        ...config,
        headers: {
          ...config.headers,
          ...headers,
        },
      })
      .then(response => {
        dispatch({
          type: actionSet.SUCCESS,
          payload: response,
          meta,
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: actionSet.FAILURE,
          payload: error,
          meta,
        });

        if (options.rethrowErrors) {
          throw error;
        }

        return error;
      });
  };
}
