import React from 'react';

import PureComponent from '^/components/common/PureComponent';
import { getTemplateDocumentVersion } from '^/components/app/template-documents/DocumentEditor';
import Editor from './Editor';
import ReadOnlyEditor from './ReadOnlyEditor';


export default class DocumentEditor extends PureComponent {

  componentWillMount() {
    const { doc, readOnly } = this.props;
    const { content, working_version } = doc.toObject();
    if (!readOnly) {
      this.props.setEditorContent(working_version ? working_version.get('content') : content);
    }
  }

  render() {
    const { setEditorContent, doc, readOnly, userId, editorContent, height } = this.props;
    const { content, activity, working_version } = doc.toObject();
    const isAttached = !!activity;
    const templateDocumentVersion = getTemplateDocumentVersion(doc, isAttached);

    // This is a version of an adopted or a global template_document
    const adopted = templateDocumentVersion.get('adopted_template_document');

    const baseTemplateDocumentVersion = adopted
      ? templateDocumentVersion.getIn(['adopted_template_document', 'template_document_version'])
      : templateDocumentVersion;

    const isLandscape = baseTemplateDocumentVersion.getIn(['template_document', 'is_landscape']);

    const locked = working_version && working_version.getIn(['user', 'id']) !== userId;

    return (
      <div>
        {(readOnly || locked) ? (
          <ReadOnlyEditor
            content={content}
            isLandscape={isLandscape}
            height={height}
          />
        ) : (
          <Editor
            content={editorContent || (working_version ? working_version.get('content') : content)}
            isLandscape={isLandscape}
            setEditorContent={setEditorContent}
            height={height}
          />
        )}
      </div>
    );
  }
}
