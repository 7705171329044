import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import classNames from 'classnames';

import {
  updateSearchItemQuery,
  expandUiComponent,
  collapseUiComponent
} from '^/actions/actions';
import { searchDocumentsActivitiesAndTasks, clearSearchResults } from '^/actions/actionSequences';
import {
  SEARCHED_DOCS,
  SEARCHED_ACTIVITIES,
  SEARCHED_TASKS,
  SEARCHED_UPLOADED_ACTIVITY_TASK_FILES
} from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';

import SearchResults, { SEARCH_MODAL } from '^/components/app/nav/search/SearchResults';
import SearchInput from '^/components/app/nav/search/SearchInput';
import PureComponent from '^/components/common/PureComponent';


export class Search extends PureComponent {
  render() {
    const {
      collapse, updateQuery, loading,
      className, isExpanded, searchQuery,
      docsResults, tasksResults, activitiesResults,
      uploadedFilesResults, search, currentPractice
    } = this.props;

    return (
      <div className={classNames('nav-strip-search', className, isExpanded && 'expanded')}>
        <div
          tabIndex={isExpanded ? '-1' : false}
          onBlur={collapse} className="nav-search-box-container"
        >
          <SearchInput
            updateQuery={updateQuery}
            collapse={collapse}
            searchQuery={searchQuery}
            search={query => search(query, currentPractice)}
            loading={loading}
          />
          </div>
          {isExpanded && !!searchQuery &&
            <SearchResults
              docsResults={docsResults}
              activitiesResults={activitiesResults}
              tasksResults={tasksResults}
              uploadedFilesResults={uploadedFilesResults}
              loading={loading}
            />
          }
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    currentPractice: state.currentPractice,
    isExpanded: state.ui.get('expandedUiComponents').contains(SEARCH_MODAL),
    searchQuery: state.ui.getIn(['searchItemQuery', SEARCH_MODAL], ''),
    docsResults: state.collections.getIn([SEARCHED_DOCS, 'items'], List()),
    activitiesResults: state.collections.getIn([SEARCHED_ACTIVITIES, 'items'], List()),
    tasksResults: state.collections.getIn([SEARCHED_TASKS, 'items'], List()),
    uploadedFilesResults: state.collections.getIn([SEARCHED_UPLOADED_ACTIVITY_TASK_FILES, 'items'], List()),
    loading:
      isPending(state.responses.getIn(['getCollection', SEARCHED_DOCS])) ||
      isPending(state.responses.getIn(['getCollection', SEARCHED_ACTIVITIES])) ||
      isPending(state.responses.getIn(['getCollection', SEARCHED_TASKS])) ||
      isPending(state.responses.getIn(['getCollection', SEARCHED_UPLOADED_ACTIVITY_TASK_FILES])),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    collapse: () => {
      dispatch(collapseUiComponent(SEARCH_MODAL));
      dispatch(clearSearchResults());
    },
    updateQuery: val => dispatch(updateSearchItemQuery(SEARCH_MODAL, val)),
    search: (query, practice) => {
      dispatch(searchDocumentsActivitiesAndTasks(query, practice));
      dispatch(expandUiComponent(SEARCH_MODAL));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
