import React, { Component, ComponentType } from 'react';
import { Link } from 'react-router';

import { AuditCategoryMerged, ItemRendererProps, BaseItem } from './types';

interface OwnProps<T extends BaseItem> {
  splitByCorrect?: boolean;
  category: AuditCategoryMerged<T> | undefined;
  itemRenderer: ComponentType<ItemRendererProps<T>>;
  auditId: string;
  correct: string | undefined;
}

type QuestionRendererProps<T extends BaseItem> = OwnProps<T>;

const createQuestionRenderer = <T extends BaseItem>() => {
  return class QuestionRenderer extends Component<
    QuestionRendererProps<T>,
    {}
  > {
    render() {
      const { splitByCorrect, category, correct, auditId } = this.props;

      if (!category) return null;

      const renderCorrect = correct === 'correct';
      return (
        <div>
          {!splitByCorrect && this.renderQuestions(category?.questions)}
          {splitByCorrect && (
            <div className="audit-actions">
              <div className="tab-bar">
                <div className={renderCorrect ? 'p-1-2' : 'selected p-1-2'}>
                  <Link
                    to={`/page/tools/audit/${auditId}/actions/${category.id}/`}
                  >
                    Action Required
                  </Link>
                </div>
                <div className={renderCorrect ? 'selected p-1-2' : 'p-1-2'}>
                  <Link
                    to={`/page/tools/audit/${auditId}/actions/${category.id}/correct`}
                  >
                    No Action Required
                  </Link>
                </div>
              </div>
              <div>
                {this.renderQuestions(
                  this.getQuestionsByCompletion(renderCorrect)
                )}
              </div>
            </div>
          )}
        </div>
      );
    }

    private getQuestionsByCompletion = (completed: boolean) => {
      const { category } = this.props;
      if (!category || !category.questions) {
        return [];
      }

      return category.questions.filter(
        question => question.is_correct === completed
      );
    };

    private renderQuestions = (questions: readonly T[] = []) => {
      const {
        category = { id: '' },
        itemRenderer: ItemRenderer,
        auditId,
      } = this.props;

      return questions.length ? (
        questions.map((question, idx) => (
          <div key={idx}>
            <ItemRenderer
              key={question.id}
              question={question}
              categoryId={category.id}
              auditId={auditId}
            />
          </div>
        ))
      ) : (
        <div className="mb-1">You are all caught up</div>
      );
    };
  };
};

export default createQuestionRenderer;
