import React from 'react';
import { reduxForm } from 'redux-form';
import { List, Map } from 'immutable';

import LiveButton from '^/components/app/forms/LiveButton';
import HelpTooltip from '^/components/app/widgets/HelpTooltip';

import { isOptional, isOperational, isOptionalOrOperational } from '^/models/compliancereviews';
import { ActivityPriority } from '^/components/app/activities/ActivityPriority';

export const COMPLIANCE_REVIEWS_GROUP_CONFIG_FORM = 'COMPLIANCE_REVIEWS_GROUP_CONFIG_FORM';

export const TemplateAdminStepField = ({fields, templateAdminStep}) => {
  const { compliance_reviews } = templateAdminStep.toObject();
  const operationalReviews = compliance_reviews.filter(isOperational);
  const optionalReviews = compliance_reviews.filter(isOptional).filterNot(isOperational);
  const mandatoryReviews = compliance_reviews.filterNot(isOptionalOrOperational);

  return (
    <div className="mt-1 small-text pl-1 pr-1">
      <h3>
        Monthly Focus: {templateAdminStep.getIn(['template_activity', 'name'])}
        {templateAdminStep.getIn(['template_activity', 'help_text']) && (
          <HelpTooltip>
            <p>{templateAdminStep.getIn(['template_activity', 'help_text'])}</p>
          </HelpTooltip>
        )}
      </h3>
      <div className="row">
        <div className="col-1-2">
          <h5>Compulsory Compliance Reviews ({mandatoryReviews.size})</h5>
            {mandatoryReviews.size > 0 ? (
                <table className="setup-compliance-review-table selectable">
                <tbody>
                    {mandatoryReviews.map(each => {
                      const templateActivity = each.get('template_activity');
                      return (
                      <tr key={templateActivity.get('id')}>
                        <td>
                          <input type="checkbox" {...fields[templateActivity.get('id')]} id={templateActivity.get('id')} />
                        </td>
                        <td className="table-col-lg">
                          <label title={templateActivity.get('help_text')} htmlFor={templateActivity.get('id')}>
                            {templateActivity.get('name')}
                          </label>
                        </td>
                        <td>
                          <ActivityPriority activity={templateActivity}/>
                        </td>
                      </tr>
                    )})
                  }
                </tbody>
                </table>
            ) : <p>There are no compulsory compliance reviews in this Monthly Focus</p>
          }
        </div>
        <div className="col-1-2">
            <h5>Operational Compliance Reviews ({operationalReviews.size})</h5>
              {operationalReviews.size > 0 ? (
                  <table className="setup-compliance-review-table selectable">
                    <tbody>
                      {operationalReviews.map(each => {
                        const templateActivity = each.get('template_activity');
                        return (
                        <tr key={templateActivity.get('id')}>
                          <td>
                            <input type="checkbox" {...fields[templateActivity.get('id')]} id={templateActivity.get('id')} />
                          </td>
                          <td className="table-col-lg">
                            <label title={templateActivity.get('help_text')} htmlFor={templateActivity.get('id')}>
                              {templateActivity.get('name')}
                            </label>
                          </td>
                          <td>
                            <ActivityPriority activity={templateActivity}/>
                          </td>
                        </tr>
                      )})
                    }
                    </tbody>
                  </table>
                ) : <p>There are no operational compliance reviews in this Monthly Focus</p>}
          <br/>
            <h5>Optional Compliance Reviews ({optionalReviews.size})</h5>
            {optionalReviews.size > 0 ? (
                <table className="setup-compliance-review-table selectable">
                  <tbody>
                    {optionalReviews.map(each => {
                      const templateActivity = each.get('template_activity');
                      return (
                      <tr key={templateActivity.get('id')}>
                        <td>
                          <input type="checkbox" {...fields[templateActivity.get('id')]} id={templateActivity.get('id')} />
                        </td>
                        <td className="table-col-lg">
                          <label title={templateActivity.get('help_text')} htmlFor={templateActivity.get('id')}>
                            {templateActivity.get('name')}
                          </label>
                        </td>
                        <td>
                          <ActivityPriority activity={templateActivity}/>
                        </td>
                      </tr>
                    )})
                  }
                  </tbody>
                </table>
              ) : <p>There are no optional compliance reviews in this Monthly Focus</p>}
          </div>
        </div>
      </div>
    );
};

export const TemplateAdminStepFields = ({fields, templateAdminSteps}) =>
  <div className="mb-2">
    {
      templateAdminSteps && templateAdminSteps.size > 0 ?
        templateAdminSteps.map(templateAdminStep => (
          <TemplateAdminStepField
            key={templateAdminStep.get('id')}
            fields={fields}
            templateAdminStep={templateAdminStep}
          />
          )
        ) : <p>There is no optional Monthly Focus to choose</p>
     }
   </div>;

export const ComplianceReviewsGroupConfigForm = ({
   handleSubmit, response, fields, templateAdminSteps
}) =>
  <form onSubmit={handleSubmit}>
    <hr className="thin" />
    <TemplateAdminStepFields
      fields={fields}
      templateAdminSteps={templateAdminSteps}
    />
    <LiveButton className="btn-default" pendingMessage="Saving..." response={response}>
      Save
    </LiveButton>
  </form>;


export const getFields = (templateAdminSteps) => templateAdminSteps
  .map(
    adminStep =>
      adminStep
        .get('compliance_reviews', List())
        .map(complianceReview => complianceReview.getIn(['template_activity', 'id']))
  )
  .flatten()
  .toJS();

export const getInitialValues = (templateAdminSteps) => Map(
  templateAdminSteps.map(
    adminStep => 
      adminStep
        .get('compliance_reviews', List())
        .map(review => [review.getIn(['template_activity', 'id']), !review.getIn(['template_activity', 'is_excluded'])])
    )
  .flatten()).toJS();

export const mapStateToProps = (state, props) => {
  return {
    fields: getFields(props.templateAdminSteps),
    initialValues: getInitialValues(props.templateAdminSteps),
  }
};

export default reduxForm({
  form: COMPLIANCE_REVIEWS_GROUP_CONFIG_FORM,
}, mapStateToProps)(ComplianceReviewsGroupConfigForm);
