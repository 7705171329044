import React from 'react';
import { reduxForm } from 'redux-form';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Loading from '^/components/app/content/Loading';
import FieldLookup from '^/components/admin/content/edit-create/dynamic-forms/field-types/FieldLookup';


const RelatedFieldSelect = ({ field, disabled, options, }) =>
  <select {...field} disabled={disabled}>
      <option>--</option>
      {
        options
          .sortBy(option => option.get('display_name'))
          .map(
            (option, key) =>
              <option key={key} value={option.get('value')} disabled={option.get('disabled')}>
                {option.get('display_name')}
              </option>
          )
      }
    </select>;


export const AdminManyRelatedFieldAdd = ({
  handleSubmit, addButtonText, addLabel, options, config, fields: { id }, disabled, controlName, defaultLookupFilters
 }) => {

  if (!options && !config) {
    return <Loading />;
  }

  return (
    <form onSubmit={handleSubmit} className="admin-manyrelatedfield-add-container">
      {addLabel ? (<label htmlFor="id">{addLabel}</label>) : ('')}

      {
        options ?
          <RelatedFieldSelect field={id} disabled={disabled} options={options} /> :
          <FieldLookup
            {...id}
            noLabel
            className="small-text mt-1-2"
            config={config.toJS()}
            name={controlName}
            defaultFilters={defaultLookupFilters}
          />
      }

      <div className="mt-1-2">
        <input className="btn btn-primary" type="submit" value={addButtonText || 'Add'} />
      </div>
      <hr className="thin" />
    </form>
  );
};

AdminManyRelatedFieldAdd.propTypes = {
  addLabel: React.PropTypes.string,
  addButtonText: React.PropTypes.string,
  defaultLookupFilters: React.PropTypes.object,
  options: ImmutablePropTypes.list,
};

export default reduxForm({
  fields: ['id']
})(AdminManyRelatedFieldAdd);
