import { fromJS } from 'immutable';


export const ADMIN_REMINDER_CREATE = 'ADMIN_REMINDER_CREATE';
export const ADMIN_REMINDER_MODEL = 'admin/template-tasks';
const ADMIN_REMINDER_MANAGE_ROUTE = '/admin/reminders/${id}/';

export const ADMIN_REMINDER_EDIT_FIELDS = fromJS([
  'sector', 'title', 'description', 'recommended_repeat_choice'
]);

export const ADMIN_REMINDER_ROW_FIELDS = fromJS([
  {name: ['title'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['description'], display_name: 'Description', type: 'string', sortable: true},
  {name: ['recommended_repeat_display'], display_name: 'Repeat', type: 'string', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Edit', route: ADMIN_REMINDER_MANAGE_ROUTE},
  {name: ['id'], display_name: 'Delete', type: 'remove', sortable: false},
]);
