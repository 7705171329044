import { Map } from 'immutable';

import { GET_COLLECTION } from '^/actions/collections';
import {
  GET_GROUP_DOCUMENTS_ALERTS_COUNT,
  DECREMENT_GROUP_DOCUMENT_ALERTS_COUNT,
  EXCLUDE_TEMPLATE_DOCUMENT_BY_GROUP,
  GET_FOLDER_UPLOADED_ACTIVITY_TASK_FILES_COUNT,
} from '^/actions/groupDocuments';
import { GROUP_DOCUMENT_ALERTS } from '^/consts/collectionKeys';

export function alertsCount(state = 0, action) {
  switch (action.type) {
    case GET_GROUP_DOCUMENTS_ALERTS_COUNT.SUCCESS:
      return action.payload;
    case GET_COLLECTION.SUCCESS:
      if (action.meta.collectionName === GROUP_DOCUMENT_ALERTS) {
        return action.payload.count;
      }
      return state;
    case DECREMENT_GROUP_DOCUMENT_ALERTS_COUNT:
      return state ? state - 1 : state;
    default:
      return state;
  }
}

export function excludeTemplateDocument(state = Map(), action) {
  switch (action.type) {
    case EXCLUDE_TEMPLATE_DOCUMENT_BY_GROUP.REQUEST:
      return null;
    case EXCLUDE_TEMPLATE_DOCUMENT_BY_GROUP.SUCCESS:
      return state
        .set('isExcluded', action.payload.is_excluded)
        .set('templateDocument', action.meta.templateDocumentId);
    default:
      return state;
  }
}

export function folderUploadedActivityTaskFilesCount(state = Map(), action) {
  switch (action.type) {
    case GET_FOLDER_UPLOADED_ACTIVITY_TASK_FILES_COUNT.REQUEST:
      return null;
    case GET_FOLDER_UPLOADED_ACTIVITY_TASK_FILES_COUNT.SUCCESS:
      return Map(action.payload);
    default:
      return state;
  }
}
