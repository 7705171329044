import { fromJS } from 'immutable';


export const ADMIN_COMPLIANCE_SYSTEM_CREATE = 'ADMIN_COMPLIANCE_SYSTEM_CREATE';
export const ADMIN_COMPLIANCE_SYSTEM_MODEL = 'admin/cqc-systems';
export const ADMIN_COMPLIANCE_SYSTEM_OPTIONS = 'COMPLIANCE_SYSTEM_OPTIONS';
const ADMIN_COMPLIANCE_SYSTEM_MANAGE_ROUTE = '/admin/compliance-systems/${id}/';

export const ADMIN_COMPLIANCE_SYSTEM_EDIT_FIELDS = fromJS([
  'sector', 'name', 'description', 'child_title', 'system', 'number', 'country', 'group_insights_enabled', 'group_insights_heading'
]);

export const ADMIN_COMPLIANCE_SYSTEM_CHILD_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['description'], display_name: 'Description', type: 'string', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Edit', route: ADMIN_COMPLIANCE_SYSTEM_MANAGE_ROUTE},
  {name: ['id'], display_name: 'Delete', type: 'remove', sortable: false},
]);

export const ADMIN_COMPLIANCE_SYSTEM_ROW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'string', sortable: true},
  {name: ['description'], display_name: 'Description', type: 'string', sortable: true},
  {name: ['sector'], display_name: 'Sector', type: 'string', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Edit', route: ADMIN_COMPLIANCE_SYSTEM_MANAGE_ROUTE},
  {name: ['id'], display_name: 'Delete', type: 'remove', sortable: false},
]);
