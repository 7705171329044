import React, { Component, ComponentType } from 'react';

import { BaseItem } from '^/components/app/healthcheck/types';

interface OwnProps<T> {
  summaryList: T[];
}

export type Column<T> = {
  name: string;
  renderer: ComponentType<{ data: T }>;
};

interface Options<T> {
  columns: Column<T>[];
}

export type SummaryTableProps<T> = OwnProps<T>;

const createSummaryTable = <T extends BaseItem>(options: Options<T>) => {
  class SummaryTable extends Component<SummaryTableProps<T>, {}> {
    render() {
      const { summaryList } = this.props;

      if (summaryList.length === 0) {
        return null;
      }

      return (
        <div className="scrollable-table-container mt-1">
          <table className="padded padded-md">
            <tr>
              {options.columns.map((column, idx) => (
                <th key={idx}>{column.name}</th>
              ))}
            </tr>
            {summaryList.map((summary, idx) => (
              <tbody key={idx}>
                <tr>
                  {options.columns.map(({ name, renderer: Renderer }) => (
                    <td key={name}>
                      <Renderer data={summary} />
                    </td>
                  ))}
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      );
    }
  }

  return SummaryTable;
};

export default createSummaryTable;
