import React from 'react';

export interface SelectProps {
  onChange: (value: string) => void;
  options: { value: string; label: string }[];
  selectedValue: string;
  disabled: boolean;
  defaultLabel?: string;
}

const Select = ({
  onChange,
  options,
  selectedValue,
  disabled,
  defaultLabel,
}: SelectProps) => {
  function onSelectChange(event: React.FormEvent<HTMLSelectElement>) {
    return onChange(event.currentTarget.value);
  }

  return (
    <div>
      <select
        disabled={disabled}
        value={selectedValue || ''}
        onChange={onSelectChange}
      >
        <option value="">
          {defaultLabel ? defaultLabel : 'Please choose..'}
        </option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
