import React from 'react';

import { landingReportsConfig, FILTER_KEYS } from '^/components/app/groups/insights/constants';
import Header from '^/components/app/groups/insights/Header';
import SubGroupFilter from '^/components/app/groups/insights/filters/SubGroupFilter';
import PrintButton from '^/components/app/groups/insights/visualisations/PrintButton';
import EventRecordsLanding from '^/components/app/groups/reports/EventRecordsLanding';

export default function GroupReportsPage({
  location: { pathname, query },
}) {
  return (
    <div className="col-1">
      <Header query={query} />
      <SubGroupFilter
        insightsConfig={landingReportsConfig}
        filterKey={FILTER_KEYS.SUB_GROUP}
        pathname={pathname}
        selectedSubGroup={query.sub_group}
      />
      <div className="mb-2">
        <EventRecordsLanding />
      </div>
      <div className="pull-right mb-2">
        <PrintButton />
      </div>
    </div>
  );
}
