import React from 'react';
import { connect } from 'react-redux';

import { renderAdminDocumentVersion, resetResponse } from '^/actions/actions';
import { retryExportAndResetPollProgress } from '^/actions/actionSequences';
import PureComponent from '^/components/common/PureComponent';
import { DownloadButton } from '^/components/app/activities/documents/DownloadDocumentButton';

export class TemplateDocumentVersionDownloadButton extends PureComponent {

  componentWillMount() {
    this.props.resetResponse('renderAdminDocumentVersion');
  }

  retryExport() {
    const exportId = this.props.response.getIn(['payload', 'id']);
    if (exportId) {
      this.props.retryExportAndResetPollProgress(exportId);
    }
  }

  render() {
    const { documentVersionId, response, hasUnsavedEdits, documentType } = this.props;
    return (
      <div>
        <div className="pull-right">
          <DownloadButton
            documentId={documentVersionId}
            startRenderDocument={() => this.props.renderAdminDocumentVersion(documentType, documentVersionId)}
            disabled={hasUnsavedEdits}
            response={response}
            onRetry={() => this.retryExport()}
          />
        </div>
        <div className="text-right text-small">
          {hasUnsavedEdits && 'You must save changes to preview as PDF.'}
        </div>
      </div>
    );
  }
}

TemplateDocumentVersionDownloadButton.propTypes = {
  documentVersionId: React.PropTypes.string.isRequired,
  hasUnsavedEdits: React.PropTypes.bool,
};

export function mapStateToProps(state, props) {
  return {
    response: state.responses.getIn(['renderAdminDocumentVersion', props.documentVersionId]),
  };
}

export default connect(
  mapStateToProps,
  { renderAdminDocumentVersion, resetResponse, retryExportAndResetPollProgress }
) (TemplateDocumentVersionDownloadButton);
