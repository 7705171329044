import React from 'react';
import { connect } from 'react-redux';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEditCreate from '^/components/admin/content/edit-create/AdminEditCreate';
import AdminChangeLog, { reloadChangeLog } from '^/components/admin/content/change-log/AdminChangeLog';

import {
  ADMIN_REMINDER_CREATE,
  ADMIN_REMINDER_MODEL,
  ADMIN_REMINDER_EDIT_FIELDS,
} from './reminders';


export const EditReminders = function ({ reminderId }) {
  return (
    <div>
      <AdminContainer title="Reminders">
        <AdminEditCreate
          itemId={reminderId}
          controlName={ADMIN_REMINDER_CREATE}
          onComplete={reloadChangeLog('ComplianceTemplateTask', reminderId)}
          collectionName={ADMIN_REMINDER_CREATE}
          model={ADMIN_REMINDER_MODEL}
          fields={ADMIN_REMINDER_EDIT_FIELDS}
          transform={fieldConfig => fieldConfig.setIn(['sector', 'read_only'], true)}
          title="Edit Reminder"
        />
      </AdminContainer>
      <AdminContainer>
        <AdminChangeLog model="ComplianceTemplateTask" id={reminderId} />
      </AdminContainer>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    reminderId: props.params.uuid,
  };
}

export default connect(mapStateToProps)(EditReminders);
