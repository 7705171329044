import React from 'react';
import { connect } from 'react-redux';
import { fromJS, List } from 'immutable';

import { setPendingUploadInForm } from '^/actions/actions';
import { updatePracticeMember } from '^/actions/actionSequences';
import { loadItem } from '^/actions/items';
import { getAllCollection } from '^/actions/collections';
import { ROLES, STAFF_PRACTICE } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';
import { isLoggedOnUserPracticeAdmin } from '^/stateHelpers';
import { STATUS_TYPES } from '^/models/user';

import Loading from '^/components/app/content/Loading';
import PureComponent from '^/components/common/PureComponent';
import EditMemberModalForm from './EditMemberModalForm';


export class EditMemberModalContent extends PureComponent {
  componentWillMount() {
    this.props.getAllCollection(ROLES);
  }

  onSubmit(data) {
    const { member } = this.props;
    const memberId = member.get('id');
    const _data = fromJS(data)
      .setIn(['user', 'id'], member.getIn(['user', 'id']))
      .setIn(['user', 'staffdetail', 'id'], member.getIn(['user', 'staffdetail', 'id']))
      .toJS();
    this.props.updatePracticeMember(this.props.practiceId, memberId, _data);
  }

  getInitialValues() {
    return this.props.member
      .updateIn(['user', 'staffdetail', 'roles'], roles => roles.map(role => role.get('id')))
      .toJS();
  }

  render() {
    const { response, rolesResponse, allRoles, isPracticeAdmin, member } = this.props;

    if (isPending(rolesResponse)) {
      return <Loading />;
    }

    return (
      <EditMemberModalForm
        onSubmit={this.onSubmit.bind(this)}
        response={response}
        allRoles={allRoles}
        setPendingUploadInForm={this.props.setPendingUploadInForm}
        initialValues={this.getInitialValues()}
        isPracticeAdmin={isPracticeAdmin}
        isInvited={member.getIn(['user', 'status']) === STATUS_TYPES.INVITED}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    isPracticeAdmin: isLoggedOnUserPracticeAdmin(state),
    allRoles: state.collections.getIn([ROLES, 'items'], List()),
    rolesResponse: state.responses.getIn(['getCollection', ROLES]),
    response: state.responses.getIn(['updateItem', STAFF_PRACTICE]),
  };
}

export default connect(
  mapStateToProps,
  {
    loadItem,
    updatePracticeMember,
    setPendingUploadInForm,
    getAllCollection,
  }
)(EditMemberModalContent);
