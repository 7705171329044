import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { Chart as GChart } from 'react-google-charts';

import { updateChartWidth } from '^/actions/actions';
import PureComponent from '^/components/common/PureComponent';


export class Chart extends PureComponent {

  updateWidth() {
    const wrapper = ReactDOM.findDOMNode(this.refs.wrapper);
    const width = wrapper ? wrapper.getBoundingClientRect().width : '100%';
    this.props.updateChartWidth(this.props.graph_id, width);
  }

  componentWillUnmount () {
    try {
      window.removeEventListener('resize', this.updateWidth.bind(this));
    } catch (e) {
      if (window.console) {
        // eslint-disable-next-line no-console
        console.warn('Failed to unbind chart resize');
      }
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateWidth.bind(this));
    this.updateWidth();
  }

  render () {
    return (
      <div ref="wrapper" className="chart-wrapper print-auto-margin-horizontal">
        <GChart {...this.props} />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    width: state.ui.getIn(['chartWidth', props.graph_id]) || props.width
  };
}


export default connect(mapStateToProps, { updateChartWidth })(Chart);
