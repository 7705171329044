import { createCachedSelector } from 're-reselect';

import { BaseItem, CachedItem } from '^/components/app/healthcheck/types';
import { StoreState } from '^/store/types';
import {
  AuditCategoriesMerged,
  AuditResponse,
  AuditReviewCategory,
  AuditReviewScores,
  Task,
} from '^/components/app/digital-tools/audit/types';

// export const selecthasCreateAuditPermissions = createSelector(
//   selectNonImmutableUserProfile,
//   userProfile =>
//     Boolean(
//       userProfile &&
//         PRACTICE_ADMIN_TYPES.includes(
//           userProfile.user_type_in_current_practice
//         ) &&
//         userProfile.staffdetail?.current_practice.memberships.find(
//           ({ membership }) => membership === MembershipType.ICAPP_NO_CYCLE
//         )
//     )
// ); TO BE USED SHORTLY

// const getInProgressAudit = (userProfile: CurrentUserProfile | null) => {
//   if (userProfile) return {} as Audit; //FIX ME
// };

// export const selectInProgressAudit = createSelector(
//   selectNonImmutableUserProfile,
//   userProfile => getInProgressAudit(userProfile)
// );

// export const selectCurrentPracticeAudit = createSelector(
//   selectInProgressAudit,
//   selectCurrentPractice,
//   (inProgressAudit, currentPractice) => {
//     if (inProgressAudit) {
//       return {
//         ...inProgressAudit,
//         user: {
//           ...inProgressAudit.user,
//           name: currentPractice
//             .get('members')
//             .find(
//               (member: Map<string, unknown>) =>
//                 member.get('id') === inProgressAudit.user.id,
//               undefined,
//               Map()
//             )
//             .get('name'),
//         },
//       };
//     }
//   }
// );

export const selectCategoryIndexById = createCachedSelector(
  (categories: AuditCategoriesMerged<BaseItem> | null) => categories,
  (_categories: AuditCategoriesMerged<BaseItem> | null, categoryId: string) =>
    categoryId,
  (categories, categoryId) =>
    categories?.findIndex(category => category.id === categoryId)
)((_categories, categoryId) => categoryId);

export const selectNextCategoryId = createCachedSelector(
  selectCategoryIndexById,
  (categories: AuditCategoriesMerged<BaseItem> | null) => categories,
  (categoryIndex, categories) =>
    categoryIndex !== undefined && categories
      ? categories[categoryIndex + 1]?.id
      : null
)((_categories, categoryId) => categoryId);

export const selectPrevCategoryId = createCachedSelector(
  selectCategoryIndexById,
  (categories: AuditCategoriesMerged<BaseItem> | null) => categories,
  (categoryIndex, categories) =>
    categoryIndex !== undefined && categories
      ? categories[categoryIndex - 1]?.id
      : null
)((_categories, categoryId) => categoryId);

export const selectAuditById = (
  state: StoreState,
  auditId: string
): CachedItem<AuditResponse> | null => {
  const auditById = state.audit.auditById;
  if (auditById) {
    return auditById[auditId];
  }
  return null;
};

export const selectAuditScoresById = (
  state: StoreState,
  auditId: string
): AuditReviewScores | null => {
  const reviewScoresById = state.audit.reviewScoresById;
  if (reviewScoresById) {
    return reviewScoresById[auditId];
  }
  return null;
};

export const selectAuditTasksById = (
  state: StoreState,
  auditId: string
): readonly Task[] | null => {
  const reviewTasksById = state.audit.reviewTasksById;
  if (reviewTasksById) {
    return reviewTasksById[auditId];
  }
  return null;
};

export const selectAuditCategoriesById = (
  state: StoreState,
  auditId: string
): readonly AuditReviewCategory[] | null => {
  const reviewCategoriesById = state.audit.reviewCategoriesById;
  if (reviewCategoriesById) {
    return reviewCategoriesById[auditId];
  }
  return null;
};
