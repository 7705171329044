import React from 'react';
import { Link } from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes';


export const TaskActivityDisplay = ({ audit_type }) =>
  <div>
    <h2>Related Digital Audit</h2>
      <Link
        className="inline-link"
        to={{pathname: '/page/tools/audit/', query: {type: audit_type.get('id')}}}
      >
        {audit_type.get('name')}
      </Link>
    <hr className="thin" />
  </div>;

TaskActivityDisplay.propTypes = {
  audit_type: ImmutablePropTypes.map,
};

export default TaskActivityDisplay;
