import React from 'react';
import { reduxForm } from 'redux-form';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { uploadFile } from '^/actions/actionSequences';
import { hasSucceeded } from '^/consts/responseStates';
import LiveButton from '^/components/app/forms/LiveButton';
import PureComponent from '^/components/common/PureComponent';
import {
  renderNotesField,
  practiceMemberField,
  renderEquipmentTrainingProvidedField,
  renderCompleteDateField,
  renderCompleteTimeField,
  renderRelatedTasksField,
  renderCheckboxField,
  renderMultipleFileUploadField,
  renderEvacuationDurationField
} from '^/components/app/digital-tools/records/digital-records/forms/FormFields';

export const FIRE_DRILL_FORM = 'FIRE_DRILL_FORM';

export class FireDrillForm extends PureComponent {
  render() {
    const {
      handleSubmit,
      fields,
      response,
      practiceMembers, 
      isEditing,
      isArchived,
      staff_tasks,
      handleArchive,
      archiveDigitalRecordResponse,
      updateDigitalRecordResponse,
      createDigitalRecordResponse,
      uploadFileResponse,
    } = this.props;

    const {
      notes,
      equipment_training_provided,
      were_patients_involved,
      completed_time,
      completed_date,
      assign_to,
      related_files,
      evacuation_duration_mins,
      evacuation_duration_secs,
    } = fields;

    const ALL_PRACTICE_MEMBERS_OPTIONS = practiceMembers.map((member) => {
      return {
        name: member.getIn(['user', 'staffdetail', 'full_name']),
        id: member.getIn(['user', 'staffdetail', 'id']),
      }
    }).toJS()

    return (
      <form onSubmit={handleSubmit}>
        {renderCompleteDateField(completed_date, response, isArchived)}
        {renderCompleteTimeField(completed_time, response, isArchived)}
        {renderEquipmentTrainingProvidedField(
          equipment_training_provided,
          response,
          isArchived
        )}
        {renderCheckboxField(
          were_patients_involved,
          'Were patients involved?',
          response,
          isArchived
        )}
        {practiceMemberField(
          assign_to,
          ALL_PRACTICE_MEMBERS_OPTIONS,
          response,
          isArchived
        )}
        {renderMultipleFileUploadField(
          related_files,
          'Add Supporting Documents:',
          handleSubmit,
          this.props.uploadFile,
          uploadFileResponse,
          FIRE_DRILL_FORM
        )}
        {renderEvacuationDurationField(evacuation_duration_mins, evacuation_duration_secs)}
        {renderRelatedTasksField(staff_tasks, isArchived)}
        {renderNotesField(notes, response, isArchived)}
        {!isEditing && !isArchived && (
          <div className="form-group">
            <div className="col-1-3">
              <LiveButton
                pendingMessage={'Creating...'}
                response={createDigitalRecordResponse}
                className="btn-default pd-2"
              >
                Save
              </LiveButton>
            </div>
          </div>
        )}
        {!isArchived && isEditing && (
          <div className="form-group">
            <div className="col-2-3">
              <LiveButton
                pendingMessage={'Archiving...'}
                response={archiveDigitalRecordResponse}
                className="btn-warning pd-2"
                onClick={handleArchive}
              >
                Archive
              </LiveButton>
            </div>
            <div className="col-1-3">
              <LiveButton
                pendingMessage={'Saving...'}
                response={updateDigitalRecordResponse}
                className="btn-default pd-2"
              >
                Save
              </LiveButton>
            </div>
          </div>
        )}
        {hasSucceeded(updateDigitalRecordResponse) && (
          <div className="form-group mb-2">
            <p className="alert mt-1 alert-success">Successfully updated!</p>
          </div>
        )}
        {hasSucceeded(archiveDigitalRecordResponse) && (
          <div className="form-group mb-2">
            <p className="alert mt-1 alert-warning">Successfully archived!</p>
          </div>
        )}
      </form>
    );
  }
};

FireDrillForm.propTypes = {
  fields: React.PropTypes.object,
  handleSubmit: React.PropTypes.func.isRequired,
  isEditing: React.PropTypes.bool,
  isArchived: React.PropTypes.bool,
  practiceMembers: ImmutablePropTypes.list,
  response: ImmutablePropTypes.map,
  archiveDigitalRecordResponse: ImmutablePropTypes.map,
  updateDigitalRecordResponse: ImmutablePropTypes.map,
};

export function validate(values) {
  const {
    completed_date,
    assign_to,
  } = values;

  const errors = {};

  if (!completed_date) {
    errors.completed_date = "Please provide a completed date.";
  }

  if (!assign_to) {
    errors.assign_to = "Please provide a team member in charge.";
  }
  return errors;
}


export function mapStateToProps(state) {
  return {
    uploadFileResponse: state.responses.get('uploadFile'),
  };
}

export const FormifiedFireDrillForm = reduxForm({
  form: FIRE_DRILL_FORM,
  fields: [
    'completed_time',
    'completed_date',
    'equipment_training_provided',
    'were_patients_involved',
    'notes',
    'assign_to',
    'related_files',
    'evacuation_duration_mins',
    'evacuation_duration_secs',
  ],
  validate,
})(FireDrillForm);

export default connect(
  mapStateToProps,
  { uploadFile}
)(FormifiedFireDrillForm);
