import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { expandMailtos } from '^/utils';

import TitleAndDetailsViewBar from '^/components/app/tasks/enduser/TitleAndDetailsViewBar';
import CompletionNotes from '^/components/app/tasks/enduser/CompletionNotes';
import CompletedBy from '^/components/app/activities/CompletedBy';
import TaskDocuments from '^/components/app/tasks/TaskDocuments';
import TaskActivityDisplay from '^/components/app/tasks/TaskActivityDisplay';
import TaskDigitalAuditDisplay from '^/components/app/tasks/TaskDigitalAuditDisplay';
import ActivityTaskAssigneeDocumentUpload
 from '^/components/app/activities/documents/ActivityTaskAssigneeDocumentUpload';

export const ViewEndUserTask = ({myTask, removeFile, onMarkCompleteComplete, togglePrivateDocument, userId}) => {
  const { entry, related_to, self_assignee, activity, audit_type } = myTask.toObject();
  const { id, is_completed, completion_notes, uploaded_files, attached_documents } = self_assignee.toObject();
  const taskId = myTask.get('id');

  return (
    <div>
      <div className="mt-1 ml-1">
        <p className="breadcrumb">{myTask.get('group_task') ? 'GROUP TASK:' : 'TASK:'}</p>

        <TitleAndDetailsViewBar task={myTask} />

        <hr />

        <div className="row">

          <div className="col-3-4">
            {related_to && (
              <div>
                <h5>Related to</h5>
                <p>{related_to.get('name')}</p>
              </div>
            )}
            <h5>Description</h5>
            <p className="pre-wrap">{expandMailtos(entry)}</p>


            <TaskDocuments
              task={myTask}
              attachedDocuments={attached_documents}
              uploadedFiles={uploaded_files}
              removeFile={(file) => removeFile(id, taskId, file)}
              isCompleted={is_completed}
              userId={userId}
              togglePrivateDocument={(file) => togglePrivateDocument(id, taskId, file)}
            />

            {activity &&
              <TaskActivityDisplay activity={activity} />
            }

            {audit_type &&
              <TaskDigitalAuditDisplay audit_type={audit_type} />
            }

            {!is_completed && (
              <ActivityTaskAssigneeDocumentUpload taskAssigneeId={id} taskId={taskId} />
            )}

            <CompletionNotes
              id={id}
              isCompleted={is_completed}
              value={completion_notes}
              onMarkCompleteComplete={onMarkCompleteComplete}
            />

          </div>

          <div className="col-1-4">
            <CompletedBy activity={self_assignee} />
          </div>

        </div>
      </div>
    </div>
  );
};

ViewEndUserTask.propTypes = {
  myTask: ImmutablePropTypes.map.isRequired,
  removeFile: React.PropTypes.func.isRequired,
  togglePrivateDocument: React.PropTypes.func.isRequired,
  userId: React.PropTypes.string.isRequired,
};

export default ViewEndUserTask;
