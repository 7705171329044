import React from 'react';
import moment from 'moment';

import { REPETITION_INTERVALS_TEXT, calculateNextDueDate } from '^/models/urgency';
import { formatDate, formatDateOrNull } from '^/utils';

import FormError from '^/components/app/forms/FormError';
import DateInput from '^/components/app/forms/DateInput';
import Icon from '^/components/app/content/Icon';
import { INCLUDED, DUE_DATE, DATE_LAST_DONE, NEVER_DONE, SECURITY_AUDIT_START_DAY } from '^/consts/account-set-up/fieldNames';

const formatAuditDate = date => formatDateOrNull(date, 'DD/MM/YYYY', '--/--/----');

export const CompulsoryTemplateAuditOrAssessmentField = ({fields, auditOrAssessment, cycleStartDate, response, isReview, isAudit}) => {
    const field = fields[auditOrAssessment.get('id')];
    const startDayField = isAudit && fields[SECURITY_AUDIT_START_DAY];
    const nextDueDate = calculateNextDueDate(
        field[DATE_LAST_DONE].value,
        auditOrAssessment,
        moment(cycleStartDate),
        (isAudit && startDayField && startDayField.value) || 1,
        field[NEVER_DONE].value
    );

    return (
        <tr>
        {isAudit && 
        <td>
        <Icon type="check-square" className="inline-icon" />
        <FormError response={response} formKeyPath={[auditOrAssessment.get('id'), INCLUDED]} />
        </td>
        }
        <td>
            <label 
            htmlFor={auditOrAssessment.get('id')} 
            title={auditOrAssessment.getIn(['template_activity', 'help_text'])}
            >
            {auditOrAssessment.getIn(['template_activity', 'name'])}
            </label>
        </td>

        {isReview && (
            <td colSpan={2}>
            {field[NEVER_DONE].value
                ? 'Not done'
                : formatAuditDate(field[DATE_LAST_DONE].value)
            }
            </td>
        )}

        {!isReview && [
            <td key={0}>
            <DateInput {...field[DATE_LAST_DONE]} maxDate={moment()} disabled={field[NEVER_DONE].value} />
            <FormError response={response} formKeyPath={[auditOrAssessment.get('id'), DUE_DATE]} />
            <FormError error={field[DATE_LAST_DONE] && field[DATE_LAST_DONE].touched && field[DATE_LAST_DONE].error} />
            </td>,
            <td key={1} className="no-wrap">
            <input type="checkbox" {...field[NEVER_DONE]} id={auditOrAssessment.get('id') + NEVER_DONE} />
            <label className="ml-1-4" htmlFor={auditOrAssessment.get('id') + NEVER_DONE}>Not done</label>
            </td>
        ]}

        {isReview ? (
            <td>
            <DateInput {...field[DUE_DATE]} minDate={moment(cycleStartDate)} />
            <FormError response={response} formKeyPath={[auditOrAssessment.get('id'), DUE_DATE]} />
            <FormError error={field[DUE_DATE] && field[DUE_DATE].touched && field[DUE_DATE].error} />
            </td>
        ) : (
            <div>
            {isAudit ? 
                (<td className="no-wrap text-center">{formatAuditDate(nextDueDate)}</td>)
                :
                (<td className="no-wrap text-center">{nextDueDate ? formatDate(nextDueDate) : '-- -- ----'}</td>)
            }
            </div>
        )}

        <td className={isAudit && "no-wrap"}>
            {REPETITION_INTERVALS_TEXT.get(auditOrAssessment.get('repetition_interval'), 'Unknown')}
        </td>
        </tr>
    );
};


export const OptionalTemplateAuditOrAssessmentField = ({field, auditOrAssessment, response}) => (
    <tr>
      <td>
        <input type="checkbox" {...field[INCLUDED]} id={auditOrAssessment.get('id')} />
        <FormError response={response} formKeyPath={[auditOrAssessment.get('id'), INCLUDED]} />
      </td>
      <td>
        <label 
            htmlFor={auditOrAssessment.get('id')} 
            title={auditOrAssessment.getIn(['template_activity', 'help_text'])}
        >
          {auditOrAssessment.getIn(['template_activity', 'name'])}
        </label>
      </td>
  
      <td className="no-wrap text-center">
        {formatAuditDate(auditOrAssessment.get('date_last_done'))}
      </td>
  
      <td>
        <DateInput {...field[DUE_DATE]} disabled={!field[INCLUDED].value} minDate={moment()} />
        <FormError response={response} formKeyPath={[auditOrAssessment.get('id'), DUE_DATE]} />
        <FormError error={field[DATE_LAST_DONE] && field[DATE_LAST_DONE].touched && field[DATE_LAST_DONE].error} />
      </td>
    </tr>
  );

export const OptionalTemplateSecurityAuditOrAssessmentFieldsHeader = ({size, isAudit}) => (
    <tr>
        <th colSpan={2} className="text-left audit-title">
        <strong>Optional {isAudit ? "Audits" : "Assessments"} ({size})</strong>
        </th>
        <th className={isAudit && "no-wrap"}>Date last done</th>
        <th className="audit-next-due">Date scheduled</th>
    </tr>
    );

export const CompulsoryTemplateSecurityAuditOrAssessmentFieldsHeader = ({size, isAudit}) => (
    <tr>
        <th colSpan={2} className="text-left audit-title">
        <strong>Compulsory {isAudit ? "Audits" : "Assessments"} ({size})</strong>
        </th>
        <th className={isAudit && "no-wrap"} colSpan={isAudit && 2}>Date last done</th>
        <th className="audit-next-due">Next Due</th>
        <th>Repetition</th>
    </tr>
    );

const NoItemsMessage = ({optional, isAudit}) => (
    <p>There are no {optional ? 'optional' : 'compulsory'} {isAudit ? 'audits relevant to' : 'risk assessments for the configuration of'} your practice.</p>
    );

export const OptionalTemplateSecurityAuditOrAssessmentFields = ({fields, auditsOrAssessments, response, isAudit}) => (
    <div className="mt-1 small-text pl-1 pr-1">
        <table className="audit-table selectable">
        <thead>
            <OptionalTemplateSecurityAuditOrAssessmentFieldsHeader size={auditsOrAssessments.size} isAudit={isAudit} />
        </thead>
        <tbody>
            {auditsOrAssessments.size > 0 ? (
            auditsOrAssessments.map(item =>
                <OptionalTemplateAuditOrAssessmentField
                key={item.get('id')}
                response={response}
                auditOrAssessment={item}
                field={fields[item.get('id')]}
                />
            )
            ) : (
            <tr><td colSpan={6}>
                <NoItemsMessage optional />
            </td></tr>
            )}
        </tbody>
        </table>
    </div>
    );

export const CompulsoryTemplateSecurityAuditOrAssessmentFields = ({fields, auditsOrAssessments, cycleStartDate, response, isReview, isAudit}) => (
    <div className="mt-1 small-text pl-1 pr-1">
        <table className="audit-table">
        <thead>
            <CompulsoryTemplateSecurityAuditOrAssessmentFieldsHeader size={auditsOrAssessments.size} isAudit={isAudit} />
        </thead>
        <tbody>
            {auditsOrAssessments.size > 0 ? (
            auditsOrAssessments.map(item =>
                <CompulsoryTemplateAuditOrAssessmentField
                key={item.get('id')}
                response={response}
                auditOrAssessment={item}
                fields={fields}
                cycleStartDate={cycleStartDate}
                isReview={isReview}
                isAudit={isAudit}
                />
            )
            ) : (
            <tr><td colSpan={4}>
                <NoItemsMessage />
            </td></tr>
            )}
        </tbody>
        </table>
    </div>
    );
