import React from 'react';
import { connect } from 'react-redux';

import PureComponent from '^/components/common/PureComponent';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminList from '^/components/admin/content/perms/AdminList';

import {
  ADMIN_AUDIT_MODEL,
  ADMIN_AUDIT_ROW_FIELDS,
} from '^/components/admin/audits/audit';


export class AuditsAdmin extends PureComponent {

  render() {
    return (
      <div>
        <AdminContainer>
          <AdminList
            model={ADMIN_AUDIT_MODEL}
            fields={ADMIN_AUDIT_ROW_FIELDS}
            title="Audit Types"
          />
        </AdminContainer>
      </div>
    );
  }
};

export default connect(null, {})(AuditsAdmin);
