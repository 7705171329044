import React from 'react';
import { Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { setSortBy, setSortByReversed, setTablePageSize } from '^/actions/actions';
import { getCollection, deleteItem, clearCollectionFilter } from '^/actions/collections';
import ListItems from '^/components/app/tables/ListItems';
import Loading from '^/components/app/content/Loading';
import PureComponent from '^/components/common/PureComponent';
import { isPending } from '^/consts/responseStates';

export const PAGE_SIZE = 10;

export class TableList extends PureComponent {
  componentDidMount() {
    this.loadItems();
  }

  componentDidUpdate(prevProps) {
    const { recordType } = this.props;
    if (recordType && recordType !== prevProps.recordType) {
      this.loadItems('', 1, null, Map({type: recordType}));
    }
  }

  componentWillUnmount() {
    const { listName } = this.props;
    this.props.clearCollectionFilter(listName);
  }

  search(searchString) {
    const { sortBy, sortByReversed } = this.props;
    const orderBy = sortBy && sortByReversed ? '' + sortBy : sortBy;
    this.loadItems(searchString, 1, orderBy);
  }

  loadPage(page) {
    const { sortBy, sortByReversed, collection, filters } = this.props;
    const orderBy = sortBy && sortByReversed ? '' + sortBy : sortBy;
    this.loadItems(collection && collection.get('searchString', ''), page, orderBy, filters);
  }

  setPageSize(pageSize) {
    const { listName} = this.props;
    this.props.setTablePageSize(listName, pageSize);
    this.loadItems('', 1, null, null, pageSize);
  }

  applyFilter(event) {
    const newFilters = this.buildFilters(event);
    this.loadItems('', 1, null, newFilters);
  }

  buildFilters(event) {
    const filterKey = event.target.id;
    const filterValue = event.target.value;
    const filters = this.props.filters || Map();

    return filterValue === '' ? filters.delete(filterKey) : filters.set(filterKey, filterValue);
  }

  loadItems(searchString = '', page = 1, ordering = null, newFilters = null, newPageSize = null) {
    const { listName, model, defaultFilters, isLoading} = this.props;
    const filters = newFilters ? newFilters.merge(defaultFilters) : this.props.filters;
    const pageSize = newPageSize || this.props.pageSize
    const applyFilters = filters

    if (!isLoading) {
      this.props.getCollection(
        model,
        {
          page, pageSize: pageSize, searchString, shouldAppend: false,
          additionalParams: {ordering},
          filters: applyFilters.toJS(),
        },
        listName
      );
    }
  }

  removeItem(itemId) {
    const { model, listName, onRemove } = this.props;
    if (window.confirm('Are you sure you want to remove?')) {
      this.props.deleteItem(model, itemId, listName);
      if (onRemove) {
        onRemove();
      }
    }
  }

  setSortBy(sortPath) {
    const { searchString, page } = this.props.collection.toObject();
    const { listName, sortByReversed, sortBy } = this.props;
    const shouldReverse = sortPath === sortBy && !sortByReversed;
    this.props.setSortBy(listName, sortPath);
    this.props.setSortByReversed(listName, shouldReverse);
    this.loadItems(searchString, page, shouldReverse ? sortPath : '-' + sortPath);
  }

  render() {
    const { collection, removeItemResponse, recordType, filters } = this.props;
    if (!collection || isPending(removeItemResponse)) {
      return <Loading />;
    }
    
    return (
      <ListItems
        {...Object.assign({}, this.props, {
          removeItem: itemId => this.removeItem(itemId),
          setSortBy: sortPath => this.setSortBy(sortPath),
          search: searchString => this.search(searchString),
          loadPage: page => this.loadPage(page),
          setPageSize: pageSize => this.setPageSize(pageSize),
          applyFilter: event => this.applyFilter(event),
          recordType: recordType,
          appliedFilters: filters.toJS(),
        })}
      />
    );
  }
}

export function mapStateToProps(state, props) {
  const listName = props.listName || (props.model);
  const collection = state.collections.get(listName);
  const defaultFilters = props.defaultFilters || Map();
  const appliedFilters = collection ? Map(collection.get('filters')) : Map();
  return {
    collection,
    filters: defaultFilters.merge(appliedFilters),
    isLoading: isPending(state.responses.getIn(['getCollection', listName])),
    sortBy: state.ui.getIn(['sortBy', listName]),
    sortByReversed: state.ui.getIn(['sortByReversed', listName]),
    listName,
    pageSize: state.ui.getIn(['tablePageSize', listName]) || props.pageSize || PAGE_SIZE,
    removeItemResponse: state.responses.getIn(['deleteItem', listName]),
  };
}

TableList.propTypes = {
  model: React.PropTypes.string,
  fields: ImmutablePropTypes.list.isRequired,
  hidePagination: React.PropTypes.bool,
  removeItem: React.PropTypes.func,
  onRemove: React.PropTypes.func,
};

export default connect(mapStateToProps, {
  getCollection,
  setSortBy,
  setSortByReversed,
  setTablePageSize,
  deleteItem,
  clearCollectionFilter,
})(TableList);
