import React from 'react';
import classNames from 'classnames';

interface OwnProps {
  className?: string;
}

type Props = OwnProps;

const Loading = ({ className }: Props) => (
  <div className={classNames(['indicator', 'loading'], className)}>
    <svg width="36" height="36" viewBox="0 0 36 36">
      <circle cx="18" cy="18" r="17" strokeWidth="2" fill="none" />
      <circle cx="18" cy="18" r="11" strokeWidth="2" fill="none" />
    </svg>
  </div>
);

export default Loading;
