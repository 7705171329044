import React from 'react';
import { connect } from 'react-redux';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import AdminChangeLog, { reloadChangeLog } from '^/components/admin/content/change-log/AdminChangeLog';

import {
  ADMIN_HELP_CREATE,
  ADMIN_HELP_MODEL,
  ADMIN_HELP_EDIT_FIELDS
} from './help';


const EditHelp = function ({ helpId }) {
  return (
    <div>
      <AdminContainer title="Help">
        <AdminEdit
          itemId={helpId}
          controlName={ADMIN_HELP_CREATE}
          collectionName={ADMIN_HELP_CREATE}
          onComplete={reloadChangeLog('HelpItem', helpId)}
          transform={fieldConfig => fieldConfig.setIn(['sector', 'read_only'], true)}
          model={ADMIN_HELP_MODEL}
          fields={ADMIN_HELP_EDIT_FIELDS}
          title="Edit Help"
        />
      </AdminContainer>
      <AdminContainer>
        <AdminChangeLog model="HelpItem" id={helpId} />
      </AdminContainer>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    helpId: props.params.uuid,
  };
}

export default connect(mapStateToProps)(EditHelp);
