import React from 'react';

import { SSO_FORGOT_PASSWORD_URL } from '^/consts/single-sign-on';
import NoAuth from '^/components/app/users/login/NoAuth';
import LoggedOutLogo from '^/components/app/users/LoggedOutLogo';

const ResetPassword = () => (
  <div>
    <LoggedOutLogo />
    <NoAuth>
      <div className="form-padded">
        <p className="mt-1">
          This password reset link is no longer valid. Please{' '}
          <a href={SSO_FORGOT_PASSWORD_URL}>request a new password reset</a>.
        </p>
      </div>
    </NoAuth>
  </div>
);

export default ResetPassword;
