import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { getItemOptions, loadItem } from '^/actions/items';
import { openInheritGroupsFunctionalityModal } from '^/actions/modals';
import PureComponent from '^/components/common/PureComponent';
import { isPending } from '^/consts/responseStates';
import { MODELS } from '^/permissions/capabilities';
import { getCollection } from '^/actions/collections';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import AdminChangeLog from '^/components/admin/content/change-log/AdminChangeLog';
import AdminManyRelatedField from '^/components/admin/content/perms/AdminManyRelatedField';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';
import Loading from '^/components/app/content/Loading';

import {
  ADMIN_PRACTICE_GROUP_CREATE,
  ADMIN_PRACTICE_GROUP_MODEL,
  ADMIN_PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_MODEL,
  ADMIN_PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_OPTIONS,
  ADMIN_PRACTICE_GROUP_EDIT_FIELDS,
  ADMIN_PRACTICE_GROUP_VIEW_FIELDS,
  ADMIN_STAFF_PRACTICE_GROUPS,
  ADMIN_STAFF_PRACTICE_GROUPS_MODEL,
  ADMIN_STAFF_PRACTICE_GROUP_ROW_FIELDS,
  ADMIN_STAFF_PRACTICE_GROUP_CREATE_FIELDS,
  ADMIN_PRACTICE_GROUP_PRACTICE_ROW_FIELDS,
  ADMIN_PRACTICE_SUB_GROUPS,
  ADMIN_PRACTICE_SUB_GROUP_MODEL,
  ADMIN_PRACTICE_SUB_GROUP_FIELDS,
  ADMIN_PRACTICE_SUB_GROUP_CREATE_FIELDS,
  transformPracticeGroupConfig,
  PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_FIELDS,
  ADMIN_PRACTICE_AUDIT_ROW_FIELDS,
} from './practice';

export class EditPracticeGroup extends PureComponent {
  constructor(props) {
    super(props);
    this.reloadPracticeGroup = this.reloadPracticeGroup.bind(this);
    this.reloadPracticeSubGroups = this.reloadPracticeSubGroups.bind(this);
    this.openInheritGroupsFunctionalityModal = this.openInheritGroupsFunctionalityModal.bind(
      this
    );
  }

  componentWillMount() {
    this.getOptions();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.practiceGroupId !== newProps.practiceGroupId) {
      this.getOptions();
    }
  }

  getOptions() {
    this.props.getItemOptions(
      ADMIN_PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_MODEL,
      null,
      ADMIN_PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_OPTIONS
    );
  }

  reloadPracticeGroup() {
    const { practiceGroupId } = this.props;
    this.props.loadItem(ADMIN_PRACTICE_GROUP_MODEL, practiceGroupId);
  }

  reloadPracticeSubGroups() {
    const { practiceGroupId } = this.props;

    return getCollection(
      ADMIN_PRACTICE_SUB_GROUP_MODEL,
      { filters: { group: practiceGroupId } },
      ADMIN_PRACTICE_SUB_GROUPS
    );
  }

  openInheritGroupsFunctionalityModal(addedPracticeId, patchItemParams) {
    const { practiceGroupId } = this.props;

    this.props.openInheritGroupsFunctionalityModal(
      addedPracticeId,
      practiceGroupId,
      patchItemParams,
      'PRACTICE_GROUP_PRACTICES'
    );
  }

  reloadPracticeGroupAdmins() {
    const { practiceGroupId } = this.props;

    return getCollection(
      ADMIN_STAFF_PRACTICE_GROUPS_MODEL,
      { filters: { group: practiceGroupId } },
      ADMIN_STAFF_PRACTICE_GROUPS
    );
  }

  render() {
    const {
      practiceGroupId,
      practiceGroup,
      practiceGroupPracticeFeatureFlagOptions,
      isPendingPracticeGroupPracticeFeatureFlagOptionsResponse,
    } = this.props;

    if (isPendingPracticeGroupPracticeFeatureFlagOptionsResponse) {
      return (
        <AdminContainer title="Loading practice...">
          <Loading />
        </AdminContainer>
      );
    }

    return (
      <div>
        <AdminContainer title="Practice Group">
          <AdminEdit
            permsModel={MODELS.PRACTICE}
            readOnlyFields={ADMIN_PRACTICE_GROUP_VIEW_FIELDS}
            itemId={practiceGroupId}
            controlName={ADMIN_PRACTICE_GROUP_CREATE}
            collectionName={ADMIN_PRACTICE_GROUP_MODEL}
            transform={transformPracticeGroupConfig}
            model={ADMIN_PRACTICE_GROUP_MODEL}
            fields={ADMIN_PRACTICE_GROUP_EDIT_FIELDS}
            title="Edit Practice Group"
          />
        </AdminContainer>
        <AdminContainer title="Practice features (defaults for new practices)">
          {practiceGroup &&
            (practiceGroup.get('practice_feature_flag_defaults') ? (
              <AdminEdit
                className="wide-label"
                permsModel={MODELS.PRACTICE}
                controlName="PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS"
                model={
                  ADMIN_PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_MODEL
                }
                collectionName={
                  ADMIN_PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_MODEL
                }
                options={practiceGroupPracticeFeatureFlagOptions}
                fields={PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_FIELDS}
                itemId={practiceGroup.getIn(['practice_feature_flag_defaults'])}
                readOnlyFields={[]}
              />
            ) : (
              <AdminCreate
                className="wide-label"
                permsModel={MODELS.PRACTICE}
                controlName="PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS"
                model={
                  ADMIN_PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_MODEL
                }
                collectionName={
                  ADMIN_PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_MODEL
                }
                options={practiceGroupPracticeFeatureFlagOptions}
                fields={PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_FIELDS}
                transformDataToSubmit={data =>
                  Object.assign({}, data, {
                    practicegroup: practiceGroup.get('id'),
                  })
                }
                onComplete={this.reloadPracticeGroup}
                saveButtonText="Save"
              />
            ))}
        </AdminContainer>
        {practiceGroup && practiceGroup.get('practice_feature_flag_defaults') && (
          <AdminContainer className="mt-2">
            <AdminManyRelatedField
              addLabel="Assign Audits to a practice"
              itemId={practiceGroup.getIn(['practice_feature_flag_defaults'])}
              controlName="PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_ACTIVE_AUDITS"
              options={practiceGroupPracticeFeatureFlagOptions}
              model={ADMIN_PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_MODEL}
              fieldName={['active_audit_types']}
              fields={ADMIN_PRACTICE_AUDIT_ROW_FIELDS}
              listTitle="Digital Audits (default selection for new practices)"
              defaultLookupFilters={{
                sector: practiceGroup && practiceGroup.get('sector'),
              }}
            />
          </AdminContainer>
        )}
        <AdminContainer className="mt-2" title="Sub groups:">
          <AdminList
            noSearch
            permsModel={MODELS.PRACTICE}
            listName={ADMIN_PRACTICE_SUB_GROUPS}
            model={ADMIN_PRACTICE_SUB_GROUP_MODEL}
            fields={ADMIN_PRACTICE_SUB_GROUP_FIELDS}
            defaultFilters={Map({ group: practiceGroupId })}
          />
          <AdminCreate
            title="Create new sub group"
            permsModel={MODELS.PRACTICE}
            onComplete={this.reloadPracticeSubGroups}
            collectionName={ADMIN_PRACTICE_SUB_GROUPS}
            controlName={ADMIN_PRACTICE_SUB_GROUPS}
            model={ADMIN_PRACTICE_SUB_GROUP_MODEL}
            fields={ADMIN_PRACTICE_SUB_GROUP_CREATE_FIELDS}
            defaultValues={{ group: practiceGroupId }}
          />
        </AdminContainer>

        <AdminContainer className="mt-2" title="Group admins:">
          <AdminList
            noSearch
            permsModel={MODELS.PRACTICE}
            listName={ADMIN_STAFF_PRACTICE_GROUPS}
            model={ADMIN_STAFF_PRACTICE_GROUPS_MODEL}
            fields={ADMIN_STAFF_PRACTICE_GROUP_ROW_FIELDS}
            defaultFilters={Map({ group: practiceGroupId })}
          />
          <AdminCreate
            permsModel={MODELS.PRACTICE}
            onComplete={() => this.reloadPracticeGroupAdmins()}
            collectionName={ADMIN_STAFF_PRACTICE_GROUPS}
            controlName={ADMIN_STAFF_PRACTICE_GROUPS}
            model={ADMIN_STAFF_PRACTICE_GROUPS_MODEL}
            fields={ADMIN_STAFF_PRACTICE_GROUP_CREATE_FIELDS}
            defaultValues={{ group: practiceGroupId }}
            defaultFilters={Map({ group: practiceGroupId })}
            title="Add new group admin"
          />
        </AdminContainer>

        <AdminContainer className="mt-2">
          <AdminManyRelatedField
            permsModel={MODELS.PRACTICE}
            itemId={practiceGroupId}
            controlName="PRACTICE_GROUP_PRACTICES"
            model={ADMIN_PRACTICE_GROUP_MODEL}
            fieldName={['practices']}
            fields={ADMIN_PRACTICE_GROUP_PRACTICE_ROW_FIELDS}
            listTitle="Practices:"
            sortBy={['name']}
            defaultLookupFilters={{
              sector: practiceGroup && practiceGroup.get('sector'),
            }}
            addHook={this.openInheritGroupsFunctionalityModal}
          />
        </AdminContainer>

        <AdminContainer>
          <AdminChangeLog model="PracticeGroup" id={practiceGroupId} />
        </AdminContainer>
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  const practiceGroupId = props.params.uuid;
  const practiceGroup = state.items.get(ADMIN_PRACTICE_GROUP_MODEL);
  const practiceGroupPracticeFeatureFlagOptions = state.items.getIn(
    ['options', ADMIN_PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_OPTIONS],
    Map()
  );
  const practiceGroupPracticeFeatureFlagOptionsResponse = state.responses.getIn(
    [
      'getItemOptions',
      ADMIN_PRACTICE_GROUP_PRACTICE_FEATURE_FLAG_DEFAULTS_OPTIONS,
    ]
  );

  return {
    practiceGroupId,
    practiceGroup,
    practiceGroupPracticeFeatureFlagOptions,
    isPendingPracticeGroupPracticeFeatureFlagOptionsResponse: isPending(
      practiceGroupPracticeFeatureFlagOptionsResponse
    ),
  };
}

export default connect(mapStateToProps, {
  openInheritGroupsFunctionalityModal,
  getItemOptions,
  loadItem,
})(EditPracticeGroup);
