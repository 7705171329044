import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import moment from 'moment';

export default class Footer extends PureComponent {
  render () {
    return (
      <div className="footer">
        <div className="wrapper">
          <div className="row">
            <div className="col-1">
              &copy; {moment().year()} | iComply is a trading name registered trademark of CODEplan Limited, 
              part of the Agilio Software Group, registered at Unit 18, Jessops Riverside, 800 Brightside Lane, 
              Sheffield, England, S9 2RX | Registered in England and Wales | Company Number: 03927086 | VAT 865046024
            </div>
          </div>
        </div>
      </div>
    );
  }
}
