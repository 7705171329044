import React from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import PermissionsComponent from '^/components/app/perms/PermissionsComponent';
import PureComponent from '^/components/common/PureComponent';
import { setShowing, setCollapse } from '^/actions/actions';
import Switcher from '^/components/app/widgets/Switcher';
import { openCreateTaskModal } from '^/actions/modals';
import { isNotReadOnlyInCurrentPractice } from '^/models/user';

import EndUserTasksPage from './enduser/EndUserTasksPage';
import ManageTasksPage from './manage/ManageTasksPage';
import { Map } from 'immutable';
import { LIST_NAV_ID } from '^/components/app/widgets/CollapsibleLeftNav';

class TasksPage extends PureComponent {

  closeMenuAndOpenModal() {
    this.props.setCollapse(LIST_NAV_ID, true);
    this.props.openCreateTaskModal();
  }

  setShowManage(showManage) {
    const { pushRoute, params: { uuid } } = this.props;
    pushRoute(`/page/tasks/${showManage ? 'manage/' : ''}${uuid ? uuid + '/' : ''}`);
  }

  render() {
    const { params, location: { pathname } } = this.props;
    const showManage = pathname.indexOf('manage') > -1;
    const WhichTasksPage = showManage ? ManageTasksPage : EndUserTasksPage;

    return (
      <div className="wrapper">
        <WhichTasksPage params={params} >
          <PermissionsComponent hasPermission={isNotReadOnlyInCurrentPractice}>
            <button
              onClick={() => this.closeMenuAndOpenModal()}
              className="btn btn-default centered fullwidth mt-1 btn-task-menu"
            >
              Create new Task
            </button>
          </PermissionsComponent>
          <Switcher
            texts={['Assigned Tasks', 'Manage Tasks']}
            currentIndex={showManage ? 1 : 0}
            onSwitch={() => this.setShowManage(!showManage)}
          />
        </WhichTasksPage>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    collapse: state.ui.get('collapse', Map()),
    showing: state.ui.getIn(['showing', 'TASKS']),
  };
}

export default connect(
  mapStateToProps,
  { setShowing, openCreateTaskModal, setCollapse, pushRoute: routeActions.push }
) (TasksPage);
