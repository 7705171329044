import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { expandMailtos, isPracticesGroupAdmin } from '^/utils';
import PureComponent from '^/components/common/PureComponent';

import Icon from '^/components/app/content/Icon';
import Notes from '^/components/app/widgets/Notes';
import TitleAndDetailsEditBar from './TitleAndDetailsEditBar';
import TitleAndDetailsViewBar from '^/components/app/tasks/enduser/TitleAndDetailsViewBar';
import EditableFieldText from '^/components/app/widgets/EditableFieldText';
import PermissionsComponent from '^/components/app/perms/PermissionsComponent';
import { TASKS } from '^/consts/collectionKeys';
import { hasAnyAssigneeStarted, readOnlyOrAssignedToTaskPermission, getAnyAssigneeStarted } from '^/models/task';
import { ACTIVITY_STATUSES } from '^/models/activities';
import AssigneeStatuses from './AssigneeStatuses';
import CompletedBy from '^/components/app/activities/CompletedBy';
import TaskDocuments from '^/components/app/tasks/TaskDocuments';
import TaskActivityDisplay from '^/components/app/tasks/TaskActivityDisplay';
import TaskDigitalAuditDisplay from '^/components/app/tasks/TaskDigitalAuditDisplay';
import TaskError from './TaskError';


export class ViewManageTask extends PureComponent {

  confirmAndDelete() {
    if (window.confirm('Are you sure you want to delete this task?')) {
      this.props.delete();
    }
  }

  render() {
    const { task, practiceMembers, user, deleteTaskResponse, currentPractice } = this.props;
    const { related_to, status, entry, assignees, activity, audit_type } = task.toObject();
    const groupTaskLockedAtPracticeLevel = task.get('is_locked_at_practice_level');
    const isGroupAdmin = isPracticesGroupAdmin(currentPractice, user.get('id'));
    const cantEdit = (
      hasAnyAssigneeStarted(task)
      || !readOnlyOrAssignedToTaskPermission(task)(user)
      || (groupTaskLockedAtPracticeLevel && !isGroupAdmin)
    );

    const isCompleted = status === ACTIVITY_STATUSES.COMPLETED;

    return (
      <div className="mt-1 ml-1">
        <p className="breadcrumb">{task.get('group_task') ? 'GROUP TASK:' : 'TASK:'}</p>

        {cantEdit ? (
          <div>
            <TitleAndDetailsViewBar task={task} />
            {hasAnyAssigneeStarted(task) &&
              <div className="hint">
                Currently being worked on by {
                  getAnyAssigneeStarted(task)
                    .map(assignee => assignee.get('full_name')).join(', ')
                }
              </div>
            }
          </div>
        ) : (
          <TitleAndDetailsEditBar task={task} practiceMembers={practiceMembers} />
        )}

        <hr />

        <div className="row">
          <div className="col-3-4">
            {related_to && (
              <div>
                <h5>Related to</h5>
                <p>{related_to.get('name')}</p>
              </div>
            )}

            <h5>Description</h5>
            {cantEdit ? (
              <p className="pre-wrap">{expandMailtos(entry)}</p>
            ) : (
              <EditableFieldText key={task.get('id')} textarea object={task} model={TASKS} fieldName="entry">
                <p className="pre-wrap">{expandMailtos(entry)}</p>
              </EditableFieldText>
            )}

            <TaskDocuments
              task={task}
              isCompleted={isCompleted}
              userId={user.get('id')}
              uploadedFiles={isCompleted &&
                task.get('assignees').map(assignee => {
                  return assignee.get('uploaded_files');
                }).flatten(true)}
              attachedDocuments={isCompleted &&
                task.get('assignees').map(assignee => {
                  return assignee.get('attached_documents');
                }).flatten(true)}
              showNotes
            />

            {activity &&
              <TaskActivityDisplay activity={activity} />
            }

            {audit_type &&
              <TaskDigitalAuditDisplay audit_type={audit_type} />
            }

            {isCompleted && (
              <div>
                <Icon type="check" className="complete-icon" /> Completed
                {assignees.map((assignee, index) => {
                  const value = `${assignee.get('full_name')}: ${assignee.get('completion_notes')}`;
                  return (
                    <Notes key={index} emptyMessage="Please add some completion notes" value={value} />
                  );
                })}
              </div>
            )}
          </div>
          <div className="col-1-4">
            <AssigneeStatuses task={task} />

            {assignees.size === 1 && (
              <CompletedBy activity={assignees.first()} />
            )}

            <PermissionsComponent hasPermission={readOnlyOrAssignedToTaskPermission(task)} >
              {!cantEdit &&
                <button className="btn btn-default indented fullwidth mb-1" onClick={() => this.confirmAndDelete()}>
                  <Icon type="trash" className="button-icon" /> delete
                </button>
              }
            </PermissionsComponent>

            <TaskError response={deleteTaskResponse} deleteTask/>
          </div>
        </div>
      </div>
    );
  }
}

ViewManageTask.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
  task: ImmutablePropTypes.map.isRequired,
  practiceMembers: ImmutablePropTypes.list,
  delete: React.PropTypes.func,
};

export default ViewManageTask;
