import React from 'react';
import { reduxForm } from 'redux-form';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { isPending } from '^/consts/responseStates';
import { getIn } from '^/utils';

import Field from '^/components/admin/content/edit-create/dynamic-forms/Field';
import Messages from '^/components/admin/content/edit-create/dynamic-forms/Messages';


export const SubmitButton = ({readOnly, response, saveButtonText}) =>
  (!readOnly && !isPending(response)) ? (
    <input type="submit" value={saveButtonText || 'Save'} className="btn btn-primary btn-admin-form" />
  ) : (
    <noscript />
  );

function getErrors(response, fieldName) {
  const fieldNameItems = fieldName.split('.');
  return (
    response.getIn(['errors', fieldName]) ||
    response.getIn(['errors'].concat(fieldNameItems)) ||
    response.getIn(['errors', fieldNameItems[fieldNameItems.length - 1]])
  )
}

export const Form = ({
  fields,
  handleSubmit,
  fieldConfig,
  readOnly,
  hideMessages,
  saveButtonText,
  className,
  fieldClassName,
  customTopButton,
  title,
  response,
  setPendingUploadInForm,
  formName,
  transform,
  isEditing,
  values,
  customSubmitButtons,
  defaultFilters,
}) => (
  <form className={className} onSubmit={handleSubmit}>
    {customTopButton && customTopButton()}
    <h4>{title}</h4>
    {(transform ? transform(fieldConfig, fields) : fieldConfig).map(
      (config, fieldName) => (
        <Field
          key={fieldName}
          field={getIn(fields, fieldName.split('.'))}
          fieldConfig={config}
          readOnly={readOnly}
          className={fieldClassName}
          setUpload={setPendingUploadInForm}
          handleSubmit={handleSubmit}
          formName={formName}
          isEditing={isEditing}
          errors={response && getErrors(response, fieldName)}
          defaultFilters={defaultFilters}
        />
      )
    )}
    {customSubmitButtons && customSubmitButtons(values)}
    <SubmitButton
      readOnly={readOnly}
      response={response}
      saveButtonText={saveButtonText}
    />
    {!hideMessages && <Messages response={response} isEditing={isEditing} />}
  </form>
);

Form.propTypes = {
  formName: React.PropTypes.string.isRequired,
  className: React.PropTypes.string,
  title: React.PropTypes.string,
  fieldClassName: React.PropTypes.string,
  handleSubmit: React.PropTypes.func.isRequired,
  fieldConfig: ImmutablePropTypes.map.isRequired,
  response: ImmutablePropTypes.map,
  readOnly: React.PropTypes.bool,
  saveButtonText: React.PropTypes.string,
  customSubmitButtons: React.PropTypes.func,
  customTopButton: React.PropTypes.func,
  defaultFilters: React.PropTypes.map,
};

export default reduxForm({})(Form);
