import React from 'react';
import { Map, List, fromJS } from 'immutable';
import { connect } from 'react-redux';

import { getAllCollection } from '^/actions/collections';
import { ADMIN_AUDIT_CATEGORIES } from '^/consts/collectionKeys';
import { getItemOptions } from '^/actions/items';
import PureComponent from '^/components/common/PureComponent';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import { transformAuditQuestionCategoryOptions} from '^/components/admin/audits/utils';

import {
  AUDIT_QUESTION,
  ADMIN_AUDIT_QUESTION_MODEL,
  ADMIN_AUDIT_QUESTION_EDIT_FIELDS,
  ADMIN_AUDIT_QUESTION_OPTIONS,
  AUDIT_QUESTION_RESPONSE,
  ADMIN_AUDIT_QUESTION_RESPONSE_MODEL,
  ADMIN_AUDIT_QUESTION_RESPONSE_ROW_FIELDS,
  ADMIN_AUDIT_QUESTION_RESPONSE_CREATE_FIELDS
} from '^/components/admin/audits/audit';

import {PAGE_SIZE} from '^/components/admin/healthcheck/constants';


export class EditAuditQuestionAdmin extends PureComponent {
  componentDidMount() {
    const { auditQuestionId, itemOptions } = this.props;
    if (itemOptions.isEmpty()) {
      this.getItemOptions(auditQuestionId);
    }
    this.props.getAllCollection(ADMIN_AUDIT_CATEGORIES, {filters: {audit_question: auditQuestionId}});
  }

  getItemOptions(auditQuestionId) {
    this.props.getItemOptions(ADMIN_AUDIT_QUESTION_MODEL, auditQuestionId, ADMIN_AUDIT_QUESTION_OPTIONS);
  }

  render() {
    const {
      auditQuestionId,
      auditQuestionFilter,
      auditCategoryIds
    } = this.props;
    return (
      <div>
        <AdminContainer title="Audit Question">
          <AdminEdit
            readOnlyFields={fromJS([])}
            itemId={auditQuestionId}
            controlName={AUDIT_QUESTION}
            collectionName={ADMIN_AUDIT_QUESTION_MODEL}
            transform={config => transformAuditQuestionCategoryOptions(auditCategoryIds, config)}
            model={ADMIN_AUDIT_QUESTION_MODEL}
            fields={ADMIN_AUDIT_QUESTION_EDIT_FIELDS}
            title="Edit Audit Question"
          />
        </AdminContainer>

        <AdminContainer title="Audit Questions Responses">
          <HelpBlock>
            <p className="small-text">
              These responses relate to the audit Question
            </p>
          </HelpBlock>
          <AdminCreate
            collectionName={AUDIT_QUESTION_RESPONSE}
            controlName={AUDIT_QUESTION_RESPONSE}
            model={ADMIN_AUDIT_QUESTION_RESPONSE_MODEL}
            fields={ADMIN_AUDIT_QUESTION_RESPONSE_CREATE_FIELDS}
            defaultValues={auditQuestionFilter}
            title="Add Audit Question Response"
          />
          <AdminList
            noSearch
            hidePagination
            pageSize={PAGE_SIZE}
            listName={AUDIT_QUESTION_RESPONSE}
            model={ADMIN_AUDIT_QUESTION_RESPONSE_MODEL}
            fields={ADMIN_AUDIT_QUESTION_RESPONSE_ROW_FIELDS}
            defaultFilters={Map(auditQuestionFilter)}
          />
        </AdminContainer>
      </div>
    );
  }
};

export function mapStateToProps(state, props) {
  const auditQuestionId =  props.params.uuid
  return {
    auditQuestionId,
    itemOptions: state.items.getIn(['options', ADMIN_AUDIT_QUESTION_OPTIONS], Map()),
    auditCategoryIds: state.collections.getIn([ADMIN_AUDIT_CATEGORIES, 'items'], List()).map(category => category.get('id')),
    auditQuestionFilter: { audit_question: auditQuestionId }
  };
}


export default connect(
  mapStateToProps,
  {
    getItemOptions,
    getAllCollection
  }
) (EditAuditQuestionAdmin);
