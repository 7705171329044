import React from 'react';
import { connect } from 'react-redux';
import { getCollection, deleteItem } from '^/actions/collections';
import { STAFF_ALERTS, ALERTS } from '^/consts/collectionKeys';
import Alerts from './Alerts';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import { ALERT_TYPES } from '^/models/alerts';

const ALERTS_TYPE_FILTER = {
  type: [ALERT_TYPES.ACTIVITY_REASSIGNED, ALERT_TYPES.ACTIVITY_DATES_UPDATED],
};

export class UserAlerts extends PureComponent {
  componentWillMount() {
    this.props.getCollection(STAFF_ALERTS);
    this.props.getCollection(ALERTS, { filters: ALERTS_TYPE_FILTER, });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentPracticeId !== this.props.currentPracticeId) {
      this.props.getCollection(STAFF_ALERTS);
      this.props.getCollection(ALERTS, { filters: ALERTS_TYPE_FILTER, });
    }
  }

  loadMore () {
    const { staffAlertsCollection, alertsCollection } = this.props;

    this.props.getCollection(STAFF_ALERTS, {
      page: staffAlertsCollection.get('page') + 1,
      shouldAppend: true,
    });

    this.props.getCollection(ALERTS, {
      filters: ALERTS_TYPE_FILTER,
      page: alertsCollection.get('page') + 1,
      shouldAppend: true,
    });
  }

  onDismiss(alertId, isStaffAlert) {
    if (isStaffAlert) {
      this.props.deleteItem(STAFF_ALERTS, alertId);
    } else {
      this.props.deleteItem(ALERTS, alertId);
    }
  }

  render() {
    const { staffAlertsCollection, alertsCollection } = this.props;

    if (
      !staffAlertsCollection ||
      !staffAlertsCollection.get('items') ||
      !alertsCollection ||
      !alertsCollection.get('items')
    ) {
      return <Loading />;
    }

    const alerts = alertsCollection.get('items').map(each => each.set('is_staff_alert', false));
    const staffAlerts = staffAlertsCollection.get('items').map(each => each.set('is_staff_alert', true));

    return (
      <Alerts
        alertCount={staffAlertsCollection.get('count') + alertsCollection.get('count')}
        alerts={alerts.concat(staffAlerts)}
        onDismiss={(alertId, isStaffAlert) => this.onDismiss(alertId, isStaffAlert)}
        loadMore={() => this.loadMore()}
      />
    );
  }
}

export function mapStateToProps(state) {
  return {
    alertsCollection: state.collections.get(ALERTS),
    staffAlertsCollection: state.collections.get(STAFF_ALERTS),
    currentPracticeId: state.currentPractice && state.currentPractice.get('id'),
  };
}

export default connect(mapStateToProps, {getCollection, deleteItem}) (UserAlerts);
