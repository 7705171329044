import React from 'react';
import { MODELS } from '^/permissions/capabilities';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminList from '^/components/admin/content/perms/AdminList';
import PureComponent from '^/components/common/PureComponent';

import {
  ADMIN_QMR_REPORT_MODEL,
  ADMIN_QMR_REPORT_ROW_FIELDS,
  ADMIN_QMR_REPORT_CREATE_FIELDS
} from '^/components/admin/qmr-reports/qmr-reports';


class QMRReportsAdmin extends PureComponent {
  render() {
    return (
      <div>
        <AdminContainer title="QMR Reports">
          <AdminCreate
            title="Create new report"
            permsModel={MODELS.QMR_REPORT}
            collectionName={ADMIN_QMR_REPORT_MODEL}
            model={ADMIN_QMR_REPORT_MODEL}
            fields={ADMIN_QMR_REPORT_CREATE_FIELDS}
          />
        </AdminContainer>

        <AdminContainer>
          <AdminList
            permsModel={MODELS.QMR_REPORT}
            title="All QMR Reports"
            model={ADMIN_QMR_REPORT_MODEL}
            fields={ADMIN_QMR_REPORT_ROW_FIELDS}
          />
        </AdminContainer>
      </div>
    );
  }
}

export default QMRReportsAdmin;
