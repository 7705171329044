export const MODELS = {
  TEMPLATE_ACTIVITY: 'TEMPLATE_ACTIVITY',
  ACTIVITY: 'ACTIVITY',
  TEMPLATE_DOCUMENT: 'TEMPLATE_DOCUMENT',
  QPS_REPORT: 'QPS_REPORT',
  QPS_FILE: 'QPS_FILE',
  NEWS: 'NEWS',
  USER: 'USER',
  PRACTICE: 'PRACTICE',
  ALERT: 'ALERT',
};

export const DENY = {
  ALL: 'ALL',
  MODIFY: 'MODIFY',
};

export const CAPABILITIES = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

export function can(user, capability, model) {
  const permissions = user && user.get('permissions');
  if (!permissions) {
    return true;
  }

  const deny = permissions.get(model);
  switch (deny) {
    case DENY.MODIFY:
      return capability === CAPABILITIES.READ;

    case DENY.ALL:
      return false;

    default:
      return true;
  }
}
