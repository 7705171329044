import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { MODELS } from '^/permissions/capabilities';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';
import AdminChangeLog from '^/components/admin/content/change-log/AdminChangeLog';

import { reloadTemplateDocumentCategoryAndRules } from '^/actions/actionSequences';

import {
  ADMIN_TEMPLATE_DOCUMENT_CATEGORY_CREATE,
  ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MODEL,
  ADMIN_TEMPLATE_DOCUMENT_CATEGORY_EDIT_FIELDS,
  ADMIN_TEMPLATE_DOCUMENT_CATEGORY_VIEW_FIELDS,
  TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE,
  ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE_MODEL,
  TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE_ROW_FIELDS,
  TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE_CREATE_FIELDS,
  transformTemplateDocumentCategoryMembershipPermissionRuleChoices,
} from './template-documents';

const EditTemplateDocumentCategory = function({
  reloadRules,
  templateDocumentId,
  templateDocumentFolder,
  templateDocumentCategoryFilter,
}) {
  return (
    <div>
      <AdminContainer title="Template Documents">
        <AdminEdit
          permsModel={MODELS.TEMPLATE_DOCUMENT}
          readOnlyFields={ADMIN_TEMPLATE_DOCUMENT_CATEGORY_VIEW_FIELDS}
          itemId={templateDocumentId}
          controlName={ADMIN_TEMPLATE_DOCUMENT_CATEGORY_CREATE}
          collectionName={ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MODEL}
          model={ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MODEL}
          fields={ADMIN_TEMPLATE_DOCUMENT_CATEGORY_EDIT_FIELDS}
          title="Edit Template Document Category"
        />
      </AdminContainer>
      <AdminContainer title="Category membership permission rules">
        <HelpBlock>
          <p className="small-text">
            If membership permission rules exist for one or more memberships
            then the following permission rules will apply for this category.
            Otherwise all memberships will see this category.
          </p>
        </HelpBlock>

        <AdminList
          permsModel={MODELS.TEMPLATE_DOCUMENT}
          noSearch
          hidePagination
          listName={TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE}
          pageSize={100}
          onRemove={() =>
            reloadRules(templateDocumentId, templateDocumentCategoryFilter)
          }
          model={
            ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE_MODEL
          }
          defaultFilters={Map(templateDocumentCategoryFilter)}
          fields={
            TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE_ROW_FIELDS
          }
        />
        <AdminCreate
          permsModel={MODELS.TEMPLATE_DOCUMENT}
          collectionName={TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE}
          controlName={TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE}
          onComplete={() =>
            reloadRules(templateDocumentId, templateDocumentCategoryFilter)
          }
          transform={config =>
            transformTemplateDocumentCategoryMembershipPermissionRuleChoices(
              templateDocumentFolder,
              config
            )
          }
          model={
            ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE_MODEL
          }
          fields={
            TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE_CREATE_FIELDS
          }
          defaultValues={templateDocumentCategoryFilter}
          title="Add Template Category Membership Permission Rule"
        />
      </AdminContainer>
      <AdminContainer>
        <AdminChangeLog
          model="TemplateDocumentCategory"
          id={templateDocumentId}
        />
      </AdminContainer>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    templateDocumentId: props.params.uuid,
    templateDocumentFolder: state.items.get(
      ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MODEL
    ),
    templateDocumentCategoryFilter: { template_category: props.params.uuid },
  };
}

export default connect(mapStateToProps, {
  reloadRules: reloadTemplateDocumentCategoryAndRules,
})(EditTemplateDocumentCategory);
