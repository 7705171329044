import React from 'react';
import { Map } from 'immutable';
import moment from 'moment';

import Loading from '^/components/app/content/Loading';
import { getTimePeriodLabel, getSubGroupFilterFromPath } from '^/components/app/groups/insights/utils';
import DetailsCard from '^/components/app/groups/insights/DetailsCard';
import {
  eventRecordInsightsConfig,
  FILTER_KEYS,
  INSIGHTS,
  INSIGHTS_API_URLS,
} from '^/components/app/groups/insights/constants';
import withInsight from '^/components/app/groups/insights/hoc/withInsight';
import withSelectedFilters from '^/components/app/groups/insights/hoc/withSelectedFilters';
import ActivitiesChart from '^/components/app/groups/insights/activities/chart/ActivitiesChart';
import DateRangeFilter from '^/components/app/groups/insights/filters/DateRangeFilter';
import EventRecordsTypeFilter from '^/components/app/groups/reports/EventRecordsTypeFilter';
import { formatDateTime, MOMENT_UTC_TODAY, MOMENT_UTC_A_YEAR_AGO } from '^/utils';

const INSIGHT = INSIGHTS.EVENT_RECORDS_STATUS;

const EMPTY_DATA = [
  ['Status', 'Percentage'],
  ['Open', 0],
  ['In progress', 0],
  ['Closed', 0],
];

function transformChartData(data) {
  return data ? [
    ['Status', 'Percentage'],
    ['Closed', data.get('CLOSED') || 0],
    ['In progress', data.get('IN_PROGRESS') || 0],
    ['Open', data.get('OPEN') || 0],
  ] : EMPTY_DATA;
}

export const EventRecordsDetailCard = ({
  dateFrom,
  dateTo,
  data,
  isLoading,
  hasFailedToLoad
}) => {
  return (
    <DetailsCard>
      <div className="row">
        <div className="col-1-2 col-md-1 print-full-width">
          <h3>
            All events
            {dateFrom && dateTo && (
              <span className="ml-2">
                <DateRangeFilter
                  insightsConfig={eventRecordInsightsConfig}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                />
              </span>
            )}
          </h3>
          <p className="insights-timestamp">Updated: {formatDateTime(moment())}</p>
          <p className="caption mt-1">
            Event records open/in progress/closed {getTimePeriodLabel(dateFrom, dateTo)}.
          </p>
          <p className="caption mt-1 mb-1-4">
            View by event type:
          </p>
          <EventRecordsTypeFilter
            insightsConfig={eventRecordInsightsConfig}
            filterKey={FILTER_KEYS.TYPE}
          />
        </div>
        <div className="col-1-2 col-md-1 print-full-width">
          {isLoading ? <Loading/> : (
            <ActivitiesChart
              data={data}
              hasFailedToLoad={hasFailedToLoad}
              transformChartData={transformChartData}
            />
          )}
        </div>
      </div>
    </DetailsCard>
  );
};

const EventRecordsDetailCardWithSelectedFilters = withSelectedFilters(
  INSIGHT
)(EventRecordsDetailCard);

export default withInsight({
  insight: INSIGHT,
  url: INSIGHTS_API_URLS.EVENT_RECORDS_STATUS,
  getFilters: () => (
    Map({
      date_from: MOMENT_UTC_A_YEAR_AGO,
      date_to: MOMENT_UTC_TODAY,
      practice_sub_group_id: getSubGroupFilterFromPath(),
    })
  )
})(EventRecordsDetailCardWithSelectedFilters);
