import React from 'react';

import { Editor as TinyMCE } from '@tinymce/tinymce-react';
import { getAdminConfig, getNewsConfig, TINYMCE_ID } from '^/consts/tinyMCE';

const FieldTinyMCE = (
  {value, onChange, className, isEditing, config, initialValue, dirty}
) => {
  if (isEditing && !value && value !== '') {
    return <div />;
  }

  if (!value && initialValue && !dirty) {
    return <div />;
  }

  if (!config.variablesSector && config.includeVariables) {
    // NOTE: tinyMCE has to remount in order to change its config so do not
    // pass in a default here unless it will never change and make sure that your
    // sector value is read only once passed in.
    return <div />;
  }
  const variablesSector = config.includeVariables ? config.variablesSector : null;

  const tinyMCEComponentID = '#' + TINYMCE_ID;
  const tinyMCEConfig = config.kind === 'news' ?
    getNewsConfig(tinyMCEComponentID, variablesSector) :
    getAdminConfig(tinyMCEComponentID, variablesSector, config.isLandscape);

  return (
    <div className={className + (config.isLandscape ? ' landscape-richtext' : '')}>
      <TinyMCE
        id={TINYMCE_ID}
        key={config.isLandscape}
        initialValue={value}
        init={tinyMCEConfig}
        onEditorChange={e => onChange(e)}
      />
    </div>
  );
};

FieldTinyMCE.propTypes = {
  value: React.PropTypes.any,
  onChange: React.PropTypes.func.isRequired,
  className: React.PropTypes.string,
  isEditing: React.PropTypes.bool
};

export default FieldTinyMCE;
