import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import classNames from 'classnames';

import PureComponent from '^/components/common/PureComponent';
import { toggleCollapse } from '^/actions/actions';

class CollapseButton extends PureComponent {
  toggleCollapse() {
    this.props.toggleCollapse(this.props.collapseId);
  }

  render() {
    const { children, collapseId, className, onExpand } = this.props;
    const collapsed = this.props.collapse.get(collapseId);
    const onClick = () => {
      this.toggleCollapse();
      if (onExpand) {
        onExpand();
      }
    };

    return (
      <span
        className={classNames(className || 'collapse-button', collapsed && 'collapsed')}
        onClick={onClick}
      >
        {children}
      </span>
    );
  }
}

export { CollapseButton as CollapseButtonUnconnected };

export function mapStateToProps(state) {
  return {
    collapse: state.ui.get('collapse', Map())
  };
}

export default connect(
  mapStateToProps,
  {
    toggleCollapse
  }
)(CollapseButton);
