import React from 'react';
import moment from 'moment';
import { Map } from 'immutable';

import { fraction, asPercent } from '^/utils';

import { getActivityTypeText, isComplete } from '^/models/activities';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';

import ActivityNav from '^/components/app/activities/generic-activity/ActivityNav';
import AdminStepSidebar from '^/components/app/activities/adminstep/AdminStepSidebar';
import ResponsibleStaff from '^/components/app/activities/ResponsibleStaff';
import ActivityStatusIcon from '^/components/app/activities/ActivityStatusIcon';
import ActivityDate from '^/components/app/activities/ActivityDate';
import BackToCalendarLink from '^/components/app/activities/generic-activity/BackToCalendarLink';
import UserCreatedMarkup from '^/components/common/content/UserCreatedMarkup';
import { ActivityPriority } from '^/components/app/activities/ActivityPriority';
import { ActivityTags } from '^/components/app/activities/ActivityTags';


const MOBILE_DESC_COLLAPSE = 'mobile-desc';


export default function AdminStep ({activity}) {
  const complianceReviews = activity.getIn(['admin_step_field', 'compliance_reviews'], Map());
  const percentDone = asPercent(fraction(complianceReviews, isComplete));

  return (
    <div className="wrapper mb-2">
      <div className="row">
        <ActivityNav activity={activity} />

        <div className="col-2-3 bordered-left">
          <div className="back-link-container relative">
            <BackToCalendarLink month={moment(activity.get('start_date'))} />
            <ActivityStatusIcon activity={activity} />
          </div>

          <ActivityPriority activity={activity}/>
          <h1>Monthly Focus: {activity.get('title')}</h1>

          <div className="metadata">

            {getActivityTypeText(activity.get('type'))}<span className="ml-1 mr-1">&bull;</span>

            <ResponsibleStaff />

            <span className="mr-1">&bull;</span>

            <ActivityDate activity={activity} />

            <span className="ml-1 mr-1">&bull;</span>

            <ActivityTags activity={activity}/>

          </div>

          <hr className="strong hidden-m" />

          <div className="row">
            <div className="col-3-4">

              <h2 className="m-toggle">
                <CollapseButton collapseId={MOBILE_DESC_COLLAPSE} initiallyCollapsed collapseOnMount>
                  Description
                </CollapseButton>
              </h2>
              <Collapse collapseId={MOBILE_DESC_COLLAPSE} className="m-content">
                <UserCreatedMarkup text={activity.get('description')} />
              </Collapse>

            </div>

            <AdminStepSidebar
              percentDone={percentDone}
              activity={activity}
            />

          </div>
        </div>
      </div>
    </div>
  );
}
