import createCategoryPage from './hoc/create-category-page';
import {
  HealthcheckTypes,
  HealthcheckQuestion,
  HealthcheckStatus,
} from './types';
import QuestionAndAnswer from './question-and-answer';
import { getHealthcheckQuestionsCategory } from '^/actions/healthcheck';

export default createCategoryPage<HealthcheckQuestion>({
  type: HealthcheckTypes.Audit,
  content: 'Please answer the following questions.',
  noContent: '',
  itemRenderer: QuestionAndAnswer,
  fetchCategoryAction: getHealthcheckQuestionsCategory,
  requestKeys: [
    'getHealthcheckQuestionsProgress',
    'getHealthcheckQuestionsCategory',
    'updateHealthcheckStatus',
  ],
  getIsLoading: state =>
    state.thunkRequests.getHealthcheckQuestionsProgress.isLoading ||
    state.thunkRequests.getHealthcheckQuestionsCategory.isLoading ||
    state.thunkRequests.updateHealthcheckStatus.isLoading,
  getErrors: state =>
    state.thunkRequests.getHealthcheckQuestionsProgress.errors ||
    state.thunkRequests.getHealthcheckQuestionsCategory.errors ||
    state.thunkRequests.updateHealthcheckStatus.errors,
  getCategories: state => state.healthcheck.questionsCategories,
  intendedFromStatus: HealthcheckStatus.AUDIT,
  urlPart: '/questions/',
  areaHeaders: ['Question'],
});
