import React from 'react';

import { CYCLE_LABELS_MAPPED_TO_INDEX, FILTER_KEYS } from '^/components/app/groups/insights/constants';
import withFilters from '^/components/app/groups/insights/hoc/withFilters';
import PureComponent from '^/components/common/PureComponent';
import { capitalize } from '^/utils';

export function getTimePeriodFilters(insightConfigFilters, newValue, storeFilters, storedFilters) {
  if (newValue === '') {
    // Switch back to date range filtering
    return insightConfigFilters.set(
      FILTER_KEYS.DATE_FROM, storedFilters.dateFrom
    ).set(
      FILTER_KEYS.DATE_TO, storedFilters.dateTo
    ).delete(FILTER_KEYS.CYCLE_INDEX);
  }
  // Store date range filters and switch to cycle index filtering
  storeFilters({
    dateFrom: insightConfigFilters.get(FILTER_KEYS.DATE_FROM) || storedFilters.dateFrom,
    dateTo: insightConfigFilters.get(FILTER_KEYS.DATE_TO) || storedFilters.dateTo
  })
  return insightConfigFilters.delete(FILTER_KEYS.DATE_FROM).delete(FILTER_KEYS.DATE_TO).set(FILTER_KEYS.CYCLE_INDEX, newValue);
}

export class TimePeriodFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.props.onChange(event.target.value);
  }

  render() {
    const { insightsConfigWithFilters, title } = this.props;
    const selectedCycleIndex = insightsConfigWithFilters.getIn([0, 'filters', FILTER_KEYS.CYCLE_INDEX]);
    const dateOptionLabel = this.props.dateOptionLabel || 'Selected dates';

    return (
      <div className="mb-1 mt-1 display-flex flex-align-items-flex-start flex-wrap row-gap-4px">
        <div>
          <h4 className="display-inline mr-1">{title || 'View by period:'}</h4>
        </div>
        <select
          className="insights-dropdown"
          onChange={this.onChange}
        >
          <option value="">{dateOptionLabel}</option>
          <option value="0">Current cycle</option>
          <option value="1">Previous cycle</option>
        </select>
        <span className="print-dropdown-as-text">
          {selectedCycleIndex ? capitalize(CYCLE_LABELS_MAPPED_TO_INDEX[selectedCycleIndex]) : dateOptionLabel}
        </span>
      </div>
    );
  }
}

export default withFilters(TimePeriodFilter);
