import React from 'react';
import PureComponent from './components/common/PureComponent';

const withRouter = (component) => {
  const Component = component;

  class WithRouter extends PureComponent {
    render() {
      return <Component {...this.props} router={this.context.router} />;
    }
  }

  WithRouter.contextTypes = {
    router: React.PropTypes.object.isRequired,
  };

  return WithRouter;
};

export { withRouter };
