import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import PureComponent from '^/components/common/PureComponent';

import { startsWith, getIn, transformHideRuleChoices } from '^/utils';
import { reloadActivityAndRules } from '^/actions/actionSequences';
import { getItemOptions } from '^/actions/items';
import { MODELS } from '^/permissions/capabilities';

import {
  ADMIN_TEMPLATE_ACTIVITIES_MODEL,
  TEMPLATE_ACTIVITY_HIDE_RULE,
  TEMPLATE_ACTIVITY_REGION_HIDE_RULE,
  TEMPLATE_ACTIVITY_CYCLE_CONFIGURATION_SHOW_RULE,
  ADMIN_TEMPLATE_ACTIVITY_HIDE_RULES_MODEL,
  ADMIN_TEMPLATE_ACTIVITY_REGION_HIDE_RULES_MODEL,
  ADMIN_TEMPLATE_ACTIVITY_HIDE_RULE_CREATE_FIELDS,
  ADMIN_TEMPLATE_ACTIVITY_HIDE_RULE_ROW_FIELDS,
  ADMIN_TEMPLATE_ACTIVITY_REGION_HIDE_RULE_ROW_FIELDS,
  ADMIN_TEMPLATE_ACTIVITY_REGION_HIDE_RULE_CREATE_FIELDS,
  ADMIN_TEMPLATE_ACTIVITIES_CYCLE_CONFIGURATION_SHOW_RULE_MODEL,
  ADMIN_TEMPLATE_ACTIVITY_CYCLE_CONFIGURATION_SHOW_RULE_ROW_FIELDS,
  ADMIN_TEMPLATE_ACTIVITY_CYCLE_CONFIGURATION_SHOW_RULE_CREATE_FIELDS,
  ADMIN_TEMPLATE_ACTIVITIES_EDIT_FIELDS,
  ADMIN_TEMPLATE_ACTIVITIES_VIEW_FIELDS,
  ADMIN_COMPLIANCE_SYSTEMS_FIELDS,
  ADMIN_TEMPLATE_ACTIVITIES_OPTIONS,
  ADMIN_TEMPLATE_DOCUMENT_FIELDS,
  TEMPLATE_ACTIVITY_REGION_OVERRIDES,
  ADMIN_TEMPLATE_ACTIVITY_REGION_OVERRIDES_MODEL,
  ADMIN_TEMPLATE_ACTIVITY_REGION_OVERRIDES_ROW_FIELDS,
  ADMIN_TEMPLATE_ACTIVITY_REGION_OVERRIDES_CREATE_FIELDS,
  transformRegionOverridesChoices,
} from '^/components/admin/activities/activity';
import { ACTIVITY_TYPE_FIELDS, getActivityTypeText } from '^/models/activities';

import HelpBlock from '^/components/app/widgets/HelpBlock';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminList from '^/components/admin/content/perms/AdminList';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import AdminManyRelatedField from '^/components/admin/content/perms/AdminManyRelatedField';
import AdminComplianceSystemFieldAdd from '^/components/admin/activities/AdminComplianceSystemFieldAdd';
import AdminChangeLog, { reloadChangeLog } from '^/components/admin/content/change-log/AdminChangeLog';
import ApplyTemplateActivityToLiveCycles
  from '^/components/admin/activities/apply-to-live-cycles/ApplyTemplateActivityToLiveCycles';


function getFields(activity) {
  const type = activity.get('type');
  const wrongTypeFields = ACTIVITY_TYPE_FIELDS.filterNot((value, key) => key === type);

  return ADMIN_TEMPLATE_ACTIVITIES_EDIT_FIELDS.filterNot(fieldName =>
    wrongTypeFields.some(wrongFieldName => startsWith(fieldName, `${wrongFieldName}.`))
  );
}

function getReadOnlyFields(activity) {
  const type = activity.get('type');
  const wrongTypeFields = ACTIVITY_TYPE_FIELDS.filterNot((value, key) => key === type);

  return ADMIN_TEMPLATE_ACTIVITIES_VIEW_FIELDS.filterNot(field =>
    wrongTypeFields.some(wrongFieldName => field.getIn(['name', 0]) === wrongFieldName)
  );
}

function getTitle(activity) {
  const activityType = getActivityTypeText(activity.get('type'));
  return `Manage Activity: ${activityType}`;
}

function applyShowInherited(fieldConfig, fields) {
  const showInherited = config => config && config.merge({
    type: 'static',
    text: 'Inherited from Monthly Focus',
  });

  const matchingTypeField =
    ACTIVITY_TYPE_FIELDS.find(typeField =>
      getIn(fields, [typeField, 'admin_step', 'value'])
    );

  if (matchingTypeField) {
    return fieldConfig
      .update('default_month', showInherited)
      .update('period', showInherited);
  }

  return fieldConfig;
}

function renameFields(fieldConfig/*, fields*/) {
  return fieldConfig.updateIn(
    ['default_month', 'label'], label => label && 'Cycle month'
  ).updateIn(
    ['compliance_review_field.admin_step', 'label'], label => label && 'Monthly Focus'
  );
}

function addLookupFilterToDependecies(fieldConfig, sector) {
  return fieldConfig.setIn(['dependency', 'lookup_filter'], Map({sector: sector}))
}

function filterResponsibilitiesBySector(fieldConfig, sector) {
  return fieldConfig.updateIn(
    ['responsibility', 'choices'],
    responsibilities => responsibilities.filter(responsibility => responsibility.get('sector') === sector)
  );
}

function addLookupFilterToAdminStep(fieldConfig, sector) {
  return fieldConfig.setIn(
    ['compliance_review_field.admin_step', 'lookup_filter'],
    Map({ sector: sector })
  );
}

function transform(fieldConfig, fields, item) {
  return filterResponsibilitiesBySector(
    addLookupFilterToAdminStep(
      addLookupFilterToDependecies(
        renameFields(applyShowInherited(fieldConfig, fields), fields),
        item.get('sector')
      ),
      item.get('sector')
    ),
    item.get('sector')
  );
}

function transformDataToSubmit(data) {
  const priority = data.priority === "" ? null : data.priority
  const tags = typeof data.tags === `string` ? [data.tags] : data.tags
  return {...data, priority, tags}
}

export function transformRegionHideRuleChoices(templateActivity, fieldConfig) {
  if (!templateActivity) {
    return fieldConfig;
  }

  const currentRegionRules =
    templateActivity
      .get('region_hide_rules')
      .map(rule => rule.get('region'));

  // filter out any rule choices that are already applied to the activity
  return fieldConfig
    .updateIn(
      ['region', 'choices'],
      choices => choices.filterNot(choice => currentRegionRules.includes(choice.get('value')))
    );
}

export function transformCycleConfigurationShowRuleChoices(templateActivity, fieldConfig) {
  if (!templateActivity) {
    return fieldConfig;
  }

  const currentShowRules =
    templateActivity
      .get('cycle_configuration_show_rules')
      .map(rule => rule.get('cycle_configuration'));

  // filter out any rule choices that are already applied to the activity
  return fieldConfig
    .updateIn(
      ['cycle_configuration', 'choices'],
      choices => choices.filterNot(choice => currentShowRules.includes(choice.get('value')))
    );
}

export class ManageActivity extends PureComponent {

  componentWillMount() {
    const { activityId, itemOptions } = this.props;
    if (itemOptions.isEmpty()) {
      this.getItemOptions(activityId);
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.activityId !== newProps.activityId) {
      this.getItemOptions(newProps.activityId);
    }
  }

  getItemOptions(activityId) {
    this.props.getItemOptions(ADMIN_TEMPLATE_ACTIVITIES_MODEL, activityId, ADMIN_TEMPLATE_ACTIVITIES_OPTIONS);
  }

  render() {

    const {
      activityId,
      templateActivity,
      readOnly,
      itemOptions,
      templateActivityFilter,
      reloadRules
    } = this.props;

    return (
      <div>
        <AdminEdit
          permsModel={MODELS.TEMPLATE_ACTIVITY}
          getReadOnlyFields={getReadOnlyFields}
          readOnly={readOnly}
          model={ADMIN_TEMPLATE_ACTIVITIES_MODEL}
          collectionName={ADMIN_TEMPLATE_ACTIVITIES_MODEL}
          onComplete={reloadChangeLog('TemplateActivity', activityId)}
          itemId={activityId}
          getTitle={getTitle}
          getFields={getFields}
          transform={transform}
          transformDataToSubmit={transformDataToSubmit}
        />

        <AdminContainer className="mt-2">
          <AdminManyRelatedField
            permsModel={MODELS.TEMPLATE_ACTIVITY}
            itemId={activityId}
            controlName={ADMIN_TEMPLATE_ACTIVITIES_MODEL}
            options={itemOptions}
            model={ADMIN_TEMPLATE_ACTIVITIES_MODEL}
            fieldName={['cqc_systems']}
            fields={ADMIN_COMPLIANCE_SYSTEMS_FIELDS}
            listTitle="Report system sections"
            addItemTitle="Add another system section"
            adminManyRelatedFieldAddType={AdminComplianceSystemFieldAdd}
            defaultLookupFilters={{sector: templateActivity && templateActivity.get('sector')}}
            sortBy={['prefix', 'name']}
          />
        </AdminContainer>

        <AdminContainer className="mt-2">
          <AdminManyRelatedField
            permsModel={MODELS.TEMPLATE_ACTIVITY}
            addLabel="Assign template document to activity"
            itemId={activityId}
            controlName={ADMIN_TEMPLATE_ACTIVITIES_MODEL}
            options={itemOptions}
            model={ADMIN_TEMPLATE_ACTIVITIES_MODEL}
            fieldName={['template_documents']}
            fields={ADMIN_TEMPLATE_DOCUMENT_FIELDS}
            listTitle="Template Documents"
            sortBy={['name']}
            defaultLookupFilters={{sector: templateActivity && templateActivity.get('sector')}}
          />
        </AdminContainer>

        <AdminContainer title="Cycle Configuration Show Rules">
          <HelpBlock>
            <p className="small-text">
              This activity will be shown only for the following cycle
              configurations.
            </p>
          </HelpBlock>

          <AdminList
            permsModel={MODELS.TEMPLATE_ACTIVITY}
            noSearch
            hidePagination
            pageSize={100}
            onRemove={() => reloadRules(activityId, templateActivityFilter)}
            listName={TEMPLATE_ACTIVITY_CYCLE_CONFIGURATION_SHOW_RULE}
            model={ADMIN_TEMPLATE_ACTIVITIES_CYCLE_CONFIGURATION_SHOW_RULE_MODEL}
            fields={ADMIN_TEMPLATE_ACTIVITY_CYCLE_CONFIGURATION_SHOW_RULE_ROW_FIELDS}
            defaultFilters={Map(templateActivityFilter)}
          />
            {!readOnly &&
              <AdminCreate
                permsModel={MODELS.TEMPLATE_ACTIVITY}
                collectionName={TEMPLATE_ACTIVITY_CYCLE_CONFIGURATION_SHOW_RULE}
                onComplete={() => reloadRules(activityId, templateActivityFilter)}
                controlName={TEMPLATE_ACTIVITY_CYCLE_CONFIGURATION_SHOW_RULE}
                transform={config => transformCycleConfigurationShowRuleChoices(templateActivity, config)}
                model={ADMIN_TEMPLATE_ACTIVITIES_CYCLE_CONFIGURATION_SHOW_RULE_MODEL}
                fields={ADMIN_TEMPLATE_ACTIVITY_CYCLE_CONFIGURATION_SHOW_RULE_CREATE_FIELDS}
                defaultValues={templateActivityFilter}
                title="Add Rule"
              />
            }
        </AdminContainer>

        <AdminContainer title="Hide Rules">
          <HelpBlock>
            <p className="small-text">
              This activity will be hidden for a practice if ANY of the
              rules added below match the value set for a particular
              attribute on that practice.
            </p>
          </HelpBlock>

          <AdminList
            permsModel={MODELS.TEMPLATE_ACTIVITY}
            noSearch
            hidePagination
            pageSize={100}
            onRemove={() => reloadRules(activityId, templateActivityFilter)}
            listName={TEMPLATE_ACTIVITY_HIDE_RULE}
            model={ADMIN_TEMPLATE_ACTIVITY_HIDE_RULES_MODEL}
            fields={ADMIN_TEMPLATE_ACTIVITY_HIDE_RULE_ROW_FIELDS}
            defaultFilters={Map(templateActivityFilter)}
          />
            {!readOnly &&
              <AdminCreate
                permsModel={MODELS.TEMPLATE_ACTIVITY}
                collectionName={TEMPLATE_ACTIVITY_HIDE_RULE}
                onComplete={() => reloadRules(activityId, templateActivityFilter)}
                controlName={TEMPLATE_ACTIVITY_HIDE_RULE}
                transform={config => transformHideRuleChoices(templateActivity, config)}
                model={ADMIN_TEMPLATE_ACTIVITY_HIDE_RULES_MODEL}
                fields={ADMIN_TEMPLATE_ACTIVITY_HIDE_RULE_CREATE_FIELDS}
                defaultValues={templateActivityFilter}
                title="Add Rule"
              />
            }
        </AdminContainer>

        <AdminContainer title="Region Hide Rules">
          <HelpBlock>
            <p className="small-text">
              This activity will be hidden for a practice if it is
              in ANY of the regions added below.
            </p>
          </HelpBlock>

          <AdminList
            permsModel={MODELS.TEMPLATE_ACTIVITY}
            noSearch
            hidePagination
            onRemove={() => reloadRules(activityId, templateActivityFilter)}
            listName={TEMPLATE_ACTIVITY_REGION_HIDE_RULE}
            pageSize={100}
            model={ADMIN_TEMPLATE_ACTIVITY_REGION_HIDE_RULES_MODEL}
            defaultFilters={Map(templateActivityFilter)}
            fields={ADMIN_TEMPLATE_ACTIVITY_REGION_HIDE_RULE_ROW_FIELDS}
          />
            {!readOnly &&
              <AdminCreate
                permsModel={MODELS.TEMPLATE_ACTIVITY}
                collectionName={TEMPLATE_ACTIVITY_REGION_HIDE_RULE}
                controlName={TEMPLATE_ACTIVITY_REGION_HIDE_RULE}
                onComplete={() => reloadRules(activityId, templateActivityFilter)}
                transform={config => transformRegionHideRuleChoices(templateActivity, config)}
                model={ADMIN_TEMPLATE_ACTIVITY_REGION_HIDE_RULES_MODEL}
                fields={ADMIN_TEMPLATE_ACTIVITY_REGION_HIDE_RULE_CREATE_FIELDS}
                defaultValues={templateActivityFilter}
                title="Add Rule"
              />
            }
        </AdminContainer>

        <AdminContainer title="Region Overrides">
          <HelpBlock>
            <p className="small-text">
              The following regions have overrides for specific values
            </p>
          </HelpBlock>

          <AdminList
            permsModel={MODELS.TEMPLATE_ACTIVITY}
            noSearch
            hidePagination
            listName={TEMPLATE_ACTIVITY_REGION_OVERRIDES}
            pageSize={100}
            onRemove={() => reloadRules(activityId)}
            model={ADMIN_TEMPLATE_ACTIVITY_REGION_OVERRIDES_MODEL}
            defaultFilters={Map(templateActivityFilter)}
            fields={ADMIN_TEMPLATE_ACTIVITY_REGION_OVERRIDES_ROW_FIELDS}
          />
            <AdminCreate 
              permsModel={MODELS.TEMPLATE_ACTIVITY}
              collectionName={TEMPLATE_ACTIVITY_REGION_OVERRIDES}
              controlName={TEMPLATE_ACTIVITY_REGION_OVERRIDES}
              onComplete={() => reloadRules(activityId, templateActivityFilter)}
              transform={config => transformRegionOverridesChoices(templateActivity, config)}
              model={ADMIN_TEMPLATE_ACTIVITY_REGION_OVERRIDES_MODEL}
              fields={ADMIN_TEMPLATE_ACTIVITY_REGION_OVERRIDES_CREATE_FIELDS}
              defaultValues={templateActivityFilter}
              title="Add Region Override" 
            />
        </AdminContainer>

        <ApplyTemplateActivityToLiveCycles templateActivity={templateActivity} />

        <AdminContainer>
          <AdminChangeLog model="TemplateActivity" id={activityId} />
        </AdminContainer>
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  const activityId = props.params.uuid;
  return {
    activityId,
    itemOptions: state.items.getIn(['options', ADMIN_TEMPLATE_ACTIVITIES_OPTIONS], Map()),
    templateActivity: state.items.get(ADMIN_TEMPLATE_ACTIVITIES_MODEL),
    templateActivityFilter: { template_activity: activityId }
  };
}

export default connect(
  mapStateToProps,
  {
    getItemOptions,
    reloadRules: reloadActivityAndRules
  }
) (ManageActivity);
