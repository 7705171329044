
export const TABS_PATH = {
  DASHBOARD: 'dashboard',
  CALENDAR: 'calendar',
  TASKS: 'tasks',
  DOCUMENTS: 'documents',
  REPORTS: 'reports',
  DIGITAL_TOOLS: 'tools',
  PRACTICE: 'practice',
  HELP: 'help',
};


export const TABS = [
  {
    path: TABS_PATH.DASHBOARD,
    label: 'dashboard',
    counter: 'overDueActivityCount',
  },
  {
    path: TABS_PATH.CALENDAR,
    label: 'calendar',
    counter: 'alertsCount',
  },
  {
    path: TABS_PATH.TASKS,
    label: 'tasks',
    applicationOnly: true,
  },
  {
    path: TABS_PATH.DOCUMENTS,
    label: 'template documents',
  },
  {
    path: TABS_PATH.REPORTS,
    label: 'reports',
    applicationOnly: true,
    adminOnly: true,
  },
  {
    path: TABS_PATH.DIGITAL_TOOLS,
    label: 'digital tools',
  },
  {
    path: TABS_PATH.PRACTICE,
    label: 'practices',
  },
  {
    path: TABS_PATH.HELP,
    label: 'help',
    iconType: 'comments',
    iconClassName: 'white-icon title ml-1',
  },
];
