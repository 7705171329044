import React, { PropTypes } from 'react';
import PureComponent from '^/components/common/PureComponent';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';

import { isPending } from '^/consts/responseStates';
import { loadItem, patchItem, getItemOptions } from '^/actions/items';
import Loading from '^/components/app/content/Loading';
import AdminListItem from '^/components/admin/content/list/AdminListItem';
import AdminManyRelatedFieldAdd from '^/components/admin/content/many-related-field/AdminManyRelatedFieldAdd';
import Table from '^/components/common/content/Table';
import ScrollableTableContainer from '^/components/common/content/ScrollableTableContainer';
import Messages from '^/components/admin/content/edit-create/dynamic-forms/Messages';

export const CONTROL_NAME_PREFIX = 'ADMINMANYRELATED_';

export class AdminManyRelatedField extends PureComponent {
  constructor(props) {
    super(props);
    this.addItem = this.addItem.bind(this);
  }

  componentWillMount() {
    const { itemId, model, controlName, options } = this.props;
    this.props.loadItem(model, itemId, controlName);
    if (!options) {
      this.props.getItemOptions(model, itemId, controlName);
    }
  }

  removeItem(itemId, itemIdFieldPath) {
    const { parentItem, fieldName, model, parentId, controlName } = this.props;

    const newManyToManyIds = parentItem
      .getIn(fieldName)
      .filter(item => item.getIn(itemIdFieldPath) !== itemId)
      .map(item => item.getIn(itemIdFieldPath));

    if (window.confirm('Are you sure you want to remove?')) {
      const updatedParentWithIdsOnly = Map()
        .setIn(fieldName, newManyToManyIds)
        .toJS();

      this.props.patchItem(
        model,
        parentId,
        updatedParentWithIdsOnly,
        controlName
      );
    }
  }

  addItem({ id }) {
    const { addHook } = this.props;

    if (id) {
      const {
        model,
        parentId,
        controlName,
        parentItem,
        fieldName,
      } = this.props;

      const newManyToManyIds = parentItem
        .getIn(fieldName)
        .map(item => item.getIn(['id']))
        .push(id);

      const updatedParentWithIdsOnly = Map()
        .setIn(fieldName, newManyToManyIds)
        .toJS();

      if (addHook) {
        addHook(id, [model, parentId, updatedParentWithIdsOnly, controlName]);
      } else {
        this.props.patchItem(
          model,
          parentId,
          updatedParentWithIdsOnly,
          controlName
        );
      }
    }
  }

  render() {
    const {
      items,
      fields,
      response,
      addItemTitle,
      controlName,
      fieldOptions,
      listTitle,
      hideAddItem,
      adminManyRelatedFieldAddType,
      sortBy,
      updateResponse,
      fieldConfig,
      addLabel,
      defaultLookupFilters,
    } = this.props;

    if (isPending(response) || !items) {
      return <Loading />;
    }

    const WhatManyRelatedFieldAdd =
      adminManyRelatedFieldAddType || AdminManyRelatedFieldAdd;
    const rows = sortBy
      ? items.sortBy(item => sortBy.map(each => item.get(each)).join())
      : items;

    return (
      <div className="admin-manyrelatedfield-container">
        <h4>{listTitle}</h4>
        <ScrollableTableContainer>
          <Table
            className="padded"
            renderRow={(item, idx, columns, key = '') => (
              <AdminListItem
                key={key + idx}
                item={item}
                fields={columns}
                removeItem={(itemId, itemIdFieldPath) =>
                  this.removeItem(itemId, itemIdFieldPath)
                }
              />
            )}
            renderHeaders={headers =>
              headers.map((header, idx) => (
                <th key={idx}>{header.get('display_name')}</th>
              ))
            }
            rows={rows}
            columns={fields}
            groupBy={fields.find(field => field.get('groupBy'))}
            emptyMessage="None Found."
          />
        </ScrollableTableContainer>

        <Messages response={updateResponse} isEditing />

        {!hideAddItem && (
          <div className="mb-2">
            <h4 className="mt-1">{addItemTitle}</h4>
            <WhatManyRelatedFieldAdd
              addLabel={addLabel}
              config={fieldConfig}
              options={fieldOptions}
              response={updateResponse}
              form={controlName + '_ADD'}
              onSubmit={this.addItem}
              controlName={controlName}
              defaultLookupFilters={defaultLookupFilters}
            />
          </div>
        )}
      </div>
    );
  }
}

function getFieldOptionsPath(fieldNamePath, method) {
  return ['actions', method].concat(fieldNamePath);
}

function getFieldOptions(itemOptions, fieldNamePath) {
  const postOptionsPath = getFieldOptionsPath(fieldNamePath, 'POST');
  const putOptionsPath = getFieldOptionsPath(fieldNamePath, 'PUT');
  return (
    itemOptions.getIn(postOptionsPath) || itemOptions.getIn(putOptionsPath)
  );
}

export function hasMatchingFields(item, filters) {
  return filters
    .keySeq()
    .reduce(
      (previousMatched, key) =>
        previousMatched ? item.get(key) === filters.get(key) : false,
      true
    );
}

export function mapStateToProps(state, props) {
  const { model, fieldName, itemId, defaultFilters } = props;
  const controlName = props.controlName || CONTROL_NAME_PREFIX + model;
  const parentItem = state.items.get(controlName);

  const itemOptions =
    props.options || state.items.getIn(['options', controlName], Map());
  const fieldConfig = getFieldOptions(itemOptions, fieldName);
  const fieldOptions = fieldConfig && fieldConfig.get('choices');

  let items;
  if (parentItem) {
    if (!defaultFilters) {
      items = parentItem.getIn(fieldName);
    } else {
      items = parentItem
        .getIn(fieldName)
        .filter(item => hasMatchingFields(item, defaultFilters));
    }
  }

  return {
    parentItem,
    parentId: itemId,
    items,
    controlName,
    fieldOptions,
    fieldConfig,
    response: state.responses.getIn(['loadItem', controlName]),
    updateResponse: state.responses.getIn(['updateItem', controlName]),
  };
}

AdminManyRelatedField.propTypes = {
  controlName: React.PropTypes.string,
  model: React.PropTypes.string.isRequired,
  itemId: React.PropTypes.string.isRequired,
  listTitle: React.PropTypes.string,
  addLabel: React.PropTypes.string,
  hideAddItem: React.PropTypes.bool,
  addItemTitle: React.PropTypes.string,
  fieldName: React.PropTypes.array.isRequired,
  defaultLookupFilters: React.PropTypes.object,
  fields: ImmutablePropTypes.list.isRequired,
  options: ImmutablePropTypes.map,
  addHook: PropTypes.func,
};

export default connect(mapStateToProps, {
  loadItem,
  patchItem,
  getItemOptions,
})(AdminManyRelatedField);
