import * as actions from '^/actions/actions';
import composeReducers from '^/reducers/composeReducers';
import { reducer as formReducer } from 'redux-form';
import { fromJS, List } from 'immutable';

function setFieldIntoField(state, form, field, file) {
  return Object.assign({}, state, {
    [form]: Object.assign({}, state[form], {
      [field]: Object.assign({}, state[field], {
        value: file,
      }),
    }),
  });
}

function fileUploadsFormReducer(state = {}, action) {
  switch (action.type) {
    case actions.SET_PENDING_UPLOAD_IN_FORM:
      const { form, field, file } = action.payload;
      return setFieldIntoField(state, form, field, file);
    case actions.GET_UPLOAD_FORM.SUCCESS:
      if (action.meta.form === undefined || action.meta.field === undefined) {
        return state;
      }
      const current_files =
        fromJS(state).getIn([action.meta.form, action.meta.field, 'value']) ||
        List();
      const new_files = current_files.push(action.payload);
      return setFieldIntoField(
        state,
        action.meta.form,
        action.meta.field,
        new_files.toJS()
      );
    default:
      return state;
  }
}

function digitalRecordsChecklistFormReducer(state = {}, action) {
  let newState = fromJS(state);
  let form;
  let field;

  switch (action.type) {
    case actions.CREATE_UPDATE_DIGITAL_RECORD_CHECKLIST.SUCCESS:
      form = action.meta.form;

      for (let i = 0; i < action.payload.field_values.length; i++) {
        const fieldValue = action.payload.field_values[i];
        newState = newState
          .setIn([form, fieldValue.key, 'value'], fieldValue.value)
          .setIn([form, fieldValue.key, 'initial'], true)
          .setIn([form, fieldValue.key, 'touched'], false);
      }
      return newState.toJS();
    case actions.CREATE_UPDATE_DIGITAL_RECORD_CHECKLIST.FAILURE:
      for (let i = 0; i < action.meta.updatedFields.length; i++) {
        form = action.meta.form;
        field = action.meta.updatedFields[i];
        newState = newState.setIn(
          [form, field, 'value'],
          newState.getIn([form, field, 'initial'])
        );
      }
      return newState.toJS();
    default:
      return state;
  }
}

const myFormReducer = formReducer.plugin({
  uploadActivityFile: (state, action) => {
    switch (action.type) {
      case 'UPLOAD_ACTIVITY_TASK_ASSIGNEE_FILE_FAILURE':
        state.file.value = null;
        return state;
      default:
        return state;
    }
  },
});

export default composeReducers([
  myFormReducer,
  fileUploadsFormReducer,
  digitalRecordsChecklistFormReducer,
]);
