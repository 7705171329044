import _ from 'underscore';

import ActionChain from '^/actions/ActionChain';

function makeActionChain(sequence) {
  return _.reduce(
    _.rest(sequence),
    (chain, step) => chain.then(step),
    new ActionChain(_.first(sequence))
  ).process();
}

export default makeActionChain;
