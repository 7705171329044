import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { isPending } from '^/consts/responseStates';
import { loadItem } from '^/actions/items';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';


export class AdminContainer extends PureComponent {

  componentWillMount() {
    const { itemId, model, collectionName } = this.props;
    this.loadItem(model, itemId, collectionName);
  }

  componentWillReceiveProps(newProps) {
    const { itemId, model, collectionName } = newProps;
    if (this.props.itemId !== itemId) {
      this.loadItem(model, itemId, collectionName);
    }
  }

  loadItem(model, itemId, collectionName) {
    if (itemId && model) {
      this.props.loadItem(model, itemId, collectionName);
    }
  }

  isLoading() {
    const { item, itemId, model, response } = this.props;
    return itemId && model && (!item || isPending(response));
  }

  render () {
    const { title, className, children } = this.props;

    return this.isLoading() ? <Loading /> : (
      <div className={classNames('admin-container mt-1', className)}>
        {title && <h2>{title}</h2>}
        <div className="admin-container-content">{children}</div>
      </div>
    );
  }
}

AdminContainer.propTypes = {
  title: React.PropTypes.string,
  className: React.PropTypes.string,
  children: React.PropTypes.any.isRequired,
  itemId: React.PropTypes.string,
  model: React.PropTypes.string,
  collectionName: React.PropTypes.string,
};

export function mapStateToProps(state, props) {
  const collectionName = props.collectionName || props.model;

  return {
    item: state.items.get(collectionName),
    response: state.responses.getIn(['loadItem', collectionName]),
    collectionName,
  };
}

export default connect(mapStateToProps, { loadItem })(AdminContainer);
