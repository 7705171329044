import React from 'react';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminList from '^/components/admin/content/perms/AdminList';
import PureComponent from '^/components/common/PureComponent';
import { Link } from 'react-router';


import {
  ADMIN_TERMS_MODEL,
  ADMIN_TERMS_ROW_FIELDS,
} from './terms';

export default class TermAdmin extends PureComponent {
  render() {
    return (
      <div>
        <Link to="/admin/terms/create/" className="btn btn-primary ml-1">
          Create New Terms
        </Link>
        <AdminContainer title="Terms">
          <AdminList
            title="All Terms"
            model={ADMIN_TERMS_MODEL}
            fields={ADMIN_TERMS_ROW_FIELDS}
          />
        </AdminContainer>
      </div>
    );
  }
}
