import React from 'react';
import { reduxForm } from 'redux-form';

import Loading from '^/components/app/content/Loading';
import LiveButton from '^/components/app/forms/LiveButton';
import FileUpload from '^/components/app/forms/FileUpload';
import FormError from '^/components/app/forms/FormError';
import PermissionsComponent from '^/components/app/perms/PermissionsComponent';
import { isNotReadOnlyInCurrentPractice } from '^/models/user';

const FORM_NAME = 'uploadActivityFile';

export const UploadActivityFileForm = ({
  isPendingUpload, response, handleSubmit, uploadFile, folders,
  fields: { name, link, file, description, folder, is_secure_document },
}) =>
  <form onSubmit={handleSubmit}>
    <div className="form-group">
      <div className="col-1">
        <label htmlFor="link">Link to a file:</label>
        <span className="help-block">File link (dropbox or your server)</span>
        <input id="link" type="text" {...link} />
      </div>
      <FormError response={response} formKey="link" />
    </div>
    <hr className="thin" />

    <div className="form-group">
      <div className="col-1">
        <label htmlFor="file">&hellip; or upload a file:</label>
        <FileUpload
          id="file"
          className="image-field"
          src={file.value}
          display={src =>
            src && <a href={src[src.length - 1].file} target="_blank">{src[src.length - 1].filename || 'Download'}</a>}
          setPendingUpload={(_file) => uploadFile(_file, FORM_NAME, 'file')}
          onSubmit={handleSubmit}
        />
      </div>
      <FormError response={response} formKey="file" />
    </div>
    <hr className="thin" />

    <div className="form-group">
      <div className="col-1">
        <label htmlFor="name">File name</label>
        <input id="name" type="text" {...name} />
      </div>
      <FormError response={response} formKey="name" />
    </div>

    <div className="form-group">
      <div className="col-1">
        <label htmlFor="description">Description</label>
        <textarea id="description" {...description} />
      </div>
      <FormError response={response} formKey="description" />
    </div>

    <div className="form-group">
      <div className="col-1">
        <label htmlFor="folder">Folder</label>
        <select id="folder" {...folder}>
          {folders.map(each => (
            <option key={each.get('id')} value={each.get('id')}>{each.get('number')} - {each.get('name')}</option>
          ))}
        </select>
      </div>
      <FormError response={response} formKey="folder" />
    </div>

    <PermissionsComponent hasPermission={isNotReadOnlyInCurrentPractice} >
      <div className="form-group">
        <div className="col-1">
          <label className="mr-1" htmlFor="private">Private</label>
          <label className="label-inline">
            <input
              {...is_secure_document}
              type="radio"
              value="true"
              checked={is_secure_document.value === 'true'}
            />
            Yes
          </label>
          <label className="label-inline">
            <input
              {...is_secure_document}
              type="radio"
              value="false"
              checked={is_secure_document.value !== 'true'}
            />
            No
          </label>
        </div>
      </div>
    </PermissionsComponent>

    <div className="form-group">
      <div className="col-1">
        <LiveButton
          disabled={isPendingUpload}
          className="btn btn-default"
          response={response}
          pendingMessage="Adding file..."
        >
          Add
        </LiveButton>
      </div>
      {isPendingUpload && <Loading />}
    </div>

  </form>;

export default reduxForm({
  form: FORM_NAME,
  fields: ['name', 'link', 'file', 'description', 'folder', 'is_secure_document'],
})(UploadActivityFileForm);
