import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List, Map } from 'immutable';

import { isPending } from '^/consts/responseStates';
import { CYCLE_RESPONSIBILITIES, RESPONSIBILITIES } from '^/consts/collectionKeys';
import { getAllCollection } from '^/actions/collections';
import { setSelectValue, clearSelect } from '^/actions/actions';
import { changeStepAndAddReassignData } from '^/actions/actionSequences';
import { USER_REASSIGN_STEPS, REASSIGN_MEMBER, REASSIGN_STEPS } from '^/consts/retireSuspend';

import Loading from '^/components/app/content/Loading';
import EmptyListPage from '^/components/app/practices/reassign-member/EmptyListPage';
import MemberFilter from '^/components/app/practices/reassign-member/MemberFilter';
import PureComponent from '^/components/common/PureComponent';
import
  ReassignResponsibilitiesForm
  from '^/components/app/practices/reassign-member/ReassignResponsibilitiesForm';


export class ReassignResponsibilities extends PureComponent {

  componentWillMount() {
    const {
      responsibilities,
      loadResponsibilities,
      loadCycleResponsibilities
    } = this.props;

    if (responsibilities.isEmpty()) {
      loadResponsibilities();
    }
    loadCycleResponsibilities();
  }

  render() {
    const {
      loading, cycleResponsibilities,
      responsibilities, setDefaultMember,
      memberSelectValue, memberChoices,
      handleSubmit
    } = this.props;

    if (loading) { return <Loading />; }

    if (cycleResponsibilities.isEmpty()) {
      return (
        <EmptyListPage
          reassignType="responsibilities"
          handleSubmit={handleSubmit}
        />
      );
    }


    const responsibilitiesToReassign =
      cycleResponsibilities
        .map(cr => cr.set(
          'name',
          responsibilities
            .find(r => cr.get('responsibility') === r.get('id'), null, Map())
            .get('name')
        ));

    const responsibilityIds =
      cycleResponsibilities
        .map(cr => cr.get('id'));

    const initialValues = Map(
        responsibilityIds
          .map(id => [id, memberSelectValue || memberChoices.first().get('value')])
      ).toJS();

    return (
      <div className="mb-1">
        <em>Reassign all responsibilities to &nbsp;</em>
        <MemberFilter
          choices={memberChoices}
          onChange={setDefaultMember}
          value={memberSelectValue}
        />
        <ReassignResponsibilitiesForm
          key={memberSelectValue}
          memberChoices={memberChoices}
          responsibilities={responsibilitiesToReassign}
          fields={responsibilityIds.toJS()}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      </div>
    );
  }
}

ReassignResponsibilities.propTypes = {
  member: ImmutablePropTypes.map.isRequired,
  practice: ImmutablePropTypes.map.isRequired,
  memberChoices: ImmutablePropTypes.list.isRequired,
  currentStep: React.PropTypes.string.isRequired,
};

export function mapStateToProps(state) {
  const loading =
    isPending(state.responses.getIn(['getCollection', CYCLE_RESPONSIBILITIES])) ||
    isPending(state.responses.getIn(['getCollection', RESPONSIBILITIES]));

  return {
    cycleResponsibilities: state.collections.getIn([CYCLE_RESPONSIBILITIES, 'items'], List()),
    responsibilities: state.collections.getIn([RESPONSIBILITIES, 'items'], List()),
    memberSelectValue: state.ui.getIn(['selectState', REASSIGN_MEMBER]),
    loading,
  };
}

export function mapDispatchToProps(dispatch, props) {
  const { currentStep, member } = props;

  const additionalParams = {
    staff: member.getIn(['user', 'staffdetail', 'id']),
  };

  const nextStepIndex = USER_REASSIGN_STEPS.indexOf(currentStep) + 1;
  const nextStep = USER_REASSIGN_STEPS.get(nextStepIndex);

  return {
    loadCycleResponsibilities: () =>
      dispatch(getAllCollection(CYCLE_RESPONSIBILITIES, { additionalParams })),
    loadResponsibilities: () => dispatch(getAllCollection(RESPONSIBILITIES)),
    setDefaultMember: val => dispatch(setSelectValue(REASSIGN_MEMBER, val)),
    clearDefaultMember: () => dispatch(clearSelect(REASSIGN_MEMBER)),
    handleSubmit: data =>
      dispatch(changeStepAndAddReassignData(REASSIGN_STEPS, nextStep, currentStep, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReassignResponsibilities);
