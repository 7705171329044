import React from 'react';
import PureComponent from '^/components/common/PureComponent';


export default class UserCreatedMarkup extends PureComponent {
  render() {
    return (
      <div
        dangerouslySetInnerHTML={{__html: this.props.text}}
        className="user-created-markup"
      />
    );
  }
}
