import React from 'react';
import { connect } from 'react-redux';

import { resetResponse } from '^/actions/actions';
import PureComponent from '^/components/common/PureComponent';
import { DownloadButton } from '^/components/app/activities/documents/DownloadDocumentButton';

class QMRReportDownloadButton extends PureComponent {

  componentWillMount() {
    this.props.resetResponse('renderQMRReport');
  }

  render() {
    const { response } = this.props;
    return (
      <div className="text-right mt-1">
        <DownloadButton
          startRenderDocument={this.props.startRenderReport}
          response={response}
        />
      </div>
    );
  }
}

QMRReportDownloadButton.propTypes = {
  startRenderReport: React.PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    response: state.responses.getIn(['renderQMRReport', props.itemId]),
  };
}

export default connect(
  mapStateToProps,
  { resetResponse }
) (QMRReportDownloadButton);
