import { List } from 'immutable';

import {
  toTitleCase,
  formatBackendDateTime,
  formatUTCTime,
  convertDurationToSeconds,
  formatBackendDate,
  convertDurationFromSeconds,
} from '^/utils';

import EmergencyDrugsAndEquipmentForm from '^/components/app/digital-tools/records/digital-records/forms/EmergencyDrugsAndEquipmentForm';
import FireDrillForm from '^/components/app/digital-tools/records/digital-records/forms/FireDrillForm';
import EventForm from '^/components/app/digital-tools/records/digital-records/forms/EventForm';
import CoshhForm from '^/components/app/digital-tools/records/digital-records/forms/CoshhForm';


export const EMERGENCY_DRUGS_AND_EQUIPMENT = "EMERGENCY_DRUGS_AND_EQUIPMENT";
export const FIRE_DRILL = "FIRE_DRILL";
export const EVENT = "EVENT";
export const COSHH = "COSHH";

export const RECORD_TYPE_NAME = {
  EMERGENCY_DRUGS_AND_EQUIPMENT: "Emergency Drugs Record",
  FIRE_DRILL: "Fire Drill Record",
  EVENT: "Event Record",
  COSHH: "COSHH Assessments (M 267B)",
}

export const FORM_NAME_BY_TYPE = {
  EMERGENCY_DRUGS_AND_EQUIPMENT: "EMERGENCY_DRUGS_AND_EQUIPMENT_FORM",
  FIRE_DRILL: "FIRE_DRILL_FORM",
  EVENT: "EVENT_FORM",
  COSHH: "COSHH_FORM",
}

export const FORM_TITLE_BY_TYPE = {
  EMERGENCY_DRUGS_AND_EQUIPMENT: "Drug or Equipment",
  FIRE_DRILL: "Record Fire Drill",
  EVENT: "Event",
  COSHH: "COSHH Assessments Record",
}

export const COLLAPSIBLE_RECORD_TYPES = [EVENT,]

export function getAddItemButtonText(recordType) {
  switch(recordType) {
    case EVENT:
      return 'ADD EVENT'
    default:
      return 'ADD ITEM'
  }
}

export function transformDataByRecordType(data, recordType, staffTasks) {

  switch(recordType) {
    case EMERGENCY_DRUGS_AND_EQUIPMENT:
      const expiryDate = data.expiry_date_null ? data.expiry_date =  null : data.expiry_date;
      const staffOrResponsibility = data.person_or_role === "role" ?
      {responsibility: data.assign_to, responsible_staff: null} :
      {responsible_staff: data.assign_to, responsibility: null};
      return {
        ...staffOrResponsibility,
        type: recordType,
        notes: data.notes,
        staff_tasks: [],
        related_files: [],
        record_details: {
          type: data.type.toUpperCase(),
          product: data.product,
          reorder_code: data.reorder_code,
          batch_number: data.batch_number,
          pack_size: data.pack_size,
          expiry_date: expiryDate,
          location: data.location,
        },
      };
    case FIRE_DRILL:
      return {
        type: recordType,
        responsible_staff: data.assign_to,
        notes: data.notes,
        staff_tasks: staffTasks.map(task => {return task.get('id')}),
        related_files: data.related_files.map(file => file.id || file.file_id),
        record_details: {
          evacuation_duration: convertDurationToSeconds(data.evacuation_duration_mins, data.evacuation_duration_secs),
          completed_date: formatBackendDateTime(data.completed_date, data.completed_time),
          equipment_training_provided: data.equipment_training_provided,
          were_patients_involved: data.were_patients_involved,
        },
      };
    case EVENT:
      return {
        type: recordType,
        responsible_staff: data.assign_to,
        notes: data.notes,
        staff_tasks: staffTasks.map(task => task.get("id")),
        related_files: data.related_files.map(file => file.id || file.file_id),
        record_details: {
          reference: data.reference,
          type: data.type,
          severity: data.severity,
          event_date: data.event_date,
          people_involved: data.people_involved,
          team_members_involved: data.team_members_involved,
          visibility_restricted: data.visibility_restricted,
          collaborators: data.collaborators,
          what_happened_notes: data.what_happened_notes,
          why_it_happened_notes: data.why_it_happened_notes,
          what_went_well_notes: data.what_went_well_notes,
          what_went_bad_notes: data.what_went_bad_notes,
          learning_points_notes: data.learning_points_notes,
          status: data.status,
          initial_actions_notes: data.initial_actions_notes,
          prevent_reoccurrence_notes: data.prevent_reoccurrence_notes,
        },
      };
      case COSHH:
        return {
          type: recordType,
          responsible_staff: data.assign_to,
          notes: data.notes,
          staff_tasks: staffTasks.map(task => task.get("id")),
          related_files: data.related_files.map(file => file.id || file.file_id),
          record_details: {
            type: data.type,
            product_name: data.product_name,
            used_by: data.used_by,
            used_for: data.used_for,
            location: data.location,
            amount_used: data.amount_used,
            usage_frequency: data.usage_frequency,
            usage_conditions: data.usage_conditions,
            signal_word: data.signal_word,
            hazard_statement: data.hazard_statement,
            risks_to_health: data.risks_to_health,
            precautionary_statement: data.precautionary_statement,
            safety_measures: data.safety_measures,
            ppe_required: data.ppe_required,
            first_aid_proceedures: data.first_aid_proceedures,
            emergency_proceedures: data.emergency_proceedures,
            special_storage: data.special_storage,
            disposal_measures: data.disposal_measures,
            health_monitoring: data.health_monitoring,
            additional_info: data.additional_info,
            safety_pictograms: data.safety_pictograms,
          },
        };
    default:
      break;
  }
}


export function getDefaultInitialValuesByRecordType(
  recordType,
  currentMoment,
  user
){
  switch(recordType) {
    case EMERGENCY_DRUGS_AND_EQUIPMENT:
      return {
        expiry_date: formatBackendDate(currentMoment),
        person_or_role: "person",
      };
    case FIRE_DRILL:
      return {
        completed_time: currentMoment.format("HH:mm"),
        completed_date: formatBackendDate(currentMoment),
        equipment_training_provided: false,
        related_files: [],
      };
    case EVENT:
      return {
        assign_to: user.getIn(['staffdetail', 'id']),
        event_date: formatBackendDate(currentMoment),
        visibility_restricted: true,
        status: "OPEN",
        related_files: [],
      };
      case COSHH:
        return {
          related_files: [],
          assign_to: user.getIn(['staffdetail', 'id']),
          safety_pictograms: [],
        };
    default:
      break;
  }
}

export function getInitialValuesByRecordType(digitalRecord, recordType) {
  switch(recordType) {
    case EMERGENCY_DRUGS_AND_EQUIPMENT:
      const emergencyDrugsAndEquipment = digitalRecord.get('emergency_drugs_and_equipment');
      return {
        type : toTitleCase(emergencyDrugsAndEquipment.get('type')),
        product: emergencyDrugsAndEquipment.get('product'),
        pack_size: emergencyDrugsAndEquipment.get('pack_size'),
        reorder_code: emergencyDrugsAndEquipment.get('reorder_code'),
        batch_number: emergencyDrugsAndEquipment.get('batch_number'),
        location: emergencyDrugsAndEquipment.get('location'),
        expiry_date: emergencyDrugsAndEquipment.get('expiry_date'),
        notes: digitalRecord.get('notes') || "",
        person_or_role: digitalRecord.get('responsibility') ? "role" : "person",
        assign_to: digitalRecord.getIn(['responsibility', 'id']) || digitalRecord.getIn(['responsible_staff', 'id']),
      }
    case FIRE_DRILL:
      const fireDrill = digitalRecord.get('fire_drill');
      return {
        completed_time: formatUTCTime(fireDrill.get('completed_date')),
        completed_date: formatBackendDate(fireDrill.get('completed_date')),
        equipment_training_provided: fireDrill.get('equipment_training_provided'),
        were_patients_involved: fireDrill.get('were_patients_involved'),
        notes: digitalRecord.get('notes') || "",
        related_files: fireDrill.get("related_files", List()).toJS() || "",
        assign_to: digitalRecord.getIn(['responsible_staff', 'id']),
        evacuation_duration_mins: convertDurationFromSeconds(fireDrill.get('evacuation_duration')).minutes,
        evacuation_duration_secs: convertDurationFromSeconds(fireDrill.get('evacuation_duration')).seconds, 
      }
    case EVENT:
      const event = digitalRecord.get("event");
      return {
        reference: event.get("reference"),
        type: event.get("type").toUpperCase(),
        assign_to: digitalRecord.getIn(["responsible_staff", "id"]),
        severity: event.get("severity").toUpperCase(),
        event_date: event.get("event_date"),
        people_involved: event.get("people_involved"),
        team_members_involved: event.get(
          "team_members_involved",
          List()
        ).map(member => member.get("id")),
        visibility_restricted: event.get("visibility_restricted"),
        collaborators: event.get(
          "collaborators",
          List()
        ).map(collaborator => collaborator.get("id")),
        notes: digitalRecord.get("notes") || "",
        what_happened_notes: event.get("what_happened_notes") || "",
        why_it_happened_notes: event.get("why_it_happened_notes") || "",
        what_went_well_notes: event.get("what_went_well_notes") || "",
        what_went_bad_notes: event.get("what_went_bad_notes") || "",
        learning_points_notes: event.get("learning_points_notes") || "",
        status: event.get("status").toUpperCase(),
        initial_actions_notes: event.get("initial_actions_notes") || "",
        prevent_reoccurrence_notes: event.get("prevent_reoccurrence_notes") || "",
        related_files: event.get("related_files", List()).toJS(),
      }
      case COSHH:
        const coshh = digitalRecord.get("coshh");
        return {
          type: coshh.get("type") || "",
          product_name: coshh.get("product_name") || "",
          used_by: coshh.get("used_by") || "",
          used_for: coshh.get("used_for") || "",
          location: coshh.get("location") || "",
          amount_used: coshh.get("amount_used") || "",
          usage_frequency: coshh.get("usage_frequency") || "",
          usage_conditions: coshh.get("usage_conditions") || "",
          signal_word: coshh.get("signal_word") || "",
          hazard_statement: coshh.get("hazard_statement") || "",
          risks_to_health: coshh.get("risks_to_health") || "",
          precautionary_statement: coshh.get("precautionary_statement") || "",
          safety_measures: coshh.get("safety_measures") || "",
          ppe_required: coshh.get("ppe_required") || "",
          first_aid_proceedures: coshh.get("first_aid_proceedures") || "",
          emergency_proceedures: coshh.get("emergency_proceedures") || "",
          special_storage: coshh.get("special_storage") || "",
          disposal_measures: coshh.get("disposal_measures") || "",
          health_monitoring: coshh.get("health_monitoring") || "",
          additional_info: coshh.get("additional_info") || "",
          related_files: coshh.get("related_files", List()).toJS(),
          assign_to: digitalRecord.getIn(['responsible_staff', 'id']),
          safety_pictograms: coshh.get("safety_pictograms", List()).toJS(),
          notes: digitalRecord.get("notes") || "",
        }
    default:
      break;
  }
}

export function getRecordFormByType(recordType) {
  switch(recordType) {
    case EMERGENCY_DRUGS_AND_EQUIPMENT:
      return EmergencyDrugsAndEquipmentForm;
    case FIRE_DRILL:
      return FireDrillForm;
    case EVENT:
      return EventForm;
      case COSHH:
        return CoshhForm;
    default:
      break;
  }
}
