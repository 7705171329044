import React from 'react';

import NoAuth from '^/components/app/users/login/NoAuth';
import LoggedOutLogo from '^/components/app/users/LoggedOutLogo';

const InvalidEmailVerification = () => (
  <div>
    <LoggedOutLogo />
    <NoAuth>
      <div className="form-padded">
        <p>
          This email verification link is no longer valid. Please contact Code,
          who will verify your email to allow access to iComply.
        </p>
      </div>
    </NoAuth>
  </div>
);

export default InvalidEmailVerification;
