import { List } from 'immutable';
import makeRsaAction from '^/middleware/makeRsaAction';
import { makeAsyncActionSet } from '^/actions/utils';
import { formatBackendDate } from '^/utils';
import * as _ from 'underscore';
import {
  ALL_PRACTICE_MEMBERS,
  ACTIVITIES,
  MY_ACTIVITIES,
  TASKS,
  DOCUMENT_REVIEWS_BY_MONTH,
} from '^/consts/collectionKeys';
import { PRIORITIES } from '^/models/constants';

export const ITEMS_PER_PAGE = 12;
const WHOLE_COLLECTION_PAGE_SIZE = 10000;

function flattenFilters(filters) {
  const flatFilters = Object.assign({}, filters);

  for (const key in filters) {
    if (typeof filters[key] === 'object' && !_.isArray(filters[key])) {
      for (const subKey in filters[key]) {
        if (filters[key][subKey]) {
          flatFilters[key + '_' + subKey] = filters[key][subKey];
        }
      }

      delete flatFilters[key];
    }
  }

  return flatFilters;
}

export const GET_COLLECTION = makeAsyncActionSet('GET_COLLECTION');
export function _getCollection(
  collectionName,
  url,
  opts = {}
) {
  let {
    shouldAppend,
    searchString,
    page,
    filters,
    pageSize,
  } = opts;

  const {
    additionalParams,
    method
  } = opts;

  shouldAppend = !!shouldAppend;
  page = page || 1;
  pageSize = pageSize || ITEMS_PER_PAGE;
  searchString = searchString || '';
  filters = flattenFilters(filters);

  const action = makeRsaAction(
    GET_COLLECTION,
    url,
    method || 'GET',
    Object.assign({}, additionalParams, {
      search: searchString,
      page: page,
      page_size: pageSize,
    }, filters),
    Object.assign({}, additionalParams, {
      collectionName: collectionName,
      search: searchString,
      page: page,
      shouldAppend: shouldAppend,
      filters: filters,
      source: collectionName
    })
  );
  return action;
}

export const ADD_TO_COLLECTION = makeAsyncActionSet('ADD_TO_COLLECTION');
export function _addToCollection(collectionName, url, data) {
  return makeRsaAction(
    ADD_TO_COLLECTION,
    url,
    'POST',
    data,
    { collectionName, source: collectionName }
  );
}

export const DELETE_FROM_COLLECTION = makeAsyncActionSet('DELETE_FROM_COLLECTION');
export function _deleteFromCollection(collectionName, url, id) {
  return makeRsaAction(
    DELETE_FROM_COLLECTION,
    url,
    'DELETE',
    null,
    { collectionName: collectionName, id, source: collectionName }
  );
}

export function getCollection(type, opts, name = type) {
  return _getCollection(name, `/${type}/`, opts);
}

export function getCollectionFromUrl(url, opts, name) {
  return _getCollection(name, url, opts);
}

export function addItem(type, data, name = type) {
  return _addToCollection(name, `/${type}/`, data);
}

export function deleteItem(type, id, name = type) {
  return _deleteFromCollection(name, `/${type}/${id}/`, id);
}

export function getAllCollection(type, opts, name) {
  return getCollection(type, Object.assign({}, opts, {pageSize: WHOLE_COLLECTION_PAGE_SIZE}), name);
}

export function getMyActivities(opts) {
  return getAllCollection(MY_ACTIVITIES, opts, ACTIVITIES);
}

export function loadTasksForMonth(month, practice) {
  const date = formatBackendDate(month);
  return getAllCollection(`tasks/by-month/${date}`, {filters: {practice}}, TASKS);
}

export function loadDocumentReviewsForMonth(month, filters = List()) {
  const date = formatBackendDate(month);
  const removeOperationalPriorityFromFilters = filters.filter((
    priority) => priority !== PRIORITIES.OPERATIONAL
  ).toJS();

  return getAllCollection(
    `document-reviews/by-month/${date}`,
    {filters: {priority: removeOperationalPriorityFromFilters}},
    DOCUMENT_REVIEWS_BY_MONTH
  );
}

export const CLEAR_COLLECTION = 'CLEAR_COLLECTION';
export function clearCollection(collectionName) {
  return { type: CLEAR_COLLECTION, payload: collectionName };
}

export const CLEAR_COLLECTION_FILTER = 'CLEAR_COLLECTION_FILTER';
export function clearCollectionFilter(collectionName) {
  return { type: CLEAR_COLLECTION_FILTER, payload: collectionName };
}

export function getAllPracticeMembers(practiceId) {
  return getAllCollection(`${ALL_PRACTICE_MEMBERS}/${practiceId}`, {}, ALL_PRACTICE_MEMBERS);
}
