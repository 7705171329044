import React from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import classNames from 'classnames';

import { getActivityTypeText, isOverdue } from '^/models/activities';
import ActivityStatusIcon from '^/components/app/activities/ActivityStatusIcon';
import ActivityNav from '^/components/app/activities/generic-activity/ActivityNav';
import ActivitySidebar from '^/components/app/activities/generic-activity/ActivitySidebar';
import ActivityDate from '^/components/app/activities/ActivityDate';
import ActivityEvidenceNotesSave from '^/components/app/activities/generic-activity/ActivityEvidenceNotesSave';
import BackToCalendarLink from '^/components/app/activities/generic-activity/BackToCalendarLink';
import ResponsibleStaff from '^/components/app/activities/ResponsibleStaff';
import ActivityAudits from '^/components/app/digital-tools/audit/activity-audits';
import { ActivityPriority } from '^/components/app/activities/ActivityPriority';
import { ActivityTags } from '^/components/app/activities/ActivityTags';


const AdminStepBacklink = ({ adminStep }) => {
  const { id, title, days_overdue } = adminStep.toObject();

  return (
    <span>
      <Link className="back-link" to={`/page/activities/${id}/`}>
        Monthly Focus {title}
      </Link>
      {isOverdue(adminStep) &&
        days_overdue > 0 &&
        ` (${days_overdue} days outstanding)`}
    </span>
  );
};

export const GenericActivity = props => {
  const { activity, displayActionsTaken } = props;
  const admin_step = activity.getIn(['compliance_review_field', 'admin_step']);
  const auditType = activity.get('audit_type');
  const inProgressAudits = activity.get('audits_in_progress');

  return (
    <div className="wrapper mb-2">
      <div className="row">
        <ActivityNav activity={activity} adminStep={admin_step} />

        <div className="col-2-3 bordered-left">
          <div className={classNames('back-link-container', 'relative')}>
            {admin_step ? (
              <AdminStepBacklink adminStep={admin_step} />
            ) : (
              <BackToCalendarLink month={moment(activity.get('start_date'))} />
            )}
            <ActivityStatusIcon activity={activity} />
          </div>


          <ActivityPriority activity={activity}/>
          <h1>{activity.get('title')}</h1>

          <div className="metadata">
            {getActivityTypeText(activity.get('type'))}

            <span className="ml-1 mr-1">&bull;</span>

            <ResponsibleStaff />

            <span className="mr-1">&bull;</span>

            <ActivityDate activity={activity} adminStep={admin_step} />
            
            <span className="ml-1 mr-1">&bull;</span>
            <ActivityTags activity={activity}/>
          </div>

          <hr className="strong hidden-m" />

          <div className="row">
            <div className="col-3-4">
              <div className="collapsible-section-activities">
                <h2>Description</h2>
                <span
                  dangerouslySetInnerHTML={{__html: activity.get('description')}}
                  className="user-created-markup"
                />
              </div>
              <hr />
              {auditType && (
                <div>
                  <ActivityAudits
                    activityId={activity.get('id')}
                    auditType={{
                      id: auditType.get('id'),
                      name: auditType.get('name'),
                    }}
                    inProgressAudits={
                      inProgressAudits && inProgressAudits.map(inProgressAudit => ({
                        id: inProgressAudit.get('id'),
                        modified: inProgressAudit.get('modified'),
                        status: inProgressAudit.get('status'),
                        audit_score: inProgressAudit.get('audit_score'),
                        user: {
                          id: inProgressAudit.getIn(['user', 'id']),
                          email: inProgressAudit.getIn(['user', 'email']),
                          full_name: inProgressAudit.getIn(['user', 'full_name']),
                        },
                      }))
                    }
                  />
                  <hr />
                </div>
              )}
              <ActivityEvidenceNotesSave activity={activity} displayActionsTaken={displayActionsTaken} />
            </div>

            <ActivitySidebar activity={activity} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericActivity;
