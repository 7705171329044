import React from 'react';
import { connect } from 'react-redux';
import PureComponent from '^/components/common/PureComponent';
import If from '^/components/common/If';

export class PermissionsComponent extends PureComponent {
  render() {
    const { children, hasPermission, user } = this.props;

    return (
      <If condition={hasPermission(user)}>
        {children}
      </If>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.userProfile
  };
};

export default connect(mapStateToProps)(PermissionsComponent);
