import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

import PureComponent from '^/components/common/PureComponent';
import Icon from '^/components/app/content/Icon';
import WarningBlock from '^/components/app/widgets/WarningBlock';
import { hasSucceeded } from '^/consts/responseStates';
import { closeModal } from '^/actions/modals';
import { resetResponse } from '^/actions/actions';
import { reassignPrivateDocumentsReloadAndClose } from '^/actions/actionSequences';
import ReassignPrivateDocumentsCopiesListForm from './ReassignPrivateDocumentsCopiesListForm';


export class ReassignPrivateDocumentsModal extends PureComponent {
  componentWillMount() {
    this.props.resetResponse('reassignPrivateDocuments');
  }

  onSubmit(data) {
    this.props.reassignPrivateDocumentsReloadAndClose(this.props.currentLoggedInPracticeMember.get('id'), data);
  }

  render() {
    const {
      templates,
      memberSelectValue,
      currentPracticeMembers,
      response
    } = this.props;

    const privateDocumentIds =
    templates
      .map(privateDocument => privateDocument.get('id'));

    const initialValues = Map(
      privateDocumentIds
        .map(id => [id, memberSelectValue || currentPracticeMembers.first().getIn(['user', 'staffdetail', 'id'])])
    ).toJS();

    return (
      <div>
        <WarningBlock>
          <p className="small-text">
            <strong>Warning:</strong> Once you have reassigned your private documents to another user, you will no
            longer be able to access them!
          </p>
          <p className="small-text">
            Only do this if for instance you are leaving the system.
          </p>
        </WarningBlock>

        <ReassignPrivateDocumentsCopiesListForm
          key={memberSelectValue}
          currentPracticeMembers={currentPracticeMembers}
          templates={templates}
          fields={privateDocumentIds.toJS()}
          initialValues={initialValues}
          onSubmit={data => this.onSubmit(data)}
          response={response}
          onCancel={this.props.closeModal}
        />

        {hasSucceeded(response) && (
          <div>
            <p className="alert-success bold-text mt-1">
              <Icon type="check" className="inline-icon complete" /> Documents reassigned.
              <span className="help-block">Your private documents have been reassigned.</span>
            </p>

            <div className="row">
              <div className="col-1">
                <a className="btn btn-default pull-right" onClick={this.props.closeModal}>
                  Done
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const selectCurrentPracticeMembers = createSelector(
  [
    state => state.currentPractice.get('members'),
    state => state.userProfile.get('id')
  ],
  (members, loggedInUserID) => members.sort((a, b) => {
    if (a.getIn(['user', 'id']) === loggedInUserID) {
      return -1;
    }

    if (b.getIn(['user', 'id']) === loggedInUserID) {
      return 1;
    }

    const aName = a.getIn(['user', 'staffdetail', 'full_name']);
    const bName = b.getIn(['user', 'staffdetail', 'full_name']);

    if (aName < bName) {
      return -1;
    }

    if (bName < aName) {
      return 1;
    }

    return 0;
  })
);

export function mapStateToProps(state) {
  const loggedInUserID = state.userProfile.get('id');

  return {
    currentPracticeMembers: selectCurrentPracticeMembers(state),
    response: state.responses.get('reassignPrivateDocuments'),
    currentLoggedInPracticeMember: state.currentPractice.get('members').find(
      member => member.getIn(['user', 'id']) === loggedInUserID
    ),
  };
}

export default connect(
  mapStateToProps,
  { closeModal, resetResponse, reassignPrivateDocumentsReloadAndClose }
) (ReassignPrivateDocumentsModal);
