import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { getAuditReviewCategories } from '^/actions/audit';
import { AuditReviewCategory } from '^/components/app/digital-tools/audit/types';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';
import ExpandAllButton from '^/components/app/content/ExpandAllButton';
import { selectAuditCategoriesById } from '^/selectors/audit';
import { StoreState } from '^/store/types';
import ReviewPageWrapper from './review-page-wrapper';

type RouteProps = RouteComponentProps<{ auditId: string }, {}>;

interface StateProps {
  reviewCategories: readonly AuditReviewCategory[] | null;
  isLoading: boolean;
}

interface DispatchProps {
  getAuditReviewCategories: typeof getAuditReviewCategories;
}

type Props = RouteProps & StateProps & DispatchProps;

class AuditReviewQAndA extends React.Component<Props, {}> {
  public componentDidMount() {
    if (!this.props.reviewCategories) {
      this.props.getAuditReviewCategories(this.props.params.auditId);
    }
  }

  public render() {
    return (
      <ReviewPageWrapper
        auditId={this.props.params.auditId}
        loadingContent={this.props.isLoading}
        loadingContentFailed={!this.props.reviewCategories}
        renderContent={this.renderContent}
        renderExpandButton={this.renderExpandButton}
      />
    );
  }

  private renderExpandButton = () => {
    if (!this.props.reviewCategories) {
      return null;
    }

    return (
      <ExpandAllButton
        className="btn btn-default pull-right print-hide ml-1"
        collapseIds={this.props.reviewCategories.map(category => category.id)}
      />
    );
  };

  private renderContent = () => {
    const { reviewCategories } = this.props;

    if (!reviewCategories) {
      return null;
    }

    return (
      <div className="audit-review-categories">
        {reviewCategories.map((category, categoryIndex) => (
          <div key={category.id} className="collapsible-section">
            <div className="audit-review-question-title-wrapper">
              <div>
                <h2>{`${categoryIndex + 1}. ${category.name}`}</h2>
                <p>{category.subtitle}</p>
              </div>
              <CollapseButton collapseId={category.id} />
            </div>
            <Collapse initiallyCollapsed collapseId={category.id}>
              <div className="audit-review-table-wrapper">
                <table className="audit-review-table">
                  <tbody>
                    <tr>
                      <th>Question</th>
                      <th>Comments</th>
                      <th>Answer</th>
                      <th>Result</th>
                    </tr>
                    {category.questions.map((question, questionIndex) => (
                      <tr key={question.id}>
                        <td>{`${categoryIndex + 1}.${questionIndex + 1} ${
                          question.question_text
                        }`}</td>
                        <td>
                          <p className="pre-wrap audit-review-table-note">
                            {question.note}
                          </p>
                        </td>
                        <td>{question.selected_response.text}</td>
                        <td>{question.is_correct ? 'CORRECT' : 'INCORRECT'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Collapse>
          </div>
        ))}
      </div>
    );
  };
}

export { AuditReviewQAndA as AuditReviewQAndAUnconnected };

const mapStateToProps = (state: StoreState, props: RouteProps): StateProps => ({
  reviewCategories: selectAuditCategoriesById(state, props.params.auditId),
  isLoading: state.thunkRequests.getAuditReviewCategories.isLoading,
});

export default connect<StateProps, DispatchProps, RouteProps>(mapStateToProps, {
  getAuditReviewCategories,
})(AuditReviewQAndA);
