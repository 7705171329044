import React from 'react';
import PureComponent from '^/components/common/PureComponent';

export class FilterSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { filterKey, onFilterChange } = this.props;
    onFilterChange(filterKey, event.target.value);
  }

  render() {
    const { filterKey, options, label, isDisabled, value } = this.props;
    return (
      <div>
        <label className="display-block mb-1-4 light-text" htmlFor={filterKey}>
          {label}
        </label>
        <select
          className="form-select full-width"
          id={filterKey}
          onChange={this.onChange}
          disabled={isDisabled}
          value={value}
        >
          <option value="">All</option>
          {options.map(option => (
            <option
              key={`${option.value}`}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default FilterSelect;
