import React from 'react';
import { connect } from 'react-redux';

import { createGroupFolderAndCloseModal } from '^/actions/groupDocuments';
import { GROUP_TEMPLATE_DOCUMENT_FOLDERS } from '^/consts/collectionKeys';
import CreateGroupFolderForm from './CreateGroupFolderForm';

const CreateGroupFolderModal = props => {
  return (
    <div className="modal-body">
      <div className="p-1">
        <h5 className="text-uppercase">
          Add a new folder
        </h5>
      </div>
      <CreateGroupFolderForm
        onSubmit={props.createGroupFolderAndCloseModal}
        response={props.response}
      />
    </div>
  );
};

export { CreateGroupFolderModal as UnconnectedCreateGroupFolderModal };

const mapStateToProps = state => ({
  response: state.responses.getIn(['createItem', GROUP_TEMPLATE_DOCUMENT_FOLDERS])
});

export default connect(mapStateToProps, {
  createGroupFolderAndCloseModal
})(CreateGroupFolderModal);
