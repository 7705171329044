import React from 'react';
import { Map } from 'immutable';

import LandingCard from '^/components/app/groups/insights/LandingCard';
import {
  INSIGHTS,
  INSIGHTS_API_URLS,
  PATHS,
  GROUP_DOCUMENTS_ADOPTED_THRESHOLDS,
  DATABASE_MODEL_NAME,
} from '^/components/app/groups/insights/constants';
import { getSubGroupFilterFromPath } from '^/components/app/groups/insights/utils';
import withInsight from '^/components/app/groups/insights/hoc/withInsight';
import withLastUpdatedTimestampData from '^/components/app/groups/insights/hoc/withLastUpdatedTimestampData';
import GaugeChart from '^/components/app/groups/insights/visualisations/GaugeChart';

const GroupDocumentsLanding = (props) => {
  return (
    <LandingCard
      title="Group documents adopted"
      icon="file-solid"
      link={PATHS.GROUP_DOCUMENTS}
      linkText="VIEW MORE"
      lastUpdatedTimestamp={props.lastUpdatedTimestamp}
      query={props.query}
    >
      <div className="group-documents-landing">
        {!isNaN(props.data) && (
        <GaugeChart
          value={props.data}
          bad={GROUP_DOCUMENTS_ADOPTED_THRESHOLDS.BAD}
          ok={GROUP_DOCUMENTS_ADOPTED_THRESHOLDS.OK}
          good={GROUP_DOCUMENTS_ADOPTED_THRESHOLDS.GOOD}
        />)}
        {(props.data && <p className="text-center">% of group docs adopted by practices</p>)}
      </div>
    </LandingCard>
  );
};

export { GroupDocumentsLanding };

const GroupDocumentsLandingWithLastUpdatedTimestamp = withLastUpdatedTimestampData(
  DATABASE_MODEL_NAME.DOCUMENT
)(GroupDocumentsLanding);

export default withInsight({
  insight: INSIGHTS.GROUP_DOCUMENTS_ADOPTED_LANDING,
  url: INSIGHTS_API_URLS.GROUP_DOCUMENTS_ADOPTED,
  getFilters: () => (
    Map({
      practice_sub_group_id: getSubGroupFilterFromPath(),
    })
  )
})(GroupDocumentsLandingWithLastUpdatedTimestamp);
