import React from 'react';

import PureComponent from '^/components/common/PureComponent';
import NoAuth from '^/components/app/users/login/NoAuth';

export default class RedeemInvite extends PureComponent {
  render() {
    return (
      <NoAuth>
        <div className="p-1">
          <h2>Redeem Invite</h2>
          <p className="text-error">
            This invite has expired please contact your account administrator to activate your account.
          </p>
        </div>
      </NoAuth>
    );
  }
}
