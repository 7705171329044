export const MY_TASK_ASSIGNEE = 'tasks/my-taskassignees';

export const TEMPLATE_DOCUMENT_VERSION = 'documents/template';
export const TEMPLATE_DOCUMENT_ACTUAL_VERSION = 'documents/template-version';
export const ACTIVITY_RELATED_TEMPLATE = 'documents/template-versions';
export const ADMIN_TEMPLATE_DOCUMENT_VERSION = 'admin/template-document-versions';
export const ADMIN_TEMPLATE_DOCUMENT = 'admin/template-documents';

export const PRACTICES = 'practices';
export const PRACTICES_DETAILS = 'practices/detail-list';
export const MY_PRACTICES = 'practices/my-practices';
export const GROUP_PRACTICES = 'practices/group-practices';
export const LIVE_CYCLES_GROUP_PRACTICES = 'practices/live-cycles-group-practices';
export const LIVE_CYCLES_GROUP_PRACTICES_UPDATE = 'practices/live-cycles-group-practices-update';
export const ALL_PRACTICE_MEMBERS = 'practices/all-members';
export const UPLOADED_ACTIVITY_TASK_FILES = 'documents/uploaded-files';
export const STAFF_PRACTICE = 'practices/member';
export const CYCLES = 'cycles';
export const ADMIN_USER = 'admin/users';
export const USERS = 'users';
export const STAFF_ALERTS = 'staff-alerts';
export const ALERTS = 'alerts';
export const GROUP_DOCUMENT_ALERTS = 'alerts/document-alerts-for-current-group';
export const ACTIVITIES = 'activities';
export const MY_ACTIVITIES = 'activities/my-activities';
export const COMPLIANCE_REPORT = 'activities/compliance-report';
export const GROUP_CONFIG_COMPLIANCE_REVIEWS = 'activities/group-admin-steps';
export const GROUP_CONFIG_AUDITS = 'activities/group-security-audits';
export const GROUP_CONFIG_ASSESSMENTS = 'activities/group-assessments';
export const GROUP_PRACTICE_MEETINGS = 'activities/group-practice-meetings';
export const TASKS = 'tasks';
export const TASKS_COMPLETE = 'tasks/complete';
export const MY_TASKS = 'tasks/my-tasks';
export const GROUP_TASKS = 'tasks/group-tasks';
export const MY_TASKS_SIMPLE_LIST = 'tasks/my-tasks/simple-list';
export const MY_TASKS_COMPLETE = 'tasks/my-tasks/complete';
export const MY_TASKS_INCOMPLETE = 'tasks/my-tasks/incomplete';
export const MY_TASKS_MANAGE = 'tasks/my-tasks/manage';
export const NEWS = 'news';
export const RESPONSIBILITIES = 'users/responsibilities';
export const GROUP_TASK_RESPONSIBILITIES = 'tasks/group-tasks/responsibilities';
export const ROLES = 'users/roles';
export const CYCLE_GROUP_TASKS = 'cycles/group-tasks';
export const TEMPLATE_TASKS = 'cycles/template-tasks';
export const CYCLE_RESPONSIBILITIES = 'cycles/cycle-responsibilities';
export const TEMPLATE_CATEGORIES = 'documents/template-categories';
export const TEMPLATE_FOLDERS = 'documents/template-folders';
export const TEMPLATE_PRIORITIES = 'documents/template-priorities';
export const TEMPLATE_ADMIN_STEPS = 'documents/template-admin-steps';
export const GROUP_TEMPLATE_DOCUMENT_FOLDERS = 'documents/group-template-document-folders';
export const GROUP_TEMPLATE_DOCUMENT_DRAFTS = 'documents/group-template-document-drafts';
export const GROUP_CREATED_TEMPLATE_DOCUMENTS = 'documents/group-template-documents';
export const DOCUMENT_READ_REQUESTS = 'documents/documents-read-requests';
export const DOC_READ_REQUEST_BULK_SEND = 'documents/documents-read-requests-bulk-send';
export const TEMPLATES = 'documents/templates';
export const DOCUMENTS = 'documents/templates/latest-versions';
export const PRIVATE_TEMPLATES_COPIES = 'documents/templates/private-copies';
export const GROUP_TEMPLATE_DOCUMENTS = 'documents/templates/latest-group-versions';
export const MANAGED_PRACTICES = 'practices/managed-practices';
export const COMPLIANCE_SYSTEMS = 'activities/cqc-systems';
export const QPS_FILES = 'files/qps-files';
export const HELP = 'help';
export const GROUP_DETAILS = 'practices/practice-group-detail';
export const DASHBOARD_DOCUMENTS_READ_REQUESTS = 'documents/dashboard-documents-read-requests';
export const DOCUMENT_VERSIONS_TO_READ = 'documents/document-versions-to-read';
export const GET_BULK_DOCUMENT_TO_SEND = 'documents/document-versions-to-read-bulk-send';
export const TEMPLATE_VERSION_COPIES = 'documents/template-version-copies';
export const DOCUMENT_REVIEWS = 'document-reviews';
export const DOCUMENT_REVIEWS_BY_MONTH = 'document-reviews/by-month';

export const ADMIN_TEMPLATE_DOCUMENT_WORKING_VERSIONS = 'admin/template-document-working-versions';
export const ADMIN_COMPLIANCE_SYSTEMS = 'admin/cqc-systems';

const SEARCHED = 'SEARCHED_';
export const SEARCHED_DOCS = `${SEARCHED}${DOCUMENTS}`;
export const SEARCHED_ACTIVITIES = `${SEARCHED}${ACTIVITIES}`;
export const SEARCHED_TASKS = 'tasks/search-cycle';
export const SEARCHED_UPLOADED_ACTIVITY_TASK_FILES = `${SEARCHED}${UPLOADED_ACTIVITY_TASK_FILES}`;

export const ADMIN_AUDIT_CATEGORIES = 'admin/audits/categories'
export const DIGITAL_RECORDS = 'digital-records/single-record-types';
export const DIGITAL_RECORDS_LIST = 'digital-records';
export const DIGITAL_RECORDS_CREATE = 'digital-records/create';
export const RECORD_GROUPS = 'digital-records/record-groups';
export const CHECKLIST_RECORD_GROUP = 'digital-records/checklists';

export const PRACTICE_GROUP_SYSTEMS = 'group-insights/practice-group-systems';
