import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { confirmChangeDateOnActivityAndCloseModal } from '^/actions/actionSequences';
import { closeModal } from '^/actions/modals';
import { isPending } from '^/consts/responseStates';
import { ACTIVITIES } from '^/consts/collectionKeys';

import Loading from '^/components/app/content/Loading';
import LiveButton from '^/components/app/forms/LiveButton';


export const ConfirmChangeActivityDateModal = props => {
  const {
    activity,
    date,
    response,
    confirm
  } = props;

  if (isPending(response)) {
    return <Loading />;
  }

  const hasDependencies = !activity.get('dependencies').isEmpty();

  return (
    <div className="mb-1">
      <p>
        Please confirm that you wish to change the
        date of this activity.
      </p>

      {hasDependencies &&
        <div>
          <p>
            The following activities have a dependency on this activity; you
            may wish to move their due dates as well:
          </p>
          <ul>
            {activity.get('dependencies').map(each =>
              <li key={each.get('id')}>{each.get('title')}</li>
            )}
          </ul>
        </div>
      }

      <LiveButton
        className="btn btn-save pull-right mt-1 mb-1"
        onClick={() => confirm(date, activity)}
        response={response}
        pendingMessage="Updating..."
      >
        Confirm
      </LiveButton>
    </div>
  );
};

ConfirmChangeActivityDateModal.propTypes = {
  date: React.PropTypes.object.isRequired,
  response: ImmutablePropTypes.map,
  activity: ImmutablePropTypes.map.isRequired,
  onChange: React.PropTypes.func,
  confirm: React.PropTypes.func,
};

export function mapStateToProps(state) {
  return {
    response: state.responses.getIn(['updateItem', ACTIVITIES])
  };
}

export default connect(
  mapStateToProps,
  {
    confirm: (date, activity) => confirmChangeDateOnActivityAndCloseModal(date, activity),
    onCancel: closeModal
  }
) (ConfirmChangeActivityDateModal);
