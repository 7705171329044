import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import {
  removeUploadedFileReloadActivityAndCloseModal,
  removeUploadedFileReloadTaskAssigneeFilesAndCloseModal
} from '^/actions/actionSequences';

import LiveButton from '^/components/app/forms/LiveButton';

export const RemoveUploadedActivityFileModal = ({
  response, activityId, taskAssigneeId, taskId, removeFromActivity, removedFromTaskAssignee, uploadedFile, cancel
}) =>
  <div>
    <p className="mb-2">
      Are you sure you want to remove <b>{uploadedFile.get('name')}</b> from this {activityId ? 'activity' : 'task'}?
    </p>

    <button onClick={cancel} className="btn btn-default mr-1">Cancel</button>

    <LiveButton
      response={response}
      onClick={() =>
        activityId ?
          removeFromActivity(activityId, uploadedFile.get('id')) :
          removedFromTaskAssignee(taskAssigneeId, taskId, uploadedFile.get('id'))}
      className="btn btn-warning"
    >
      Remove
    </LiveButton>

  </div>;

export const mapStateToProps = (state) => ({
  response: state.responses.get('removeUploadedFile'),
});

RemoveUploadedActivityFileModal.propTypes = {
  activityId: React.PropTypes.string,
  taskAssigneeId: React.PropTypes.string,
  taskId: React.PropTypes.string,
  uploadedFile: React.PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  {
    removeFromActivity: removeUploadedFileReloadActivityAndCloseModal,
    removedFromTaskAssignee: removeUploadedFileReloadTaskAssigneeFilesAndCloseModal,
    cancel: closeModal,
  }
) (RemoveUploadedActivityFileModal);
