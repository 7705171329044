import makeRsaAction from '^/middleware/makeRsaAction';
import { makeAsyncActionSet } from '^/actions/utils';
import {
  ACTIVITIES,
  ACTIVITY_RELATED_TEMPLATE,
  TEMPLATE_DOCUMENT_VERSION,
  DOCUMENT_READ_REQUESTS,
  DOCUMENT_VERSIONS_TO_READ,
  DOC_READ_REQUEST_BULK_SEND,
  GROUP_TEMPLATE_DOCUMENT_FOLDERS,
} from '^/consts/collectionKeys';
import { formatBackendDate } from '^/utils';

export const LOAD_ITEM = makeAsyncActionSet('LOAD_ITEM');

export function _loadItem(itemType, id, url, method = 'GET') {
  const action = makeRsaAction(
    LOAD_ITEM,
    url,
    method,
    null,
    { itemType, id, source: itemType }
  );
  return action;
}

export const CLEAR_ITEM = 'CLEAR_ITEM';

export function clearItem(itemType) {
  return {
    type: CLEAR_ITEM,
    payload: itemType
  };
}

export const UPDATE_ITEM = makeAsyncActionSet('UPDATE_ITEM');

export function _updateItem(collectionName, url, data, method = 'PUT', merge = false) {
  return makeRsaAction(
    UPDATE_ITEM,
    url,
    method,
    data,
    { merge, collectionName, source: collectionName }
  );
}

export const CREATE_ITEM = makeAsyncActionSet('CREATE_ITEM');

export function _createItem(collectionName, url, data, method = 'POST') {
  return makeRsaAction(
    CREATE_ITEM,
    url,
    method,
    data,
    { collectionName, source: collectionName }
  );
}

export const GET_ITEM_OPTIONS = makeAsyncActionSet('GET_ITEM_OPTIONS');

export function _getItemOptions(collectionName, url) {
  return makeRsaAction(
    GET_ITEM_OPTIONS,
    url,
    'OPTIONS',
    null,
    { collectionName, source: collectionName }
  );
}

export function loadItem(type, id, name = type) {
  return _loadItem(name, id, `/${type}/${id}/`);
}

export function updateItem(type, id, data, name = type) {
  return _updateItem(name, `/${type}/${id}/`, data, 'PUT');
}

export function patchItem(type, id, data, source = type, merge = false) {
  return _updateItem(source, `/${type}/${id}/`, data, 'PATCH', merge);
}

export function createItem(type, data, name = type, method) {
  return _createItem(name, `/${type}/`, data, method);
}

export function getItemOptions(type, id, name = type) {
  if (!id) {
    return _getItemOptions(name, `/${type}/`);
  }
    return _getItemOptions(name, `/${type}/${id}/`);

}

export const SEND_DOCUMENT_READ_TO_TEAM = makeAsyncActionSet('SEND_DOCUMENT_READ_TO_TEAM');
export function sendDocReadToTeam(data, isActivityTemplatePage, activityDocumentId) {
  return makeRsaAction(
    SEND_DOCUMENT_READ_TO_TEAM,
    `/${DOCUMENT_READ_REQUESTS}/`,
    'POST',
    data,
    {
      collectionName: DOCUMENT_READ_REQUESTS,
      isActivityTemplatePage,
      activityDocumentId
    }
  );
}

export const ADD_READ_REQUESTS_TO_DOC_READ = makeAsyncActionSet('ADD_READ_REQUESTS_TO_DOC_READ');
export function addReadRequestsToDocRead(documentVersionToReadId, data) {
  return makeRsaAction(
    ADD_READ_REQUESTS_TO_DOC_READ,
    `/${DOCUMENT_VERSIONS_TO_READ}/${documentVersionToReadId}/add-read-requests/`,
    'POST',
    data,
  );
}

export const MARK_DOCUMENT_AS_READ = makeAsyncActionSet('MARK_DOCUMENT_AS_READ');
export function markDocumentAsRead(documentReadRequestId) {
  return makeRsaAction(
    MARK_DOCUMENT_AS_READ,
    `/${DOCUMENT_READ_REQUESTS}/${documentReadRequestId}/mark-as-read/`,
    'POST',
    { collectionName: DOCUMENT_READ_REQUESTS }
  );
}

export const ARCHIVE_DOCUMENT_TO_READ = makeAsyncActionSet('ARCHIVE_DOCUMENT_TO_READ');
export function archiveDocumentToRead(documentVersionToReadId) {
  return makeRsaAction(
    ARCHIVE_DOCUMENT_TO_READ,
    `/${DOCUMENT_VERSIONS_TO_READ}/${documentVersionToReadId}/archive/`,
    'POST',
    { collectionName: DOCUMENT_VERSIONS_TO_READ }
  );
}

export const ARCHIVE_DOCUMENT_FOLDER = makeAsyncActionSet('ARCHIVE_DOCUMENT_FOLDER');
export function archiveDocumentFolder(folderId) {
  return makeRsaAction(
    ARCHIVE_DOCUMENT_FOLDER,
    `/${GROUP_TEMPLATE_DOCUMENT_FOLDERS}/${folderId}/archive/`,
    'POST',
    { collectionName: GROUP_TEMPLATE_DOCUMENT_FOLDERS }
  );
}

export const LOAD_TEMPLATE_LATEST_VERSION = makeAsyncActionSet('LOAD_TEMPLATE_LATEST_VERSION');
export function loadTemplateLatestVersion(
  templateId,
  itemType = TEMPLATE_DOCUMENT_VERSION
) {
  return makeRsaAction(
    LOAD_TEMPLATE_LATEST_VERSION,
    `/${TEMPLATE_DOCUMENT_VERSION}/${templateId}/latest-version/`,
    'GET',
    null,
    {itemType, source: itemType}
  );
}

export const LOAD_TEMPLATE_LATEST_GROUP_VERSION = makeAsyncActionSet('LOAD_TEMPLATE_LATEST_GROUP_VERSION');
export function loadTemplateLatestGroupVersion(templateId, itemType = TEMPLATE_DOCUMENT_VERSION) {
  return makeRsaAction(
    LOAD_TEMPLATE_LATEST_GROUP_VERSION,
    `/${TEMPLATE_DOCUMENT_VERSION}/${templateId}/latest-group-version/`,
    'GET',
    null,
    {itemType, source: itemType}
  );
}

export const CREATE_TEMPLATE_VERSION = makeAsyncActionSet('CREATE_TEMPLATE_VERSION');
export function createTemplateVersion(templateId, content, extraData = null) {
  const data = Object.assign(
    {},
    { content },
    extraData
  );
  return makeRsaAction(
    CREATE_TEMPLATE_VERSION,
    `/${TEMPLATE_DOCUMENT_VERSION}/${templateId}/create-version/`,
    'POST',
    data,
    {collectionName: extraData && extraData.activity ? ACTIVITY_RELATED_TEMPLATE : TEMPLATE_DOCUMENT_VERSION}
  );
}

export const RESTORE_TEMPLATE_VERSION = makeAsyncActionSet('RESTORE_TEMPLATE_VERSION');
export function restoreTemplateVersion(templateId, activityId = null) {
  const data = Object.assign(
    {},
    activityId && { activity: activityId }
  );
  return makeRsaAction(
    CREATE_TEMPLATE_VERSION,
    `/${TEMPLATE_DOCUMENT_VERSION}/${templateId}/restore-version/`,
    'POST',
    data,
    {collectionName: TEMPLATE_DOCUMENT_VERSION}
  );
}

export const SAVE_AS_WORKING_DRAFT = makeAsyncActionSet('SAVE_AS_WORKING_DRAFT');
export function saveAsWorkingDraft(templateId, content, isActivity = null, groupId, isComparisonEditor = false) {
  return makeRsaAction(
    SAVE_AS_WORKING_DRAFT,
    `/${TEMPLATE_DOCUMENT_VERSION}/${templateId}/working-draft/`,
    'POST',
    { content, group: groupId || null , is_comparison_editor : isComparisonEditor},
    { collectionName: isActivity ? ACTIVITY_RELATED_TEMPLATE : TEMPLATE_DOCUMENT_VERSION }
  );
}

export const DISCARD_WORKING_DRAFT = makeAsyncActionSet('DISCARD_WORKING_DRAFT');
export function discardWorkingDraft(templateId, groupId) {
  return makeRsaAction(
    DISCARD_WORKING_DRAFT,
    `/${TEMPLATE_DOCUMENT_VERSION}/${templateId}/working-draft/`,
    'DELETE',
    { group: groupId || null }
  );
}

export const CREATE_BULK_DOC_READ = makeAsyncActionSet('CREATE_BULK_DOC_READ');
export function createBulkDocRead(data) {
  return makeRsaAction(
    CREATE_BULK_DOC_READ,
    `/${DOC_READ_REQUEST_BULK_SEND}/`,
    'POST',
    data,
    { collectionName: DOCUMENT_READ_REQUESTS }
  );
}

export const DELETE_TEMPLATE_COPY = makeAsyncActionSet('DELETE_TEMPLATE_COPY');
export function deleteTemplateCopy(templateId) {
  return makeRsaAction(
    DELETE_TEMPLATE_COPY,
    `/${TEMPLATE_DOCUMENT_VERSION}/${templateId}/`,
    'DELETE',
    null
  );
}

export const ADOPT_TEMPLATE_VERSION = makeAsyncActionSet('ADOPT_TEMPLATE_VERSION');
export function adoptTemplateVersion(templateId, content, practice, practice_group, activityId = null) {
  const data = Object.assign({},
    { content },
    practice && { practice },
    practice_group && { practice_group },
    activityId && { activity: activityId, }
  );

  return makeRsaAction(
    ADOPT_TEMPLATE_VERSION,
    `/${TEMPLATE_DOCUMENT_VERSION}/${templateId}/adopt-version/`,
    'POST',
    data,
    {collectionName: TEMPLATE_DOCUMENT_VERSION}
  );
}

export const COPY_TEMPLATE_VERSION = makeAsyncActionSet('COPY_TEMPLATE_VERSION');
export function copyTemplateVersion(templateId, content, practice, practice_group, activityId = null) {
  const data = Object.assign({},
    { content },
    practice && { practice },
    practice_group && { practice_group },
    activityId && { activity: activityId, }
  );

  return makeRsaAction(
    COPY_TEMPLATE_VERSION,
    `/${TEMPLATE_DOCUMENT_VERSION}/${templateId}/copy-version/`,
    'POST',
    data,
    {collectionName: TEMPLATE_DOCUMENT_VERSION}
  );
}

export function copyTemplateVersionWithName(templateId, content, name, practice, practice_group, activityId = null) {
  const data = Object.assign({},
    { content, name },
    practice && { practice },
    practice_group && { practice_group },
    activityId && { activity: activityId, }
  );

  return makeRsaAction(
    COPY_TEMPLATE_VERSION,
    `/${TEMPLATE_DOCUMENT_VERSION}/${templateId}/copy-version/`,
    'POST',
    data,
    {collectionName: TEMPLATE_DOCUMENT_VERSION}
  );
}

export const REBASE_COPIED_TEMPLATE = makeAsyncActionSet('REBASE_COPIED_TEMPLATE');
export function rebaseCopiedTemplate(templateId, content, practice, activityId = null) {
  const data = Object.assign({},
    { content },
    practice && { practice },
    activityId && { activity: activityId, }
  );

  return makeRsaAction(
    REBASE_COPIED_TEMPLATE,
    `/${TEMPLATE_DOCUMENT_VERSION}/${templateId}/rebase-copy/`,
    'POST',
    data,
    {collectionName: TEMPLATE_DOCUMENT_VERSION}
  );
}

export function updateActivityDate(activity, date) {
  const dateString = formatBackendDate(date);
  return patchItem(
    ACTIVITIES,
    activity.get('id'),
    { end_date: dateString, start_date: dateString }
  );
}
