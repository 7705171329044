import React from 'react';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';
import { MODELS } from '^/permissions/capabilities';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';
import PureComponent from '^/components/common/PureComponent';

import {
  ADMIN_NEWS_CREATE,
  ADMIN_NEWS_MODEL,
  ADMIN_NEWS_EDIT_FIELDS,
  ADMIN_NEWS_ROW_FIELDS,
} from './news';

export class NewsAdmin extends PureComponent {
  handleComplete(model, data, collectionName, formName, newItem) {
    this.props.routePush('/admin/news/' + newItem.id);
  }

  render() {
    return (
      <div>
        <AdminContainer title="News">
          <AdminCreate
            permsModel={MODELS.NEWS}
            controlName={ADMIN_NEWS_CREATE}
            onComplete={this.handleComplete.bind(this)}
            collectionName={ADMIN_NEWS_CREATE}
            model={ADMIN_NEWS_MODEL}
            fields={ADMIN_NEWS_EDIT_FIELDS}
            transform={fieldConfig => fieldConfig.setIn(['content', 'kind'], 'news')}
            title="Add News Item"
          />
        </AdminContainer>

        <AdminContainer>
          <AdminList
            permsModel={MODELS.NEWS}
            title="All News Items"
            model={ADMIN_NEWS_MODEL}
            fields={ADMIN_NEWS_ROW_FIELDS}
          />
        </AdminContainer>
      </div>
    );
  }
}

export default connect(null, { routePush: routeActions.push })(NewsAdmin);
