import React from 'react';
import CompletedBy from '^/components/app/activities/CompletedBy';


const ActivitySidebar = ({activity}) => (
  <div className="col-1-4">
    <div className="sidebar-step">
      <CompletedBy activity={activity} />
    </div>
  </div>
);

export default ActivitySidebar;
