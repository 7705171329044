import React from 'react';
import classNames from 'classnames';
import AdminFormError from '^/components/admin/content/edit-create/dynamic-forms/AdminFormError';
import PureComponent from '^/components/common/PureComponent';

export function getFieldInputId(fieldName, formName) {
  return `${formName}-${fieldName}`;
}

export function getOptionId(option, fieldName, formName) {
  return `${getFieldInputId(fieldName, formName)}-option-${option.value}`;
}

export default class FieldMultiCheckbox extends PureComponent {
  onChange(options) {
    this.props.onChange(options.map(option => option.value));
  }

  onChangeCheckbox = event => {
    const checkboxValue = event.target.value;
    const selectedValues = this.props.value || [];
    return event.target.checked
      ? this.props.onChange([...selectedValues, checkboxValue])
      : this.props.onChange(
          selectedValues.filter(
            selectedValue => selectedValue !== checkboxValue
          )
        );
  };

  render() {
    const { readOnly, config, value, className, name, errors } = this.props;

    return (
      <div className="form-group">
        <div className={classNames('mb-1-4', className)}>
            <label>{config.label}:</label>
          {config.choices &&
            config.choices.map(option => (
              <div key={option.value} className="inline-text">
              <label
                key={option.value}
                htmlFor={getOptionId(option, name, 'test')}
                className="checkbox-group-label"
              >
                {option.display_name}
                <input
                  id={getOptionId(option, name, 'test')}
                  type="checkbox"
                  checked={
                    (value || []).filter(
                      selectedValue => selectedValue === option.value
                    ).length > 0
                  }
                  name={name}
                  onChange={this.onChangeCheckbox}
                  disabled={readOnly}
                  value={option.value}
                  className={"checkbox-group-admin-checkbox"}
                />
              </label>
            </div>

            ))}
          <AdminFormError errors={errors} />
        </div>
      </div>
    );
  }
}
