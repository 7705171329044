import { connect } from 'react-redux';

import { hasSucceeded, isPending, hasFailed } from '^/consts/responseStates';
import { selectInsightData } from '^/selectors/insights';

const withInsightData = (insight) => (Component) => {
  function mapStateToProps(state) {
    const response = state.responses.getIn(['loadInsightData', insight]);

    return {
      data: selectInsightData(insight)(state),
      isLoading: isPending(response),
      hasFailedToLoad: hasFailed(response),
      hasLoaded: hasSucceeded(response),
    };
  }

  return connect(mapStateToProps)(Component);
};

export default withInsightData;
