import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import {
  getHealthcheckAuditResults,
  getHealthcheckReview,
} from '^/actions/healthcheck';
import createIntroPage from './hoc/create-intro-page';
import { StoreState } from '^/store/types';
import {
  HealthcheckAuditResult,
  HealthcheckReview,
  HealthcheckStatus,
  HealthcheckTypes,
} from './types';
import AuditResultsTable from './audit-results-table';
import AuditScore from './audit-score';

interface StateProps {
  healthcheckReview: HealthcheckReview | null;
  healthcheckAuditResults: HealthcheckAuditResult[] | null;
  healthcheckReviewIsLoading: boolean;
  healthcheckAuditResultsIsLoading: boolean;
}

interface DispatchProps {
  getHealthcheckAuditResults: typeof getHealthcheckAuditResults;
  getHealthcheckReview: typeof getHealthcheckReview;
}

type OwnProps = RouteComponentProps<{ healthcheckId: string }, {}>;

export type Props = StateProps & DispatchProps & OwnProps;

export class Content extends Component<Props, {}> {
  public componentDidMount() {
    const {
      params: { healthcheckId },
    } = this.props;
    this.props.getHealthcheckAuditResults(healthcheckId);
    this.props.getHealthcheckReview(healthcheckId);
  }

  public render() {
    const {
      healthcheckAuditResults,
      healthcheckAuditResultsIsLoading,
      healthcheckReview,
      healthcheckReviewIsLoading,
    } = this.props;

    return (
      <div className="content">
        <div>
          Here are the results of your audit as a total and broken down by each
          section:
        </div>
        <div className="review-row pb-2 pt-2">
          <AuditResultsTable
            isLoading={healthcheckAuditResultsIsLoading}
            results={healthcheckAuditResults}
          />
          <AuditScore
            isLoading={healthcheckReviewIsLoading}
            review={healthcheckReview}
          />
        </div>
        <div>Click on the next button to prepare for the actions section.</div>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => {
  return {
    healthcheckReview: state.healthcheck.review,
    healthcheckAuditResults: state.healthcheck.auditResults,
    healthcheckReviewIsLoading:
      state.thunkRequests.getHealthcheckReview.isLoading,
    healthcheckAuditResultsIsLoading:
      state.thunkRequests.getHealthcheckAuditResults.isLoading,
  };
};

const ConnectedContent = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  {
    getHealthcheckAuditResults,
    getHealthcheckReview,
  }
)(Content);

export default createIntroPage<Props>({
  type: HealthcheckTypes.AuditResults,
  header: '',
  buttonText: 'Next',
  intendedFromStatus: HealthcheckStatus.AUDIT_RESULTS,
})(ConnectedContent as any); // eslint-disable-line @typescript-eslint/no-explicit-any
