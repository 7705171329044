import React from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';

import {
  setPendingUploadInForm
} from '^/actions/actions';
import { loadItem, patchItem } from '^/actions/items';
import { USERS } from '^/consts/collectionKeys';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import Icon from '^/components/app/content/Icon';
import UpdateProfileForm from '^/components/app/users/profile/UpdateProfileForm';

export class UserProfileEditPage extends PureComponent {

  componentWillMount() {
    this.props.loadItem(USERS, this.props.userId);
  }

  onSubmit(data) {
    const dataToSubmit = (
      data.staffdetail &&
      data.staffdetail.has_indemnity_provider !== 'true'
    ) ?
      fromJS(data)
        .setIn(['staffdetail', 'indemnity_provider'], null)
        .setIn(['staffdetail', 'indemnity_expiration_date'], null).toJS()
      : data;
    this.props.patchItem(USERS, this.props.userId, dataToSubmit);
  }

  getInitialValues() {
    const has_indemnity_provider = this.props.user.getIn(['staffdetail', 'indemnity_provider']) ? 'true' : 'false';
    return this.props.user.setIn(['staffdetail', 'has_indemnity_provider'], has_indemnity_provider).toJS();
  }

  render() {
    const { user, response } = this.props;

    if (!user) {
      return (
        <Loading />
      );
    }

    return (
      <div className="employee-details">
        <h1><Icon type="user" />{user.getIn(['staffdetail', 'full_name'])}</h1>
        <hr className="thin" />

        <UpdateProfileForm
          onSubmit={this.onSubmit.bind(this)}
          response={response}
          setPendingUploadInForm={this.props.setPendingUploadInForm}
          initialValues={this.getInitialValues()}
        />

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userId: state.userProfile.get('id'),
    user: state.userProfile,
    response: state.responses.getIn(['updateItem', USERS]),
  };
}

export default connect(
  mapStateToProps,
  { setPendingUploadInForm, loadItem, patchItem }
) (UserProfileEditPage);
