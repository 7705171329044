import React from 'react';
import { connect } from 'react-redux';

import FilterPill from '^/components/app/groups/insights/filters/FilterPill';
import { activitiesInsightsConfig, FILTER_KEYS } from '^/components/app/groups/insights/constants';
import { getTemplateActivitiesForCurrentGroup } from '^/actions/actions';

export const ActivityTypeFilters = (props) => (
  <ul className="mb-2 list-style-none active-types-filter">
    <FilterPill
      title="All"
      value={undefined}
      filterKey={FILTER_KEYS.TYPE}
      insightsConfig={activitiesInsightsConfig}
      additionalOnChange={props.getTemplateActivitiesForCurrentGroup}
    />
    <FilterPill
      title="Audits"
      value="AUDIT"
      filterKey={FILTER_KEYS.TYPE}
      insightsConfig={activitiesInsightsConfig}
      additionalOnChange={props.getTemplateActivitiesForCurrentGroup}
    />
    <FilterPill
      title="Risk Assessments"
      value="ASSESSMENT"
      filterKey={FILTER_KEYS.TYPE}
      insightsConfig={activitiesInsightsConfig}
      additionalOnChange={props.getTemplateActivitiesForCurrentGroup}
    />
    <FilterPill
      title="Compliance Reviews"
      value="COMPLIANCE_REVIEW"
      filterKey={FILTER_KEYS.TYPE}
      insightsConfig={activitiesInsightsConfig}
      additionalOnChange={props.getTemplateActivitiesForCurrentGroup}
    />
    <FilterPill
      title="Practice Meetings"
      value="PRACTICE_MEETING"
      filterKey={FILTER_KEYS.TYPE}
      insightsConfig={activitiesInsightsConfig}
      additionalOnChange={props.getTemplateActivitiesForCurrentGroup}
    />
    <FilterPill
      title="Tasks"
      value="TASK"
      filterKey={FILTER_KEYS.TYPE}
      insightsConfig={activitiesInsightsConfig}
      additionalOnChange={props.getTemplateActivitiesForCurrentGroup}
    />
  </ul>
);

export default connect(null, {getTemplateActivitiesForCurrentGroup})(ActivityTypeFilters);
