import { Map } from 'immutable';

const DONE_CHOICES = {
  DEFAULT: 'DEFAULT',
  DONE: 'DONE',
  N_A: 'N_A',
};

export const DONE_CHOICE_TEXTS = Map({
  [DONE_CHOICES.DONE]: 'Yes',
  [DONE_CHOICES.N_A]: 'N/A',
});

// Only one of the actions is now to be shown
export const ACTIONS = [
  {
    key: 'actions_completed',
    text: 'Actions arising from this activity have been completed or related Tasks have been scheduled',
  },
];

export const isOptional = (complianceReview) =>
  complianceReview && complianceReview.getIn(['template_activity', 'is_optional']);

export const isCompulsory = (complianceReview) =>
  complianceReview && !complianceReview.getIn(['template_activity', 'is_optional']);

export const isOperational = (complianceReview) =>
  complianceReview && complianceReview.get('is_operational');

export const isOptionalOrOperational = (complianceReview) => 
  (isOperational(complianceReview) || isOptional(complianceReview));
