import React from 'react';
import { connect } from 'react-redux';

import { setupCycleComplianceDetailsAndRedirectToOptionalAudits } from '^/actions/actionSequences';
import { transformInitialValues } from '^/models/practice';
import Loading from '^/components/app/content/Loading';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import CycleSetupHeader from '^/components/app/cycle-setup/CycleSetupHeader';
import CycleSetupComplianceDetailsForm, { getCycleSetupComplianceDetailsFormFields } from '^/components/app/cycle-setup/CycleSetupComplianceDetailsForm';


const CycleSetupComplianceDetails = ({practice, response, setup, cycleId}) =>
  <div>
    <CycleSetupHeader practice={practice}>
      Practice details
    </CycleSetupHeader>

    <HelpBlock className="mb-2">
      Review your answers to these questions about your practice, so we can make sure you are
      undertaking the correct compliance reviews and audits.
    </HelpBlock>

    <CycleSetupComplianceDetailsForm
      response={response}
      fields={getCycleSetupComplianceDetailsFormFields(practice)}
      practice={practice}
      initialValues={
        transformInitialValues(practice).compliance_details
      }
      onSubmit={data => {setup(cycleId, data);}}
    />
  </div>;


export const CycleSetupComplianceDetailsPage = props =>
  props.practice ?
    <CycleSetupComplianceDetails {...props} /> :
    <Loading />;


export const mapStateToProps = (state, props) => ({
  user: state.userProfile,
  practice: state.currentPractice,
  cycleId: props.params.uuid,
  response: state.responses.get('setupCycleComplianceDetails'),
});

export default connect(
  mapStateToProps,
  { setup: setupCycleComplianceDetailsAndRedirectToOptionalAudits }
) (CycleSetupComplianceDetailsPage);
