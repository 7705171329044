import 'whatwg-fetch';
import './stubConsoleIfUnavailable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  Router,
  Route,
  browserHistory,
  IndexRoute,
  Redirect,
} from 'react-router';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';

import {
  resetResponse,
  loadUsername,
  setSelectedEvent,
} from '^/actions/actions';
import { clearItem } from '^/actions/items';
import { TEMPLATE_DOCUMENT_VERSION } from '^/consts/collectionKeys';
import {
  getAndCheckUserInfo,
  checkUserInfoAndLogin,
} from '^/actions/actionSequences';
import AdminPage from '^/components/admin/AdminPage';
import AdminHome from '^/components/admin/AdminHome';
import ActivitiesAdmin from '^/components/admin/activities/ActivitiesAdmin';
import ManageActivity from '^/components/admin/activities/ManageActivity';
import QMRReportsAdmin from '^/components/admin/qmr-reports/QMRReportsAdmin';
import ManageQMRReport from '^/components/admin/qmr-reports/ManageQMRReport';
import QPSPageAdmin from '^/components/admin/qps-page/QPSPageAdmin';
import ManageQPSFile from '^/components/admin/qps-page/ManageQPSFile';
import UserAccountsAdmin from '^/components/admin/users/end-users/UserAccountsAdmin';
import UserAccountEditAdmin from '^/components/admin/users/end-users/UserAccountEditAdmin';
import AdminUserAccountsAdmin from '^/components/admin/users/admin-users/AdminUserAccountsAdmin';
import AdminUserAccountEditAdmin from '^/components/admin/users/admin-users/AdminUserAccountEditAdmin';
import PracticesAdmin from '^/components/admin/practices/PracticesAdmin';
import HealthcheckAdmin from '^/components/admin/healthcheck/HealthcheckAdmin';
import EditHealthcheckQuestion from '^/components/admin/healthcheck/HealthcheckQuestionAdmin';
import AuditsAdmin from '^/components/admin/audits/AuditsAdmin';
import EditAuditAdmin from '^/components/admin/audits/EditAuditAdmin';
import EditAuditQuestionAdmin from '^/components/admin/audits/EditAuditQuestionAdmin';
import ManagePractice from '^/components/admin/practices/ManagePractice';
import EditPracticeGroup from '^/components/admin/practices/EditPracticeGroup';
import ManagePracticeSubGroup from '^/components/admin/practices/ManagePracticeSubGroup';
import PracticeGroupsAdmin from '^/components/admin/practices/PracticeGroupsAdmin';
import ExportAdmin from '^/components/admin/export/ExportAdmin';
import NewsAdmin from '^/components/admin/news/NewsAdmin';
import EditNews from '^/components/admin/news/EditNews';
import ResponsibilitiesAdmin from '^/components/admin/responsibilities/ResponsibilitiesAdmin';
import EditResponsibilities from '^/components/admin/responsibilities/EditResponsibilities';
import EditReminders from '^/components/admin/reminders/EditReminders';
import RemindersAdmin from '^/components/admin/reminders/RemindersAdmin';
import EditTemplateDocument from '^/components/admin/template-documents/EditTemplateDocument';
import ManageTemplateDocument from '^/components/admin/template-documents/ManageTemplateDocument';
import TemplateDocumentsAdmin from '^/components/admin/template-documents/TemplateDocumentsAdmin';
import EditTemplateDocCategory from '^/components/admin/template-documents/EditTemplateDocumentCategory';
import TemplateDocumentsDraftsAdmin from '^/components/admin/template-documents/TemplateDocumentsDraftsAdmin';
import EditTemplateDocumentDraft from '^/components/admin/template-documents/EditTemplateDocumentDraft';
import TemplateDocumentCategoriesAdmin from '^/components/admin/template-documents/TemplateDocumentCategoriesAdmin';
import EditTemplateDocFolder from '^/components/admin/template-documents/EditTemplateDocumentFolder';
import TemplateDocumentFoldersAdmin from '^/components/admin/template-documents/TemplateDocumentFoldersAdmin';
import AlertsAdmin from '^/components/admin/alerts/AlertsAdmin';
import EditComplianceSystems from '^/components/admin/compliance-systems/EditComplianceSystems';
import ComplianceSystemsAdmin from '^/components/admin/compliance-systems/ComplianceSystemsAdmin';
import DiscountCodesAdmin from '^/components/admin/discount-codes/DiscountCodesAdmin';
import CalendarPreviewsAdmin from '^/components/admin/calendar-previews/CalendarPreviewsAdmin';
import ReportsAdmin from '^/components/admin/reports/ReportsAdmin';
import HelpAdmin from '^/components/admin/help/HelpAdmin';
import EditHelp from '^/components/admin/help/EditHelp';
import TermsAdmin from '^/components/admin/terms/TermsAdmin';
import EditTerms from '^/components/admin/terms/EditTerms';
import CreateTerms from '^/components/admin/terms/CreateTerms';
import CreateTemplateDocument from '^/components/admin/template-documents/CreateTemplateDocument';

import Page from '^/components/app/Page';
import Dashboard from '^/components/app/dashboard/Dashboard';
import TemplatesDocumentsPage from '^/components/app/template-documents/TemplatesDocumentsPage';
import TemplateDocumentPage from '^/components/app/template-documents/TemplateDocumentPage';
import TemplateDocumentVersionPage from '^/components/app/template-documents/TemplateDocumentVersionPage';
import TemplateDocumentUpdatePage from '^/components/app/template-documents/compare/TemplateDocumentUpdatePage';
import ReportsPage from '^/components/app/reports/ReportsPage';
import TasksPage from '^/components/app/tasks/TasksPage';
import NewsStoriesPage from '^/components/app/news/NewsStoriesPage';
import Styleguide from '^/components/styleguide/Styleguide';
import RedeemInvite from '^/components/app/invites/RedeemInvite';
import InvalidInvite from '^/components/app/invites/InvalidInvite';
import AddPracticePaymentPage from '^/components/app/practices/AddPracticePaymentPage';
import PracticePage from '^/components/app/practices/PracticePage';
import PracticeDetails from '^/components/app/practices/PracticeDetails';
import PracticeTeamPage from '^/components/app/practices/PracticeTeamPage';
import CycleResponsibilitiesPage from '^/components/app/practices/CycleResponsibilitiesPage';
import ChangePassword from '^/components/app/users/ChangePassword';
import LockedOutDemo from '^/components/app/users/LockedOutDemo';
import InvalidEmailVerification from '^/components/app/users/InvalidEmailVerification';
import RegistrationPage from '^/components/app/users/login/RegistrationPage';
import ResetPassword from '^/components/app/users/ResetPassword';

import CalendarPage from '^/components/app/calendar/CalendarPage';
import CalendarGridView from '^/components/app/calendar/CalendarGridView';
import CalendarListView from '^/components/app/calendar/CalendarListView';
import ActivityPage from '^/components/app/activities/ActivityPage';
import UserProfilePage from '^/components/app/users/profile/UserProfilePage';
import UserProfileEditPage from '^/components/app/users/profile/UserProfileEditPage';
import MyPractices from '^/components/app/users/profile/my-practices/MyPractices';
import GroupDetails from '^/components/app/groups/group-details/GroupDetails';
import GroupPracticeListPage from '^/components/app/groups/group-details/practice-list/PracticeListPage';
import AccountSetUpPage from '^/components/app/account-set-up/AccountSetUpPage';
import PersonalDetailsSetUpPage from '^/components/app/account-set-up/PersonalDetailsSetUpPage';
import PracticeDetailsSetupPage from '^/components/app/account-set-up/PracticeDetailsSetupPage';
import TeamMembersSetupPage from '^/components/app/account-set-up/TeamMembersSetupPage';
import CycleResponsibilitiesSetupPage from '^/components/app/account-set-up/CycleResponsibilitiesSetupPage';
import GroupTasksSetupPage from '^/components/app/account-set-up/GroupTasksSetupPage';
import SuggestedTasksSetupPage from '^/components/app/account-set-up/SuggestedTasksSetupPage';
import ComplianceReviewsSetupPage from '^/components/app/account-set-up/ComplianceReviewsSetupPage';
import SecurityAuditsSetupPage from '^/components/app/account-set-up/SecurityAuditsSetupPage';
import RiskAssessmentsSetupPage from '^/components/app/account-set-up/RiskAssessmentsSetupPage';
import MeetingDaysSetupPage from '^/components/app/account-set-up/MeetingDaysSetupPage';
import SetupCompletePage from '^/components/app/account-set-up/SetupCompletePage';
import ActivityRelatedTemplateEditPage from '^/components/app/activities/documents/ActivityRelatedTemplateEditPage';
import MyAccountPage from '^/components/app/users/profile/MyAccountPage';
import CycleSetupPage from '^/components/app/cycle-setup/CycleSetupPage';
import CycleSetupComplianceDetailsPage from '^/components/app/cycle-setup/CycleSetupComplianceDetailsPage';
import CycleSetupOptionalAuditsPage from '^/components/app/cycle-setup/CycleSetupOptionalAuditsPage';
import CycleSetupReviewGroupTasksPage from '^/components/app/cycle-setup/CycleSetupReviewGroupTasksPage';
import CycleSetupReviewRiskAssessmentsPage from '^/components/app/cycle-setup/CycleSetupReviewRiskAssessmentsPage';
import CycleSetupReviewMeetingDaysPage from '^/components/app/cycle-setup/CycleSetupReviewMeetingDaysPage';
import CycleSetupReviewTasksPage from '^/components/app/cycle-setup/CycleSetupReviewTasksPage';
import CycleSetupAdditionalComplianceReviewsPage from '^/components/app/cycle-setup/CycleSetupAdditionalComplianceReviewsPage';
import QPSPage from '^/components/app/qps/QPSPage';
import App from '^/containers/App';
import Help from '^/components/app/help/Help';
import CookiePolicy from '^/components/app/policies/CookiePolicy';
import PrivacyPolicy from '^/components/app/policies/PrivacyPolicy';
import InsightsPage from '^/components/app/groups/insights/InsightsPage';
import ActiveUsersPage from '^/components/app/groups/insights/active-user-count/ActiveUsersPage';
import ActivitiesPage from '^/components/app/groups/insights/activities/ActivitiesPage';
import ComplianceActivitiesPage from '^/components/app/groups/insights/compliance-activities/ComplianceActivitiesPage';
import GroupDocumentsPage from '^/components/app/groups/insights/group-documents/GroupDocumentsPage';
import GroupTasksPage from '^/components/app/groups/group-tasks/GroupTasksPage';
import GroupConfigPage from '^/components/app/groups/group-config/GroupConfigPage';
import PracticeInteraction from '^/components/app/groups/reports/PracticeInteraction';
import ComplianceReviewsGroupConfigPage from '^/components/app/groups/group-config/ComplianceReviewsGroupConfigPage';
import AuditsGroupConfigPage from '^/components/app/groups/group-config/AuditsGroupConfigPage';
import AssessmentsGroupConfigPage from '^/components/app/groups/group-config/AssessmentsGroupConfigPage';
import PracticeMeetingsGroupConfigPage from '^/components/app/groups/group-config/PracticeMeetingsGroupConfigPage';
import GroupPage from '^/components/app/groups/group-details/GroupPage';
import GroupsDocumentsPage from '^/components/app/groups/group-documents/GroupsDocumentsPage';
import GroupTemplateDocumentPage from '^/components/app/groups/group-documents/GroupTemplateDocumentPage';
import GroupNewsStoriesPage from './components/app/news/GroupNewsStoriesPage';
import GroupReportsPage from '^/components/app/groups/reports/GroupReportsPage';
import EventRecordsPage from '^/components/app/groups/reports/EventRecordsPage';
import HealthcheckInfo from './components/app/healthcheck/info';
import HealthcheckAuditIntro1 from './components/app/healthcheck/audit-intro-1';
import HealthcheckAuditResults from './components/app/healthcheck/audit-results';
import HealthcheckActionsIntro1 from './components/app/healthcheck/actions-intro-1';
import HealthcheckAudit from './components/app/healthcheck/audit';
import HealthcheckActions from './components/app/healthcheck/actions';
import HealthcheckReview from './components/app/healthcheck/review';
import PracticeHealthcheckList from './components/app/healthcheck/practice-healthcheck-list';
import DocumentReadDetailPage from '^/components/app/template-documents/DocumentReadDetailPage';
import RecordsChecklistPage from '^/components/app/digital-tools/records/RecordsChecklistPage';
import RecordsListPage from '^/components/app/digital-tools/records/digital-records/RecordsListPage';
import DigitalToolsPage from './components/app/digital-tools/DigitalToolsPage';
import RecordsDetailPage from '^/components/app/digital-tools/records/digital-records/RecordsDetailPage';
import ChecklistRecordGroupsDetail from '^/components/app/digital-tools/records/checklist/ChecklistRecordGroupsDetail';
import CreateChecklistRecordGroup from '^/components/app/digital-tools/records/checklist/CreateChecklistRecordGroup';
import LoginFailed from '^/components/app/users/login/LoginFailed';
import UserSuspended from '^/components/app/users/login/UserSuspended';

import store from '^/store';
import UsernameStore from '^/UsernameStore';
import { isLoggedIn } from '^/utils';

import 'moment/locale/en-gb';
import AuditAuditIntro1 from './components/app/digital-tools/audit/audit-intro-1';
import AuditAuditResults from './components/app/digital-tools/audit/audit-results';
import AuditAudit from './components/app/digital-tools/audit/audit';
import AuditActionsIntro1 from './components/app/digital-tools/audit/actions-intro-1';
import AuditActions from './components/app/digital-tools/audit/actions';
import AuditReview from './components/app/digital-tools/audit/review';
import AuditReviewQAndA from './components/app/digital-tools/audit/review/q-and-a';
import AuditListPage from './components/app/digital-tools/audit/list-page';

import * as Sentry from '@sentry/browser';

moment.locale('en-gb');
BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

export function setupSentry(dsn) {
  Sentry.init({
    dsn,
  });
}

function loadUserInfo() {
  if (isLoggedIn(store.getState())) {
    store.dispatch(getAndCheckUserInfo());
  }
}

function trySkipLogin() {
  if (isLoggedIn(store.getState())) {
    store.dispatch(checkUserInfoAndLogin());
  }
}

function tryLoadUsername() {
  const username = UsernameStore.get();
  if (username) {
    store.dispatch(loadUsername(username));
  }
}

function enterLogin() {
  trySkipLogin();
  tryLoadUsername();
}

function resetResponseFor(key) {
  store.dispatch(resetResponse(key));
}

function resetCreateAndUpdateItemResponses() {
  resetResponseFor('createItem');
  resetResponseFor('updateItem');
}

function resetItem(key) {
  store.dispatch(clearItem(key));
}

function clearSelectedEvent() {
  store.dispatch(setSelectedEvent(null));
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={browserHistory}>
      <Route path="/" name="App" component={App} onEnter={loadUserInfo}>
        <Redirect from="login" to="/" />
        <Route
          path="register"
          component={RegistrationPage}
          onEnter={enterLogin}
        />
        <Route path="resetPassword/:uuid/:token" component={ResetPassword} />
        <Route path="invite/:uuid/redeem/" component={RedeemInvite} />
        <Route
          name="Registered"
          staticName="Registered"
          path="users/register/:uuid"
          component={InvalidEmailVerification}
        />
        <Route path="styleguide" component={Styleguide} />
        <Route path="cookie-policy" component={CookiePolicy} />
        <Route path="login-failed" component={LoginFailed} />
        <Route path="invalid-invite" component={InvalidInvite} />
        <Route path="user-suspended" component={UserSuspended} />
        <Route path="privacy-policy" component={PrivacyPolicy} />
        <Route path="expired" component={LockedOutDemo} />
        <Route path="admin" name="Home" component={AdminPage}>
          <IndexRoute component={AdminHome} />
          <Route name="Exports" path="export">
            <IndexRoute component={ExportAdmin} />
          </Route>
          <Route name="Reports" path="reports">
            <IndexRoute component={ReportsAdmin} />
          </Route>
          <Route path="users" name="Users">
            <IndexRoute component={UserAccountsAdmin} />
            <Route
              name="Manage"
              staticName="Manage"
              path=":uuid"
              component={UserAccountEditAdmin}
              onEnter={() => resetResponseFor('updateItem')}
            />
          </Route>
          <Route path="admin-users" name="Admin Users">
            <IndexRoute component={AdminUserAccountsAdmin} />
            <Route
              name="Manage"
              staticName="Manage"
              path=":uuid"
              component={AdminUserAccountEditAdmin}
              onEnter={() => resetResponseFor('updateItem')}
            />
          </Route>
          <Route name="Activities" path="activities">
            <IndexRoute
              component={ActivitiesAdmin}
              onEnter={() => resetResponseFor('createItem')}
            />
            <Route
              name="Manage"
              staticName="Manage"
              path=":uuid"
              component={ManageActivity}
              onEnter={() => resetResponseFor('updateItem')}
            />
          </Route>
          <Route name="Health Check" path="healthcheck">
            <IndexRoute component={HealthcheckAdmin} />
            <Route
              name="Edit"
              staticName="Edit"
              path="question/:uuid"
              component={EditHealthcheckQuestion}
              onEnter={() => resetResponseFor('updateItem')}
            />
          </Route>
          <Route name="Audits" path="audits">
            <IndexRoute component={AuditsAdmin} />
            <Route
              name="Edit"
              staticName="Edit"
              path=":uuid"
              component={EditAuditAdmin}
              onEnter={() => resetResponseFor('updateItem')}
            />
            <Route
              name="Question Edit"
              staticName="Edit"
              path="question/:uuid"
              component={EditAuditQuestionAdmin}
              onEnter={() => resetResponseFor('updateItem')}
            />
          </Route>
          <Route name="QMR Reports" path="qmr-reports">
            <IndexRoute
              component={QMRReportsAdmin}
              onEnter={() => resetResponseFor('createItem')}
            />
            <Route
              name="Manage"
              staticName="Manage"
              path=":uuid"
              component={ManageQMRReport}
              onEnter={() => resetResponseFor('updateItem')}
            />
          </Route>
          <Route name="QPS Page" path="qps-page">
            <IndexRoute
              component={QPSPageAdmin}
              onEnter={() => resetResponseFor('createItem')}
            />
            <Route
              name="Manage"
              staticName="Manage"
              path=":uuid"
              component={ManageQPSFile}
              onEnter={() => resetResponseFor('updateItem')}
            />
          </Route>
          <Route name="Practices" path="practices">
            <IndexRoute component={PracticesAdmin} />
            <Route path="groups" name="Practice Groups">
              <IndexRoute
                component={PracticeGroupsAdmin}
                onEnter={() => resetResponseFor('createItem')}
              />
              <Route
                name="Edit"
                staticName="Edit"
                path=":uuid"
                component={EditPracticeGroup}
                onEnter={() => resetResponseFor('updateItem')}
              />
              <Route path="subgroups" name="Practice SubGroups">
                <Route
                  name="Manage"
                  staticName="Manage"
                  path=":uuid"
                  component={ManagePracticeSubGroup}
                  onEnter={() => resetResponseFor('updateItem')}
                />
              </Route>
            </Route>
            <Route
              name="Manage"
              staticName="Manage"
              path=":uuid"
              component={ManagePractice}
            />
          </Route>
          <Route name="News" path="news">
            <IndexRoute
              component={NewsAdmin}
              onEnter={() => resetResponseFor('createItem')}
            />
            <Route
              name="Edit"
              staticName="Edit"
              path=":uuid"
              component={EditNews}
              onEnter={() => resetResponseFor('updateItem')}
            />
          </Route>
          <Route name="Responsibilities" path="responsibilities">
            <IndexRoute
              component={ResponsibilitiesAdmin}
              onEnter={() => resetResponseFor('createItem')}
            />
            <Route
              name="Edit"
              staticName="Edit"
              path=":uuid"
              component={EditResponsibilities}
              onEnter={() => resetResponseFor('updateItem')}
            />
          </Route>
          <Route
            name="Alerts"
            path="alerts"
            component={AlertsAdmin}
            onEnter={() => resetResponseFor('createItem')}
          />
          <Route name="Compliance Systems" path="compliance-systems">
            <IndexRoute
              component={ComplianceSystemsAdmin}
              onEnter={() => resetResponseFor('createItem')}
            />
            <Route
              name="Edit"
              staticName="Edit"
              path=":uuid"
              component={EditComplianceSystems}
              onEnter={() => resetResponseFor('updateItem')}
            />
          </Route>
          <Route name="Help" path="help">
            <IndexRoute
              component={HelpAdmin}
              onEnter={() => resetResponseFor('createItem')}
            />
            <Route
              name="Edit"
              staticName="Edit"
              path=":uuid"
              component={EditHelp}
              onEnter={() => resetResponseFor('updateItem')}
            />
          </Route>
          <Route name="Terms" path="terms">
            <IndexRoute
              component={TermsAdmin}
              onEnter={() => resetResponseFor('createItem')}
            />
            <Route
              name="Create"
              staticName="Create"
              path="create"
              component={CreateTerms}
              onEnter={() => resetResponseFor('updateItem')}
            />
            <Route
              name="Edit"
              staticName="Edit"
              path=":uuid"
              component={EditTerms}
              onEnter={() => resetResponseFor('updateItem')}
            />
          </Route>
          <Route name="Documents" path="template-documents">
            <IndexRoute
              component={TemplateDocumentsAdmin}
              onEnter={() => resetResponseFor('createItem')}
            />
            <Route path="categories" name="Categories">
              <IndexRoute
                component={TemplateDocumentCategoriesAdmin}
                onEnter={() => resetResponseFor('createItem')}
              />
              <Route
                name="Edit"
                staticName="Edit"
                path=":uuid"
                component={EditTemplateDocCategory}
                onEnter={() => resetResponseFor('updateItem')}
                onLeave={() => resetItem(TEMPLATE_DOCUMENT_VERSION)}
              />
            </Route>
            <Route path="folders" name="Folders">
              <IndexRoute
                component={TemplateDocumentFoldersAdmin}
                onEnter={() => resetResponseFor('createItem')}
              />
              <Route
                name="Edit"
                staticName="Edit"
                path=":uuid"
                component={EditTemplateDocFolder}
                onEnter={() => resetResponseFor('updateItem')}
              />
            </Route>
            <Route
              path="create"
              name="Create"
              staticName="Create"
              onEnter={() => resetResponseFor('createItem')}
              component={CreateTemplateDocument}
              onLeave={() => resetItem(TEMPLATE_DOCUMENT_VERSION)}
            />
            <Route path="drafts" name="Drafts">
              <IndexRoute component={TemplateDocumentsDraftsAdmin} />
              <Route
                name="Edit"
                staticName="Edit"
                path=":uuid/edit"
                component={EditTemplateDocumentDraft}
                onEnter={() => resetResponseFor('createItem')}
                onLeave={() => resetResponseFor('updateItem')}
              />
            </Route>
            <Route
              path=":uuid"
              name="Manage"
              staticName="Manage"
              onEnter={() => resetResponseFor('updateItem')}
              onLeave={() => resetItem(TEMPLATE_DOCUMENT_VERSION)}
            >
              <IndexRoute component={ManageTemplateDocument} />
              <Route
                name="Edit"
                path="edit"
                component={EditTemplateDocument}
                onEnter={() =>
                  ['updateItem', 'autosaveWorkingVersion'].forEach(
                    resetResponseFor
                  )
                }
                onLeave={() => resetItem(TEMPLATE_DOCUMENT_VERSION)}
              />
            </Route>
          </Route>
          <Route name="Reminders" path="reminders">
            <IndexRoute
              component={RemindersAdmin}
              onEnter={() => resetResponseFor('createItem')}
            />
            <Route
              name="Edit"
              staticName="Edit"
              path=":uuid"
              component={EditReminders}
              onEnter={() => resetResponseFor('updateItem')}
            />
          </Route>
          <Route
            name="Discount Codes"
            path="discount-codes"
            component={DiscountCodesAdmin}
            onEnter={() => resetResponseFor('createItem')}
          />
          <Route
            name="Calendar Previews"
            path="calendar-previews"
            component={CalendarPreviewsAdmin}
            onEnter={() => resetResponseFor('createItem')}
          />
        </Route>

        <Route path="page" component={Page}>
          <Route path="dashboard" component={Dashboard} />
          <Route path="help" component={Help} />
          <Route path="tasks/">
            <IndexRoute component={TasksPage} />
            <Route path="manage/">
              <IndexRoute component={TasksPage} />
              <Route
                path=":uuid/"
                component={TasksPage}
                onEnter={() => resetResponseFor('deleteItem')}
              />
            </Route>
            <Route path=":uuid/" component={TasksPage} />
          </Route>
          <Route path="documents">
            <IndexRoute component={TemplatesDocumentsPage} />
            <Route path="group/">
              <IndexRoute component={TemplatesDocumentsPage} />
              <Route path=":uuid/" component={TemplateDocumentPage} />
              <Route path=":uuid/copies/" component={TemplateDocumentPage} />
            </Route>
            <Route path="uploaded/" component={TemplatesDocumentsPage} />
            <Route path="document-read-tracker/">
              <IndexRoute component={TemplatesDocumentsPage} />
              <Route path=":uuid/" component={DocumentReadDetailPage} />
            </Route>
            <Route
              path="version/:uuid/"
              component={TemplateDocumentVersionPage}
              onLeave={() => resetItem(TEMPLATE_DOCUMENT_VERSION)}
            />
            <Route path=":uuid/" component={TemplateDocumentPage} />
            <Route path=":uuid/copies/" component={TemplateDocumentPage} />
            <Route
              path=":uuid/update/"
              component={TemplateDocumentUpdatePage}
              onLeave={() => resetItem(TEMPLATE_DOCUMENT_VERSION)}
            />
            <Route
              path=":uuid/update/:activity_id/"
              component={TemplateDocumentUpdatePage}
              onLeave={() => resetItem(TEMPLATE_DOCUMENT_VERSION)}
            />
          </Route>
          <Route path="reports" component={ReportsPage} />
          <Route
            path="practices/add/"
            component={AddPracticePaymentPage}
            onEnter={() => resetResponseFor('createItem')}
          />
          <Route path="practices/:uuid/" component={PracticePage}>
            <IndexRoute component={PracticeDetails} />
            <Route
              path="team"
              component={PracticeTeamPage}
              onEnter={() => resetResponseFor('updateItem')}
            />
            <Route
              path="responsibilities"
              component={CycleResponsibilitiesPage}
            />
            <Route
              path="compliance_healthcheck"
              component={PracticeHealthcheckList}
            />
          </Route>
          <Route path="news/" component={NewsStoriesPage} />
          <Route path="news/:uuid/" component={NewsStoriesPage} />
          {/* HERE */}
          <Route
            path="calendar"
            component={CalendarPage}
            onEnter={clearSelectedEvent}
          >
            <IndexRoute component={CalendarGridView} />
            <Route path="grid-view" component={CalendarGridView} />
            <Route path="grid-view/:month/" component={CalendarGridView} />
            <Route path="list-view" component={CalendarListView} />
            <Route path="list-view/:/month" component={CalendarListView} />
          </Route>
          <Route path="qps-scheme" component={QPSPage} />
          <Route path="activities/" component={ActivityPage} />
          <Route path="activities">
            <Route path=":uuid/" component={ActivityPage} />
            <Route
              path=":uuid/related-templates/:related_template_id/"
              component={ActivityRelatedTemplateEditPage}
              onLeave={() => resetItem(TEMPLATE_DOCUMENT_VERSION)}
            />
          </Route>
          <Route
            path="profile"
            component={MyAccountPage}
            onLeave={resetResponseFor.bind(null, 'updateItem')}
            myProfile
          >
            <IndexRoute component={UserProfilePage} />
            <Route path="my-practices" component={MyPractices} />
            <Route
              path="edit"
              component={UserProfileEditPage}
              onEnter={resetResponseFor.bind(null, 'updateItem')}
              myProfile
            />
            <Route
              path="change-password"
              component={ChangePassword}
              onEnter={resetResponseFor.bind(null, 'changePassword')}
            />
          </Route>

          <Route path="set-up" component={AccountSetUpPage}>
            <Route
              path="personal-details/"
              component={PersonalDetailsSetUpPage}
            />
            <Route
              path="practice-details/"
              component={PracticeDetailsSetupPage}
            />
            <Route path="team-members/" component={TeamMembersSetupPage} />
            <Route
              path="cycle-responsibilities/"
              component={CycleResponsibilitiesSetupPage}
            />
            <Route path="suggested-task/" component={SuggestedTasksSetupPage} />
            <Route path="group-tasks/" component={GroupTasksSetupPage} />
            <Route
              path="compliance-reviews/"
              component={ComplianceReviewsSetupPage}
            />
            <Route path="audits/" component={SecurityAuditsSetupPage} />
            <Route
              path="risk-assessments/"
              component={RiskAssessmentsSetupPage}
            />
            <Route path="meeting-days/" component={MeetingDaysSetupPage} />
          </Route>

          <Route path="setup-cycle" component={CycleSetupPage}>
            <Route
              path=":uuid/practice-details/"
              component={CycleSetupComplianceDetailsPage}
            />
            <Route
              path=":uuid/optional-audits/"
              component={CycleSetupOptionalAuditsPage}
            />
            <Route
              path=":uuid/risk-assessments/"
              component={CycleSetupReviewRiskAssessmentsPage}
            />
            <Route
              path=":uuid/meeting-days/"
              component={CycleSetupReviewMeetingDaysPage}
            />
            <Route
              path=":uuid/review-tasks/"
              component={CycleSetupReviewTasksPage}
            />
            <Route
              path=":uuid/review-group-tasks/"
              component={CycleSetupReviewGroupTasksPage}
            />
            <Route
              path=":uuid/compliance-reviews/"
              component={CycleSetupAdditionalComplianceReviewsPage}
            />
          </Route>

          <Route path="setup-complete/:uuid/" component={SetupCompletePage} />

          <Redirect from="groups/" to="groups/insights/" />
          <Route path="groups/">
            <Route path="insights/">
              <IndexRoute component={InsightsPage} />
              <Route path="activities/" component={ActivitiesPage} />
              <Route path="active-users/" component={ActiveUsersPage} />
              <Route path="group-documents/" component={GroupDocumentsPage} />
              <Route
                path="compliance-activities/"
                component={ComplianceActivitiesPage}
              />
            </Route>
            <Route path="group-details/" component={GroupPage}>
              <IndexRoute component={GroupDetails} />
              <Route path="practice-list/" component={GroupPracticeListPage} />
            </Route>
            <Route path="group-tasks/">
              <IndexRoute component={GroupTasksPage} />
            </Route>
            <Route path="group-config/" component={GroupConfigPage}>
              <IndexRoute component={ComplianceReviewsGroupConfigPage} />
              <Route
                path="compliance-reviews/"
                component={ComplianceReviewsGroupConfigPage}
              />
              <Route path="audits/" component={AuditsGroupConfigPage} />
              <Route
                path="assessments/"
                component={AssessmentsGroupConfigPage}
              />
              <Route
                path="practice-meetings/"
                component={PracticeMeetingsGroupConfigPage}
              />
            </Route>
            <Route path="news/" component={GroupNewsStoriesPage} />
            <Route path="news/:uuid/" component={GroupNewsStoriesPage} />
            <Route path="group-documents/">
              <IndexRoute component={GroupsDocumentsPage} />
              <Route path="templates/">
                <IndexRoute
                  component={GroupTemplateDocumentPage}
                  onEnter={resetCreateAndUpdateItemResponses}
                />
                <Route
                  path=":uuid/"
                  component={GroupTemplateDocumentPage}
                  onEnter={resetCreateAndUpdateItemResponses}
                />
              </Route>
              <Route path=":uuid/" component={TemplateDocumentPage} />
              <Route path=":uuid/copies/" component={TemplateDocumentPage} />
              <Route
                path=":uuid/update/"
                component={TemplateDocumentUpdatePage}
                onLeave={() => resetItem(TEMPLATE_DOCUMENT_VERSION)}
              />
              <Route
                path="version/:uuid/"
                component={TemplateDocumentVersionPage}
                onLeave={() => resetItem(TEMPLATE_DOCUMENT_VERSION)}
              />
            </Route>
            <Route path="reports/">
              <IndexRoute component={GroupReportsPage} />
              <Route path="event-records/" component={EventRecordsPage} />
            </Route>
            <Route path="interaction/" component={PracticeInteraction} />
          </Route>
          <Route path="healthcheck">
            <Route path=":healthcheckId">
              <Route path="info" component={HealthcheckInfo} />
              <Route path="audit-intro-1" component={HealthcheckAuditIntro1} />
              <Route path="audit-results" component={HealthcheckAuditResults} />
              <Route
                path="actions-intro-1"
                component={HealthcheckActionsIntro1}
              />
              <Route
                path="questions/:categoryId"
                component={HealthcheckAudit}
              />
              <Route
                path="actions/(:categoryId)"
                component={HealthcheckActions}
              />
              <Route path="review" component={HealthcheckReview} />
            </Route>
          </Route>
          <Route path="tools" name="Digital Tools" component={DigitalToolsPage}>
            <Route path="records" component={RecordsChecklistPage}>
              <Route
                path="checklist/:recordTypeUrl/add-new/"
                component={CreateChecklistRecordGroup}
              />
              <Route
                path="checklist/:recordGroupId/:date"
                component={ChecklistRecordGroupsDetail}
              />
              <Route
                path="checklist/:recordGroupId"
                component={ChecklistRecordGroupsDetail}
              />
              <Route path=":recordTypeUrl" component={RecordsListPage} />
              <Route
                path=":recordTypeUrl/create"
                component={RecordsDetailPage}
              />
              <Route
                path=":recordTypeUrl/:uuid"
                component={RecordsDetailPage}
              />
            </Route>
            <Route path="audit">
              <IndexRoute component={AuditListPage} />
              <Route path=":auditId">
                <Route path="audit-intro-1" component={AuditAuditIntro1} />
                <Route path="audit-results" component={AuditAuditResults} />
                <Route path="questions/:categoryId" component={AuditAudit} />
                <Route path="actions-intro-1" component={AuditActionsIntro1} />
                <Route
                  path="actions/(:categoryId)(/:correct)"
                  component={AuditActions}
                />
                <Route path="review" component={AuditReview} />
                <Route path="review-q-and-a" component={AuditReviewQAndA} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Router>
  </Provider>,
  document.getElementById('app')
);

window.setupSentry = setupSentry;
