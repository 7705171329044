import React from 'react';
import { connect } from 'react-redux';

import PureComponent from '^/components/common/PureComponent';
import CreateChecklistRecordGroupForm from '^/components/app/digital-tools/records/checklist/forms/CreateChecklistRecordGroupForm';
import Loading from '^/components/app/content/Loading';

import { addNewChecklistAndCloseModal } from '^/actions/actionSequences';
import { CHECKLIST_RECORD_GROUP } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';

export class CreateChecklistRecordGroupModal extends PureComponent {
  constructor(props) {
    super(props);
    this.createChecklistRecordGroup = this.createChecklistRecordGroup.bind(
      this
    );
  }

  createChecklistRecordGroup(data) {
    const {
      checklistType,
      customChecklistFields,
      recordGroupMetadataFieldKeys,
      recordGroupMetadataFormState,
    } = this.props;
    const recordGroupMetadataFormValues = [];
    if (recordGroupMetadataFormState !== undefined) {
      for (const [key, value] of Object.entries(recordGroupMetadataFormState)) {
        if (recordGroupMetadataFieldKeys.indexOf(key) !== -1) {
          recordGroupMetadataFormValues.push({ key: key, value: value.value });
        }
      }
    }

    const submitData = {
      name: data.name,
      record_type: checklistType,
      custom_fields: customChecklistFields,
      meta_field_values: recordGroupMetadataFormValues,
    };

    this.props.addNewChecklistAndCloseModal(
      CHECKLIST_RECORD_GROUP,
      submitData,
      checklistType
    );
  }

  render() {
    if (this.props.isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <p>Give your checklist a name</p>
        <CreateChecklistRecordGroupForm
          onSubmit={this.createChecklistRecordGroup}
          metadataFields={this.props.metadataFields}
        />
      </div>
    );
  }
}

export function mapStateToProps(state) {
  const createChecklistRecordGroupResponse = state.responses.getIn([
    'createItem',
    CHECKLIST_RECORD_GROUP,
  ]);
  return {
    isLoading: isPending(createChecklistRecordGroupResponse),
    customChecklistFields: state.customChecklistFields,
    recordGroupMetadataFormState:
      state.form.CREATE_CHECKLIST_RECORD_GROUP_METADATA_FORM,
  };
}

export default connect(mapStateToProps, { addNewChecklistAndCloseModal })(
  CreateChecklistRecordGroupModal
);
