import React from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import classNames from 'classnames';
import PureComponent from '^/components/common/PureComponent';
import { DOCUMENT_VERSIONS_TO_READ } from '^/consts/collectionKeys';
import TableList from '^/components/app/tables/TableList';
import { documentVersionToReadSendReminder } from '^/actions/actions';
import { isDocumentReadByAllMembers, ARCHIVED } from '^/utils';
import { openCreateBulkDocReadModal } from '^/actions/modals';

export const PAGE_SIZE = 20;

export class DocumentReadTracker extends PureComponent {
  isDisabled(documentReadRequest) {
    return (
      documentReadRequest.get('status') === ARCHIVED ||
      isDocumentReadByAllMembers(documentReadRequest)
    );
  }

  getReadStatusStyle(documentReadRequest) {
    return classNames(
      'doc-read-status',
      isDocumentReadByAllMembers(documentReadRequest)
        ? 'complete'
        : 'incomplete'
    );
  }

  render() {
    const { practiceId } = this.props;
    const fields = fromJS([
      {
        name: ['document_name'],
        display_name: 'Document Name',
        type: 'string',
        sortable: true,
      },
      {
        name: ['document_code'],
        display_name: 'Document Code',
        type: 'string',
        sortable: true,
      },
      {
        name: ['read_status'],
        display_name: 'Read Status',
        type: 'string',
        sortByName: 'outstanding_read',
        sortable: true,
        styleHandler: this.getReadStatusStyle,
      },
      {
        name: ['status'],
        display_name: 'Status',
        type: 'string',
        sortable: true,
      },
      {
        display_name: 'Send Reminder',
        type: 'live_button',
        liveButton: {
          text: 'Send',
          successText: 'Sent!',
          keyedResponse: this.props.sendReminderResponse,
          disabled: this.isDisabled,
        },
        handler: this.props.sendReminder,
      },
      {
        display_name: 'Action',
        type: 'detail',
        link_text: 'View',
        route: '/page/documents/document-read-tracker/${id}/',
      },
    ]);

    return (
      <div className="wrapper mb-2">
        <div className="pull-right">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => this.props.openCreateBulkDocReadModal(practiceId)}
          >
            Send Multiple Documents
          </button>
        </div>
        <TableList
          model={DOCUMENT_VERSIONS_TO_READ}
          fields={fields}
          title={'Document Read Tracker'}
          pageSize={PAGE_SIZE}
          noSearch
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sendReminderResponse: state.responses.get(
      'documentVersionToReadSendReminderResponse'
    ),
    practiceId: state.currentPractice && state.currentPractice.get('id'),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    openCreateBulkDocReadModal: practiceId =>
      dispatch(openCreateBulkDocReadModal(practiceId)),
    sendReminder: documentVersionToRead =>
      dispatch(
        documentVersionToReadSendReminder(documentVersionToRead.get('id'))
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentReadTracker);
