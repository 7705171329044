import { fromJS } from 'immutable';
import { SectorTypes } from '^/consts/sector';

export function filterHideRulesBySector(fieldConfig) {
  const sector = SectorTypes.DENTAL;
  
  return fieldConfig.updateIn(
   ['attribute', 'choices'],
   choices => choices.filter(choice => choice.get('sectors').includes(sector))
 );
}

export const transformHideRuleIntoChoices = (
  healthcheckQuestion,
  fieldConfig
) => {
  if (!healthcheckQuestion) {
    return fieldConfig;
  }

  const valueDropdownConfig = fromJS({
    required: true,
    type: 'choice',
    choices: [
      {
        display_name: 'True',
        value: true,
      },
      {
        display_name: 'False',
        value: false,
      },
    ],
  });

  const currentRules = healthcheckQuestion
    .get('hide_rules')
    .map(rule => rule.get('attribute'));

  return filterHideRulesBySector(fieldConfig
    .update('attribute_value', config => config.merge(valueDropdownConfig))
    .updateIn(['attribute', 'choices'], choices =>
      choices.filterNot(choice => currentRules.includes(choice.get('value')))
    ));
};

export const transformRegionHideRuleIntoChoices = (
  healthcheckQuestion,
  fieldConfig
) => {
  if (!healthcheckQuestion) {
    return fieldConfig;
  }

  const currentRegionRules = healthcheckQuestion
    .get('region_hide_rules')
    .map(rule => rule.get('region'));

  return fieldConfig.updateIn(['region', 'choices'], choices =>
    choices.filterNot(choice =>
      currentRegionRules.includes(choice.get('value'))
    )
  );
};
