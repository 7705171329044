import React from 'react';
import { reduxForm } from 'redux-form';

import PureComponent from '^/components/common/PureComponent';

import {
  renderCheckItemWithSnapshotData,
  renderTextItemWithSnapshotData,
  renderTextAreaItem,
} from '^/components/app/digital-tools/records/checklist/field-utils';

export class DynamicChecklistForm extends PureComponent {
  render() {
    const {
      fields,
      handleSubmit,
      handleChange,
      digitalRecordChecklistType,
      fieldsData,
      dynamicChecklistSnapshotHeading,
    } = this.props;

    const snapshotFields = dynamicChecklistSnapshotHeading
      .map(key => key)
      .toList()
      .toJS();

    const renderCheck = (field, snapshotData, snapshotHash, choices) =>
      renderCheckItemWithSnapshotData(
        field,
        event => {
          handleChange(field, event, digitalRecordChecklistType, snapshotHash);
        },
        snapshotData,
        choices
      );

    const renderText = (field, snapshotData, snapshotHash) =>
      renderTextItemWithSnapshotData(
        field,
        event => {
          handleChange(field, event, digitalRecordChecklistType, snapshotHash);
        },
        snapshotData
      );

    const renderTextArea = (field, label) =>
      renderTextAreaItem(field, label, event => {
        handleChange(field, event, digitalRecordChecklistType);
      });

    const renderSnapshotTableData = data => {
      // Get the sorting order from the snapshot heading
      const sortByKeyIndex = dynamicChecklistSnapshotHeading
        .map((_, value) => value)
        .toList()
        .toJS();

      const renderedData = [];

      // Sort the table row according to the snapshot heading
      Object.entries(data)
        .sort(
          ([a], [b]) => sortByKeyIndex.indexOf(a) - sortByKeyIndex.indexOf(b)
        )
        .forEach(([key, value]) => {
          if (key !== 'id' && key !== 'heading') {
            renderedData.push(value);
          }
        });

      return renderedData;
    };

    const showFieldHeading = index => {
      if (index === 0 && fieldsData[0].snapshot.heading !== null) {
        return (
          <th>
            <h6 className="mb-1 mr-1">{fieldsData[index].snapshot.heading}</h6>
          </th>
        );
      }

      if (
        index < fieldsData.length - 1 &&
        index > 0 &&
        fieldsData[index].snapshot.heading !==
          fieldsData[index - 1].snapshot.heading
      ) {
        return (
          <th>
            <h6 className="mb-1 mr-1">{fieldsData[index].snapshot.heading}</h6>
          </th>
        );
      }
    };

    // A bit of a hack to display 'notes' as a normal field
    // 'notes' is the last field, remove it from the rest of the data and
    // display it as a one off item at the bottom
    const notesField = fieldsData[fieldsData.length - 1];
    const snapshotFieldsData = fieldsData.slice(0, -1);

    const sortedFieldsDataBySnapshotHeading = snapshotFieldsData.sort(
      (a, b) => {
        if (a.snapshot.heading !== null && b.snapshot.heading !== null) {
          return a.snapshot.heading.localeCompare(b.snapshot.heading);
        }
      }
    );

    return (
      <form ref="formRef" onSubmit={handleSubmit}>
        <div>
          <table className="setup-compliance-review-table text-left">
            <thead>
              <tr>
                {snapshotFields.map(field => (
                  <th key={field}>{field}</th>
                ))}
              </tr>
            </thead>
            <tbody className="text-left">
              <tr>
                {sortedFieldsDataBySnapshotHeading.map((field, i) => {
                  switch (field.field_type) {
                    case 'YES_NO_NA':
                      return (
                        <tr>
                          {showFieldHeading(i)}
                          {renderCheck(
                            fields[field.key],
                            renderSnapshotTableData(field.snapshot),
                            field.snapshot_hash,
                            ['YES', 'NO', 'NA']
                          )}
                        </tr>
                      );
                    case 'YES_NO':
                      return (
                        <tr>
                          {showFieldHeading(i)}
                          {renderCheck(
                            fields[field.key],
                            renderSnapshotTableData(field.snapshot),
                            field.snapshot_hash,
                            ['YES', 'NO']
                          )}
                        </tr>
                      );
                    case 'FREE_TEXT':
                      return (
                        <tr>
                          {showFieldHeading(i)}
                          {renderText(
                            fields[field.key],
                            renderSnapshotTableData(field.snapshot),
                            field.snapshot_hash
                          )}
                        </tr>
                      );
                    case 'PASS_FAIL_NA':
                      return (
                        <div>
                          {showFieldHeading(i)}
                          {renderCheck(fields[field.key], field.name, [
                            'PASS',
                            'FAIL',
                            'NA',
                          ])}
                        </div>
                      );
                    default:
                      return (
                        <tr>
                          <th>{showFieldHeading}</th>
                          {renderText(
                            fields[field.key],
                            renderSnapshotTableData(field.snapshot),
                            field.snapshot_hash
                          )}
                        </tr>
                      );
                  }
                })}
              </tr>
            </tbody>
          </table>
          <div className="mb-1 mt-1">
            <h5 className="mb-1">Notes</h5>
            {renderTextArea(fields[notesField.key], notesField.name)}
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  touchOnChange: true,
  touchOnBlur: false,
})(DynamicChecklistForm);
