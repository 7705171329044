import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List, Map } from 'immutable';

import { isPending } from '^/consts/responseStates';
import { TASKS } from '^/consts/collectionKeys';
import { getAllCollection } from '^/actions/collections';
import { setSelectValue, clearSelect } from '^/actions/actions';
import { changeStepAndAddReassignData } from '^/actions/actionSequences';
import { USER_REASSIGN_STEPS, REASSIGN_MEMBER, REASSIGN_STEPS } from '^/consts/retireSuspend';

import Loading from '^/components/app/content/Loading';
import EmptyListPage from '^/components/app/practices/reassign-member/EmptyListPage';
import MemberFilter from '^/components/app/practices/reassign-member/MemberFilter';
import PureComponent from '^/components/common/PureComponent';
import
  ReassignTasksForm
  from '^/components/app/practices/reassign-member/ReassignTasksForm';


export class ReassignTasks extends PureComponent {

  componentWillMount() {
    const { loadTasks } = this.props;

    loadTasks();
  }

  render() {
    const {
      loading, tasks, setDefaultMember,
      memberSelectValue, memberChoices,
      handleSubmit
    } = this.props;

    if (loading) { return <Loading />; }

    if (tasks.isEmpty()) {
      return (
        <EmptyListPage
          reassignType="tasks"
          handleSubmit={handleSubmit}
        />
      );
    }


    const taskIds =
      tasks
        .map(task => task.get('id'));

    const initialValues = Map(
        taskIds
          .map(id => [id, memberSelectValue || memberChoices.first().get('value')])
      ).toJS();


    return (
      <div className="mb-1">
        <em>Reassign all tasks to &nbsp;</em>
        <MemberFilter
          choices={memberChoices}
          onChange={setDefaultMember}
          value={memberSelectValue}
        />
        <ReassignTasksForm
          key={memberSelectValue}
          memberChoices={memberChoices}
          tasks={tasks}
          fields={taskIds.toJS()}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      </div>
    );
  }
}

ReassignTasks.propTypes = {
  member: ImmutablePropTypes.map.isRequired,
  practice: ImmutablePropTypes.map.isRequired,
  memberChoices: ImmutablePropTypes.list.isRequired,
  currentStep: React.PropTypes.string.isRequired,
};

export function mapStateToProps(state) {
  const loading = isPending(state.responses.getIn(['getCollection', TASKS]));

  return {
    tasks: state.collections.getIn([TASKS, 'items'], List()),
    memberSelectValue: state.ui.getIn(['selectState', REASSIGN_MEMBER]),
    loading,
  };
}

export function mapDispatchToProps(dispatch, props) {
  const { currentStep, member, practice } = props;

  const additionalParams = {
    staffdetail: member.getIn(['user', 'staffdetail', 'id']),
    practice: practice.get('id')
  };

  const nextStepIndex = USER_REASSIGN_STEPS.indexOf(currentStep) + 1;
  const nextStep = USER_REASSIGN_STEPS.get(nextStepIndex);

  return {
    loadTasks: () =>
      dispatch(getAllCollection(TASKS, { additionalParams })),
    setDefaultMember: val => dispatch(setSelectValue(REASSIGN_MEMBER, val)),
    clearDefaultMember: () => dispatch(clearSelect(REASSIGN_MEMBER)),
    handleSubmit: data =>
      dispatch(changeStepAndAddReassignData(REASSIGN_STEPS, nextStep, currentStep, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReassignTasks);
