import React from 'react';
import { reduxForm } from 'redux-form';

import PureComponent from '^/components/common/PureComponent';

import {
  renderCheckItem,
  renderTextItem,
  renderTextAreaItem,
} from '^/components/app/digital-tools/records/checklist/field-utils';

export class StaticChecklistForm extends PureComponent {
  render() {
    const {
      fields,
      handleSubmit,
      handleChange,
      digitalRecordChecklistType,
      fieldsData,
    } = this.props;

    const renderCheck = (field, label, choices) =>
      renderCheckItem(
        field,
        label,
        event => {
          handleChange(field, event, digitalRecordChecklistType);
        },
        choices
      );

    const renderText = (field, label) =>
      renderTextItem(field, label, event => {
        handleChange(field, event, digitalRecordChecklistType);
      });

    const renderTextArea = (field, label) =>
      renderTextAreaItem(field, label, event => {
        handleChange(field, event, digitalRecordChecklistType);
      });

    const showFieldHeading = index => {
      if (index === 0 && fieldsData[0].heading !== null) {
        return <h3 className="mb-1">{fieldsData[index].heading}</h3>;
      }

      if (
        index > 0 &&
        fieldsData[index].heading !== fieldsData[index - 1].heading
      ) {
        return <h3 className="mb-1">{fieldsData[index].heading}</h3>;
      }
    };

    return (
      <form ref="formRef" onSubmit={handleSubmit}>
        {fieldsData.map((field, i) => {
          switch (field.field_type) {
            case 'YES_NO_NA':
              return (
                <div>
                  {showFieldHeading(i)}
                  {renderCheck(fields[field.key], field.name, [
                    'YES',
                    'NO',
                    'NA',
                  ])}
                  {field.help_text && (
                    <p className="pb-2 col-1 checklist-help-text">
                      {field.help_text}
                    </p>
                  )}
                </div>
              );
            case 'YES_NO':
              return (
                <div>
                  {showFieldHeading(i)}
                  {renderCheck(fields[field.key], field.name, ['YES', 'NO'])}
                  {field.help_text && (
                    <p className="pb-2 col-1 checklist-help-text">
                      {field.help_text}
                    </p>
                  )}
                </div>
              );
            case 'FREE_TEXT':
              return (
                <div>
                  {showFieldHeading(i)}
                  {renderText(fields[field.key], field.name)}
                  {field.help_text && (
                    <p className="pb-2 col-1 checklist-help-text">
                      {field.help_text}
                    </p>
                  )}
                </div>
              );
            case 'FREE_TEXT_AREA':
              return (
                <div>
                  {showFieldHeading(i)}
                  {renderTextArea(fields[field.key], field.name)}
                  {field.help_text && (
                    <p className="pb-2 col-1 checklist-help-text">
                      {field.help_text}
                    </p>
                  )}
                </div>
              );
            case 'PASS_FAIL_NA':
              return (
                <div>
                  {showFieldHeading(i)}
                  {renderCheck(fields[field.key], field.name, [
                    'PASS',
                    'FAIL',
                    'NA',
                  ])}
                  {field.help_text && (
                    <p className="pb-2 col-1 checklist-help-text">
                      {field.help_text}
                    </p>
                  )}
                </div>
              );
            default:
              return (
                <div>
                  {showFieldHeading(i)}
                  {renderText(fields[field.key], field.name)}
                  {field.help_text && (
                    <p className="pb-2 col-1 checklist-help-text">
                      {field.help_text}
                    </p>
                  )}
                </div>
              );
          }
        })}
      </form>
    );
  }
}

export default reduxForm({
  touchOnChange: true,
  touchOnBlur: false,
})(StaticChecklistForm);
