export const EventRecordStatus = {
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  CLOSED: 'CLOSED',
}

export const EventRecordStatusLabels = {
  [EventRecordStatus.OPEN]: 'Open',
  [EventRecordStatus.IN_PROGRESS]: 'In Progress',
  [EventRecordStatus.CLOSED]: 'Closed',
}

export const EVENT_TYPE_OPTIONS = [
  {
    "key": "PROBLEM",
    "value": "Problem"
  },
  {
    "key": "SAFETY_INCIDENT",
    "value": "Safety Incident"
  },
  {
    "key": "SERIOUS_INCIDENT",
    "value": "Serious Incident"
  },
  {
    "key": "SAFETY_ALERT",
    "value": "Safety Alert"
  },
  {
    "key": "NEVER_EVENT",
    "value": "Never Event"
  },
  {
    "key": "HAZARD_OBSERVATION",
    "value": "Hazard Observation"
  },
  {
    "key": "NEGATIVE_REVIEW",
    "value": "Negative Review"
  },
  {
    "key": "SIGNIFICANT",
    "value": "Significant"
  },
  {
    "key": "OTHER",
    "value": "Other"
  },
  {
    "key": "COMPLIMENT",
    "value": "Compliment"
  },
  {
    "key": "COMPLAINT",
    "value": "Complaint"
  },
];
