import React from 'react';
import { reduxForm } from 'redux-form';
import { List, fromJS, Map } from 'immutable';
import moment from 'moment';

import { isOptional } from '^/models/compliancereviews';
import { calculateNextDueDate } from '^/models/urgency';
import { formatDateOrNull } from '^/utils';

import LiveButton from '^/components/app/forms/LiveButton';
import { DATE_LAST_DONE, NEVER_DONE, DUE_DATE, INCLUDED } from '^/consts/account-set-up/fieldNames';
import { 
  OptionalTemplateSecurityAuditOrAssessmentFields,
  CompulsoryTemplateSecurityAuditOrAssessmentFields
} from '^/components/app/account-set-up/SecurityAuditsRiskAssessmentsCommonFormComponents';

export const RISK_ASSESSMENTS_SETUP_FORM = 'RISK_ASSESSMENTS_SETUP_FORM';

export const RiskAssessmentsSetupForm = ({
   handleSubmit, response, fields, templateRiskAssessments, cycleStartDate, isReview
}) =>
  <form onSubmit={handleSubmit}>

    <CompulsoryTemplateSecurityAuditOrAssessmentFields
      response={response}
      fields={fields}
      auditsOrAssessments={templateRiskAssessments.filterNot(isOptional)}
      cycleStartDate={cycleStartDate}
      isReview={isReview}
    />

    <OptionalTemplateSecurityAuditOrAssessmentFields
      response={response}
      fields={fields}
      auditsOrAssessments={templateRiskAssessments.filter(isOptional)}
    />

    <LiveButton className="btn-default" pendingMessage="Saving..." response={response}>
      Next...
    </LiveButton>
  </form>
;

export const getFields = (templateRiskAssessments) =>
  templateRiskAssessments
    .map(template =>
      List.of(INCLUDED, DATE_LAST_DONE, NEVER_DONE, DUE_DATE).map(
        fieldName => `${template.get('id')}.${fieldName}`
      )
    )
    .flatten()
    .toJS();

function _transformAssessmentData(assessments, templateRiskAssessments, cycleStartDate, forValidation=false) {
  const findAssessment = assessmentId => templateRiskAssessments.find(each => each.get('id') === assessmentId);

  return assessments
    .filterNot((assessment, assessmentId) =>
      isOptional(findAssessment(assessmentId)) && !assessment.get(INCLUDED)
    )
    .map((assessment, assessmentId) => Map({
      id: assessmentId,
      [INCLUDED]: assessment.get(INCLUDED),
      [NEVER_DONE]: forValidation ? assessment.get(NEVER_DONE) : undefined,
      [DUE_DATE]: formatDateOrNull(
        assessment.get(DUE_DATE) || calculateNextDueDate(
          assessment.get(DATE_LAST_DONE),
          findAssessment(assessmentId),
          moment(cycleStartDate),
          1,
          assessment.get(NEVER_DONE)
        ),
        'YYYY-MM-DD'
      ),
    }));
}

export function transformAssessmentData(assessments, templateRiskAssessments, cycle) {
  return _transformAssessmentData(assessments, templateRiskAssessments, cycle.get('start_date'))
    .valueSeq().toJS();
}

export function validateData(data, templateRiskAssessments, cycleStartDate) {
  const findAssessment = assessmentId => templateRiskAssessments.find(each => each.get('id') === assessmentId);
  const assessments = fromJS(data);

  return _transformAssessmentData(assessments, templateRiskAssessments, cycleStartDate, true)
    .filter(assessment => !assessment.get(DUE_DATE) && !assessment.get(NEVER_DONE))
    .filterNot(assessment => assessment.get(INCLUDED) && assessment.get(DUE_DATE))
    .map((assessment, assessmentId) => ({
      [DATE_LAST_DONE]: 'You must provide a ' +
        (isOptional(findAssessment(assessmentId)) ? 'scheduled date for an optional' : 'last done date for a compulsory') +
        ' risk assessment.'
    }))
    .toJS();
}

export const mapStateToProps = (state, props) => ({
  fields: getFields(props.templateRiskAssessments),
  validate: (data) => validateData(data, props.templateRiskAssessments, props.cycleStartDate),
});

export default reduxForm(
  { form: RISK_ASSESSMENTS_SETUP_FORM },
  mapStateToProps
)(RiskAssessmentsSetupForm);
