import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import {
  deleteItemAndRedirectTo,
  getDraftsCollectionAndNavTo,
  patchItemAndRunCallback
} from '^/actions/actionSequences';
import { resetResponse } from '^/actions/actions';
import { DOCUMENT_TYPE } from '^/models/documents';
import { loadItem } from '^/actions/items';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import PureComponent from '^/components/common/PureComponent';
import { MODELS } from '^/permissions/capabilities';
import {
  ADMIN_TEMPLATE_DOCUMENT_CREATE,
  ADMIN_TEMPLATE_DOCUMENT_CREATE_MODEL,
  ADMIN_TEMPLATE_DOCUMENT_DRAFT_EDIT_FIELDS,
  ADMIN_TEMPLATE_DOCUMENT_DRAFT_MODEL,
  transformDataToSubmit,
} from './template-documents';
import TemplateDocumentVersionDownloadButton
  from '^/components/admin/template-documents/TemplateDocumentVersionDownloadButton';
import {
  openPublishDraftModal,
} from '^/actions/modals';

function transform(fieldConfig, fields) {
  return fieldConfig
  .setIn(['content', 'isLandscape'], fields.is_landscape.value)
  .setIn(['sector', 'read_only'], true)
  .mergeIn(['content'], Map({ includeVariables: true, variablesSector: fields.sector.value }));
}

export class EditTemplateDocumentDraft extends PureComponent {
  constructor() {
    super();
    this.updateDeleteDraftButtons = this.updateDeleteDraftButtons.bind(this);
    this.printButton = this.printButton.bind(this);
    this.navToCreatedDoc = this.navToCreatedDoc.bind(this);
  }

  componentWillMount() {
    this.props.loadItem(ADMIN_TEMPLATE_DOCUMENT_DRAFT_MODEL, this.props.draftId);
  }

  componentWillUnmount() {
    this.props.resetResponse('loadItem');
  }

  navToCreatedDoc(model, data, collectionName, formName, newItem) {
    this.props.getDraftsCollectionAndNavTo(`/admin/template-documents/${newItem.id}`);
  }

  updateDraft(e, data) {
    e.preventDefault();
    const dataToSubmit = data;
    dataToSubmit['region_hide_rules'] = data['region_hide_rules'].filter(rule => rule !== '');
    this.props.patchItemAndRunCallback(
      ADMIN_TEMPLATE_DOCUMENT_DRAFT_MODEL,
      this.props.draftId,
      dataToSubmit,
      null,
      () => this.props.getDraftsCollectionAndNavTo('/admin/template-documents/drafts/')
    );
  }

  deleteDraft(e) {
    e.preventDefault();
    this.props.deleteItemAndRedirectTo(
      ADMIN_TEMPLATE_DOCUMENT_DRAFT_MODEL,
      this.props.draftId,
      '/admin/template-documents/drafts/'
    );
  }

  updateDeleteDraftButtons(data, disabled) {
    return (
      <span>
        <input
          type="submit"
          className="btn btn-primary btn-admin-form"
          onClick={e => this.updateDraft(e, data)}
          disabled={disabled}
          value="Update draft"
        />
        <input
          type="submit"
          className="btn btn-warning bold-text btn-admin-form"
          onClick={e => this.deleteDraft(e)}
          disabled={disabled}
          value="Delete draft"
        />
      </span>
    );
  }

  printButton() {
    return (
      <TemplateDocumentVersionDownloadButton
        documentVersionId={this.props.draftId}
        documentType={DOCUMENT_TYPE.DRAFT}
      />
    );
  }

  render() {
    const { draftData, draftId, publishDraft } = this.props;
    return (
      <div>
        <AdminContainer title="Template Document Drafts">
          <AdminCreate
            permsModel={MODELS.TEMPLATE_DOCUMENT}
            controlName={ADMIN_TEMPLATE_DOCUMENT_CREATE}
            collectionName={ADMIN_TEMPLATE_DOCUMENT_CREATE_MODEL}
            model={ADMIN_TEMPLATE_DOCUMENT_CREATE_MODEL}
            defaultValues={{'is_landscape': false, }}
            fields={ADMIN_TEMPLATE_DOCUMENT_DRAFT_EDIT_FIELDS}
            getInitialValues={() => draftData.toJS()}
            title="Edit Draft"
            className="btn-left-align"
            transform={transform}
            transformDataToSubmit={transformDataToSubmit}
            customSubmitButtons={this.updateDeleteDraftButtons}
            saveButtonText="Publish"
            sourceItemId={draftId}
            customTopButton={this.printButton}
            saveModal={(data) => publishDraft(data, this.navToCreatedDoc)}
          />
        </AdminContainer>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    draftId: props.params.uuid,
    draftData: state.responses.getIn(['loadItem', ADMIN_TEMPLATE_DOCUMENT_DRAFT_MODEL, 'payload'], Map())
  };
}

export default connect(mapStateToProps, {
  routePush: routeActions.push,
  publishDraft: openPublishDraftModal,
  deleteItemAndRedirectTo,
  loadItem,
  patchItemAndRunCallback,
  getDraftsCollectionAndNavTo,
  resetResponse
})(EditTemplateDocumentDraft);
