import { createReducer } from '@dabapps/redux-create-reducer';
import { combineReducers } from 'redux';
import moment from 'moment';

import {
  GET_HEALTHCHECK_QUESTIONS_PROGRESS,
  GET_HEALTHCHECK_QUESTIONS_CATEGORY,
  GET_HEALTHCHECK_ACTIONS_PROGRESS,
  GET_HEALTHCHECK_ACTIONS_CATEGORY,
  GET_HEALTHCHECK_REVIEW,
  GET_HEALTHCHECK_AUDIT_RESULTS,
  UPDATE_HEALTHCHECK_STATUS,
  GENERIC_HEALTHCHECK_ERROR,
  ANSWER_HEALTHCHECK_QUESTION,
  AnswerQuestionActionExtra,
  UPDATE_ACTION,
  MarkActionCompleteActionExtra,
  GET_CURRENT_PRACTICE_HEALTHCHECKS,
  GET_HEALTHCHECK_BY_ID,
  DELETE_HEALTHCHECK,
} from '^/actions/healthcheck';
import {
  createThunkRequestReducer,
  ThunkRequestSuccessAction,
  ThunkActionWithPayload,
} from '^/utils/requests';
import {
  HealthcheckCategoriesMerged,
  HealthcheckQuestion,
  HealthcheckCategoriesProgress,
  HealthcheckCategoryWithAreas,
  HealthcheckAction,
  HealthcheckListSummary,
  HealthcheckReview,
  HealthcheckAuditResult,
  ItemKeyedById,
  CachedItem,
  Healthcheck,
  HealthcheckState,
} from '^/components/app/healthcheck/types';

const genericHealthcheckError = createReducer<
  string | null,
  { type: string; payload: string }
>(
  {
    [GENERIC_HEALTHCHECK_ERROR]: (_state, action) => action.payload,
  },
  null
);

const questionsCategories = createReducer<
  HealthcheckCategoriesMerged<HealthcheckQuestion> | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ThunkActionWithPayload<any, any>
>(
  {
    [GET_HEALTHCHECK_QUESTIONS_PROGRESS.SUCCESS]: (
      _state,
      action: ThunkRequestSuccessAction<HealthcheckCategoriesProgress, {}>
    ) => action.payload.data,
    [GET_HEALTHCHECK_QUESTIONS_CATEGORY.SUCCESS]: (
      state,
      action: ThunkRequestSuccessAction<
        HealthcheckCategoryWithAreas<HealthcheckQuestion>,
        {}
      >
    ) => {
      if (!state) {
        return state;
      }

      const matchingIndex = state.findIndex(
        ({ id }) => id === action.payload.data.id
      );

      return state.map((category, index) => {
        if (index !== matchingIndex) {
          return category;
        }

        return {
          ...category,
          ...action.payload.data,
        };
      });
    },
    [ANSWER_HEALTHCHECK_QUESTION.SUCCESS]: (
      state,
      action: ThunkRequestSuccessAction<
        HealthcheckQuestion,
        AnswerQuestionActionExtra
      >
    ) => {
      if (!state) {
        return state;
      }

      return state.map(category => {
        if (category.id !== action.meta.extra.categoryId) {
          return category;
        }

        const newAreas = category.areas?.map(area => {
          if (area.id !== action.meta.extra.areaId) {
            return area;
          }

          return {
            ...area,
            items: area.items.map(item => {
              if (item.id !== action.meta.extra.questionId) {
                return item;
              }
              return {
                ...item,
                ...action.payload.data,
              };
            }),
          };
        });

        return {
          ...category,
          areas: newAreas,
          is_completed: Boolean(
            newAreas?.every(area => area.items.every(item => item.response))
          ),
        };
      });
    },
  },
  null
);

const actionsCategories = createReducer<
  HealthcheckCategoriesMerged<HealthcheckAction> | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ThunkActionWithPayload<any, any>
>(
  {
    [GET_HEALTHCHECK_ACTIONS_PROGRESS.SUCCESS]: (
      _state,
      action: ThunkRequestSuccessAction<HealthcheckCategoriesProgress, {}>
    ) => action.payload.data,
    [GET_HEALTHCHECK_ACTIONS_CATEGORY.SUCCESS]: (
      state,
      action: ThunkRequestSuccessAction<
        HealthcheckCategoryWithAreas<HealthcheckAction>,
        {}
      >
    ) => {
      if (!state) {
        return state;
      }

      const matchingIndex = state.findIndex(
        ({ id }) => id === action.payload.data.id
      );

      return state.map((category, index) => {
        if (index !== matchingIndex) {
          return category;
        }

        return {
          ...category,
          ...action.payload.data,
        };
      });
    },
    [UPDATE_ACTION.SUCCESS]: (
      state,
      action: ThunkRequestSuccessAction<
        HealthcheckQuestion,
        MarkActionCompleteActionExtra
      >
    ) => {
      if (!state) {
        return state;
      }

      return state.map(category => {
        if (category.id !== action.meta.extra.categoryId) {
          return category;
        }

        const newAreas = category.areas?.map(area => {
          if (area.id !== action.meta.extra.areaId) {
            return area;
          }

          return {
            ...area,
            items: area.items.map(item => {
              if (item.id !== action.meta.extra.actionId) {
                return item;
              }
              return {
                ...item,
                ...action.payload.data,
              };
            }),
          };
        });

        return {
          ...category,
          areas: newAreas,
          is_completed: Boolean(
            newAreas?.every(area => area.items.every(item => item.is_completed))
          ),
        };
      });
    },
  },
  null
);

const currentPracticeHealthchecks = createReducer<
  HealthcheckListSummary[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ThunkActionWithPayload<any, any>
>(
  {
    [GET_CURRENT_PRACTICE_HEALTHCHECKS.SUCCESS]: (_state, action) =>
      action.payload.data.results,
  },
  []
);

const review = createReducer<
  HealthcheckReview | null,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  ThunkRequestSuccessAction<any>
>(
  {
    [GET_HEALTHCHECK_REVIEW.SUCCESS]: (
      _state,
      action: ThunkRequestSuccessAction<HealthcheckReview>
    ) => {
      return action.payload.data;
    },
    [UPDATE_HEALTHCHECK_STATUS.SUCCESS]: (
      state,
      action: ThunkRequestSuccessAction<Healthcheck>
    ) => {
      if (!state) {
        return null;
      }

      return {
        ...state,
        status: action.payload.data.status,
        modified: moment().format(),
      };
    },
  },
  null
);

const auditResults = createThunkRequestReducer<HealthcheckAuditResult[] | null>(
  GET_HEALTHCHECK_AUDIT_RESULTS,
  null
);

const healthcheckById = createReducer<ItemKeyedById<CachedItem<Healthcheck>>>(
  {
    [GET_HEALTHCHECK_BY_ID.SUCCESS]: (state, action) => ({
      ...state,
      [action.payload.data.id]: {
        ...action.payload.data,
        modified: moment()
          .toDate()
          .getTime(),
      },
    }),
    [UPDATE_HEALTHCHECK_STATUS.SUCCESS]: (state, action) => ({
      ...state,
      [action.payload.data.id]: {
        ...state[action.payload.data.id],
        status: action.payload.data.status,
        modified: moment()
          .toDate()
          .getTime(),
      },
    }),
    [DELETE_HEALTHCHECK.SUCCESS]: (state, action) => ({
      ...state,
      [action.meta.extra]: null,
    }),
  },
  {}
);

export default combineReducers<HealthcheckState>({
  genericHealthcheckError,
  questionsCategories,
  actionsCategories,
  auditResults,
  review,
  currentPracticeHealthchecks,
  healthcheckById,
});
