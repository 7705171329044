const isNonNullObject = (input: unknown): input is {} =>
  Boolean(typeof input === 'object' && input);

export const hasKey = <T extends string>(
  input: unknown,
  key: T
): input is { [P in T]: unknown } => isNonNullObject(input) && key in input;

export const extractErrorMessage = (errors: unknown): string | null => {
  if (!errors) {
    return null;
  }

  if (typeof errors === 'string') {
    return errors || 'Unknown error';
  }

  if (typeof errors === 'number') {
    return errors.toString();
  }

  if (typeof errors === 'object') {
    if (Array.isArray(errors)) {
      const maybeError = extractErrorMessage(errors[0]);

      if (maybeError) {
        return maybeError;
      }
    }

    if (hasKey(errors, 'response')) {
      const maybeError = extractErrorMessage(errors.response);

      if (maybeError) {
        return maybeError;
      }
    }

    if (hasKey(errors, 'detail')) {
      const maybeError = extractErrorMessage(errors.detail);

      if (maybeError) {
        return maybeError;
      }
    }

    if (hasKey(errors, 'data')) {
      const maybeError = extractErrorMessage(errors.data);

      if (maybeError) {
        return maybeError;
      }
    }

    if (hasKey(errors, 'message')) {
      const maybeError = extractErrorMessage(errors.message);

      if (maybeError) {
        return maybeError;
      }
    }

    if (hasKey(errors, 'non_field_errors')) {
      const maybeError = extractErrorMessage(errors.non_field_errors);

      if (maybeError) {
        return maybeError;
      }
    }

    if (errors instanceof Error) {
      return errors.toString();
    }
  }

  return null;
};
