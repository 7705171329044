import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  type?: 'info';
  children: ReactNode | readonly ReactNode[];
  className?: string;
}

const Alert = ({ type = 'info', className, children }: Props) => {
  return (
    <div className={classNames(`alert-${type}`, className)}>{children}</div>
  );
};

export default Alert;
