import React from 'react';
import classNames from 'classnames';

import moment from 'moment';
import DateTime from 'react-datetime';
import PureComponent from '^/components/common/PureComponent';

const FORMAT = 'HH:mm';
const TIME_CONSTRAINTS = {
  minutes: { step: 15 }
};

export default class TimeInput extends PureComponent {

  getValue() {
    const value = moment(this.props.value, FORMAT);
    return value.isValid() ? value : moment();
  }

  render() {
    return (
      <DateTime
        value={this.getValue()}
        className={classNames('timeinput-field', this.props.className)}
        dateFormat={false}
        input={false}
        onChange={(value) => this.props.onChange(value.format(FORMAT))}
        timeConstraints={this.props.timeConstraints || TIME_CONSTRAINTS}
      />
    );
  }

}
