import { Map, Set, fromJS } from 'immutable';

import {
  CLOSE_MODAL,
  OPEN_MODAL,
  ADD_CLOSE_BUTTON_AFTER_POLLING,
  REMOVE_CLOSE_BUTTON_WHILE_POLLING,
} from '^/actions/modals';
import * as actions from '^/actions/actions';
import * as items from '^/actions/items';
import { GROUP_TEMPLATE_DOCUMENT_FOLDERS } from '^/consts/collectionKeys';
import { TEMPLATE_VIEW_BY } from '^/consts/templateViewBy';

export function expandedFilters(state = Map(), action) {
  switch (action.type) {
    case actions.EXPAND_FILTER:
      return state.set(action.collection, action.filterKey);
    case actions.CLOSE_FILTERS:
      return state.delete(action.collection);
    default:
      return state;
  }
}

export function teamMemberPracticeSelect(state = '', action) {
  switch (action.type) {
    case actions.CHANGE_TEAM_PRACTICE_SELECT:
      return action.payload;
    default:
      return state;
  }
}

export function imageStyles(state = Map(), action) {
  switch (action.type) {
    case actions.STORE_IMAGE_STYLE:
      return state.set(action.payload.get('ident'), action.payload.get('style'));
    default:
      return state;
  }
}

export function modalConfig(state = Map(), action) {
  switch (action.type) {
    case OPEN_MODAL:
      return state
        // Ensure previous props are removed in case this modal does not have explicit props
        .set('props', {})
        .merge(action.payload)
        .set('open', true);
    case CLOSE_MODAL:
      return Map({
        open: false
      });
    case REMOVE_CLOSE_BUTTON_WHILE_POLLING:
      return state.set('pollInProgress', true);
    case ADD_CLOSE_BUTTON_AFTER_POLLING:
      return state.set('pollInProgress', false);
    default:
      return state;
  }
}

export function sidebarCollapsed(state = false, action) {
  switch (action.type) {
    case actions.TOGGLE_SIDEBAR:
      return !state;
    default:
      return state;
  }
}

export function loggedInAsDropdownOpen(state = false, action) {
  switch (action.type) {
    case actions.TOGGLE_LOGGED_IN_AS_DROPDOWN:
      return !state;
    default:
      return state;
  }
}

export function selectState(state = Map(), action) {
  switch (action.type) {
    case actions.SET_SELECT_VALUE:
      const { controlName, value } = action.payload;
      return state.set(controlName, value);
    case actions.CLEAR_SELECT:
      return state.delete(action.payload.controlName);
    default:
      return state;
  }
}

export function sidebarMenuStates(state = Map(), action) {
  switch (action.type) {
    case actions.TOGGLE_SIDEBAR_MENU:
      const title = action.payload;
      return state.set(title, !state.get(title, false));
    case actions.OPEN_SIDEBAR_MENU:
      return state.set(action.payload, true);
    case actions.CLOSE_SIDEBAR_MENU:
      return state.set(action.payload, false);
    default:
      return state;
  }
}

export function collapse(state = Map(), action) {
  switch (action.type) {
    case actions.SET_COLLAPSE:
      return state.set(action.payload.collapseId, action.payload.collapsed);
    case actions.TOGGLE_COLLAPSE:
      return state.set(action.payload.collapseId, !state.get(action.payload.collapseId));
    default:
      return state;
  }
}

export function retainLoadedDocuments(state = false, action) {
  if (action.type === actions.RETAIN_LOADED_DOCUMENTS) {
    return state = action.payload.retainLoadedDocuments;
  }
  return state;
}

export function parentSize(state = Map(), action) {
  switch (action.type) {
    case actions.SET_PARENT_SIZE:
      return state.set(action.id, Map(action.size));
    case actions.REMOVE_PARENT_SIZE:
      return state.delete(action.id);
    default:
      return state;
  }
}

export function sortBy(state = Map(), action) {
  switch (action.type) {
    case actions.SET_SORT_BY:
      return state.set(action.listName, action.value);
    default:
      return state;
  }
}

export function tablePageSize(state = Map(), action) {
  switch (action.type) {
    case actions.SET_TABLE_PAGE_SIZE:
      return state.set(action.listName, action.value);
    default:
      return state;
  }
}

export function sortByReversed(state = Map(), action) {
  switch (action.type) {
    case actions.SET_SORT_BY_REVERSED:
      return state.set(action.listName, action.value);
    default:
      return state;
  }
}

export function activeTabs(state = Map(), action) {
  switch (action.type) {
    case actions.SET_ACTIVE_TAB:
      const { tabsetName, value } = action.payload;
      return state.set(tabsetName, value);
    case items.UPDATE_ITEM.SUCCESS:
      if (action.meta.collectionName === GROUP_TEMPLATE_DOCUMENT_FOLDERS) {
        const { name } = action.payload;
        return state.setIn([TEMPLATE_VIEW_BY.FOLDER, 'name'], name);
      }
      return state;
    case actions.CLEAR_TABSET:
      const { tabset } = action.payload;
      return state.delete(tabset);
    default:
      return state;
  }
}

export function selectedEvent(state = Map(), action) {
  switch (action.type) {
    case actions.SET_SELECTED_EVENT:
      return Map(action.payload);
    default:
      return state;
  }
}

export function chartWidth(state = Map(), action) {
  switch (action.type) {
    case actions.UPDATE_CHART_WIDTH:
      const { chartId, width } = action.payload;
      return state.set(chartId, width);
    default:
      return state;
  }
}

export function expandedUiComponents(state = Set(), action) {
  switch (action.type) {
    case actions.COLLAPSE_UI_COMPONENT:
      return state.remove(action.payload.id);
    case actions.EXPAND_UI_COMPONENT:
      return state.add(action.payload.id);
    case actions.COLLAPSE_ALL_UI_COMPONENTS:
      return Set();
    case actions.EXPAND_ALL_UI_COMPONENTS:
      return state.union(action.payload.componentIds);
    default:
      return state;
  }
}

export function selectHighlightedIndex(state = Map(), action) {
  switch (action.type) {
    case actions.SET_HIGHLIGHTED_INDEX:
      return state.set(action.payload.id, action.payload.index);
    case actions.CLEAR_HIGHLIGHTED_INDEX:
      return state.delete(action.payload.id);
    default:
      return state;
  }
}

export function searchItemQuery(state = Map(), action) {
  switch (action.type) {
    case actions.UPDATE_SEARCH_ITEM_QUERY:
      const { id, searchQuery } = action.payload;
      return state.set(id, searchQuery);
    default:
      return state;
  }
}

export function captcha(state = null, action) {
  switch (action.type) {
    case actions.SET_CAPTCHA:
      return action.payload;
    default:
      return state;
  }
}

export function navDisabled(state = false, action) {
  switch (action.type) {
    case actions.DISABLE_NAV:
      return true;
    case actions.ENABLE_NAV:
      return false;
    default:
      return state;
  }
}

export function showing(state = Map(), action) {
  switch (action.type) {
    case actions.SET_SHOWING:
      return state.set(action.payload.showingSet, action.payload.showing);
    default:
      return state;
  }
}

export function formErrors(state = Map(), action) {
  switch (action.type) {
    case actions.SET_FORM_ERROR:
      const { formId, errors } = action.payload;
      return state.set(formId, fromJS(errors));
    default:
      return state;
  }
}

export function searchText(state = '', action) {
  switch (action.type) {
    case actions.SET_SEARCH_TEXT:
      return action.payload;
    default:
      return state;
  }
}

export function defaultDocumentSearchValue(state = '', action) {
  switch (action.type) {
    case actions.SET_DEFAULT_DOCUMENT_SEARCH_VALUE:
      return action.payload;
    default:
      return state;
  }
}

export function editorContent(state = '', action) {
  switch (action.type) {
    case actions.SET_EDITOR_CONTENT:
      return action.payload;
    default:
      return state;
  }
}

export function acceptTermsButton(state = false, action) {
  switch (action.type) {
    case actions.TOGGLE_TERMS_ACCEPT:
      return action.payload;
    case actions.GET_LATEST_TERMS.SUCCESS:
    case actions.GET_LATEST_TERMS.REQUEST:
      return false;
    default:
      return state;
  }
}

export function expandedSections(state = Map(), action) {
  switch (action.type) {
    case actions.TOGGLE_EXPAND_SECTION:
      return state.update(action.payload.key, sections => {
        const { payload: {section} } = action;

        return sections && sections.contains(action.payload.section)
          ? sections.delete(section)
          : (sections || Set()).add(section);
      });
    case actions.COLLAPSE_ALL_SECTIONS:
      return state.set(action.payload.key, Set());
    default:
      return state;
  }
}

export function disableReportDownloadButton(state = false, action) {
  switch (action.type) {
    case actions.SET_DOWNLOADING_REPORT:
      return true;
    case CLOSE_MODAL:
      return false;
    default:
      return state;
  }
}

export function docToReadCheckBox(state = false, action) {
  switch (action.type) {
    case actions.TOGGLE_DOC_TO_READ_CHECKBOX:
      return action.payload;
    default:
      return state;
  }
}

export function markAsReadButton(state = false, action) {
  switch (action.type) {
    case actions.TOGGLE_MARK_AS_READ_BUTTON:
      return action.payload;
    default:
      return state;
  }
}

export function applyToLiveCyclesMonthOverride(state = false, action) {
  switch (action.type) {
    case actions.STORE_APPLY_TO_LIVE_CYCLES_MONTH_OVERRIDE:
      return action.payload;
    default:
      return state;
  }
}
