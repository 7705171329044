import React from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import PureComponent from '^/components/common/PureComponent';
import ItemField from '^/components/app/tables/ItemField';
import { parseImmutablePropertyString } from '^/utils';

export class ListItem extends PureComponent {

  maybeDetailLink() {
    const { item, fields } = this.props;
    const detailField = fields.find(field => field.get('type') === 'detail');

    // Can't let the row be clickable if there are other event handlers
    // as they will also call the detail link on their onClick
    const hasOtherHandlers = fields.some(field =>
      field.get('type') === 'function' ||
      field.get('type') === 'remove' ||
      field.get('type') === 'live_button'
    );

    return !hasOtherHandlers && detailField && parseImmutablePropertyString(item, detailField.get('route'));
  }

  getAttrs() {
    const detailLink = this.maybeDetailLink();
    if (detailLink) {
      return {
        className: 'clickable',
        onClick: () => this.props.routePush(detailLink),
      };
    }
    return {};
  }

  render() {
    const { item, fields, removeItem } = this.props;

    return (
      <tr {...this.getAttrs()}>
        {fields.map((field, idx) =>
          <td key={idx}>
            <ItemField
              key={idx}
              item={item}
              field={field}
              removeItem={removeItem}
            />
          </td>
         )}
      </tr>
    );
  }
}

ListItem.propTypes = {
  item: ImmutablePropTypes.map.isRequired,
  fields: ImmutablePropTypes.list.isRequired,
  removeItem: React.PropTypes.func,
};

export default connect(null, { routePush: routeActions.push }) (ListItem);
