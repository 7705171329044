import { List, Map } from 'immutable';

export const GROUP_ADMIN = 'Group Admin';
export const GROUP_ADMIN_TYPE = 'GROUP_ADMIN';

export const STAFF_PRACTICE_TYPE = {
  PRACTICE_ADMIN: 'PRACTICE_ADMIN',
  PRACTICE_MANAGER: 'PRACTICE_MANAGER',
  TEAM_MEMBER: 'TEAM_MEMBER',
  READ_ONLY: 'READ_ONLY',
  INSIGHTS_ONLY: 'INSIGHTS_ONLY',
};

const ADMIN_TYPES = List.of(
  STAFF_PRACTICE_TYPE.PRACTICE_ADMIN,
  STAFF_PRACTICE_TYPE.PRACTICE_MANAGER
);

export const STATUS_CHOICES = {
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
  RETIRED: 'RETIRED',
};

export const STAFF_PRACTICE_TYPE_DISPLAY = {
  [STAFF_PRACTICE_TYPE.PRACTICE_ADMIN]: 'iComply Admin',
  [STAFF_PRACTICE_TYPE.PRACTICE_MANAGER]: 'iComply Manager',
  [STAFF_PRACTICE_TYPE.TEAM_MEMBER]: 'Team member',
  [STAFF_PRACTICE_TYPE.READ_ONLY]: 'Read only',
};

export function getStaffPracticeTypes(isPracticeAdmin) {
  const types = Map(STAFF_PRACTICE_TYPE_DISPLAY);
  const availableTypes =
    isPracticeAdmin
      ? types
      : types.filter((_val, key) => key !== STAFF_PRACTICE_TYPE.PRACTICE_ADMIN);
  return availableTypes.toSeq();
}

export function getStaffPracticeTypeDisplay(staffPracticeType, isGroupAdmin) {
  if (isGroupAdmin) {
    return GROUP_ADMIN;
  }
  return STAFF_PRACTICE_TYPE_DISPLAY[staffPracticeType];
}

export function getStaffPracticeType(staffPracticeType, isGroupAdmin) {
  if (isGroupAdmin) {
    return GROUP_ADMIN_TYPE;
  }
  return staffPracticeType;
}

export function isAdmin(staffPractice) {
  if (!staffPractice) { return false; }

  const { is_owner, type } = staffPractice.toObject();
  return is_owner || ADMIN_TYPES.contains(type);
}

function getStaffPracticeForUser(practice, user) {
  return practice && practice
    .get('members')
    .find(staffPractice => user.get('id') === staffPractice.getIn(['user', 'id']));
}

export const isAdminOfPractice = (practice, user) => isAdmin(getStaffPracticeForUser(practice, user));
