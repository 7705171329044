import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';

export const ViewSwitcher = ({isOnGroupAdminPage}) =>
  <div className="view-switcher">
    <Link
      to="/page/dashboard/"
      className={classNames('pill', isOnGroupAdminPage ? '' : 'active')}
    >
      Practices
    </Link>
    <Link
      to="/page/groups/"
      className={classNames('pill', isOnGroupAdminPage ? 'active' : '')}
    >
      Groups
    </Link>
  </div>
;

ViewSwitcher.propTypes = {
  isOnGroupAdminPage: React.PropTypes.bool.isRequired,
};

export default ViewSwitcher;
