
import React, { PropTypes } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import { connect } from 'react-redux';

import { isPending } from '^/consts/responseStates';
import { downloadDigitalRecordFileFromURL } from '^/actions/actionSequences';

import FormError from '^/components/app/forms/FormError';
import Loading from '^/components/app/content/Loading';
import FileUpload from '^/components/app/forms/FileUpload';
import PureComponent from '^/components/common/PureComponent';


function removeFile(onChange, files, file) {
  return onChange(List(files || []).filterNot(each => each.file === file));
}

const File = ({ file, onRemove, downloadFileFromURL }) => {
  if (file.digital_record) {
    return (
      <div>
        <a
          className="inline inline-label"
          onClick={() => downloadFileFromURL(file.file.replace('/api', ''))}
        >
        {file.name || file.filename}
      </a>
        <a className="pull-right inline-edit" onClick={onRemove}>Remove</a>
      </div>
    );
  }

  return (
    <div>
    <a href={file.file} target="_blank" className="inline inline-label" download>
      {file.name || file.filename}
    </a>
      <a className="pull-right inline-edit" onClick={onRemove}>Remove</a>
    </div>
  );
};


const Files = ({ field: { value, onChange, }, downloadFileFromURL }) =>
  <div className="mt-1-2">
    {List(value || []).map(
      each =>
        <div key={each.file_id}>
          <File
            key={each.file_id}
            file={each}
            downloadFileFromURL={downloadFileFromURL}
            onRemove={() => removeFile(onChange, value, each.file)}
          />
          <div className="hint mb-1-2">{each.uploaded_or_created_by_and_date}</div>
        </div>
    )}
  </div>;

function SelectFile ({ field: { name, }, formName, handleSubmit, uploadFile, uploadFileResponse, }) {

  if (isPending(uploadFileResponse)) {
    return <div>Uploading file... <Loading className="inline small" /></div>;
  }

  return (
    <div>
      <FileUpload
        id={name}
        setPendingUpload={(_file) => uploadFile(_file, formName, name)}
        display={() => <noscript />}
        onSubmit={handleSubmit}
      />
      <FormError response={uploadFileResponse} formKey="file" />
      <FormError response={uploadFileResponse} formKeyPath={['errors', 'non_field_errors']} />
    </div>
  );
}


export class MultipleFileUpload extends PureComponent {

  render() {
    const {
      field,
      downloadFileFromURL,
      formName,
      handleSubmit,
      uploadFile,
      uploadFileResponse,
      isArchived,
    } = this.props;

    return (
      <div className="multiple-file-upload">
        {!isArchived &&
          <SelectFile
            field={field}
            formName={formName}
            handleSubmit={handleSubmit}
            uploadFile={uploadFile}
            uploadFileResponse={uploadFileResponse}
          />
        }
        <Files
          field={field}
          downloadFileFromURL={downloadFileFromURL}
        />
      </div>
    )
  }
};

MultipleFileUpload.propTypes = {
  field: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  uploadFileResponse: ImmutablePropTypes.map,
};

export function mapDispatchToProps(dispatch) {
  return {
    downloadFileFromURL: (url) => dispatch(downloadDigitalRecordFileFromURL(url)),
  };
}

export default connect(null, mapDispatchToProps) (MultipleFileUpload);
