import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

const CycleProgressToDateText = ({ progress }) =>
  <div className="x-small-text">
    In this cycle, you have <span className="text-outstanding small-text">
    {progress.get('overdue')} outstanding activities </span>
    from a possible <span className="text-info small-text">{progress.get('total_to_date')} to date</span>.
  </div>;

CycleProgressToDateText.propTypes = {
  progress: ImmutablePropTypes.map.isRequired,
};

export default CycleProgressToDateText;
