import React from 'react';
import { Map, fromJS } from 'immutable';
import { connect } from 'react-redux';

import { getItemOptions } from '^/actions/items';
import { loadHealthcheckQuestionAndRules } from '^/actions/actionSequences';
import PureComponent from '^/components/common/PureComponent';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import AdminManyRelatedField from '^/components/admin/content/perms/AdminManyRelatedField';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import { transformHideRuleIntoChoices, transformRegionHideRuleIntoChoices } from '^/components/admin/healthcheck/utils';

import {
  ADMIN_HEALTHCHECK_QUESTION_CREATE,
  ADMIN_HEALTHCHECK_QUESTION_MODEL,
  ADMIN_HEALTHCHECK_QUESTION_OPTIONS,
  ADMIN_HEALTHCHECK_QUESTION_EDIT_FIELDS,
  ADMIN_HEALTHCHECK_QUESTION_DOCUMENT_ROW_FIELDS,
  HEALTHCHECK_QUESTION_HIDE_RULE,
  ADMIN_HEALTHCHECK_QUESTION_HIDE_RULES_MODEL,
  ADMIN_HEALTHCHECK_QUESTION_HIDE_RULE_ROW_FIELDS,
  ADMIN_HEALTHCHECK_QUESTION_HIDE_RULE_CREATE_FIELDS,
  HEALTHCHECK_QUESTION_REGION_HIDE_RULE,
  ADMIN_HEALTHCHECK_QUESTION_REGION_HIDE_RULES_MODEL,
  ADMIN_HEALTHCHECK_QUESTION_REGION_HIDE_RULE_ROW_FIELDS,
  ADMIN_HEALTHCHECK_QUESTION_REGION_HIDE_RULE_CREATE_FIELDS
} from '^/components/admin/healthcheck/healthcheck';
import {PAGE_SIZE} from '^/components/admin/healthcheck/constants';


export class EditHealthcheckQuestion extends PureComponent {
  componentDidMount() {
    const { healthcheckQuestionId, itemOptions } = this.props;
    if (itemOptions.isEmpty()) {
      this.getItemOptions(healthcheckQuestionId);
    }
  }

  getItemOptions(healthcheckQuestionId) {
    this.props.getItemOptions(ADMIN_HEALTHCHECK_QUESTION_MODEL, healthcheckQuestionId, ADMIN_HEALTHCHECK_QUESTION_OPTIONS);
  }

  render() {
    const {
      healthcheckQuestionId,
      healthcheckQuestion,
      itemOptions,
      loadRules,
      healthcheckQuestionFilter
    } = this.props;

    return (
      <div>
        <AdminContainer title="Healthcheck Question">
          <AdminEdit
            readOnlyFields={fromJS([])}
            itemId={healthcheckQuestionId}
            controlName={ADMIN_HEALTHCHECK_QUESTION_CREATE}
            collectionName={ADMIN_HEALTHCHECK_QUESTION_MODEL}
            model={ADMIN_HEALTHCHECK_QUESTION_MODEL}
            fields={ADMIN_HEALTHCHECK_QUESTION_EDIT_FIELDS}
            title="Edit Healthcheck Question"
          />
        </AdminContainer>

        <AdminContainer className="mt-2 small-text">
          <AdminManyRelatedField
            addLabel="Assign template document to healthcheck question"
            itemId={healthcheckQuestionId}
            controlName="HEALTHCHECK_QUESTION_DOCUMENT"
            options={itemOptions}
            model={ADMIN_HEALTHCHECK_QUESTION_MODEL}
            fieldName={['action_template_documents']}
            fields={ADMIN_HEALTHCHECK_QUESTION_DOCUMENT_ROW_FIELDS}
            listTitle="Template Documents"
            sortBy={['name']}
          />
        </AdminContainer>

        <AdminContainer title="Hide Rules">
          <HelpBlock>
            <p className="small-text">
              This Question will be hidden for a practice if ANY of the
              rules added below match the value set for a particular
              attribute on that practice.
            </p>
          </HelpBlock>

          <AdminList
            noSearch
            hidePagination
            pageSize={PAGE_SIZE}
            onRemove={() => loadRules(healthcheckQuestionId, healthcheckQuestionFilter)}
            listName={HEALTHCHECK_QUESTION_HIDE_RULE}
            model={ADMIN_HEALTHCHECK_QUESTION_HIDE_RULES_MODEL}
            fields={ADMIN_HEALTHCHECK_QUESTION_HIDE_RULE_ROW_FIELDS}
            defaultFilters={Map(healthcheckQuestionFilter)}
          />
          <AdminCreate
            collectionName={HEALTHCHECK_QUESTION_HIDE_RULE}
            onComplete={() => loadRules(healthcheckQuestionId, healthcheckQuestionFilter)}
            controlName={HEALTHCHECK_QUESTION_HIDE_RULE}
            transform={config => transformHideRuleIntoChoices(healthcheckQuestion, config)}
            model={ADMIN_HEALTHCHECK_QUESTION_HIDE_RULES_MODEL}
            fields={ADMIN_HEALTHCHECK_QUESTION_HIDE_RULE_CREATE_FIELDS}
            defaultValues={healthcheckQuestionFilter}
            title="Add Rule"
          />
        </AdminContainer>

        <AdminContainer title="Region Hide Rules">
          <HelpBlock>
            <p className="small-text">
              This Question will be hidden for a practice if it is
              in ANY of the regions added below.
            </p>
          </HelpBlock>

          <AdminList
            noSearch
            hidePagination
            onRemove={() => loadRules(healthcheckQuestionId, healthcheckQuestionFilter)}
            listName={HEALTHCHECK_QUESTION_REGION_HIDE_RULE}
            pageSize={PAGE_SIZE}
            model={ADMIN_HEALTHCHECK_QUESTION_REGION_HIDE_RULES_MODEL}
            defaultFilters={Map(healthcheckQuestionFilter)}
            fields={ADMIN_HEALTHCHECK_QUESTION_REGION_HIDE_RULE_ROW_FIELDS}
          />
          <AdminCreate
            collectionName={HEALTHCHECK_QUESTION_REGION_HIDE_RULE}
            controlName={HEALTHCHECK_QUESTION_REGION_HIDE_RULE}
            onComplete={() => loadRules(healthcheckQuestionId, healthcheckQuestionFilter)}
            transform={config => transformRegionHideRuleIntoChoices(healthcheckQuestion, config)}
            model={ADMIN_HEALTHCHECK_QUESTION_REGION_HIDE_RULES_MODEL}
            fields={ADMIN_HEALTHCHECK_QUESTION_REGION_HIDE_RULE_CREATE_FIELDS}
            defaultValues={healthcheckQuestionFilter}
            title="Add Rule"
          />
        </AdminContainer>

      </div>
    );
  }
}


export function mapStateToProps(state, props) {
  const healthcheckQuestionId =  props.params.uuid
  return {
    healthcheckQuestionId,
    itemOptions: state.items.getIn(['options', ADMIN_HEALTHCHECK_QUESTION_OPTIONS], Map()),
    healthcheckQuestion: state.items.get(ADMIN_HEALTHCHECK_QUESTION_MODEL),
    healthcheckQuestionFilter: { healthcheck_question: healthcheckQuestionId }
  };
}


export default connect(
  mapStateToProps,
  {
    getItemOptions,
    loadRules: loadHealthcheckQuestionAndRules,
  }
) (EditHealthcheckQuestion);
