import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { MODELS } from '^/permissions/capabilities';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';
import AdminChangeLog, { reloadChangeLog } from '^/components/admin/content/change-log/AdminChangeLog';

import {
  ADMIN_NEWS_CREATE,
  ADMIN_NEWS_MODEL,
  ADMIN_NEWS_REGION_MODEL,
  ADMIN_NEWS_EDIT_FIELDS,
  ADMIN_NEWS_VIEW_FIELDS,
  ADMIN_NEWS_REGION_FIELDS,
  ADMIN_NEWS_REGION_CREATE_FIELDS,
  NEWS_REGIONS,
} from './news';


export const EditNews = function ({ newsStoryId }) {
  return (
    <div>
      <AdminContainer title="News">
        <AdminEdit
          permsModel={MODELS.NEWS}
          itemId={newsStoryId}
          controlName={ADMIN_NEWS_CREATE}
          collectionName={ADMIN_NEWS_CREATE}
          model={ADMIN_NEWS_MODEL}
          onComplete={reloadChangeLog('NewsStory', newsStoryId)}
          fields={ADMIN_NEWS_EDIT_FIELDS}
          transform={fieldConfig => fieldConfig.setIn(['content', 'kind'], 'news').setIn(['sector', 'read_only'], true)}
          readOnlyFields={ADMIN_NEWS_VIEW_FIELDS}
          title="Edit News"
        />
      </AdminContainer>

      <AdminContainer className="mt-2" title="Region(s)">
        <AdminList
          permsModel={MODELS.NEWS}
          noItemsMessage="If not tagged to any region, will appear in all."
          noSearch
          hidePagination
          pageSize={100}
          listName={NEWS_REGIONS}
          model={ADMIN_NEWS_REGION_MODEL}
          fields={ADMIN_NEWS_REGION_FIELDS}
          defaultFilters={Map({ news_item: newsStoryId })}
        />

        <AdminCreate
          permsModel={MODELS.NEWS}
          collectionName={NEWS_REGIONS}
          controlName={NEWS_REGIONS}
          model={ADMIN_NEWS_REGION_MODEL}
          onComplete={reloadChangeLog('NewsStory', newsStoryId)}
          fields={ADMIN_NEWS_REGION_CREATE_FIELDS}
          defaultValues={{ news_item: newsStoryId }}
          title="Assign to region"
        />
      </AdminContainer>
      <AdminContainer>
        <AdminChangeLog model="NewsStory" id={newsStoryId} />
      </AdminContainer>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    newsStoryId: props.params.uuid,
  };
}

export default connect(mapStateToProps)(EditNews);
