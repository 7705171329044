import React from 'react';
import { connect } from 'react-redux';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import { isPending, hasFailed } from '^/consts/responseStates';
import { loadItem, clearItem } from '^/actions/items';
import { TEMPLATE_DOCUMENT_ACTUAL_VERSION } from '^/consts/collectionKeys';
import DocumentEditor from './DocumentEditor';
import DemoModeMayDisallow from '^/components/app/perms/DemoModeMayDisallow';
import BackButton from '^/components/app/nav/back-button';

export class TemplateDocumentVersionPage extends PureComponent {
  componentWillMount() {
    this.props.loadTemplateVersion();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.params &&
      prevProps.params &&
      this.props.params.uuid !== prevProps.params.uuid
    ) {
      this.props.loadTemplateVersion();
    }
  }

  componentWillUnmount() {
    this.props.resetItem();
  }

  renderDocument() {
    const { templateDocumentVersion, response } = this.props;

    if (hasFailed(response)) {
      return null;
    }

    if (isPending(response) || !templateDocumentVersion) {
      return <Loading />;
    }

    return (
      <div className="wrapper">
        <BackButton />

        <DocumentEditor
          {...this.props}
          doc={templateDocumentVersion}
          readOnly
        />
      </div>
    );
  }

  render() {
    const { response } = this.props;

    return (
      <DemoModeMayDisallow
        message="cannot see documents"
        response={response}
        goBack
      >
        {this.renderDocument()}
      </DemoModeMayDisallow>
    );
  }
}

export function mapStateToProps(state) {
  const templateDocumentVersion = state.items.get(
    TEMPLATE_DOCUMENT_ACTUAL_VERSION
  );

  return {
    templateDocumentVersion,
    response: state.responses.getIn([
      'loadItem',
      TEMPLATE_DOCUMENT_ACTUAL_VERSION,
    ]),
    currentPracticeId: state.currentPractice && state.currentPractice.get('id'),
    currentPracticeGroupId:
      state.currentPractice && state.currentPractice.getIn(['group', 'id']),
    currentPathname: state.routing.location.pathname,
  };
}

export function mapDispatchToProps(dispatch, props) {
  return {
    loadTemplateVersion: () =>
      dispatch(loadItem(TEMPLATE_DOCUMENT_ACTUAL_VERSION, props.params.uuid)),
    resetItem: () => dispatch(clearItem(TEMPLATE_DOCUMENT_ACTUAL_VERSION)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateDocumentVersionPage);
