import React from 'react';
import { Link } from 'react-router';

import { formatDate } from '^/utils';

const NewsStory = ({ newsStory, isSelected, isGroupNewsTriagePage }) => {
  const { id, title, image, created } = newsStory.toObject();

  const storyLinkTemplateString = isGroupNewsTriagePage
    ? `/page/groups/news/${id}/`
    : `/page/news/${id}/`;

  return (
    <li className={isSelected && 'selected'}>
      <Link className="list-link" to={storyLinkTemplateString}>
        {image && <img className="news-image" src={image} />}
        <div className="preview-text">
          <p>{title}</p>
          <p className="lighten-text x-small-text">{formatDate(created)}</p>
        </div>
      </Link>
    </li>
  );
};

const NewsStoryPreviews = ({
  newsStories,
  selectedNewsStory,
  isGroupNewsTriagePage,
}) => (
  <ul className="list-no-indent news-list">
    {newsStories.map((newsStory, idx) => (
      <NewsStory
        key={idx}
        newsStory={newsStory}
        isSelected={newsStory === selectedNewsStory}
        isGroupNewsTriagePage={isGroupNewsTriagePage}
      />
    ))}
  </ul>
);
export default NewsStoryPreviews;
