import React from 'react';
import ScrollableTableContainer from '^/components/common/content/ScrollableTableContainer';
import StatusDot, { getStatusColor, getStatusMessage, getTooltipType,
  COMPLETED, IN_PROGRESS, OVERDUE } from '^/components/app/widgets/StatusDot';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { fromJS, Range } from 'immutable';

export const AllPracticesKey = ({colCount}) =>
  <tr className="all-practices-activities-stats-key">
    <td />
    <td colSpan={colCount - 1} className="text-center">
      <span className="key-item"><StatusDot statusColor={COMPLETED} /> Completed</span>
      <span className="key-item"><StatusDot statusColor={IN_PROGRESS} /> In progress</span>
      <span className="key-item"><StatusDot statusColor={OVERDUE} /> Outstanding</span>
    </td>
  </tr>;

export const AllPracticesRow = ({practice, switchPractice, isSelected}) =>
  <tr className={isSelected && 'activities-stats-row-selected'}>
    <td>
      <a onClick={switchPractice}>
        {practice.get('name')}
      </a>
    </td>
    {practice.get('activity_stats').map((month, idx) => {
      if (month === null) {
        return <td key={idx} />;
      }
      const { overdue, complete, in_progress, total } = month.toObject();
      return (
        <td key={idx} className="text-center">
          <StatusDot
            statusColor={getStatusColor(overdue, complete, in_progress, total)}
            statusMessage={getStatusMessage(overdue, complete, in_progress, total)}
            tooltipType={getTooltipType(overdue, complete, in_progress, total)}
          />
        </td>
      );
    })}
  </tr>;

export const AllPracticesHeaderRow = ({months}) =>
  <tr className="all-practices-activities-stats-header">
    <th />
    {months.map((month, idx) => <th key={idx} >{month}</th>)}
  </tr>;

function sortByDate(practices) {
  return practices.sortBy(practice => moment(practice.get('cycle_start')).unix());
}

function getMonths(date) {
  const m = moment(date);
  return (m.year() * 12) + m.month();
}

function pad(practices, earliestMonths, latestMonths) {
  return practices.map(function (data) {
    const months = getMonths(data.get('cycle_start'));
    const preFilling = new Array(months - earliestMonths).fill(null);
    const postFilling = new Array(latestMonths - months).fill(null);
    return data.set('activity_stats',
      fromJS(preFilling).concat(fromJS(data.get('activity_stats')).concat(postFilling))
    );
  });
}

function getLongestRange(practices) {
  return practices.max(practice => practice.get('activity_stats').size).get('activity_stats').size;
}

export const ActivityStatsAllPractices = ({stats, switchPractice, currentPracticeId}) => {
  const data = stats && stats.get('practice_activity_data');

  if (!data) {
    return (
      <p>No data found.</p>
    );
  }

  const sorted = sortByDate(data);
  const earliestDate = getMonths(sorted.first().get('cycle_start'));
  const padded = pad(data, earliestDate, getMonths(sorted.last().get('cycle_start')));
  const months = Range(earliestDate, earliestDate + getLongestRange(padded)).map(function (i) {
    return moment().month((i + 12) % 12).format('MMM');
  });

  return (
    <div className="stats-graph-wrapper all-practices-activity-stats">
      <ScrollableTableContainer>
        <ReactTooltip effect="solid" />
        <table className="activity-stats-all-practices">
          <thead>
            <AllPracticesKey colCount={months && months.count()} />
            <AllPracticesHeaderRow months={months} />
          </thead>
          <tbody>
            {padded.map((practice, id) =>
              <AllPracticesRow
                key={id}
                practice={practice}
                isSelected={currentPracticeId === id}
                switchPractice={() => switchPractice(id)}
              />
            )}
            <AllPracticesHeaderRow months={months} />
          </tbody>
        </table>
      </ScrollableTableContainer>
    </div>
  );
};

export default ActivityStatsAllPractices;
