/* global process */
import React from 'react';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';

import PureComponent from '^/components/common/PureComponent';


export class AdminHome extends PureComponent {
  componentWillMount() {
    if (process.env.TEMPLATES_ONLY === 'true') {
      this.props.routePush('/admin/template-documents');
    }
  }

  render() {
    return (
      <div className="mt-2">
        <h3>Welcome! This is the admin panel for iComply</h3>
      </div>
    );
  }
}

export default connect(undefined, { routePush: routeActions.push })(AdminHome);
