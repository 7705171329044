import React from 'react';
import { Editor as TinyMCE } from '@tinymce/tinymce-react';

import { getConfig } from '^/consts/tinyMCE';

export const ReadOnlyEditor = ({content, isLandscape, height}) => (
  <div className="mb-1">
    <TinyMCE
      id="topEditor"
      initialValue={content}
      init={Object.assign({}, getConfig(isLandscape), {
        height: height, menu: {}, toolbar: false,
        removed_menuitems: '', plugins: '', selector: 'textarea#topEditor',
        init_instance_callback: ed => ed.getBody().removeAttribute('contenteditable'),
      })}
    />
  </div>
);

ReadOnlyEditor.defaultProps = {
  height: 300
};

export default ReadOnlyEditor;
