import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';


export const MemberFilter = ({ onChange, choices, value, className }) =>
    <span className={className}>
      <select
        value={value || choices.first()}
        onChange={evt => evt.target.value && onChange(evt.target.value)}
        id="practice-filter-select"
        className="form-control-margin"
      >
        {choices.map(choice =>
          <option key={choice.get('value')} value={choice.get('value')}>
            {choice.get('display')}
          </option>
          )}
      </select>
    </span>;

MemberFilter.propTypes = {
  onChange: React.PropTypes.func.isRequired,
  choices: ImmutablePropTypes.list.isRequired,
};

export default MemberFilter;
