import React from 'react';
import { reduxForm } from 'redux-form';

import { isOptional, isCompulsory } from '^/models/compliancereviews';
import LiveButton from '^/components/app/forms/LiveButton';
import {
  validateData, getAuditFields
} from '^/components/app/account-set-up/SecurityAuditsSetupForm';
import { 
  OptionalTemplateSecurityAuditOrAssessmentFields,
  CompulsoryTemplateSecurityAuditOrAssessmentFields
} from '^/components/app/account-set-up/SecurityAuditsRiskAssessmentsCommonFormComponents';

export const FORM_NAME = 'setupCycleOptionalAudits';

export const CycleSetupOptionalAuditsForm =
  ({fields, handleSubmit, response, compulsoryAudits, optionalAudits, cycleStartDate}) =>
  <form onSubmit={handleSubmit}>
    <hr className="thin" />

    <CompulsoryTemplateSecurityAuditOrAssessmentFields
      fields={fields}
      response={response}
      auditsOrAssessments={compulsoryAudits}
      cycleStartDate={cycleStartDate}
      isReview
      isAudit
    />

    <OptionalTemplateSecurityAuditOrAssessmentFields
      fields={fields}
      response={response}
      auditsOrAssessments={optionalAudits}
      cycleStartDate={cycleStartDate}
      isAudit
    />

    <hr className="thin" />

    <LiveButton pendingMessage="Saving..." response={response}>Next...</LiveButton>
  </form>;

export const mapStateToProps = (state, { templateAudits, cycle }) => {
  const compulsoryAudits = templateAudits.filter(isCompulsory);
  const optionalAudits = templateAudits.filter(isOptional);
  const { start_date } = cycle.toObject();
  return {
    fields: getAuditFields(templateAudits),
    validate: (data) => validateData(data, templateAudits, start_date),
    compulsoryAudits,
    optionalAudits,
    cycleStartDate: start_date
  };
};

export default reduxForm({ form: FORM_NAME, }, mapStateToProps)(CycleSetupOptionalAuditsForm);
