import React from 'react';
import { connect } from 'react-redux';

import { publishAsCurrentVersionAndReloadTemplateVersion } from '^/actions/actionSequences';
import { closeModal } from '^/actions/modals';
import { ADMIN_TEMPLATE_DOCUMENT_VERSION } from '^/consts/collectionKeys';

import PublishDocumentVersionForm from './PublishDocumentVersionForm';


export function PublishAsCurrentVersionModal({
  response, templateVersion, publish, onCancel, data
}) {
  const message = `Are you sure you want to publish this to the live version of ${
    templateVersion.get('template_document_display')} (Version ${templateVersion.get('version_number')})?`;

  return (
    <div className="p-1">
      <PublishDocumentVersionForm
        response={response}
        onSubmit={formData => publish(templateVersion.get('id'), Object.assign({}, data, formData))}
        initialValues={{notes: '', suppress_alert: true}}
        onCancel={onCancel}
        message={message}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    response: state.responses.getIn(['updateItem', ADMIN_TEMPLATE_DOCUMENT_VERSION])
  };
}

export default connect(
  mapStateToProps,
  { publish: publishAsCurrentVersionAndReloadTemplateVersion, onCancel: closeModal }
) (PublishAsCurrentVersionModal);
