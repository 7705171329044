import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import AdminFormError from '^/components/admin/content/edit-create/dynamic-forms/AdminFormError';
import PureComponent from '^/components/common/PureComponent';


export default class FieldMultiSelect extends PureComponent {
  onChange(value) {
    this.props.onChange(value.map(each => each.value));
  }

  getChoiceLabel(choice) {
    return choice.display_name;
  }

  render() {
    const { readOnly, config, value, className, name, errors } = this.props;

    return readOnly ? (
      <div className={classNames('mb-1-4', className)}>
        <label>{config.label}</label>
        <span>{value}</span>
      </div>
    ) : (
      <div className={classNames('mb-1-4', className)}>
        <div>
          <label>{config.label}:</label>
          <Select
            multi
            name={name}
            getOptionLabel={this.getChoiceLabel}
            value={value ? value : ''}
            placeholder={config.placeholder}
            onChange={(e) => this.onChange(e)}
            options={config.choices.map(choice => {
              return { value: choice.value, label: choice.display_name };
            })}
          />
        </div>
        <AdminFormError errors={errors} />
      </div>
    );
  }
}
