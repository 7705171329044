import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import { upgradeDemoAndCloseModal } from '^/actions/actionSequences';
import { isPending } from '^/consts/responseStates';

import Loading from '^/components/app/content/Loading';
import LiveButton from '^/components/app/forms/LiveButton';


const ConfirmUpgradeDemoModal = props => (
  <div className="mb-1">
    <p>Your demo data will be deleted and you will continue to set up your practice.</p>

    <button
      className="btn btn-default mt-1 mr-1"
      onClick={props.closeModal}
    >
      Cancel
    </button>

    <LiveButton
      className="btn btn-primary mt-1"
      onClick={props.upgradeDemoAndCloseModal}
      response={props.response}
      pendingMessage="Upgrading demo..."
    >
      Confirm
    </LiveButton>

    {isPending(props.response) && <Loading />}
  </div>
);

function mapStateToProps(state) {
  return {
    response: state.responses.get('upgradeDemo'),
  };
}

export default connect(mapStateToProps, { upgradeDemoAndCloseModal, closeModal }) (ConfirmUpgradeDemoModal);
