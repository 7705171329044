import { fromJS } from 'immutable';

export const RESPONSIBILITIES = 'RESPONSIBILITIES';
export const ACTIVITIES = 'ACTIVITIES';
export const TASKS = 'TASKS';
export const PRIVATE_DOCUMENTS = 'PRIVATE_DOCUMENTS';
const CONFIRM = 'CONFIRM';

export const REASSIGN_STEPS = 'REASSIGN_STEPS';
export const REASSIGN_MEMBER = 'REASSIGN_MEMBER';

export const USER_REASSIGN_STEPS = fromJS([
  RESPONSIBILITIES,
  ACTIVITIES,
  TASKS,
  PRIVATE_DOCUMENTS,
  CONFIRM,
]);

export const STEP_TEXT = fromJS({
  [USER_REASSIGN_STEPS.get(0)]: 'Reassign Responsibilities',
  [USER_REASSIGN_STEPS.get(1)]: 'Reassign Activities',
  [USER_REASSIGN_STEPS.get(2)]: 'Reassign Tasks',
  [USER_REASSIGN_STEPS.get(3)]: 'Reassign Private Documents',
  [USER_REASSIGN_STEPS.get(4)]: 'Confirm Reassignment',
});
