import React from 'react';
import { reduxForm } from 'redux-form';
import PureComponent from '^/components/common/PureComponent';
import FormError from '^/components/app/forms/FormError';
import Loading from '^/components/app/content/Loading';


export class ExportForm extends PureComponent {
  render() {
    const { fields: { table }, handleSubmit, response, tables, disabled } = this.props;
    return (
      <form onSubmit={handleSubmit} className="admin-edit-create-form">
        <h4>Choose a table to export</h4>
        <FormError response={response} />
        <div className="admin-edit-create-field">
          <label>Table:</label>
          <select {...table} disabled={disabled}>
            <option>--</option>
            { tables.map(tableName => <option value={tableName} key={tableName}>{tableName}</option>) }
          </select>
          <FormError response={response} formKey="table" />
        </div>
        <input className="btn btn-primary btn-admin-form" value="Export" type="submit" disabled={disabled} />
        { disabled && <Loading />}
      </form>
    );
  }
}

export default reduxForm({
  form: 'tableExport',
  fields: ['table']
})(ExportForm);
