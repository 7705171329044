import { fromJS, Map } from 'immutable';
import {
  addCloseButtonAfterPolling,
  removeCloseButtonWhilePolling,
} from '^/actions/modals';
import * as actions from '^/actions/actions';
import { pollComplete, pollInProgress } from '^/models/export';
import { getCollection } from '^/actions/collections';
import settings from '^/consts/settings';
import { DOCUMENT_VERSIONS_TO_READ } from '^/consts/collectionKeys';


function pollBulkDocSendReadRequestAgainAfterTimeout(store, bulkDocSendReadRequestJobId) {
  return store.dispatch(
    actions.storeBulkDocReadRequestPollTimeout(
      bulkDocSendReadRequestJobId,
      window.setTimeout(
        () => store.dispatch(actions.pollBulkDocSendReadRequest(bulkDocSendReadRequestJobId)),
        settings.POLL_INTERVAL
      )
    )
  );
}

export default store => next => action => {

  const state = store.getState();
  const docVersionToReadCollection = state.collections.get(DOCUMENT_VERSIONS_TO_READ);
  const docVersionToReadCollectionFilters = docVersionToReadCollection ? Map(docVersionToReadCollection.get('filters')) : Map();

  switch (action.type) {
    case actions.POLL_BULK_SEND_READ_REQUEST.REQUEST:
      store.dispatch(removeCloseButtonWhilePolling());
      break;
    case actions.POLL_BULK_SEND_READ_REQUEST.SUCCESS: {
      const immutableAction = fromJS(action);
      const bulkDocSendReadRequestJobId = immutableAction.getIn(['meta', 'source']);
      if (pollInProgress(immutableAction)) {
        pollBulkDocSendReadRequestAgainAfterTimeout(store, bulkDocSendReadRequestJobId);
      } else if (pollComplete(immutableAction)) {
        store.dispatch(getCollection(DOCUMENT_VERSIONS_TO_READ, {filters: docVersionToReadCollectionFilters.toJS()}));
        store.dispatch(actions.clearAllExportTimeouts());
        store.dispatch(addCloseButtonAfterPolling());
      }
      break;
    }
  }

  return next(action);
};
