import React from 'react';

import { MODELS } from '^/permissions/capabilities';
import {
  ADMIN_QMR_REPORT_MODEL,
  ADMIN_QMR_REPORT_EDIT_FIELDS
} from '^/components/admin/qmr-reports/qmr-reports';

import PureComponent from '^/components/common/PureComponent';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminChangeLog from '^/components/admin/content/change-log/AdminChangeLog';
import ManageQMRReportCreate from '^/components/admin/qmr-reports/ManageQMRReportCreate';


class ManageQMRReport extends PureComponent {

  render() {
    const { params: { uuid } } = this.props;

    return (
      <div>
        <AdminContainer title="Edit QMR Report">
          <ManageQMRReportCreate
            permsModel={MODELS.QMR_REPORT}
            itemId={uuid}
            collectionName={ADMIN_QMR_REPORT_MODEL}
            model={ADMIN_QMR_REPORT_MODEL}
            fields={ADMIN_QMR_REPORT_EDIT_FIELDS}
          />
        </AdminContainer>
        <AdminChangeLog model="QMRReport" id={uuid} />
      </div>
    );
  }
}

export default ManageQMRReport;
