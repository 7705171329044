import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { getAllCollection } from '^/actions/collections';
import { COMPLIANCE_SYSTEMS, CYCLES, PRACTICES } from '^/consts/collectionKeys';
import PureComponent from '^/components/common/PureComponent';


export class ReportsFilter extends PureComponent {
  componentWillMount() {
    this.props.getAllCollection(COMPLIANCE_SYSTEMS);
    this.props.getAllCollection(CYCLES);

    this.setFilterCurrentPractice();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentPracticeId !== this.props.currentPracticeId) {
      this.setFilterCurrentPractice();
    }
  }

  setFilterCurrentPractice() {
    const { applyFilter, currentPracticeId } = this.props;
    applyFilter({practice: currentPracticeId});
  }

  render() {
    const { filter, searchText, applyFilter, applySearch, practices, complianceSystems, cycles } = this.props;
    const selectedPracticeId = filter && filter.get('practice');
    const practiceCycles = selectedPracticeId && cycles && cycles.filter(
      cycle => cycle.get('practice') === selectedPracticeId
    );

    return (
      <div>
        <div className="row">
          <div className="col-1-4">
            <div className="form-group">
              <label htmlFor="practices">Practice</label>
              <select
                onChange={evt => applyFilter({practice: evt.target.value})}
                disabled={!practices}
                id="practices"
                className="form-select"
                value={selectedPracticeId}
              >
                {practices && practices.map(_practice =>
                  <option key={_practice.get('id')} value={_practice.get('id')}>
                    {_practice.get('name')}
                  </option>
                )}
              </select>
            </div>
          </div>

          <div className="col-1-4">
            <div className="form-group">
              <label htmlFor="cycle">Cycle</label>
              <select
                onChange={evt => applyFilter({cycle: evt.target.value})}
                disabled={!practiceCycles}
                id="cycle"
                className="form-select"
              >
                {!filter.get('cycle') && <option>--</option>}
                {practiceCycles && practiceCycles.map(cycle =>
                  <option key={cycle.get('id')} value={cycle.get('id')}>
                    {moment(cycle.get('start_date')).format('MMM YYYY')
                    } &ndash; {
                    moment(cycle.get('end_date')).format('MMM YYYY')}
                  </option>
                )}
              </select>
            </div>
          </div>

          <div className="col-1-4">
            <div className="form-group">
              <label htmlFor="system">Compliance system</label>
              <select
                onChange={evt => applyFilter({cqc_system: evt.target.value})}
                disabled={!complianceSystems}
                className="form-select"
                id="system"
              >
                {!filter.get('cqc_system') && <option>--</option>}
                {complianceSystems && complianceSystems.map(complianceSystem =>
                  <option key={complianceSystem.get('id')} value={complianceSystem.get('id')}>
                    {complianceSystem.get('name')}
                  </option>
                )}
              </select>
            </div>
          </div>

          <div className="col-1-4">
            <div className="form-group">
              <label htmlFor="search">Search</label>
              <input
                disabled={!filter || !filter.get('cycle') || !filter.get('cqc_system')}
                id="search" type="text" value={searchText} placeholder="Search by title"
                onChange={evt => applySearch(evt.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    cycles: state.collections.getIn([CYCLES, 'items']),
    complianceSystems: state.collections.getIn([COMPLIANCE_SYSTEMS, 'items']),
    practices: state.collections.getIn([PRACTICES, 'items']),
    currentPracticeId: state.currentPractice && state.currentPractice.get('id'),
  };
}

export default connect(mapStateToProps, { getAllCollection }) (ReportsFilter);
