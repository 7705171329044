import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { List } from 'immutable';
import { pluralize, formatDate, getAppSectionPathPrefix, isGroupsAppSection } from '^/utils';

import { isPending } from '^/consts/responseStates';
import {
  destructureTemplateVersion,
  getVersionNumber,
  isGroupCreatedDocument,
  getDocumentLink,
} from '^/models/documents';
import { isTotalHRMember } from '^/models/practice';
import { isAdminOfPractice } from '^/models/staffPractice';
import {
  getCollection, clearCollection
} from '^/actions/collections';
import {
  DOCUMENTS,
  GROUP_TEMPLATE_DOCUMENT_FOLDERS,
  GROUP_TEMPLATE_DOCUMENTS,
} from '^/consts/collectionKeys';
import { openDocumentFolderExportModal, openDocumentTakeOverModal, openDeleteFolderConfirmationModal } from '^/actions/modals';
import { MaybeDocumentEditLink } from '^/components/app/perms/DocumentEditingMayDisallow';
import EditableFieldText from '^/components/app/widgets/EditableFieldText';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import LiveButton from '^/components/app/forms/LiveButton';
import { TEMPLATE_VIEW_BY_PROPERTIES, TEMPLATE_VIEW_BY } from '^/consts/templateViewBy';
import UpdateToIfLaterVersion from './UpdateToIfLaterVersion';
import { isLoggedOnUserAdminOfCurrentGroup, practiceHasAccessToDocumentReviews } from '^/stateHelpers';
import TemplateDocumentTypeIcon from './TemplateDocumentTypeIcon';
import Icon from '^/components/app/content/Icon';
import { excludeTemplateDocumentByGroup } from '^/actions/groupDocuments';
import { setRetainLoadedDocuments } from '^/actions/actions';
import {
  PRIORITY_CLASSES,
  PRIORITY_NAMES,
} from '^/models/constants';


const collectionFor = props => props.groupDocuments ? GROUP_TEMPLATE_DOCUMENTS : DOCUMENTS;

export class TemplatesListing extends PureComponent {

  clearRetainLoadedDocuments() {
    this.props.setRetainLoadedDocuments(false);
  }

  componentWillMount() {
    const { showingSearch, search, selectedViewByValue, retainLoadedDocuments } = this.props;

    if(retainLoadedDocuments) {
      this.clearRetainLoadedDocuments();
      return;
    }

    if ((showingSearch && search) || (!showingSearch && selectedViewByValue)) {
      this.loadTemplates();
    }
    else {
      this.clearTemplates();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.groupDocuments !== this.props.groupDocuments) {
      this.loadTemplates(nextProps);
    }

    if (nextProps.showingSearch) {
      const shouldClearCollection = !this.props.showingSearch || !nextProps.search;
      const searchChanged = nextProps.search && nextProps.search !== this.props.search;

      if (shouldClearCollection) {
        this.clearTemplates();
      }
      else if (searchChanged) {
        this.loadTemplates(nextProps);
      }
    }
    else {
      const viewByValueChanged = nextProps.selectedViewByValue &&
        !nextProps.selectedViewByValue.equals(this.props.selectedViewByValue);
      const switchingToViewByView = !nextProps.showingSearch && this.props.showingSearch;

      if (viewByValueChanged || switchingToViewByView) {
        this.loadTemplates(nextProps);
      }
    }
  }

  clearTemplates(props) {
    this.props.clearCollection(collectionFor(props || this.props));
  }

  loadTemplates(props = this.props, opts) {
    const { showingSearch, search, viewBy, selectedViewByValue } = props;
    const filters = showingSearch
      ? {search}
      : {[TEMPLATE_VIEW_BY_PROPERTIES[viewBy]]: selectedViewByValue.get('id')};

    this.props.getCollection(collectionFor(props), Object.assign({ filters: filters, }, opts));
  }

  loadMoreTemplates() {
    const { templateCollection } = this.props;
    this.loadTemplates(this.props, {page: templateCollection.get('page') + 1, shouldAppend: true});
  }

  renderTemplateRow(templateVersion) {
    const {
      groupDocuments,
      userId,
      userIsAdmin,
      appSectionPrefix,
      isGroupsApp,
      hasAccessToDocumentReviews,
      excludeTemplateDocumentItem,
      excludeTemplateDocumentItemResponse,
    } = this.props;
    const {
      documentVersion,
      docPagePath,
      isAdopted,
      isCopied,
      templateDocument,
      adoptedDocument,
      copiedDocument,
      groupDocument,
      adoptedGroupDocument
    } = destructureTemplateVersion(templateVersion, groupDocuments, appSectionPrefix);

    const versionNumber = (isCopied || (isAdopted && !groupDocuments)) ? (
      getVersionNumber(templateDocument.get('kind'), documentVersion)
    ) : (
      templateVersion.get('version_number')
    );

    const groupOrAdoptedDoc = groupDocument || adoptedDocument
    const isComparisonEditor = templateVersion.get('working_version') && templateVersion.getIn(['working_version', 'is_comparison_editor']);

    const tempalateEditLink = `${docPagePath}${groupDocuments && !isGroupsApp ? 'group/' : ''}${
      (adoptedDocument || groupDocument || templateDocument).get('id')
    }/`

    const navLink = isComparisonEditor && groupOrAdoptedDoc ? `${getDocumentLink(groupOrAdoptedDoc.get('id'))}update/` : tempalateEditLink

    const isGroupsAppOrGroupDocuments = isGroupsApp || this.props.groupDocuments;

    const showTemplateDocument = excludeTemplateDocumentItem && excludeTemplateDocumentItem.get('templateDocument') === templateDocument.get('id') ?
      excludeTemplateDocumentItem.get('isExcluded') :
      templateDocument.get('is_excluded');

    const showLoadingSpinner = excludeTemplateDocumentItemResponse &&
      excludeTemplateDocumentItemResponse.getIn(['meta', 'templateDocumentId']) === templateDocument.get('id') &&
      isPending(excludeTemplateDocumentItemResponse);

    const reviewedByDate = adoptedDocument && 
      adoptedDocument.get('document_review') &&
      adoptedDocument.getIn(['document_review', 'scheduled_completion_date']);

    const excludeTemplateDocument = (event) => {
      const submitData = {
        template_document: templateDocument.get('id'),
        is_excluded: !event.target.checked
      };
      this.props.excludeTemplateDocumentByGroup(submitData);
    }

    const templateDocumentPriority = templateDocument.get('priority');

    return (
      <tr key={templateVersion.get('id')} className="row">
        <td className="nowrap" data-title="Code">
          {templateDocument.get('code')}
        </td>
        <td>
          <TemplateDocumentTypeIcon templateDocument={templateDocument} />
          {showTemplateDocument ?
            <span className='lighten-text not-allowed'>
              {templateDocument.get('name')}  
            </span> :
            <span>
              <MaybeDocumentEditLink
                templateVersion={templateVersion}
                to={navLink}
                className="underlined"
                userId={userId}
                userIsAdmin={userIsAdmin}
                openModal={this.props.openDocumentTakeOverModal}
              >
                {templateDocument.get('name')}
              </MaybeDocumentEditLink>
              {templateDocumentPriority && hasAccessToDocumentReviews &&
                <span className={`ml-1-2 mb-1-2 actvities-priority-pill smaller-size ${PRIORITY_CLASSES[templateDocumentPriority]}`}>
                  {PRIORITY_NAMES[templateDocumentPriority]}
                </span>
              }
            </span>
          }
          {isAdopted && !(isGroupCreatedDocument(templateDocument) && !adoptedGroupDocument) && (
            showTemplateDocument ? 
              <div className='ml-1 lighten-text not-allowed'>
                ↳ Adopted from {adoptedGroupDocument ? 'Group' : 'iComply'
                  } template v{
                    adoptedDocument.get('document_version_number')
                  }
              </div> :
              <div className={'ml-1'}>
              ↳ Adopted from <MaybeDocumentEditLink
                  templateVersion={adoptedDocument}
                  to={
                    `${docPagePath}version/${
                      adoptedDocument.get('template_document_version')
                    }/`
                  }
                  className="underlined"
                  userId={userId}
                >
                  {adoptedGroupDocument ? 'Group' : 'iComply'
                  } template v{
                    adoptedDocument.get('document_version_number')
                  }
                </MaybeDocumentEditLink>
                  <UpdateToIfLaterVersion
                    adoptedDocument={
                    groupDocuments
                      ? adoptedDocument.deleteIn(['template_document', 'group_template_document_latest_version_number'])
                      : adoptedDocument}
                    documentKind={templateDocument.get('kind')}
                    isGroupsApp={isGroupsAppOrGroupDocuments}
                  />
              </div>
            )
          }
          {isCopied && !showTemplateDocument && (
            <span>
              <span> ({pluralize(copiedDocument.get('copies_count'), 'Copy', 'Copies')})</span>
              <UpdateToIfLaterVersion
                copiedDocument={copiedDocument}
                documentKind={templateDocument.get('kind')}
                showAllCopies
                isGroupsApp={isGroupsAppOrGroupDocuments}
              />
            </span>
          )}
        </td>
        <td data-title="Folder">
          {templateDocument.getIn(['folder', 'number'])}
        </td>
        <td data-title="Version">
          {versionNumber}
        </td>
        <td data-title="Last edited" className="nowrap">
          {isAdopted && (
            formatDate(templateVersion.get('modified'))
          )}
          {!groupDocuments && templateVersion.get('last_copy_edited') && (
            formatDate(templateVersion.get('last_copy_edited'))
          )}
          {!groupDocuments && templateVersion.get('copies_count') > 0 && (
            ` (${pluralize(templateVersion.get('copies_count'), 'copy', 'copies')})`
          )}
        </td>
        {hasAccessToDocumentReviews && !isGroupsAppOrGroupDocuments &&
          <td data-title='Review By'>
            {(isAdopted && adoptedDocument.get('document_review')) ?
              <p className={moment() > moment(reviewedByDate) && 'drug-expired'}>{formatDate(reviewedByDate)}</p> :
              'No Review'
            }
          </td>
        }
        {isGroupsAppOrGroupDocuments &&
          <td data-title="Show">
            <span className={`${showLoadingSpinner && 'mr-1'}`}>
              <input
                type='checkbox'
                checked={!showTemplateDocument}
                onClick={excludeTemplateDocument}
              />
            </span>
            {showLoadingSpinner &&
              <span className='pull-right'>
                <Loading />
              </span>
            }
          </td>
        }
      </tr>
    );
  }

  render() {
    const {
      templateCollection,
      templates,
      selectedViewByValue,
      isLoading,
      showingSearch,
      search,
      viewBy,
      userIsAdmin,
      practiceHasTotalHR,
      isGroupsApp,
      userIsGroupAdmin,
      hasAccessToDocumentReviews,
    } = this.props;

    const showPagination = (templates && !templates.isEmpty()) &&
      (templateCollection && templateCollection.get('hasMore'));

    if (!showingSearch && !selectedViewByValue && !templates) {
      return <Loading />;
    }

    const showPrintButton = viewBy === TEMPLATE_VIEW_BY.FOLDER && userIsAdmin && practiceHasTotalHR;

    const isGroupsAppOrGroupDocuments = isGroupsApp || this.props.groupDocuments;

    const folderId = viewBy === TEMPLATE_VIEW_BY.FOLDER && selectedViewByValue && selectedViewByValue.get('id');
    const folderName = viewBy === TEMPLATE_VIEW_BY.FOLDER && selectedViewByValue && selectedViewByValue.get('name');
    const hasGroupDocuments = templates && !templates.isEmpty();
    const customGroupFolder = viewBy === TEMPLATE_VIEW_BY.FOLDER && selectedViewByValue && selectedViewByValue.get('practice_group_creator');

    return (
      <div>
        {isGroupsApp && customGroupFolder ? (
          <h2 className="mb-1">
            <EditableFieldText
              key={selectedViewByValue.get("id")}
              object={selectedViewByValue}
              model={GROUP_TEMPLATE_DOCUMENT_FOLDERS}
              fieldName="name"
            />
          </h2>
        ) : (
          <h2 className="mb-1">
            {showingSearch && selectedViewByValue
              ? "Search results" + (search && ` matching '${search}'`)
              : selectedViewByValue && selectedViewByValue.get("name")}
          </h2>
        )}

        {customGroupFolder && isGroupsApp && userIsGroupAdmin && (
          <LiveButton
            onClick={() => this.props.openDeleteFolderConfirmationModal(
              folderId,
              folderName,
              hasGroupDocuments
            )}
            className="btn btn-warning pull-right mb-1-2 ml-1"
          >
            Delete Folder
          </LiveButton>
        )}

        {isGroupsApp && userIsGroupAdmin && (
          <Link
            to="/page/groups/group-documents/templates/"
            className="btn btn-primary pull-right mb-1-2 ml-1"
          >
            Create new document
          </Link>
        )}

        { showPrintButton && (
          <a
            className="btn btn-default pull-right mb-1-2"
            onClick={() => this.props.openDocumentFolderExportModal(selectedViewByValue)}
          >
            Print <Icon className="inline-icon small-icon" type="print" />
          </a>
        )}
        <table className="responsive padded padded-sm">
          <thead>
            <tr>
              <th>Code</th>
              <th>Document name</th>
              <th>Folder</th>
              <th>Version</th>
              <th>Last edited</th>
              {(isGroupsApp || this.props.groupDocuments) && <th>Show</th>}
              {hasAccessToDocumentReviews && !isGroupsAppOrGroupDocuments && <th>Review By</th>}
            </tr>
          </thead>
          <tbody>
            {templates && templates.map(template => this.renderTemplateRow(template))}
          </tbody>
        </table>
        {isLoading && <Loading />}
        {(!isLoading && (templates && !templates.count())) && (
          <p className="help-block mt-1">
            No documents found.
          </p>
        )}
        {showPagination && (
          <a className="inline-link pull-right" onClick={() => this.loadMoreTemplates()}>
            Load more {'>>'}
          </a>
        )}
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  const templateCollection = state.collections.get(collectionFor(props));
  const currentPathname = state.routing.location.pathname;
  return {
    templateCollection,
    userId: state.userProfile.get('id'),
    userIsAdmin: isAdminOfPractice(state.currentPractice, state.userProfile),
    practiceHasTotalHR: isTotalHRMember(state.currentPractice),
    templates: templateCollection && templateCollection.get('items', List()),
    appSectionPrefix: getAppSectionPathPrefix(currentPathname),
    isGroupsApp: isGroupsAppSection(currentPathname),
    userIsGroupAdmin: isLoggedOnUserAdminOfCurrentGroup(state),
    hasAccessToDocumentReviews: practiceHasAccessToDocumentReviews(state),
    isLoading: (
      isPending(state.responses.getIn(['getCollection', DOCUMENTS]))
      || isPending(state.responses.getIn(['getCollection', GROUP_TEMPLATE_DOCUMENTS]))
      ),
    retainLoadedDocuments: state.ui.get('retainLoadedDocuments'),
    excludeTemplateDocumentItem: state.groupDocuments.get('excludeTemplateDocument'),
    excludeTemplateDocumentItemResponse: state.responses.get('excludeTemplateDocumentItem'),
  };
}

export default connect(mapStateToProps, {
  getCollection,
  clearCollection,
  openDocumentFolderExportModal,
  openDocumentTakeOverModal,
  openDeleteFolderConfirmationModal,
  setRetainLoadedDocuments,
  excludeTemplateDocumentByGroup,
}) (TemplatesListing);
