import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { readRequestOverdue } from '^/models/documents';

const DashboardDocumentReadRequests = ({ readRequests, openDocReadModal, canReadRequests }) => {
const NUM_COLS = 2;
  const readRequestsGroups = readRequests.groupBy((each, idx) =>
    Math.floor(idx / Math.round(readRequests.size / NUM_COLS))
  );

  return (
    <div className="row clearfix">
      {readRequestsGroups.map((readRequestsGroup, groupIdx) => (
        <div key={groupIdx} className={classNames('col-1-2')}>
          <table className="task-list">
            <tbody>
              {readRequestsGroup.map((readRequest, index) => {
                const {
                  document_name,
                  document_version,
                  due_date,
                  id,
                } = readRequest.toObject();

                return (
                  <tr key={index}>
                    <td>
                      <span>
                        {canReadRequests ?
                          <a
                          className="task-list-title text-underlined-on-hover"
                          onClick={() => openDocReadModal(id)}
                          >
                            {document_name} v{document_version}
                          </a>
                          :
                          <div>{document_name} v{document_version}</div>
                        }
                      </span>
                    </td>
                    <td>
                      <span className='task-list-date'>
                        {moment(due_date).format('Do MMM')}
                      </span>
                      {canReadRequests &&
                        <span>
                          <a
                            className={classNames(
                              'task-list-title',
                              'text-underlined-on-hover',
                              readRequestOverdue(due_date)
                                ? 'overdue'
                                : 'complete'
                            )}
                            onClick={() => openDocReadModal(id)}
                          >
                            READ
                          </a>
                        </span>
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default DashboardDocumentReadRequests;
