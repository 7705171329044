export const CREATE_TASK = 'CREATE_TASK';
export const EDIT_PRACTICE_MODAL = 'EDIT_PRACTICE_MODAL';
export const EDIT_GROUP_DETAILS_MODAL = 'EDIT_GROUP_DETAILS_MODAL';
export const ADD_NEW_OR_EXISTING_TEAM_MEMBER_TABSET =
  'ADD_NEW_OR_EXISTING_TEAM_MEMBER_TABSET';
export const EXPORT_MODAL_CONTENT = 'EXPORT_MODAL_CONTENT';
export const EDIT_MEMBER_MODAL_CONTENT = 'EDIT_MEMBER_MODAL_CONTENT';
export const EDIT_MEMBER_ACTIVITIES_MODAL = 'EDIT_MEMBER_ACTIVITIES_MODAL';
export const SUSPEND_MEMBER_MODAL = 'SUSPEND_MEMBER_MODAL';
export const RETIRE_MEMBER_MODAL = 'RETIRE_MEMBER_MODAL';
export const REINSTATE_MEMBER_MODAL = 'REINSTATE_MEMBER_MODAL';
export const SETUP_CYCLE_DATE_FORM_MODAL = 'SETUP_CYCLE_DATE_FORM_MODAL';
export const UPLOAD_ACTIVITY_TASK_ASSIGNEE_FILE_MODAL =
  'UPLOAD_ACTIVITY_TASK_ASSIGNEE_FILE_MODAL';
export const REMOVE_UPLOADED_ACTIVITY_FILE_MODAL =
  'REMOVE_UPLOADED_ACTIVITY_FILE_MODAL';
export const TOGGLE_PRIVACY_UPLOADED_ACTIVITY_TASK_FILE_MODAL =
  'TOGGLE_PRIVACY_UPLOADED_ACTIVITY_TASK_FILE_MODAL';
export const CHANGE_UPLOADED_TASK_FILE_FOLDER_MODAL =
  'CHANGE_UPLOADED_TASK_FILE_FOLDER_MODAL';
export const CONFIRM_DEMO_RESET_MODAL = 'CONFIRM_DEMO_RESET_MODAL';
export const CONFIRM_UPGRADE_DEMO_MODAL = 'CONFIRM_UPGRADE_DEMO_MODAL';
export const DISCARD_WORKING_VERSION_MODAL = 'DISCARD_WORKING_VERSION_MODAL';
export const PUBLISH_AS_CURRENT_VERSION_MODAL =
  'PUBLISH_AS_CURRENT_VERSION_MODAL';
export const CONFIRM_CHANGE_ACTIVITY_DATE_MODAL =
  'CONFIRM_CHANGE_ACTIVITY_DATE_MODAL';
export const PUBLISH_AS_NEW_VERSION_MODAL = 'PUBLISH_AS_NEW_VERSION_MODAL';
export const PUBLISH_DRAFT_MODAL = 'PUBLISH_DRAFT_MODAL';
export const REPORT_MODAL_CONTENT = 'REPORT_MODAL_CONTENT';
export const MARK_TASK_COMPLETE_MODAL = 'MARK_TASK_COMPLETE_MODAL';
export const APPLY_TEMPLATE_ACTIVITY_TO_LIVE_CYCLES_MODAL =
  'APPLY_TEMPLATE_ACTIVITY_TO_LIVE_CYCLES_MODAL';
export const PRACTICE_ADOPTION_MODAL = 'PRACTICE_ADOPTION_MODAL';
export const HELP_MODAL = 'HELP_MODAL';
export const REASSIGN_PRIVATE_DOCUMENTS_MODAL = 'REASSIGN_PRIVATE_DOCUMENTS_MODAL';
export const RESTORE_ADOPTED_VERSION_MODAL = 'RESTORE_ADOPTED_VERSION_MODAL';
export const EXIT_CONFIRMATION_MODAL = 'EXIT_CONFIRMATION_MODAL';
export const NAME_DOCUMENT_COPY_MODAL = 'NAME_DOCUMENT_COPY_MODAL';
export const TASKS_LEFT_UNDELETED = 'TASKS_LEFT_UNDELETED';
export const DOCUMENT_FOLDER_EXPORT_MODAL = 'DOCUMENT_FOLDER_EXPORT_MODAL';
export const DELETE_COPY_MODAL = 'DELETE_COPY_MODAL';
export const MAINTENANCE_MODAL = 'MAINTENANCE_MODAL';
export const REASSIGN_CONFIRMATION_MODAL = 'REASSIGN_CONFIRMATION_MODAL';
export const CREATE_GROUP_FOLDER_MODAL = 'CREATE_GROUP_FOLDER_MODAL';
export const PUBLISH_GROUP_DOCUMENT_MODAL = 'PUBLISH_GROUP_DOCUMENT_MODAL';
export const EDIT_GROUP_CREATED_DOCUMENT_MODAL = 'EDIT_GROUP_CREATED_DOCUMENT_MODAL';
export const PUBLISH_GROUP_DOCUMENT_NEW_VERSION_MODAL = 'PUBLISH_GROUP_DOCUMENT_NEW_VERSION_MODAL';
export const CONNECTED_DEMO_MODE_DISALLOWS_MODAL = 'CONNECTED_DEMO_MODE_DISALLOWS_MODAL';
export const DELETE_HEALTHCHECK_MODAL = 'DELETE_HEALTHCHECK_MODAL';
export const DELETE_AUDIT_MODAL = 'DELETE_AUDIT_MODAL';
export const COMPLETE_HEALTHCHECK_CONFIRM_MODAL = 'COMPLETE_HEALTHCHECK_CONFIRM_MODAL';
export const AUDIT_STAGE_CONFIRM_MODAL = 'AUDIT_STAGE_CONFIRM_MODAL';
export const CREATE_EDIT_GROUP_TASK_MODAL = 'CREATE_EDIT_GROUP_TASK_MODAL';
export const ARCHIVE_GROUP_TASK_MODAL = 'ARCHIVE_GROUP_TASK_MODAL';
export const DISCARD_CURRENT_AND_ADOPT_NEW_VERSION = 'DISCARD_CURRENT_AND_ADOPT_NEW_VERSION';
export const COMPARISON_EDITOR_EXIT_CONFIRMATION_MODAL = 'COMPARISON_EDITOR_EXIT_CONFIRMATION_MODAL';
export const REMOVE_UPLOADED_DOCUMENT = 'REMOVE_UPLOADED_DOCUMENT';
export const DOC_READ_ADD_TEAM_MEMBERS_MODAL = 'DOC_READ_ADD_TEAM_MEMBERS_MODAL';
export const DOCUMENT_TO_READ = 'DOCUMENT_TO_READ';
export const ARCHIVE_DOCUMENT_TO_READ = 'ARCHIVE_DOCUMENT_TO_READ';
export const EXIT_RECORD_FORM_CONFIRMATION_MODAL = 'EXIT_RECORD_FORM_CONFIRMATION_MODAL';
export const DOCUMENT_TAKE_OVER_MODAL = 'DOCUMENT_TAKE_OVER_MODAL';
export const CREATE_BULK_DOC_READ_MODAL = 'CREATE_BULK_DOC_READ_MODAL';
export const CREATE_CHECKLIST_RECORD_GROUP_MODAL = 'CREATE_CHECKLIST_RECORD_GROUP_MODAL';
export const REFRESH_CHECKLIST_CONFIRMATION_MODAL = 'REFRESH_CHECKLIST_CONFIRMATION_MODAL';
export const ARCHIVE_CHECKLIST_CONFIRMATION_MODAL = 'ARCHIVE_CHECKLIST_CONFIRMATION_MODAL';
export const CREATE_CUSTOM_FIELD_MODAL = 'CREATE_CUSTOM_FIELD_MODAL';
export const EXIT_CREATE_RECORD_WITH_CUSTOM_FIELDS_CONFIRMATION_MODAL = 'EXIT_CREATE_RECORD_WITH_CUSTOM_FIELDS_CONFIRMATION_MODAL';
export const DOCUMENT_REVIEW_CONFIRMATION_MODAL = 'DOCUMENT_REVIEW_CONFIRMATION_MODAL';
export const DELETE_FOLDER_CONFIRMATION_MODAL = 'DELETE_FOLDER_CONFIRMATION_MODAL';
export const INHERIT_GROUPS_FUNCTIONALITY_MODAL = 'INHERIT_GROUPS_FUNCTIONALITY_MODAL';
