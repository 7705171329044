import React from 'react';
import { connect } from 'react-redux';
import { List, OrderedMap, Map } from 'immutable';

import { STATUS_CHOICES, STAFF_PRACTICE_TYPE, GROUP_ADMIN_TYPE, getStaffPracticeType } from '^/models/staffPractice';
import { STATUS_TYPES } from '^/models/user';
import { deepMergeObject, isPracticesGroupAdmin } from '^/utils';
import { getAllPracticeMembers } from '^/actions/collections';
import { ALL_PRACTICE_MEMBERS } from '^/consts/collectionKeys';
import {
  patchTeamMembersAndReloadMembers,
  createNewTeamMemberAndReloadMembers,
  setTeamMemberAndNavToDashboard,
  resetResponseAndOpenSuspendPracticeMemberModal,
  resetResponseAndOpenPracticeMemberModal,
  resetResponseAndOpenRetirePracticeMemberModal,
  resetResponseAndOpenReinstatePracticeMemberModal,
} from '^/actions/actionSequences';
import { openTeamMemberModal } from '^/actions/modals';
import { resendInvite } from '^/actions/actions';

import Icon from '^/components/app/content/Icon';
import Loading from '^/components/app/content/Loading';
import PureComponent from '^/components/common/PureComponent';
import TeamMembersTable from '^/components/app/practices/TeamMembersTable';
import ShowIfAdmin from '^/components/app/perms/ShowIfAdmin';

const HEADINGS = OrderedMap({
  [STATUS_CHOICES.ACTIVE]: '',
  [STATUS_TYPES.INVITED]: 'Pending Invites:',
  [STATUS_CHOICES.SUSPENDED]: 'Suspended Members:',
  [STATUS_CHOICES.RETIRED]: 'Retired Members:',
});

const TEAM_MEMBER_LIST_ORDERING = [
  GROUP_ADMIN_TYPE,
  STAFF_PRACTICE_TYPE.PRACTICE_ADMIN,
  STAFF_PRACTICE_TYPE.PRACTICE_MANAGER,
  STAFF_PRACTICE_TYPE.TEAM_MEMBER,
  STAFF_PRACTICE_TYPE.READ_ONLY,
];

export class PracticeTeamPage extends PureComponent {
  setupTeamMember(data) {
    const { currentPractice } = this.props;
    const currentPracticeId = currentPractice.get('id');

    // set default practice as this one on the new user's staff detail
    const mergedData = deepMergeObject(data, {
      staffdetail: { current_practice: currentPracticeId },
      staffpractice: { practice: currentPracticeId },
    });

    this.props.createNewTeamMemberAndReloadMembers(
      currentPracticeId,
      mergedData
    );
  }

  patchTeamMembers(members) {
    const { currentPractice } = this.props;
    this.props.patchTeamMembersAndReloadMembers(
      currentPractice.get('id'),
      members
    );
  }

  openAddModal() {
    const onSubmitNewMember = (data) => this.setupTeamMember(data);
    const onSubmitExistingMembers = (data) => this.patchTeamMembers(data);

    return this.props.openTeamMemberModal(
      onSubmitNewMember,
      onSubmitExistingMembers,
      "p-1"
    );
  }

  loadMembers(practiceId) {
    this.props.getAllPracticeMembers(practiceId);
  }

  componentWillMount() {
    const { currentPractice } = this.props;

    const practiceId = currentPractice.get('id', '');
    this.loadMembers(practiceId);
  }

  componentWillReceiveProps(newProps) {
    const { currentPractice: newCurrentPractice } = newProps;
    const { currentPractice } = this.props;

    const newPracticeId = newCurrentPractice.get('id', '');
    const oldPracticeId = currentPractice.get('id', '');

    if (newPracticeId !== oldPracticeId) {
      this.loadMembers(newPracticeId);
    }
  }

  render() {
    const { currentPractice, userProfile, members } = this.props;
    const {
      openMemberModal,
      openSuspendModal,
      openRetireModal,
      openReinstateModal,
      resendInviteResponse,
    } = this.props;
    const currentPracticeId = currentPractice.get('id');

    if (!currentPractice || !members) {
      return <Loading />;
    }

    const loggedInMember = members.find(
      (member) => member.getIn(['user', 'id']) === userProfile.get('id')
    );

    const isInvited = (member) =>
      member.getIn(['user', 'status']) === STATUS_TYPES.INVITED
      || member.get('status') === STATUS_TYPES.INVITED;

    // invited members have status on the user, all other relevant statuses are on staff practice object
    const invitedMembers = members.filter(isInvited);
    const otherMembers = members.filterNot(isInvited);

    const groupedMembers = otherMembers
      .sortBy((member) => member.getIn(['user', 'created']))
      .sortBy((member) => TEAM_MEMBER_LIST_ORDERING.indexOf(
        getStaffPracticeType(
          member.get('type'),
          isPracticesGroupAdmin(currentPractice, member.getIn(['user', 'id'])))
        )
      )
      .groupBy((member) => member.get('status'))
      .set(STATUS_TYPES.INVITED, invitedMembers);

    return (
      <div className="mb-2">
        <h5>{currentPractice.get('name')}</h5>

        <ShowIfAdmin>
          <button
            className="btn btn-default indented pull-right-sm"
            onClick={() => this.openAddModal()}
          >
            <Icon type="plus" className="button-icon" /> Add team member
          </button>
        </ShowIfAdmin>

        <h1>Team Members</h1>

        <hr className="dark" />

        {HEADINGS.map((heading, status) => (
          <div key={status}>
            {heading && <h3 className="mt-2">{heading}</h3>}
            <TeamMembersTable
              setTeamMemberAndNavToDashboard={(member) =>
                this.props.setTeamMemberAndNavToDashboard(member.get('user'))
              }
              openMemberModal={(member) => {
                  if(member.get('status') !== STATUS_TYPES.INVITED) {
                    openMemberModal(member, currentPracticeId)
                  }
                }
              }
              openSuspendModal={(member) =>
                openSuspendModal(member, currentPracticeId)
              }
              openRetireModal={(member) =>
                openRetireModal(member, currentPracticeId)
              }
              openReinstateModal={(member) =>
                openReinstateModal(member, currentPracticeId)
              }
              currentPractice={currentPractice}
              resendInvite={this.props.resendInvite}
              resendInviteResponse={resendInviteResponse}
              members={groupedMembers.get(status, List())}
              loggedInMember={loggedInMember}
              group={status}
            />
          </div>
        ))}
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    currentPractice: state.currentPractice,
    members: state.collections.getIn([ALL_PRACTICE_MEMBERS, 'items']),
    userProfile: state.userProfile,
    resendInviteResponse: state.responses.get('resendInvite', Map()),
  };
}

export default connect(mapStateToProps, {
  resendInvite,
  openTeamMemberModal,
  getAllPracticeMembers,
  createNewTeamMemberAndReloadMembers,
  patchTeamMembersAndReloadMembers,
  setTeamMemberAndNavToDashboard,
  openMemberModal: resetResponseAndOpenPracticeMemberModal,
  openSuspendModal: resetResponseAndOpenSuspendPracticeMemberModal,
  openRetireModal: resetResponseAndOpenRetirePracticeMemberModal,
  openReinstateModal: resetResponseAndOpenReinstatePracticeMemberModal,
})(PracticeTeamPage);
