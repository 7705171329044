import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConnectHOC } from '@dabapps/connect-hoc';

import { StoreState } from '^/store/types';
import NonFieldErrorRenderer from '^/components/common/NonFieldErrorRenderer';
import {
  selectRequestErrorsById,
  selectRequestIsLoadingById,
} from '^/selectors/thunk-requests';
import {
  ItemRendererProps,
  AuditQuestion,
} from '^/components/app/digital-tools/audit/types';
import { updateAuditQuestion } from '^/actions/audit';
import Select from '^/components/app/forms/Select';
import NotesEditor from './question-notes';

interface StateProps {
  errors: unknown;
  isLoading: boolean;
}

interface DispatchProps {
  onAnswerSelect: typeof updateAuditQuestion;
}

type OwnProps = ItemRendererProps<AuditQuestion>;

export type Props = OwnProps & StateProps & DispatchProps;

class QuestionAndAnswer extends Component<Props, {}> {
  public render() {
    const { question, errors, isLoading } = this.props;

    return (
      <div className="question-and-answer-wrapper">
        <div className="question-and-answer pre-wrap">
          <span className="question pr-large">{question.question_text}</span>
          <span>
            <Select
              disabled={isLoading}
              selectedValue={question.selected_response ?? ''}
              onChange={this.onSelectAnswer}
              options={question.responses.map(option => ({
                label: option.text,
                value: option.id,
              }))}
            />
          </span>
        </div>
        <div className="question pr-large pre-wrap">
          <i>{question.information_text}</i>
        </div>
        <NotesEditor onClick={this.onSubmitNotes} notes={question.note} />
        {errors && (
          <div>
            <NonFieldErrorRenderer errors={errors} />
            <button onClick={this.onTryAgain}>Try again?</button>
          </div>
        )}
      </div>
    );
  }

  private onSubmitNotes = (note: string) => {
    const { auditId, categoryId, question, onAnswerSelect } = this.props;

    onAnswerSelect(auditId, categoryId, question.id, { note });
  };

  public onSelectAnswer = (value: string) => {
    const { auditId, categoryId, question, onAnswerSelect } = this.props;

    onAnswerSelect(auditId, categoryId, question.id, {
      selected_response: value,
    });
  };

  public onTryAgain = () => {
    const { selected_response } = this.props.question;
    if (selected_response) {
      this.onSelectAnswer(selected_response);
    }
  };
}

const mapStateToProps = (state: StoreState, props: OwnProps): StateProps => ({
  errors: selectRequestErrorsById(state, {
    requestName: 'answerAuditQuestion',
    requestId: props.question?.id,
  }),
  isLoading: selectRequestIsLoadingById(state, {
    requestName: 'answerAuditQuestion',
    requestId: props.question?.id,
  }),
});

export { QuestionAndAnswer as QuestionAndAnswerUnconnected };

export default (connect as ConnectHOC)<
  StateProps,
  DispatchProps,
  OwnProps,
  StoreState
>(mapStateToProps, {
  onAnswerSelect: updateAuditQuestion,
})(QuestionAndAnswer);
