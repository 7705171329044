import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import HTMLContent from '^/components/common/content/HTMLContent';
import PolicyWrapper from './PolicyWrapper';
import * as privacyHTML from './privacy.html';

export default class PrivacyPolicy extends PureComponent {
  render() {
    return (
      <PolicyWrapper>
        <HTMLContent text={privacyHTML} />
      </PolicyWrapper>
    );
  }
}
