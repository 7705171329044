import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';

import { closeModal, openReassignmentConfirmationModal } from '^/actions/modals';
import Loading from '^/components/app/content/Loading';
import { STAFF_PRACTICE_TYPE } from '^/models/staffPractice';
import PureComponent from '^/components/common/PureComponent';


export class ReassignPrivateDocumentOwnershipControl extends PureComponent {
  constructor (props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    if (event.target.value && this.props.practiceMembers) {
      const chosenMemberDetails = this.props.practiceMembers.find(item => item.get('id') === event.target.value);
      const { target: { value }} = event;
      this.props.openReassignmentConfirmationModal(
        () => this.props.onReassign(
          value,
          chosenMemberDetails.getIn(['user', 'staffdetail', 'id'])
        ),
        () => this.props.closeModal(),
        this.props.isLoading
      );
    }
  }

  render () {
    const { practiceMembers, isLoading, loggedInUser } = this.props;

    const possibleNewMembers = practiceMembers.filterNot(
      member => member.getIn(['user', 'id']) === loggedInUser.get('id') ||
      member.get('type') === STAFF_PRACTICE_TYPE.READ_ONLY
    );

    return isLoading ?
      (<Loading className="small inline" />) :
      (
        <div>
          <select
            defaultValue={0}
            onChange={this.onChange}
            id="assignee" className="form-control-margin"
          >
            <option value={0}>
              Choose a new owner...
            </option>
            {possibleNewMembers.map(member =>
              <option key={member.get('id')} value={member.get('id')}>
                {member.getIn(['user', 'staffdetail', 'full_name'])}
              </option>
            )}
          </select>
        </div>
      );
  }
}

export function mapStateToProps(state) {
  return {
    practiceMembers: state.currentPractice.get('members') || List(),
    loggedInUser: state.userProfile,
  };
}

export default connect(
  mapStateToProps,
  {
    openReassignmentConfirmationModal,
    closeModal,
  })
(ReassignPrivateDocumentOwnershipControl);
