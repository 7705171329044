export const extraInfo = {
  productType:
    "Please enter details of product type. For example, 'Acid Etch' or 'Fissure Sealan'",
  productName:
    "Please enter product name. For example, 'Total Etch' or  'Ultraseal XT Plus'",
  usedBy:
    "Please enter the roles of the people who will use the hazardous substance. For example, 'Nurse and Dentist' or 'Nurse and Cleaner'",
  usedFor:
    "Please enter what the product is used for. For example, 'Phosphoric etching for tooth preparation' or 'Cleaning surgery surfaces'",
  location:
    "Please enter where the substance is located. For example, 'Surgery materials drawer' or 'Cleaners cupboard'",
  amountUsed:
    "Please enter the amount used per day (or per week/month if more appropriate). For example, '5ml per day' or '1g per week'",
  usageFrequency:
    "Please enter the frequency of use. For example '5 times per day' or 'Once a month",
  usageConditions:
    'Please enter instructions for safe usage that will be shared with the team. These are often found on product information leaflets or may form part of your clinical or cleaning procedures. You may need to write these yourself',
  signalWord:
    "This is located on the product's safety data sheet and is normally either 'Danger', 'Warning' or 'Caution'",
  hazardStatement: `These are listed on the product's safety data sheet. For example: 
    -H310 Fatal in contact with skin 
    -H331 Toxic if inhaled 
    -H314 Causes severe skin burns and eye damage`,
  risksToHealth: `These are listed on the product's safety data sheet, normally under the toxicological information section. For example: 
    Route of entry: Skin contact, eye contact, ingestion. Eyes. Causes severe burns. Skin. Corrosive - will burn skin. Ingestion. Harmful if swallowed. Inhalation. R37: Irritation to respiratory system
    Chronic effects and symptoms of long -term exposure: Eyes. Causes severe burns. Skin. Corrosive - will burn skin. Ingestion. Chemical burns - may cause swallowing difficulties, vomiting, diarrhoea and shock. 
    Inhalation. Will irritate the respiratory tract. Irritancy of product: Corrosive. Will cause eye burns and permanent tissue damage.`,
  precautionaryStatement: `These are listed on the product's safety data sheet. For example:
    P261 Avoid breathing dust/fume/gas/mist/vapours/spray
    P280 Wear protective gloves/protective clothing/eye protection/face protection
    P262 Do not get in eyes, on skin, or on clothing
    P301+P310 IF SWALLOWED: Immediately call a POISON CENTRER or doctor/physician
    `,
  safetyMeasures: `Please list control measures and instructions, using information from the safety data sheet, such as:
    Avoid breathing the vapour and use in a well-ventilated area. Keep away from sources of ignition and direct sunlight
    `,
  ppeRequired: `These are listed on the product's safety data sheet. For example:
    Respiratory protection: None 
    Hand protection: Latex gloves
    Eye protection: Protective eyewear
    Skin protection: Clinical workwear (scrubs)
    `,
  firstAidProceedures: `These are listed on the product's safety data sheet and you will need to adapt them to add phone numbers and practice specific information etc. For example:
    - Eyes: Flush immediately with plenty of water. Following this remove any contact lenses and repeat for at least 15 minutes. Have eyes examined and tested by medical personnel.
    - Skin: Wash with soap and water. Contact medical personnel if irritation develops or persists
    - Ingestion: Do NOT induce vomiting. Call 999
    - Inhalation Move to fresh air. If not breathing give artificial respiration or oxygen by trained personnel. Contact medical personnel by calling 999
    `,
  emergencyProceedures: `Using information from the product's safety data sheet add any additional practice specific procedures here, such as what to do in the case of fire or environmental exposure/accidental release`,
  specialStorage: `These are listed on the product's safety data sheet. For example:
    No special storage requirement or Waste mercury and used capsules stored under suppressant solution in sealed containers`,
  disposalMeasures: `These are listed on the product.s safety data sheet and will need to be adapted. For example:
    Yes, classified as special waste, used developer and fixer is stored in special containers and disposed of by a certified waste management company
    `,
  healthMonitoring: `There are very few circumstances where these are required in a dental practice. You should discuss this with your occupational health provider to determine if this field needs to be completed. For Example:
    Only if allergy develops. Medical opinion to be sought`,
  additionalInfo: `Please enter any other information you feel is relevant`,
};
