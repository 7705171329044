import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import { connect } from 'react-redux';
import { updateGroupDetailsAndCloseModal, setPendingUploadAndResetError } from '^/actions/actionSequences';
import EditGroupDetailsForm from '^/components/app/groups/group-details/modals/EditGroupDetailsForm';

class EditGroupDetailsModal extends PureComponent {

  updateGroupDetails(data) {
    const { logo, ...rest } = data;
    const dataToSubmit = typeof logo === 'string' ? rest : {...rest, logo };
    this.props.updateGroupDetailsAndCloseModal(this.props.group.get('id'), dataToSubmit);
  }

  render() {
    const { group, setFileUpload } = this.props;

    return (
      <EditGroupDetailsForm
        onSubmit={data => this.updateGroupDetails(data)}
        setPendingUploadInForm={(form, field, file) => setFileUpload(form, field, file, form)}
        initialValues={group.toJS()}
        group={group}
      />
    );
  }
}

export default connect(
  undefined,
  {
    updateGroupDetailsAndCloseModal,
    setFileUpload: setPendingUploadAndResetError
  }
) (EditGroupDetailsModal);
