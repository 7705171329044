import React from 'react';

import { PRIORITY_NAMES, PRIORITY_CLASSES } from '^/models/constants';

export const ActivityPriority = ({activity}) => {
  const { priority } = activity.toObject();
  return (
    <div className={`actvities-priority-pill ${PRIORITY_CLASSES[priority]}`}>
      {PRIORITY_NAMES[priority]}
    </div>
  );
};
