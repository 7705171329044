import React, { PropTypes } from 'react';
import { OrderedMap } from 'immutable';

export const DAYS_OF_WEEK = OrderedMap({
  1: 'Monday', 2: 'Tuesday', 3: 'Wednesday',
  4: 'Thursday', 5: 'Friday', 6: 'Saturday', 7:'Sunday'
});

export const DayOfTheWeekInput = ({field, id, className}) =>
  <select id={id} className={className} {...field}>
    {DAYS_OF_WEEK.map((display, value) =>
      <option key={value} value={value}>{display}</option>)}
  </select>;


DayOfTheWeekInput.propTypes = {
  field: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default DayOfTheWeekInput;
