import { routeActions } from 'react-router-redux';

import { collapseUiComponent, resetResponse, setShowing } from '^/actions/actions';
import { deleteItem } from '^/actions/collections';
import { createItem, loadTemplateLatestGroupVersion, patchItem } from '^/actions/items';
import makeActionChain from '^/actions/makeActionChain';
import { closeModal, openGroupTemplateDocumentEditModal } from '^/actions/modals';
import { makeAsyncActionSet } from '^/actions/utils';
import {
  GROUP_CREATED_TEMPLATE_DOCUMENTS,
  GROUP_DOCUMENT_ALERTS,
  GROUP_TEMPLATE_DOCUMENT_DRAFTS,
  GROUP_TEMPLATE_DOCUMENT_FOLDERS,
} from '^/consts/collectionKeys';
import { TEMPLATE_VIEW_BY, VIEW_BY_NAME } from '^/consts/templateViewBy';
import makeRsaAction from '^/middleware/makeRsaAction';

export const GET_GROUP_DOCUMENTS_ALERTS_COUNT = makeAsyncActionSet('GET_GROUP_DOCUMENTS_ALERTS_COUNT');
export function getGroupDocumentsAlertsCount() {
  return makeRsaAction(
    GET_GROUP_DOCUMENTS_ALERTS_COUNT,
    '/alerts/document-alert-count-for-current-group/',
    'GET',
  );
}

export const DECREMENT_GROUP_DOCUMENT_ALERTS_COUNT = 'DECREMENT_GROUP_DOCUMENT_ALERTS_COUNT';
export function decrementGroupDocumentAlertsCount() {
  return { type: DECREMENT_GROUP_DOCUMENT_ALERTS_COUNT };
}

export function dismissGroupDocumentAlert(id) {
  return makeActionChain([
    () => deleteItem(GROUP_DOCUMENT_ALERTS, id),
    () => decrementGroupDocumentAlertsCount()
  ]);
}

export function createGroupFolderAndCloseModal(data) {
  return makeActionChain([
    () => createItem(GROUP_TEMPLATE_DOCUMENT_FOLDERS, data),
    closeModal,
  ]);
}

export function createGroupDraftShowDraftsAndRedirect(data) {
  return makeActionChain([
    () => createItem(GROUP_TEMPLATE_DOCUMENT_DRAFTS, data),
    () => setShowing(VIEW_BY_NAME, TEMPLATE_VIEW_BY.DRAFTS),
    () => routeActions.push('/page/groups/group-documents/'),
  ]);
}

export function resetResponseAndOpenGroupDocumentModal(templateDocument) {
  return makeActionChain([
    () => resetResponse('updateItem'),
    () => openGroupTemplateDocumentEditModal(templateDocument),
  ]);
}

export function updateGroupTemplateDocumentReloadAndCloseModal(id, data) {
  return makeActionChain([
    () => patchItem(GROUP_CREATED_TEMPLATE_DOCUMENTS, id, data),
    () => loadTemplateLatestGroupVersion(id),
    closeModal,
  ]);
}

export const UPDATE_GROUP_TEMPLATE_DOCUMENT_FILE_VERSION =
  makeAsyncActionSet('UPDATE_GROUP_TEMPLATE_DOCUMENT_FILE_VERSION');
export function updateGroupTemplateDocumentFileVersion(groupTemplateDocumentId, data) {
  return makeRsaAction(
    UPDATE_GROUP_TEMPLATE_DOCUMENT_FILE_VERSION,
    `/documents/group-template-documents/${groupTemplateDocumentId}/update-file-version/`,
    'POST',
    data,
    {source: data.new_version ? 'newVersion' : 'currentVersion'}
  );
}

export function updateGroupTemplateDocumentFileVersionReloadAndCloseEditor(
  editorName,
  groupTemplateDocumentId,
  data
) {
  return makeActionChain([
    () => updateGroupTemplateDocumentFileVersion(groupTemplateDocumentId, data),
    () => loadTemplateLatestGroupVersion(groupTemplateDocumentId),
    () => collapseUiComponent(editorName),
  ]);
}

export const EXCLUDE_TEMPLATE_DOCUMENT_BY_GROUP = makeAsyncActionSet('EXCLUDE_TEMPLATE_DOCUMENT_BY_GROUP');
export function excludeTemplateDocumentByGroup(data) {
  return makeRsaAction(
    EXCLUDE_TEMPLATE_DOCUMENT_BY_GROUP,
    '/documents/group-template-excluded/',
    'POST',
    data,
    {templateDocumentId: data.template_document}
  );
}

export const GET_FOLDER_UPLOADED_ACTIVITY_TASK_FILES_COUNT = makeAsyncActionSet('GET_FOLDER_UPLOADED_ACTIVITY_TASK_FILES_COUNT');
export function getFolderUploadedActivityTaskFilesCount(folderId) {
  return makeRsaAction(
      GET_FOLDER_UPLOADED_ACTIVITY_TASK_FILES_COUNT,
    `/${GROUP_TEMPLATE_DOCUMENT_FOLDERS}/${folderId}/uploaded-files-count/`,
    'GET',
    null,
  );
}
