import React from 'react';
import Icon from '^/components/app/content/Icon';
import DateFilter from './DateFilter';
import { FILTER_KEYS } from '^/components/app/groups/insights/constants';

const DateRangeFilter = ({ dateFrom, dateTo, isDisabled, onFilterChange }) => {
  return (
    <span className="date-wrapper print-hide">
      <DateFilter
        filterKey={FILTER_KEYS.DATE_FROM}
        selectedValue={dateFrom}
        onFilterChange={onFilterChange}
        maxDate={dateTo}
        isDisabled={isDisabled}
      />
      <Icon type="arrow-right" className="link-icon ml-1-2" />
      <DateFilter
        className="ml-1-2"
        filterKey={FILTER_KEYS.DATE_TO}
        selectedValue={dateTo}
        onFilterChange={onFilterChange}
        minDate={dateFrom}
        isDisabled={isDisabled}
      />
    </span>
  );
};

export default DateRangeFilter;
