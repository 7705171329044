import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import {
  updateAuditStatusIfMatchAndContinue,
  getAuditById,
} from '^/actions/audit';
import {
  IntroOptions,
  AuditResponse,
  AuditWorkflowTypes,
} from '^/components/app/digital-tools/audit/types';
import { StoreState } from '^/store/types';
import { selectAuditById } from '^/selectors/audit';
import { CachedItem } from '^/components/app/healthcheck/types';
import Loading from '^/components/app/content/Loading';

interface DispatchProps {
  updateAuditStatusIfMatchAndContinue: typeof updateAuditStatusIfMatchAndContinue;
  getAuditById: typeof getAuditById;
}

type RouteProps = RouteComponentProps<{ auditId: string }, {}>;

type OwnProps = RouteProps;

interface StateProps {
  displayedAudit: CachedItem<AuditResponse> | null;
  loading: boolean;
}

type Props = DispatchProps & OwnProps & StateProps;

const createIntroPage = <P extends {} = {}>(options: IntroOptions) => (
  Content: React.ComponentType<P>
) => {
  class AuditIntroPage extends Component<Props & P, {}> {
    public componentDidMount() {
      const {
        displayedAudit,
        params: { auditId },
      } = this.props;

      if (!displayedAudit) {
        this.props.getAuditById(auditId);
      }
    }

    public render() {
      const { displayedAudit, loading, ...remainingProps } = this.props;
      if (loading) {
        return <Loading />;
      }

      if (!displayedAudit) {
        return <div className="error">No Audit found</div>;
      }

      return (
        <div className="healthcheck">
          <div className="main">
            <h1>
              <span className="bold">{displayedAudit.audit_type.name}</span>
            </h1>
            <hr className="thin" />
            <h2>Preparing for your {options.type.toLowerCase()}</h2>
            <Content {...(remainingProps as P)} />
            <div className="content pre-wrap">
              {options.type === AuditWorkflowTypes.Audit &&
                displayedAudit.audit_intro_text}
              {options.type === AuditWorkflowTypes.Actions &&
                displayedAudit.action_intro_text}
            </div>
            <button className="btn btn-save" onClick={this.onClickStart}>
              {options.buttonText || `Start ${options.type}`}
            </button>
          </div>
        </div>
      );
    }

    private onClickStart = () => {
      if (this.props.displayedAudit) {
        this.props.updateAuditStatusIfMatchAndContinue(
          this.props.displayedAudit,
          options.intendedFromStatus
        );
      }
    };
  }

  const mapStateToProps = (
    state: StoreState,
    props: RouteProps
  ): StateProps => ({
    displayedAudit: selectAuditById(state, props.params.auditId),
    loading: state.thunkRequests.getAuditById.isLoading,
  });

  return connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, {
    updateAuditStatusIfMatchAndContinue,
    getAuditById,
  })(AuditIntroPage as any); // eslint-disable-line @typescript-eslint/no-explicit-any
};

export default createIntroPage;
