import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Map } from 'immutable';

import { isPending, hasSucceeded, hasFailed } from '^/consts/responseStates';
import { renderDocument, renderDocumentVersionToRead, resetResponse } from '^/actions/actions';
import { retryExportAndResetPollProgress, downloadFromURL } from '^/actions/actionSequences';
import PureComponent from '^/components/common/PureComponent';
import Icon from '^/components/app/content/Icon';
import PollDownload from './PollDownload';
import DemoModeMayDisallow from '^/components/app/perms/DemoModeMayDisallow';

const BUTTON_CLASS_NAME = 'btn btn-default indented ml-1-2';

const getClassName = (className) => classNames(BUTTON_CLASS_NAME, className);


export function DownloadButton(props) {
  const {
    trackedDownloadLink, downloadLink, webLink, className, filename,
    response, startRenderDocument, disabled, exportId, onRetry, pristine,
    downloadPDFLink
  } = props;

  if (trackedDownloadLink) {
    return (
      <a
        className={getClassName(className)}
        onClick={() => props.downloadFromURL(trackedDownloadLink.replace('/api', ''))}
      >
        <Icon type="cloud-download" className="button-icon" /> Download
      </a>
    );
  }

  if (downloadLink) {
    return (
      <a className={getClassName(className)} href={downloadLink} download={filename}>
        <Icon type="cloud-download" className="button-icon" /> Download
      </a>
    );
  }

  if (downloadPDFLink) {
    return (
      <a className={getClassName(className)} href={downloadPDFLink} download={filename}>
        <Icon type="cloud-download" className="button-icon" /> Download PDF
      </a>
    );
  }

  if (webLink) {
    return (
      <a className={getClassName(className)} href={webLink} target="_blank" >
        <Icon type="external-link" className="button-icon" /> Open
      </a>
    );
  }

  if (isPending(response)) {
    return (
      <a className={getClassName(className)} disabled>
        <Icon type="cog" className="button-icon rotate" /> Generating PDF...
      </a>
    );
  }

  if (exportId || hasSucceeded(response)) {
    return (
      <PollDownload
        className={getClassName(className)}
        exportId={exportId || response.getIn(['payload', 'id'])}
        onRetry={() => onRetry()}
      />
    );
  }

  return (
    <span>
      <a className={getClassName(className)} onClick={startRenderDocument} disabled={disabled}>
        <Icon type="cloud-download" className="button-icon" />
        {hasFailed(response) ? 'Try Again' : ((pristine ? '' : 'Save & ') + 'Generate PDF')}
      </a>

      {hasFailed(response) && (
        <p className="pull-right cr alert-error">
          Unable to access PDF. Please try again later.
        </p>
      )}
    </span>
  );
}

export class DownloadDocumentButton extends PureComponent {

  componentWillMount() {
    this.props.resetResponse('renderDocument');
    this.props.resetResponse('renderDocumentVersionToRead');
  }

  startRenderDocument() {
    const { canSave, documentType, documentId, onSave, pristine, isSaveCopy, onSaveCopy, documentVersionToRead } = this.props;
    if (!pristine && canSave) {
      onSave(documentType);
    } else if (!pristine && isSaveCopy) {
      onSaveCopy(documentType);
    } else if (documentVersionToRead) {
      this.props.renderDocumentVersionToRead(documentId, documentVersionToRead);
    } else {
      this.props.renderDocument(documentType, documentId);
    }
  }

  render() {
    const { response, exportId } = this.props;

    return (
      <DemoModeMayDisallow message="cannot download documents as PDF" response={response}>
        <DownloadButton
          {...this.props}
          filename={this.props.pollResponse.getIn(['payload', 'filename'], true)}
          startRenderDocument={() => this.startRenderDocument()}
          onRetry={() => this.props.retryExportAndResetPollProgress(exportId)}
        />
      </DemoModeMayDisallow>
    );
  }
}

DownloadDocumentButton.propTypes = {
  documentId: React.PropTypes.string,
  documentType: React.PropTypes.string,
};

export function mapStateToProps(state, props) {
  const response = state.responses.getIn(['renderDocument', props.documentId]) || state.responses.getIn(['renderDocumentVersionToRead', props.documentId]);;
  const exportId = props.exportId || response && response.getIn(['payload', 'id']);
  return {
    response,
    exportId,
    pollResponse: state.responses.getIn(['pollExport', exportId], Map())
  };
}

export default connect(
  mapStateToProps,
  { renderDocument, renderDocumentVersionToRead, retryExportAndResetPollProgress, downloadFromURL, resetResponse }
) (DownloadDocumentButton);
