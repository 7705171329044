import React from 'react';
import { fromJS, Map } from 'immutable';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';
import { setResponseErrorForKey } from '^/actions/actions';
import { createItemAndRunCallback, getDraftsCollectionAndNavTo } from '^/actions/actionSequences';
import { MODELS } from '^/permissions/capabilities';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import PureComponent from '^/components/common/PureComponent';

import {
  ADMIN_TEMPLATE_DOCUMENT_CREATE,
  ADMIN_TEMPLATE_DOCUMENT_CREATE_MODEL,
  ADMIN_TEMPLATE_DOCUMENT_DRAFT_MODEL,
  ADMIN_TEMPLATE_DOCUMENT_CREATE_FIELDS,
  DOCUMENT_TYPE_CONTENT_FIELDS,
  transformDataToSubmit,
  filterFolderBySector
} from './template-documents';


function transform (fieldConfig, fields) {
  const sector = fields.sector.value;
  fieldConfig = filterFolderBySector(fieldConfig, sector);

  return fieldConfig
    .setIn(['content', 'isLandscape'], fields.is_landscape.value)
    .setIn(['sector', 'read_only'], Boolean(fields.sector.value))
    .mergeIn(['content'], Map({ includeVariables: true, variablesSector: fields.sector.value }))
    .setIn(['content_file', 'read_only'], false)
    .filter(
      (config, fieldName) =>
        !DOCUMENT_TYPE_CONTENT_FIELDS.get(fieldName) ||
        DOCUMENT_TYPE_CONTENT_FIELDS.get(fieldName).contains(fields.type.value || 'HTML')
    );
}


export class CreateTemplateDocument extends PureComponent {
  constructor(props) {
    super(props);
    this.navOnComplete = this.navOnComplete.bind(this);
    this.saveDraft = this.saveDraft.bind(this);
    this.saveDraftButton = this.saveDraftButton.bind(this);
  }

  navOnComplete(model, data, collectionName, formName, newItem) {
    this.props.routePush('/admin/template-documents/' + newItem.id);
  }

  saveDraft(e, data) {
    e.preventDefault();
    if (data.type === 'FILE') {
      this.props.setResponseErrorForKey(
        'createItem',
        ADMIN_TEMPLATE_DOCUMENT_CREATE_MODEL,
        fromJS({type: ['Please set type to HTML to create a draft.']})
      );
    } else {
      this.props.createItemAndRunCallback(
        ADMIN_TEMPLATE_DOCUMENT_DRAFT_MODEL,
        data,
        ADMIN_TEMPLATE_DOCUMENT_CREATE_MODEL,
        null,
        () => this.props.getDraftsCollectionAndNavTo('/admin/template-documents/drafts/')
      );
    }
  }

  saveDraftButton(data, disabled) {
    return (
      <input
        type="submit"
        className="btn btn-primary btn-admin-form"
        onClick={e => this.saveDraft(e, data)}
        disabled={disabled}
        value="Save draft"
      />
    );
  }

  render() {
    return (
      <div>
        <AdminContainer title="Template Documents">
          <AdminCreate
            permsModel={MODELS.TEMPLATE_DOCUMENT}
            controlName={ADMIN_TEMPLATE_DOCUMENT_CREATE}
            collectionName={ADMIN_TEMPLATE_DOCUMENT_CREATE_MODEL}
            model={ADMIN_TEMPLATE_DOCUMENT_CREATE_MODEL}
            defaultValues={{'is_landscape': false, }}
            fields={ADMIN_TEMPLATE_DOCUMENT_CREATE_FIELDS}
            getInitialValues={() => ({'is_landscape': false, 'content': '<p></p>'})}
            title="Add Template Document"
            className="btn-left-align"
            transform={transform}
            transformDataToSubmit={transformDataToSubmit}
            onComplete={this.navOnComplete}
            customSubmitButtons={this.saveDraftButton}
          />
        </AdminContainer>
      </div>
    );
  }
}

export default connect(undefined, {
  routePush: routeActions.push,
  createItemAndRunCallback,
  setResponseErrorForKey,
  getDraftsCollectionAndNavTo
})(CreateTemplateDocument);
