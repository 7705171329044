import React from 'react';
import { connect } from 'react-redux';
import { List, fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { createItemAndCloseModal, uploadFile } from '^/actions/actionSequences';
import { getAllCollection } from '^/actions/collections';
import { TEMPLATES, TASKS } from '^/consts/collectionKeys';

import { transformRepeatOption } from '^/models/task';
import {
  getActivePracticeMembers,
  isLoggedOnUserAdminOfCurrentPractice,
} from '^/stateHelpers';
import { isPending } from '^/consts/responseStates';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import CreateTaskForm from '^/components/app/tasks/manage/CreateTaskForm';

export function transformData(data) {
  return Object.assign(
    {},
    transformRepeatOption(data),
  );
}

export class CreateTask extends PureComponent {
  componentWillMount() {
    if (this.props.isAdmin) {
      this.props.getTemplateDocuments();
    }
  }

  render() {
    const {
      user,
      isAdmin,
      activity,
      createTask,
      response,
      practiceMembers,
      singleAssignee,
      templateDocuments,
      templateDocumentsResponse,
      uploadFileResponse,
      practiceDigitalAuditActionId,
      hideRepeat
    } = this.props;

    const activityId = activity && activity.get('id');

    if (
      isPending(templateDocumentsResponse)
    ) {
      return <Loading />;
    }

    const initialValues = {
      assignees: isAdmin ? [] : [user.getIn(['staffdetail', 'id'])],
      title: '',
      entry: '',
      repeatOption: '',
    };

    return (
      <div>
        <CreateTaskForm
          practiceMembers={practiceMembers}
          initialValues={initialValues}
          templateDocuments={templateDocuments}
          onSubmit={data => {
            createTask(
              transformData(data),
              user,
              activityId,
              practiceDigitalAuditActionId
            );
          }}
          uploadFile={this.props.uploadFile}
          uploadFileResponse={uploadFileResponse}
          singleAssignee={singleAssignee}
          relatedActivityFiles={
            (activity && activity.get('uploaded_activity_files')) || List()
          }
          response={response}
          isRelated={!!activityId}
          hideRepeat={hideRepeat}
        />
      </div>
    );
  }
}

CreateTask.propTypes = {
  practiceMembers: ImmutablePropTypes.list.isRequired,
  templateDocuments: ImmutablePropTypes.iterable.isRequired,
  activity: ImmutablePropTypes.map,
};

export function mapStateToProps(state) {
  return {
    practiceMembers: getActivePracticeMembers(state),
    templateDocuments: state.collections.getIn([TEMPLATES, 'items'], List()),
    user: state.userProfile,
    response: state.responses.getIn(['createItem', TASKS]),
    uploadFileResponse: state.responses.get('uploadFile'),
    isAdmin: isLoggedOnUserAdminOfCurrentPractice(state),
    templateDocumentsResponse: state.responses.getIn([
      'getCollection',
      TEMPLATES,
    ]),
  };
}

function dispatchDefaultCreateTask(
  data,
  user,
  activityId,
  practiceDigitalAuditActionId,
  dispatch
) {
  const transformedTaskData = Object.assign(
    {},
    data,
    {
      created_by: user.getIn(['staffdetail', 'id']),
      assignees: data.assignees,
    },
    activityId && { activity: activityId },
    practiceDigitalAuditActionId && {
      practice_audit_question: practiceDigitalAuditActionId,
    }
  );
  return dispatch(createItemAndCloseModal(TASKS, transformedTaskData, user));
}

function transformFileData(data) {
  const immutableData = fromJS(data);
  return immutableData
    .set(
      'related_files',
      (immutableData.get('related_files') || List()).map(each => each.get('id'))
    )
    .toJS();
}

export function mapDispatchToProps(dispatch, props) {
  // allow for an alternative createTask function to be passed
  // (note if this is action you will need to handle the dispatch yourself)
  const createTask = props.createTask
    ? (data, user, activityId) =>
        props.createTask(transformFileData(data), user, activityId)
    : (data, user, activityId) =>
        dispatchDefaultCreateTask(
          transformFileData(data),
          user,
          activityId,
          props.practiceDigitalAuditActionId,
          dispatch
        );
  return {
    createTask,
    getTemplateDocuments: () => dispatch(getAllCollection(TEMPLATES)),
    uploadFile: (file, form, field) => dispatch(uploadFile(file, form, field)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTask);
