import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';

import {
  dismissAlert,
  loadDashboardActivitiesAndUpdatedTemplates,
  loadDocumentReviewsCount,
} from '^/actions/dashboardActivitiesAndUpdatedTemplates';
import { getCollection, getAllCollection } from '^/actions/collections';
import { openDocumentToReadModal } from '^/actions/modals';
import { ALERTS, DASHBOARD_DOCUMENTS_READ_REQUESTS } from '^/consts/collectionKeys';
import { shouldLoadTaskListAndUpdatedNewTemplates } from '^/consts/reloadRules';
import { isPending } from '^/consts/responseStates';
import { ALERT_TYPES } from '^/models/alerts';
import { isReadOnlyInCurrentPractice } from '^/models/user';
import { isGroupDocument } from '^/utils';
import { practiceHasDocumentsToReadAccess, practiceHasAccessToDocumentReviews } from '^/stateHelpers';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import Icon from '^/components/app/content/Icon';
import ContentTabset from '^/components/app/content/ContentTabset';
import ContentTab from '^/components/app/content/ContentTab';
import DashboardActivity from '^/components/app/dashboard/DashboardActivity';
import AlertList from '^/components/app/alerts/AlertList';
import DashboardDocumentReadRequests from '^/components/app/dashboard/DashboardDocumentReadRequests';
import DocumentReviews from '^/components/app/dashboard/DocumentReviews';

export const DASHBOARD_ACTIVITIES = 'DASHBOARD_ACTIVITIES';
export const UPDATED_NEW_TEMPLATES_COUNT = 'UPDATED_NEW_TEMPLATES_COUNT';
export const DOCUMENT_READ_REQUESTS = 'DOCUMENT_READ_REQUESTS';
export const DOCUMENTS_TO_REVIEW = 'DOCUMENTS_TO_REVIEW';

const DASHBOARD_ACTIVITY_TITLES = {
  DUE_THIS_WEEK: 'Due this week',
  DUE_THIS_MONTH: 'Due this month',
  OVERDUE: 'Outstanding',
  UPDATED_NEW_TEMPLATES_COUNT: 'Updated and new templates',
  DOCUMENT_READ_REQUESTS: 'Documents to read',
  DOCUMENTS_TO_REVIEW: 'Documents to review',
};

const DASHBOARD_ACTIVITY_EMPTY_MESSAGES = {
  DUE_THIS_WEEK: 'No activities due this week.',
  DUE_THIS_MONTH: 'No activities due this month.',
  OVERDUE: 'No outstanding activities.',
  UPDATED_NEW_TEMPLATES_COUNT: 'No updated or new templates.',
  DOCUMENT_READ_REQUESTS: 'You currently have no documents to read.',
  DOCUMENTS_TO_REVIEW: 'You currently have no documents to review.',
};

const DASHBOARD_ACTIVITY_HELPTEXTS = {
  OVERDUE: 'Activities are outstanding if not completed by the start of the following month.',
};

const asCountText = count => count ? ` (${count})` : '';

export class DashboardActivitiesAndUpdatedTemplate extends PureComponent {

  componentWillMount() {
    this.loadDashboardActivityAndUpdatedNewTemplates(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.loadDashboardActivityAndUpdatedNewTemplates(nextProps, this.props);
  }

  loadDashboardActivityAndUpdatedNewTemplates(props, oldProps = {}) {
    if (shouldLoadTaskListAndUpdatedNewTemplates(props, oldProps)) {
      const { practiceId, currentTeamMember } = props;
      this.props.loadDashboardActivitiesAndUpdatedTemplates(practiceId, currentTeamMember && currentTeamMember.get('id'));
      this.loadUpdatedNewTemplates();
      if (props.hasDocumentsToReadAccess) {
        this.loadDocumentReadRequests(practiceId, currentTeamMember && currentTeamMember.get('id'));
      }
      if (props.hasDocumentsToReviewAccess) {
        this.props.loadDocumentReviewsCount();
      }
    }
  }

  titleFor(dashboardActivityName) {
    const { dashboardActivitiesAndUpdatedTemplates } = this.props;
    const count = dashboardActivitiesAndUpdatedTemplates && dashboardActivitiesAndUpdatedTemplates.get(dashboardActivityName, List()).count();
    return DASHBOARD_ACTIVITY_TITLES[dashboardActivityName] + asCountText(count);
  }

  renderDashboardActivities(activities, emptyMessage) {
    if (activities.isEmpty()) {
      return (
        <div className="help-block mb-1">
          {emptyMessage}
        </div>
      );
    }

    return (
      <DashboardActivity activities={activities} />
    );
  }

  renderDashboardDocumentReadRequests(documentReadRequests, emptyMessage, openDocReadModal, canReadRequests) {
    if (documentReadRequests.isEmpty()) {
      return (
        <div className="help-block mb-1">
          {emptyMessage}
        </div>
      );
    }

    return (
      <DashboardDocumentReadRequests
        openDocReadModal={openDocReadModal}
        readRequests={documentReadRequests}
        canReadRequests={canReadRequests}
      />
    );
  }

  renderDashboardDocumentsToReview(documentReviews, emptyMessage) {
    if (documentReviews.isEmpty()) {
      return (
        <div className="help-block mb-1">
          {emptyMessage}
        </div>
      );
    }

    return (
      <DocumentReviews documentReviews={documentReviews} />
    );
  }

  loadUpdatedNewTemplates() {
    this.props.getCollection(ALERTS, { filters: { type: ALERT_TYPES.NEW_OR_UPDATED_DOCUMENT, }, });
  }

  loadDocumentReadRequests(practiceId, currentTeamMember) {
    this.props.getAllCollection(
      DASHBOARD_DOCUMENTS_READ_REQUESTS,
      practiceId && currentTeamMember && {additionalParams: {practice: practiceId, user: currentTeamMember}}
    );
  }

  loadMoreUpdatedNewTemplates() {
    this.props.getCollection(
      ALERTS,
      {
        filters: { type: ALERT_TYPES.NEW_OR_UPDATED_DOCUMENT, },
        page: this.props.alertsPage + 1,
        shouldAppend: true,
      }
    );
  }

  renderUpdatedNewTemplatesTab(dashboardActivityName, idx) {
    const {
      tabsetName,
      alerts,
      alertsCount,
      alertsResponse,
      hasMoreAlerts,
      isGroupAdmin,
      isReadOnlyUser
    } = this.props;

    const title = DASHBOARD_ACTIVITY_TITLES[dashboardActivityName] + asCountText(alertsCount);
    const loading = isPending(alertsResponse);
    return (
      <ContentTab
        key={idx} tabsetName={tabsetName} tabId={idx} tabName={title}
      >
        {loading ? (
          <Loading />
        ) : (
          <AlertList
            alerts={alerts}
            dismissAlert={id => this.props.dismissAlert(id)}
            isGroupAdmin={isGroupAdmin}
            isReadOnlyUser={isReadOnlyUser}
            hasMoreAlerts={hasMoreAlerts}
            onLoadMore={() => this.loadMoreUpdatedNewTemplates()}
          />
        )}
      </ContentTab>
    );
  }

  renderDashboardActivitiesTab(dashboardActivityName, idx) {
    const { dashboardActivitiesAndUpdatedTemplates, tabsetName, response } = this.props;
    const loading = isPending(response);

    const activities = dashboardActivitiesAndUpdatedTemplates && dashboardActivitiesAndUpdatedTemplates.get(dashboardActivityName, List());
    const title = this.titleFor(dashboardActivityName);
    const helpText = DASHBOARD_ACTIVITY_HELPTEXTS[dashboardActivityName];
    const emptyMessage = DASHBOARD_ACTIVITY_EMPTY_MESSAGES[dashboardActivityName];

    return (
      <ContentTab key={idx} tabsetName={tabsetName} tabId={idx} tabName={title}>
        {helpText && (
          <div className="help-block mb-1">
            <Icon type="lightbulb-o" className="tip-icon" />
            {helpText}
          </div>
        )}

        {(loading || !dashboardActivitiesAndUpdatedTemplates) ? (
          <Loading />
        ) : (
          this.renderDashboardActivities(activities, emptyMessage)
        )}
      </ContentTab>
    );
  }

  renderDashboardDocumentReadRequestsTab(dashboardActivityName, idx) {
    const {
      documentReadRequests,
      tabsetName,
      documentReadRequestsResponse,
      openDocReadModal,
      currentTeamMember,
      user,
    } = this.props;
    const loading = isPending(documentReadRequestsResponse);

    const numberOfRequests = documentReadRequests ? documentReadRequests.count() : 0
    const title = DASHBOARD_ACTIVITY_TITLES[dashboardActivityName] + asCountText(numberOfRequests);
    const helpText = DASHBOARD_ACTIVITY_HELPTEXTS[dashboardActivityName];
    const emptyMessage = DASHBOARD_ACTIVITY_EMPTY_MESSAGES[dashboardActivityName];
    const canReadRequests = (
      (!currentTeamMember || !user) 
      || (currentTeamMember.get('id') === user.get('id'))
    );

    return (
      <ContentTab key={idx} tabsetName={tabsetName} tabId={idx} tabName={title}>
        {helpText && (
          <div className="help-block mb-1">
            <Icon type="lightbulb-o" className="tip-icon" />
          </div>
        )}

        {(loading || !documentReadRequests) ? (
          <Loading />
        ) : (
          this.renderDashboardDocumentReadRequests(documentReadRequests, emptyMessage, openDocReadModal, canReadRequests)
        )}
      </ContentTab>
    );
  }

  renderDashboardDocumentsToReviewTab(dashboardName, idx) {
    const {
      documentReviewsCount,
      tabsetName,
    } = this.props;

    const count = documentReviewsCount && documentReviewsCount.get('document_reviews_count');
    const title = DASHBOARD_ACTIVITY_TITLES[dashboardName] + asCountText(count);
    const helpText = DASHBOARD_ACTIVITY_HELPTEXTS[dashboardName];
    const emptyMessage = DASHBOARD_ACTIVITY_EMPTY_MESSAGES[dashboardName];
    
    return (
      <ContentTab key={idx} tabsetName={tabsetName} tabId={idx} tabName={title}>
        {helpText && (
          <div className="help-block mb-1">
            <Icon type="lightbulb-o" className="tip-icon" />
          </div>
        )}

        <DocumentReviews emptyMessage={emptyMessage} />
      </ContentTab>
    );
  }

  renderTabView(dashboardActivitiesAndUpdatedTemplatesName, idx) {
    switch (dashboardActivitiesAndUpdatedTemplatesName) {
      case UPDATED_NEW_TEMPLATES_COUNT:
        return this.renderUpdatedNewTemplatesTab(dashboardActivitiesAndUpdatedTemplatesName, idx)
      case DOCUMENT_READ_REQUESTS:
        return this.renderDashboardDocumentReadRequestsTab(dashboardActivitiesAndUpdatedTemplatesName, idx)
      case DOCUMENTS_TO_REVIEW:
        return this.renderDashboardDocumentsToReviewTab(dashboardActivitiesAndUpdatedTemplatesName, idx)
      default:
        return this.renderDashboardActivitiesTab(dashboardActivitiesAndUpdatedTemplatesName, idx)
    }
  }

  render() {
    const { tabsetName, hasDocumentsToReadAccess, hasDocumentsToReviewAccess } = this.props;

    const contentTabItems = [
      'DUE_THIS_WEEK',
      'DUE_THIS_MONTH',
      'OVERDUE',
      UPDATED_NEW_TEMPLATES_COUNT,
      ...hasDocumentsToReadAccess ? [DOCUMENT_READ_REQUESTS] : [],
      ...hasDocumentsToReviewAccess ? [DOCUMENTS_TO_REVIEW] : [],
    ]

    return (
      <ContentTabset name={tabsetName} defaultActiveTab={0} className="mb-1">
        {contentTabItems.map((dashboardActivitiesAndUpdatedTemplatesName, idx) =>
          this.renderTabView(dashboardActivitiesAndUpdatedTemplatesName, idx)
        )}
      </ContentTabset>
    );
  }
}

export function mapStateToProps(state) {
  const { userProfile, currentPractice, dashboardActivitiesAndUpdatedTemplates } = state;

  return {
    dashboardActivitiesAndUpdatedTemplates: dashboardActivitiesAndUpdatedTemplates.get('dashboardActivitiesAndUpdatedTemplates'),
    isGroupAdmin: isGroupDocument(currentPractice, userProfile),
    isReadOnlyUser: isReadOnlyInCurrentPractice(userProfile),
    response: state.responses.get('loadDashboardActivitiesAndUpdatedTemplates'),
    alertsResponse: state.responses.getIn(['getCollection', ALERTS]),
    alerts: state.collections.getIn([ALERTS, 'items']),
    alertsCount: dashboardActivitiesAndUpdatedTemplates &&
      dashboardActivitiesAndUpdatedTemplates.getIn(['dashboardActivitiesAndUpdatedTemplates', UPDATED_NEW_TEMPLATES_COUNT]),
    alertsPage: state.collections.getIn([ALERTS, 'page']),
    documentReadRequests: state.collections.getIn([DASHBOARD_DOCUMENTS_READ_REQUESTS, 'items']),
    documentReadRequestsResponse: state.responses.getIn(['getAllCollection', DASHBOARD_DOCUMENTS_READ_REQUESTS]),
    documentReviewsCount: dashboardActivitiesAndUpdatedTemplates &&
      dashboardActivitiesAndUpdatedTemplates.get('documentReviewsCount'),
    hasMoreAlerts: state.collections.getIn([ALERTS, 'hasMore']),
    tabsetName: DASHBOARD_ACTIVITIES,
    currentTeamMember: state.currentTeamMember,
    user: userProfile,
    hasDocumentsToReadAccess: practiceHasDocumentsToReadAccess(state),
    hasDocumentsToReviewAccess: practiceHasAccessToDocumentReviews(state),
  };
}

export default connect(mapStateToProps, {
  dismissAlert,
  loadDashboardActivitiesAndUpdatedTemplates,
  getCollection,
  getAllCollection,
  openDocReadModal: openDocumentToReadModal,
  loadDocumentReviewsCount,
})(DashboardActivitiesAndUpdatedTemplate);
