import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';

import PureComponent from '^/components/common/PureComponent';
import Icon from '^/components/app/content/Icon';
import { formatBackendDate, FORMAT_DATE_PICKER } from '^/utils';

export default class DateInput extends PureComponent {
  componentDidMount() {
    if (this.props.value) {
      this.props.onChange(formatBackendDate(this.props.value));
    }
  }

  handleChange(event) {
    if (!event) {
      return event;
    }

    const newValue = event.target ? event.target.value : event;
    this.props.onChange(formatBackendDate(newValue));
  }

  getValue() {
    const { value } = this.props;
    return value && moment(value).isValid() ? moment(value) : null;
  }

  render() {
    const { showYearDropdown, placeholder, readOnly, className, minDate, maxDate, isClearable, disabled } = this.props;
    const showYear = typeof showYearDropdown === 'undefined' ? true : showYearDropdown;
    const value = this.getValue();

    return (
      <label className={classNames('datepicker-field', className, {disabled})}>
        <DatePicker
          fixedHeight
          showYearDropdown={showYear}
          placeholderText={placeholder || FORMAT_DATE_PICKER}
          readOnly={readOnly}
          disabled={disabled}
          dateFormat={FORMAT_DATE_PICKER}
          openToDate={value}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          isClearable={isClearable || undefined}
          selected={this.getValue()}
          onChange={(newValue) =>this.handleChange(newValue)}
        />
        <Icon type="calendar" />
      </label>
    );
  }

}
