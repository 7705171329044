import React from 'react';
import { connect } from 'react-redux';

import Loading from '^/components/app/content/Loading';
import PureComponent from '^/components/common/PureComponent';
import PracticeMeetingsGroupConfigForm from '^/components/app/groups/group-config/PracticeMeetingsGroupConfigForm';

import { excludeTemplateActivitiesByGroup } from '^/actions/actions';
import { isPending } from '^/consts/responseStates';
import { getAllCollection } from '^/actions/collections';
import { GROUP_PRACTICE_MEETINGS } from '^/consts/collectionKeys';

export class PracticeMeetingsGroupConfigPage extends PureComponent {
  componentDidMount() {
    this.props.getAllCollection(GROUP_PRACTICE_MEETINGS);
  }

  transformAndSubmitData(data) {
    const submitData = Object.entries(data).map(
      item => ({template_activity: item[0], is_excluded: !item[1] })
    );

    this.props.excludeTemplateActivitiesByGroup(submitData);
  }

  render() {
    const { templateMeetings, isLoading, excludeTemplateActivitiesByGroupResponse } = this.props;

    if (isLoading || !templateMeetings) {
      return <Loading />;
    }

    return (
      <PracticeMeetingsGroupConfigForm
        templateMeetings={templateMeetings}
        onSubmit={data => this.transformAndSubmitData(data)}
        response={excludeTemplateActivitiesByGroupResponse}
      />
    );
  }
};

export const mapStateToProps = (state) => {
  return {
    templateMeetings: state.collections.getIn([GROUP_PRACTICE_MEETINGS, 'items']),
    isLoading: isPending(state.responses.getIn(['getCollection', GROUP_PRACTICE_MEETINGS])),
    excludeTemplateActivitiesByGroupResponse: state.responses.get('excludeTemplateActivitiesByGroup'),
  };
};

export default connect(mapStateToProps,
  { getAllCollection, excludeTemplateActivitiesByGroup }
)(PracticeMeetingsGroupConfigPage);

