import React from 'react';
import classNames from 'classnames';
import { reduxForm } from 'redux-form';

import { isPending, hasSucceeded } from '^/consts/responseStates';
import { getIn, formatDate, formatTime } from '^/utils';
import { DOCUMENT_TYPE } from '^/models/documents';

import Field from '^/components/admin/content/edit-create/dynamic-forms/Field';
import {
  PendingMessage, SuccessMessage, FailureMessage
} from '^/components/admin/content/edit-create/dynamic-forms/Messages';
import TemplateDocumentVersionDownloadButton
  from '^/components/admin/template-documents/TemplateDocumentVersionDownloadButton';
import { isAdmin } from '^/models/user'


const ActionButton = ({className, children, onClick, disabled}) =>
  <a className={classNames('btn mr-1 small-text', className)} disabled={disabled} onClick={onClick}>{children}</a>;

const SaveWorkingVersionButton = ({onClick, disabled}) =>
  <ActionButton className="btn-default" onClick={onClick} disabled={disabled}>
    Save as working version
  </ActionButton>;

const PublishCurrentVersionButton = ({onClick}) =>
  <ActionButton className="btn-primary" onClick={onClick}>
    Publish as current version
  </ActionButton>;

const PublishNewVersionButton = ({onClick}) =>
  <ActionButton className="btn-primary" onClick={onClick}>
    Publish as new version
  </ActionButton>;

const DiscardWorkingVersionButton = ({onClick}) =>
  <a className="btn pull-right btn-warning inline" onClick={onClick}>
    Discard
  </a>;

const WorkingVersion = ({templateDocumentVersion, discardWorkingVersion}) => {
  const { working_version } = templateDocumentVersion.toObject();

  if (!working_version || working_version.isEmpty()) {
    return (<noscript />);
  }

  const { id, user, modified } = working_version.toObject();

  const updatedUser = isAdmin(user) ? 'iComply Admin' : user.getIn(['staffdetail', 'full_name'])

  return (
    <div>
      <strong className="mb-1 inline">
        Working version last updated {formatDate(modified)} by {updatedUser}
      </strong>
      <DiscardWorkingVersionButton onClick={() => discardWorkingVersion(id, templateDocumentVersion)} />
    </div>
  );
};


export const EditTemplateDocumentForm = ({
  fields, handleSubmit, fieldConfig, setPendingUploadInForm, formName, transform,
  discardWorkingVersion, values, saveAsWorkingDraft, autosaveWorkingDraft, publishAsNewVersion,
  templateDocumentVersion, saveAsWorkingDraftResponse, publishAsNewVersionResponse,
  publishAsCurrentVersionResponse, publishAsCurrentVersion, autosaveAsWorkingDraftResponse
}) => {
  const isHtml = Boolean(fields.content);
  const hasUnsavedEdits = isHtml && fields.content.initialValue !== fields.content.value;

  if (isHtml) {
    const originalOnChange = fields.content.onChange;
    fields.content.onChange = content => {
      originalOnChange(content);
      autosaveWorkingDraft(Object.assign({}, values, {content}));
    };
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <form className="admin-edit-create-form" onSubmit={() => {}}>
      <h4>
        Edit {templateDocumentVersion.get('template_document_display')}&nbsp;
        {templateDocumentVersion.get('code')}&nbsp;
        (Version {templateDocumentVersion.get('version_number')})
      </h4>
      <WorkingVersion
        templateDocumentVersion={templateDocumentVersion}
        discardWorkingVersion={discardWorkingVersion}
      />
      {fields.content && (
        <div className="relative clearfix mt-1">
          <TemplateDocumentVersionDownloadButton
            documentVersionId={templateDocumentVersion.get('id')}
            documentType={DOCUMENT_TYPE.TEMPLATE}
            hasUnsavedEdits={hasUnsavedEdits}
          />
        </div>
      )}
      {(transform ? transform(fieldConfig, fields) : fieldConfig).map(
        (config, fieldName) => (
          <Field
            key={fieldName}
            field={getIn(fields, fieldName.split('.'))}
            fieldConfig={config}
            className="admin-edit-create-field"
            setUpload={setPendingUploadInForm}
            handleSubmit={handleSubmit}
            formName={formName}
            isEditing
          />
        )
      )}

      {hasSucceeded(autosaveAsWorkingDraftResponse) && (
        <div className="hint">
          Last autosaved {formatTime(autosaveAsWorkingDraftResponse.get('time'))}
        </div>
      )}
      {isPending(autosaveAsWorkingDraftResponse) && (
        <div className="hint">
          Autosaving...
        </div>
      )}

      <div className="mt-1 mb-1">
        {!isPending(saveAsWorkingDraftResponse) && (
          <div>
            { isHtml &&
              <SaveWorkingVersionButton
                onClick={() => saveAsWorkingDraft(values)}
                disabled={!hasUnsavedEdits || isPending(autosaveAsWorkingDraftResponse)}
              />
            }
            <PublishCurrentVersionButton onClick={() => publishAsCurrentVersion(values)} />
            <PublishNewVersionButton onClick={() => publishAsNewVersion(values)} />
          </div>
        )}

        <PendingMessage response={saveAsWorkingDraftResponse}>Saving...</PendingMessage>
        <SuccessMessage response={saveAsWorkingDraftResponse}>Saved successfully!!</SuccessMessage>
        <FailureMessage response={saveAsWorkingDraftResponse}>Failed to save.</FailureMessage>

        <PendingMessage response={publishAsNewVersionResponse}>Publishing...</PendingMessage>
        <SuccessMessage response={publishAsNewVersionResponse}>Published new version successfully!!</SuccessMessage>
        <FailureMessage response={publishAsNewVersionResponse}>Failed to save new version.</FailureMessage>

        <PendingMessage response={publishAsCurrentVersionResponse}>Publishing...</PendingMessage>
        <SuccessMessage response={publishAsCurrentVersionResponse}>
          Published current version successfully!!
        </SuccessMessage>
        <FailureMessage response={publishAsCurrentVersionResponse}>Failed to save.</FailureMessage>
      </div>

    </form>
  );
};

export default reduxForm({})(EditTemplateDocumentForm);
