import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import PureComponent from '^/components/common/PureComponent';
import LiveButton from '^/components/app/forms/LiveButton';
import { restorePolicyAndOverviewDocuments } from '^/actions/actions';


export class RestorePolicyAndOverviewDocuments extends PureComponent {
  handleClick() {
    if (window.confirm(`
Please confirm that you want to restore all documents for ${this.props.practice.get('name')}.

This action cannot be undone and all versions of the document, other than the original template, will be deleted.
    `)) {
      this.props.restorePolicyAndOverviewDocuments(this.props.practice.get('id'));
    }
  }

  render() {
    return (
      <LiveButton
        pendingMessage="Restoring..."
        response={this.props.response}
        className="btn btn-primary"
        onClick={() => this.handleClick()}
        successContent={<p className="alert-success">Policy & Overview Documents Restored Successfully!</p>}
      >
        Restore all policy & Overview type documents
      </LiveButton>
    );
  }

}

export const mapStateToProps = (state) => ({
  response: state.responses.get('restorePolicyAndOverviewDocuments')
});

export default connect(mapStateToProps, { restorePolicyAndOverviewDocuments })(RestorePolicyAndOverviewDocuments);
