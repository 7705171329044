//eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {}

// Stop IE exploding
if (!window.console) {
  window.console = {
    log: noop,
    warn: noop,
    info: noop,
    error: noop,
    debug: noop
  };
}
