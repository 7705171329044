import React from 'react';

const AdminFormError = ({errors}) => (
  <div className="text-error">
    {errors && errors.map(error =>
      <p key={error}>{error}</p>
    )}
  </div>
);

export default AdminFormError;
