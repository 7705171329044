import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { expandUiComponent, collapseUiComponent } from '^/actions/actions';
import { patchItemSetUpdatingForm } from '^/actions/actionSequences';
import { isPending } from '^/consts/responseStates';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';


export class EditableFieldChoices extends PureComponent {
  componentWillMount() {
    this.props.onCancel();
  }

  componentWillReceiveProps(newProps) {
    const { object, model, fieldName } = this.props;
    if (object !== newProps.object || model !== newProps.model || fieldName !== newProps.fieldName) {
      this.props.onCancel();
    }
  }

  render() {
    const {isLoading, isEditing, value, choices, onUpdate, onCancel, startEditing} = this.props;
    return isEditing ? (
      <div className="editable-field">
        <select defaultValue={value} onChange={evt => onUpdate(evt.target.value)}>
          {choices.map((_value, key) =>
            <option key={key} value={key}>{_value}</option>
          ).valueSeq()}
        </select>

        {isLoading && <Loading />}

        <a
          className="inline-edit"
          onClick={onCancel}
        >
          Cancel
        </a>

      </div>
    ) : (
      <div className="editable-field">
        <span>{choices.get(value, 'None')}</span> <a
          className="inline-edit"
          onClick={startEditing}
        >Change</a>
      </div>
    );
  }
}

EditableFieldChoices.propTypes = {
  object: ImmutablePropTypes.map.isRequired,
  model: React.PropTypes.string.isRequired,
  fieldName: React.PropTypes.string.isRequired,
};

export const name = props => `EDITABLE_FIELD.${props.model}.${props.fieldName}`;

export function mapStateToProps(state, props) {
  const { valuePath, object } = props;
  const value = valuePath ? object.getIn(valuePath) : object.get(props.fieldName);

  return {
    value,
    isEditing: state.ui.get('expandedUiComponents').contains(name(props)),
    isLoading: isPending(state.responses.getIn(['updateItem', props.model])),
  };
}

export function mapDispatchToProps(dispatch, props) {
  return {
    startEditing: () => dispatch(expandUiComponent(name(props))),
    onCancel: () => dispatch(collapseUiComponent(name(props))),
    onUpdate: value => {
      dispatch(patchItemSetUpdatingForm(props.model, props.object.get('id'), {[props.fieldName]: value}));
      dispatch(collapseUiComponent(name(props)));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (EditableFieldChoices);
