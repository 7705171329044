import React from 'react';
import Select from 'react-select';
import PureComponent from '^/components/common/PureComponent';
import { SectorTypes } from '^/consts/sector';

const sectorOptions = Array.from(Object.entries(SectorTypes).map(
  item => ({value: item[0], label: item[0] })
));


export default class SectorFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.filterBySector = this.filterBySector.bind(this);
  }

  filterBySector(event) {
    const value = event ? event.value : ""
    this.props.filterBySector(value);
  }

  render() {
    return (
      <div>
        <Select
          className="small-text"
          options={sectorOptions}
          name={"Sector"}
          placeholder={"Filter by sector..."}
          onChange={this.filterBySector}
          searchable={false}
          value={this.props.sector}
          clearable
        />
      </div>
    );
  }
}

SectorFilter.propTypes = {
  filterBySector: React.PropTypes.func,
  sector: React.PropTypes.string,
};
