import React from 'react';
import { connect } from 'react-redux';
import { reset as resetForm } from 'redux-form';
import { Map } from 'immutable';

import { isLoggedOnUserPracticeAdmin } from '^/stateHelpers';
import { USERS, MANAGED_PRACTICES, ROLES, PRACTICES } from '^/consts/collectionKeys';
import { changeTeamPracticeSelect } from '^/actions/actions';
import { getAllCollection } from '^/actions/collections';

import AddNewTeamMemberToPracticeForm from '^/components/app/users/forms/AddNewTeamMemberToPracticeForm';
import ChooseExistingTeamMember from '^/components/app/users/modals/ChooseExistingTeamMember';
import ContentTab from '^/components/app/content/ContentTab';
import SuccessMessage from '^/components/app/content/SuccessMessage';
import ContentTabset from '^/components/app/content/ContentTabset';
import Loading from '^/components/app/content/Loading';
import PureComponent from '^/components/common/PureComponent';
import { FORM_NAME as EXISTING_MEMBERS_FORM_NAME } from '^/components/app/users/forms/ChooseExistingTeamMemberForm';

export const TABSET = 'ADD_CHOOSE_TEAM_MEMBERS_TABSET';


export class AddNewOrExistingTeamMemberTabset extends PureComponent {

  componentWillMount() {
    const { adminPractices } = this.props;

    if (!adminPractices) {
      this.props.getAllCollection(MANAGED_PRACTICES);
    }

    this.props.getAllCollection(ROLES);
  }

  changePractice(id) {
    this.props.resetForm(EXISTING_MEMBERS_FORM_NAME);
    this.props.changeTeamPracticeSelect(id);
  }

  submitExistingMembers(data) {
    const newMembers =
      Map(data)
        .filter(val => val)
        .map((_, id) => id)
        .toArray();

    this.props.onSubmitExistingMembers(newMembers);
    this.props.resetForm(EXISTING_MEMBERS_FORM_NAME);
  }

  render() {
    const {
      adminPractices,
      currentPractice,
      addNewMemberResponse,
      onSubmitNewMember,
      className,
      selectedPracticeId,
      userId,
      addExistingMemberResponse,
      isPracticeAdmin,
    } = this.props;

    if (!adminPractices || !currentPractice) {
      return <Loading className="mt-1" />;
    }

    const currentPracticeId = currentPractice.get('id');

    const isAdmin =
      adminPractices
        .some(practice => practice.get('id') === currentPracticeId);

    const isAdminThisAndOthers =
      isAdmin &&
      adminPractices
        .filterNot(practice => practice.get('id') === currentPracticeId)
        .size > 0;

    return (
      <ContentTabset name={TABSET} defaultActiveTab={0} className={className}>
        <ContentTab tabsetName={TABSET} tabId={0} tabName="Add New Team Member">
          <AddNewTeamMemberToPracticeForm
            onSubmit={onSubmitNewMember}
            response={addNewMemberResponse}
            isPracticeAdmin={isPracticeAdmin}
          />

          <SuccessMessage response={addNewMemberResponse}>
            Team Member added!
          </SuccessMessage>
        </ContentTab>

        {isAdminThisAndOthers &&
          <ContentTab tabsetName={TABSET} tabId={1} tabName="Choose Existing Team Member(s)">
            <ChooseExistingTeamMember
              userId={userId}
              response={addExistingMemberResponse}
              adminPractices={adminPractices}
              currentPractice={currentPractice}
              selectedPracticeId={selectedPracticeId}
              onSubmitExistingMembers={data => this.submitExistingMembers(data)}
              onChangePractice={id => this.changePractice(id)}
            />

            <SuccessMessage response={addExistingMemberResponse}>
              Successfully added!
            </SuccessMessage>
          </ContentTab>
         }
      </ContentTabset>
    );
  }
}


export function mapStateToProps(state) {
  return {
    userId: state.userProfile.get('id'),
    isPracticeAdmin: isLoggedOnUserPracticeAdmin(state),
    selectedPracticeId: state.ui.get('teamMemberPracticeSelect'),
    currentPractice: state.currentPractice,
    adminPractices: state.collections.getIn([MANAGED_PRACTICES, 'items']),
    addNewMemberResponse: state.responses.getIn(['createItem', USERS]),
    addExistingMemberResponse: state.responses.getIn(['updateItem', PRACTICES]),
  };
}

export default connect(
  mapStateToProps,
  {
    getAllCollection,
    changeTeamPracticeSelect,
    resetForm,
  }
)(AddNewOrExistingTeamMemberTabset);
