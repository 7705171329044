import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { logout } from '^/actions/actions';
import { makeStaticPath } from '^/utils';
import Icon from '^/components/app/content/Icon';
import PureComponent from '^/components/common/PureComponent';


export class Header extends PureComponent {
  render() {
    const { userProfile } = this.props;

    return (
      <div className="admin-header">
        <div className="admin-logo-strip">
          <div className="wrapper relative">
            <div className="row">
              <Link className="brand" to="/admin/">
                <img src={makeStaticPath('images/brand/logo-color.svg')} className="admin-brand-logo" />
              </Link>
            </div>
          </div>
        </div>
        <div className="admin-header-strip">
          <div className="wrapper relative">
            <div className="row">
              <div className="admin-header-content col-1-4-offset col-3-4">
                <span>
                  iCOMPLY ADMIN SYSTEM
                </span>
                <span className="pull-right">
                  {userProfile.getIn(['staffdetail', 'full_name']) || userProfile.get('role')}
                  <a onClick={() => this.props.logout()} className={'header-link'}>
                    logout <Icon type="sign-out" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    userProfile: state.userProfile,
  };
}

export default connect(
  mapStateToProps,
  { logout }
)(Header);
