import React from 'react';
import { TEMPLATE_VIEW_BY_TEXT } from '^/consts/templateViewBy';


const TemplatesDropdown = ({viewByOptions, setViewBy, value}) => (
  <h5 className="inline">
    View by <select
      onChange={evt => setViewBy(evt.target.value)}
      value={value}
    >
      {Object.keys(viewByOptions).map(viewBy =>
        <option key={viewBy} value={viewBy}>
          {TEMPLATE_VIEW_BY_TEXT[viewBy]}
        </option>
      )}
    </select>
  </h5>
);

export default TemplatesDropdown;
