import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { formatChangeType, formatDate } from '^/utils';
import moment from 'moment';
import * as constants from '^/components/app/groups/reports/constants';

import { collapseUiComponent, expandUiComponent } from '^/actions/actions';
import { formatTaskRepeatInterval } from '^/models/task';

function getEventDescription(eventItem) {
  const type = eventItem.get('type');
  switch (type) {
    // Users
    case constants.USER_INVITED:
      return `Invited ${eventItem.get('user_full_name')} (${eventItem.get(
        'user_email'
      )})`;
    case constants.USER_INVITE_REMOVED:
      return `Removed invite for ${eventItem.get(
        'user_full_name'
      )} (${eventItem.get('user_email')})`;
    case constants.USER_ACCEPTED:
      return `${eventItem.get('active_user_full_name')} (${eventItem.get(
        'active_user_email'
      )}) accepted invite`;
    case constants.USER_UPDATED:
      return `Updated user ${eventItem.get('user_full_name')} (${eventItem.get(
        'user_email'
      )}). Fields updated: ${eventItem.get('user_updated_fields')}`;
    case constants.USER_RETIRED:
      return `Retired user ${eventItem.get('user_full_name')} (${eventItem.get(
        'user_email'
      )})`;
    case constants.USER_SUSPENDED:
      return `Suspended user ${eventItem.get(
        'user_full_name'
      )} (${eventItem.get('user_email')})`;
    case constants.USER_REJECTED:
      return `${eventItem.get('active_user_full_name')} (${eventItem.get(
        'active_user_email'
      )}) rejected invite`;

    // Activities
    case constants.ACTIVITY_COMPLETED:
      return `Completed activity "${eventItem.get('item_name')}"`;
    case constants.ACTIVITY_RESCHEDULED:
      return `Rescheduled activity "${eventItem.get(
        'item_name'
      )}" from ${formatDate(eventItem.get('from_date'))} to ${formatDate(
        eventItem.get('to_date')
      )}`;
    case constants.ACTIVITY_REASSIGNED:
      return `Reassigned activity "${eventItem.get(
        'item_name'
      )}" from ${eventItem.get('from_user_full_name')} (${eventItem.get(
        'from_user_email'
      )}) to ${eventItem.get('to_user_full_name')} (${eventItem.get(
        'to_user_email'
      )})`;
    case constants.ACTIVITY_FILE_UPLOADED:
      return `Uploaded file "${eventItem.get(
        'uploaded_file_name'
      )}" to activity "${eventItem.get('item_name')}"`;

    // Responsibilities
    case constants.RESPONSIBILITY_UPDATED:
      return `Updated responsibility "${eventItem.get(
        'item_name'
      )}" from "${eventItem.get('from_user_full_name')}" (${eventItem.get(
        'from_user_email'
      )}) to "${eventItem.get('to_user_full_name')}" (${eventItem.get(
        'to_user_email'
      )})`;

    // Tasks
    case constants.TASK_CREATED:
      return `Created task "${eventItem.get('item_name')}"`;
    case constants.TASK_RESCHEDULED:
      return `Rescheduled task "${eventItem.get(
        'item_name'
      )}" from ${formatDate(
        eventItem.get('from_date')
      )} (${formatTaskRepeatInterval(
        eventItem.get('from_repeat'),
        eventItem.get('from_repeat_interval')
      )}) to ${formatDate(
        eventItem.get('to_date')
      )} (${formatTaskRepeatInterval(
        eventItem.get('to_repeat'),
        eventItem.get('to_repeat_interval')
      )})`;
    case constants.TASK_ASSIGNEES_CHANGED:
      const changeType = formatChangeType(eventItem.get('change_type'));
      return `${changeType} assignee ${eventItem.get('user_full_name')} ${
        eventItem.get('change_type') === 'ADDED' ? 'to' : 'from'
      } task "${eventItem.get('item_name')}"`;
    case constants.TASK_DELETED:
      return `Deleted task "${eventItem.get('item_name')}"`;
    case constants.TASK_ASSIGNEE_COMPLETED:
      const completed = eventItem.get('task_assignees_completed');
      const total = eventItem.get('task_assignees_total');
      const userFullName = eventItem.get('user_full_name');
      return `${userFullName} completed task "${eventItem.get(
        'item_name'
      )}" (${completed} of ${total} assignees completed)`;

    // Documents
    case constants.DOCUMENT_VERSION_UPDATED:
      return `Updated version of document "${eventItem.get(
        'item_name'
      )}" to version ${eventItem.get('document_version_number')}`;
    case constants.DOCUMENT_EDITED:
      return `Edited document "${eventItem.get(
        'item_name'
      )}" (Document code: ${eventItem.get('document_code')})`;
    case constants.DOCUMENT_COPY_CREATED:
      return `Created copy of document "${eventItem.get(
        'item_name'
      )}" (Document code: ${eventItem.get('document_code')})`;

    default:
      return eventItem.get('summary') || type.replace('_', ' ').toLowerCase();
  }
}

function EventItem({ eventItem }) {
  const eventTime = moment(eventItem.get('created'))
    .utc()
    .format('HH:mm');
  const eventDescription = getEventDescription(eventItem);

  return (
    <p className="mt-1">
      <span className="bolder-text">{eventTime}</span>
      <span className="ml-1">{eventDescription}</span>
    </p>
  );
}

export function ExpandableSection({
  isExpanded,
  expand,
  collapse,
  events,
  groupingKey,
  practiceName,
  summary,
}) {
  return (
    <div className="insights-card mt-1-2">
      <button
        className={classNames(
          'no-border',
          'pl-0',
          'collapse-button',
          'display-flex',
          'flex-align-items-center',
          'text-left',
          'focus-outline-none',
          'focus-underline',
          'medium-text',
          !isExpanded && 'collapsed'
        )}
        onClick={isExpanded ? collapse : expand}
      >
        <span
          className="width-200px flex-shrink-0 text-overflow-ellipsis bolder-text"
          title={practiceName}
        >
          {practiceName}
        </span>
        <span
          className="flex-grow text-overflow-ellipsis pl-2 pr-2 light-text"
          title={summary}
        >
          {summary}
        </span>
      </button>
      {isExpanded && events && events.size > 0 && (
        <div className="pl-1 pr-1 pb-1 background-lightest-gray mt-1 base-border-radius">
          {events.map((eventItem, index) => (
            <EventItem key={`${groupingKey}-${index}`} eventItem={eventItem} />
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state, props) {
  return {
    isExpanded: state.ui
      .get('expandedUiComponents')
      .contains(props.groupingKey),
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    expand: () => dispatch(expandUiComponent(props.groupingKey)),
    collapse: () => dispatch(collapseUiComponent(props.groupingKey)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableSection);
