import React from 'react';
import { connect } from 'react-redux';

import { selectTotalHRDashboardUrl } from '^/selectors/hr';
import { AGILO_ILEARN_URL } from '^/consts/hr';
import { makeStaticPath } from '^/utils';

const TotalHRPanel = ({ iTeamUrl }) => (
  <div>
  <a href={iTeamUrl} target="_blank" className="small-text">
    <img src={makeStaticPath('images/brand/agilio-iteam-logo.svg')} alt="agilio-iteam-logo" className="display-block" />
    <span className="display-block mb-1-2">
      Go to Agilio iTeam &gt;
    </span>
  </a>
  <hr className="thin hidden-m mt-1 mb-1" />
  <a href={AGILO_ILEARN_URL} target="_blank" className="small-text">
    <img src={makeStaticPath('images/brand/agilio-ilearn-logo.svg')} alt="agilio-ilearn-logo" className="display-block" />
    <span className="display-block">
      Go to Agilio iLearn &gt;
    </span>
  </a>
  </div>
);

TotalHRPanel.propTypes = {
  iTeamUrl: React.PropTypes.string,
};

export { TotalHRPanel };

function mapStateToProps(state) {
  return {
    iTeamUrl: selectTotalHRDashboardUrl(state),
  };
}

export default connect(mapStateToProps)(TotalHRPanel);
