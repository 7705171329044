import React from 'react';
import { connect } from 'react-redux';

import { isPending } from '^/consts/responseStates';
import { closeModal } from '^/actions/modals';
import { archiveDocumentFolderAndCloseModal } from '^/actions/actionSequences';
import { getFolderUploadedActivityTaskFilesCount } from '^/actions/groupDocuments';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';


export class DeleteFolderConfirmationModal extends PureComponent {
  componentWillMount() {
    const { folderId } = this.props;
    this.props.getFolderUploadedActivityTaskFilesCount(folderId);
  }

  render() {
    const { folderId, folderName, hasGroupDocuments, uploadedFilesCount, onClose, onDelete, uploadedFilesCountResponse } = this.props;

    if (isPending(uploadedFilesCountResponse)) {
      return <Loading />;
    }

    return (
      <div>
        <div className="row">
          <div className="col-1">
            {hasGroupDocuments ? (
              <p>
                You cannot delete the {folderName} folder because it has documents in it. Delete/move all documents in this folder to delete the folder.
              </p>
            ) : (
              <div>
                <p>{uploadedFilesCount > 0 && `This folder has ${uploadedFilesCount} uploaded files. Deleting this folder will move all uploaded files to the MISC folder.`}</p>
                <p>Are you sure you wish to delete this folder: {folderName}</p>
              </div>
            )}
          </div>
        </div>
        <div className="row align-right mt-1">
          <div className="col-1">
            <button className="btn btn-default" onClick={onClose}>
              Cancel
            </button>
            {!hasGroupDocuments && (
              <button className="btn btn-warning ml-1" onClick={() => onDelete(folderId)}>
                Delete
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    uploadedFilesCount: state.groupDocuments.getIn(['folderUploadedActivityTaskFilesCount', 'uploaded_files_count']),
    uploadedFilesCountResponse: state.responses.get('folderUploadedActivityTaskFilesCount'),
  }
}

export default connect(
  mapStateToProps, 
  {
    onDelete: archiveDocumentFolderAndCloseModal,
    onClose: closeModal,
    getFolderUploadedActivityTaskFilesCount,
  }
)(DeleteFolderConfirmationModal);
