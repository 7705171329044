import { fromJS } from 'immutable';

import * as actions from '^/actions/actions';
import { LOGOUT_URL } from '^/consts/single-sign-on';
import { COUNTRIES } from '^/models/practice';
import { isAdmin } from '^/models/user';
import UsernameStore from '^/UsernameStore';

export function maybeInitializePendo(user) {
  if (!window.PENDO_KEY || isAdmin(user) || user.get('is_impersonate')) {
    return;
  }
  global.pendo.initialize({
    visitor: {
      id: window.USER_AP_REFERENCE,
      icomply_roles: user.getIn(['staffdetail', 'roles']).map(role => role.get('name')).toArray(),
      icomply_user_level: user.get('user_type_in_current_practice'),
    },
    account: {
      id: user.getIn(['staffdetail', 'current_practice', 'code_reference']),
      name: user.getIn(['staffdetail', 'current_practice', 'name']),
      locale: COUNTRIES.get(user.getIn(['staffdetail', 'current_practice', 'country']))
    }
  });
}

export default () => next => action => {
  switch (action.type) {
    case actions.LOGIN.SUCCESS:
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ 'user_id': action.payload.user_id });
      break;

    case actions.SAVE_USERNAME:
      if (action.payload.rememberDetails) {
        UsernameStore.set(action.payload.email);
      }
      break;

    case actions.CLEAR_USERNAME:
      UsernameStore.clear();
      break;

    case actions.LOGOUT.SUCCESS:
      UsernameStore.clear();
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ 'user_id': undefined });
      break;

    case actions.GET_USER_INFO.SUCCESS:
      const user = fromJS(action.payload);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ 'user_id': user.get('id') });

      maybeInitializePendo(user);

      break;
  }

  if (
    action.type &&
    typeof action.type === 'string' &&
    action.type.endsWith('_FAILURE') &&
    action.error &&
    (action.payload.status === 401 || action.payload.status === 403) &&
    action.payload.response.detail === 'Authentication credentials were not provided.'
  ) {
    window.onbeforeunload = null;
    return window.location.replace(LOGOUT_URL);
  }

  return next(action);
};
