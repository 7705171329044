import React from 'react';
import classNames from 'classnames';
import { hasSucceeded } from '^/consts/responseStates';

export const SuccessMessage = ({children, response, className}) =>
  hasSucceeded(response) ?
    <div className={classNames('alert alert-success', className)}>{children}</div> :
    <noscript />;

export default SuccessMessage;
