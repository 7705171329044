import React from 'react';
import { reduxForm } from 'redux-form';

import LiveButton from '^/components/app/forms/LiveButton';
import PracticeComplianceDetailsForm, { getComplianceDetailsFields } from '^/components/app/practices/forms/PracticeComplianceDetailsForm';

export const FORM_NAME = 'setupCyclePracticeDetails';

export function getCycleSetupComplianceDetailsFormFields(practice) {
  return getComplianceDetailsFields(practice);
}

export const CycleSetupComplianceDetailsForm = ({fields, practice, handleSubmit, response}) =>
  <form onSubmit={handleSubmit}>
    <PracticeComplianceDetailsForm fields={fields} practice={practice} />
    <LiveButton pendingMessage="Saving..." response={response}>Next...</LiveButton>
  </form>;

export default reduxForm({
  form: FORM_NAME,
})(CycleSetupComplianceDetailsForm);
