import React from 'react';
import { Editor as TinyMCE } from '@tinymce/tinymce-react';

import { getConfig } from '^/consts/tinyMCE';

export const Editor = ({content, isLandscape, setEditorContent, height}) => (
  <div className="mb-1">
    <TinyMCE
      id="bottomEditor"
      initialValue={content}
      init={Object.assign({}, getConfig(isLandscape), {height: height, selector: 'textarea#bottomEditor'})}
      onChange={(evt, ed) => setEditorContent(ed.getContent())}
    />
  </div>
);

Editor.defaultProps = {
  height: 300
};

export default Editor;
