import React from 'react';

import PureComponent from '^/components/common/PureComponent';
import NoAuth from '^/components/app/users/login/NoAuth';
import LoggedOutLogo from '^/components/app/users/LoggedOutLogo';

export default class InvalidInvite extends PureComponent {
  render() {
    return (
      <div>
        <LoggedOutLogo />
        <NoAuth>
          <h1 className="centered">Invalid Invite</h1>
          <h3 className="centered">
            This invite is invalid or has already been redeemed. Please ask your
            practice admin to resend the invite.
            <br />
            Click the logo to return to iComply.
          </h3>
        </NoAuth>
      </div>
    );
  }
}
