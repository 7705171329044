import React from 'react';
import { connect } from 'react-redux';

import { updateGroupTemplateDocumentReloadAndCloseModal } from '^/actions/groupDocuments';
import { getAllCollection } from '^/actions/collections';
import Loading from '^/components/app/content/Loading';
import GroupTemplateDocumentEditForm from '^/components/app/groups/group-documents/GroupTemplateDocumentEditForm';
import PureComponent from '^/components/common/PureComponent';
import {
  TEMPLATE_CATEGORIES,
  TEMPLATE_FOLDERS,
} from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';
class GroupTemplateDocumentEditModal extends PureComponent {

  constructor(props) {
    super(props);
    this.onUpdate = this.onUpdate.bind(this);
  }

  componentDidMount() {
    this.props.getAllCollection(TEMPLATE_CATEGORIES);
    this.props.getAllCollection(TEMPLATE_FOLDERS);
  }

  render() {
    const { templateDocument, isLoading } = this.props;

    if (isLoading) {
      return (
        <div className="p-1">
          <Loading />
        </div>
      );
    }

    return (
      <div className="p-1 clearfix">
        <GroupTemplateDocumentEditForm
          onSubmit={this.onUpdate}
          initialValues={{
            name: templateDocument.get('name'),
            code: templateDocument.get('code'),
            priority: templateDocument.get('priority'),
            folder: templateDocument.getIn(['folder', 'id']),
            template_category: templateDocument.getIn(['template_category', 'id']),
            kind: templateDocument.get('kind'),
            enable_doc_reads: templateDocument.get('enable_doc_reads'),
          }}
        />
      </div>
    );
  }

  onUpdate(formData) {
    this.props.updateGroupTemplateDocumentReloadAndCloseModal(
      this.props.templateDocument.get('id'),
      formData,
    );
  }
}

export { GroupTemplateDocumentEditModal as UnconnectedGroupTemplateDocumentEditModal };

function mapStateToProps(state) {
  const loadFoldersResponse = state.responses.getIn(['getCollection', TEMPLATE_FOLDERS]);
  const loadCategoriesResponse = state.responses.getIn(['getCollection', TEMPLATE_CATEGORIES]);
  return {
    isLoading: isPending(loadFoldersResponse) || isPending(loadCategoriesResponse),
  };
}

export default connect(mapStateToProps, {
  getAllCollection,
  updateGroupTemplateDocumentReloadAndCloseModal,
})(GroupTemplateDocumentEditModal);
