import React from 'react';
import { List, OrderedMap } from 'immutable';
import moment from 'moment';
import ExpandableSection from './ExpandableSection';

const DateGroupedEvents = ({ data }) => {
  const dateGroupedData = data.reduce((dateGroups, eventGroup) => {
    const dateGroupKey = moment
      .utc(eventGroup.get('first_event_timestamp'))
      .format('YYYY-MM-DD');
    return dateGroups.update(dateGroupKey, (dateGroup = List()) => {
      return dateGroup.push(eventGroup);
    });
  }, OrderedMap());

  return (
    <div>
      {dateGroupedData.map((eventGroups, dateKey) => {
        const formattedDate = moment.utc(dateKey).format('dddd, Do MMMM YYYY');

        return (
          <div key={dateKey} className="mb-2">
            <p className="m-1 bolder-text">{formattedDate}</p>
            <div className="pl-1">
              {eventGroups.map((eventGroup, idx) => {
                return (
                  <ExpandableSection
                    key={`${eventGroup.get('date_practice_type_user')}_${idx}`}
                    groupingKey={eventGroup.get('date_practice_type_user')}
                    events={eventGroup.get('events')}
                    practiceName={eventGroup.get('practice_name')}
                    summary={eventGroup.get('summary')}
                    hideDate
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DateGroupedEvents;
