import { fromJS } from 'immutable';

const TEMPLATE_ACTIVITY_PAGE_ID = 'admin/template-documents/${id}';

export const ADMIN_HEALTHCHECK_QUESTION_CREATE = 'ADMIN_HEALTHCHECK_QUESTION_CREATE';
export const ADMIN_HEALTHCHECK_QUESTION_MODEL = 'admin/healthcheck/questions';
export const ADMIN_HEALTHCHECK_QUESTION_CREATE_FIELDS = fromJS([
  'question_text', 'action_text', 'area',
]);

export const ADMIN_HEALTHCHECK_QUESTION_MANAGE_ROUTE = '/admin/healthcheck/question/${id}';


export const ADMIN_HEALTHCHECK_ROW_FIELDS = fromJS([
  {name: ['area_display'], display_name: 'Area', type: 'string', sortable: true},
  {name: ['position'], display_name: 'Position', type: 'string', sortable: true},
  {name: ['question_text',], display_name: 'Question Text', type: 'number', sortable: false},
  {name: ['action_text'], display_name: 'Action Text', type: 'string', sortable: false},
  {display_name: 'Actions', type: 'detail', link_text: 'Edit', route: ADMIN_HEALTHCHECK_QUESTION_MANAGE_ROUTE},
  {name: ['id'], display_name: 'Delete', type: 'remove', sortable: false},
]);

export const ADMIN_HEALTHCHECK_QUESTION_EDIT_FIELDS = fromJS([
  'question_text', 'action_text', 'area', 'position'
]);

export const ADMIN_HEALTHCHECK_QUESTION_OPTIONS = 'ADMIN_HEALTHCHECK_QUESTION_OPTIONS';

export const ADMIN_HEALTHCHECK_QUESTION_DOCUMENT_ROW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'detail', route: TEMPLATE_ACTIVITY_PAGE_ID, sortable: true},
  {name: ['template_category_name'], display_name: 'Category', type: 'string', sortable: true},
  {name: ['code'], display_name: 'Code', type: 'string', sortable: true},
  {name: ['folder_display'], display_name: 'Folder Number', type: 'string', sortable: true},
  {name: ['version_number'], display_name: 'Version', type: 'string', sortable: true},
  {name: ['is_print_recommended'], display_name: 'Print', type: 'boolean', sortable: true},
  {name: ['id'], display_name: 'Remove', type: 'remove', sortable: false},
]);


export const HEALTHCHECK_QUESTION_HIDE_RULE = 'HEALTHCHECK_QUESTION_HIDE_RULE';
export const ADMIN_HEALTHCHECK_QUESTION_HIDE_RULES_MODEL = 'admin/healthcheck/question-hide-rules';

export const ADMIN_HEALTHCHECK_QUESTION_HIDE_RULE_ROW_FIELDS = fromJS([
  {name: ['attribute_display'], display_name: 'Attribute', type: 'string', sortable: true},
  {name: ['attribute_value'], display_name: 'Attribute Value', type: 'boolean', sortable: true},
  {name: ['id'], display_name: 'Remove', type: 'remove'},
]);

export const ADMIN_HEALTHCHECK_QUESTION_HIDE_RULE_CREATE_FIELDS = fromJS(['attribute', 'attribute_value']);

export const HEALTHCHECK_QUESTION_REGION_HIDE_RULE = 'HEALTHCHECK_QUESTION_REGION_HIDE_RULE';
export const ADMIN_HEALTHCHECK_QUESTION_REGION_HIDE_RULES_MODEL = 'admin/healthcheck/question-region-hide-rules';
export const ADMIN_HEALTHCHECK_QUESTION_REGION_HIDE_RULE_ROW_FIELDS = fromJS([
  {name: ['region_display'], display_name: 'Region', type: 'string', sortable: true},
  {name: ['id'], display_name: 'Remove', type: 'remove'},
]);

export const ADMIN_HEALTHCHECK_QUESTION_REGION_HIDE_RULE_CREATE_FIELDS = fromJS(['region']);
