import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import {
  getRepeatOptions,
  toOptionValue,
  transformRepeatOption,
} from '^/models/task';
import Loading from '^/components/app/content/Loading';
import SortIcon from '^/components/common/content/SortIcon';
import {
  openManageGroupTaskModal,
  openArchiveGroupTaskModal,
} from '^/actions/modals';
import { getCollection } from '^/actions/collections';
import { isPending } from '^/consts/responseStates';
import { GROUP_TASKS } from '^/consts/collectionKeys';

const ACTIVE = 'ACTIVE';
const ARCHIVED = 'ARCHIVED';
const DEFAULT_PAGE = 1;
const FILTER_KEYS = {
  REPEAT: 'repeat',
  REPEAT_INTERVAL: 'repeat_interval',
  ARCHIVED: 'is_archived',
  RESPONSIBILITY: 'responsibility',
  NONE: 'None',
};
const SORT_KEYS = {
  TITLE: 'title',
  REPEAT: 'repeat_ordering',
  ARCHIVED: 'is_archived',
  RESPONSIBILITY: 'responsibility__name',
};

export class GroupTasksTable extends React.Component {
  constructor(props) {
    super(props);
    this.loadGroupTasks = this.loadGroupTasks.bind(this);
    this.loadMoreGroupTasks = this.loadMoreGroupTasks.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.buildFilters = this.buildFilters.bind(this);
    this.sortGroupTasksBy = this.sortGroupTasksBy.bind(this);
  }

  componentDidMount() {
    this.loadGroupTasks(DEFAULT_PAGE);
  }

  applyFilter(event) {
    const newFilters = this.buildFilters(event);
    this.loadGroupTasks(this.props.page, null, newFilters);
  }

  buildFilters(event) {
    const filterKey = event.target.id;
    const filterValue = event.target.value;
    const filters = this.props.filters || Map();

    if (filterKey === FILTER_KEYS.REPEAT) {
      // Handle special case for repeat filter
      const repeatKeys = Map(
        transformRepeatOption({ repeatOption: filterValue })
      );

      return filterValue === FILTER_KEYS.NONE
        ? [FILTER_KEYS.REPEAT, FILTER_KEYS.REPEAT_INTERVAL].reduce(
            (map, key) => map.delete(key),
            filters
          )
        : filters.merge(repeatKeys);
    }

    return filterValue === FILTER_KEYS.NONE
      ? filters.delete(filterKey)
      : filters.set(filterKey, filterValue);
  }

  loadGroupTasks(page, sortBy = null, newFilters = null) {
    const orderingFilter = sortBy ? Map({ ordering: sortBy }) : Map();

    const filters = newFilters ? newFilters : this.props.filters;
    const applyFilters = filters.merge(orderingFilter);

    this.props.getCollection(GROUP_TASKS, {
      page,
      shouldAppend: page !== DEFAULT_PAGE,
      filters: applyFilters.toJS(),
    });
  }

  loadMoreGroupTasks() {
    this.loadGroupTasks(this.props.page + 1);
  }

  sortGroupTasksBy(key, isAscending = true) {
    this.loadGroupTasks(1, `${isAscending ? '' : '-'}${key}`);
  }

  renderGroupTasksRow(groupTask) {
    if (!groupTask.has('id') && groupTask.has('group_task_update_job_id')) {
      return (
        <tr key="pending-task" className="row">
          <td colSpan="5">New group task pending creation...</td>
        </tr>
      );
    }

    return this.renderGroupTaskDetailsRow(groupTask);
  }

  renderGroupTaskDetailsRow(groupTask) {
    const { id, title, is_archived, responsibility, repeat } = groupTask.toJS();

    return (
      <tr key={id} className="row">
        <td className="nowrap" data-title="Name">
          {title}
        </td>
        <td className="nowrap" data-title="Status">
          {is_archived ? ARCHIVED : ACTIVE}
        </td>
        <td className="nowrap" data-title="Responsible person">
          {responsibility.name}
        </td>
        <td data-title="Repetition">{repeat}</td>
        <td>
          {!is_archived && (
            <button
              onClick={() =>
                this.props.openManageGroupTaskModal({
                  groupTaskId: id,
                  isManage: true,
                })
              }
              type="button"
              className="btn btn-secondary mr-1-2"
            >
              Manage
            </button>
          )}
        </td>
        <td>
          {!is_archived && (
            <button
              onClick={() =>
                this.props.openArchiveGroupTaskModal({ id, title })
              }
              type="button"
              className="btn btn-warning"
            >
              Archive
            </button>
          )}
        </td>
      </tr>
    );
  }

  render() {
    const {
      groupTasks,
      isLoading,
      hasMore,
      sortedBy,
      isSortAscending,
      responsibilities,
      responsibilitiesLoading,
      filters,
    } = this.props;

    if (!groupTasks || (isLoading && !groupTasks) || responsibilitiesLoading) {
      return <Loading />;
    }

    if (!isLoading && groupTasks.size === 0 && filters.isEmpty()) {
      return (
        <div>
          <h3 className="mt-2">YOU HAVE NO GROUP TASKS SETUP YET.</h3>
          <h3>WATCH THE VIDEO BELOW TO SEE HOW TO SETUP GROUP TASKS.</h3>
          <iframe
            width="600"
            height="315"
            src="https://www.youtube.com/embed/z_ypYYndp9g"
          />
        </div>
      );
    }

    return (
      <div>
        <h3 className="mt-1">TABLE VIEW OF ALL CREATED TASKS</h3>
        <table className="responsive padded padded-sm">
          <thead>
            <tr>
              <th>
                <p>
                  Name
                  <SortIcon
                    itemKey={SORT_KEYS.TITLE}
                    isSorted={SORT_KEYS.TITLE === sortedBy}
                    isAscending={isSortAscending}
                    onClick={this.sortGroupTasksBy}
                  />
                </p>
              </th>
              <th>
                <p>
                  Status
                  <SortIcon
                    itemKey={SORT_KEYS.ARCHIVED}
                    isSorted={SORT_KEYS.ARCHIVED === sortedBy}
                    isAscending={isSortAscending}
                    onClick={this.sortGroupTasksBy}
                  />
                </p>
                <select
                  id={FILTER_KEYS.ARCHIVED}
                  className="form-select"
                  onChange={this.applyFilter}
                >
                  <option value={FILTER_KEYS.NONE}>All</option>
                  <option key={ACTIVE} value={false}>
                    {ACTIVE}
                  </option>
                  <option key={ARCHIVED} value>
                    {ARCHIVED}
                  </option>
                </select>
              </th>
              <th>
                <p>
                  Responsible person
                  <SortIcon
                    itemKey={SORT_KEYS.RESPONSIBILITY}
                    isSorted={SORT_KEYS.RESPONSIBILITY === sortedBy}
                    isAscending={isSortAscending}
                    onClick={this.sortGroupTasksBy}
                  />
                </p>
                <select
                  id={FILTER_KEYS.RESPONSIBILITY}
                  className="form-select"
                  onChange={this.applyFilter}
                >
                  <option value={FILTER_KEYS.NONE}>All</option>
                  {responsibilities &&
                    responsibilities.map(responsibilityOption => (
                      <option
                        key={responsibilityOption.get('id')}
                        value={responsibilityOption.get('id')}
                      >
                        {responsibilityOption.get('name')}
                      </option>
                    ))}
                </select>
              </th>
              <th>
                <p>
                  Repetition
                  <SortIcon
                    itemKey={SORT_KEYS.REPEAT}
                    isSorted={SORT_KEYS.REPEAT === sortedBy}
                    isAscending={isSortAscending}
                    onClick={this.sortGroupTasksBy}
                  />
                </p>
                <select
                  id={FILTER_KEYS.REPEAT}
                  className="form-select"
                  onChange={this.applyFilter}
                >
                  <option value={FILTER_KEYS.NONE}>All</option>
                  {getRepeatOptions(Map({})).map(option => (
                    <option key={option} value={toOptionValue(option)}>
                      {option.get('text')}
                    </option>
                  ))}
                </select>
              </th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {groupTasks.map(groupTask => this.renderGroupTasksRow(groupTask))}
          </tbody>
        </table>
        {groupTasks.size === 0 && !filters.isEmpty() && (
          <div>
            <p>NO RESULTS FOUND FOR THIS SEARCH</p>
          </div>
        )}
        {isLoading ? (
          <Loading />
        ) : (
          hasMore && (
            <a
              className="inline-link pull-right"
              onClick={() => this.loadMoreGroupTasks()}
            >
              Load more &gt;&gt;
            </a>
          )
        )}
      </div>
    );
  }
}

GroupTasksTable.propTypes = {
  groupTasks: ImmutablePropTypes.list,
  responsibilities: ImmutablePropTypes.list,
  hasMore: React.PropTypes.bool,
  page: React.PropTypes.number,
  isLoading: React.PropTypes.bool,
  isSortAscending: React.PropTypes.bool,
  sortedBy: React.PropTypes.string,
  responsibilitiesLoading: React.PropTypes.bool,
  getCollection: React.PropTypes.func.isRequired,
  openManageGroupTaskModal: React.PropTypes.func.isRequired,
  openArchiveGroupTaskModal: React.PropTypes.func.isRequired,
};

export function mapStateToProps(state) {
  const collection = state.collections.get(GROUP_TASKS);
  const filters = collection ? Map(collection.get('filters')) : Map();
  const ordering = filters.get('ordering');
  const sortedBy = ordering && ordering.replace('-', '');
  const isSortAscending = ordering === sortedBy;

  return {
    groupTasks: state.collections.getIn([GROUP_TASKS, 'items']),
    hasMore: state.collections.getIn([GROUP_TASKS, 'hasMore']),
    page: state.collections.getIn([GROUP_TASKS, 'page']),
    isLoading: isPending(state.responses.getIn(['getCollection', GROUP_TASKS])),
    isSortAscending,
    sortedBy,
    filters,
  };
}

export default connect(mapStateToProps, {
  getCollection,
  openManageGroupTaskModal,
  openArchiveGroupTaskModal,
})(GroupTasksTable);
