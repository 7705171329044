import React from 'react';
import { fromJS, List } from 'immutable';
import Chart from '^/components/app/widgets/Chart';
import moment from 'moment';

const KEYS = fromJS(['complete', 'in_progress', 'overdue']);
const COLORS = fromJS({ overdue: '#f15930', in_progress: '#ffca08', complete: '#00B68A' });
const LABELS = fromJS({ overdue: 'Outstanding', in_progress: 'In progress', complete: 'Complete' });

const HEADERS = ['Month', LABELS.get(KEYS.get(0)), LABELS.get(KEYS.get(1)), LABELS.get(KEYS.get(2))];

const AXIS_OPTIONS = {minValue: 0, viewWindow: {min: 0}};
const CHART_OPTIONS = {
  legend: { position: 'top', maxLines: 3 },
  bar: { groupWidth: '60%' },
  isStacked: true,
  vAxis: AXIS_OPTIONS,
  hAxis: AXIS_OPTIONS,
  series: {
    0: { color: COLORS.get(KEYS.get(0)) },
    1: { color: COLORS.get(KEYS.get(1)) },
    2: { color: COLORS.get(KEYS.get(2)) },
  },
  chartArea: { left: 30, top: 40, bottom: 30, right: 30, width: '100%', height: '100%' },
};


const ActivityStatsThisYear = ({practiceData, chartId}) => {
  const now = moment();
  const data = practiceData && practiceData
    .map(month =>
      List([
        now.month(month.get('month') - 1).format('MMM'),
        month.get(KEYS.get(0)),
        month.get(KEYS.get(1)),
        month.get(KEYS.get(2))
      ]));

  const dataWithHeaders = data && data.insert(0, HEADERS).toJS();

  return practiceData ? (
    <div className="chart-wrapper print-full-width">
      <Chart
        chartType="ColumnChart"
        data={dataWithHeaders}
        options={CHART_OPTIONS}
        graph_id={chartId}
        width="100%"
      />
    </div>
  ) : <p>No data found for current year.</p>;
};

export default ActivityStatsThisYear;
