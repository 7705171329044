import React from 'react';
import PureComponent from '^/components/common/PureComponent';

export default class DetailsCard extends PureComponent {
  render () {
    const { children } = this.props;

    return (
      <div className="row">
        <div className="col-1 mb-2">
          <div className="insights-details-card">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

DetailsCard.propTypes = {
  children: React.PropTypes.any,
};
