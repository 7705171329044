import React from 'react';
import { reduxForm } from 'redux-form';

import { COUNTRIES, BUSINESS_DAY_CHOICES } from '^/models/practice';

import LiveButton from '^/components/app/forms/LiveButton';
import FormError from '^/components/app/forms/FormError';
import PictureUpload from '^/components/app/forms/PictureUpload';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';
import CheckboxGroup from '^/components/app/widgets/CheckboxGroup';
import Img from '^/components/app/content/Img';
import Icon from '^/components/app/content/Icon';
import PracticeComplianceDetailsForm, { getComplianceDetailsFields, filterComplianceDetailsFields } from '^/components/app/practices/forms/PracticeComplianceDetailsForm';
import { SectorTypes } from '^/consts/sector';

export const COLLAPSE_ID = 'PRACTICE_DETAILS_FORM_COLLLAPSE';
export const EXTRA_ADDRESS_FIELDS_COLLAPSE = 'EXTRA_ADDRESS_FIELDS_COLLAPSE';
export const FORM_NAME = 'setupPracticeDetails';

export function getPracticeDetailsSetupFormFields(practice) {
  return [
    'name', 'address_line_1', 'address_line_2', 'address_line_3',
    'city', 'county', 'postcode', 'country', 'phone', 'email',
    'logo', 'url', 'fax', 'business_days',
    'practices_in_group', 'treatment_rooms', 'has_dental_hygienist', 'has_dental_plan', 'dental_plan_provider',
  ].concat(getComplianceDetailsFields(practice));
}

export const PracticeDetailsSetupForm = ({
  fields: {
    name, phone, email,
    address_line_1, address_line_2, address_line_3, city, county, postcode, country,
    logo, url, fax, business_days,
    practices_in_group, treatment_rooms, has_dental_hygienist, has_dental_plan, dental_plan_provider
  }, fields, handleSubmit, response, setPendingUploadInForm, practice
}) => {
  return (
    <form onSubmit={handleSubmit} className="mb-1">

      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor="name" className="label-left padded">Practice Name</label>
          <span className="help-inline">required</span>
          </div>
        <div className="col-2-3">
          <input type="text" id="name" placeholder="eg. ABC Dentalcare" {...name} />
        </div>
        <FormError response={response} formKey="name" />
      </div>
      <hr className="thin" />

      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor="address_line_1" className="label-left padded">Address line 1</label>
          <span className="help-inline">required</span>
        </div>
        <div className="col-2-3">
          <input type="text" id="address_line_1" placeholder="eg. 10 Main Street" {...address_line_1} />
          <div className="mt-1">
            <CollapseButton collapseId={EXTRA_ADDRESS_FIELDS_COLLAPSE} className="optional-field-toggle">
              + add more address lines
            </CollapseButton>
          </div>
        </div>
        <FormError response={response} formKey="address_line_1" />
      </div>

      <div className="mb-2 optional-field">

        <Collapse initiallyCollapsed collapseId={EXTRA_ADDRESS_FIELDS_COLLAPSE}>
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="address_line_2" className="label-left padded">Address line 2</label>
            </div>
            <div className="col-2-3">
              <input type="text" id="address_line_2" placeholder="eg. 10 Main Street" {...address_line_2} />
            </div>
            <FormError response={response} formKey="address_line_2" />
          </div>
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="address_line_3" className="label-left padded">Address line 3</label>
            </div>
            <div className="col-2-3">
              <input type="text" id="address_line_3" placeholder="eg. 10 Main Street" {...address_line_3} />
            </div>
            <FormError response={response} formKey="address_line_3" />
          </div>
        </Collapse>
      </div>

      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor="city" className="label-left padded">City</label>
          <span className="help-inline">required</span>
        </div>
        <div className="col-2-3">
          <input type="text" id="city" placeholder="eg. Brighton" {...city} />
        </div>
        <FormError response={response} formKey="city" />
      </div>
      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor="county" className="label-left padded">County</label>
          <span className="help-inline">required</span>
        </div>
        <div className="col-2-3">
          <input type="text" id="county" placeholder="eg. East Sussex" {...county} />
        </div>
        <FormError response={response} formKey="county" />
      </div>
      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor="postcode" className="label-left padded">Postcode</label>
          <span className="help-inline">required</span>
        </div>
        <div className="col-2-3">
          <input type="text" id="postcode" placeholder="eg. BN1 1AA" {...postcode} />
        </div>
        <FormError response={response} formKey="postcode" />
      </div>
      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor="country" className="label-left padded">Country</label>
          <span className="help-inline">required</span>
        </div>
        <div className="col-2-3">
          <select id="country" {...country}>
            {COUNTRIES.map((display, code) =>
              <option value={code} key={code}>{display}</option>)}
          </select>
        </div>
        <FormError response={response} formKey="country" />
      </div>
      <hr className="thin" />

      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor="phone" className="label-left padded">Phone</label>
          <span className="help-inline">required</span>
        </div>
        <div className="col-2-3">
          <input type="text" id="phone" placeholder="eg. +44 1230 456789" {...phone} />
        </div>
        <FormError response={response} formKey="phone" />
      </div>
      <div className="form-group">
        <div className="col-1-3">
          <label htmlFor="email" className="label-left padded">Practice Email</label>
          <span className="help-inline">required</span>
        </div>
        <div className="col-2-3">
          <input type="email" id="email" placeholder="eg. mail@icomply.cc" {...email} />
        </div>
        <FormError response={response} formKey="email" />
      </div>
      <hr className="thin" />
      <CheckboxGroup field={business_days} display="Business Days" response={response} options={BUSINESS_DAY_CHOICES} />

      { business_days.value !== business_days.initialValue && <div className="p-1 mb-1 alert alert-warning">Editing business days will update all your future daily tasks.</div>}

      <div className="form-group">
        <div className="col-1">
          <span className="help-block">
            <Icon type="lightbulb-o" className="tip-icon" />
            A logo brands your iComply and your documents to your practice nicely.
            A minimum of 500px width by 500px high is required.
          </span>
        </div>
        <div className="col-1-3">
          <label htmlFor="logo" className="label-left padded">Logo</label>
        </div>
        <div className="col-2-3">
          <PictureUpload
            id="logo" src={logo.value && (logo.value.preview || logo.value)}
            display={src => src && <Img circular contain src={src} />}
            setPendingUpload={(file) => setPendingUploadInForm(FORM_NAME, 'logo', file)}
            onSubmit={handleSubmit}
          />
        </div>
        <FormError response={response} formKey="logo" />
      </div>
      <hr className="thin" />

      <div className="mb-2 optional-field">
        <CollapseButton collapseId={COLLAPSE_ID} className="optional-field-toggle">
          + add fax number, website details
        </CollapseButton>

        <Collapse initiallyCollapsed collapseId={COLLAPSE_ID}>
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="url" className="label-left padded">Practice Website</label>
              </div>
            <div className="col-2-3">
              <input type="text" id="url" placeholder="https://icomply.cc/" {...url} />
            </div>
            <FormError response={response} formKey="url" />
          </div>
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="fax" className="label-left padded">Fax Number</label>
              </div>
            <div className="col-2-3">
              <input type="text" id="fax" placeholder="eg. +44 1230 456789" {...fax} />
            </div>
            <FormError response={response} formKey="fax" />
          </div>
        </Collapse>
      </div>
      <hr className="thin" />

      <h2>More about the practice</h2>
      <div className="help-block mb-1">
        <Icon type="lightbulb-o" className="tip-icon" />
        These quick questions will help us select the right compliance reviews for your practice.
      </div>
      <PracticeComplianceDetailsForm fields={filterComplianceDetailsFields(fields, practice)} practice={practice} response={response} />
      {practice.get('sector') === SectorTypes.DENTAL &&
        <div className="form-group">
        <h2>General questions about your practice:</h2>
        <div className="form-group">
          <div className="col-2-3 label-left">
            <label htmlFor="practices_in_group">
              How many practices are there in your group?
            </label>
          </div>
          <div className="col-1-3">
            <span className="help-block">required</span>
            <input type="number" id="practices_in_group" min="0" {...practices_in_group} />
            <FormError response={response} formKey="practices_in_group" />
          </div>
        </div>
        <div className="form-group">
          <div className="col-2-3 label-left">
            <label htmlFor="treatment_rooms">
              How many treatment rooms are there in this practice?
            </label>
          </div>
          <div className="col-1-3">
            <span className="help-block">required</span>
            <input type="number" id="treatment_rooms" min="0" {...treatment_rooms} />
            <FormError response={response} formKey="treatment_rooms" />
          </div>
      </div>

        <div className="form-group">
          <div className="col-2-3 label-left">
            <label htmlFor="has_dental_hygienist">
              Do you have dental hygienists/therapists?
            </label>
            <span className="help-block">required</span>
          </div>
          <div className="col-1-3">
            <label className="label-inline">
              <input {...has_dental_hygienist} type="radio" value checked={has_dental_hygienist.value === 'true'} />
              Yes
            </label>
            <label className="label-inline">
              <input
                {...has_dental_hygienist}
                type="radio"
                value={false}
                checked={has_dental_hygienist.value !== 'true'}
              />
              No
            </label>
            <FormError response={response} formKey="has_dental_hygienist" />
          </div>
        </div>

        <div className="form-group">
          <div className="col-2-3 label-left">
            <label htmlFor="has_dental_plan">
              Do you have a dental plan?
            </label>
            <span className="help-block">required</span>
          </div>
          <div className="col-1-3">
            <label className="label-inline">
              <input {...has_dental_plan} type="radio" value checked={has_dental_plan.value === 'true'} />
              Yes
            </label>
            <label className="label-inline">
              <input {...has_dental_plan} type="radio" value={false} checked={has_dental_plan.value !== 'true'} />
              No
            </label>
            <FormError response={response} formKey="has_dental_plan" />
          </div>
        </div>
        {has_dental_plan.value === 'true' &&
        <div className="form-group">
          <div className="col-1-3 label-left">
            <label htmlFor="dental_plan_provider">
              If so, which company provides this plan?
            </label>
            <span className="help-block">required</span>
          </div>
          <div className="col-2-3">
            <input type="text" {...dental_plan_provider} placeholder="Name of dental provider" />
            <FormError response={response} formKey="dental_plan_provider" />
          </div>
        </div>}
      </div>}

      <LiveButton pendingMessage="Saving..." response={response}>Next...</LiveButton>

    </form>
  );
};

export default reduxForm({
  form: FORM_NAME,
})(PracticeDetailsSetupForm);
