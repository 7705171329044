import React from 'react';
import { CAPABILITIES } from '^/permissions/capabilities';
import IfCan from '^/components/common/IfCan';
import IfCant from '^/components/common/IfCant';
import AdminEditCreate from '^/components/admin/content/edit-create/AdminEditCreate';
import AdminDetailView from '^/components/admin/content/detail/AdminDetailView';

export default function AdminEdit(props) {
  return (
    <span>
      <IfCan capability={CAPABILITIES.UPDATE} model={props.permsModel}>
        <AdminEditCreate {...props} />
      </IfCan>
      <IfCant capability={CAPABILITIES.UPDATE} model={props.permsModel}>
        <AdminDetailView
          {...props}
          fields={props.readOnlyFields}
          getFields={props.getReadOnlyFields}
        />
      </IfCant>
    </span>
  );
}
