import React from 'react';

import PureComponent from '^/components/common/PureComponent';


class EditMemberActivitiesModal extends PureComponent {
  render() {
    return (
      <div>
        <h2>Noot yet implemented.</h2>
      </div>
    );
  }
}

export default EditMemberActivitiesModal;
