import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import PublishDocumentVersionForm from '^/components/admin/template-documents/modals/PublishDocumentVersionForm';
import PureComponent from '^/components/common/PureComponent';

class GroupTemplateDocumentNewVersionModal extends PureComponent {

  constructor(props) {
    super(props);
    this.onPublishNewVersion = this.onPublishNewVersion.bind(this);
  }

  render() {
    const { documentName, response, onCancel } = this.props;
    const message = `Are you sure you want to publish this as a new version of ${documentName || 'Untitled'}?`;

    return (
      <div className="p-1">
        <PublishDocumentVersionForm
          response={response}
          onSubmit={this.onPublishNewVersion}
          onCancel={onCancel}
          message={message}
        />
      </div>
    );
  }

  onPublishNewVersion(formData) {
    this.props.publishNewVersion(formData);
  }
}

export { GroupTemplateDocumentNewVersionModal as UnconnectedGroupTemplateDocumentNewVersionModal };

function mapStateToProps(state) {
  return {
    response: state.responses.get('createTemplateVersion')
  };
}

export default connect(mapStateToProps, {
  onCancel: closeModal
})(GroupTemplateDocumentNewVersionModal);
