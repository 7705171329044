import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';

import AdminFormError from '^/components/admin/content/edit-create/dynamic-forms/AdminFormError';
import Img from '^/components/app/content/Img';
import PictureUpload from '^/components/app/forms/PictureUpload';
import { getPlaceholderImage } from '^/utils';

const FieldImage = (
  {
    readOnly, config, errors, value, handleSubmit, className, setUpload, formName, name
  }
) => {
  return readOnly ? (
    <div className={classNames('mb-1-4', className)}>
      <label>{config.label}</label>
      <Img className="image-field" circular cover src={value || getPlaceholderImage()} />
    </div>
  ) : (
    <div className={classNames('mb-1-4', className)}>
      <div className="mb-1-4">
        <label>{config.label}:</label>
        <PictureUpload
          className="image-field"
          src={value && (value.preview || value)}
          display={src => <Img circular cover src={src || getPlaceholderImage()} />}
          setPendingUpload={(file) => setUpload(formName, name, file)}
          onSubmit={handleSubmit}
        />
      </div>
      <AdminFormError errors={errors} />
    </div>
  );
};

FieldImage.propTypes = {
  readOnly: React.PropTypes.bool,
  config: React.PropTypes.object.isRequired,
  name: React.PropTypes.string.isRequired,
  formName: React.PropTypes.string.isRequired,
  errors: ImmutablePropTypes.list,
  value: React.PropTypes.any,
  onChange: React.PropTypes.func.isRequired,
  className: React.PropTypes.string,
};

export default FieldImage;
