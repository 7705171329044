import React from 'react';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import ImmutablePropTypes from 'react-immutable-proptypes';

import PureComponent from '^/components/common/PureComponent';
import Icon from '^/components/app/content/Icon';
import moment from 'moment';
import { formatBackendDate, FORMAT_DATE_PICKER, formatDate } from '^/utils';
import AdminFormError from '^/components/admin/content/edit-create/dynamic-forms/AdminFormError';


class FieldDate extends PureComponent {

  handleChange(value) {
    const newValue = value.target ? value.target.value : value;
    this.props.onChange(formatBackendDate(newValue));
  }

  getValue() {
    const value = moment(this.props.value);
    return value.isValid() ? value : null;
  }

  render() {
    const { readOnly, className, config, value, errors } = this.props;

    return readOnly ? (
      <div className={classNames('mb-1-4', className)}>
        <label>{config.label}</label>
        <span>{formatDate(value)}</span>
      </div>
    ) : (
      <div className={classNames('mb-1-4', className)}>
        <div>
          <label>{config.label}:</label>
          <span className="date-field">
            <DatePicker
              fixedHeight
              showYearDropdown
              dateFormat={FORMAT_DATE_PICKER}
              selected={this.getValue()}
              onChange={(e) => this.handleChange(e)}
            />
            <Icon type="calendar" />
          </span>
        </div>

        <AdminFormError errors={errors} />
      </div>
    );
  }

}


FieldDate.propTypes = {
  readOnly: React.PropTypes.bool,
  config: React.PropTypes.object.isRequired,
  name: React.PropTypes.string.isRequired,
  errors: ImmutablePropTypes.list,
  value: React.PropTypes.any,
  onChange: React.PropTypes.func.isRequired,
  className: React.PropTypes.string,
};

export default FieldDate;
