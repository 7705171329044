import React from 'react';
import { Map } from 'immutable';
import PureComponent from '^/components/common/PureComponent';
import { ACTIVITY_TYPE_CLASSES } from '^/models/activities';
import Capsule from './Capsule';

export default class ActivityTypeCapsule extends PureComponent {
  getClassName() {
    const { type, className } = this.props;
    return `${Map(ACTIVITY_TYPE_CLASSES).get(type, '')} ${className || ''}`;
  }

  render() {
    return (
      <Capsule className={this.getClassName()} />
    );
  }
}

ActivityTypeCapsule.propTypes = {
  type: React.PropTypes.string.isRequired,
};
