import { AnyAction } from 'redux';

import {
  GET_HEALTHCHECK_QUESTIONS_PROGRESS,
  GET_HEALTHCHECK_QUESTIONS_CATEGORY,
  GET_HEALTHCHECK_ACTIONS_PROGRESS,
  GET_HEALTHCHECK_ACTIONS_CATEGORY,
  GET_HEALTHCHECK_REVIEW,
  GET_HEALTHCHECK_AUDIT_RESULTS,
  CREATE_HEALTHCHECK,
  ANSWER_HEALTHCHECK_QUESTION,
  UPDATE_ACTION,
  DELETE_HEALTHCHECK,
  UPDATE_HEALTHCHECK_STATUS,
  CLEAR_THUNK_REQUEST_ERRORS,
  GET_CURRENT_PRACTICE_HEALTHCHECKS,
  GET_HEALTHCHECK_BY_ID,
} from '^/actions/healthcheck';
import { AsyncActionSet } from '^/actions/types';
import { ThunkRequestState, ThunkRequestByIdState } from '^/store/types';
import { hasKey } from '^/utils/common';
import {
  GET_AUDIT_REVIEW_SCORES,
  GET_AUDIT_REVIEW_TASKS,
  GET_AUDIT_REVIEW_CATEGORIES,
  GET_CURRENT_PRACTICE_AUDITS,
  UPDATE_AUDIT_QUESTION,
  DELETE_AUDIT,
  GET_AUDIT_BY_ID,
  GET_AUDIT_QUESTIONS_PROGRESS,
  GET_AUDIT_QUESTIONS_CATEGORY,
  UPDATE_AUDIT,
  GET_AUDIT_ACTIONS_CATEGORY,
  GET_AUDIT_ACTIONS_PROGRESS,
  GET_AUDIT_TYPES,
  GET_AUDIT_BY_TYPE,
  GET_AUDIT_AUDIT_RESULTS,
} from '^/actions/audit';

const DEFAULT_STATE: ThunkRequestState = {
  totalRequestCount: 0,
  inFlightCount: 0,
  isLoading: false,
  errors: null,
  lastRequestTime: null,
};

const createThunkRequestReducer = <T extends string>(
  actionSetMap: Record<T, AsyncActionSet>
) => {
  const keys = Object.keys(actionSetMap) as T[];
  const initialValue = keys.reduce(
    (memo, key) => ({
      ...memo,
      [key]: DEFAULT_STATE,
    }),
    {} as Record<T, ThunkRequestState>
  );

  return (
    state = initialValue,
    action: AnyAction
  ): Record<T, ThunkRequestState> => {
    let matchingKey = keys.find(key => {
      const actionSet = actionSetMap[key];
      return (
        actionSet.REQUEST === action.type ||
        actionSet.SUCCESS === action.type ||
        actionSet.FAILURE === action.type
      );
    });

    if (action.type === CLEAR_THUNK_REQUEST_ERRORS) {
      matchingKey = action.payload;
    }

    if (!matchingKey) {
      return state;
    }

    const matchingAction = actionSetMap[matchingKey];

    const newRequestState = { ...state[matchingKey] };

    if (action.type === matchingAction.REQUEST) {
      newRequestState.totalRequestCount += 1;
      newRequestState.inFlightCount += 1;
      newRequestState.errors = null;
      newRequestState.lastRequestTime = Date.now();
    } else if (action.type === matchingAction.SUCCESS) {
      newRequestState.inFlightCount -= 1;
      newRequestState.errors = null;
    } else if (action.type === matchingAction.FAILURE) {
      newRequestState.inFlightCount -= 1;
      newRequestState.errors = action.payload;
    } else if (action.type === CLEAR_THUNK_REQUEST_ERRORS) {
      newRequestState.errors = null;
    }

    newRequestState.isLoading = newRequestState.inFlightCount > 0;

    return {
      ...state,
      [matchingKey]: newRequestState,
    };
  };
};

const createThunkRequestByIdReducer = <T extends string>(
  actionSetMap: Record<T, AsyncActionSet>
) => {
  const keys = Object.keys(actionSetMap) as T[];
  const initialValue: Partial<Record<T, ThunkRequestByIdState>> = {};

  return (
    state = initialValue,
    action: AnyAction
  ): Partial<Record<T, ThunkRequestByIdState>> => {
    const matchingKey = keys.find(key => {
      const actionSet = actionSetMap[key];
      return (
        actionSet.REQUEST === action.type ||
        actionSet.SUCCESS === action.type ||
        actionSet.FAILURE === action.type
      );
    });

    if (!matchingKey) {
      return state;
    }

    if (
      !hasKey(action, 'meta') ||
      !hasKey(action.meta, 'options') ||
      !hasKey(action.meta.options, 'requestId') ||
      typeof action.meta.options.requestId !== 'string'
    ) {
      return state;
    }

    const matchingAction = actionSetMap[matchingKey];

    const newRequestState = {
      ...DEFAULT_STATE,
      ...state[matchingKey]?.[action.meta.options.requestId],
    };

    if (action.type === matchingAction.REQUEST) {
      newRequestState.totalRequestCount += 1;
      newRequestState.inFlightCount += 1;
      newRequestState.errors = null;
      newRequestState.lastRequestTime = Date.now();
    } else if (action.type === matchingAction.SUCCESS) {
      newRequestState.inFlightCount -= 1;
      newRequestState.errors = null;
    } else if (action.type === matchingAction.FAILURE) {
      newRequestState.inFlightCount -= 1;
      newRequestState.errors = action.payload;
    }

    newRequestState.isLoading = newRequestState.inFlightCount > 0;

    return {
      ...state,
      [matchingKey]: {
        ...matchingAction,
        [action.meta.options.requestId]: newRequestState,
      },
    };
  };
};

export const thunkRequests = createThunkRequestReducer({
  getHealthcheckQuestionsProgress: GET_HEALTHCHECK_QUESTIONS_PROGRESS,
  getHealthcheckQuestionsCategory: GET_HEALTHCHECK_QUESTIONS_CATEGORY,
  getHealthcheckActionsProgress: GET_HEALTHCHECK_ACTIONS_PROGRESS,
  getHealthcheckActionsCategory: GET_HEALTHCHECK_ACTIONS_CATEGORY,
  getHealthcheckReview: GET_HEALTHCHECK_REVIEW,
  getHealthcheckAuditResults: GET_HEALTHCHECK_AUDIT_RESULTS,
  getCurrentPracticeHealthchecks: GET_CURRENT_PRACTICE_HEALTHCHECKS,
  createHealthcheck: CREATE_HEALTHCHECK,
  answerHealthcheckQuestion: ANSWER_HEALTHCHECK_QUESTION,
  updateAction: UPDATE_ACTION,
  deleteHealthcheck: DELETE_HEALTHCHECK,
  updateHealthcheckStatus: UPDATE_HEALTHCHECK_STATUS,
  getHealthcheckById: GET_HEALTHCHECK_BY_ID,
  getAuditById: GET_AUDIT_BY_ID,
  getAuditReviewScores: GET_AUDIT_REVIEW_SCORES,
  getAuditReviewTasks: GET_AUDIT_REVIEW_TASKS,
  getAuditReviewCategories: GET_AUDIT_REVIEW_CATEGORIES,
  getCurrentPracticeAudits: GET_CURRENT_PRACTICE_AUDITS,
  answerAuditQuestion: UPDATE_AUDIT_QUESTION,
  deleteAudit: DELETE_AUDIT,
  getAuditQuestionsProgress: GET_AUDIT_QUESTIONS_PROGRESS,
  getAuditQuestionsCategory: GET_AUDIT_QUESTIONS_CATEGORY,
  updateAudit: UPDATE_AUDIT,
  getAuditActionsCategory: GET_AUDIT_ACTIONS_CATEGORY,
  getAuditActionsProgress: GET_AUDIT_ACTIONS_PROGRESS,
  getAuditTypes: GET_AUDIT_TYPES,
  getAuditsByType: GET_AUDIT_BY_TYPE,
  getAuditAuditResults: GET_AUDIT_AUDIT_RESULTS,
});

export const thunkRequestsById = createThunkRequestByIdReducer({
  answerHealthcheckQuestion: ANSWER_HEALTHCHECK_QUESTION,
  updateAction: UPDATE_ACTION,
});
