import React from 'react';
import { connect } from 'react-redux';

import classNames from 'classnames';
import { GROUP_SECTION, GROUP_SECTION_URLS } from '^/consts/groups';
import { isLoggedOnUserAdminOfCurrentGroup } from '^/stateHelpers';
import GroupNavItem from '^/components/app/groups/group-details/GroupNavItem';


export const GroupNav = (props) => (
  <div className={classNames(props.className, 'secondary-left')}>
    <h2 className="mt-1-2">{props.groupName}</h2>
    <ul className="list-no-indent icon-right">
      {GROUP_SECTION.map(section =>
        props.isAdmin && (
          <GroupNavItem
            key={section}
            section={section}
            currentSection={props.currentSection}
          />
        )
      )}
    </ul>
  </div>
);

export const mapStateToProps = (state) => {
  const path = state.routing.location.pathname || '';

  return {
    currentSection: GROUP_SECTION_URLS.keyOf(path),
    isAdmin: isLoggedOnUserAdminOfCurrentGroup(state),
  };
};

export default connect(mapStateToProps)(GroupNav);
