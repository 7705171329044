import AuditActionCell from '^/components/app/digital-tools/audit/action-cell';
import { AUDIT_SUMMARY_TABLE_COMMON_COLUMNS } from '^/consts/healthcheck-audit';
import createSummaryTable from '^/components/app/healthcheck/hoc/create-summary-table';
import { AuditListSummary } from '^/components/app/digital-tools/audit/types';

export default createSummaryTable<AuditListSummary>({
  columns: [
    ...AUDIT_SUMMARY_TABLE_COMMON_COLUMNS,
    {
      name: 'Actions',
      renderer: AuditActionCell,
    },
  ],
});
