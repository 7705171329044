import React from 'react';
import { connect } from 'react-redux';
import PureComponent from '^/components/common/PureComponent';
import { Map } from 'immutable';

import { loadItem } from '^/actions/items';
import { getCollection } from '^/actions/collections';
import Loading from '^/components/app/content/Loading';
import LiveButtonSet from '^/components/app/forms/LiveButtonSet';
import { CAPABILITIES, MODELS } from '^/permissions/capabilities';

import { isAdminType } from '^/models/user';
import { setUserLockAndReloadUser } from '^/actions/actionSequences';
import AdminChangeLog, {
  reloadChangeLog,
} from '^/components/admin/content/change-log/AdminChangeLog';
import {
  ADMIN_USER,
  ADMIN_STAFF_DETAIL_EDIT_FIELDS,
  ADMIN_STAFF_DETAIL,
  ACCOUNT_DETAILS_VIEW,
  ACCOUNT_DETAILS_VIEW_FIELDS,
  ADMIN_USER_ROLES,
  ADMIN_USER_ROLES_FIELDS,
  ACCOUNT_LOGINS,
  ACCOUNT_LOGINS_FIELDS,
  ADMIN_USER_OWNED_PRACTICES,
  ADMIN_ACCOUNT_DETAILS_FIELDS,
  ADMIN_USER_PRACTICE_MEMBERS_FIELDS,
  ADMIN_USER_PRACTICE_MEMBERS_CREATE_FIELDS,
  ADMIN_ACCOUNT_DETAILS_VIEW_FIELDS,
  ADMIN_STAFF_DETAIL_VIEW_FIELDS,
} from '^/components/admin/users/user';
import {
  ADMIN_STAFF_PRACTICE_MODEL,
  PRACTICES_STAFF_MEMBERS,
} from '^/components/admin/practices/practice';

import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';
import AdminManyRelatedField from '^/components/admin/content/many-related-field/AdminManyRelatedField';
import AdminDetail from '^/components/admin/content/detail/AdminDetail';
import LockUser from '^/components/admin/users/end-users/LockUser';
import Memberships from '^/components/admin/users/end-users/Memberships';
import IfCan from '^/components/common/IfCan';
import { SITE_URL } from '^/consts/single-sign-on';

function transform(fieldConfig, fields) {
  const { type } = fields;
  return isAdminType(type && (type.value || type.initialValue))
    ? fieldConfig
    : fieldConfig.filterNot((config, fieldName) => fieldName === 'admin_role');
}

export class UserAccountEditAdmin extends PureComponent {
  constructor(props) {
    super(props);
    this.reloadStaffMembers = this.reloadStaffMembers.bind(this);
    this.reloadStaffMembersAndChangeLog = this.reloadStaffMembersAndChangeLog.bind(
      this
    );
  }

  componentWillMount() {
    const { userId } = this.props;
    this.props.loadItem(ADMIN_USER, userId);
  }

  loginAsUser(userId) {
    window.location.replace(`${SITE_URL}/impersonate/${userId}/`);
  }

  reloadStaffMembers() {
    const { staffPracticePageSize, staffPracticeFilter } = this.props;

    this.props.getCollection(
      ADMIN_STAFF_PRACTICE_MODEL,
      { pageSize: staffPracticePageSize, filters: staffPracticeFilter },
      PRACTICES_STAFF_MEMBERS
    );
  }

  reloadStaffMembersAndChangeLog() {
    this.reloadStaffMembers();
    this.props.reloadUserChangeLog();
  }

  render() {
    const {
      user,
      userId,
      lockResponse,
      practices,
      staffPracticePageSize,
      staffPracticeFilter,
      staffdetailOptions,
      staffdetailId,
    } = this.props;

    if (!user || !staffdetailId) {
      return <Loading />;
    }

    return (
      <AdminContainer title="User Accounts">
        <h3>Account - {user && user.get('email')}</h3>

        <div className="mt-2">
          {practices && practices.size && <Memberships practices={practices} />}
        </div>

        <IfCan capability={CAPABILITIES.UPDATE} model={MODELS.USER}>
          <div className="mt-2">
            <LockUser
              isLocked={user.get('is_locked')}
              setLock={lockUser =>
                this.props.setUserLockAndReloadUser(
                  userId,
                  lockUser,
                  ADMIN_USER
                )
              }
              response={lockResponse}
            />
          </div>
        </IfCan>

        <div className="mt-2">
          <AdminDetail
            item={user}
            detailName={ACCOUNT_LOGINS}
            fields={ACCOUNT_LOGINS_FIELDS}
            title="Set user to logged out"
          >
            <LiveButtonSet>
              <button
                onClick={() => this.loginAsUser(userId)}
                className="btn btn-warning logout-button ml-1"
              >
                Login As User
              </button>
            </LiveButtonSet>
          </AdminDetail>
        </div>

        <AdminContainer
          className="mt-2 admin-edit-create-form"
          title="Account Details"
        >
          <AdminDetail
            className="admin-form-no-border"
            item={user}
            detailName={ACCOUNT_DETAILS_VIEW}
            fields={ACCOUNT_DETAILS_VIEW_FIELDS}
          />
          <AdminEdit
            className="admin-form-no-border"
            permsModel={MODELS.USER}
            readOnlyFields={ADMIN_ACCOUNT_DETAILS_VIEW_FIELDS}
            onComplete={this.reloadStaffMembersAndChangeLog}
            model={ADMIN_USER}
            itemId={userId}
            fields={ADMIN_ACCOUNT_DETAILS_FIELDS}
            transform={transform}
          />
        </AdminContainer>

        <div className="mt-2">
          <AdminEdit
            permsModel={MODELS.USER}
            readOnlyFields={ADMIN_STAFF_DETAIL_VIEW_FIELDS}
            model={ADMIN_STAFF_DETAIL}
            onComplete={this.props.reloadUserChangeLog}
            itemId={user.getIn(['staffdetail', 'id'])}
            fields={ADMIN_STAFF_DETAIL_EDIT_FIELDS}
            title="Staff Details"
          />
        </div>

        <div className="mt-2">
          <AdminManyRelatedField
            itemId={staffdetailId}
            controlName={ADMIN_USER_ROLES}
            model={ADMIN_STAFF_DETAIL}
            options={staffdetailOptions}
            fieldName={['roles']}
            fields={ADMIN_USER_ROLES_FIELDS}
            sortBy={['name']}
            listTitle="Roles"
          />
        </div>

        <AdminContainer className="mt-2" title="Practice(s)">
          <AdminList
            permsModel={MODELS.USER}
            noSearch
            hidePagination
            pageSize={staffPracticePageSize}
            listName={PRACTICES_STAFF_MEMBERS}
            model={ADMIN_STAFF_PRACTICE_MODEL}
            fields={ADMIN_USER_PRACTICE_MEMBERS_FIELDS}
            defaultFilters={Map(staffPracticeFilter)}
          />
          <AdminCreate
            permsModel={MODELS.USER}
            onComplete={this.reloadStaffMembers}
            collectionName={PRACTICES_STAFF_MEMBERS}
            controlName={PRACTICES_STAFF_MEMBERS}
            model={ADMIN_STAFF_PRACTICE_MODEL}
            fields={ADMIN_USER_PRACTICE_MEMBERS_CREATE_FIELDS}
            defaultValues={{ user: userId }}
            title="Add to Practice"
          />
        </AdminContainer>
        <AdminContainer>
          <AdminChangeLog model="User" id={userId} />
        </AdminContainer>
      </AdminContainer>
    );
  }
}

export function mapStateToProps(state, props) {
  const userId = props.params.uuid;
  const user = state.items.get(ADMIN_USER) || Map();

  const staffPracticePageSize = 100;
  const staffPracticeFilter = { user: userId };

  return {
    user,
    userId,
    lockResponse: state.responses.get('setUserLock'),
    staffdetailId: user.getIn(['staffdetail', 'id']),
    staffdetailOptions: state.items.getIn(
      ['options', ADMIN_STAFF_DETAIL],
      Map()
    ),
    practices: state.collections.getIn([ADMIN_USER_OWNED_PRACTICES, 'items']),
    staffPracticeFilter,
    staffPracticePageSize,
  };
}

export function mapDispatchToProps(dispatch, props) {
  const reloadUserChangeLog = reloadChangeLog('User', props.params.uuid);

  return {
    loadItem: (type, id, name = type) => dispatch(loadItem(type, id, name)),
    setUserLockAndReloadUser: (userId, locked, itemName) =>
      dispatch(setUserLockAndReloadUser(userId, locked, itemName)),
    getCollection: (type, opts, name = type) =>
      dispatch(getCollection(type, opts, name)),
    reloadUserChangeLog: () => dispatch(reloadUserChangeLog()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAccountEditAdmin);
