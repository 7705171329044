import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { ConnectHOC } from '@dabapps/connect-hoc';

import Icon from '^/components/app/content/Icon';
import { ItemRendererProps, HealthcheckAction } from './types';
import { StoreState } from '^/store/types';
import { linkify } from '^/utils';
import { updateAction } from '^/actions/healthcheck';
import {
  selectRequestIsLoadingById,
  selectRequestErrorsById,
} from '^/selectors/thunk-requests';
import NonFieldErrorRenderer from '^/components/common/NonFieldErrorRenderer';
import NotesEditor from '^/components/app/digital-tools/audit/question-notes';

interface StateProps {
  errors: unknown;
  isLoading: boolean;
}

interface DispatchProps {
  updateAction: typeof updateAction;
}

type OwnProps = ItemRendererProps<HealthcheckAction>;

type Props = DispatchProps & StateProps & OwnProps;

class ActionRow extends Component<Props, {}> {
  public render() {
    const { is_completed } = this.props.item;
    const backgroundStyle = is_completed
      ? 'overline'
      : 'background-grey overline';

    return (
      <div className={backgroundStyle}>
        <div className="action-details">
          <div className="left">
            <span className="inline-icon">
              <p className="pr-1">{linkify(this.props.item.action_text)}</p>
              {this.props.item.action_template_documents.map(document => (
                <div key={document.id}>
                  <Icon type="file-text-o" />{' '}
                  <Link to={`/page/documents/${document.id}/`}>
                    {document.name}
                  </Link>
                </div>
              ))}
            </span>
          </div>
          {is_completed ? (
            <span className="right button-container">
              <button
                className="btn btn-primary"
                onClick={this.onMarkIncomplete}
                disabled={this.props.disabled}
              >
                Mark Incomplete
              </button>
              <div className="mt-1">
                <span className="inline-icon complete">
                  <Icon type="check" />
                </span>
                <span className="complete pr-1">Completed</span>
              </div>
            </span>
          ) : (
            <span className="right button-container">
              <button
                className="btn btn-secondary-large"
                onClick={this.onMarkComplete}
                disabled={this.props.disabled}
              >
                Mark Complete
              </button>
            </span>
          )}
        </div>

        <NotesEditor
          onClick={this.onSubmitNotes}
          notes={this.props.item.notes}
          disabled={this.props.disabled}
        />

        <NonFieldErrorRenderer errors={this.props.errors} />
      </div>
    );
  }

  private onSubmitNotes = (notes: string) => {
    this.props.updateAction(
      this.props.healthcheckId,
      this.props.categoryId,
      this.props.areaId,
      this.props.item.id,
      { notes }
    );
  };

  private onMarkComplete = () => {
    this.props.updateAction(
      this.props.healthcheckId,
      this.props.categoryId,
      this.props.areaId,
      this.props.item.id,
      { is_completed: true }
    );
  };

  private onMarkIncomplete = () => {
    this.props.updateAction(
      this.props.healthcheckId,
      this.props.categoryId,
      this.props.areaId,
      this.props.item.id,
      { is_completed: false }
    );
  };
}

const mapStateToProps = (state: StoreState, props: OwnProps): StateProps => ({
  errors: selectRequestErrorsById(state, {
    requestName: 'updateAction',
    requestId: props.item.id,
  }),
  isLoading: selectRequestIsLoadingById(state, {
    requestName: 'updateAction',
    requestId: props.item.id,
  }),
});

export default (connect as ConnectHOC)<
  StateProps,
  DispatchProps,
  OwnProps,
  StoreState
>(mapStateToProps, {
  updateAction: updateAction,
})(ActionRow);
