import React from 'react';
import UserCreatedMarkup from '^/components/common/content/UserCreatedMarkup';
import { getPlaceholderImage } from '^/utils';


export default function HelpModal({ topic }) {
  return (
    <div className="help-modal-wrapper">
      { topic.get('video_id') ? (
        <div className="help-modal-video">
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${topic.get('video_id')}?rel=0`}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      ) : (
        <img className="centered" src={topic.get('image') || getPlaceholderImage()} />
      )}
      <div className="mt-1">
        <UserCreatedMarkup text={topic.get('content', '')} />
      </div>
    </div>
  );
}
