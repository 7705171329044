import React from 'react';
import { reduxForm } from 'redux-form';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { resetResponse } from '^/actions/actions';
import { uploadFile } from '^/actions/actionSequences';
import { hasSucceeded } from '^/consts/responseStates';
import LiveButton from '^/components/app/forms/LiveButton';
import PureComponent from '^/components/common/PureComponent';
import { renderMultipleFileUploadField } from '^/components/app/digital-tools/records/digital-records/forms/FormFields';

export const ADD_SUPPORTING_DOCUMENTS_FORM = 'ADD_SUPPORTING_DOCUMENTS_FORM';

export class AddSupportingDocumentsForm extends PureComponent {
  componentDidUpdate(prevProps) {
    if (prevProps.fields.related_files !== this.props.fields.related_files) {
      this.props.resetResponse(this.props.responseName);
    }
  }

  render() {
    const {
      buttonText,
      fields,
      handleSubmit,
      successText,
      updateRelatedFilesResponse,
      uploadFileResponse,
    } = this.props;

    const { related_files } = fields;

    return (
      <div>
        <h3 className="mb-1">
          Documents
        </h3>
        <form onSubmit={handleSubmit}>
          {renderMultipleFileUploadField(
            related_files,
            'Add Supporting Documents:',
            handleSubmit,
            this.props.uploadFile,
            uploadFileResponse,
            ADD_SUPPORTING_DOCUMENTS_FORM
          )}
          <div className="form-group">
            <div className="col-2-3 col-1-3-offset">
              <LiveButton
                pendingMessage="Saving..."
                response={updateRelatedFilesResponse}
                className="btn-default pd-2"
              >
                {buttonText}
              </LiveButton>
            </div>
          </div>
          {hasSucceeded(updateRelatedFilesResponse) && (
            <div className="form-group mb-2">
              <p className="alert mt-1 alert-success">{successText}</p>
            </div>
          )}
        </form>
      </div>
    );
  }
};

AddSupportingDocumentsForm.propTypes = {
  buttonText: React.PropTypes.string.isRequired,
  fields: React.PropTypes.object.isRequired,
  handleSubmit: React.PropTypes.func.isRequired,
  successText: React.PropTypes.string.isRequired,
  resetResponse: React.PropTypes.func.isRequired,
  responseName: React.PropTypes.string.isRequired,
  updateRelatedFilesResponse: ImmutablePropTypes.map,
  uploadFileResponse: ImmutablePropTypes.map,
};

export function mapStateToProps(state, props) {
  return {
    updateRelatedFilesResponse: state.responses.get(props.responseName),
    uploadFileResponse: state.responses.get('uploadFiletoSignedUrl'),
  };
}

export const FormifiedAddSupportingDocumentsForm = reduxForm({
  form: ADD_SUPPORTING_DOCUMENTS_FORM,
  fields: [ 'related_files' ],
})(AddSupportingDocumentsForm);

export default connect(
  mapStateToProps,
  { uploadFile, resetResponse }
)(FormifiedAddSupportingDocumentsForm);
