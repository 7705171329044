import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { isPending, hasSucceeded } from '^/consts/responseStates';
import { getCollection } from '^/actions/collections';
import { UPLOADED_ACTIVITY_TASK_FILES } from '^/consts/collectionKeys';
import { downloadFromURL } from '^/actions/actionSequences';
import { openChangeUploadedTaskFileFolderModal, openRemoveUploadedDocumentsModal } from '^/actions/modals';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import { isLoggedOnUserAdminOfCurrentPractice } from '^/stateHelpers';

function UploadedDocumentsListingHeader ({ showingSearch, search, folder, }) {
  const searchHeader = `Search results${(search ? ` matching '${search}'` : '')}`;
  const folderHeader = folder ? folder.get('name') : '';
  return (
    <h2 className="mb-1 inline">
      {showingSearch ? searchHeader : folderHeader}
    </h2>
  );
}


export class UploadedDocumentsListing extends PureComponent {

  componentWillMount() {
    this.loadDocuments();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.haveFiltersChanged(this.props, nextProps) ||
      this.hasADocumentUpdated(this.props, nextProps)
    ) {
      this.loadDocuments(nextProps);
    }
  }

  hasSearchChanged (oldProps, newProps) {
    return (
      newProps.showingSearch !== oldProps.showingSearch ||
      (newProps.search !== oldProps.search)
    );
  }

  hasFolderChanged (oldProps, newProps) {
    const oldFolderId = oldProps.folder && oldProps.folder.get('id');
    const newFolderId = newProps.folder && newProps.folder.get('id');
    return (
      newProps.showingSearch !== oldProps.showingSearch ||
      oldFolderId !== newFolderId
    );
  }

  haveFiltersChanged (oldProps, newProps) {
    return (
      (newProps.showingSearch && this.hasSearchChanged(oldProps, newProps)) ||
      (!newProps.showingSearch && this.hasFolderChanged(oldProps, newProps))
    );
  }

  hasADocumentUpdated (oldProps, newProps) {
    return (
      (
        !oldProps.documentUpdateResponse ||
        isPending(oldProps.documentUpdateResponse)
      ) &&
      hasSucceeded(newProps.documentUpdateResponse)
    );
  }

  loadDocuments(props = this.props, opts) {
    const { search, showingSearch, folder } = props;
    const filters = showingSearch ? { search, } : { folder: folder && folder.get('id'), };
    this.props.getCollection(UPLOADED_ACTIVITY_TASK_FILES, Object.assign({ filters: filters, }, opts));
  }

  loadMoreDocuments() {
    const { uploadedDocuments } = this.props;
    this.loadDocuments(this.props, {page: uploadedDocuments.get('page') + 1, shouldAppend: true});
  }

  renderDocumentRow(uploadedDoc) {
    const { id, name, description, link, file, activity, task, task_assignee, folder } = uploadedDoc.toObject();

    const activityTask = activity || task || task_assignee;
    const uploadId = activityTask.get('id');

    return (
      <tr key={id} className="row">
        <td data-title="Type">
          <span className="text-empty-state">
            {link ? 'Link' : 'File'}
          </span>
        </td>
        <td data-title="Name">
          { link ? (
            <a className="underlined" href={link} target="_blank">{name}</a>
          ) : (
            <a className="underlined" onClick={() => this.props.downloadFromURL(file.replace('/api', ''))}>
              {name}
            </a>
          )}
        </td>
        <td className="nowrap" data-title="Description">
          {description}
        </td>
        <td className="nowrap" data-title="Folder">
          {folder && folder.get('number') || 'None'}
        <a
          className="ml-1-2 underlined"
          onClick={() => this.props.openChangeUploadedTaskFileFolderModal(
            uploadId,
            id,
            folder && folder.get('id')
          )}
        >
          Change
        </a>
        </td>
        <td data-title="Linked activity/task">
          {activity && (
            <Link className="underlined" to={`/page/activities/${activity.get('id')}/`}>
              {activity.get('title')}
            </Link>
          )}
          {task && (
            <Link className="underlined" to={`/page/tasks/manage/${task.get('id')}/`}>
              {task.get('title')}
            </Link>
          )}
          {task_assignee && (
            <Link className="underlined" to={`/page/tasks/${task_assignee.getIn(['task', 'id'])}/`}>
              {task_assignee.getIn(['task', 'title'])}
            </Link>
          )}
        </td>
        <td>
          {this.props.isAdmin && (
            <button onClick={() => this.props.openRemoveUploadedDocumentsModal(name, uploadId, id)} type="button" className="btn btn-warning">
              Remove
            </button>
          )}
        </td>
      </tr>
    );
  }

  render() {
    const { uploadedDocuments, isLoading, userId, isAdmin} = this.props;

    if (!uploadedDocuments || (isLoading && !uploadedDocuments.get('items'))) {
      return <Loading />;
    }

    return (
      <div>
        <UploadedDocumentsListingHeader {...this.props} />
        <p className="help-block pull-right pt-1-2 inline">{uploadedDocuments.get('count')} results found.</p>
        <table className="responsive padded padded-sm">
          <thead>
            <tr>
              <th>Type</th>
              <th>Name</th>
              <th>Description</th>
              <th>Folder</th>
              <th>Linked Activity/Task</th>
              {isAdmin && (<th />)}
            </tr>
          </thead>
          <tbody>
            {uploadedDocuments.get('items')
              .sortBy(doc => doc.get('name'))
              .map(doc => this.renderDocumentRow(doc, userId))
            }
          </tbody>
        </table>
        {isLoading ? (
          <Loading />
        ) : (
          uploadedDocuments.get('hasMore') && (
            <a className="inline-link pull-right" onClick={() => this.loadMoreDocuments()} >
              Load more >>
            </a>
          )
        )}
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    uploadedDocuments: state.collections.get(UPLOADED_ACTIVITY_TASK_FILES),
    isLoading: isPending(state.responses.getIn(['getCollection', UPLOADED_ACTIVITY_TASK_FILES])),
    documentUpdateResponse: state.responses.get('changeFileFolder'),
    userId: state.userProfile.get('id'),
    isAdmin: isLoggedOnUserAdminOfCurrentPractice(state),
  };
}

export default connect(mapStateToProps, {
  getCollection,
  downloadFromURL,
  openChangeUploadedTaskFileFolderModal,
  openRemoveUploadedDocumentsModal
}) (UploadedDocumentsListing);
