import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import PureComponent from '^/components/common/PureComponent';
import { setCollapse } from '^/actions/actions';

class ExpandAllButton extends PureComponent {
  get anyCollapsed() {
    const { collapse, collapseIds } = this.props;
    return collapseIds.some(id => collapse.get(id));
  }

  toggleCollapseAll() {
    const { collapseIds } = this.props;
    const collapse = !this.anyCollapsed;
    collapseIds.forEach(id => this.props.setCollapse(id, collapse));
  }

  render() {
    const { className } = this.props;

    return (
      <a
        className={className}
        onClick={() => this.toggleCollapseAll()}
      >
        {this.anyCollapsed ? 'Expand all' : 'Collapse all'}
      </a>
    );
  }
}

export { ExpandAllButton as ExpandAllButtonUnconnected };

export function mapStateToProps(state) {
  return {
    collapse: state.ui.get('collapse', Map())
  };
}

export default connect(
  mapStateToProps,
  {
    setCollapse
  }
)(ExpandAllButton);
