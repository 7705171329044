import { fromJS } from 'immutable';


export const ADMIN_TERMS_CREATE = 'HELP_ADMIN_CREATE';
export const ADMIN_TERMS_MODEL = 'admin/terms';
const ADMIN_TERMS_MANAGE_ROUTE = '/admin/terms/${id}/';
export const ADMIN_TERMS_EDIT_FIELDS = fromJS([
  'date', 'content',
]);

export const ADMIN_TERMS_ROW_FIELDS = fromJS([
  {name: ['date'], display_name: 'Date', type: 'date', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Edit', route: ADMIN_TERMS_MANAGE_ROUTE},
  {name: ['id'], display_name: 'Delete', type: 'remove', sortable: false},
]);
