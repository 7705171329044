import React from 'react';
import { connect } from 'react-redux';

import { createItemRunCallbackAndCloseModal } from '^/actions/actionSequences';
import { closeModal } from '^/actions/modals';
import { ADMIN_TEMPLATE_DOCUMENT_CREATE_MODEL, ADMIN_TEMPLATE_DOCUMENT_CREATE } from '^/components/admin/template-documents/template-documents';

import PublishDraftForm from './PublishDraftForm';


export function PublishDraftModal({response, data, onComplete, publish, onCancel}) {
  const message = `Are you sure you want to publish: ${data.name}?`;

  return (
    <div className="p-1">
      <PublishDraftForm
        response={response}
        onSubmit={formData => publish(
          ADMIN_TEMPLATE_DOCUMENT_CREATE_MODEL,
          Object.assign({}, data, formData),
          ADMIN_TEMPLATE_DOCUMENT_CREATE_MODEL,
          ADMIN_TEMPLATE_DOCUMENT_CREATE,
          onComplete
        )}
        onCancel={onCancel}
        message={message}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    response: state.responses.getIn(['createItem', ADMIN_TEMPLATE_DOCUMENT_CREATE_MODEL])
  };
}

export default connect(
  mapStateToProps,
  { publish: createItemRunCallbackAndCloseModal, onCancel: closeModal }
) (PublishDraftModal);
