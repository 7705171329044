import React from 'react';
import { Map, fromJS, List } from 'immutable';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import { getAllCollection } from '^/actions/collections';
import { ADMIN_AUDIT_CATEGORIES } from '^/consts/collectionKeys';
import { getItemOptions } from '^/actions/items';
import PureComponent from '^/components/common/PureComponent';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEdit from '^/components/admin/content/perms/AdminEdit';
import AdminManyRelatedField from '^/components/admin/content/perms/AdminManyRelatedField';
import AdminCreate from '^/components/admin/content/perms/AdminCreate';
import AdminList from '^/components/admin/content/perms/AdminList';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import { transformAuditQuestionCategoryOptions } from '^/components/admin/audits/utils';

import {
  ADMIN_AUDIT_MODEL,
  ADMIN_AUDIT_OPTIONS,
  ADMIN_AUDIT_CREATE,
  ADMIN_AUDIT_EDIT_FIELDS,
  ADMIN_AUDIT_TEMPLATE_ACTIVITY_ROW_FIELDS,
  AUDIT_QUESTION,
  ADMIN_AUDIT_QUESTION_MODEL,
  ADMIN_AUDIT_QUESTION_ROW_FIELDS,
  ADMIN_AUDIT_QUESTION_CREATE_FIELDS
} from '^/components/admin/audits/audit';

import {PAGE_SIZE} from '^/components/admin/audits/constants';


export class EditAuditAdmin extends PureComponent {
  componentDidMount() {
    const { auditId, itemOptions } = this.props;
    if (itemOptions.isEmpty()) {
      this.getItemOptions(auditId);
    }
    this.props.getAllCollection(ADMIN_AUDIT_CATEGORIES, {filters: {audit_type: auditId}});
  }

  handleComplete(model, data, collectionName, formName, newItem) {
    this.props.routePush('/admin/audits/question/' + newItem.id);
  }

  getItemOptions(auditId) {
    this.props.getItemOptions(ADMIN_AUDIT_MODEL, auditId, ADMIN_AUDIT_OPTIONS);
  }

  render() {
    const {
      auditId,
      audit,
      itemOptions,
      auditQuestionFilter,
      auditCategoryIds
    } = this.props;

    return (
      <div>
        <AdminContainer title="Audit Type">
          <AdminEdit
            readOnlyFields={fromJS([])}
            itemId={auditId}
            controlName={ADMIN_AUDIT_CREATE}
            collectionName={ADMIN_AUDIT_MODEL}
            model={ADMIN_AUDIT_MODEL}
            fields={ADMIN_AUDIT_EDIT_FIELDS}
            title="Edit Audit"
          />
        </AdminContainer>
        <AdminContainer className="mt-2 small-text">
          <AdminManyRelatedField
            addLabel="Assign template activities to Audit"
            itemId={auditId}
            controlName="AUDIT_TEMPLATE_ACTIVITY"
            options={itemOptions}
            model={ADMIN_AUDIT_MODEL}
            fieldName={['template_activities']}
            fields={ADMIN_AUDIT_TEMPLATE_ACTIVITY_ROW_FIELDS}
            listTitle="Template Activities"
            defaultLookupFilters={{sector: audit && audit.get('sector')}}
            sortBy={['name']}
          />
        </AdminContainer>
        <AdminContainer title="Audit Questions">
          <HelpBlock>
            <p className="small-text">
              These questions will be related to the audit
            </p>
          </HelpBlock>
          <AdminCreate
            collectionName={AUDIT_QUESTION}
            controlName={AUDIT_QUESTION}
            model={ADMIN_AUDIT_QUESTION_MODEL}
            fields={ADMIN_AUDIT_QUESTION_CREATE_FIELDS}
            transform={config => transformAuditQuestionCategoryOptions(auditCategoryIds, config)}
            onComplete={this.handleComplete.bind(this)}
            title="Add Audit Question"
          />
          <AdminList
            noSearch
            pageSize={PAGE_SIZE}
            listName={AUDIT_QUESTION}
            model={ADMIN_AUDIT_QUESTION_MODEL}
            fields={ADMIN_AUDIT_QUESTION_ROW_FIELDS}
            defaultFilters={Map(auditQuestionFilter)}
          />
        </AdminContainer>
      </div>
    );
  }
}


export function mapStateToProps(state, props) {
  const auditId =  props.params.uuid
  return {
    auditId,
    itemOptions: state.items.getIn(['options', ADMIN_AUDIT_OPTIONS], Map()),
    auditCategoryIds: state.collections.getIn([ADMIN_AUDIT_CATEGORIES, 'items'], List()).map(category => category.get('id')),
    auditQuestionFilter: { audit_type: auditId },
    audit: state.items.get(ADMIN_AUDIT_MODEL),
  };
}


export default connect(
  mapStateToProps,
  {
    getItemOptions,
    getAllCollection,
    routePush: routeActions.push
  }
) (EditAuditAdmin);
