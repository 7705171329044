import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Loading from '^/components/app/content/Loading';
import { isPending } from '^/consts/responseStates';

const LockUser = ({isLocked, setLock, response}) => (

  <div className="inline lock-user">
    <h4>Lock / Unlock</h4>
    {isPending(response) ? <Loading /> : (
      <button className="btn btn-warning" onClick={() => setLock(!isLocked)}>
        { !isLocked ? 'Lock' : 'Unlock' }
      </button>
    )}
  </div>

);


LockUser.propTypes = {
  isLocked: React.PropTypes.bool.isRequired,
  setLock: React.PropTypes.func.isRequired,
  response: ImmutablePropTypes.map,
};

export default LockUser;
