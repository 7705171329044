import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List, Map } from 'immutable';

import { isPending } from '^/consts/responseStates';
import { PRIVATE_TEMPLATES_COPIES } from '^/consts/collectionKeys';
import { getAllCollection } from '^/actions/collections';
import { setSelectValue, clearSelect } from '^/actions/actions';
import { changeStepAndAddReassignData } from '^/actions/actionSequences';
import { USER_REASSIGN_STEPS, REASSIGN_MEMBER, REASSIGN_STEPS } from '^/consts/retireSuspend';

import Loading from '^/components/app/content/Loading';
import EmptyListPage from '^/components/app/practices/reassign-member/EmptyListPage';
import MemberFilter from '^/components/app/practices/reassign-member/MemberFilter';
import PureComponent from '^/components/common/PureComponent';
import
  ReassignPrivateDocumentsForm
  from '^/components/app/practices/reassign-member/ReassignPrivateDocumentsForm';


export class ReassignPrivateDocuments extends PureComponent {

  componentWillMount() {
    const { loadPrivateDocuments } = this.props;
    loadPrivateDocuments();
  }

  render() {
    const {
      loading, privateDocuments, setDefaultMember,
      memberSelectValue, memberChoices,
      handleSubmit
    } = this.props;

    if (loading) { return <Loading />; }

    if (privateDocuments.isEmpty()) {
      return (
        <EmptyListPage
          reassignType="Private Documents"
          handleSubmit={handleSubmit}
        />
      );
    }

    const privateDocumentIds =
      privateDocuments
        .map(privateDocument => privateDocument.get('id'));

    const initialValues = Map(
        privateDocumentIds
          .map(id => [id, memberSelectValue || memberChoices.first().get('value')])
      ).toJS();

    return (
      <div className="mb-1">
        <em>Reassign all private documents to &nbsp;</em>
        <MemberFilter
          choices={memberChoices}
          onChange={setDefaultMember}
          value={memberSelectValue}
        />
        <ReassignPrivateDocumentsForm
          key={memberSelectValue}
          memberChoices={memberChoices}
          privateDocuments={privateDocuments}
          fields={privateDocumentIds.toJS()}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      </div>
    );
  }
}

ReassignPrivateDocuments.propTypes = {
  member: ImmutablePropTypes.map.isRequired,
  practice: ImmutablePropTypes.map.isRequired,
  memberChoices: ImmutablePropTypes.list.isRequired,
  currentStep: React.PropTypes.string.isRequired,
};

export function mapStateToProps(state) {
  const loading = isPending(state.responses.getIn(['getCollection', PRIVATE_TEMPLATES_COPIES]));

  return {
    privateDocuments: state.collections.getIn([PRIVATE_TEMPLATES_COPIES, 'items'], List()),
    memberSelectValue: state.ui.getIn(['selectState', REASSIGN_MEMBER]),
    loading,
  };
}

export function mapDispatchToProps(dispatch, ownProps) {
  const { currentStep, member } = ownProps;

  const nextStepIndex = USER_REASSIGN_STEPS.indexOf(currentStep) + 1;
  const nextStep = USER_REASSIGN_STEPS.get(nextStepIndex);

  return {
    loadPrivateDocuments: () =>
      dispatch(getAllCollection(PRIVATE_TEMPLATES_COPIES, {filters: {staff_practice: member.get('id')}})),
    setDefaultMember: val => dispatch(setSelectValue(REASSIGN_MEMBER, val)),
    clearDefaultMember: () => dispatch(clearSelect(REASSIGN_MEMBER)),
    handleSubmit: data =>
      dispatch(changeStepAndAddReassignData(REASSIGN_STEPS, nextStep, currentStep, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReassignPrivateDocuments);
