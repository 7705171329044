import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Map, OrderedMap } from 'immutable';

import { isPending } from '^/consts/responseStates';
import { PRACTICES, ACTIVITIES } from '^/consts/collectionKeys';
import { isLoggedOnUserAdminOfCurrentPractice, isUpdatingFormField } from '^/stateHelpers';
import { loadItem } from '^/actions/items';
import { STAFF_PRACTICE_TYPE } from '^/models/staffPractice';

import Icon from '^/components/app/content/Icon';
import Loading from '^/components/app/content/Loading';
import EditableFieldChoices from '^/components/app/widgets/EditableFieldChoices';
import PureComponent from '^/components/common/PureComponent';


export class ResponsibleStaff extends PureComponent {

  componentWillMount() {
    this.props.loadItem(PRACTICES, this.props.currentPracticeId);
  }

  render() {
    const {
      activity,
      practice,
      isAdmin,
      loadingPractice,
      loadingActivity,
    } = this.props;

    if (loadingPractice || loadingActivity) {
      return <Loading className="small inline" />;
    }

    const practiceMemberChoices =
      practice
        .get('members')
        .filter(member => member.get('type') !== STAFF_PRACTICE_TYPE.READ_ONLY)
        .map(member => member.getIn(['user', 'staffdetail']))
        .map(staffdetail => [staffdetail.get('id'), staffdetail.get('full_name')]);

    return (
      <span>
        <Icon type="user" className="metadata-icon" />
        {(isAdmin && activity && !activity.get('is_completed')) ? (
          <EditableFieldChoices
            choices={OrderedMap(practiceMemberChoices)}
            object={activity}
            valuePath={['responsible_staff', 'id']}
            model={ACTIVITIES}
            fieldName="responsible_staff_id"
          />
        ) : (
          <span>{activity.getIn(['responsible_staff', 'full_name'])}</span>
        )}
      </span>
    );
  }
}

ResponsibleStaff.propTypes = {
  loadItem: React.PropTypes.func.isRequired,
  currentPracticeId: React.PropTypes.string.isRequired,
  practice: ImmutablePropTypes.map.isRequired,
  activity: ImmutablePropTypes.map.isRequired,
};

export function mapStateToProps(state) {
  const practice = state.items.get(PRACTICES) || Map();
  const activity = state.items.get(ACTIVITIES) || Map();

  const isAdmin = isLoggedOnUserAdminOfCurrentPractice(state);

  return {
    practice,
    activity,
    isAdmin,
    loadingPractice: practice.isEmpty() || isPending(state.responses.getIn(['loadItem', PRACTICES])),
    loadingActivity: activity.isEmpty() || isUpdatingFormField(state, ACTIVITIES, 'responsible_staff_id'),
    currentPracticeId: state.currentPractice && state.currentPractice.get('id'),
  };
}

export default connect(mapStateToProps, { loadItem })(ResponsibleStaff);
