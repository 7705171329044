import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Icon from '^/components/app/content/Icon';
import DateFilter from '^/components/app/groups/insights/filters/DateFilter';
import { FILTER_KEYS } from '^/components/app/groups/insights/constants';

const DateRangeFilter = ({insightsConfig, dateFrom, dateTo, isDisabled}) => {
  return (
    <span className="date-wrapper print-hide">
      <DateFilter
        filterKey={FILTER_KEYS.DATE_FROM}
        insightsConfig={insightsConfig}
        maxDate={dateTo}
        isDisabled={isDisabled}
      />
      <Icon type="arrow-right" className="link-icon ml-1-2" />
      <DateFilter
        className="ml-1-2"
        filterKey={FILTER_KEYS.DATE_TO}
        insightsConfig={insightsConfig}
        minDate={dateFrom}
        isDisabled={isDisabled}
      />
    </span>
  );
};

DateRangeFilter.propTypes = {
  insightsConfig: ImmutablePropTypes.list.isRequired,
  dateFrom: React.PropTypes.string,
  dateTo: React.PropTypes.string
};

export default DateRangeFilter;
