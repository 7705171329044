import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { loadInsightData } from '^/actions/insights';
import PureComponent from '^/components/common/PureComponent';


const withLoadInsightDataOnMount = ({ insight, url, getFilters }) => (Component) => {
  class ComponentWithLoadInsightDataOnMount extends PureComponent {
    componentDidMount() {
      this.props.loadInsightData(insight, url, getFilters() || Map());
    }

    componentWillUpdate(nextProps) {
      if (this.props.country !== nextProps.country) {
        const filters = getFilters().set('country', nextProps.country);
        this.props.loadInsightData(insight, url, filters || Map());
      }
    }

    render() {
      return (<Component {...this.props} />);
    }
  }

  return connect(undefined, { loadInsightData })(ComponentWithLoadInsightDataOnMount);
};

export default withLoadInsightDataOnMount;
