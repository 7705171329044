import React from 'react';
import { connect } from 'react-redux';

import { deleteWorkingVersionandReloadTemplateVersion } from '^/actions/actionSequences';
import { closeModal } from '^/actions/modals';
import { ADMIN_TEMPLATE_DOCUMENT_WORKING_VERSIONS } from '^/consts/collectionKeys';
import LiveButton from '^/components/app/forms/LiveButton';

export function DiscardWorkingVersionModal({
  response, workingVersionId, templateVersion, discard, cancel
}) {

  return (
    <div className="p-1">
      <p className="mb-1">
        Are you sure you want to discard this working version
        of {templateVersion.get('template_document_display')} (Version {templateVersion.get('version_number')})?
        You will not be able to undo this action.
      </p>

      <LiveButton
        className="btn-warning mr-1"
        response={response}
        onClick={() => discard(workingVersionId, templateVersion.get('id'))}
      >
        Discard
      </LiveButton>

      <LiveButton
        className="btn-default"
        response={response}
        onClick={() => cancel()}
      >
        Cancel
      </LiveButton>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    response: state.responses.getIn(['deleteItem', ADMIN_TEMPLATE_DOCUMENT_WORKING_VERSIONS])
  };
}

export default connect(
  mapStateToProps,
  { discard: deleteWorkingVersionandReloadTemplateVersion, cancel: closeModal }
) (DiscardWorkingVersionModal);
