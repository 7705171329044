import { createSelector } from 'reselect';

import { AGILO_ITEAM_URL } from '^/consts/hr';
import { isTotalHRMember } from '^/models/practice';
import { selectCurrentPractice } from '^/selectors/practice';

export const selectTotalHRDashboardUrl = createSelector(
  selectCurrentPractice,
  (currentPractice) => currentPractice &&
    isTotalHRMember(currentPractice) &&
    currentPractice.get('breathehr_dashboard_url') ||
    AGILO_ITEAM_URL
);
