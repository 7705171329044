import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import classNames from 'classnames';

import PureComponent from '^/components/common/PureComponent';

export class Tab extends PureComponent {

  pathMatches() {
    const { link, pathname, matches } = this.props;
    if (!pathname) {
      return false;
    }

    if (pathname === link) {
      return true;
    }

    if (!matches) {
      return false;
    }

    return typeof matches === 'string' ?
      (pathname === matches) : pathname.match(matches);
  }

  render() {
    const { link, children } = this.props;

    return (
      <li className="admin-tab">
        <Link
          to={link}
          className={classNames('nav-primary-link', this.pathMatches() && 'active')}
        >
          {children}
        </Link>
      </li>
    );
  }
}

Tab.propTypes = {
  link: React.PropTypes.string.isRequired,
  matches: React.PropTypes.any,
};

export function mapStateToProps(state) {
  return {
    pathname: state.routing.location.pathname
  };
}

export default connect(mapStateToProps)(Tab);
