import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { routeActions } from 'react-router-redux';
import { isGroupDocument } from '^/utils';
import { isAdminOfPractice } from '^/models/staffPractice';
import {
  practiceHasDocumentsToReadAccess,
  practiceHasAccessToDocumentReviews,
} from '^/stateHelpers';
import ContentTab from '^/components/app/content/ContentTab';
import ContentTabset from '^/components/app/content/ContentTabset';
import { PRACTICE_TEMPLATE_VIEW_BY, COMMON_VIEW_BY, TEMPLATE_DOCUMENTS_TABSET } from '^/consts/templateViewBy';
import TemplatesDocuments from '^/components/app/template-documents/TemplatesDocuments';
import UploadedDocuments from '^/components/app/template-documents/UploadedDocuments';
import DocumentReadTracker from '^/components/app/template-documents/DocumentReadTracker';

const IS_GROUP = 'IS_GROUP';
const IS_ADMIN = 'IS_ADMIN'


// defer computation so that subcomponents can be mocked in tests
function getTabs(hasDocumentsToReadAccess, hasDocumentsToReviewAccess) {
  const renderViewByOptions = hasDocumentsToReviewAccess ?
    PRACTICE_TEMPLATE_VIEW_BY :
    COMMON_VIEW_BY;
  
  return List([
    {
      name: 'Practice Documents',
      children: (
        <TemplatesDocuments
          defaultViewBy={PRACTICE_TEMPLATE_VIEW_BY.FOLDER}
          viewByOptions={renderViewByOptions}
          showingViewBy
        />
      ),
      path: '',
      restriction: null
    },
    {
      name: 'Uploaded Documents',
      children: <UploadedDocuments />,
      path: 'uploaded/',
      restriction: null
    },
    {
      name: 'Group Documents',
      children: (
        <TemplatesDocuments
          groupDocuments
          defaultViewBy={PRACTICE_TEMPLATE_VIEW_BY.FOLDER}
          viewByOptions={renderViewByOptions}
          showingViewBy
        />
      ),
      path: 'group/',
      restriction: IS_GROUP,
    },
    ...hasDocumentsToReadAccess ? [{
      name: 'Document Read Tracker',
      children: (
        <DocumentReadTracker />
      ),
      path: 'document-read-tracker/',
      restriction: IS_ADMIN,
    }] : [],
  ]);
}

function renderTabView(showGroupDocuments, isAdmin, onSetActiveTab, name, children, restriction, idx) {
  if (restriction === IS_GROUP && !showGroupDocuments) {
    return <noscript />
  };
  if (restriction === IS_ADMIN && !isAdmin) {
    return <noscript />
  };
  return (
    <ContentTab key={idx} tabsetName={TEMPLATE_DOCUMENTS_TABSET} tabId={idx} tabName={name} onSetActive={() => onSetActiveTab(idx)}>
      {children}
    </ContentTab>
  )
}

const tabAt = path => getTabs().findLastIndex(tab => path.includes('/' + tab.path));

export const TemplatesDocumentsPage = ({
  showGroupDocuments,
  activeTab,
  isAdmin,
  onSetActiveTab,
  hasDocumentsToReadAccess,
  hasDocumentsToReviewAccess,
}) => (
  <div>
  <ContentTabset className="mt-2" name={TEMPLATE_DOCUMENTS_TABSET} activeTab={activeTab} defaultActiveTab={activeTab} >
    {getTabs(hasDocumentsToReadAccess, hasDocumentsToReviewAccess).map(({name, children, restriction}, idx) =>
      renderTabView(showGroupDocuments, isAdmin, onSetActiveTab, name, children, restriction, idx)
    ).toArray()}
  </ContentTabset>
  </div>
);


export function mapStateToProps(state, props) {
  const { userProfile, currentPractice } = state;

  const showGroupDocuments = isGroupDocument(currentPractice, userProfile);
  const isAdmin = isAdminOfPractice(currentPractice, userProfile);
  const hasDocumentsToReadAccess = practiceHasDocumentsToReadAccess(state);
  const hasDocumentsToReviewAccess = practiceHasAccessToDocumentReviews(state);

  return {
    showGroupDocuments,
    isAdmin,
    hasDocumentsToReadAccess,
    hasDocumentsToReviewAccess,
    activeTab: tabAt(props.location.pathname),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    onSetActiveTab: tabId => dispatch(routeActions.push('/page/documents/' + getTabs().get(tabId).path))
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (TemplatesDocumentsPage);
