import React from 'react';

import { hasSucceeded } from '^/consts/responseStates';
import { RESPONSIBILITIES, ACTIVITIES, TASKS, PRIVATE_DOCUMENTS } from '^/consts/retireSuspend';

import SuccessMessage from '^/components/app/content/SuccessMessage';
import PureComponent from '^/components/common/PureComponent';
import LiveButton from '^/components/app/forms/LiveButton';


export class ConfirmReassignment extends PureComponent {

  componentDidUpdate() {
    const { response, onSuccess } = this.props;

    if (hasSucceeded(response)) {
      onSuccess();
    }
  }

  getSize(maybeMap) {
    return maybeMap ? maybeMap.size : '0';
  }

  render() {
    const { response, onSubmit, reassignData, action } = this.props;

    const hasReassignments = reassignData && reassignData.some(each => each && !each.isEmpty());

    return (
      <div className="mb-1">
        <p>Please confirm that you wish to {action} this staff member.</p>

        {hasReassignments &&
          <p>
            {this.getSize(reassignData.get(TASKS))} tasks,
            {' ' + this.getSize(reassignData.get(RESPONSIBILITIES))} responsibilities,
            {' ' + this.getSize(reassignData.get(ACTIVITIES))} activities,
            and {this.getSize(reassignData.get(PRIVATE_DOCUMENTS))} private documents will be reassigned.
          </p>
        }

        <SuccessMessage response={response}>
          Successfully reassigned!
        </SuccessMessage>

        <LiveButton
          className="btn-default mt-1"
          response={response}
          pendingMessage="Saving..."
          onClick={onSubmit}
        >
          Confirm
        </LiveButton>
      </div>
    );
  }
}

ConfirmReassignment.propTypes = {
  onSubmit: React.PropTypes.func.isRequired,
  action: React.PropTypes.string.isRequired,
};

export default ConfirmReassignment;
