import React from 'react';
import { connect } from 'react-redux';

import Loading from '^/components/app/content/Loading';
import PureComponent from '^/components/common/PureComponent';
import AssessmentsGroupConfigForm from '^/components/app/groups/group-config/AssessmentsGroupConfigForm';

import { excludeTemplateActivitiesByGroup } from '^/actions/actions';
import { isPending } from '^/consts/responseStates';
import { getAllCollection } from '^/actions/collections';
import { GROUP_CONFIG_ASSESSMENTS } from '^/consts/collectionKeys';

export class AssessmentsGroupConfigPage extends PureComponent {

  componentDidMount() {
    this.props.getAllCollection(GROUP_CONFIG_ASSESSMENTS);
  }

  transformAndSubmitData(data) {
    const submitData = Object.entries(data).map(
      item => ({template_activity: item[0], is_excluded: !item[1] })
    );
    
    this.props.excludeTemplateActivitiesByGroup(submitData);
  }

  render() {
    const { groupConfigAssessments, isLoading, excludeTemplateActivitiesByGroupResponse } = this.props;

    if (isLoading || !groupConfigAssessments) {
      return <Loading />;
    }

    return (
      <AssessmentsGroupConfigForm
        templateAssessments={groupConfigAssessments}
        onSubmit={data => this.transformAndSubmitData(data)}
        response={excludeTemplateActivitiesByGroupResponse}
      />
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    groupConfigAssessments: state.collections.getIn([GROUP_CONFIG_ASSESSMENTS, 'items']),
    isLoading: isPending(state.responses.getIn(['getCollection', GROUP_CONFIG_ASSESSMENTS])),
    excludeTemplateActivitiesByGroupResponse: state.responses.get('excludeTemplateActivitiesByGroup'),
  };
};

export default connect(mapStateToProps,
  { getAllCollection, excludeTemplateActivitiesByGroup }
)(AssessmentsGroupConfigPage);
