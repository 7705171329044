import { CALL_API, isRSAA } from 'redux-api-middleware';
import { isFSA } from 'flux-standard-action';
import _ from 'underscore';


export function addCallbacksToBareAction(action, success, failure) {
  if (isRSAA(action)) {
    if (success) {
      action[CALL_API].types[1].meta = _.extend({ callback: success }, action[CALL_API].types[1].meta);
    }
    if (failure) {
      action[CALL_API].types[2].meta = _.extend({ callback: failure }, action[CALL_API].types[2].meta);
    }
  } else if (isFSA(action)) {
    action.meta = _.extend({ callback: success }, action.meta);
  } else {
    throw new Error(
      'Attempted to add callbacks to nonstandard action! ' +
      'Please make sure your action matches either FSA or RSAA standards.'
    );
  }
  return action;
}

export function addCallbacks(actionCreator, success, failure) {
  return function (...args) {
    const action = actionCreator(...args);
    return addCallbacksToBareAction(action, success, failure);
  };
}

export default store => next => action => {
  if (action.meta && action.meta.callback) {
    const result = action.meta.callback(action.payload, action);
    // If we're provided with a new action from the callback, continue the chain!
    if (isRSAA(result) || isFSA(result)) {
      store.dispatch(result);
    }
  }
  return next(action);
};
