import React from 'react';
import MaybeLink from '^/components/app/content/MaybeLink';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { isAssignedTo } from '^/models/task';


function makeLink(userStaffId, task) {
  const isOwn = isAssignedTo(userStaffId)(task);
  return `/page/tasks${isOwn ? '' : '/manage'}/${task.get('id')}/`;
}

export const TaskLink = ({task, children, className, userStaffId}) => {
  const { title } = task.toObject();

  return (
    <MaybeLink
      className={className}
      title={title}
      to={makeLink(userStaffId, task)}
    >
      {children}
    </MaybeLink>
  );
};


TaskLink.propTypes = {
  task: ImmutablePropTypes.map.isRequired,
};

export function mapStateToProps(state) {
  return {
    userStaffId: state.userProfile.getIn(['staffdetail', 'id']),
  };
}

export default connect(mapStateToProps) (TaskLink);
