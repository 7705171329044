import React from 'react';
import classNames from 'classnames';

import PureComponent from '^/components/common/PureComponent';
import { convertRateToPercentage, getRateColourClassNames } from '^/utils';

export default class BatteryBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      width: 0
    };
    this.setChartRef = element => {
      this.chart = element;
    };
  }

  render() {
    const { rate, height, borderRadius, thresholdBad, thresholdOk, className } = this.props;
    window.setTimeout(() => {
      this.setState(state => ({
        ...state,
        width: convertRateToPercentage(rate)
      }));
    }, 100);

    return (
      <div
        className={classNames("insights-battery-bar", className)}
        style={{height: `${height}px`, 'borderRadius': `${borderRadius}px`}}
      >
        <div
          className={classNames(
            getRateColourClassNames(rate, thresholdBad, thresholdOk)
          )}
          style={{width: `${this.state.width}%`, 'borderRadius': `${borderRadius}px`}}
        />
      </div>
    );
  }
}

BatteryBar.propTypes = {
  rate: React.PropTypes.number,
  className: React.PropTypes.string,
  height: React.PropTypes.number.isRequired,
  borderRadius: React.PropTypes.number.isRequired,
  thresholdBad: React.PropTypes.number.isRequired,
  thresholdOk: React.PropTypes.number.isRequired,
};
