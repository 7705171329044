import composeReducers from '^/reducers/composeReducers';
import { Map, fromJS, List } from 'immutable';
import {
  ACTIVITIES,
  TEMPLATE_DOCUMENT_VERSION,
  ACTIVITY_RELATED_TEMPLATE,
  TASKS,
  DOC_READ_REQUEST_BULK_SEND,
  GROUP_TASKS,
} from '^/consts/collectionKeys';
import * as actionsItems from '^/actions/items';
import * as actions from '^/actions/actions';

function itemsReducer(state = Map(), action) {
  switch (action.type) {
    case actionsItems.CLEAR_ITEM:
      return state.set(action.payload, null);
    case actionsItems.LOAD_ITEM.REQUEST:
    case actionsItems.LOAD_TEMPLATE_LATEST_VERSION.REQUEST:
      return action.meta.id !== state.getIn([action.meta.itemType, 'id'])
        ? state.set(action.meta.itemType, null)
        : state;
    case actionsItems.LOAD_TEMPLATE_LATEST_GROUP_VERSION.REQUEST:
      return action.meta.id !== state.getIn([action.meta.itemType, 'id'])
        ? state.set(action.meta.itemType, null)
        : state;
    case actionsItems.LOAD_ITEM.SUCCESS:
    case actionsItems.LOAD_TEMPLATE_LATEST_VERSION.SUCCESS:
    case actionsItems.LOAD_TEMPLATE_LATEST_GROUP_VERSION.SUCCESS:
      return state.set(action.meta.itemType, fromJS(action.payload));
    case actionsItems.SEND_DOCUMENT_READ_TO_TEAM.SUCCESS:
      if (action.meta.activityDocumentId) {
        return state.setIn(
          [ACTIVITIES, 'template_documents'],
          state.getIn([ACTIVITIES, 'template_documents']).map(templateDocument => {
            if (templateDocument.get('id') === action.meta.activityDocumentId) {
              return templateDocument.set('doc_read_requests', fromJS(action.payload));
            }
            return templateDocument;
          })
        );
      }
      const templateDocumentVersionKey = (
        action.meta.isActivityTemplatePage ? ACTIVITY_RELATED_TEMPLATE : TEMPLATE_DOCUMENT_VERSION
      );
      return state.setIn(
        [templateDocumentVersionKey, 'doc_read_requests'],
        fromJS(action.payload)
      );
    case actionsItems.UPDATE_ITEM.SUCCESS:
      if (
        state.getIn([action.meta.collectionName, 'id']) === action.payload.id
      ) {
        if (action.meta.merge) {
          return state.set(
            action.meta.collectionName,
            state.get(action.meta.collectionName).mergeDeep(action.payload)
          );
        }
        return state.set(action.meta.collectionName, fromJS(action.payload));
      }
      if (
        state.getIn([
          action.meta.collectionName,
          'copied_template_document',
          'id',
        ]) === action.payload.id
      ) {
        return state.setIn(
          [action.meta.collectionName, 'copied_template_document', 'name'],
          action.payload.name
        );
      }
      return state;
    case actions.UPDATE_COLLABORATORS.SUCCESS:
      if (
        state.getIn([
          TEMPLATE_DOCUMENT_VERSION,
          'copied_template_document',
          'id',
        ]) === action.payload.id
      ) {
        return state.setIn(
          [
            TEMPLATE_DOCUMENT_VERSION,
            'copied_template_document',
            'collaborators',
          ],
          List(action.payload.collaborators)
        );
      }
      return state;
    case actionsItems.CREATE_TEMPLATE_VERSION.SUCCESS:
    case actionsItems.ADOPT_TEMPLATE_VERSION.SUCCESS:
      return state.set(action.meta.collectionName, fromJS(action.payload));
    case actionsItems.SAVE_AS_WORKING_DRAFT.SUCCESS:
      if (
        state.getIn([action.meta.collectionName, 'id']) ===
        action.payload.source_version.id
      ) {
        return state.setIn(
          [action.meta.collectionName, 'working_version'],
          fromJS(action.payload)
        );
      }
      return state;
    case actionsItems.CREATE_ITEM.SUCCESS:
      if (action.meta.collectionName === DOC_READ_REQUEST_BULK_SEND) {
        return state.setIn(
          [DOC_READ_REQUEST_BULK_SEND, 'job_tracker_id'],
          action.payload.job_tracker_id
        );
      }
      if (action.meta.collectionName === GROUP_TASKS) {
        return state.setIn([GROUP_TASKS], fromJS(action.payload));
      }
      return action.meta.collectionName === TASKS
        ? state.updateIn(
            [ACTIVITIES, 'tasks'],
            tasks => tasks && tasks.push(fromJS(action.payload))
          )
        : state;
    case actionsItems.GET_ITEM_OPTIONS.SUCCESS:
      return state.setIn(
        ['options', action.meta.collectionName],
        fromJS(action.payload)
      );
    default:
      return state;
  }
}

export default composeReducers([itemsReducer], Map());
