import { Map } from 'immutable';
import moment from 'moment';
import {
  getFirstInstanceOfWeekdayOnOrBeforeDate,
  getFirstInstanceOfWeekdayInMonth,
} from '^/utils';

export const REPETITION_INTERVALS_TEXT = Map({
  ANNUALLY: 'Annually',
  SIX_MONTHLY: '6 Monthly',
  QUARTERLY: 'Quarterly',
  MONTHLY: 'Monthly',
  BIENNIAL: 'Biennial',
});

export const REPETITION_INTERVALS_MONTHS = Map({
  ANNUALLY: 12,
  SIX_MONTHLY: 6,
  QUARTERLY: 3,
  MONTHLY: 1,
  BIENNIAL: 24,
});

export function calculateNextDueDate(
  lastDone,
  audit = Map(),
  cycleStartDate = moment(),
  preferredWeekday = 1,
  neverDone = false
) {
  // TODO: add behaviour for existing practices

  if (!neverDone && (!lastDone || !moment(lastDone).isValid())) {
    return null;
  }

  const urgencyRule = audit.get('urgency_rule') || Map();

  // if no urgency rule
  if (urgencyRule.isEmpty()) {
    const nextDueDate = neverDone
      ? getFirstInstanceOfWeekdayInMonth(
          moment().add(1, 'months'),
          preferredWeekday
        )
      : getFirstInstanceOfWeekdayOnOrBeforeDate(
          moment(lastDone).add(
            REPETITION_INTERVALS_MONTHS.get(
              audit.get('repetition_interval'),
              0
            ),
            'months'
          ),
          preferredWeekday
        );

    return nextDueDate.isBefore(cycleStartDate) ? cycleStartDate : nextDueDate;
  }

  const {
    cycle_month_to_complete_new_practice_if_never_done: newPracticeMonth,
    default_frequency,
  } = urgencyRule.toObject();

  const nextDueDate = neverDone
    ? getFirstInstanceOfWeekdayInMonth(
        moment(cycleStartDate).add(newPracticeMonth, 'months'),
        preferredWeekday
      )
    : getFirstInstanceOfWeekdayOnOrBeforeDate(
        moment(lastDone).add(default_frequency, 'months'),
        preferredWeekday
      );

  return nextDueDate.isBefore(cycleStartDate) ? cycleStartDate : nextDueDate;
}
