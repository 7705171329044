export const ALERT_TYPES = {
  NEW_OR_UPDATED_DOCUMENT: 'NEW_OR_UPDATED_DOCUMENT',
  ACTIVITY_REASSIGNED: 'ACTIVITY_REASSIGNED',
  ACTIVITY_DATES_UPDATED: 'ACTIVITY_DATES_UPDATED',
};

export const IS_DISSMISED_CHOICES = {
  NOT_DISMISSIBLE: 'NOT_DISMISSIBLE',
};

export const PRACTICE_ALERT_TYPES = {
  N_MEMBERSHIPS: 'N_MEMBERSHIPS',
  ALL_PRACTICES: 'ALL_PRACTICES',
  SINGLE_PRACTICE: 'SINGLE_PRACTICE'
};
