function getUsername(name) {
  return window.localStorage.getItem(name);
}

function setUsername(name, value) {
  window.localStorage.setItem(name, value);
}

function clearUsername(name) {
  setUsername(name, '');
}

const USERNAME = 'email';

const UsernameStore = {
  get: () => getUsername(USERNAME),
  set: (value) => setUsername(USERNAME, value),
  clear: () => clearUsername(USERNAME)
};

export default UsernameStore;
