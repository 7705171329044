import React from 'react';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminEditCreate from '^/components/admin/content/edit-create/AdminEditCreate';
import AdminList from '^/components/admin/content/list/AdminList';
import { Map } from 'immutable';

import {
  ADMIN_COMPLIANCE_SYSTEM_CREATE,
  ADMIN_COMPLIANCE_SYSTEM_MODEL,
  ADMIN_COMPLIANCE_SYSTEM_EDIT_FIELDS,
  ADMIN_COMPLIANCE_SYSTEM_ROW_FIELDS,
} from './compliance-systems';


export default () =>
  <div>
    <AdminContainer title="Compliance Systems">
      <AdminEditCreate
        controlName={ADMIN_COMPLIANCE_SYSTEM_CREATE}
        collectionName={ADMIN_COMPLIANCE_SYSTEM_CREATE}
        model={ADMIN_COMPLIANCE_SYSTEM_MODEL}
        fields={ADMIN_COMPLIANCE_SYSTEM_EDIT_FIELDS}
        title="Add Compliance System"
      />
    </AdminContainer>

    <AdminContainer>
      <AdminList
        noSearch
        title="Root Compliance Systems"
        model={ADMIN_COMPLIANCE_SYSTEM_MODEL}
        defaultFilters={Map({ root: 'True' })}
        fields={ADMIN_COMPLIANCE_SYSTEM_ROW_FIELDS}
        isSectorFiltered
      />
    </AdminContainer>
  </div>;
