import React from 'react';
import Tab from '^/components/admin/nav/Tab';

const Nav = () =>
  <div className="admin-nav col-1-4 mb-2">
    <nav className="admin-nav-primary">
      <ul>
        <Tab link="/admin" matches="/admin/">Home</Tab>
        <Tab link="/admin/alerts">Alerts</Tab>
        <Tab link="/admin/reminders">Reminders</Tab>
        <Tab link="/admin/users">User Accounts</Tab>
        <Tab link="/admin/admin-users">Admin User Accounts</Tab>
        <Tab link="/admin/discount-codes">Discount Codes</Tab>
        <Tab link="/admin/practices">Practices</Tab>
        <Tab link="/admin/calendar-previews">Calendar Previews</Tab>
        <Tab link="/admin/compliance-systems">Compliance Systems</Tab>
        <Tab link="/admin/activities">Activities</Tab>
        <Tab link="/admin/audits">Audits</Tab>
        <Tab link="/admin/healthcheck">Healthcheck</Tab>
        <Tab link="/admin/template-documents">Template Documents</Tab>
        <Tab link="/admin/qmr-reports">QMR Reports</Tab>
        <Tab link="/admin/qps-page">QPS Page</Tab>
        <Tab link="/admin/responsibilities">Responsibilities</Tab>
        <Tab link="/admin/news">News</Tab>
        <Tab link="/admin/export" matches="/admin/export/">Export</Tab>
        <Tab link="/admin/reports" matches="/admin/reports/">Reports</Tab>
        <Tab link="/admin/help" matches="/admin/help/">Help Items</Tab>
        <Tab link="/admin/terms">Terms</Tab>
      </ul>
    </nav>
  </div>;

export default Nav;
