import React from 'react';
import { connect } from 'react-redux';

import { loadLastDatabaseSync } from '^/actions/insights';
import PureComponent from '^/components/common/PureComponent';


const withLoadLastUpdatedTimestampOnMount = (Component) => {
  class ComponentWithLastUpdatedTimestamp extends PureComponent {
    componentDidMount () {
      this.props.loadLastDatabaseSync();
    }

    render() {
      return (<Component {...this.props} />);
    }
  }

  return connect(undefined, { loadLastDatabaseSync })(ComponentWithLastUpdatedTimestamp);
};

export default withLoadLastUpdatedTimestampOnMount;
