import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import Nav from '^/components/app/nav/Nav';
import Main from '^/components/app//Main';
import Footer from '^/components/app/content/Footer';
import Loading from '^/components/app/content/Loading';
import { connect } from 'react-redux';


export class Page extends PureComponent {
  render() {
    const { isLoggedIn } = this.props;

    if(!isLoggedIn) {
      return( <Loading /> )
    }

    return (
      <div>
        <Nav routes={this.props.routes} />
        <Main>
          {this.props.children}
        </Main>
        <Footer />
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    isLoggedIn: Boolean(state.user && state.user.get('loggedInUserId')),
  };
}

export default connect(mapStateToProps)(Page);
