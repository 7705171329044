import { fromJS } from 'immutable';
import { PRACTICE_ALERT_TYPES } from '^/models/alerts';


export const ADMIN_ALERT_CREATE = 'ADMIN_ALERT_CREATE';
export const ADMIN_ALERT_MODEL = 'admin/practice-alerts';

export const ADMIN_ALERT_EDIT_FIELDS = fromJS([
  'message', 'type', 'memberships', 'practice'
]);

export const ADMIN_ALERT_ROW_FIELDS = fromJS([
  {name: ['message'], display_name: 'Message', type: 'string', sortable: true},
  {name: ['type_display'], display_name: 'Type', type: 'string', sortable: true},
  {name: ['memberships_display'], display_name: 'Memberships', type: 'string', sortable: true},
  {name: ['practice_display'], display_name: 'Practice', type: 'string', sortable: true},
  {name: ['dismissed'], display_name: 'Dismissed', type: 'string', sortable: true},
  {name: ['id'], display_name: 'Delete', type: 'remove', sortable: false},
]);

export const ADMIN_ALERT_TYPE_FIELDS = fromJS({
  memberships: [PRACTICE_ALERT_TYPES.N_MEMBERSHIPS],
  practice: [PRACTICE_ALERT_TYPES.SINGLE_PRACTICE]
});
