import React from 'react';
import { connect } from 'react-redux';
import { reset as resetForm } from 'redux-form';
import PureComponent from '^/components/common/PureComponent';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map, OrderedMap } from 'immutable';
import classNames from 'classnames';
import { loadItem, getItemOptions, patchItem, createItem } from '^/actions/items';
import { createItemAndRunCallback, patchItemAndRunCallback } from '^/actions/actionSequences';

import { setPendingUploadInForm } from '^/actions/actions';
import Loading from '^/components/app/content/Loading';
import { isPending, hasSucceeded } from '^/consts/responseStates';
import Form from '^/components/admin/content/edit-create/dynamic-forms/Form';


export class AdminEditCreate extends PureComponent {

  componentWillMount() {
    const { itemId, options } = this.props;
    if (!options) {
      this.loadItemAndOptions(itemId);
    } else {
      this.loadItem(itemId);
    }
  }

  componentWillReceiveProps(newProps) {
    const { itemResponse, isEditing, controlName } = newProps;
    if (this.props.itemId !== newProps.itemId) {
      this.loadItemAndOptions(newProps.itemId);
    }

    if (!isEditing && hasSucceeded(itemResponse) && !hasSucceeded(this.props.itemResponse)) {
      this.props.resetForm(controlName); // this action cannot be chained as redux form returns a non FSA :'(
    }
  }

  loadItemAndOptions(itemId) {
    const { model, isEditing, collectionName } = this.props;
    if (isEditing) {
      this.props.getItemOptions(model, itemId, collectionName);
      this.props.loadItem(model, itemId, collectionName);
    } else {
      this.props.getItemOptions(model, null, collectionName);
    }
  }

  loadItem(itemId) {
    const { model, isEditing, collectionName } = this.props;
    if (isEditing) {
      this.props.loadItem(model, itemId, collectionName);
    }
  }

  isLoading() {
    const { item, response, isEditing, itemOptions } = this.props;
    const awaitingItem = isPending(response) && !item;
    const awaitingOptions = !itemOptions || itemOptions.isEmpty();
    return (isEditing && awaitingItem) || awaitingOptions;
  }

  submitData(data) {
    const {
      isEditing,
      model,
      itemId,
      collectionName,
      defaultValues,
      onComplete,
      controlName,
      transformDataToSubmit,
      sourceItemId,
      saveModal,
    } = this.props;
    let dataToSubmit = data;
    if (sourceItemId) {
      dataToSubmit['source_item_id'] = sourceItemId;
    }
    if (typeof transformDataToSubmit === 'function') {
      dataToSubmit = transformDataToSubmit(dataToSubmit);
    }

    if (isEditing) {
      if (onComplete) {
        this.props.patchItemAndRunCallback(model, itemId, dataToSubmit, collectionName, onComplete);
      } else {
        this.props.patchItem(model, itemId, dataToSubmit, collectionName);
      }
    } else {
      const defaultedData = Object.assign(defaultValues || {}, dataToSubmit);
      if (saveModal) {
        saveModal(defaultedData);
      } else if (onComplete) {
        this.props.createItemAndRunCallback(model, defaultedData, collectionName, controlName, onComplete);
      } else {
        this.props.createItem(model, defaultedData, collectionName);
      }
    }
  }

  getFields() {
    const { getFields, item, fields } = this.props;
    return getFields ? getFields(item) : fields;
  }

  getInitialValues() {
    const { item, isEditing, getInitialValues } = this.props;
    if (typeof getInitialValues === 'function') {
      return getInitialValues(item);
    }
    return isEditing && item.toJS();
  }

  renderItem() {
    const {
      item, itemOptions, isEditing, title, getTitle, hideMessages, customTopButton,
      itemResponse, controlName, readOnly, transform, className, saveButtonText, 
      customSubmitButtons, defaultFilters
    } = this.props;

    const fields = this.getFields();

    const fieldConfig = OrderedMap(fields.map(field =>
      [field, itemOptions.getIn(field.split('.').join('.children.').split('.'), Map())]
    ));

    return (
      <Form
        title={getTitle ? getTitle(item) : title}
        className={classNames('admin-edit-create-form', className)}
        fieldClassName="admin-edit-create-field"
        onSubmit={(data) => this.submitData(data)}
        fields={fields.toJS()}
        form={controlName}
        formName={controlName}
        initialValues={this.getInitialValues()}
        fieldConfig={fieldConfig}
        transform={transform && ((_fieldConfig, _fields) => transform(_fieldConfig, _fields, item))}
        setPendingUploadInForm={this.props.setPendingUploadInForm}
        readOnly={readOnly}
        response={itemResponse}
        isEditing={!!isEditing}
        saveButtonText={saveButtonText || (isEditing ? 'Save' : 'Create')}
        hideMessages={hideMessages}
        customSubmitButtons={customSubmitButtons}
        customTopButton={customTopButton}
        defaultFilters={defaultFilters}
      />
    );
  }

  render() {
    const { item, itemOptions, isEditing } = this.props;
    const failedLoading = !itemOptions || isEditing && !item;

    if (this.isLoading()) {
      return <Loading />;
    } else if (failedLoading) {
      return <div>Failed to load item.</div>;
    }
      return this.renderItem();

  }
}

AdminEditCreate.propTypes = {
  controlName: React.PropTypes.string,
  model: React.PropTypes.string.isRequired,
  title: React.PropTypes.string,
  getTitle: React.PropTypes.func,
  itemId: React.PropTypes.string,
  fields: ImmutablePropTypes.list,
  getFields: React.PropTypes.func,
  options: ImmutablePropTypes.map,
  defaultValues: React.PropTypes.object,
  defaultFilters: React.PropTypes.map,
  onComplete: React.PropTypes.func,
  customSubmitButtons: React.PropTypes.func,
  customTopButton: React.PropTypes.func,
  sourceItemId: React.PropTypes.string,
  saveModal: React.PropTypes.func,
};

export function mapStateToProps(state, props) {
  const controlName = props.controlName || props.model;
  const collectionName = props.collectionName || controlName;
  const itemOptions = props.options || state.items.getIn(['options', collectionName], Map());
  const isEditing = !!props.itemId;
  const itemResponse = isEditing ?
    state.responses.getIn(['updateItem', collectionName]) :
    state.responses.getIn(['createItem', collectionName]);

  return {
    item: state.items.get(collectionName),
    itemOptions: itemOptions.getIn(['actions', 'POST']) || itemOptions.getIn(['actions', 'PUT']),
    response: state.responses.getIn(['loadItem', collectionName]),
    optionsResponse: state.responses.getIn(['getItemOptions', controlName]),
    itemResponse,
    isEditing,
    controlName
  };
}

export default connect(mapStateToProps,
  {
    createItem, createItemAndRunCallback, loadItem,
    getItemOptions, patchItem, setPendingUploadInForm,
    resetForm, patchItemAndRunCallback
  }
)(AdminEditCreate);
