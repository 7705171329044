import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import {
  StoreState,
  ThunkRequestState,
  ThunkRequestsByIdState,
} from '^/store/types';

interface Thing {
  requestName: keyof Required<ThunkRequestsByIdState>;
  requestId: string;
}

const selectRequestStateById = createCachedSelector(
  (state: StoreState) => state.thunkRequestsById,
  (_state: StoreState, props: Thing) => props,
  (requestState, props: Thing): undefined | ThunkRequestState => {
    return requestState[props.requestName]?.[props.requestId];
  }
)(
  (_state: StoreState, props: Thing) =>
    `${props.requestName}.${props.requestId}`
);

export const selectRequestIsLoadingById = createSelector(
  selectRequestStateById,
  requestState => Boolean(requestState?.isLoading)
);

export const selectRequestErrorsById = createSelector(
  selectRequestStateById,
  requestState => requestState?.errors
);
