import React from 'react';
import { reduxForm } from 'redux-form';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { isPending, hasSucceeded, hasFailed } from '^/consts/responseStates';
import { getIn } from '^/utils';

import Loading from '^/components/app/content/Loading';
import Field from '^/components/admin/content/edit-create/dynamic-forms/Field';
import QMRReportDownloadButton from '^/components/admin/qmr-reports/QMRReportDownloadButton';


const SuccessMessage = ({response, children}) => hasSucceeded(response) ?
  (<div className="mt-1 alert-success">{children}</div>) : (<noscript />);


const PendingMessage = ({response}) => isPending(response) ?
  (<Loading />) : (<noscript />);


const FailureMessage = ({response}) => hasFailed(response) ? (
  <div className="mt-1 alert-error">
    <p>Failed to update.</p>
    {response.getIn(['errors', 'non_field_errors'], List()).map((error, idx) => <p key={idx}>{error}</p>)}
  </div>
) : (<noscript />);


const SubmitButton = ({readOnly, response, saveButtonText}) =>
  (!readOnly && !isPending(response)) ?
  <input type="submit" value={saveButtonText || 'Save'} className="btn btn-primary pull-left" /> :
  <noscript />;


const ManageQMRReportForm = ({
      fields, handleSubmit, fieldConfig, readOnly, values,
      saveButtonText, className, fieldClassName, onPublish, itemId,
      title, response, setPendingUploadInForm, formName, transform, isEditing
}) => (
  <form className={className} onSubmit={handleSubmit}>
    <h4>{title}</h4>
    {(transform ? transform(fieldConfig, fields) : fieldConfig).map(
      (config, fieldName) => (
        <Field
          key={fieldName}
          field={getIn(fields, fieldName.split('.'))}
          fieldConfig={config}
          readOnly={readOnly}
          className={fieldClassName}
          setUpload={setPendingUploadInForm}
          handleSubmit={handleSubmit}
          formName={formName}
          isEditing={isEditing}
          errors={response && response.getIn(['errors', fieldName])}
        />
      )
    )}
    <SubmitButton readOnly={readOnly} response={response} saveButtonText={saveButtonText} />

    <QMRReportDownloadButton startRenderReport={() => onPublish(values)} itemId={itemId} />

    <PendingMessage response={response} />
    <SuccessMessage response={response}>{isEditing ? 'Successfully updated.' : 'Successfully created.'}</SuccessMessage>
    <FailureMessage response={response} />
  </form>
);

ManageQMRReportForm.propTypes = {
  formName: React.PropTypes.string.isRequired,
  className: React.PropTypes.string,
  title: React.PropTypes.string,
  fieldClassName: React.PropTypes.string,
  handleSubmit: React.PropTypes.func.isRequired,
  fieldConfig: ImmutablePropTypes.map.isRequired,
  response: ImmutablePropTypes.map,
  readOnly: React.PropTypes.bool,
  saveButtonText: React.PropTypes.string,
};

export default reduxForm({})(ManageQMRReportForm);
