import React from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';

import {
  getCollection, getAllCollection,
} from '^/actions/collections';
import {
  MY_TASKS,
  MY_TASKS_SIMPLE_LIST,
  MY_TASKS_COMPLETE
} from '^/consts/collectionKeys';
import { loadItem } from '^/actions/items';
import {
  openCreateTaskModal, openRemoveUploadedTaskAssigneeFileModal, openTogglePrivateUploadedTaskFileModal
} from '^/actions/modals';
import { isPending } from '^/consts/responseStates';
import PureComponent from '^/components/common/PureComponent';
import PermissionsComponent from '^/components/app/perms/PermissionsComponent';
import Loading from '^/components/app/content/Loading';
import EndUserTasksList from '^/components/app/tasks/enduser/EndUserTasksList';
import ViewEndUserTask from '^/components/app/tasks/enduser/ViewEndUserTask';
import CollapsibleLeftNav from '^/components/app/widgets/CollapsibleLeftNav';
import { isNotReadOnlyInCurrentPractice } from '^/models/user';


class EndUserTasksPage extends PureComponent {

  componentWillMount() {
    this.loadTasks();
    this.loadActiveTask(this.props.activeId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentPracticeId !== this.props.currentPracticeId) {
      this.loadTasks();
    }

    if (nextProps.activeId !== this.props.activeId) {
      this.loadActiveTask(nextProps.activeId);
    }
  }

  loadTasks() {
    this.props.getAllCollection(MY_TASKS_SIMPLE_LIST);
    this.loadCompletedTasks();
  }

  loadActiveTask(activeTaskId) {
    if (activeTaskId) {
      this.props.loadItem(MY_TASKS, activeTaskId);
    }
  }

  reloadData() {
    this.loadTasks();
    this.loadActiveTask(this.props.activeId);
  }

  renderNoActiveTask() {
    const { response, myTasks, activeId } = this.props;
    const message = myTasks.isEmpty() ? 'You have no tasks.' : 'Please choose a task from the list on the left.';

    return (
      <div className="empty-screen">
        {isPending(response) ? (
          <Loading />
        ) : (
          <div>
            <h5 className="text-empty-state">
              {activeId ? 'You do not have access to this task.' : message}
            </h5>
            <PermissionsComponent hasPermission={isNotReadOnlyInCurrentPractice} >
              <button type="button" className="btn btn-default" onClick={this.props.openCreateTaskModal}>
                Create a task
              </button>
            </PermissionsComponent>
          </div>
        )}
      </div>
    );
  }

  loadCompletedTasks() {
    this.props.getCollection(MY_TASKS_COMPLETE);
  }

  loadMoreCompletedTasks() {
    this.props.getCollection(MY_TASKS_COMPLETE, {page: this.props.completedTasksPage + 1, shouldAppend: true});
  }

  renderNoTasks() {
    const { response, loadCompletedResponse, activeTaskResponse } = this.props;

    return (isPending(response) || isPending(loadCompletedResponse) || isPending(activeTaskResponse)) ? (
      <Loading />
    ) : (
      <p className="help-block">No tasks.</p>
    );
  }

  render() {
    const {
      response, myTasks, activeId, activeTask, children, loadCompletedResponse, moreCompletedTasksAvailable,
      activeTaskResponse, userId
    } = this.props;

    return (
      <div className="row">
        <div className="col-1-3 secondary-left">
          <CollapsibleLeftNav>
            {children}

            {myTasks.isEmpty() ? (
              this.renderNoTasks()
            ) : (
              <div>
                <EndUserTasksList
                  myTasks={myTasks}
                  activeId={activeId}
                  onLoadCompleted={() => this.loadCompletedTasks()}
                  onLoadMoreCompleted={moreCompletedTasksAvailable && (() => this.loadMoreCompletedTasks())}
                  loadCompletedResponse={loadCompletedResponse}
                />
                {isPending(response) && <Loading />}
              </div>
            )}
          </CollapsibleLeftNav>
        </div>
        <div className="col-2-3 bordered-left-d">
          {activeTask ? (
            <ViewEndUserTask
              response={activeTaskResponse}
              myTask={activeTask}
              removeFile={this.props.openRemoveUploadedTaskAssigneeFileModal}
              onMarkCompleteComplete={() => this.reloadData()}
              togglePrivateDocument={this.props.openTogglePrivateUploadedTaskFileModal}
              userId={userId}
            />
          ) : (
            this.renderNoActiveTask()
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const myIncompleteTasks = state.collections.getIn([MY_TASKS_SIMPLE_LIST, 'items']) || List();
  const myCompletedTasks = state.collections.get(MY_TASKS_COMPLETE);
  const completedTasksPage = myCompletedTasks && myCompletedTasks.get('page');
  const moreCompletedTasksAvailable = myCompletedTasks && myCompletedTasks.get('hasMore');
  const myTasks = myIncompleteTasks.concat(myCompletedTasks && myCompletedTasks.get('items') || List());

  const activeId = props.params.uuid;
  const activeTask = state.items.get(MY_TASKS);

  return {
    activeId,
    activeTask,
    myTasks,
    moreCompletedTasksAvailable,
    completedTasksPage,
    loadCompletedResponse: state.responses.getIn(['getCollection', MY_TASKS_COMPLETE]),
    response: state.responses.getIn(['getCollection', MY_TASKS_SIMPLE_LIST]),
    activeTaskResponse: state.responses.getIn(['loadItem', MY_TASKS]),
    collapse: state.ui.get('collapse', Map()),
    currentPracticeId: state.currentPractice && state.currentPractice.get('id'),
    userId: state.userProfile.get('id'),
  };
}

export default connect(
  mapStateToProps,
  {
    getCollection, getAllCollection, openCreateTaskModal,
    openRemoveUploadedTaskAssigneeFileModal, loadItem, openTogglePrivateUploadedTaskFileModal
  }
)(EndUserTasksPage);
