import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { hasFailed } from '^/consts/responseStates';

function InvitedMemberErrors({resendInviteResponse, removeInviteResponse}) {
  const hasFailedResend = hasFailed(resendInviteResponse);
  const hasFailedRemove = hasFailed(removeInviteResponse);
  return (
    <div>
      {hasFailedResend && (
        <p className="text-error mt-1-4">
          There was an error resending this invite.
        </p>
      )}
      {hasFailedRemove && (
        <p className="text-error mt-1-4">
          There was an error removing this invite.
        </p>
      )}
    </div>
  );
}

function mapStateToProps(state, {practiceInviteId, ssoInviteId, userId}) {
  const resendInviteResponse = state.responses.get('resendInvite', Map());
  return {
    removeInviteResponse: (
      practiceInviteId ?
      state.responses.getIn(['removeInvite', practiceInviteId], Map()) :
      state.responses.getIn(['removeSSOInvite', ssoInviteId], Map())
    ),
    resendInviteResponse: resendInviteResponse.getIn(['meta', 'invitedUserId']) === userId
      ? resendInviteResponse : Map()
  };
}

export default connect(mapStateToProps)(InvitedMemberErrors)
