import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import { startContinueOrViewHealthcheck } from '^/actions/healthcheck';
import { HealthcheckStatus, HealthcheckListSummary } from './types';
import { StoreState } from '^/store/types';

interface OwnProps {
  data: HealthcheckListSummary;
}

interface DispatchProps {
  startContinueOrViewHealthcheck: typeof startContinueOrViewHealthcheck;
}

interface StateProps {
  loggedInUserId: unknown | null;
}

export type HealthcheckActionCellProps = StateProps & DispatchProps & OwnProps;

class HealthcheckActionCell extends Component<HealthcheckActionCellProps, {}> {
  render() {
    const { data, loggedInUserId } = this.props;

    return (
      <span>
        {data.status === HealthcheckStatus.COMPLETED ? (
          <Link to={`/page/healthcheck/${data.id}/review/`}>View</Link>
        ) : (
          data.user.id === loggedInUserId && (
            <a onClick={this.onContinue}>Continue</a>
          )
        )}
      </span>
    );
  }

  private onContinue = () => this.props.startContinueOrViewHealthcheck();
}

const mapStateToProps = (state: StoreState): StateProps => ({
  loggedInUserId: state.userProfile?.get('id'),
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, {
  startContinueOrViewHealthcheck,
})(HealthcheckActionCell);
