import React from 'react';
import { connect } from 'react-redux';
import PureComponent from '^/components/common/PureComponent';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { loadItem } from '^/actions/items';

import Loading from '^/components/app/content/Loading';
import { isPending } from '^/consts/responseStates';
import AdminItemField from '^/components/admin/content/detail/AdminItemField';


class AdminDetailView extends PureComponent {

  componentWillMount() {
    const { itemId } = this.props;
    this.loadItem(itemId);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.itemId !== newProps.itemId) {
      this.loadItem(newProps.itemId);
    }
  }

  loadItem(itemId) {
    const { model, collectionName } = this.props;
    this.props.loadItem(model, itemId, collectionName);
  }

  isLoading() {
    const { item, response } = this.props;
    return isPending(response) && !item;
  }

  getFields() {
    const { getFields, item, fields } = this.props;
    return getFields ? getFields(item) : fields;
  }

  renderItem() {
    const { item, title, className } = this.props;
    const fields = this.getFields();

    return (
      <div className={classNames('admin-edit-create-form', className)}>
        <h4>{title}</h4>
        {fields.map((field, idx) =>
          <div key={idx} className="mb-1-4 admin-edit-create-field">
            <label>{field.get('display_name')}</label>
            <AdminItemField item={item} field={field} />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { item } = this.props;

    if (this.isLoading()) {
      return <Loading />;
    } else if (!item) {
      return <div>Failed to load item.</div>;
    }
      return this.renderItem();

  }
}

AdminDetailView.propTypes = {
  model: React.PropTypes.string.isRequired,
  title: React.PropTypes.string,
  getTitle: React.PropTypes.func,
  itemId: React.PropTypes.string,
  fields: ImmutablePropTypes.list,
  getFields: React.PropTypes.func,
  options: ImmutablePropTypes.map,
  defaultValues: React.PropTypes.object,
};

function mapStateToProps(state, props) {
  const collectionName = props.collectionName || props.model;

  return {
    item: state.items.get(collectionName),
    response: state.responses.getIn(['loadItem', collectionName]),
    collectionName
  };
}

export default connect(mapStateToProps, { loadItem }) (AdminDetailView);
