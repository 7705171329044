export const PRIORITIES = {
  CRITICAL: 'CRITICAL',
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
  OPERATIONAL: 'OPERATIONAL',
};

export const PRIORITY_NAMES = {
  [PRIORITIES.CRITICAL]: 'Critical',
  [PRIORITIES.HIGH]: 'High',
  [PRIORITIES.MEDIUM]: 'Medium',
  [PRIORITIES.LOW]: 'Low',
  [PRIORITIES.OPERATIONAL]: 'Operational',
};

export const PRIORITY_CLASSES = {
  [PRIORITIES.CRITICAL]: 'critical',
  [PRIORITIES.HIGH]: 'high',
  [PRIORITIES.MEDIUM]: 'medium',
  [PRIORITIES.LOW]: 'low',
  [PRIORITIES.OPERATIONAL]: 'operational',
};

export const CALENDAR_PRIORITY_FILTERS = [
  PRIORITIES.CRITICAL,
  PRIORITIES.HIGH,
  PRIORITIES.MEDIUM,
  PRIORITIES.LOW,
  PRIORITIES.OPERATIONAL,
];

export const DOCUMENT_PRIORITIES = [
  PRIORITIES.CRITICAL,
  PRIORITIES.HIGH,
  PRIORITIES.MEDIUM,
  PRIORITIES.LOW,
];
