import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import { toggleMarkAsReadButton, toggleDocToReadCheckBox } from '^/actions/actions';
import { markDocumentAsReadAndCloseModal } from '^/actions/actionSequences';
import { loadItem } from '^/actions/items';
import { DOCUMENT_READ_REQUESTS } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';

import Loading from '^/components/app/content/Loading';
import MinimalDocumentHeader from '^/components/app/template-documents/MinimalDocumentHeader';
import PureComponent from '^/components/common/PureComponent';
import DocumentToReadForm from '^/components/app/forms/DocumentToReadForm';
import DownloadDocumentButton from '^/components/app/activities/documents/DownloadDocumentButton';
import { DOCUMENT_TYPE } from '^/models/documents';


export class DocumentToReadModal extends PureComponent {
  componentDidMount() {
    const { documentReadRequestId } = this.props;

    this.props.toggleDocToReadCheckBox(false);
    this.props.toggleMarkAsReadButton(false);
    this.loadTemplateDocument(documentReadRequestId);
  }

  loadTemplateDocument(documentReadRequestId) {
    if (documentReadRequestId) {
      this.props.loadItem(DOCUMENT_READ_REQUESTS, documentReadRequestId);
    }
  }

  handleCheckBox(event) {
    const checked = event.target.checked;
    this.props.toggleMarkAsReadButton(checked);
  }

  handleScroll(evt) {
    const element = evt.target;
    if (!this.props.scrollIsAtBottom) {
      this.props.toggleDocToReadCheckBox(
        element.scrollTop >= element.scrollHeight - element.offsetHeight
      );
    }
  }

  markDocAsRead() {
    const { documentReadRequestId } = this.props;
    if (documentReadRequestId) {
      this.props.markDocumentAsReadAndCloseModal(documentReadRequestId);
    }
  }

  render() {
    const {
      document,
      documentResponse,
      onClose,
      scrollIsAtBottom,
      canMarkAsRead,
    } = this.props

    if (
      isPending(documentResponse) || !document
    ) {
      return <Loading />;
    }

    const { header, content, footer } = document.get('document_content').toObject();
    const {
      document_name,
      document_version_number,
      document_code,
      template_created,
      template_category,
      folder_number,
      code_modified,
      template_modified,
      staff_practice_name,
      template_document_version_id,
      document_version_to_read_id,
    } = document.toObject();

    return (
      <div>
        <div className="row mb-1">
          <MinimalDocumentHeader
            name={document_name}
            versionNumber={document_version_number}
            created={template_created}
            categoryName={template_category}
            folderNumber={folder_number}
            codeModified={code_modified}
            templateModified={template_modified}
            practiceName={staff_practice_name}
            code={document_code}
          />
        <div className="pull-right mr-2">
          <DownloadDocumentButton
            documentId={template_document_version_id}
            documentType={DOCUMENT_TYPE.TEMPLATE}
            canSave={false}
            documentVersionToRead={document_version_to_read_id}
            downloadPDFLink={document.getIn(['export', 'download_url'])}
            pristine
          />
        </div>
        </div>
        <div
          className="row scrollable-modal document-to-read-modal"
          onScroll={(evt) => this.handleScroll(evt)}
        >
          <div className="col-1">
            <div className="document no-border">
              <div
                className="document-content no-select"
                dangerouslySetInnerHTML={{__html: header}}
              />
              <div
                className="document-content no-select"
                dangerouslySetInnerHTML={{__html: content}}
              />
              <div
                className="document-content no-select"
                dangerouslySetInnerHTML={{__html: footer}}
              />
            </div>
          </div>
        </div>

        <DocumentToReadForm
          scrollIsAtBottom={scrollIsAtBottom}
          canMarkAsRead={canMarkAsRead}
          onClose={onClose}
          toggleMarkAsReadButton={(checked) => this.props.toggleMarkAsReadButton(checked)}
          markDocAsRead={() => this.markDocAsRead()}
        />
      </div>
    );
  }
}

DocumentToReadModal.propTypes = {
  canMarkAsRead: React.PropTypes.bool,
  scrollIsAtBottom: React.PropTypes.bool,
  onClose: React.PropTypes.func,
  toggleMarkAsReadButton: React.PropTypes.func,
  toggleDocToReadCheckBox: React.PropTypes.func,
  documentReadRequestId: React.PropTypes.string,
};

export function mapStateToProps(state) {
  return {
    scrollIsAtBottom: state.ui.get('docToReadCheckBox'),
    canMarkAsRead: state.ui.get('markAsReadButton'),
    document: state.items.get(DOCUMENT_READ_REQUESTS),
    documentResponse: state.responses.getIn(['loadItem', DOCUMENT_READ_REQUESTS]),
  };
}

export default connect(mapStateToProps, {
  loadItem,
  toggleMarkAsReadButton,
  toggleDocToReadCheckBox,
  markDocumentAsReadAndCloseModal,
  onClose: closeModal
})(DocumentToReadModal);
