import createCategoryPage from './hoc/create-category-page';
import ActionRow from './action-row';
import {
  AuditAction,
  AuditStatus,
  AuditWorkflowTypes,
} from '^/components/app/digital-tools/audit/types';
import { getAuditActionsCategory } from '^/actions/audit';

export default createCategoryPage<AuditAction>({
  type: AuditWorkflowTypes.Actions,
  content: '',
  noContent: 'No actions needed! - Complete this stage to review your results.',
  header: '',
  itemRenderer: ActionRow,
  fetchCategoryAction: getAuditActionsCategory,
  requestKeys: [
    'getAuditActionsProgress',
    'getAuditActionsCategory',
    'updateAudit',
    'getAuditById',
  ],
  getIsLoading: state =>
    state.thunkRequests.getAuditActionsProgress.isLoading ||
    state.thunkRequests.getAuditActionsCategory.isLoading ||
    state.thunkRequests.updateAudit.isLoading ||
    state.thunkRequests.getAuditById.isLoading,
  getErrors: state =>
    state.thunkRequests.getAuditActionsProgress.errors ||
    state.thunkRequests.getAuditActionsCategory.errors ||
    state.thunkRequests.updateAudit.errors ||
    state.thunkRequests.getAuditById.errors,
  getCategories: state => state.audit.actionsCategories,
  intendedFromStatus: AuditStatus.ACTIONS,
  urlPart: '/actions/',
  splitByCorrect: true,
  auditWorkflow: true,
});
