import { Moment } from 'moment';

import {
  ItemKeyedById,
  CachedItem,
  HealthcheckStatus,
} from '^/components/app/healthcheck/types';

export enum AuditWorkflowTypes {
  Audit = 'Audit',
  Actions = 'Actions',
  AuditResults = 'Audit Results',
}

export interface IntroOptions {
  type: AuditWorkflowTypes;
  intendedFromStatus: AuditStatus;
  buttonText?: string;
}

export interface BaseItem {
  id: string;
  note: string | null;
  is_correct?: boolean;
}

export interface ItemRendererProps<T extends BaseItem> {
  question: T;
  auditId: string;
  categoryId: string;
}

interface AuditPractice {
  id: string;
  name: string;
}

export type Audit = {
  id: string;
  modified: Moment;
  user: AuditUser;
  taking_over_user_id: string;
  status: AuditStatus;
  audit_score: number;
  review_summary: string;
  audit_type: AuditType;
  practice: AuditPractice;
  audit_intro_text: string;
  action_intro_text: string;
  incorrect_questions_actioned_count: number;
  incorrect_questions_count: number;
};

export enum AuditStatus {
  AUDIT_INTRO_1 = 'AUDIT_INTRO_1',
  AUDIT = 'AUDIT',
  AUDIT_RESULTS = 'AUDIT_RESULTS',
  ACTIONS_INTRO_1 = 'ACTIONS_INTRO_1',
  ACTIONS = 'ACTIONS',
  REVIEW = 'REVIEW',
  COMPLETED = 'COMPLETED',
}

export interface AuditUser {
  id: string;
  email: string;
  full_name: string;
}

interface AuditQuestionResponse {
  id: string;
  text: string;
}

export interface AuditQuestion {
  id: string;
  question_text: string;
  information_text?: string;
  selected_response?: string | null;
  responses: readonly AuditQuestionResponse[];
  note: string;
  is_actioned: boolean;
}

export interface AnswerQuestionAction {
  questionId: string;
  auditId: string;
  categoryId: string;
}

export interface AuditActionTemplateDocument {
  id: string;
  name: string;
}

export interface AuditAction {
  id: string;
  question_text: string;
  note: string | null;
  correct_answer_feedback: string;
  incorrect_answer_feedback: string;
  is_correct: boolean;
  tasks: readonly Task[];
  is_actioned: boolean;
}

export interface AuditReviewCategoryScores {
  id: string;
  name: string;
  score: number;
  task_count: number;
}

export interface AuditReviewScores {
  categories: readonly AuditReviewCategoryScores[];
  id: string;
  overall_score: number;
  status: AuditStatus;
}

export interface AuditReviewQuestion {
  id: string;
  is_correct: boolean;
  note: string;
  question_text: string;
  selected_response: AuditQuestionResponse;
}

export interface AuditReviewCategory {
  id: string;
  name: string;
  questions: readonly AuditReviewQuestion[];
  subtitle: string;
}

export interface AuditListSummary {
  score: number;
  id: string;
  modified: Date;
  user: AuditUser;
  status: AuditStatus;
}

interface AuditCategoriesBase {
  id: string;
  name: string;
  subtitle: string;
}

// Response for displaying list of categories in sidebar
export type AuditCategoriesProgress = readonly AuditCategoryProgress[];
export interface AuditCategoryProgress extends AuditCategoriesBase {
  is_completed: boolean;
}

// Response containing questions/actions
export type AuditCategories<T> = readonly AuditCategory<T>[];
export interface AuditCategory<T> extends AuditCategoriesBase {
  questions: readonly T[];
}

export type CategoryExtension<T> = T & AuditCategoriesBase;

// List of categories possibly merged with questions/actions
export type AuditCategoriesMerged<T> = readonly AuditCategoryMerged<T>[];
export type AuditCategoryMerged<T> = AuditCategoryProgress &
  Partial<AuditCategory<T>>;

export type TaskAssignee = {
  full_name: string;
};

export enum TaskStatus {
  COMPLETED = 'COMPLETED',
  OVERDUE = 'OVERDUE',
  NOT_STARTED = 'NOT_STARTED',
}

export type Task = {
  id: string;
  title: string;
  entry: string;
  deadline: string | null;
  assignees: readonly TaskAssignee[];
  status: TaskStatus;
};

export interface BaseProgress {
  status: HealthcheckStatus | AuditStatus;
}

export type AuditType = {
  id: string;
  name: string;
  allow_new_creation: boolean;
};

export type AuditTypeWithCount = AuditType & {
  practice_audit_count: number;
};

export type AuditResponse = Audit & { audit_type: AuditType };

export interface AuditAuditResult {
  id: string;
  name: string;
  score: number;
}

export interface AuditReview {
  modified: string | null;
  overall_score: number;
  question_yes_count: number;
  question_total_count: number;
  action_completed_count: number;
  action_total_count: number;
  status: AuditStatus;
  user: AuditUser;
  id: string;
}

export interface AuditState {
  genericAuditError: null | string;
  questionsCategories: AuditCategoriesMerged<AuditQuestion> | null;
  actionsCategories: AuditCategoriesMerged<AuditAction> | null;
  reviewScoresById: ItemKeyedById<AuditReviewScores | null>;
  reviewTasksById: ItemKeyedById<readonly Task[] | null>;
  reviewCategoriesById: ItemKeyedById<readonly AuditReviewCategory[]>;
  currentPracticeAudits: AuditListSummary[];
  auditById: ItemKeyedById<CachedItem<AuditResponse>>;
  tasksByAuditQuestionId: ItemKeyedById<readonly Task[]>;
  auditTypes: readonly AuditTypeWithCount[];
  auditsByType: ItemKeyedById<AuditListSummary[]>;
  auditResults: {
    categories: AuditAuditResult[];
    overall_score: number;
  } | null;
}
