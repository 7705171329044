import React from 'react';
import { reduxForm } from 'redux-form';
import { Map } from 'immutable';

import LiveButton from '^/components/app/forms/LiveButton';

export const PRACTICE_MEETINGS_GROUP_CONFIG_FORM = 'PRACTICE_MEETINGS_GROUP_CONFIG_FORM';

export const TemplateMeetingFields = ({
  fields,
  templateMeetings,
}) => {
  return (
    <div>
      {templateMeetings
        .sortBy(
        each =>
          each.getIn(['template_activity', 'default_month'])
      )
      .map(each => 
        <div className="selectable" key={each.getIn(['template_activity', 'id'])}>
          <input type="checkbox" {...fields[each.getIn(['template_activity', 'id'])]} id={each.getIn(['template_activity', 'id'])} />
          <label title={each.getIn(['template_activity', 'help_text'])} htmlFor={each.getIn(['template_activity', 'id'])}>
            {/* This is an index so add 1 for the non l33t h4x0rz */}
            Month {each.getIn(['template_activity', 'default_month']) + 1}:
            &nbsp;
            {each.getIn(['template_activity', 'name'])}
          </label>
        </div>
      )}
  </div>
  )
}

export const PracticeMeetingsGroupConfigForm = ({
  handleSubmit, response, fields, templateMeetings
}) =>
  <form onSubmit={handleSubmit}>
    <div className="mb-2">
      <TemplateMeetingFields
        fields={fields}
        response={response}
        templateMeetings={templateMeetings}
      />
    </div>
    <LiveButton className="btn-default" pendingMessage="Saving..." response={response}>
      Save
    </LiveButton>
  </form>;

export const getFields = (templateMeetings) => templateMeetings
  .map(templateMeeting => templateMeeting.getIn(['template_activity', 'id']))
  .flatten()
  .toJS();

export const getInitialValues = (templateMeetings) =>
  Map(
    templateMeetings
      .map(
      templateMeeting =>
        [
          templateMeeting.getIn(['template_activity', 'id']),
          !templateMeeting.getIn(['template_activity', 'is_excluded'])
        ]
    )
  .flatten())
  .toJS();


export const mapStateToProps = (state, props) => {
  return {
    fields: getFields(props.templateMeetings),
    initialValues: getInitialValues(props.templateMeetings),
  };
};

export default reduxForm({
  form: PRACTICE_MEETINGS_GROUP_CONFIG_FORM,
}, mapStateToProps)(PracticeMeetingsGroupConfigForm);
