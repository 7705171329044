import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';

import { loadItem } from '^/actions/items';
import { isPending } from '^/consts/responseStates';

import Loading from '^/components/app/content/Loading';

import AdminDetailItem from '^/components/admin/content/detail/AdminDetailItem';
import Table from '^/components/common/content/Table';
import ScrollableTableContainer from '^/components/common/content/ScrollableTableContainer';

export const DETAIL_NAME_PREFIX = 'ADMINDETAIL_';

export class AdminDetail extends PureComponent {

  componentWillMount() {
    const { item, itemId, model, detailName } = this.props;
    if (!item) {
      this.props.loadItem(model, itemId, detailName);
    }
  }

  render() {
    const { item, fields, response, title, className } = this.props;

    return (isPending(response) || !item) ? <Loading /> : (
        <div className={classNames('admin-list-detail', className)}>
          <h4>{title}</h4>
          <ScrollableTableContainer className="mb-1">
            <Table
              className="padded"
              noHeader
              renderRow={(row, idx) => <AdminDetailItem key={idx} item={item} row={row} />}
              rows={fields}
              columns={null}
            />
          </ScrollableTableContainer>
          {this.props.children}
        </div>
    );
  }
}

export function mapStateToProps(state, props) {
  const detailName = props.detailName || (DETAIL_NAME_PREFIX + props.model);

  return {
    item: props.item || state.items.get(detailName),
    response: state.responses.getIn(['loadItem', detailName]),
    detailName,
  };
}

AdminDetail.propTypes = {
  model: React.PropTypes.string,
  itemId: React.PropTypes.string,
  item: ImmutablePropTypes.map,
  fields: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
  detailName: React.PropTypes.string,
  title: React.PropTypes.string,
  className: React.PropTypes.string,
};

export default connect(mapStateToProps, { loadItem })(AdminDetail);
