import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';

import {
  getActivityTypeText,
  STATUS_ICONS,
  STATUS_CLASSES,
  ACTIVITY_STATUSES,
  sortActivitiesInCalendar,
} from '^/models/activities';
import { PRIORITY_CLASSES, PRIORITY_NAMES } from '^/models/constants';
import {
  getActivitiesDisplayData,
  getTasksDisplayData,
  getDocumentReviewsDisplayData,
  removeDuplicateDates,
} from '^/utils';
import { ACTIVITIES, TASKS, DOCUMENT_REVIEWS_BY_MONTH } from '^/consts/collectionKeys';
import ActivityLink from '^/components/app/calendar/ActivityLink';
import MaybeLink from '^/components/app/content/MaybeLink';
import TaskLink from '^/components/app/calendar/TaskLink';
import ActivityTypeCapsule from '^/components/app/content/ActivityTypeCapsule';
import Icon from '^/components/app/content/Icon';


export class CalendarListView extends React.Component {

  renderDate(displayDate, index) {
    return (
      <tr key={index}>
        <td><h5 className="calendar-list-date">{displayDate}</h5></td>
      </tr>
    );
  }

  renderActivityOrTaskRow(activityOrTask) {
    const {
      title,
      priority,
      assignees,
      type,
      status,
      activity,
      task,
      isComplete,
      index,
      documentReview,
    } = activityOrTask;

    const activityStatus = activity && activity.get('status');

    return (
      <tr key={index}>
        <td>
          <div className="calendar-row-activity-title">
            {activity &&
              <ActivityLink activity={activity}>
                <span className={classNames("calendar-list-activity", {"complete": isComplete})}>
                  {title}
                </span>
              </ActivityLink>
            }
            {task &&
              <TaskLink task={task}>
                <span className={classNames("calendar-list-activity", {"complete": isComplete})}>
                  {title}
                </span>
              </TaskLink>
            }
            {documentReview &&
              <MaybeLink
                to={`/page/documents/${documentReview.get('adopted_template_document_id')}/`}
              >
                <span className={classNames('event-popup-title')}>
                  {documentReview.get('adopted_template_document_name')}
                </span>
              </MaybeLink>

            }
          </div>
        </td>
        <td>
        <div className={`mb-1-2 actvities-priority-pill smaller-size ${PRIORITY_CLASSES[priority]}`}>
          {PRIORITY_NAMES[priority]}
        </div>
        </td>
        <td>
          <ActivityTypeCapsule
            className="mr-1-2 calendar-capsule"
            type={type}
          />
          {getActivityTypeText(type)}
        </td>
        <td>
          <h5 className="calendar-list-assignees">{assignees}</h5>
          <p>
            {status}
            {(activityStatus && activityStatus !== ACTIVITY_STATUSES.NOT_STARTED) &&
              <Icon
                type={STATUS_ICONS[activityStatus]}
                className={classNames('step-icon small', STATUS_CLASSES[activityStatus])}
              />
            }
          </p>
        </td>
      </tr>
    );
  }

  renderTableRows(activitiesAndTasks) {
    const rows = [];

    removeDuplicateDates(activitiesAndTasks).map( 
      (activityOrTask, index) => {
        if (activityOrTask.displayDate){
          rows.push(this.renderDate(activityOrTask.displayDate, 'date-'+index));
        }
        rows.push(this.renderActivityOrTaskRow(activityOrTask, index));
      }
    );

    return rows;
  }

  render() {
    const {
      tasks,
      activities,
      documentReviews,
    } = this.props;

    const activitiesDisplayData = getActivitiesDisplayData(activities).sort(sortActivitiesInCalendar);
    const tasksDisplayData = getTasksDisplayData(tasks);
    const documentReviewsDisplayData = getDocumentReviewsDisplayData(documentReviews);
    const activitiesAndTasks = activitiesDisplayData
      .concat(tasksDisplayData)
      .concat(documentReviewsDisplayData)
      .toArray()
      .sort((a, b) => moment(a.date) - moment(b.date));

    return (
      <div>
        <table className="responsive padded padded-sm">
          <thead className="calendar-table-header">
          {activitiesAndTasks.length > 0 ?
            <tr>
              <th><h4 className="mt-1-2">Activity</h4></th>
              <th><h4 className="mt-1-2">Priority</h4></th>
              <th><h4 className="mt-1-2">Type</h4></th>
              <th><h4 className="mt-1-2">Assignee/Status</h4></th>
            </tr>
            :
            <tr>
              <th className="text-center default-text">
                There are no Activities due this month. Click <a href="https://icomply.agiliosoftware.com/page/tasks/">here</a> to add Tasks.
              </th>
            </tr>
          }
          </thead>
          <tbody>
            {this.renderTableRows(activitiesAndTasks)}
          </tbody>
        </table>
      </div>
    );
  }
}

CalendarListView.propTypes = {
  activities: ImmutablePropTypes.list.isRequired,
  tasks: ImmutablePropTypes.list.isRequired,
};

function mapStateToProps(state) {
  const activities = state.collections.getIn([ACTIVITIES, 'items'], List());
  const tasks = state.collections.getIn([TASKS, 'items'], List());
  const documentReviews = state.collections.getIn([DOCUMENT_REVIEWS_BY_MONTH, 'items'], List());

  return {
    tasks,
    activities,
    documentReviews,
  };
}

export default connect(mapStateToProps, {})(CalendarListView);
