import React from 'react';
import classNames from 'classnames';

import {
  PROGRESS_BAR_STEPS,
  STATUS_ORDER_COMBINED,
} from '^/consts/healthcheck';
import Icon from '^/components/app/content/Icon';
import { BaseProgress } from '^/components/app/digital-tools/audit/types';

interface OwnProps<T> {
  item: T;
}

type Props<T> = OwnProps<T>;

const ProgressBar = <T extends BaseProgress>({ item }: Props<T>) => {
  const statusIndex = STATUS_ORDER_COMBINED.indexOf(item.status);

  return (
    <ol className="healthcheck-progress-bar underline light">
      {PROGRESS_BAR_STEPS.map(step => {
        const isInProgress = statusIndex > step.minInProgressIndex;
        const isComplete = statusIndex > step.minCompleteIndex;

        return (
          <li
            key={step.text}
            className={classNames('step', {
              'in-progress': !isComplete && isInProgress,
              complete: isComplete,
            })}
          >
            <span className="circle">
              {isComplete && <Icon type="check" />}
            </span>
            {step.text}
          </li>
        );
      })}
    </ol>
  );
};

export default ProgressBar;
