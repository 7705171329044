import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import {
  togglePrivacyUploadedFileReloadActivityAndCloseModal,
  togglePrivacyUploadedFileReloadTaskAssigneeFilesAndCloseModal
} from '^/actions/actionSequences';

import LiveButton from '^/components/app/forms/LiveButton';

export const TogglePrivacyUploadedActivityTaskFileModal = ({
  response, activityId, taskAssigneeId, taskId, togglePrivacyActivity, togglePrivacyTaskAssignee, uploadedFile, cancel
}) =>
  <div>
    <p className="mb-2">
      Are you sure you want to make <b>{uploadedFile.get('name')}</b> from this {
        activityId ? 'activity' : 'task'
      } {
        uploadedFile.get('is_secure_document') ? 'viewable by' : 'private from'
      } other members of this practice?
    </p>

    <button onClick={cancel} className="btn btn-default mr-1">Cancel</button>

    <LiveButton
      response={response}
      onClick={() =>
        activityId ?
          togglePrivacyActivity(activityId, uploadedFile.get('id'), !uploadedFile.get('is_secure_document')) :
          togglePrivacyTaskAssignee(
            taskAssigneeId,
            taskId,
            uploadedFile.get('id'),
            !uploadedFile.get('is_secure_document')
          )
        }
      className="btn btn-warning"
    >
      { uploadedFile.get('is_secure_document') ? 'Make Viewable' : 'Make Private' }
    </LiveButton>
  </div>;

export const mapStateToProps = (state) => ({
  response: state.responses.get('togglePrivacyUploadedFile'),
});

TogglePrivacyUploadedActivityTaskFileModal.propTypes = {
  activityId: React.PropTypes.string,
  taskAssigneeId: React.PropTypes.string,
  taskId: React.PropTypes.string,
  uploadedFile: React.PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  {
    togglePrivacyActivity: togglePrivacyUploadedFileReloadActivityAndCloseModal,
    togglePrivacyTaskAssignee: togglePrivacyUploadedFileReloadTaskAssigneeFilesAndCloseModal,
    cancel: closeModal,
  }
) (TogglePrivacyUploadedActivityTaskFileModal);
