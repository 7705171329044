import React from 'react';
import { reduxForm } from 'redux-form';

import PureComponent from '^/components/common/PureComponent';
import FormError from '^/components/app/forms/FormError';
import LiveButton from '^/components/app/forms/LiveButton';
import DateInput from '^/components/app/forms/DateInput';

const DOCUMENT_REVIEW_CREATE_COMPLETE_FORM = "DOCUMENT_REVIEW_CREATE_COMPLETE_FORM";

export class DocumentReviewForm extends PureComponent {
  render() {
    const {
      fields: { reschedule_existing, schedule_date },
      handleSubmit,
      response,
      canRescheduleReview,
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className="">
        {canRescheduleReview &&
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="reschedule_existing">Reschedule existing review?</label>
            </div>
            <div className="col-2-3">
              <input type="checkbox" id="reschedule_existing" {...reschedule_existing} />
              <FormError
                error={reschedule_existing && reschedule_existing.touched && reschedule_existing.error}
                formKey="reschedule_existing"
              />
            </div>
          </div>
        }
        {(!canRescheduleReview || (canRescheduleReview && reschedule_existing.checked)) &&
          <div className="form-group">
            <div className="col-1-3">
              <label htmlFor="schedule_date">Schedule next Review for:</label>
            </div>
            <div className="col-2-3">
              <DateInput id="schedule_date" {...schedule_date} />
              <FormError
                error={schedule_date && schedule_date.touched && schedule_date.error}
                formKey="schedule_date"
              />
            </div>
          </div>
        }
        <div className="form-group">
          <div className="col-2">
            <LiveButton
              pendingMessage="Creating..."
              response={response}
              className="btn-warning pull-right"
            >
              {(!canRescheduleReview || (canRescheduleReview && reschedule_existing.checked)) ? 'CREATE AND SCHEDULE NEXT' : 'CREATE AD-HOC REVIEW'}
            </LiveButton>
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: DOCUMENT_REVIEW_CREATE_COMPLETE_FORM,
  fields: ['reschedule_existing', 'schedule_date'],
})(DocumentReviewForm);
