import React from 'react';
import { connect } from 'react-redux';

import { isDemoUser } from '^/models/user';

export const DemoModeDisable = ({isDisabled, message, children}) =>
  isDisabled ? (
    <p className="alert-info">
      Sorry, in demo mode you {message || 'cannot do this action'}.
    </p>
  ) : (
    children
  );

export const mapStateToProps = state => ({
  isDisabled: isDemoUser(state.userProfile)
});

export default connect(mapStateToProps) (DemoModeDisable);
