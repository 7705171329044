import React from 'react';
import { connect } from 'react-redux';

import { GroupDocumentsDeniedAccessLandingText } from '^/components/app/groups/insights/GroupDocumentsDeniedAccessLanding';
import TemplatesDocuments from '^/components/app/template-documents/TemplatesDocuments';
import { TEMPLATE_VIEW_BY } from '^/consts/templateViewBy';
import { hasAccessToAdoptedGroupDocuments } from '^/stateHelpers';

const GroupsDocumentsPage = ({hasAdoptedGroupDocumentAccess}) => (
  <div>
    {hasAdoptedGroupDocumentAccess ? (
      <TemplatesDocuments
        groupDocuments
        defaultViewBy={TEMPLATE_VIEW_BY.NEW_AND_UPDATED_DOCUMENTS}
        viewByOptions={TEMPLATE_VIEW_BY}
        showingViewBy
      />
    ) : (
      <div className="mt-1">
        <GroupDocumentsDeniedAccessLandingText />
      </div>
    )}
  </div>
);

export { GroupsDocumentsPage as UnconnectedGroupsDocumentsPage };

const mapStateToProps = (state) => ({
  hasAdoptedGroupDocumentAccess: hasAccessToAdoptedGroupDocuments(state),
});

export default connect(mapStateToProps)(GroupsDocumentsPage);
