import React from 'react';
import Dropzone from 'react-dropzone';
import PureComponent from '^/components/common/PureComponent';
import { STYLE, ACTIVE_STYLE, REJECT_STYLE } from '^/consts/dropzone';


export default class PictureUpload extends PureComponent {
  onOpenClick() {
    this.refs.dropzone.open();
  }

  onDrop(files) {
    if (files.length) {
      this.props.setPendingUpload(files[0]);
    }
  }

  render() {
    const { src, file, className } = this.props;

    return (
      <div className={className}>
        <Dropzone
          ref="dropzone"
          multiple={false}
          accept="image/*"
          disableClick
          onDrop={this.onDrop.bind(this)}
          style={STYLE}
          activeStyle={ACTIVE_STYLE}
          rejectStyle={REJECT_STYLE}
        >
          {this.props.display(file ? file.preview : src)}
        </Dropzone>

        <button
          type="button"
          className="btn btn-default col-xs-12"
          onClick={this.onOpenClick.bind(this)}
        >
          Select a {src || file ? 'new' : ''} picture
        </button>

        {file && (
          <button
            type="button"
            className="btn btn-primary col-xs-12"
            onClick={() => this.props.onSubmit()}
          >
            Upload picture
          </button>
        )}
      </div>
    );
  }
}
