import React from 'react';
import { reduxForm } from 'redux-form';
import FormError from '^/components/app/forms/FormError';
import LiveButtonSet from '^/components/app/forms/LiveButtonSet';


export const PublishDocumentVersionForm = ({ fields, handleSubmit, response, message, onCancel }) => {
  const { notes, suppress_alert } = fields;
  return (
    <form onSubmit={handleSubmit} className="admin-edit-create-form">

      <p className="mb-1">
        {message}
      </p>

      <FormError response={response} />

      <div className="admin-edit-create-field">
        <label>Notes:</label>
        <textarea {...notes} />
        <FormError response={response} formKey="notes" />
      </div>

      <div className="admin-edit-create-field">
        <label>Suppress alert:</label>
        <input type="checkbox" {...suppress_alert} />
        <FormError response={response} formKey="suppress_alert" />
      </div>

      <div className="mt-1">
        <LiveButtonSet pendingMessage="Publishing..." response={response}>
          <button type="submit" className="btn btn-warning mr-1">
            Publish
          </button>

          <button type="button" className="btn btn-default" onClick={onCancel}>
            Cancel
          </button>
        </LiveButtonSet>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'publishDocumentVersion',
  fields: ['notes', 'suppress_alert'],
  initialValues: {notes: '', suppress_alert: false},
})(PublishDocumentVersionForm);
