import React from 'react';
import { Map, OrderedMap, List } from 'immutable';
import queryString from 'query-string';

import { withRouter } from '^/withRouter';
import {
  MOMENT_UTC_TODAY,
  MOMENT_UTC_A_YEAR_AGO,
  formatDateTime,
  capitalize
} from '^/utils';
import DetailsCard from '^/components/app/groups/insights/DetailsCard';
import {
  INSIGHTS,
  INSIGHTS_API_URLS,
  DATABASE_MODEL_NAME,
  complianceActivitiesInsightsConfig,
} from '^/components/app/groups/insights/constants';
import withInsight from '^/components/app/groups/insights/hoc/withInsight';
import withSelectedFilters from '^/components/app/groups/insights/hoc/withSelectedFilters';
import withLastUpdatedTimestamp from '^/components/app/groups/insights/hoc/withLastUpdatedTimestamp';
import ComplianceActivitiesCompletedCharts from '^/components/app/groups/insights/compliance-activities/ComplianceActivitiesCompletedCharts';
import DateRangeFilter from '^/components/app/groups/insights/filters/DateRangeFilter';
import {
  getTimePeriodLabel,
  getSubGroupFilterFromPath,
  getCompletedActivitiesCountryFromPath,
} from '^/components/app/groups/insights/utils';
import PureComponent from '^/components/common/PureComponent';

const INSIGHT = INSIGHTS.COMPLIANCE_ACTIVITIES_COMPLETED;

export class ComplianceActivitiesCompletedDetails extends PureComponent {
  componentDidMount() {
    const { pathname, country, selectedSubGroup } = this.props;

    const queryParams = {
      ...(selectedSubGroup ? { sub_group: selectedSubGroup } : {}),
      country,
    };

    this.props.router.replace(
      `${pathname}${'?' + queryString.stringify(queryParams)}`
    );
  }

  render() {
    const {
      data,
      dateFrom,
      dateTo,
      cycleIndex,
      lastUpdatedTimestamp,
      labels,
      complianceSystemName,
    } = this.props;

    const activitiesData = data
      ? OrderedMap(List(labels.keys()).map(key => [key, data.get(key, 0.0)]))
      : OrderedMap(List(labels.keys()).map(key => [key, 0.0]));

    return (
      <DetailsCard>
        <h3>
          Compliance activities completed ({complianceSystemName})
          {dateFrom && dateTo && (
            <span className="ml-2">
              <DateRangeFilter
                insightsConfig={complianceActivitiesInsightsConfig}
                dateFrom={dateFrom}
                dateTo={dateTo}
              />
            </span>
          )}
        </h3>
        {lastUpdatedTimestamp && (
          <p className="insights-timestamp">
            Updated: {formatDateTime(lastUpdatedTimestamp)}
          </p>
        )}
        <p className="caption mt-1">{capitalize(getTimePeriodLabel(dateFrom, dateTo, cycleIndex))}</p>
        <ComplianceActivitiesCompletedCharts
          labels={labels.toJS()}
          data={activitiesData}
        />
      </DetailsCard>
    );
  }
}

const ComplianceActivitiesCompletedDetailsWithLastUpdatedTimestamp = withLastUpdatedTimestamp(
  DATABASE_MODEL_NAME.ACTIVITY
)(withRouter(ComplianceActivitiesCompletedDetails));

const ComplianceActivitiesCompletedDetailsWithLastUpdatedTimestampAndSelectedFilters = withSelectedFilters(
  INSIGHT
)(ComplianceActivitiesCompletedDetailsWithLastUpdatedTimestamp);

export default withInsight({
  insight: INSIGHT,
  url: INSIGHTS_API_URLS.COMPLIANCE_ACTIVITIES_COMPLETED,
  getFilters: () =>
    Map({
      date_from: MOMENT_UTC_A_YEAR_AGO,
      date_to: MOMENT_UTC_TODAY,
      practice_sub_group_id: getSubGroupFilterFromPath(),
      country: getCompletedActivitiesCountryFromPath(),
    }),
})(
  ComplianceActivitiesCompletedDetailsWithLastUpdatedTimestampAndSelectedFilters
);
