import { Map } from "immutable";
import makeRsaAction from '^/middleware/makeRsaAction';
import { makeAsyncActionSet } from '^/actions/utils';

export const LOAD_STATS = makeAsyncActionSet('LOAD_STATS');
export const LOAD_YEAR_TO_DATE_STATS = makeAsyncActionSet('LOAD_YEAR_TO_DATE_STATS');

export function _loadStats(actionName, statsType, url, user, filters = Map()) {
  const action = makeRsaAction(
    actionName,
    url,
    'GET',
    Object.assign(user ? { user } : {}, filters.toJS()),
    { statsType, source: statsType, filters: filters.toJS() }
  );
  return action;
}

export function loadStats(type, id, name = type, user = null) {
  return _loadStats(LOAD_STATS, name, `/${type}/stats/${id}/`, user);
}

export function loadYearToDateStats(type, id, filters, name = type, user = null) {
  return _loadStats(
    LOAD_YEAR_TO_DATE_STATS,
    name,
    `/${type}/year-to-date-stats/${id}/`,
    user,
    filters
  );
}
