import React from 'react';
import PureComponent from '^/components/common/PureComponent';
import { connect } from 'react-redux';
import Icon from '^/components/app/content/Icon';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';
import Loading from '^/components/app/content/Loading';
import { List } from 'immutable';

import { openHelpModal } from '^/actions/modals';
import { HELP } from '^/consts/collectionKeys';
import { getAllCollection } from '^/actions/collections';
import { isPending } from '^/consts/responseStates';
import { getPlaceholderImage } from '^/utils';

export class Help extends PureComponent {
  componentWillMount() {
    this.props.getAllCollection(HELP);
  }

  render() {
    if (this.props.loading) {
      return (
        <Loading />
      );
    }
    return (
      <div>
        <div className="col-2-3 bordered">
          <div>
            <h1 className="mt-2">iComply Support <Icon type="comments" className="title ml-1" /></h1>
            <p>Our experienced customer support team is ready to help
               - either contact us using the methods below, or browse our help and FAQs.</p>
            <div className="alert-info mt-1 pt-1">
              <div className="col-1-2">
                <h4>
                  <Icon type="phone" className="title" />
                  <a href="tel:03301659712">0330 165 9712</a>
                </h4>
              </div>
              <div className="col-1-2">
                <h4>
                  <Icon type="envelope" className="title" />
                  <a href="mailto:icomply@agiliosoftware.com">icomply@agiliosoftware.com</a>
                </h4>
              </div>
            </div>
          </div>
          <h2 className="mt-2">Help articles and videos</h2>
          <hr className="thin" />
          <ul className="card-container">
          { this.props.helps.map((topic) =>
            <li key={topic.get('id')}>
              <div className="card text-center">
                <a onClick={() => this.props.openHelpModal(topic)}>
                  <h3>{topic.get('title')}</h3>
                  <div className="card-content mt-1 mb-1">
                    <img
                      className="centered"
                      src={topic.get('image') || getPlaceholderImage()}
                    />
                  </div>
                </a>
                <a className="btn btn-default" onClick={() => this.props.openHelpModal(topic)}>View</a>
              </div>
            </li>
          )}
        </ul>
        </div>
        <div className="col-1-3 col-md-1">
          <h2 className="mt-2">FAQs</h2>
          <hr className="thin" />
          { this.props.faqs.map((faq) =>
            <div key={faq.get('id')}>
              <CollapseButton collapseId={'faq' + faq.get('id')}>
                <h5 className="mt-1-2">
                  {faq.get('title')}
                </h5>
              </CollapseButton>
              <Collapse initiallyCollapsed collapseId={'faq' + faq.get('id')} className="content">
                <p className="secondary-info" dangerouslySetInnerHTML={{__html: faq.get('content', '')}} />
              </Collapse>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const allHelp = state.collections.getIn([HELP, 'items'], List()).groupBy((item) => item.get('is_faq'));
  return {
    loading: isPending(state.responses.getIn(['getCollection', HELP])),
    helps: allHelp.get(false, List()),
    faqs: allHelp.get(true, List())
  };
}

export default connect(mapStateToProps, {
  openHelpModal,
  getAllCollection
})(Help);
