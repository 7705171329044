import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { enableNav, disableNav } from '^/actions/actions';
import PureComponent from '^/components/common/PureComponent';
import Icon from '^/components/app/content/Icon';
import { getUserAccountStagesToComplete, SETUP_URLS, SETUP_STAGE_TEXT, getUserAccountStages } from '^/consts/setup';
import SetupMayDisallow from '^/components/app/perms/SetupMayDisallow';
import { selectCurrentPractice } from '^/selectors/practice';


export const AccountSetUpNavItem = ({allSetupStages, accountStage, currentStage}) => {
  const isComplete = allSetupStages.toJS().indexOf(accountStage) < allSetupStages.toJS().indexOf(currentStage);
  const isCurrent = accountStage === currentStage;
  return (
    <li className={isCurrent ? 'sidenav-active' : ''}>
      <span
        className={
        classNames(
          'sidenav-text',
          isComplete ? 'step-complete' : ''
        )}
      >
        {SETUP_STAGE_TEXT.get(accountStage)}
        {isCurrent &&
          <Icon type="long-arrow-left" className="setup-icon" />
        }
        {!isCurrent && isComplete &&
          <Icon type="check" className="setup-icon complete" />
        }
      </span>
    </li>
  );
};

export const AccountSetUpNav = ({setupStagesToComplete, allSetupStages, className, currentStage}) =>
  <div className={classNames(className, 'secondary-left')}>
    <ul className="list-no-indent icon-right">
      {setupStagesToComplete.map(stage =>
        <AccountSetUpNavItem key={stage} accountStage={stage} currentStage={currentStage} allSetupStages={allSetupStages} />)}
    </ul>
  </div>;

export class AccountSetUpPage extends PureComponent {

  componentWillMount() {
    this.props.disableNav();
  }

  componentWillUnmount() {
    this.props.enableNav();
  }

  render () {
    const { children, currentStage, setupStagesToComplete, allSetupStages, } = this.props;
    return (
      <SetupMayDisallow className="m-1 mt-2 mb-2 col-1">
        <AccountSetUpNav className="col-1-3" currentStage={currentStage} setupStagesToComplete={setupStagesToComplete} allSetupStages={allSetupStages} />
        <div className="col-2-3 bordered-left">{children}</div>
      </SetupMayDisallow>
    );
  }
}


export const mapStateToProps = (state) => {
  const path = state.routing.location.pathname || '';
  const practice = selectCurrentPractice(state);
  return {
    currentStage: SETUP_URLS.keyOf(path),
    setupStagesToComplete: getUserAccountStagesToComplete(practice),
    allSetupStages: getUserAccountStages(practice),
  };
};

export default connect(mapStateToProps, { enableNav, disableNav }) (AccountSetUpPage);
