import React from 'react';
import SeeCopiesLinkComponent from '^/components/app/template-documents/SeeCopiesLinkComponent';


const SeeCopiesLink = ({hasCopiedTemplates, isExpanded, toggleExpanded, hideToggleButton, isAdmin, originalId, documentKind, documentVersionId}) => {
  if (!hasCopiedTemplates) {
    return <noscript />;
  }

  return isExpanded ? (
    <span>
      { !hideToggleButton && (
        <a className="inline-link" onClick={toggleExpanded}>
          Hide copies
        </a>
      )}
      <div>
        <SeeCopiesLinkComponent
          isAdmin={isAdmin}
          activityId={originalId}
          documentKind={documentKind}
          documentVersionId={documentVersionId}
        />
      </div>
    </span>
  ) : (
    <a className="inline-link" onClick={toggleExpanded}>
      Show copies
    </a>
  );
};

SeeCopiesLink.propTypes = {
  documentKind: React.PropTypes.string.isRequired,
};

export default SeeCopiesLink;
