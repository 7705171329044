import { connect } from 'react-redux';

import { hasSucceeded, isPending, hasFailed } from '^/consts/responseStates';
import {
  selectInsightTableData,
  selectInsightTableTotalNumberOfResults,
  selectInsightTablePagination,
  selectInsightTableNumberOfPaginatedResults,
  selectInsightTableSortByKey,
  selectInsightTableSortIsAscending
} from '^/selectors/insights';

const withInsightTableData = (Component) => {
  function mapStateToProps(state, props) {
    const { insight } = props;
    const response = state.responses.getIn(['loadInsightData', insight]);

    return {
      data: selectInsightTableData(insight)(state),
      totalNumberOfResults: selectInsightTableTotalNumberOfResults(insight)(state),
      paginatedNumberOfResults: selectInsightTableNumberOfPaginatedResults(insight)(state),
      areAllShown: selectInsightTablePagination(insight)(state),
      tableSortByKey: selectInsightTableSortByKey(insight)(state),
      tableSortIsAscending: selectInsightTableSortIsAscending(insight)(state),
      isLoading: isPending(response),
      hasFailedToLoad: hasFailed(response),
      hasLoaded: hasSucceeded(response),
    };
  }

  return connect(mapStateToProps)(Component);
};

export default withInsightTableData;
