import React from 'react';
import { fromJS } from 'immutable';

import ProgressSpeechBubble from '^/components/app/widgets/ProgressSpeechBubble';


const PROGRESS_TEXT = fromJS([
  { text: '', begin: 0, end: 14 },
  { text: '', begin: 15, end: 99 },
  { text: 'Well done!', begin: 100, end: 100 },
]);

const getProgressText = (percentage) => {
  const extraText = PROGRESS_TEXT
    .find(item => percentage >= item.get('begin') && percentage <= item.get('end'))
    .get('title');
  return 'in current cycle.' + (extraText ? ' ' + extraText : '');
};


const CycleProgress = ({progress, forceColour}) => {
  const cycleProgress = progress && Math.round(progress * 100).toFixed(0);

  return (
    <ProgressSpeechBubble
      percentage={cycleProgress}
      forceColour={forceColour}
      title={cycleProgress + '% Complete'}
      text={getProgressText(cycleProgress)}
    />
  );
};

export default CycleProgress;
