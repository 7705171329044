import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';

import { STATUS_ICONS, STATUS_CLASSES, ACTIVITY_STATUSES } from '^/models/activities';
import Icon from '^/components/app/content/Icon';


const ActivityStatusIcon = ({activity}) => {
  const status = activity.get('status');
  const className = STATUS_CLASSES[status || ACTIVITY_STATUSES.NOT_STARTED];
  const type = STATUS_ICONS[status];

  if (!type) {
    return <noscript />;
  }

  return (
    <Icon type={type} className={classNames('step-icon step-icon-right', className)} />
  );
};

ActivityStatusIcon.propTypes = {
  activity: ImmutablePropTypes.map.isRequired,
};

export default ActivityStatusIcon;
