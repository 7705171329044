import { Map, fromJS, List } from 'immutable';
import moment from 'moment';

import * as actions from '^/actions/actions';
import * as items from '^/actions/items';
import * as healthcheckActions from '^/actions/healthcheck'
import { getCurrentPracticeId } from '^/models/user';
import { transformNewCycleTaskData } from '^/models/task';
import {
  GROUP_DETAILS,
  DIGITAL_RECORDS_LIST,
} from '^/consts/collectionKeys';
import { HealthcheckStatus } from '^/components/app/healthcheck/types';


export function user(state = fromJS({}), action) {
  switch (action.type) {
    case actions.LOGIN.SUCCESS:
      return state.set(
        'loggedInUserId', action.payload.user_id
      );
    case actions.LOAD_USERNAME:
      return state.set('email', action.payload.username);
    case actions.SAVE_USERNAME:
      return state.set('email', action.payload.email);
    case actions.CLEAR_USERNAME:
      return state.delete('email');
    case actions.CLEAR_USER:
    case actions.LOGOUT.SUCCESS:
      return Map();
    default:
      return state;
  }
}

export function userProfile(state = Map(), action) {
  switch (action.type) {
    case actions.SWITCH_PRACTICE.SUCCESS:
      return fromJS({
        ...action.payload,
        // Prevent is_impersonate from being updated here - MeSerializer on the back end loses
        // the original admin user is_impersonate property
        is_impersonate: state.get('is_impersonate')
      });
    case actions.GET_USER_INFO.SUCCESS:
    case actions.UPGRADE_DEMO.SUCCESS:
      return fromJS(action.payload);
    case actions.GET_USER_INFO.FAILURE:
      return state.set('data', 'ERROR: ' + JSON.stringify(action.payload));
    case items.UPDATE_ITEM.SUCCESS:
      // Pick up any updates to the practice
      return getCurrentPracticeId(state) === action.payload.id
        ? state.mergeIn(['staffdetail', 'current_practice'], action.payload)
        : state;
    case healthcheckActions.DELETE_HEALTHCHECK.SUCCESS:
      return state.setIn(
        ['staffdetail', 'current_practice', 'in_progress_healthcheck'],
        null
      );
    case healthcheckActions.CREATE_HEALTHCHECK.SUCCESS:
    case healthcheckActions.UPDATE_HEALTHCHECK_STATUS.SUCCESS:
      return state.setIn(
        ['staffdetail', 'current_practice', 'in_progress_healthcheck'],
        action.payload.data.status === HealthcheckStatus.COMPLETED
          ? null
          : fromJS(action.payload.data)
      );
    default:
      return state;
  }
}


export function pageHeader(state = null, action) {
  switch (action.type) {
    case actions.SET_PAGE_HEADER:
      return action.payload;
    case actions.CLEAR_PAGE_HEADER:
      return null;
    default:
      return state;
  }
}

export function filterOptions(state = Map(), action) {
  switch (action.type) {
    case actions.GET_FILTER_OPTIONS.REQUEST:
      return state.deleteIn([action.meta.collection, action.meta.filterKey]);
    case actions.GET_FILTER_OPTIONS.SUCCESS:
      return state.setIn(
        [action.meta.collection, action.meta.filterKey],
        fromJS(action.payload)
      );
    default:
      return state;
  }
}

export function filterSpec(state = Map(), action) {
  switch (action.type) {
    case actions.SET_FILTER:
      return state.setIn(
        [action.collection, action.filterKey],
        fromJS(action.filterValue)
      );
    case actions.CLEAR_FILTER:
      return state.deleteIn([action.collection, action.filterKey]);
    case actions.CLEAR_FILTERS:
      return state.delete(action.collection);
    default:
      return state;
  }
}

export function filterValues(state = Map(), action) {
  switch (action.type) {
    case actions.SET_FILTER_VALUE:
      const value = fromJS(action.filterValue);

      if (!value || (value.count && value.count() === 0)) {
        return state.deleteIn([action.collection, action.filterKey]);
      }
      return state.setIn([action.collection, action.filterKey], value);

    default:
      return state;
  }
}

export function currentMonth(state = moment(), action) {
  switch (action.type) {
    case actions.SET_CURRENT_MONTH:
      return action.payload;
    default:
      return state;
  }
}

export function initialMonth(state = null, action) {
  switch (action.type) {
    case actions.SET_CURRENT_MONTH:
      return null;
    case actions.SET_INITIAL_MONTH:
      return action.payload;
    default:
      return state;
  }
}

export function currentPractice(state = null, action) {
  switch (action.type) {
    case actions.GET_USER_INFO.SUCCESS:
    case actions.SWITCH_PRACTICE.SUCCESS:
    case actions.UPGRADE_DEMO.SUCCESS:
      return (
        fromJS(action.payload).getIn(['staffdetail', 'current_practice']) ||
        null
      );
    case items.UPDATE_ITEM.SUCCESS:
      // Pick up any updates to the practice
      if (
        action.meta.collectionName === GROUP_DETAILS &&
        state &&
        state.getIn(['group', 'id']) === action.payload.id
      ) {
        return state.set('group', fromJS(action.payload));
      }
      return state && state.get('id') === action.payload.id
        ? state.merge(action.payload)
        : state;
    default:
      return state;
  }
}

export function currentTeamMember(state = null, action) {
  switch (action.type) {
    case actions.SWITCH_PRACTICE.SUCCESS:
      return null;
    case actions.SET_CURRENT_TEAM_MEMBER:
      return action.payload;
    default:
      return state;
  }
}

export function reassignData(state = Map(), action) {
  switch (action.type) {
    case actions.ADD_REASSIGN_DATA:
      const { form, data } = action.payload;
      return state.set(form, fromJS(data));
    case actions.CLEAR_REASSIGN_DATA:
      return state.clear();
    default:
      return state;
  }
}

export function rememberedLocation(state = null, action) {
  switch (action.type) {
    case actions.LOGOUT.SUCCESS:
      return null;
    case actions.REMEMBER_LOCATION:
      return fromJS(action.payload);
    default:
      return state;
  }
}

export function exportTables(
  state = fromJS({ tables: [], download: { valid: 0, url: '' } }),
  action
) {
  switch (action.type) {
    case actions.GET_EXPORT_TABLES.SUCCESS:
      return state.set('tables', fromJS(action.payload));
    case actions.GET_EXPORT_URL.SUCCESS:
      return state.set('download', fromJS(action.payload));
    default:
      return state;
  }
}

export function practiceReport(state = fromJS({ valid: 0, url: '' }), action) {
  switch (action.type) {
    case actions.GET_EXPORT_URL.SUCCESS:
      return fromJS(action.payload);
    default:
      return state;
  }
}

export function pendingFormFields(state = Map(), action) {
  switch (action.type) {
    case actions.SET_PENDING_FIELDS:
      const { collection, data } = action.payload;
      return state.set(collection, fromJS(data));
    case items.UPDATE_ITEM.SUCCESS:
    case items.UPDATE_ITEM.FAILURE:
      return state.delete(action.meta.collectionName);
    default:
      return state;
  }
}

export function teamMembers(state = null, action) {
  switch (action.type) {
    case actions.GET_SETUP_TEAM_MEMBERS.SUCCESS:
      return fromJS(action.payload);
    default:
      return state;
  }
}

export function subGroups(state = List(), action) {
  switch (action.type) {
    case actions.GET_SUB_GROUPS_FOR_CURRENT_GROUP.SUCCESS:
      return fromJS(action.payload);
    default:
      return state;
  }
}

export function templateActivitiesForGroup(state = List(), action) {
  switch (action.type) {
    case actions.GET_TEMPLATE_ACTIVITIES_FOR_CURRENT_GROUP.SUCCESS:
      return fromJS(action.payload);
    default:
      return state;
  }
}

export function templateActivities(state = Map(), action) {
  switch (action.type) {
    case actions.LOAD_TEMPLATE_ACTIVITIES.REQUEST:
      return state.set(action.meta.activityTemplateType, null);
    case actions.LOAD_TEMPLATE_ACTIVITIES.SUCCESS:
      return state.set(
        action.meta.activityTemplateType,
        fromJS(action.payload)
      );
    default:
      return state;
  }
}

export function templateActivityTypeLastDone(state = Map(), action) {
  switch (action.type) {
    case actions.LOAD_TEMPLATE_ACTIVITY_TYPE_LAST_DONE.REQUEST:
      return state.set(action.meta.activityTemplateType, null);
    case actions.LOAD_TEMPLATE_ACTIVITY_TYPE_LAST_DONE.SUCCESS:
      return state.set(
        action.meta.activityTemplateType,
        fromJS(action.payload)
      );
    default:
      return state;
  }
}

export function cycleSetupTasks(state = Map(), action) {
  switch (action.type) {
    case actions.CREATE_CYCLE_SETUP_TASK:
      const { cycleId, data } = action.payload;
      const newTasks = (state.get(cycleId) || List()).push(
        transformNewCycleTaskData(data)
      );
      return state.set(cycleId, newTasks);
    default:
      return state;
  }
}

export function discountCode(state = '', action) {
  switch (action.type) {
    case actions.SET_DISCOUNT_CODE:
      return action.payload;
    default:
      return state;
  }
}

export function latestTerms(state = Map(), action) {
  switch (action.type) {
    case actions.GET_LATEST_TERMS.SUCCESS:
      return fromJS(action.payload);
    default:
      return state;
  }
}

export function templateActivityLiveCycleApplicationProgress(
  state = null,
  action
) {
  switch (action.type) {
    case actions.APPLY_TEMPLATE_ACTIVITY_TO_LIVE_CYCLES.SUCCESS:
    case actions.CHECK_LIVE_TEMPLATE_ACTIVITY_APPLICATION_PROGRESS.SUCCESS:
      return fromJS(action.payload);
    case actions.APPLY_TEMPLATE_ACTIVITY_TO_LIVE_CYCLES.FAILED:
    case actions.CLEAR_ACTIVITY_CYCLE_APPLICATION_PROGRESS:
      return null;
    default:
      return state;
  }
}

export function practiceDocumentVersions(state = null, action) {
  switch (action.type) {
    case actions.GET_PRACTICE_DOCUMENT_VERSIONS.REQUEST:
      return null;
    case actions.GET_PRACTICE_DOCUMENT_VERSIONS.SUCCESS:
      return fromJS(action.payload || []);
    default:
      return state;
  }
}

export function cycleReport(state = fromJS({ valid: 0, url: '' }), action) {
  switch (action.type) {
    case actions.GET_CYCLE_REPORT_URL.SUCCESS:
      return fromJS(action.payload);
    case actions.GET_CYCLE_REPORT_URL.REQUEST: // reset on request
      return fromJS({ valid: 0, url: '' });
    default:
      return state;
  }
}

export function digitalRecordsTasks(state = List(), action) {
  switch (action.type) {
    case items.LOAD_ITEM.SUCCESS:
        if (action.meta.source === DIGITAL_RECORDS_LIST && state)
        { return fromJS(action.payload.staff_tasks) }
      return state;
    case actions.CREATE_DIGITAL_RECORD_TASK.SUCCESS:
      const newTask = fromJS(action.payload)
      return state.push(newTask);
    case actions.CLEAR_DIGITAL_RECORD_TASKS:
      return List()
    default:
      return state;
  }
}

export function digitalRecordsNavigationList(state=List(), action) {
  switch (action.type) {
    case actions.GET_DIGITAL_RECORD_NAVIGATION_LIST.REQUEST:
      return null;
    case actions.GET_DIGITAL_RECORD_NAVIGATION_LIST.SUCCESS:
      return fromJS(action.payload || []);
    default:
      return state;
  }
}

export function digitalRecordChecklist(state = null, action) {
  switch (action.type) {
    case actions.GET_DIGITAL_RECORD_CHECKLIST.REQUEST:
      return null;
    case actions.GET_DIGITAL_RECORD_CHECKLIST.SUCCESS:
      return fromJS(action.payload || []);
    default:
      return state;
  };
};

export function digitalRecordChecklistHistory(state = null, action) {
  switch (action.type) {
    case actions.GET_DIGITAL_RECORD_CHECKLIST_HISTORY.REQUEST:
      return null;
    case actions.GET_DIGITAL_RECORD_CHECKLIST_HISTORY.SUCCESS:
      return fromJS(action.payload || []);
    default:
      return state;
  };
};

export function predefinedChecklistFields(state = null, action) {
  switch (action.type) {
    case actions.GET_PREDEFINED_CHECKLIST_FIELDS.REQUEST:
      return null;
    case actions.GET_PREDEFINED_CHECKLIST_FIELDS.SUCCESS:
      return action.payload || [];
    default:
      return state;
  };
};

export function customChecklistFields(state = List(), action) {
  switch (action.type) {
    case actions.ADD_CUSTOM_FIELD:
      return state.push(action.payload);
    case actions.REMOVE_CUSTOM_FIELD:
      return state.filter((field) => field.slug !== action.payload.slug);
    case actions.CLEAR_CUSTOM_FIELDS:
      return List();
    default:
      return state;
  }
}
