import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AuditType, Audit } from '^/components/app/digital-tools/audit/types';
import Icon from '^/components/app/content/Icon';
import { createAuditFromActivityAndContinue } from '^/actions/audit';
import ActivityAudit from '^/components/app/digital-tools/audit/activity-audit';
import { selectCurrentUserId } from '^/selectors/healthcheck';
import { StoreState } from '^/store/types';

interface OwnProps {
  activityId: string;
  auditType: AuditType;
  inProgressAudits: Array<Audit> | null;
}

interface DispatchProps {
  createAuditFromActivityAndContinue: typeof createAuditFromActivityAndContinue;
}

type StateProps = { currentUserId: string | undefined };

export type Props = OwnProps & DispatchProps & StateProps;

class ActivityAudits extends Component<Props, {}> {
  public render() {
    const { inProgressAudits, auditType, currentUserId } = this.props;
    return (
      <div>
        <h2>Complete digital audit</h2>
        <div className="help-block mb-1">
          <Icon type="lightbulb-o" className="tip-icon" />
          You can use the iComply Digit Audit tool
        </div>
        <div onClick={this.onClickCreateAudit}>
          <span className="underlined pointer small-text mr-1-4 bold-text">
            {auditType.name}
          </span>
          <span className="small-text mr-1-4">-</span>
          <span className="underlined pointer small-text mr-1-4 complete bold-text">
            start new
          </span>
        </div>
        {inProgressAudits &&
          inProgressAudits.map(inProgressAudit => (
            <div className="mt-1-2" key={inProgressAudit.id}>
              <ActivityAudit
                inProgressAudit={inProgressAudit}
                currentUserId={currentUserId}
              />
            </div>
          ))}
      </div>
    );
  }

  private onClickCreateAudit = () => {
    this.props.createAuditFromActivityAndContinue(this.props.activityId);
  };
}

const mapStateToProps = (state: StoreState): StateProps => {
  return {
    currentUserId: selectCurrentUserId(state),
  };
};

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, {
  createAuditFromActivityAndContinue,
})(ActivityAudits);
