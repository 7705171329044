import moment from 'moment';
import { fromJS, List, Map } from 'immutable';

import { isComplete } from '^/models/activities';
import { PRIORITIES } from '^/models/constants';

export const isHTMLDocument = (documentVersion) =>
  documentVersion && (typeof documentVersion.get('content') === 'string') ||
    documentVersion && (typeof documentVersion.getIn(['working_version', 'content']) === 'string');

export const NOT_PERMITTED = 'NOT_PERMITTED';
export const READ = 'READ';

export const getBaseTemplateDocumentVersion = templateVersion =>
    templateVersion.getIn(['adopted_template_document', 'group_template_document'])
    || templateVersion.getIn(['copied_template_document', 'group_template_document'])
    || templateVersion.get('adopted_template_document')
    || templateVersion.get('copied_template_document')
    || templateVersion.get('group_template_document')
    || templateVersion.get('source_version')
    || templateVersion;

export const getBaseTemplateDocument = templateVersion =>
  getBaseTemplateDocumentVersion(templateVersion).get('template_document');

export function canUserEditDocument (templateVersion) {
  const templateDocument = getBaseTemplateDocument(templateVersion);
  return templateDocument && templateDocument.get('permission_level', NOT_PERMITTED) === READ;
}

export const KINDS = {
  POLICY: 'POLICY',
  AUDIT: 'AUDIT',
  CONTRACT: 'CONTRACT',
  OVERVIEW: 'OVERVIEW'
};

export const DOCUMENT_PRIORITY_OPTIONS = fromJS([
  {id: PRIORITIES.CRITICAL, name: 'Critical'},
  {id: PRIORITIES.HIGH, name: 'High'},
  {id: PRIORITIES.MEDIUM, name: 'Medium'},
  {id: PRIORITIES.LOW, name: 'Low'},
])

export const KINDS_DISPLAY = fromJS([
  {id: KINDS.POLICY, name: 'Policy'},
  {id: KINDS.AUDIT, name: 'Audit'},
  {id: KINDS.CONTRACT, name: 'Contract'},
  {id: KINDS.OVERVIEW, name: 'Overview'},
]);

export const getWorkingVersionId = templateVersion =>
  templateVersion.getIn(['working_version', 'id']);

export const templateDocumentKindIsPolicyOrOverview = templateDocument =>
  [KINDS.POLICY, KINDS.OVERVIEW].includes(templateDocument.get('kind'));


export const isCopiable = templateVersion =>
  !templateDocumentKindIsPolicyOrOverview(getBaseTemplateDocument(templateVersion));


export const TYPES = {
  HTML: 'HTML',
  FILE: 'FILE',
};

export const TYPES_DISPLAY = fromJS([
  {id: TYPES.HTML, name: 'Online'},
  {id: TYPES.FILE, name: 'Upload'}
]);

export const DOCUMENT_TYPE = {
  TEMPLATE: 'version',
  ATTACHED: 'attached',
  DRAFT: 'draft',
};

export const isFileUploadTemplateDocument = (documentVersion) =>
  documentVersion &&
  documentVersion.getIn(['template_document', 'type']) === TYPES.FILE;

export function getDocumentLink(documentId) {
  return `/page/documents/${documentId}/`;
}

export function getGroupDocumentLink(documentId, isGroupsApp) {
  return isGroupsApp ? (
    `/page/groups/group-documents/${documentId}/`
  ) : (
    `/page/documents/group/${documentId}/`
  );
}

export function getUpdateLink(documentId, isGroupsApp) {
  return isGroupsApp ? (
    `/page/groups/group-documents/${documentId}/update/`
  ) : (
    `/page/documents/${documentId}/update/`
  );
}

export const isCopiedOrAdoptedDocument = (templateVersion) => {
  return Boolean(templateVersion.get('adopted_template_document') || templateVersion.get('copied_template_document'));
};

export const isGroupCreatedDocument = templateDocument =>
  Boolean(
    templateDocument &&
    templateDocument.get('practice_group_creator')
  );

export const getAttachedDocumentListsFromActivity = (activity) => {
  activity = activity || Map();
  const attachedDocuments = activity.get('attached_documents', List())
      .filter(ad => isCopiedOrAdoptedDocument(ad.get('template_document_version')));

  return {attachedDocuments};
};

export const getDocumentListsFromActivity = (activity) => {
  activity = activity || Map();
  let relatedTemplates = activity.get('template_documents', List());
  if (isComplete(activity)) {
    relatedTemplates = relatedTemplates.filter((templateDocumentVersion) => {
      const baseDocument = getBaseTemplateDocument(templateDocumentVersion);
      return baseDocument.get('is_secure_document')
        || baseDocument.get('kind') === KINDS.CONTRACT
        || !isCopiedOrAdoptedDocument(templateDocumentVersion);
    });
  }
  const { attachedDocuments } = getAttachedDocumentListsFromActivity(activity);

  return {attachedDocuments, relatedTemplates};
};

export const getVersionNumber = (kind, doc) => {
  const baseDocument = getBaseTemplateDocument(doc);
  const versionNumber = baseDocument.get('group_template_document_latest_version_number') ||
    baseDocument.get('latest_version_number');
  return (
    kind === KINDS.AUDIT ?
      versionNumber :
      doc.get('document_version_number')
  );
};

export function destructureTemplateVersion(templateVersion, groupDocuments, appSectionPrefix) {
  const docPagePath = `${appSectionPrefix}`;
  const adoptedDocument = templateVersion.get(
    groupDocuments ? 'group_template_document' : 'adopted_template_document'
  );
  const isAdopted = Boolean(adoptedDocument);
  const copiedDocument = templateVersion.get('copied_template_document');
  const isCopied = Boolean(copiedDocument);
  const documentVersion = adoptedDocument || copiedDocument || templateVersion;
  const groupDocument = !groupDocuments && documentVersion.get('group_template_document');
  const templateDocument = documentVersion.get('template_document')
    || documentVersion.getIn(['group_template_document', 'template_document']);
  const adoptedGroupDocument = templateVersion.getIn(['adopted_template_document', 'group_template_document']);
  return {
    documentVersion,
    docPagePath,
    isAdopted,
    isCopied,
    templateDocument,
    adoptedDocument,
    copiedDocument,
    groupDocument,
    adoptedGroupDocument,
   };
}

export function getExportFromTemplateVersion(templateVersion) {
  return templateVersion.getIn(['group_template_document', 'export'])
    || templateVersion.getIn(['copied_template_document', 'export'])
    || templateVersion.getIn(['adopted_template_document', 'export']);
}

export function readRequestOverdue(duedate) {
  return moment(duedate).isBefore();
}
