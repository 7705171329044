import React from 'react';
import { reduxForm } from 'redux-form';
import FieldLookup from '^/components/admin/content/edit-create/dynamic-forms/field-types/FieldLookup';
import FormError from '^/components/app/forms/FormError';
import LiveButton from '^/components/app/forms/LiveButton';


const CONFIG = {
  type: 'lookup',
  lookup_url: '/admin/cqc-systems/',
  lookup_parameter: 'search',
  display_format_string: '${name}',
  label: 'Compliance System',
  value_field_path: ['id']
};

function ComplianceSystemFieldAddForm({ fields: { id }, handleSubmit, response, defaultLookupFilters }) {
  return (
    <form onSubmit={handleSubmit}>
      <FieldLookup
        {...id}
        dontUpdateSearch
        className="admin-edit-create-field"
        name="compliance-system"
        onSubmit={(val) => id.onSubmit(null, val)}
        formName="complianceSystemsFieldAdd"
        config={CONFIG}
        defaultFilters={defaultLookupFilters}
      />
      <FormError response={response} formKey="children" />

      <div className="mt-1-2">
        <LiveButton
          pendingMessage="Adding..."
          response={response}
          className="btn btn-primary"
        >
          Add
        </LiveButton>
      </div>
    </form>
  );
}


export default reduxForm({
  form: 'complianceSystemFieldAdd',
  fields: ['id'],
})(ComplianceSystemFieldAddForm);
