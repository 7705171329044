import React from 'react';
import Loading from '^/components/app/content/Loading';


const TemplatesNav = ({isLoading, viewByValues, setViewByValue, selectedViewByValue}) =>
  <div className="mt-1">
    {isLoading && <Loading />}

    <ul className="list-no-indent">
      {viewByValues.map(viewByValue =>
        <li key={viewByValue.get('id')} className={viewByValue.equals(selectedViewByValue) && 'sidenav-active'}>
          <a className="sidenav-text" onClick={() => setViewByValue(viewByValue)}>
            <span>
              {viewByValue.get('number') && <span>{viewByValue.get('number')} &ndash; </span>}
              {viewByValue.get('name')}
            </span>
          </a>
        </li>
      )}
    </ul>
  </div>;

export default TemplatesNav;
