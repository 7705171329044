import { fromJS } from 'immutable';

const TEMPLATE_ACTIVITY_PAGE_ID = 'admin/activities/${id}';

export const ADMIN_AUDIT_MODEL = 'admin/audits';
const ADMIN_AUDIT_MANAGE_ROUTE = '/admin/audits/${id}';
export const ADMIN_AUDIT_ROW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name (Type)', type: 'string', sortable: true},
  {name: ['sector_display'], display_name: 'Sector', type: 'string', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Edit', route: ADMIN_AUDIT_MANAGE_ROUTE},
]);
export const ADMIN_AUDIT_OPTIONS = 'ADMIN_AUDIT_OPTIONS';
export const ADMIN_AUDIT_CREATE = 'ADMIN_AUDIT_CREATE';
export const ADMIN_AUDIT_EDIT_FIELDS = fromJS([
  'sector_display', 'name', 'audit_intro_text', 'action_intro_text', 'allow_new_creation',
]);
export const ADMIN_AUDIT_TEMPLATE_ACTIVITY_ROW_FIELDS = fromJS([
  {name: ['name'], display_name: 'Name', type: 'detail', route: TEMPLATE_ACTIVITY_PAGE_ID, sortable: true},
  {name: ['id'], display_name: 'Remove', type: 'remove', sortable: false},
]);


export const AUDIT_QUESTION = 'AUDIT_QUESTION';
export const ADMIN_AUDIT_QUESTION_MODEL = 'admin/audits/questions';
export const ADMIN_AUDIT_QUESTION_OPTIONS = 'ADMIN_AUDIT_QUESTION_OPTIONS';
const ADMIN_AUDIT_QUESTION_MANAGE_ROUTE = '/admin/audits/question/${id}';
export const ADMIN_AUDIT_QUESTION_ROW_FIELDS = fromJS([
  {name: ['question_text'], display_name: 'Question Text', type: 'string', sortable: true},
  {name: ['audit_category_display'], display_name: 'Category', type: 'string', sortable: true},
  {name: ['position'], display_name: 'Position', type: 'string', sortable: true},
  {display_name: 'Actions', type: 'detail', link_text: 'Edit', route: ADMIN_AUDIT_QUESTION_MANAGE_ROUTE},
  {name: ['id'], display_name: 'Remove', type: 'remove'},
]);
export const ADMIN_AUDIT_QUESTION_CREATE_FIELDS = fromJS([
  'audit_category',
  'question_text',
  'correct_answer_feedback',
  'incorrect_answer_feedback',
  'information_text',
]);
export const ADMIN_AUDIT_QUESTION_EDIT_FIELDS = fromJS([
  'audit_category',
  'question_text',
  'correct_answer_feedback',
  'incorrect_answer_feedback',
  'information_text',
  'position',
]);

export const AUDIT_QUESTION_RESPONSE = 'AUDIT_QUESTION_RESPONSE';
export const ADMIN_AUDIT_QUESTION_RESPONSE_MODEL = 'admin/audits/responses';
export const ADMIN_AUDIT_QUESTION_RESPONSE_ROW_FIELDS = fromJS([
  {name: ['text'], display_name: 'Responses Text', type: 'string', sortable: true},
  {name: ['state'], display_name: 'State', type: 'string', sortable: true},
  {name: ['id'], display_name: 'Remove', type: 'remove'},
]);
export const ADMIN_AUDIT_QUESTION_RESPONSE_CREATE_FIELDS = fromJS(['text', 'state']);
