import React from 'react';
import { reduxForm } from 'redux-form';

import LiveButton from '^/components/app/forms/LiveButton';
import FormError from '^/components/app/forms/FormError';

export const ChangeFileFolderForm = ({
  response, handleSubmit, folders, fields: { folder },
}) =>
  <form onSubmit={handleSubmit}>

    <div className="form-group">
      <div className="col-1">
        <label htmlFor="folder">Folder</label>
        <select id="folder" {...folder}>
            <option value="" />
            {folders.map(each => (
              <option key={each.get('id')} value={each.get('id')}>{each.get('number')} - {each.get('name')}</option>
            ))}
        </select>
      </div>
      <FormError response={response} formKey="folder" />
    </div>

    <div className="row align-right mt-1">
      <div className="col-1">
        <LiveButton className="btn btn-default" response={response} pendingMessage="Changing folder...">
          Change
        </LiveButton>
      </div>
    </div>

  </form>;

export default reduxForm({
  form: 'uploadActivityFile',
  fields: ['folder'],
})(ChangeFileFolderForm);
