import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Loading from '^/components/app/content/Loading';
import PureComponent from '^/components/common/PureComponent';
import DocumentCopiesTable from '^/components/app/template-documents/DocumentCopiesTable';
import { getCollection, clearCollection } from '^/actions/collections';
import { TEMPLATE_VERSION_COPIES } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';


export class SeeCopiesLinkComponent extends PureComponent {

  componentWillMount() {
    this.props.clearCollection(TEMPLATE_VERSION_COPIES);
    this.loadTemplateCopies()

  }

  loadTemplateCopies(opts) {
      this.props.getCollection(TEMPLATE_VERSION_COPIES, {...opts, filters: { version_id: this.props.documentVersionId }});
  }

  loadMoreTemplateCopies(){
    const { copiesCollection } = this.props;
    this.loadTemplateCopies({page: copiesCollection.get('page') + 1, shouldAppend: true});
  }

  render() {

    const {copies, isAdmin, originalId, documentKind, isLoading, hasMore} = this.props

    const showPagination = (copies && !copies.isEmpty()) && hasMore;

    return (
      <div>
      { copies && (
          <DocumentCopiesTable
            copies={copies}
            isAdmin={isAdmin}
            activityId={originalId}
            documentKind={documentKind}
          />
      )}
      {
        isLoading &&
          <Loading />
      }
      {showPagination &&
        <a className="inline-link pull-left mr-100px mb-1" onClick={() => this.loadMoreTemplateCopies()}>
          Load more >>
        </a>
      }
      </div>
    )
  }
}

SeeCopiesLinkComponent.propTypes = {
  copies: ImmutablePropTypes.list.isRequired,
  documentKind: React.PropTypes.string.isRequired,
  getCollection: React.PropTypes.func,
  clearCollection: React.PropTypes.func,
  hasMore: React.PropTypes.bool,
  copiesCollection: React.PropTypes.map,
};

export function mapStateToProps(state) {
  const copiesCollection = state.collections.get(TEMPLATE_VERSION_COPIES)
  return {
    copies: copiesCollection && copiesCollection.get('items'),
    hasMore: copiesCollection && copiesCollection.get('hasMore'),
    isLoading: isPending(state.responses.getIn(['getCollection', TEMPLATE_VERSION_COPIES])),
    copiesCollection,
  };
}

export default connect(mapStateToProps, {
  getCollection,
  clearCollection
}) (SeeCopiesLinkComponent);
