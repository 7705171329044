import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  AuditListSummary,
  AuditStatus,
} from '^/components/app/digital-tools/audit/types';
import { continueAuditFrom } from '^/actions/audit';
import { StoreState } from '^/store/types';
import { isLoggedOnUserAdminOfCurrentPractice } from '^/stateHelpers';

interface OwnProps {
  data: AuditListSummary;
}

interface DispatchProps {
  continueAuditFrom: typeof continueAuditFrom;
}

interface StateProps {
  loggedInUserId: unknown | null;
  userIsAdmin: boolean;
}

export type AuditActionCellProps = StateProps & DispatchProps & OwnProps;

class AuditActionCell extends Component<AuditActionCellProps, {}> {
  render() {
    const { data, loggedInUserId, userIsAdmin } = this.props;
    const auditCompleted =
      data.status === AuditStatus.REVIEW ||
      data.status === AuditStatus.COMPLETED;

    if (auditCompleted) {
      return (
        <a className="underlined" onClick={this.onClickContinueAudit}>
          View
        </a>
      );
    }

    if (data.user.id === loggedInUserId || userIsAdmin) {
      return (
        <a className="underlined" onClick={this.onClickContinueAudit}>
          Continue
        </a>
      );
    }

    return null;
  }

  private onClickContinueAudit = () => {
    const {
      data: { status, id },
    } = this.props;

    this.props.continueAuditFrom(status, id);
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  loggedInUserId: state.userProfile?.get('id'),
  userIsAdmin: isLoggedOnUserAdminOfCurrentPractice(state),
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, {
  continueAuditFrom,
})(AuditActionCell);
