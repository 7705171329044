import React from 'react';
import LiveButton from '^/components/app/forms/LiveButton';
import Loading from '^/components/app/content/Loading';

// Like a LiveButton but can contain multiple buttons

export default class LiveButtonSet extends LiveButton {
  renderDefault(isPending) {
    const { pendingMessage } = this.props;
    return (
      <div>
        {(isPending && pendingMessage) ? pendingMessage : this.props.children}
        {isPending && <Loading className="inline small" />}
      </div>
    );
  }
}
