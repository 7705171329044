import React from 'react';
import { MODELS } from '^/permissions/capabilities';
import AdminContainer from '^/components/admin/content/AdminContainer';
import AdminList from '^/components/admin/content/perms/AdminList';
import PureComponent from '^/components/common/PureComponent';

import {
  ADMIN_TEMPLATE_DOCUMENT_DRAFT_MODEL,
  ADMIN_TEMPLATE_DOCUMENT_DRAFT_ROW_FIELDS,
} from './template-documents';


export default class TemplateDocumentsDraftsAdmin extends PureComponent {
  render() {
    return (
      <AdminContainer>
        <AdminList
          permsModel={MODELS.TEMPLATE_DOCUMENT}
          title="All Template Document Drafts"
          model={ADMIN_TEMPLATE_DOCUMENT_DRAFT_MODEL}
          fields={ADMIN_TEMPLATE_DOCUMENT_DRAFT_ROW_FIELDS}
        />
      </AdminContainer>
    );
  }
}
