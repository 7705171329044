import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { assigneeStatus } from '^/models/task';
import AssigneeStatus from '^/components/app/tasks/manage/AssigneeStatus';


const AssigneeStatuses = ({task}) => (
  <ul className="list-no-indent icon-right">
    {task.get('assignees').map((assignee, idx) =>
      <AssigneeStatus
        key={idx}
        task={task}
        assignee={assignee}
        status={assigneeStatus(assignee, task)}
      />
    )}
  </ul>
);

AssigneeStatuses.propTypes = {
  task: ImmutablePropTypes.map.isRequired,
};

export default AssigneeStatuses;
