export const TABS = [
  {
    path: 'dashboard',
    label: 'dashboard',
    counter: 'overDueActivityCount',
  },
  {
    path: 'calendar',
    label: 'calendar',
    counter: 'alertsCount',
  },
  {
    path: 'tasks',
    label: 'tasks',
    applicationOnly: true,
  },
  {
    path: 'documents',
    label: 'documents',
  },
  {
    path: 'reports',
    label: 'reports',
    applicationOnly: true,
    adminOnly: true,
  },
  {
    path: 'tools',
    label: 'digital tools',
  },
  {
    path: 'practices',
    label: 'practice',
  },
  {
    path: 'help',
    label: 'help',
    iconType: 'comments',
    iconClassName: 'white-icon title ml-1',
  },
];

export const getGroupsTabConfig = (
  hasGroupDocumentsAccess,
  hasAccessToGroupConfiguration,
  hasGroupNewsTriageAccess,
  hasGroupPracticeInteractionReportAccess
) => {
  const GROUPS_TABS = [
    {
      path: 'groups/insights',
      label: 'insights',
    },
    {
      path: 'groups/group-details',
      label: 'details',
    },
    {
      path: 'groups/group-tasks',
      label: 'tasks',
    },
  ];

  if (hasGroupDocumentsAccess) {
    GROUPS_TABS.splice(2, 0, {
      path: 'groups/group-documents',
      label: 'documents',
      counter: 'groupDocumentsAlertsCount',
    });
  }

  if (hasAccessToGroupConfiguration) {
    GROUPS_TABS.push({
      path: 'groups/group-config',
      label: 'config',
    });
  }

  if (hasGroupNewsTriageAccess) {
    GROUPS_TABS.push({
      path: 'groups/news',
      label: 'news',
    });
  }

  GROUPS_TABS.push({
    path: 'groups/reports',
    label: 'reports',
  });

  if (hasGroupPracticeInteractionReportAccess) {
    GROUPS_TABS.push({
      path: 'groups/interaction',
      label: 'interaction',
    });
  }

  return GROUPS_TABS;
};
